import React, { useState, useEffect } from 'react';
import {
  RACTextbox,
  RACDatePicker,
  RACButton,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACRadio,
  CircularProgress,
  Grid,
  Typography,
} from '@rentacenter/racstrap';
import { ReactComponent as Loadalert } from '../../assets/images/no-records-found.svg';
import { GetSearchResult, GetCustomerBasicInfo } from '../../api/user';
import moment from 'moment';
export interface coCustomerProps {
  customerId: string;
  handleClosePopUp: (action: boolean) => void;
  showCustomerInfo: (customerId: string) => void;
  history: any;
  isValidatePage?: boolean;
}
import { CoCustomerHeader } from '../../constants/constants';
const Initialsearch = {
  FirstName: '',
  LastName: '',
  dob: '',
};
const InitialSSN = {
  ThreeDigitSSN: '',
  TwoDigitSSN: '',
  FourDigitSSN: '',
}; //Ini

export function AddCoCustomer(props: coCustomerProps) {
  const dt = new Date();
  const [SSN, setSSN] = useState(InitialSSN);
  const [search, Setsearch] = useState(Initialsearch);
  const [searchData, SetsearchData] = useState([]);
  const [hidesearch, SetHideSearch] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [btnstate, setbtnstate] = useState(true);
  const [phonenumber, Setphonenumber]: any = useState();
  const [searchedUser, setSearchedUser]: any = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datepicker, setdatepicker] = useState('');
  const [enableloader, setenableloader] = useState<any>(false);
  const [clearData, setclearData] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableSubmitButton, setenableSubmitButton] = useState(true);
  const [radioIndex, setRadioIndex] = useState<any>(undefined);
  const OnsearchChange = (e: any) => {
    let regexItem;

    if (e.target.name !== 'dob') {
      regexItem = new RegExp('^[a-zA-Z]*$');

      if (e.target.value.match(regexItem)) {
        //setSSN({...SSN,[e.target.name]:e.target.value})
        Setsearch({ ...search, [e.target.name]: e.target.value });
      }
    } else {
      Setsearch({ ...search, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    if (
      search.FirstName.length >= 3 ||
      search.LastName.length >= 3 ||
      search.FirstName.length + search.LastName.length >= 3 ||
      search.dob !== '' ||
      (SSN.ThreeDigitSSN.length == 3 &&
        SSN.TwoDigitSSN.length === 2 &&
        SSN.FourDigitSSN.length == 4)
    ) {
      setbtnstate(false);
    } else {
      setbtnstate(true);
    }
    if (phonenumber != undefined) {
      const PhoneNum = parseInt(phonenumber);
      const phoneinput = PhoneNum.toString();

      if (phoneinput.length === 11) {
        setbtnstate(false);
      }
      if (
        (phoneinput.length < 10 && search.LastName.length >= 3) ||
        (phoneinput.length < 10 && search.FirstName.length >= 3) ||
        (SSN.ThreeDigitSSN.length == 3 &&
          SSN.TwoDigitSSN.length === 2 &&
          SSN.FourDigitSSN.length == 4 &&
          phoneinput.length < 10)
      ) {
        setbtnstate(true);
      } else {
        setbtnstate(false);
      }
    }
    setdatepicker(moment().subtract(18, 'years').format('YYYY-MM-DD'));
  }, [search, SSN, phonenumber]);
  const Search_Onclick = async () => {
    setenableloader(true);
    SetsearchData([]);

    if (
      search.FirstName !== '' ||
      search.LastName !== '' ||
      search.dob !== '' ||
      phonenumber != ''
    ) {
      //declaring the three variables initailly and convert them to tostring and concatenate all the variables to a variable Ssnnum
      let twodigit: any = SSN.ThreeDigitSSN;
      twodigit = twodigit.toString();
      let threedigit: any = SSN.TwoDigitSSN;
      threedigit = threedigit.toString();
      let fourdigit: any = SSN.FourDigitSSN;
      fourdigit = fourdigit.toString();
      let Ssnnum: any = twodigit + threedigit + fourdigit;
      Ssnnum = parseInt(Ssnnum);
      // eslint-disable-next-line no-console
      console.log(Ssnnum);
      setSearchedUser(search.FirstName);
      const phones: any = [
        {
          phoneNumber: '',
        },
      ];
      const payloadReq: any = {};
      if (search.FirstName != '') {
        payloadReq.firstName = search.FirstName;
      }
      if (search.LastName != '') {
        payloadReq.lastName = search.LastName;
      }
      if (search.dob != '') {
        payloadReq.dateOfBirth = search.dob;
      }
      if (
        SSN.ThreeDigitSSN !== '' &&
        SSN.TwoDigitSSN !== '' &&
        SSN.FourDigitSSN !== ''
      ) {
        payloadReq.taxId =
          SSN.ThreeDigitSSN + SSN.TwoDigitSSN + SSN.FourDigitSSN;
      }

      if (
        phonenumber !== undefined &&
        phonenumber !== '' &&
        phonenumber.length >= 12
      ) {
        const PhoneNum = phonenumber.replace(/[-()+ ]/g, '');
        const phoneinput = PhoneNum.toString();
        phones[0].phoneNumber = phoneinput.substring(1, 11);
        payloadReq.phones = phones;
        // eslint-disable-next-line no-console
        console.log(payloadReq);
      }
      getsearchvalues(payloadReq);
    }
  };
  const getsearchvalues = async (payload: any) => {
    //getting the response form the client to the tsx component
    const SearchCoCustomerresponse = await GetSearchResult(payload);
    setenableloader(false);
    setclearData(true);

    if (
      SearchCoCustomerresponse !== undefined &&
      SearchCoCustomerresponse.GetCustomers !== undefined &&
      SearchCoCustomerresponse.GetCustomers.customers !== undefined
    ) {
      SetsearchData(SearchCoCustomerresponse.GetCustomers.customers);
    }
    SetsearchData(SearchCoCustomerresponse.GetCustomers.customers);
    // eslint-disable-next-line no-console
    console.log(
      'searchciustomerresponse',
      SearchCoCustomerresponse.GetCustomers.customers
    );
    SetHideSearch(true);
  };
  const enterKeyPressed = (event: any) => {
    // Number 13 is the "Enter" key on the keyboard
    /* eslint-disable no-console */

    console.log('key pressed');
    /* eslint-enable no-console */

    if (event.charCode === 13 && !btnstate) {
      // Cancel the default action, if needed
      event.preventDefault();
      /* eslint-disable no-console */
      console.log('enter pressed');
      /* eslint-enable no-console */
      Search_Onclick();
      // Trigger the button element with a click
    }
  };
  const handlessn1 = (e: any) => {
    let regexItem;
    if (
      e.target.name == 'ThreeDigitSSN' ||
      e.target.name == 'TwoDigitSSN' ||
      e.target.name == 'FourDigitSSN'
    ) {
      regexItem = new RegExp('^[0-9]*$');
    }
    if (e.target.value.match(regexItem)) {
      setSSN({ ...SSN, [e.target.name]: e.target.value });

      if (e.target.name === 'ThreeDigitSSN' && e.target.value.length === 3) {
        (document.getElementById('ssn2') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'TwoDigitSSN' &&
        e.target.value.length === 2
      ) {
        (document.getElementById('ssn4') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'TwoDigitSSN' &&
        e.target.value.length === 0
      ) {
        (document.getElementById('ssn3') as HTMLInputElement).focus();
      } else if (
        e.target.name === 'FourDigitSSN' &&
        e.target.value.length === 0
      ) {
        (document.getElementById('ssn2') as HTMLInputElement).focus();
      }
    }
  };
  const HandleChange = (e: any) => {
    // debugger;
    //alert(e.target.value);
    const cleaned = ('' + e.target.value).replace(/\D/g, '');
    //This is raw data to pass on props
    //setPhonenumberforrcustValidatoion(cleaned);
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const temp = cleaned.replace(PhoneRegex, '($1) $2-$3');

    //Isvalid = temp.length == 14 ? true:false
    //alert(temp);
    Setphonenumber(temp);
  };
  // const Buildgrid = () => {
  //   if (
  //     searchData !== undefined &&
  //     searchData !== null &&
  //     searchData.length > 0
  //   ) {
  //     return searchData.map((value: any, index: any) => {
  //       //binding the grid with the result obtained from API
  //       return (
  //         //checking the count of active agreements ,greater than 0 or not
  //         <tr key={index}>
  //           <td>
  //             {value.agreementCounts == undefined ||
  //             (value.agreementCounts !== undefined &&
  //               value.agreementCounts.findIndex(
  //                 (type: { lob: string }) => type.lob === 'RTO'
  //               ) !== -1 &&
  //               value.agreementCounts[
  //                 value.agreementCounts.findIndex(
  //                   (type: { lob: string }) => type.lob === 'RTO'
  //                 )
  //               ].active == 0) ? (
  //               <input
  //                 className="form-check-input"
  //                 type="radio"
  //                 name="flexRadioDefault"
  //                 onClick={() =>
  //                   checkBoxActiveOnclick(index, value.globalCustomerId)
  //                 }
  //               ></input>
  //             ) : null}
  //           </td>
  //           <td>
  //             <a
  //               className="racpad-link"
  //               data-testid="searchhide"
  //               style={{ cursor: 'pointer' }}
  //               onClick={() => getcustomerbyid('R', value.globalCustomerId)}
  //             >
  //               {value.firstName + ' ' + value.lastName}
  //             </a>
  //           </td>
  //           <td>{truncString(value)}</td>
  //           <td>
  //             {value.addresses !== undefined &&
  //             value.addresses[0].addressLine1 !== undefined
  //               ? value.addresses[0].addressLine1
  //               : ''}
  //             {','}
  //             {AddressLine2Validation(value)}
  //             {value.addresses !== undefined &&
  //             value.addresses[0].city !== undefined &&
  //             value.addresses[0].postalCode !== undefined
  //               ? value.addresses[0].city + ',' + value.addresses[0].postalCode
  //               : ''}
  //           </td>
  //           <td>{normalizeinput(value)}</td>
  //           <td className="text-center">
  //             <a className="racpad-link text-end" href="#">
  //               {AgeementCounts(value)}
  //             </a>
  //           </td>
  //         </tr>
  //       );
  //     });
  //   }
  // };
  const [checkBoxActive, setcheckBoxActive] = useState<any>(undefined);

  const checkBoxActiveOnclick = async (index: number, gcd: any) => {
    setcheckBoxActive(undefined);

    const currentCUstomerChecked: number | undefined =
      checkBoxActive == index ? undefined : index;
    if (currentCUstomerChecked !== undefined) {
      const res = await getcustomerbyid('C', gcd);
      /* eslint-disable no-console */
      console.log('custID.customerId', res);
      if (res == undefined) {
        setcheckBoxActive(undefined);
      } else {
        res.customerId !== undefined
          ? setenableSubmitButton(false)
          : setenableSubmitButton(true);
        setRadioIndex(index);
        setcheckBoxActive(currentCUstomerChecked);
      }
    } else {
      setRadioIndex(undefined);
      setenableSubmitButton(false);
      setcheckBoxActive(currentCUstomerChecked);
    }
  };
  const AgeementCounts = (value: any) => {
    return value.agreementCounts !== undefined &&
      value.agreementCounts.findIndex(
        (type: { lob: string }) => type.lob === 'RTO'
      ) !== undefined &&
      value.agreementCounts.findIndex(
        (type: { lob: string }) => type.lob === 'RTO'
      ) !== -1
      ? value.agreementCounts[
          value.agreementCounts.findIndex(
            (type: { lob: string }) => type.lob === 'RTO'
          )
        ].active
      : 0;
  };
  const normalizeinput = (phonenum: any) => {
    phonenum =
      phonenum !== undefined &&
      phonenum.phones.length > 0 &&
      phonenum.phones[0].phoneNumber !== undefined
        ? phonenum.phones[0].phoneNumber
        : '';
    if (phonenum == '') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const AddressLine2Validation = (value: any) => {
    return value.addresses !== undefined &&
      value.addresses[0].addressLine2 !== undefined &&
      value.addresses[0].addressLine2 !== null
      ? value.addresses[0].addressLine2 + ','
      : '';
  };
  //   const Add_Cocustomer = (customerid: any) => {
  //     setenableSubmitButton(false);
  //     // eslint-disable-next-line no-console
  //     console.log('GlobalCutomerId', customerid);
  //     setCustomerId(customerid);
  //     const addobj: any = { customerid };
  //     Setactivecustomer(addobj);
  //   };
  //   const [isFetchingcustIdByGCD, setisFetchingcustIdByGCD] =
  // useState<any>(false);
  const [custID, setcustID] = useState<any>(undefined);
  const getcustomerbyid = async (type: any, id: any) => {
    try {
      const res: any = await GetCustomerBasicInfo(id);
      if (res !== undefined && res.customerId !== undefined) {
        // eslint-disable-next-line no-console
        //setisFetchingcustIdByGCD(false);
        console.log(res);
        if (type == 'C') {
          setcustID(res);
          return res;
        } else if (type == 'R') {
          props.showCustomerInfo(res.customerId);
        }
      }
    } catch (err: any) {
      if (err) {
        //setisFetchingcustIdByGCD(false);
        //setisclsAlertMsg(true);
        // eslint-disable-next-line no-console
        console.log('error while fetching customerid', err);
      }
    }
  };
  const createCoCustomer = () => {
    props.history.push(
      `/customer/createcustomer?customerId=${props.customerId}`
    );
    if (props.isValidatePage === true) {
      window.location.reload();
    }
  };
  function truncString(value: any) {
    if (
      value.dateOfBirth !== undefined &&
      value.dateOfBirth !== null &&
      value.dateOfBirth !== ''
    ) {
      const change = value.dateOfBirth;
      const statelookup = change.replace(/-/g, '/');
      const myArray = statelookup.split('/');
      const formatedDate = myArray[1] + '/' + myArray[2] + '/' + '****';

      return formatedDate !== '' &&
        formatedDate !== undefined &&
        formatedDate !== null
        ? formatedDate.toString()
        : '';
    }
    return '';
  }
  const renderSearchTableHead = () => (
    <>
      {CoCustomerHeader.map((col: string, index: number) => {
        return (
          <RACTableCell className="racstrap-tablecell-BgColor" key={index}>
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  const renderSearchTableContentFilled = () => {
    return searchData.map((el: any, i: any) => {
      return (
        <React.Fragment key={i}>
          <RACTableRow key={i}>
            <RACTableCell className="checkbox-width racstrap-tablecell-BgColor">
              {el.agreementCounts == undefined ||
              (el.agreementCounts !== undefined &&
                el.agreementCounts.findIndex(
                  (type: { lob: string }) => type.lob === 'RTO'
                ) !== -1 &&
                el.agreementCounts[
                  el.agreementCounts.findIndex(
                    (type: { lob: string }) => type.lob === 'RTO'
                  )
                ].active == 0) ? (
                <RACRadio
                  name={`flexRadioDefault_${i}`}
                  onClick={() => checkBoxActiveOnclick(i, el.globalCustomerId)}
                  checked={radioIndex == i ? true : false}
                  value={undefined}
                />
              ) : null}
            </RACTableCell>
            <RACTableCell className="racstrap-tablecell-BgColor">
              <a
                className="racpad-link"
                data-testid="searchhide"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => getcustomerbyid('R', el.globalCustomerId)}
              >
                {el.firstName + ' ' + el.lastName}
              </a>
            </RACTableCell>
            <RACTableCell className="racstrap-tablecell-BgColor">
              {truncString(el)}
            </RACTableCell>
            <RACTableCell className="racstrap-tablecell-BgColor">
              {el.addresses !== undefined &&
              el.addresses[0].addressLine1 !== undefined
                ? el.addresses[0].addressLine1
                : ''}
              {','}
              {AddressLine2Validation(el)}
              {el.addresses !== undefined &&
              el.addresses[0].city !== undefined &&
              el.addresses[0].postalCode !== undefined
                ? el.addresses[0].city + ',' + el.addresses[0].postalCode
                : ''}
            </RACTableCell>
            <RACTableCell className="racstrap-tablecell-BgColor">
              {normalizeinput(el)}
            </RACTableCell>
            <RACTableCell
              className="racstrap-tablecell-BgColor"
              style={{ textDecoration: 'none' }}
            >
              <a className="racpad-link text-end" href="#">
                {AgeementCounts(el)}
              </a>
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      );
    });
  };
  const renderContentNoRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Records Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderSearchTableContent: any = () => {
    //if (loading) return <></>;

    return searchData !== undefined &&
      searchData !== null &&
      searchData.length > 0
      ? renderSearchTableContentFilled()
      : renderContentNoRecordFound();
  };
  return (
    <Grid container className="pl-2">
      <Grid container spacing={2}>
        <Grid item md={12} className="mb-3">
          <Typography className="title">Search Co-Customer</Typography>
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            type="text"
            name="FirstName"
            inputlabel="First Name"
            value={search.FirstName}
            // className="form-control"
            maxlength={30}
            OnChange={(e: any) => OnsearchChange(e)}
            onKeyPress={(e: any) => enterKeyPressed(e)}
            isCurrency={false}
          />
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            type="text"
            inputlabel="Last Name"
            name="LastName"
            value={search.LastName}
            OnChange={(e: any) => OnsearchChange(e)}
            onKeyPress={(e: any) => enterKeyPressed(e)}
            isCurrency={false}
          />
        </Grid>
        <Grid item md={2}>
          <RACDatePicker
            id="a11y_dob"
            label="Date of birth"
            value={search.dob}
            required={false}
            name={'dob'}
            onChange={(e: any) => OnsearchChange(e)}
            inputProps={{
              max:
                dt.getFullYear() -
                18 +
                '-' +
                (dt.getMonth() + 1) +
                '-' +
                (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate()),
            }}
          />
        </Grid>
        <Grid item md={2}>
          <label htmlFor="a11y_ssn" className="form-label">
            Social Security# / ITIN#
          </label>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <RACTextbox
                type="password"
                name="ThreeDigitSSN"
                id="ssn3"
                // className="form-control"
                maxlength={3}
                value={SSN.ThreeDigitSSN}
                OnChange={(e: any) => handlessn1(e)}
                isCurrency={false}
              />
            </Grid>
            <Grid item md={4}>
              <RACTextbox
                type="password"
                name="TwoDigitSSN"
                value={SSN.TwoDigitSSN}
                maxlength={2}
                id="ssn2"
                OnChange={(e: any) => handlessn1(e)}
                isCurrency={false}
              />
            </Grid>
            <Grid item md={4}>
              <RACTextbox
                type="text"
                name="FourDigitSSN"
                value={SSN.FourDigitSSN}
                id="ssn4"
                maxlength={4}
                OnChange={(e: any) => handlessn1(e)}
                onKeyPress={(e: any) => enterKeyPressed(e)}
                isCurrency={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            isCurrency={false}
            type="phoneno"
            maxlength={14}
            inputlabel="Phone Number"
            value={phonenumber}
            name="phone_number"
            OnChange={(e: any) => HandleChange(e)}
            id="Phone"
            onKeyPress={(e: any) => enterKeyPressed(e)}
          />
        </Grid>
        <Grid item md={2} className="mt-3">
          <RACButton
            disabled={btnstate}
            className="btn btn-primary mt-2"
            isRounded={false}
            onClick={() => Search_Onclick()}
          >
            Search
          </RACButton>
        </Grid>
      </Grid>

      {hidesearch ? (
        <Grid container className="mt-4">
          <Grid container>
            <Grid item md={6}>
              <Typography className="title">Search Results</Typography>
            </Grid>
            <Grid item md={6} id="hidecreatecocustomer" className="text-right">
              {/* <button
                type="button"
                className="Create_CoCustomer"
                onClick={() => {
                  props.history.push('/customer/racstrap/createcustomer');
                }}
              >
                Create Co-Customer
              </button> */}
              <RACButton
                className="btn btn-primary"
                size="small"
                variant="outlined"
                onClick={() => createCoCustomer()}
              >
                Create Co-Customer
              </RACButton>
            </Grid>
            {/* ) : null} */}
          </Grid>
          {enableloader === true ? (
            <Grid item md={12} className="Norecords mt-5">
              <CircularProgress />
            </Grid>
          ) : null}

          {clearData === true &&
          enableloader === false &&
          searchData !== undefined &&
          searchData !== null &&
          searchData.length <= 0 ? (
            <Grid item md={12} className="Norecords">
              <Loadalert className="alerticon"></Loadalert>
              No Records Found
              <Typography className="margin-top-bottom">
                Do you want to create {searchedUser} as n a new customer?
              </Typography>
              <Grid item md={12} id="showcocustomer">
                <button type="button" className="create_Cocustomer">
                  Create Co-Customer
                </button>
              </Grid>
            </Grid>
          ) : enableloader === false ? (
            <Grid item md={12} className="mt-2">
              <RACTable
                renderTableHead={renderSearchTableHead}
                renderTableContent={renderSearchTableContent}
              />
            </Grid>
          ) : null}
          <Grid item md={12} className="text-right mt-3">
            <RACButton
              className="btn btn-secondary floatLeft"
              onClick={() => props.handleClosePopUp(false)}
            >
              Cancel
            </RACButton>
            <RACButton
              className="btn btn-primary mx-1"
              disabled={enableSubmitButton}
              onClick={() => {
                props.history.push(
                  `/agreement/rental/itemsearch/${props.customerId}?coCustomerId=${custID}`
                );
              }}
            >
              Submit
            </RACButton>
          </Grid>
        </Grid>
      ) : (
        <>
          {enableloader === true ? (
            <Grid item md={12} className="Norecords">
              <CircularProgress />
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  );
}
