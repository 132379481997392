/* eslint-disable prettier/prettier */
/* eslint-disable no-console */

import { client, client1, clientCall, defaultClient } from './client';
import { appConfig } from '../config/app-config';
import { CancelToken } from 'axios';
import jwtDecode from 'jwt-decode';
import { getJWToken } from './client';
import { DEHistoryRequest } from '../racstrapComponent/manageCustomer/ManageCustomerTypeScripts';
const QAToken =
  'eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmOWQ1OTcxNy02YjVhLTRmNjgtOWM5YS1lZTYxOGI5Yjk4YzUiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfOE1iT3lFWUc2X09rdGEiXSwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTYzNTY4MzY5MCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfOE1iT3lFWUc2IiwiZXhwIjoxNjM1NzEyNDkwLCJpYXQiOjE2MzU2ODM2OTEsInZlcnNpb24iOjIsImp0aSI6ImRiYzY4MzI0LTVmYTQtNGY4Zi1hZjc1LTczNzI3NTJhYmVmNyIsImNsaWVudF9pZCI6IjQ3cjJpaGs5ZmVoY3BuOXQ2NHRoZGJ1MnRsIiwidXNlcm5hbWUiOiJPa3RhX0ZTQ1VTRVIxQHJlbnRhY2VudGVyLmNvbSJ9.WC6b7pveBUnxBUzsPfPfEUOXBFlonuBWHtBD2-CafTJwgEOKW4WTfseo0CdHR5roEWScGp84q4_MEm94WZfNcJw9Q2s-bkJI7urpWQG2ROkyCRK3jUjGNy9txf9f0rU7dmI4jigjP2Exy3dSgIr419Y2oH-wiql8OEjJcj8mSu7NSnECj48Vm7nu3A-B0zOhZJk4qFpoNgKDZLEx6Ek_bf5XNNSQe5ovGnuZE9NPmfijRkSEqNJ37cL7i13RXHCah8dXMSDWeX0tz4W5_39INwYyikMsCMGu2Ax8inQh2lSkx80GMz3on6SZ2yird58z9YhY5k0uJWxG_nRrBKY25Q';
const BaseUrl = appConfig.apiUrls.micro;
const TwoFactorBaseURL = appConfig.apiUrls.storemgmt;
const getCurrentUserBaseURL = appConfig.apiUrls.menu;

// eslint-disable-next-line no-console
console.log('app config in user', appConfig);
// eslint-disable-next-line no-console
console.log('app url in user', appConfig.apiUrls.micro);
export const GetSearchResult = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  /* eslint-enable no-console */
  const TempAccessToken = QAToken;
  return await client(
    `customer/validate`,
    { method: 'POST', body: payload },

    BaseUrl,
    TempAccessToken
  );
};


export const UpdateAddressInfo = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `/customer/address/update`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const CreateCustomerInValidPage = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    const customHeaders = {
      headers: {
        storeNumber:
          window.localStorage.getItem('storeNumber') == undefined ||
            window.localStorage.getItem('storeNumber') == null
            ? window.sessionStorage.getItem('storeNumber')
            : window.localStorage.getItem('storeNumber'),
      },
    };
    return await client(
      `customer/create`,
      { method: 'POST', body: payload, customHeaders },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetSuggestedAddress = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('Address Doctor', payload);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/address/validate`,
      { method: 'POST', body: payload },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetWebLead = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/getweblead`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const RunDE = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/decision-engine`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const RefundLeadAmount = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `saleLead/refund`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetNODEReason = async () => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/no-decision-reason`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetState = async () => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/state`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const storeConfigCall = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  try {
    /* eslint-enable no-console */
    return await clientCall(
      `store-details`,
      { method: 'POST', body: payload },
      TwoFactorBaseURL,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const GetReferenceValues = async (param: string) => {
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `reference?referenceName=${param}`,
      { method: "GET" },
      appConfig.apiUrls.cashmgmt,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetWebLeadById = async (id: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/saleslead/details/${id}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetLegalSatatus = async (customerId: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/${customerId}/legal-hold`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetStateFromZip = async (zipCode: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/get-state/${zipCode}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetStateFromInfoOptions = async (storeNumber: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/customerinfo-options/${storeNumber}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetAgreementById = async (id: any, type: 'Y' | 'N') => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', id);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/${id}/agreements?inActive=${type}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetCustomerBasicInfo = async (GCID: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', GCID);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/${GCID}/detail`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
// get customer info
export const GetCustomerByIdInfo = async (
  customerId: any,
  storeNumber: any
) => {
  try {
    return await client(
      `customer/${customerId}`,
      { method: 'PUT', body: { storeNumber: storeNumber } },
      BaseUrl,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};

export const GetEnvelopeID = (parameters: any) =>
  client1('/ess-rest-test.cloudhub.io/esignature/envelopes', {
    method: 'POST',
    body: parameters,
  });
export const getCurrentUser = (): Promise<any> =>
  defaultClient(
    'users/current',
    { method: 'GET' },
    appConfig.apiUrls.container,
    true
  );
export const getUserPreferences = (productType: string) =>
  defaultClient(
    `users/current/preferences/${productType}`,
    { method: 'GET' },
    appConfig.apiUrls.container
  );
// export const getUserPermissions = (productType: string): Promise<any> =>
//   client(
//     `users/current/permissions/${productType}`,
//     { method: 'GET' },
//     appConfig.apiUrls.container
//   );
export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: 'PATCH',
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );
export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_DAP',
    { method: 'GET' },
    appConfig.apiUrls.container
  );

export const GetCustomerAlert = async (customerId: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', customerId);
  try {
    /* eslint-enable no-console */
    const TempAccessToken = QAToken;
    const queryValue = Math.floor(Math.random() * 1000000 + 1);
    return await client(
      `customer/alerts/${customerId}?alert=${queryValue}`,
      { method: 'GET' },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetAlert = async (customerId: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', customerId);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/${customerId}/alerts/assign`,
      { method: 'GET' },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
// export const GetCuCostumerRole = async (customerId: any) => {
//   /* eslint-disable no-console */
//   console.log('QA TOKEN', QAToken);
//   /* eslint-enable no-console */
//   /* eslint-disable no-console */
//   console.log('data', customerId);
//   /* eslint-enable no-console */
//   const TempAccessToken = QAToken;
//   return await client(
//     `customer/${customerId}/alerts/assign`,
//     { method: 'GET' },

//     BaseUrl,
//     TempAccessToken
//   );
// };
export const GetCustomerAddress = async (customerId: any, active: any) => {
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/address/${customerId}?active=${active}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const UpdateAddress = async (payload: any) => {
  // eslint-disable-next-line no-console
  console.log('QA TOKEN', QAToken);
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/address/update`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};


export const GetCustomerPhone = async (customerId: any, active: any) => {
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/phone/${customerId}?active=${active}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};


export const UpdateAlert = async (payload: any, customerId: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('payload', payload);
  /* eslint-disable no-console */
  console.log('customerId', customerId);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/${customerId}/update-customer-alert`,
      { method: 'PUT', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetCustomerInfoOptions = async (storeNumber: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('payload', storeNumber);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/customerinfo-options/${storeNumber}`,
      { method: 'GET' },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetEmployInfoOptions = async (squenceId: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/employement-options/${squenceId}`,
      { method: 'GET' },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetReferenceInfoOptions = async () => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/reference-options`,
      { method: 'GET' },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const UpdateCustomerInfo = async (payload: any, customerId: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/${customerId}/information`,
      { method: 'PUT', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const UpdateNoDeReason = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/post-no-decision`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const PrintFaxSheet = async (storeNumber: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/store-search`,
      { method: 'POST', body: { storeNumbers: storeNumber } },
      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetOptions = async (storeNumber: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('payload', storeNumber);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/employement-options`,
      { method: 'GET' },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetActivateClub = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('payload', payload);
  /* eslint-enable no-console */
  try {
    const TempAccessToken = QAToken;
    return await client(
      `customer/activate-club`,
      { method: 'POST', body: payload },

      BaseUrl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const getprofileaccess = async (Payload: any) => {
  try {
    return await clientCall(
      `store-access`,
      { method: 'POST', body: Payload },
      TwoFactorBaseURL,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getPinDetails = async (Payload: any) => {
  try {
    return await clientCall(
      `coworker`,
      { method: 'POST', body: Payload },
      TwoFactorBaseURL,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getsecondfactor = async (Payload: any) => {
  try {
    return await clientCall(
      `coworker-authentication`,
      { method: 'POST', body: Payload },
      TwoFactorBaseURL,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};
export const getCurrentInfo = async () => {
  try {
    return await clientCall(
      `user/current`,
      { method: 'GET' },
      getCurrentUserBaseURL,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};

export const getmenuandstore = async () => {
  try {
    const jwtToken = await getJWToken();
    const decodedToken: any = jwtDecode(jwtToken);
    // eslint-disable-next-line no-console
    console.log('decodedToken', decodedToken);
    const parameters = {
      coworkerId: decodedToken['custom:employeeId'],
    };
    return await client(
      'menu-and-stores',
      { method: 'POST', body: parameters },
      getCurrentUserBaseURL,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetCustomerCoverage = async (
  customerId: any,
  storeNumber: any
) => {
  try {
    return await client(
      `customer/${customerId}/${storeNumber}`,
      { method: 'PUT' },
      BaseUrl,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};
// eslint-disable-next-line sonarjs/no-identical-functions
export const GetSalesLeadExpirationDays = async (payload: any) => {
  /* eslint-disable no-console */
  console.log('QA TOKEN', QAToken);
  /* eslint-enable no-console */
  /* eslint-disable no-console */
  console.log('data', payload);
  try {
    /* eslint-enable no-console */
    return await clientCall(
      `store-details`,
      { method: 'POST', body: payload },
      TwoFactorBaseURL,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};
export interface customerInfoLoad {
  getAlerts: any;
  getCustomerAlerts: any;
  responseSales: any;
  result: any;
  getOptions: any;
  getCoverage: any;
  getAgrHistory?: any;
}
export const GetCustomerInfo = async (
  configPayload: any,
  customerId: any,
  storeNumber: any
) => {
  const responseObj: customerInfoLoad = {
    getAlerts: '',
    getCustomerAlerts: '',
    responseSales: '',
    result: '',
    getOptions: '',
    getCoverage: '',
    getAgrHistory: ''
  };
  try {
    //const getHeaderObj = await customerInfoClient(QAToken);

    await Promise.all([
      GetAlert(storeNumber),
      GetCustomerInfoOptions(storeNumber),
      GetCustomerAlert(customerId),
      GetSalesLeadExpirationDays(configPayload),
      GetCustomerByIdInfo(customerId, storeNumber),
      GetCustomerCoverage(customerId, storeNumber),
      GetAgreementById(customerId, 'N'),
    ])
      .then((response) => {
        [
          responseObj.getAlerts,
          responseObj.getOptions,
          responseObj.getCustomerAlerts,
          responseObj.responseSales,
          responseObj.result,
          responseObj.getCoverage,
          responseObj.getAgrHistory,
        ] = response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log('responseObj', resp);
      });
    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export interface exploymentInfoOpt {
  weekLy: any;
  semiMonthly: any;
  monthly: any;
}
export const GetEmploymentInfoOptions = async () => {
  const responseObj: exploymentInfoOpt = {
    weekLy: '',
    semiMonthly: '',
    monthly: '',
  };

  try {
    await Promise.all([
      GetEmployInfoOptions(1),
      GetEmployInfoOptions(3),
      GetEmployInfoOptions(5),
    ])
      .then((response) => {
        [responseObj.weekLy, responseObj.monthly, responseObj.semiMonthly] =
          response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log('responseObj', resp);
      });

    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export const GetCustomerCreateInfo = async (
  customerId: any,
  storeNumber: any
) => {
  const responseObj: customerInfoLoad = {
    getAlerts: '',
    getCustomerAlerts: '',
    responseSales: '',
    result: '',
    getOptions: '',
    getCoverage: '',
  };
  try {
    await Promise.all([
      GetCustomerInfoOptions(storeNumber),
      GetCustomerByIdInfo(customerId, storeNumber),
    ])
      .then((response) => {
        [responseObj.getOptions, responseObj.result] = response;
      })
      .catch((resp) => {
        // eslint-disable-next-line no-console
        console.log('responseObj', resp);
      });

    return responseObj;
  } catch (e: any) {
    return responseObj;
  }
};
export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};
export const GetDEHistory = async (Payload: DEHistoryRequest) => {
  let url = `cashmgmt/dehistory/${Payload.globalCustomerId}`;
  if (Payload.limit && Payload.offset) {
    url += `?limit=${Payload.limit}&offset=${Payload.offset}`;
  }
  return await clientCall(
    url,
    { method: 'GET' },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};

export const getRetentionOffers = async (Payload: any) => {
  try {

    const TempAccessToken = "QAToken";
    return await clientCall(
      `retention/offers`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const postAgreementActivity = async (Payload: any) => {
  try {

    const TempAccessToken = "QAToken";
    return await clientCall(
      `agreement/activity`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.micro,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const featureFlagInfo = async (bodyobject: any) => {
  const TempAccessToken = QAToken;
  return await client(
    `agreement/address/eventId`,
    { method: "POST", body: bodyobject },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const decryptCustomeeInfo = async (payload: any) => {
  const TempAccessToken: any = "QAToken";
  return await clientCall(
    `manage/draweraudit`,
    { method: "POST", body: payload },
    appConfig.apiUrls.cashmgmt,
    TempAccessToken
  );
};
export const storeException = async (Payload: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `agreement/store/exception`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreement,
    TempAccessToken
  );
};
export const getpaymentHistory = async (customer_id: string, type: string, offset: any) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `payment1/details/history/${customer_id}?type=${type}&limit=10&offset=${offset}`,
    { method: "GET" },
    appConfig.apiUrls.payment,
    TempAccessToken
  );
};
export const getVerificationNotRequiredReason = async (param?: string) => {
  const TempAccessToken = "QAToken";
  return await clientCall(
    `reference?referenceName=${param}`,
    { method: "GET" },
    appConfig.apiUrls.cashmgmt,
    TempAccessToken
  );
};


export const UpdateVerificationOptimization = async (payload: any) => {
  try {
    return await client(
      `customer/decision-engine`,
      {
        method: 'POST',
        body: payload
      },
      BaseUrl
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('Error in GetAgreements: ', error?.message);
    return undefined;
  }
};

export const UpdateCustomerDetails = async (payload: any) => {
  try {
    return await client(
      `customer/${payload.customerId}/information`,
      {
        method: 'PUT',
        body: payload
      },
      BaseUrl
    );
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('Error in GetAgreements: ', error?.message);
    return undefined;
  }
};

export interface ManageRiskAssessmentRequest {
  customerId: string;
  skip?: boolean;
  hard?: boolean;
  stolen?: boolean;
}

export const ManageRiskAssessment = async (payload: ManageRiskAssessmentRequest) => {
  try {
    return await client(
      'customer/manage-risk-assessment',
      { method: 'POST', body: payload },
      BaseUrl,
    );
  } catch (e: unknown) {
    // eslint-disable-next-line no-console
    console.log('Error in ManageRiskAssessment:', e instanceof Error ? e.message : '');
    return null;
  }
};