/* eslint-disable */
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACCheckBox,
  RACRadio,
  RACSelect,
  Typography,
} from "@rentacenter/racstrap";
import {
  API_ERROR_MESSAGE,
  EMPTY_STRING,
  STRING_NO,
  STRING_YES,
} from "../../constants/constants";
import { valueExistsInDropdown } from "../../utils/utils";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { CommunicationInformation } from "../../interface/updateCustomerInterface";
import { DropdownOptions } from "../../interface/globalInterface";
import { updateCustomerStyles } from "../../jsStyles/updateCustomerStyles";
import { updateObjectStateVariable } from "./Utils";
import { UpdateCustomerDetails } from "../../../api/user";
import { useParams } from "react-router-dom";
import { UpdateCustomerContext } from "../../context/UpdateCustomerContext";
import SecondFactor from "../../../racstrapComponent/manageCustomer/secondAuthen";

interface CommunicationPreferenceProps {
  data: {
    agreementTransferInitiated: boolean;
    dropdownLoader: boolean;
    communicationInformation: CommunicationInformation;
    updatedCommunicationInformation: CommunicationInformation;
    currentRole:string
  };
  dropdowns: {
    routeOptions: DropdownOptions[];
    methodOfContactOptions: DropdownOptions[];
  };
  function: {
    setCommunicationInformation: React.Dispatch<
      React.SetStateAction<CommunicationInformation>
    >;
    setUpdatedCommunicationInformation: React.Dispatch<
      React.SetStateAction<CommunicationInformation>
    >;
    setScreenLoader: React.Dispatch<React.SetStateAction<boolean>>;
  };
}
export function CommunicationPreference(props: CommunicationPreferenceProps) {
  const {
    communicationInformation,
    agreementTransferInitiated,
    dropdownLoader,
    updatedCommunicationInformation,
    currentRole
  } = props.data;
  const { routeOptions, methodOfContactOptions } = props.dropdowns;

  const {
    setCommunicationInformation,
    setUpdatedCommunicationInformation,
    setScreenLoader,
  } = props.function;
  const [showSecondLevlAuth, setShowSecondLevelAuth]=useState<boolean>(false);
  const [preferedRoute,setPreferedRoute]=useState<string>(EMPTY_STRING);;
  const { configurations } = useContext(UpdateCustomerContext)
  const { customerId } = useParams<any>();

  const classes = updateCustomerStyles();

  const saveButtonDisable =
    JSON.stringify(communicationInformation) ===  JSON.stringify(updatedCommunicationInformation);
  console.log(
    "communicationInformation",
    communicationInformation,
    updatedCommunicationInformation,
    saveButtonDisable
  );

  const isCustVerfOpt: boolean = configurations?.customerVerificationOptimization;

    useEffect(() => {
        console.log('Naa thaan');
    }, []);

  const saveOnChangeValue = (
    field: string,
    value: string | boolean,
    setterFunc: React.Dispatch<React.SetStateAction<CommunicationInformation>>,
    setterObj: CommunicationInformation
  ) => {
    try {
      setterFunc({
        ...setterObj,
        [field]: value,
      });
    } catch (error: any) {
      console.log("Error in handleCommnicationOnChange: ", error?.message);
    }
  };

  function getSolicitationValue(
    isEditEnabled: boolean,
    key: keyof typeof communicationInformation
  ) {
    if (isEditEnabled) {
      return communicationInformation[key] === STRING_YES
        ? STRING_NO
        : STRING_YES;
    }
    return updatedCommunicationInformation[key] === STRING_YES
      ? STRING_NO
      : STRING_YES;
  }

  async function updateCustomer() {
    try {
      const payload = {
        customerId: customerId,
        preferredContactMethod: updatedCommunicationInformation.contactMethod,
        preferredLanguage: updatedCommunicationInformation.language,
        printReceiptMethod: updatedCommunicationInformation.paymentReceipt,
        phoneSolicitationAllowed:
          updatedCommunicationInformation.phoneSolicitation,
        emailSolicitationAllowed:
          updatedCommunicationInformation.emailSolicitation,
        mailSolicitationAllowed:
          updatedCommunicationInformation.mailSolicitation,
        paymentReminderCallsAllowed:
          updatedCommunicationInformation.paymentRemainderCall,
        assignRoute: {
          storeNumber: window.sessionStorage.getItem("storeNumber"),
          customerId: Number(customerId),
          routeRefCode: updatedCommunicationInformation.route,
        },
      };
      setScreenLoader(true);
      const updateCustomer = await UpdateCustomerDetails(payload);
      if (updateCustomer?.UpdateCustomer?.value?.customerId) {
        updatedCommunicationInformation.isEditEnabled = true;
        console.log(
          "Inside Updated Customer Response 1",
          updatedCommunicationInformation
        );
        setCommunicationInformation(updatedCommunicationInformation);
      }
      setScreenLoader(false);
    } catch (error: any) {
      setScreenLoader(false);
      console.log("Error in Update customer in ComminicationPreferences");
    }
  }

  return (
    <>

    {showSecondLevlAuth ? (
        <SecondFactor
        key={'SLAComponent'}

          setTwoFactorCancelClick={() => setShowSecondLevelAuth(false)}
          setTwoFactorCompleted={() => {
            // setCommunicationInformation({...communicationInformation,route:preferedRoute});
            setUpdatedCommunicationInformation({...updatedCommunicationInformation, route:preferedRoute})
          }}
          moduleName={'Modify Customer Route'}
          currentRole={ currentRole }
        />
      ) : null}


      <Grid container md={12}>
        <Grid item md={12}>
          <Typography className={classes.title}>
            Communication Preferences
          </Typography>
        </Grid>

        <Grid item md={12}>
          <Card className={classes.cardStyle} style={{padding:'9px'}}>
            <CardContent>
              <Grid container spacing={2} md={12}>
                <Grid container md={3}>
                  <Grid item lg={12} xl={12}>
                    <Typography component="label">
                      Payment Reminder Call
                    </Typography>
                  </Grid>

                  <Grid container lg={12} xl={12}>
                    <Grid item md={3}>
                      <RACRadio
                        label="Opt In"
                        name="paymentRemainderCall"
                        id="paymentRemainderCallOptIn"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        value={STRING_YES}
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.paymentRemainderCall ===
                              STRING_YES
                            : updatedCommunicationInformation.paymentRemainderCall ===
                              STRING_YES
                        }
                        onClick={(event: any) => {
                          saveOnChangeValue(
                            event.target.name,
                            STRING_YES,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <RACRadio
                        label="Opt Out"
                        name="paymentRemainderCall"
                        id="paymentReminderCallOptOut"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        value={STRING_NO}
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.paymentRemainderCall ===
                              STRING_NO
                            : updatedCommunicationInformation.paymentRemainderCall ===
                              STRING_NO
                        }
                        onClick={(event: any) => {
                          saveOnChangeValue(
                            event.target.name,
                            STRING_NO,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel="Preferred Method of Contact "
                    name="contactMethod"
                    isDisabled={
                      agreementTransferInitiated ||
                      communicationInformation.isEditEnabled
                    }
                    errorMessage={
                      !dropdownLoader && methodOfContactOptions.length == 1
                        ? API_ERROR_MESSAGE
                        : EMPTY_STRING
                    }
                    options={methodOfContactOptions}
                    defaultValue={valueExistsInDropdown(
                      methodOfContactOptions,
                      communicationInformation.isEditEnabled
                        ? communicationInformation.contactMethod
                        : updatedCommunicationInformation.contactMethod
                    )}
                    loading={dropdownLoader}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      saveOnChangeValue(
                        "contactMethod",
                        event.target.value,
                        communicationInformation.isEditEnabled
                          ? setCommunicationInformation
                          : setUpdatedCommunicationInformation,
                        communicationInformation.isEditEnabled
                          ? communicationInformation
                          : updatedCommunicationInformation
                      );
                    }}
                  />
                </Grid>

                <Grid container md={3}>
                  <Grid container md={12}>
                    <Typography component="label">
                      Preferred Payment Receipt
                    </Typography>
                  </Grid>

                  <Grid container md={12}>
                    <Grid item>
                      <RACRadio
                        label="Email"
                        name="paymentReceipt"
                        value="PREM"
                        id="PREM"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.paymentReceipt == "PREM"
                            : updatedCommunicationInformation.paymentReceipt ==
                              "PREM"
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <RACRadio
                        label="Text"
                        name="paymentReceipt"
                        value="EM"
                        id="EM"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.paymentReceipt == "EM"
                            : updatedCommunicationInformation.paymentReceipt ==
                              "EM"
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <RACRadio
                        label="Print"
                        name="paymentReceipt"
                        value="PRNT"
                        id="PRNT"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.paymentReceipt == "PRNT"
                            : updatedCommunicationInformation.paymentReceipt ==
                              "PRNT"
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container md={3}>
                  <Grid container md={12}>
                    <Typography component="label">
                      Preferred Language
                    </Typography>
                  </Grid>
                  <Grid container md={12}>
                    <Grid item md={3}>
                      <RACRadio
                        label="English"
                        name="language"
                        value="ENG"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.language == "ENG"
                            : updatedCommunicationInformation.language == "ENG"
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <RACRadio
                        label="Spanish"
                        name="language"
                        value="SPA"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.language == "SPA"
                            : updatedCommunicationInformation.language == "SPA"
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container md={3}>
                  <Grid container md={12}>
                    <Typography component="label">
                      Accept Solicitation
                    </Typography>
                  </Grid>
                  <Grid container md={12}>
                    <Grid item>
                      <RACCheckBox
                        label="Phone"
                        size="small"
                        name="phoneSolicitation"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        value={getSolicitationValue(
                          communicationInformation.isEditEnabled,
                          "phoneSolicitation"
                        )}
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.phoneSolicitation ===
                              STRING_YES
                            : updatedCommunicationInformation.phoneSolicitation ===
                              STRING_YES
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <RACCheckBox
                        label="Mail"
                        size="small"
                        name="mailSolicitation"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        value={getSolicitationValue(
                          communicationInformation.isEditEnabled,
                          "mailSolicitation"
                        )}
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.mailSolicitation ===
                              STRING_YES
                            : updatedCommunicationInformation.mailSolicitation ===
                              STRING_YES
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <RACCheckBox
                        label="Email"
                        size="small"
                        name="emailSolicitation"
                        disabled={
                          agreementTransferInitiated ||
                          communicationInformation.isEditEnabled
                        }
                        value={getSolicitationValue(
                          communicationInformation.isEditEnabled,
                          "emailSolicitation"
                        )}
                        checked={
                          communicationInformation.isEditEnabled
                            ? communicationInformation.emailSolicitation ===
                              STRING_YES
                            : updatedCommunicationInformation.emailSolicitation ===
                              STRING_YES
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          saveOnChangeValue(
                            event.target.name,
                            event.target.value,
                            communicationInformation.isEditEnabled
                              ? setCommunicationInformation
                              : setUpdatedCommunicationInformation,
                            communicationInformation.isEditEnabled
                              ? communicationInformation
                              : updatedCommunicationInformation
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={3}>
                  <RACSelect
                    inputLabel={"Route"}
                    loading={dropdownLoader}
                    options={routeOptions}
                    isDisabled={
                      agreementTransferInitiated ||
                      communicationInformation.isEditEnabled
                    }
                    errorMessage={
                      !dropdownLoader && routeOptions.length == 0
                        ? API_ERROR_MESSAGE
                        : EMPTY_STRING
                    }
                    defaultValue={valueExistsInDropdown(
                      routeOptions,
                      communicationInformation.isEditEnabled ? communicationInformation.route : updatedCommunicationInformation.route
                    )}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      setPreferedRoute(event.target.value);
                      setShowSecondLevelAuth(true);
                    }}
                  />
                </Grid>
                {isCustVerfOpt ? (
                  <Grid item md={6} className={`${classes.textAlignRight}`}>
                    {!communicationInformation.isEditEnabled ? (
                      <>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          id="cancel"
                          className={`${classes.buttonsPadding} ${classes.mr16} ${classes.mt11px}`}
                          onClick={() => {
                            updateObjectStateVariable(
                              setCommunicationInformation,
                              "isEditEnabled",
                              true
                            );
                            updateObjectStateVariable(
                              setUpdatedCommunicationInformation,
                              "isEditEnabled",
                              true
                            );
                          }}
                        >
                          Cancel
                        </RACButton>
                        <RACButton
                          variant="contained"
                          color="primary"
                          id="save"
                          disabled={saveButtonDisable}
                          className={`${classes.buttonsPadding} ${classes.mr25p} ${classes.mt11px}`}
                          onClick={() => {
                            updateCustomer();
                          }}
                        >
                          Save
                        </RACButton>
                      </>
                    ) : (
                      <RACButton
                        variant="contained"
                        color="primary"
                        id="edit"
                        className={`${classes.buttonsPadding} ${classes.mr25p} ${classes.mt11px}`}
                        onClick={() => {
                          updateObjectStateVariable(
                            setCommunicationInformation,
                            "isEditEnabled",
                            false
                          );
                          communicationInformation.isEditEnabled = false;
                          setUpdatedCommunicationInformation(
                            communicationInformation
                          );
                        }}
                      >
                        Edit
                      </RACButton>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
