/* eslint-disable */
import {
  CircularProgress,
  Grid,
  RACButton,
  RACCheckBox,
  RACModalCard,
  RACTextbox,
} from "@rentacenter/racstrap";
import React, { useContext, useState } from "react";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { updateCustomerStyles } from "../../../jsStyles/updateCustomerStyles";
import { useParams } from "react-router-dom";
import { UpdateAlert } from "../../../api/user";
import { getCustomerAlerts } from "../../../apiHandler/updateCustomerApiHandler";
import { Alert, AlertResponse, CustomerAlertsResponse, removeAlert } from "../../../interface/updateCustomerInterface";

export function AssignAlerts() {
  const { alertIsOpen, setAlertIsOpen, assignedAlerts, setAssignedAlerts,
    alertsOptions, setIsAlertLoading, activeAlert, setActiveAlert,
    alertUpdateObj, setAlertUpdateObj, AlertLoad, setAlertLoad
  } = useContext(UpdateCustomerContext);

  interface InActiveAlerts {
    alertText: string,
    alertTypeDescEn: string,
    alertTypeDescEs: string,
    alertCleared: number,
    alertTypeId: string,
    customerAlertId: string,
  }

  const [Enabletxt, setEnabletxt] = useState(true);
  const [disableAlert, setdisableAlert] = useState<boolean>(false);
  const [CustomAlerttxt, setCustomAlerttxt] = useState('');
  const { customerId } = useParams<{ customerId: string }>();

  const classes = updateCustomerStyles();

  function closeAlert() {
    setAlertIsOpen(false);
  }

  const manageAlertValue = (action: 'add' | 'remove', newAlert: InActiveAlerts, alertTypeId?: number | string) => {
    const updatedAlerts: CustomerAlertsResponse[] = [...alertUpdateObj];
    if (action === 'remove') {
      if (alertTypeId !== '3') {
        const editAlertIndex = updatedAlerts.findIndex((val) => val.alertTypeId === alertTypeId);

        // If the alert is found, mark it as cleared
        if (editAlertIndex !== -1) {
          updatedAlerts[editAlertIndex] = {
            ...updatedAlerts[editAlertIndex],
            alertCleared: 1,
          };
        }
      } else {
        // Filter out alerts with alertTypeId '3'
        return updatedAlerts.filter((val) => val.alertTypeId !== '3');
      }
    } else {
      const editAlertIndex = updatedAlerts.findIndex((val) => val.alertTypeId === alertTypeId);

      // If the alert exists, mark it as active
      if (editAlertIndex !== -1) {
        updatedAlerts[editAlertIndex] = {
          ...updatedAlerts[editAlertIndex],
          alertCleared: 0,
        };
      } else {
        // If it doesn't exist, add the new alert
        updatedAlerts.push({ ...newAlert, alertCleared: 0 });
      }
    }
    return updatedAlerts;
  };

  const handleAssignCheckBox = (action: 'add' | 'remove', newAlert: InActiveAlerts, alertTypeId?: number | string) => {

    let activeAlertObj: CustomerAlertsResponse[] = alertUpdateObj;
    if (alertTypeId && alertTypeId == '3') {
      if (action == 'remove') {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    activeAlertObj = manageAlertValue(action, newAlert, alertTypeId);

    setAlertUpdateObj([...activeAlertObj]);
  };

  const buildAlert = (isAlertExist: boolean, index: number, addAlertObj: InActiveAlerts, val: AlertResponse) => {
    return (
      <Grid className={`${classes.p0} ${classes.mb3}`} key={index}>
        <RACCheckBox
          name="assignAlertChecked"
          checked={isAlertExist}
          onChange={() =>
            handleAssignCheckBox(
              isAlertExist === false ? 'add' : 'remove',
              addAlertObj,
              val.alertTypeId
            )
          }
        />
        {val.alertTypeDescEn}
      </Grid>
    );
  };

  const Assignalert = () => {
    if (alertsOptions?.length > 0) {
      return alertsOptions.map((val: AlertResponse, index: number) => {
        let inActiveAlert: InActiveAlerts = {
          alertText: '',
          alertTypeDescEn: '',
          alertTypeDescEs: '',
          alertCleared: 0,
          alertTypeId: '',
          customerAlertId: '',
        };;
        const isAlertActive: boolean = alertUpdateObj.filter((val1: CustomerAlertsResponse) =>
          val1.alertTypeId == val.alertTypeId && val1.alertCleared == 0
        ).length > 0 ? true : false;

        if (isAlertActive === false) {
          inActiveAlert = {
            alertText: '',
            alertTypeDescEn: val.alertTypeDescEn,
            alertTypeDescEs: val.alertTypeDescEs,
            alertCleared: 0,
            alertTypeId: val.alertTypeId,
            customerAlertId: '',
          };
        }

        return buildAlert(isAlertActive, index, inActiveAlert, val)
      });
    } else if (alertsOptions?.length == 0) {
      return (
        <Grid item className={`${classes.racCol2} ${classes.spacerMR2}`}>
          <Grid item style={{ marginLeft: '160px' }}
            className={`${classes.customerNorecordsAlert} ${classes.alertLoader} ${classes.ms2}`}
          >
            <CircularProgress className={classes.alertLoader} />
          </Grid>
        </Grid>
      );
    }
  };

  const CustomAlertval = (e: any) => {
    const alertValue = e.target.value?.trim();
    setCustomAlerttxt(alertValue);
    document.getElementById('CustomAlert')?.focus();
    if (!alertValue) {
      setdisableAlert(true);
    } else {
      setdisableAlert(false);
    }
  };

  const onAssignAlertCancel = () => {
    const updatedAlerts: any[] = [];

    if (activeAlert.length > 0) {
      activeAlert.forEach((alert: CustomerAlertsResponse) => {
        const alertIndex = AlertLoad.findIndex((val: Alert) => val.alertTypeId === alert.alertTypeId);

        if (alertIndex !== -1) {
          // Create a new alert object with updated cleared status
          const updatedAlert = {
            ...alert, alertCleared: AlertLoad[alertIndex].alertCleared,
          };
          updatedAlerts.push(updatedAlert);
        }
      });
    }

    // Filter out alerts with alertTypeId of 3
    const filteredAlerts = updatedAlerts.filter((alert) => alert.alertTypeId !== 3 && alert.alertTypeId !== '3');

    // Update state
    setAlertUpdateObj(filteredAlerts);
    setEnabletxt(true);
    setCustomAlerttxt('');
    setdisableAlert(false);
    closeAlert();
  };

  const buildActiveAlerts = (optionalAlerts?: CustomerAlertsResponse[]) => {
    let customerAlerts = assignedAlerts;
    if (optionalAlerts) {
      customerAlerts = optionalAlerts;
    }
    console.log(customerAlerts, 'assignedAlerts in build active')
    const activeAlerts: CustomerAlertsResponse[] = customerAlerts?.length ?
      customerAlerts.filter((val: CustomerAlertsResponse) => val.alertCleared == 0) : [];
    const popupAlerts: CustomerAlertsResponse[] = customerAlerts?.length ?
      customerAlerts.filter((val: CustomerAlertsResponse) =>
        val.alertCleared == 0 &&
        val.alertTypeId !== '3' &&
        val.alertTypeId !== 3
      ) : [];

    const alertLoad: Alert = customerAlerts?.map((val: CustomerAlertsResponse) => ({
      alertTypeId: val.alertTypeId,
      alertCleared: val.alertCleared,
    })) || [];

    setAlertLoad(alertLoad);
    setActiveAlert(activeAlerts);
    setAlertUpdateObj([...popupAlerts]);
  };

  const onAssignAlertSave = async () => {
    try {
      const removeAlert: removeAlert[] = [];
      const alreadyAssignedAlerts: CustomerAlertsResponse[] = assignedAlerts;
      alertUpdateObj.forEach((alert: CustomerAlertsResponse) => {
        const alertExist = alreadyAssignedAlerts.filter((val: CustomerAlertsResponse) => alert.alertTypeId === val.alertTypeId);

        const customerAlertId = alert.customerAlertId ? parseInt(alert.customerAlertId)
          : alertExist.length > 0 && alert.alertTypeId !== '3'
            ? parseInt(alertExist[0].customerAlertId) : null;

        removeAlert.push({
          alertTypeId: parseInt(alert.alertTypeId),
          customerAlertId,
          alertText: alert.alertTypeId === '3' ? CustomAlerttxt : '',
          alertClear: alert.alertCleared,
        });
      });

      const removeAlertObj = removeAlert.filter((val: removeAlert) => !(val.customerAlertId) && val.alertClear === 1);

      const alertObj = removeAlert.filter((alert: removeAlert) => {
        const inValidAlert = removeAlertObj.some((val: removeAlert) => val.alertTypeId === alert.alertTypeId);
        return !inValidAlert;
      });

      const payload = {
        alertTypes: alertObj,
        customerId: parseInt(customerId),
        userId: '191197',
      };

      console.log(payload, 'payload of save')

      setIsAlertLoading(true);
      const response = await UpdateAlert(payload, customerId);

      setCustomAlerttxt('');
      setEnabletxt(true);

      if (response && response.status === 200) {
        const getCustomerAlert = await getCustomerAlerts(customerId);
        setAssignedAlerts(getCustomerAlert);
        buildActiveAlerts(getCustomerAlert);
      }

      setIsAlertLoading(false);
      closeAlert();
    } catch (e) {
      setIsAlertLoading(false);
      closeAlert();
    }
  };

  const AlertPopUp = () => {
    return (
      <Grid container>
        <Grid item className={`${classes.floatLeft} ${classes.w100}`}>
          {Assignalert()}
          {Enabletxt !== true ? (
            <RACTextbox
              isCurrency={false}
              id="CustomAlert"
              value={CustomAlerttxt}
              OnChange={(e) => CustomAlertval(e)}
            />
          ) : null}
        </Grid>
        {alertsOptions?.length > 0 ? (
          <Grid item md={12} className={classes.mt4}>
            <Grid className={classes.floatRight}>
              <RACButton
                variant="outlined"
                color="primary"
                className={`${classes.mx1} ${classes.foc}`}
                onClick={() => onAssignAlertCancel()}
              >
                Cancel
              </RACButton>

              <RACButton
                disabled={
                  (activeAlert.length == 0 && alertUpdateObj.length == 0) ||
                    disableAlert
                    ? true
                    : false
                }
                variant="contained"
                color="primary"
                className={classes.mx1}
                onClick={() => {
                  onAssignAlertSave();
                  closeAlert();
                }}
              >
                Save
              </RACButton>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  return (
    <>
      <RACModalCard
        borderRadius="20px"
        isOpen={alertIsOpen}
        maxWidth="xs"
        title="Customer Alerts"
        onClose={() => {
          onAssignAlertCancel();
          closeAlert();
        }}
        closeIcon={true}
        children={AlertPopUp()}
      />
    </>
  );
}
