/* eslint-disable */
import moment from "moment";
import { useEffect, useState } from "react";
import { getFaxSheetDetails } from "../../../apiHandler/updateCustomerApiHandler";
import {
  EMPTY_STRING,
  FAX_SHEET_INFO_TEMPLATE,
  LOGGED_IN_STORE,
} from "../../../constants/constants";
import { makeStyles } from "@rentacenter/racstrap";
import { FaxSheetInformation } from "../../../interface/updateCustomerInterface";

const useStyles = makeStyles((theme) => ({
  block: {
    display: "block",
  },
  sheetAlignemnt: {
    width: "100%",
    fontFamily: "Arial",
    margin: "80px auto 0px auto",
  },
  floatLeft: {
    float: "left",
  },
  width100: {
    width: "100%",
  },
  mb10: {
    marginBottom: theme.typography.pxToRem(10),
  },
  sectionOneAlignement: {
    fontSize: "13px",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

interface FaxSheetProps {
  faxSheetDetails:any;
  customerInformation: {
    firstName: string;
    lastName: string;
    customerId: string;
  };
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setFaxSheetDetails:React.Dispatch<React.SetStateAction<any>>;
};

export function FaxSheetTemplate(props: FaxSheetProps) {
  const classes = useStyles();
  const {faxSheetDetails}=props;
  const { firstName, lastName, customerId } = props.customerInformation;
  const { setLoader,setFaxSheetDetails }=props;



  const getStoreFaxSheetDetails = async () => {
    try {
      setLoader(true);
      const storeFaxSheetDetails: FaxSheetInformation =
        await getFaxSheetDetails();
      setFaxSheetDetails(storeFaxSheetDetails);
      setLoader(false);
    } catch (error: any) {
      setLoader(false);
      console.log("Error in getStoreFaxSheetDetails: ", error?.message);
    }
  };

  useEffect(() => {
    if (
      !faxSheetDetails.storeNumber ||
      faxSheetDetails.storeNumber !== LOGGED_IN_STORE
    ) {
      getStoreFaxSheetDetails();
    }
  }, [LOGGED_IN_STORE]);

  return (    
        <div style={{ display: 'block' }}>
        <div
          style={{
            width: '100%',
            fontFamily: 'Arial',
            margin: '80px auto 0px auto',
          }}
          id="faxSheet"
        >
          {/*content starts*/}
          {/*Section 1 starts*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginBottom: '10px',
              }}
            >
              <div style={{ width: '66.66%', float: 'left' }}>
                <p style={{ fontSize: '13px', marginBottom: '10px' }}>
                  Rent-A- Center
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {faxSheetDetails?.storeName +
                  " " +
                  "(" +
                  faxSheetDetails?.storeName +
                  ")" || EMPTY_STRING}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {faxSheetDetails?.addressLine1 || EMPTY_STRING}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {faxSheetDetails?.city +
                  " " +
                  faxSheetDetails?.stateName +
                  " " +
                  faxSheetDetails?.fullZip || EMPTY_STRING}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {faxSheetDetails?.workPhoneNumber?.replace(
                  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                  "($1) $2-$3"
                ) || EMPTY_STRING}
                </p>
              </div>
              <table style={{ width: '33.33%', float: 'right' }}>
                <thead />
                <tbody
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                  }}
                >
                  <tr
                    style={{
                      width: '15%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Date:</td>
                  </tr>
                  <tr
                    style={{
                      width: '85%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}> {moment(new Date()).format("MM/DD/YYYY")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*Section 1 ends*/}
          {/*Section 2 starts*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginBottom: '10px',
              }}
            >
              <table style={{ width: '75%', float: 'left' }}>
                <thead />
                <tbody
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                  }}
                >
                  <tr
                    style={{
                      width: '4%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>To:</td>
                  </tr>
                  <tr
                    style={{
                      width: '96%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Rent-A-Center Field Service Center Tax Department
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '5%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Fax:</td>
                  </tr>
                  <tr
                    style={{
                      width: '95%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>(866) 706-2972</td>
                  </tr>
                  <tr
                    style={{
                      width: '4%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Re:</td>
                  </tr>
                  <tr
                    style={{
                      width: '96%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Tax Exempt Customer Documentation
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '33%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Pages (including cover page)
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '67%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      <span
                        style={{
                          borderBottom: '0.5px solid #707070',
                          width: '130px',
                          marginTop: '13px',
                          float: 'left',
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                style={{
                  float: 'left',
                  marginTop: '5px',
                  fontSize: '13px',
                  marginLeft: '5px',
                }}
              >
                Please fax documentation to support this Tax Exempt Application.
              </p>
              <table
                style={{
                  width: '75%',
                  float: 'left',
                  marginBottom: '400px',
                }}
              >
                <thead />
                <tbody>
                  <tr
                    style={{
                      width: '100%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Please complete the following:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '20%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Customer Name:</td>
                  </tr>
                  <tr
                    style={{
                      width: '80%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      {firstName + " " + lastName}
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '25%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Customer ID:</td>
                  </tr>

                  <tr
                    style={{
                      width: '75%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>{customerId}</td>
                  </tr>
                  <tr
                    style={{
                      width: '25%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Agreement Number:</td>
                  </tr>
                  <tr
                    style={{
                      width: '75%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }} />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*Section 2 ends*/}
          {/*Section 3 starts*/}
          <div>
            <p
              style={{
                width: '100%',
                fontSize: '13px',
                float: 'left',
                marginBottom: '0px',
              }}
            >
              Legal Disclosure
            </p>
            <p style={{ width: '100%', fontSize: '13px', float: 'left' }}>
              TBD
            </p>
          </div>
          {/*Section 3 ends*/}
          {/*Content ends*/}
        </div>
      </div>
  );
}
