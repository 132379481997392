/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import { History } from 'history';
import {
  RACThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
import { CustomerContextProvider } from '../context/customerContext/customerContext';
import { FeatureFlagContextProvider } from '../context/FeatureFlagContext';
interface Props {
  history: History;
  customProps?: CustomPropInterface;
}
const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodprefix',
  seed: 'RAC-CUSTOMER-',
});
export const appTestId = 'appTestId';

export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

export const App = ({ history, customProps }: Props) => {
  const enableLogs = window.sessionStorage.getItem('enableLogs');

  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
  const { isRenderedByContainer } = window;
  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <FeatureFlagContextProvider>
        <CustomerContextProvider>
          <ContainerContext.Provider value={customProps}>
            <div className="">
              <ErrorBoundary>
                <Router history={history}>
                  {isRenderedByContainer ? (
                    <InContainerAuthProvider>
                      <AuthGuard />
                    </InContainerAuthProvider>
                  ) : (
                    <StandaloneAuthProvider>
                      <AuthGuard />
                    </StandaloneAuthProvider>
                  )}
                </Router>
              </ErrorBoundary>
            </div>
          </ContainerContext.Provider>
        </CustomerContextProvider>
          </FeatureFlagContextProvider>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
