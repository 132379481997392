/* eslint-disable */

import React, { useContext } from "react";
import {
  Grid,
  RACDatePicker,
  RACTextbox,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { ResidenceInfoProps } from "../../../interface/globalInterface";
import { formatMobileNumber, formatText, updateResidenceInfo } from "../Utils";

import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
export default function RentalResidenceInfo({ value, setterData }: ResidenceInfoProps) {
  const classes = globalStyles();
  const { residenceEditClicked, residenceInfoCopy, setResidenceInfoCopy } = useContext(UpdateCustomerContext)

  return (
    <Grid container md={12} spacing={2} className={`${classes.mt16pxRem}`}>
      <React.Fragment>
        <Grid item md={2}>
          <RACTextbox
            required={true}
            type={"text"}
            disabled={!residenceEditClicked}
            inputlabel="Landlord's First Name"
            OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
              rentalDetails: {
                ...setterData.residenceInfo.rentalDetails,
                landlordsFirstName: formatText(e.target.value).replace(/[^A-Z'.;’ a-z]/gi, '')
              }
            })}
            maxlength={30}
            value={residenceEditClicked ? value.rentalDetails.landlordsFirstName : residenceInfoCopy?.rentalDetails.landlordsFirstName}

            errorMessage={
              value?.residenceInfoError?.landlordsFirstNameError ? "Required" : ''
            }
          />
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            isCurrency={false}
            required={true}
            type={"text"}
            disabled={!residenceEditClicked}
            inputlabel="Landlord's Last Name"
            OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
              verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
              rentalDetails: {
                ...setterData.residenceInfo.rentalDetails,
                landlordsLastName: formatText(e.target.value),

              }
            })}
            maxlength={30}
            value={residenceEditClicked ? value.rentalDetails.landlordsLastName : residenceInfoCopy?.rentalDetails.landlordsLastName}
            errorMessage={
              value?.residenceInfoError?.landlordsLastNameError ? "Required" : ''
            }
          />
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            isCurrency={false}
            required={true}
            type={"text"}
            disabled={!residenceEditClicked}
            inputlabel="Landlord’s Phone Number"
            name={`llnumber`}
            OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
              verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
              rentalDetails: { ...setterData.residenceInfo.rentalDetails, landlordsPhoneNumber: e.target.value }
            })}
            maxlength={14}
            value={residenceEditClicked ? formatMobileNumber(value.rentalDetails?.landlordsPhoneNumber) : formatMobileNumber(residenceInfoCopy?.rentalDetails?.landlordsPhoneNumber)}
            errorMessage={
              value?.residenceInfoError?.landlordsPhoneNumberError ? "Required" : ''
            }
          />
        </Grid>
        <Grid item md={2}>
          <RACTextbox
            isCurrency={true}
            type={"number"}
            digitFormat={"currency"}
            disabled={!residenceEditClicked}
            inputlabel="Payment Amount"
            name={`llamount`}
            OnChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
              verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
              rentalDetails: { ...setterData.residenceInfo.rentalDetails, paymentAmount: e.target.value }
            })}
            maxlength={10}
            value={residenceEditClicked ? value.rentalDetails.paymentAmount ? value.rentalDetails?.paymentAmount : '' : residenceInfoCopy?.rentalDetails.paymentAmount ? residenceInfoCopy?.rentalDetails?.paymentAmount : ''}
            className={classes.currentTextBoxStyle}
            dollarTextClassName={classes.customerDollerInputFiled}
          />
        </Grid>
        <Grid item md={2}>
          <RACDatePicker
            disabled={!residenceEditClicked}
            value={residenceEditClicked ? value.rentalDetails.moveInDate : residenceInfoCopy?.rentalDetails?.moveInDate}
            label="Move in Date"
            name="lldateMove"
            onChange={(e: any) => updateResidenceInfo(setterData.residenceInfo, setterData.setResidenceInfo, {
              verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
              rentalDetails: { ...setterData.residenceInfo.rentalDetails, moveInDate: e }
            })}
          />
        </Grid>
      </React.Fragment>
    </Grid>
  );
}
