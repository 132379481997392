/* eslint-disable */

import React, { useContext } from "react";
import { CircularProgress, Grid, RACButton, RACModalCard, RACRadio, Typography } from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { UpdateCustomerContext } from "./../../../context/UpdateCustomerContext";
import { updateArrayOfObjectState } from "../Utils";
import { ReactComponent as WarningIcon } from "../../../../assets/images/no-records-found.svg";
import { MultiplePrimaryFuntion } from "./MultiplePrimaryAddress";
import { CustomerBasicInformation } from "../../../interface/updateCustomerInterface";
import { AddressDetailsBinder } from "../../../interface/globalInterface";
import { buildAddressPayload } from "../../updateCustomer/microComponents/FooterValidations/SaveValidations";
import { useParams } from "react-router-dom";
import { UpdateAddressInfo } from "../../../api/user";

export function AddressValidationPopUp(props: any) {

  const { addressErrorMsg, suggestedAddress,
    defaultAddress, setDefaultAddress, matchCode,
    ValidateIsOpen, setValidateIsOpen, validatedAddresses,
    canSuggestAddress, setCanSuggestAddress,
    AddressDoc, BtnIsdisabled1, setBtnIsdisabled1, BtnIsdisabled2, setBtnIsdisabled2,
    cantSuggestAddress, setCantSuggestAddress, addressIndex,
    setIsCustomerInfoUpdate, customerBasicInformation,
    setCustomerBasicInformation, setScreenLoader } = props?.addressValidationPayload;

  const classes = globalStyles();
  const setterData = props?.value;
  const value = props?.data;
  const { customerId } = useParams<{ customerId: string }>();

  const continueClickAction = async (type: 'SUG' | 'ENT' | 'GA', uppercase?: boolean, overRide?: boolean) => {
    let customerInformationObj: any = setterData.editedAddress[addressIndex];
    let activeAddress: any = value.addressDetails.filter((address: any) => address.addressId !== 0);
    const customerBasicInfo: CustomerBasicInformation = { ...customerBasicInformation };
    const editDisable = () => {
      customerInformationObj.isEditEnabled = false;
      updateArrayOfObjectState(setterData.editedAddress, setterData.setEditedAddress, addressIndex, customerInformationObj);
    }

    // Update customer information based on type (suggested address, entered address, google address)
    updateCustomerInformation(type, customerInformationObj, uppercase)

    setValidateIsOpen(false);
    setCanSuggestAddress(false);

    // Check if an update is needed
    if (shouldUpdateAddress(type, uppercase, overRide)) {
      activeAddress[addressIndex] = customerInformationObj;
      setIsCustomerInfoUpdate(true);
      // Collect all addressIds with addressType 'MAIL'
      const mailAddressIds = activeAddress
        .filter((address: any) => address.addressType === 'MAIL')
        .map((address: any) => address.addressId);

      activeAddress = activeAddress.filter((address: any) => {
        // Remove addressId from 'PRIM' if it matches any 'MAIL' addressId
        if (address.addressType === 'PRIM' && mailAddressIds.includes(address.addressId)) {
          delete address.addressId;
        }

        // Keep the address unless it's a mail address with an empty addressLine1
        return !(address.addressType === 'MAIL' && address.addressLine1 == null);
      });

      setScreenLoader(true);
      const addressUpdation = await updateAddress(activeAddress);
      setScreenLoader(false)

      if (addressUpdation) {
        editDisable();
        activeAddress[addressIndex] = customerInformationObj;
        setterData.setActiveAddress(activeAddress);
        updateCustomerBasicInfo(customerInformationObj, customerBasicInfo);
      }
    } else {
      editDisable();
    }
  };

  const updateCustomerInformation = (type: 'SUG' | 'ENT' | 'GA', customerInformationObj: any, uppercase?: boolean) => {
    if (type == 'GA') {
      // Manipulations for google address
      const postalAddress = validatedAddresses?.postalAddress;
      customerInformationObj.city = postalAddress?.locality;
      customerInformationObj.state = postalAddress?.administrativeArea;
      customerInformationObj.zipCode = postalAddress?.postalCode;
      if (postalAddress?.addressLines.length > 1) {
        customerInformationObj.addressLine1 = postalAddress?.addressLines[1]
        customerInformationObj.addressLine2 = postalAddress?.addressLines[0]
      } else {
        customerInformationObj.addressLine1 = postalAddress?.addressLines.join(', ');
        customerInformationObj.addressLine2 = '';
      }
    }
    else if (type == 'SUG') {
      // Manipulations for suggested address
      const addressLine2 = [customerInformationObj.addressLine2, suggestedAddress?.Building, suggestedAddress?.Unit,
      suggestedAddress?.POBox].filter(Boolean).join(' ')
      customerInformationObj.addressLine1 = suggestedAddress?.StreetAddress;
      customerInformationObj.addressLine2 = addressLine2;
      customerInformationObj.city = suggestedAddress?.City;
      customerInformationObj.state = suggestedAddress?.StateProvince;
      customerInformationObj.zipCode = suggestedAddress?.PostalCode;
    }
    else if (type === 'ENT' && uppercase) {
      // Manipulations for Entered address
      Object.keys(customerInformationObj).forEach(key => {
        if (typeof customerInformationObj[key] === 'string') {
          customerInformationObj[key] = UpperCase(customerInformationObj[key]);
        }
      });
    }
  }

  const updateCustomerBasicInfo = (customerInformationObj: any, customerBasicInfo: any) => {
    customerBasicInfo.addressLine1 = customerInformationObj.addressLine1;
    customerBasicInfo.addressLine2 = customerInformationObj.addressLine2;
    customerBasicInfo.city = customerInformationObj.city;
    customerBasicInfo.state = customerInformationObj.state;
    customerBasicInfo.zip = customerInformationObj.zipCode;
    setCustomerBasicInformation(customerBasicInfo);
  };

  // Update address API function
  async function updateAddress(address: AddressDetailsBinder[]) {
    const addressPayload = buildAddressPayload(address, customerId);
    const updateAddress = await UpdateAddressInfo(addressPayload);
    if (updateAddress.status == 200 && updateAddress.data?.updateAddress?.value?.customerId) {
      return true;
    }
    return false;
  }

  const shouldUpdateAddress = (type: 'SUG' | 'ENT' | 'GA', uppercase?: boolean, overRide?: boolean) => {
    return type === 'SUG' || type === 'GA' || (type === 'ENT' && uppercase) || overRide;
  };

  const UpperCase = (value: string | null) => {
    let uppercaseValue: string = '';
    if (value) {
      uppercaseValue = value.toUpperCase();
    }
    return uppercaseValue;
  }

  const RadioBtnclick = (type: any) => {
    if (type === 'SUG') {
      setBtnIsdisabled1(true);
      setBtnIsdisabled2(false);
      setDefaultAddress('SUG');
    } else if (type === 'ENT') {
      setBtnIsdisabled1(false);
      setBtnIsdisabled2(true);
      setDefaultAddress('ENT');
    }
    else if (type == 'GA') {
      setDefaultAddress('GA')
    }
  };

  const addressDoctorPopup = () => {
    return (
      <Grid item md={12} className={classes.spacerPX2}>
        {AddressDoc === true ? (
          <div className={classes.customerNorecords}>
            <CircularProgress />
          </div>
        ) : suggestedAddress == 'Error' ? (
          <React.Fragment>
            <Typography className={classes.RACPOPMsg}>
              {addressErrorMsg}
            </Typography>
            {matchCode === '1' || matchCode === '2' ? (
              <Grid item md={12} className={classes.customerTextcenter}>
                <RACButton
                  className={`${classes.spacerMR2}`}
                  variant="outlined"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    continueClickAction('ENT');
                  }}
                >
                  No
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  isRounded={false}
                  onClick={() => {
                    continueClickAction('ENT', true);
                  }}
                >
                  Yes
                </RACButton>
              </Grid>
            ) : (
              <Grid item md={12} className={`${classes.customerTextcenter} ${classes.spacerMT2}`}>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    continueClickAction('ENT', true);
                  }}
                >
                  Ok
                </RACButton>
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <Grid item md={12} className={classes.spacerPX2}>
            <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold}`}
            >
              Entered address is not located
            </Typography>
            <Grid item md={12} className={`${classes.spacerMB2} ${classes.spacerMT2}`}>
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold}`}
              >
                Suggested Addresses
              </Typography>
              <div className={classes.formCheck}>
                <RACRadio
                  id="inlineRadio1"
                  name="inlineRadioOptions"
                  checked={defaultAddress == 'SUG' ? true : false}
                  onClick={() => RadioBtnclick('SUG')}
                  value={'SUG'}
                />
                <Typography
                  variant="caption"
                  className={`${classes.RACPOPMsg} ${classes.semiBold}`}
                >
                  {formatAddressDoctorAddress('SUG')}
                </Typography>
              </div>
            </Grid>
            <Grid item md={12} className={classes.spacerMB3}>
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold}`}
              >
                Entered Address
              </Typography>
              <div className={classes.formCheck}>
                <RACRadio
                  id="inlineRadio2"
                  name="inlineRadioOptions"
                  checked={defaultAddress == 'ENT' ? true : false}
                  onClick={() => RadioBtnclick('ENT')}
                  value={'ENT'}
                />
                <Typography
                  variant="caption"
                  className={`${classes.RACPOPMsg} ${classes.semiBold}`}
                >
                  {formatAddressDoctorAddress('ENT')}
                </Typography>
              </div>
            </Grid>
            <Grid
              className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
            >
              <Grid className={classes.floatLeft}>
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={classes.foc}
                  onClick={() => continueClickAction('ENT')}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid className={`${classes.floatRight}`}>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.spacerME2}
                  onClick={() => continueClickAction('ENT')}
                  disabled={BtnIsdisabled1}
                >
                  Override
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={BtnIsdisabled2}
                  onClick={() => continueClickAction('SUG')}
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const formatAddressDoctorAddress = (type: 'SUG' | 'ENT') => {
    let address = ''
    if (type == 'SUG') {
      address = [suggestedAddress?.StreetAddress, suggestedAddress?.Building, suggestedAddress?.Unit, suggestedAddress?.POBox,
      suggestedAddress?.City, suggestedAddress?.StateProvince, suggestedAddress?.PostalCode].filter(Boolean).join(', ')
    } else {
      const enteredAddress = setterData.editedAddress[addressIndex];
      address = [enteredAddress?.addressLine1, enteredAddress?.addressLine2, enteredAddress?.city,
      enteredAddress?.state, enteredAddress?.zipCode].filter(Boolean).join(', ')
    }
    return address;
  }

  function suggestAddressFn() {
    return (
      <>
        <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
            Entered Address
          </Typography>
          <RACRadio
            id="inlineRadio1"
            name="inlineRadioOptions"
            checked={defaultAddress == 'ENT' ? true : false}
            onClick={() => setDefaultAddress('ENT')}
            value={'ENT'}
          />
          <Typography className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}>
            {validatedAddresses.enteredAddress}
          </Typography>
          <Grid item md={12} className={`${classes.spacerMB2} ${classes.spacerMT2}`}>
            <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultAddress == 'GA' ? true : false}
                onClick={() => setDefaultAddress('GA')}
                value={'GA'}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
              >
                {validatedAddresses.suggestedAddress}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}>
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setCanSuggestAddress(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => continueClickAction(defaultAddress, false, true)}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    )
  }

  function suggestErrorInAddress() {
    return (
      <>
        <Grid item md={12}>
          <Grid style={{ justifyContent: "center", display: "flex", marginBottom: '2%' }}>
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid item md={12} className={classes.customerTextcenter}>
            <Typography variant={'body2'} className={classes.RACPOPMsg}>
              {validatedAddresses?.message}
            </Typography>
          </Grid>
          <Grid container className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}>
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              onClick={() => setCantSuggestAddress(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <RACModalCard
        isOpen={canSuggestAddress}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setCanSuggestAddress(false)}
        children={suggestAddressFn()}
        title="Validation Message"
      />

      <RACModalCard
        isOpen={cantSuggestAddress}
        maxWidth="xs"
        borderRadius="20px"
        children={suggestErrorInAddress()}
      />

      <RACModalCard
        borderRadius="20px"
        isOpen={ValidateIsOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          continueClickAction('ENT')
        }}
        children={addressDoctorPopup()}
        title="Validation Message"
      />

      <MultiplePrimaryFuntion />
    </>
  )

}