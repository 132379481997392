/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useState, createContext } from 'react';

export const customerValidationContext = createContext<any>('');

export function CustomerContextProvider(props: any) {
  const [customerValidation, setcustomerValidation] = useState<any>();
  const [customerInformation, setcustomerInformation] = useState<any>();
  const [employementInformation, setemployementInformation] = useState<any>();
  const [residenceInformation, setresidenceInformation] = useState<any>();
  const [referenceInformation, setreferenceInformation] = useState<any>();
  //state for enable the validate btn
  const [enableValidatebtn, setenableValidatebtn] = useState<any>(true);
  //disabled for enable the validate btnvalidateEnablebtn
  const [disabledValidatebtn, setdisabledValidatebtn] = useState<any>(true);
  //run validate customer button
  const [runValidatebtn, setrunValidatebtn] = useState<any>(false);
  //run new customer button
  const [runNewCustomerbtn, setrunNewCustomerbtn] = useState<any>(false);
  //run next  button in customer information
  const [runclearbtn, setrunclearbtn] = useState<any>(false);
  //run next  button in customer information
  const [clearbtndisable, setclearbtndisable] = useState<any>(true);
  const [saleLeadCoCreate, setsaleLeadCoCreate] = useState<any>();
  const [newCustValidBtnHidden, setnewCustValidBtnHidden] = useState<any>(true);
  const [customerIdQ, setcustomerIdQ] = useState<any>(null);
  const [customerSrc, setcustomerSrc] = useState<any>(null);
  const [agrId, setagrId] = useState<any>(null);
  //run next  button in customer information
  const [runNextCustomerInfobtn, setrunNextCustomerInfobtn] =
    useState<any>(false);
  //run next  button in Employee information
  const [runNextEmpInfobtn, setrunNextEmpInfobtn] = useState<any>(false);
  //run next  button in Residence information
  const [runNextResInfobtn, setrunNextResInfobtn] = useState<any>(false);
  //run next  button in Refrence information
  const [runNextRefInfobtn, setrunNextRefInfobtn] = useState<any>(false);
  //run prev  button in employment information
  const [page3Previosbtn, setpage3Previosbtn] = useState<any>(false);
  //run prev  button in employment information
  const [page4Previosbtn, setpage4Previosbtn] = useState<any>(false);
  //run prev  button in employment information
  const [page5Previosbtn, setpage5Previosbtn] = useState<any>(false);
  //run run DE  button in search information
  const [runDEbtn, setrunDEbtn] = useState<any>(false);
  //run Take payment button in search information
  const [takePaymentbtn, settakePaymentbtn] = useState<any>(false);
  //Global var for decision engine
  const [DEStatus, setDEStatus] = useState<any>('Decline');
  //Class Name for hidden wizard
  const [className, setclassName] = useState<any>('inline-block');

  const [activePages, setactivePages] = useState<any>({
    page1: 'active',
    page2: 'inactive',
    page3: 'inactive',
    page4: 'inactive',
    page5: 'inactive',
  });
  const [pageClass, setpageClass] = useState<any>({
    page1Class: '',
    page2Class: '',
    page3Class: '',
    page4Class: '',
    page5Class: '',
  });
  const [searchValues, setsearchVal] = useState<any>({
    firstName: '',
    lastName: '',
    dob: '',
    ssn1: '',
    ssn2: '',
    ssn3: '',
    phoneNumber: '',
  });

  const [searchPageActive, setsearchPageActive] = useState<any>(false);
  const [isFromCustPage, setisFromCustPage] = useState<any>(null);

  const [selectedinventoryNumber, setselectedinventoryNumber] =
    useState<any>(null);

  return (
    <div>
      <customerValidationContext.Provider
        value={{
          page1: [customerValidation, setcustomerValidation],
          page2: [customerInformation, setcustomerInformation],
          page3: [employementInformation, setemployementInformation],
          page4: [residenceInformation, setresidenceInformation],
          page5: [referenceInformation, setreferenceInformation],
          activePages: [activePages, setactivePages],
          wizardClass: [pageClass, setpageClass],
          validateEnablebtn: [enableValidatebtn, setenableValidatebtn],
          validatedisabledbtn: [disabledValidatebtn, setdisabledValidatebtn],
          runValidbtn: [runValidatebtn, setrunValidatebtn],
          clearbtn: [runclearbtn, setrunclearbtn],
          runCustbtn: [runNewCustomerbtn, setrunNewCustomerbtn],
          nextBtnInCustInfo: [
            runNextCustomerInfobtn,
            setrunNextCustomerInfobtn,
          ],
          nextBtnInEmpInfo: [runNextEmpInfobtn, setrunNextEmpInfobtn],
          nextBtnResInfo: [runNextResInfobtn, setrunNextResInfobtn],
          nextBtnRefInfo: [runNextRefInfobtn, setrunNextRefInfobtn],
          prevPage3: [page3Previosbtn, setpage3Previosbtn],
          prevPage4: [page4Previosbtn, setpage4Previosbtn],
          prevPage5: [page5Previosbtn, setpage5Previosbtn],
          destatus: [DEStatus, setDEStatus],
          cName: [className, setclassName],
          clrBtnDisable: [clearbtndisable, setclearbtndisable],
          searchVal: [searchValues, setsearchVal],
          searchPage: [searchPageActive, setsearchPageActive],
          deRunBtn: [runDEbtn, setrunDEbtn],
          takePmtbtn: [takePaymentbtn, settakePaymentbtn],
          validNewCustBtn: [newCustValidBtnHidden, setnewCustValidBtnHidden],
          customerId: [customerIdQ, setcustomerIdQ],
          customerQsrc: [customerSrc, setcustomerSrc],
          agrQ: [agrId, setagrId],
          inventory: [selectedinventoryNumber, setselectedinventoryNumber],
          reInstateCheck: [isFromCustPage, setisFromCustPage],
          setsaleLeadCoCreate,
          saleLeadCoCreate
        }}
      >
        {props.children}
      </customerValidationContext.Provider>
    </div>
  );
}
