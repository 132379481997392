/* eslint-disable prettier/prettier */
import React from "react";
import { Grid } from "@rentacenter/racstrap";
import { globalStyles } from './../../../jsStyles/globalStyles';
import {
  ContactInfoHeaderProps,
  EmployerDetailsProps,
} from "../../../interface/globalInterface";
import ContactInfoHeader from "../ContactInfoHeader";
import EmploymentDetails from "./EmploymentDetails";

export default function EmploymentInformation(props: {
  value: EmployerDetailsProps;
}) {
  const classes = globalStyles();
  const contentHeading: ContactInfoHeaderProps = {
    heading: "Employer's Information",
    popupHeading: null,
    popupFunctionality: null,
    params: null,
    infoIcon: true,
    infoIconContent: 'Recent paystub and 1 contact information for supervisor'
  };

  return (
    <Grid container className={`${classes.pt05p}`}>
      <Grid container> 
        <ContactInfoHeader value={contentHeading} />
        <EmploymentDetails value={props.value} />
      </Grid>
    </Grid>
  );
}
