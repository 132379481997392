/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { createContext, useState, useEffect } from 'react';
export const FeatureFlagContext = createContext<any>('');
export function FeatureFlagContextProvider(props: any) {
  const [featureFlagDetails, setFeatureFlagDetails] = useState({});
  const [racExchangeFuncFlag, setRacExchangeFuncFlag] =
    useState<boolean>(false);
  return (
    <div>
      <FeatureFlagContext.Provider
        value={{
          featureFlagDetails,
          setFeatureFlagDetails,
          racExchangeFuncFlag,
          setRacExchangeFuncFlag,
        }}
      >
        {props.children}
      </FeatureFlagContext.Provider>
    </div>
  );
}
export function MakeFeatureFlagObjFn(storeValue: any) {
  const tempFeatureFlagObj: any = {};
  for (let i = 0; i < storeValue?.length; i++) {
    tempFeatureFlagObj[storeValue[i]?.paramKeyName] = storeValue[i]?.paramValue;
  }
  console.log('tempFeatureFlagObj', tempFeatureFlagObj);
  return tempFeatureFlagObj;
}
