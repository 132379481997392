/* eslint-disable */
import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";
import accordianOpenIcon from '../../../src/assets/images/Accordion-down.svg';
import accordianCloseIcon from '../../../src/assets/images/Accordion-close.svg';

export const manageCustomerStyles = () => {
    const UseClasses = makeStyles((theme) => ({

        breadcrumbStyles: {
            padding: '0px 35px',
            display: 'flex',
            alignItems: 'center',

            '& p': {
                color: `${RACCOLOR.NORMAL_BLUE} !important`,
                padding: '0px 2px'
            },
            textDecoration: 'none'
        },

        ractabsStyle: {

            '& .Mui-selected': {
                '& .RAC-CUSTOMER--MuiTab-wrapper': {
                    color: '#0d6efd'
                },
            },

            '& div': {

                '& .RAC-CUSTOMER--MuiTabs-flexContainer': {
                    backgroundColor: 'white'
                },

            },
            '& .RAC-CUSTOMER--MuiTab-wrapper': {
                color: 'black'
            },
            '& #full-width-tabpanel-0': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-1': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-2': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-3': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-4': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-5': {
                width: '100%',
                padding: '1rem'
            },
            '& #full-width-tabpanel-6': {
                width: '100%',
                padding: '1rem'
            },

        },

        mb100: {
            marginBottom: '100px'
        },

        mb8: {
            marginBottom: theme.typography.pxToRem(8),
        },

        mb16: {
            marginBottom: theme.typography.pxToRem(16),
        },

        mt24: {
            marginTop: theme.typography.pxToRem(24),
        },

        px2: {
            paddingRight: theme.typography.pxToRem(8),
            paddingLeft: theme.typography.pxToRem(8),
        },

        title: {
            color: '#212529',
            fontFamily: 'OpenSans-bold',
            fontSize: '16px',
            marginBottom: '10px',
        },

        mb120: {
            marginBottom: '120px',
        },

        card: {
            borderRadius: theme.typography.pxToRem(16),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
        },

        w100: {
            width: '100%',
        },

        textCenter: {
            textAlign: 'center',
        },

        mt16: {
            marginTop: theme.typography.pxToRem(16),
        },

        pt0: {
            paddingTop: 0,
        },

        fixTableHead: {
            overflowY: 'auto',
            maxHeight: '205px',
            '& th': {
                position: 'sticky',
                top: 0,
                backgroundColor: '#ffffff',
                fontFamily: 'OpenSans-semibold',
                zIndex: 1,
                padding: '0.9375rem 0.2rem',
                whiteSpace: 'nowrap',
            },
            '& tbody': {
                '& tr': {
                    position: 'sticky',
                    top: 10,
                    backgroundColor: '#ffffff',
                },
                '& td': {
                    fontFamily: 'OpenSans-semibold',
                    padding: '0.9375rem 0.2rem',
                    color: '#4A5174',
                },
            },
        },

        fixTableHeight300: {
            maxHeight: '300px',
        },

        fixTableHeight400: {
            maxHeight: '400px',
        },

        GridLoader: {
            textAlign: 'center',
            marginBottom: theme.typography.pxToRem(20),
            fontSize: theme.typography.pxToRem(16),
            color: `${RACCOLOR.RICH_BLACK}`,
            margin: '30px 0px',
        },

        lineBreak: {
            whiteSpace: 'nowrap',
        },

        customerTablecellbgcolor: {
            backgroundColor: `${RACCOLOR.WHITE}`,
        },

        pointerEvent: {
            pointerEvents: 'none',
            opacity: '0.6',
        },

        agrementGridMC: {
            '& > tbody > tr': {
                background: 'transparent',
            },
            '& td': {
                padding: '10px 5px',
            },
        },

        txtStart: {
            textAlign: 'start',
        },

        pl40: {
            paddingLeft: '32px!important',
            color: '#212529',
            fontSize: '16px',
            fontFamily: 'OpenSans-bold',
            marginBottom: '10px',
        },

        agreementCategory: {
            color: '#212529',
            fontSize: '16px',
            fontFamily: 'OpenSans-bold',
            marginBottom: '10px',
        },

        accordionIconGrid: {
            backgroundImage: `url(${accordianOpenIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '23px',
            cursor: 'pointer',
            width: '23px',
            height: '23px',
            display: 'block',
            float: 'left',
            marginTop: '1px',
        },

        mR8: {
            marginRight: theme.typography.pxToRem(8),
        },

        accordionGridClose: {
            backgroundImage: `url(${accordianCloseIcon})`,
            color: 'blue'
        },

        paymentIssue: {
            '& td': {
                backgroundColor: '#ffefef',
            },
            '& td:first-child': {
                borderTopLeftRadius: '7px',
                borderBottomLeftRadius: '7px',
            },
            '& td:last-child': {
                borderTopRightRadius: '7px',
                borderBottomRightRadius: '7px',
            },
        },

        agreementRow: {
            borderBottom: '2px solid white',
        },

        pl28: {
            paddingLeft: '28px!important',
        },

        racpadAgrLink: {
            borderLeft: '3px solid transparent',
            paddingLeft: '10px',
            borderRadius: '2px',
        },

        racpadPaymentInActive: {
            borderColor: '#b1adac',
        },

        racpadLink: {
            color: '#2468ff',
            fontSize: '14px',
            textDecoration: 'none',
            cursor: 'pointer',
        },

        racpadPaymentFails: {
            position: 'relative',
            '&:before': {
                content: '""',
                height: '20px',
                width: '3px',
                background: '#fd6a63',
                position: 'absolute',
                left: 0,
                top: 0,
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        },

        racpadClubLink: {
            color: '#000000',
            fontSize: '14px',
            textDecoration: 'none',
        },

        racpadPaymentSuccess: {
            position: 'relative',
            '&:before': {
                content: '""',
                height: '20px',
                width: '3px',
                background: '#56e0d8',
                position: 'absolute',
                left: 0,
                top: 0,
                borderTopRightRadius: '2px',
                borderBottomRightRadius: '2px',
            },
        },

        focforLinkPaymentIssue: {
            outline: 'none',
        },

        badgeContainer: {
            padding: '1px 13px',
            borderRadius: '10px',
            backgroundColor: '#ddf8ed',
            color: '#10523e',
            marginLeft: ' 5px',
        },

        textRight: {
            textAlign: 'right',
        },

        pointerEvents: {
            pointerEvents: 'none',
            cursor: 'default'
        },

        positionRelative: {
            position: 'relative'
        },

        tooltipcss: {
            height: '35px',
            marginLeft: '4px',
            marginBottom: '-2px',
            top: '0',
            positon: 'absolute',
        },

        toolTipStyle: {
            top: '-45px',
            left: '0',
            backgroundColor: '#ECF0F3',
            color: 'black'
        },

        arrowStyle: {
            color: '#ECF0F3'
        },

        iconWidth1: {
            width: '35px',
        },

        pointer: {
            cursor: 'pointer'
        },

        blur: {
            opacity: '0.5',
        },

        exchangeInProgress: {
            color: '#fcd209',
            fontWeight: 600,
            float: 'left',
        },

        custDigit: {
            width: '87%',
            '& input': {
                textAlign: 'right',
            },
            borderBottomLeftRadius: '0px',
            borderTopLeftRadius: '0px',
        },

        currencyDollarField: {
            width: '13%',
            paddingTop: '7px',
            paddingBottom: '6px',
            paddingLeft: theme.typography.pxToRem(10),
            paddingRight: theme.typography.pxToRem(10),
        },

        foc: {
            '&:focus': {
                borderColor: '#80bdff',
                boxShadow: '1px 1px 6px 3px #80bdff',
            },
        },

    }));
    const classes = UseClasses();
    return classes;
}