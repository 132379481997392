/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
export const ProductType = "RPDCUSTOMERS";
import { dropdown } from "../racstrapComponent/manageCustomer/ManageCustomerTypeScripts";
export enum ProductPermissions {
  CUSTOMER = "CUSTOMER",
}

export const gridheadfields = [
  "Phone Numbers",
  "Ext",
  "Best Time To Call",
  "Phone Type",
  "Phone Instructions",
  "Is Active",
  "Is Primary",
];

export const headerPrimAdd = [
  "Radio",
  "Address Line 1",
  "Address Line 2",
  "Zip",
  "City",
  "State",
  "Store#",
  "Agreement Count",
];

export const bindHeaderAddressHistory = [
  "Type",
  "Agreement #",
  "Store #",
  "Address 1",
  "Address 2",
  "Zip",
  "City",
  "State",
  "Last Modified",
  "Last Modified By",
];

export const agreementGridHeader = [
  "Agreement #",
  "Description",
  "Type",
  "# Payments",
  "Total Cost",
  "Schedule",
  "Next Due Date",
  "Amount Due",
  "Status",
  "Sub Status",
  "Action",
];
export const agreementHistoryHeader = [
  "Agreement #",
  "Description",
  "Type",
  "Store",
  "# Payments",
  "Total Cost",
  "Schedule",
  "Next Due Date",
  "Amount Due",
  "Sub Status",
];
export const SearchGridHeader = [
  "",
  "",
  "Last Name",
  "First Name",
  "MI",
  "Suffix",
  "Date of Birth",
  "Address",
  "City",
  "State",
  "Zip Code",
  "Phone#",
  "DE",
  "Category",
  "Pending/Active Agr #",
];
export const CoCustomerHeader = [
  "",
  "Last Name",
  "First Name",
  "Date of Birth",
  "Address",
  "Phone#",
  "#Active Agreements",
];
export const API_ERROR_MESSAGE = "Unable to fetch the data";
export const PHONE_ERROR_MESSAGE = "Please enter valid phone number";
export const INVALID_ZIP = "Invalid Zip";
export const FIELD_ERROR = "Required";
export const howLongObj: dropdown[] = [
  {
    value: "0",
    label: "Select",
  },
  {
    value: "1",
    label: "0 to 1 year",
  },
  {
    value: "2",
    label: "1 to 5 years",
  },
  {
    value: "3",
    label: "5+ years",
  },
];
export const stateCC: dropdown[] = [
  {
    value: "0",
    label: "Select",
  },
];
export const relationShipObj: dropdown[] = [
  {
    value: "0",
    label: "Select",
  },
  {
    value: "FRI",
    label: "Friend",
  },
  {
    value: "REL",
    label: "Relative",
  },
  {
    value: "SPO",
    label: "Spouse",
  },
];
export const buildVechicleYear = () => {
  const dt = new Date();
  const year = dt.getFullYear() - 50;
  const yearList: dropdown[] = [];
  for (let i = dt.getFullYear(); i > year; i--) {
    if (i == dt.getFullYear()) {
      yearList.push({
        value: "0",
        label: "Select",
      });
    }
    yearList.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return yearList;
};
export const DEHistoryHeader = [
  "DE Date",
  "Status",
  "Approval Amount",
  "Weekly Approval Amount",
  "Source",
  "DE Expiry Date",
];
