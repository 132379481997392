/* eslint-disable */
import { GetAgreement, ClubInfoResponse, AgrHistoryEventId, GetAgreementByIdResponse } from "../../../../interface/globalInterface";

export const getClass = (agreementStatusProp: string, classes: any) => {
    let className = classes.racpadAgrLink;
    if (agreementStatusProp === 'InActive') {
        className += ` ${classes.racpadPaymentInActive} ${classes.racpadLink}`;
    } else if (agreementStatusProp === 'PaymentIssue') {
        className += ` ${classes.racpadPaymentFails} ${classes.racpadLink}`;
    } else if (agreementStatusProp === 'club') {
        className += ` ${classes.racpadClubLink}`;
    } else {
        className += ` ${classes.racpadPaymentSuccess} ${classes.racpadLink}`;
    }
    return className;
};

// For formatting the next due date
export const nextDueDayWithDate = (date: string) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date(date);
    const index = today.getDay();
    return days[index] !== undefined && days[index] !== null
        ? days[index] + ', ' + date : date;
};

// For truncating the string
export const truncString = (str: string, max: number, add: string) => {
    add = add || '...';
    return str !== undefined && str !== null && typeof str === 'string' && str.length > max
        ? str.substring(0, max) + add : str;
};

// Function for returning the class styles for different table columns
export const getTableStyles = (value: (GetAgreement | ClubInfoResponse), column: string, classes: any) => {
    let cellStyle = '';
    if (!value && column !== 'accordion') {
        cellStyle = `${classes.textCenter}`
    }
    else if (column == 'numberOfPayments' && value) {
        cellStyle = `${classes.textRight}`
    }
    else if (column == 'totalCost' && value) {
        cellStyle = `${classes.lineBreak} ${classes.textRight}`
    }
    else if ((column == 'paymentSchedule' || column == 'agreementSubstatus') && value) {
        cellStyle = `${classes.lineBreak}`
    }
    else if (column == 'nextDueDate' && value) {
        cellStyle = `${classes.lineBreak} ${classes.txtStart}`
    }
    else if (column == 'amountDue' && value) {
        cellStyle = `${classes.lineBreak} ${classes.textCenter}`
    }
    else if (column == 'accordion') {
        if (value.isReinstate === true) {
            cellStyle = `${classes.pl40}`
        } else {
            cellStyle = `${classes.agreementCategory}`
        }
    }
    return cellStyle;
}

// For filtering the agreement based in the status (Active, Inactive, Pending, etc)
export const filterAgreement = (agreementStatusProp: string, agreementInfo: (GetAgreement | ClubInfoResponse)[]) => {
    let agreements: (GetAgreement | ClubInfoResponse)[] = [];
    if (agreementInfo && agreementInfo.length > 0) {
        agreements = agreementInfo.filter((value: (GetAgreement | ClubInfoResponse)) =>
            value.agreementStatus == agreementStatusProp);
        if (agreementStatusProp == 'InActive') {
            agreements = agreementInfo.filter((value: (GetAgreement | ClubInfoResponse)) =>
                value.agreementStatus != 'Active' &&
                value.agreementStatus != 'Pending' &&
                value.agreementStatus != 'Preliminary' &&
                value.agreementStatus != 'Preliminary Paid' &&
                value.agreementStatus != 'Preliminary Refunded' &&
                value.agreementStatus != 'Preliminary Inventory' &&
                value.isReinstate === false
            );
        } else if (agreementStatusProp == 'Reinstatable') {
            agreements = agreementInfo.filter((value: (GetAgreement | ClubInfoResponse)) => value.isReinstate === true);
        }
        else if (agreementStatusProp == 'Pending') {
            agreements = agreementInfo.filter((value: (GetAgreement | ClubInfoResponse)) =>
                value.agreementStatus == 'Pending' || value.agreementStatus == 'Preliminary' ||
                value.agreementStatus == 'Preliminary Paid' || value.agreementStatus == 'Preliminary Refunded' ||
                value.agreementStatus == 'Preliminary Inventory'
            )
        }
    }
    return agreements;
};

// Function used to add isReinstate key in the object of overall agreement details array based on reInstated value and featureFlag
export const checkAgreementReinstate = (customerAgreements: (GetAgreement | ClubInfoResponse)[], storeReinstatementAllowed: boolean) => {
    const agr = [];
    for (let index = 0; index < customerAgreements.length; index++) {
        const agreement: (GetAgreement | ClubInfoResponse) = customerAgreements[index];
        if (agreement.reInstated == '1' && storeReinstatementAllowed) {
            agreement.isReinstate = true;
        } else {
            agreement.isReinstate = false;
        }
        agr.push(agreement);
    }
    return agr;
};

// Validation for Agreement Exchange
export function validateForExchange(agreementStatus: string, closeDate: string, currentDueDate: string | undefined,
    agreementOpenDate: string, isEppAgreement: string, agreementType: string, isExchangeEnabled: boolean,
    returnPeriod: string, exchangePeriod: string, moment: any) {
    if (!isExchangeEnabled || isEppAgreement) {
        return false;
    }
    const today = moment().format('YYYY-MM-DD');
    let sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(7, 'days');
    if (returnPeriod) {
        sevenDaysAgo = moment(today, 'YYYY-MM-DD').subtract(returnPeriod, 'days');
    }
    let sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(6, 'months').format('YYYY-MM-DD');
    if (exchangePeriod) {
        sixMonthsLater = agreementOpenDate && moment(agreementOpenDate, 'YYYY-MM-DD').add(exchangePeriod, 'months').format('YYYY-MM-DD');
    }
    if ((closeDate && (moment(closeDate).format('YYYY-MM-DD') >= sevenDaysAgo.format('YYYY-MM-DD'))) && (agreementStatus == 'Agreement Returned To Store (Repossession)')) {
        return true;
    }
    if ((agreementStatus === 'Active' && agreementType == 'Rental Agreement (RTO)')) {
        if (currentDueDate && moment(currentDueDate).format('YYYY-MM-DD') >= today) {
            if (sixMonthsLater && today <= sixMonthsLater) {
                return true;
            }
        }
    }
    return false;
}

export const formatCurrency = (amount: string) => `$ ${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

const formatNextDueDate = (nextDueDate: string, value: any, classes: any, racExchangeFuncFlag: boolean) => {
    if (value.isExchangeAgreement && racExchangeFuncFlag && value.agreementStatus === 'Active') {
        return <span className={classes.exchangeInProgress}>Exchange In Progress</span>;
    }
    return nextDueDate ? nextDueDayWithDate(nextDueDate) : '-';
};

export const formatValue = (column: string, value: any, classes: any, racExchangeFuncFlag: boolean) => {
    switch (column) {
        case 'Description':
            return value ? truncString(value, 30, '...') : '-';
        case 'agreementType':
            return value ? truncString(value, 13, '...') : '-';
        case 'totalCost':
        case 'amountDue':
            return value ? formatCurrency(value) : '-';
        case 'nextDueDate':
            return formatNextDueDate(value.nextDueDate, value, classes, racExchangeFuncFlag);
        default:
            return value ? value : '-';
    }
};

// Helper function to process feature flags
export function processFeatureFlags(featureFlagInfo: AgrHistoryEventId[],
    setExchangeEnabled: React.Dispatch<React.SetStateAction<boolean>>,
    setExchangePeriod: React.Dispatch<React.SetStateAction<string>>,
    setReturnPeriod: React.Dispatch<React.SetStateAction<string>>,
    setRacExchangeFuncFlag: React.Dispatch<React.SetStateAction<boolean>>
) {
    if (featureFlagInfo.length > 0) {
        featureFlagInfo.forEach(flag => {
            switch (flag.paramKeyName) {
                case "AgreementExchange":
                    setExchangeEnabled(Number(flag.paramValue) > 0);
                    break;
                case "AgreementExchangePeriod":
                    setExchangePeriod(flag.paramValue || '');
                    break;
                case "AgreementExchangeReturnPeriod":
                    setReturnPeriod(flag.paramValue || '');
                    break;
                case "RACExchange":
                    if (flag.paramValue === '1') {
                        setRacExchangeFuncFlag(true);
                    }
                    break;
                default:
                    break;
            }
        });
    }
}

// Helper function to process agreement data
export function processAgreementData(agreementInfo: GetAgreementByIdResponse, activeAgreementInfo: GetAgreementByIdResponse,
    setGetAgrbyIdresponse: any, setagreementInfo: React.Dispatch<React.SetStateAction<(GetAgreement | ClubInfoResponse)[]>>, storeReinstatementAllowed: boolean
) {

    if (activeAgreementInfo?.agreement) {
        setGetAgrbyIdresponse(activeAgreementInfo.agreement);
    }

    const agreements = agreementInfo?.agreement || [];

    // Merge club info responses into agreements
    if (agreementInfo?.clubInfoResponse?.length > 0) {
        agreementInfo.clubInfoResponse.forEach((clubInfo: ClubInfoResponse) => {
            agreements.push(clubInfo);
        });
    }

    // Add isReinstate key to the agreements
    const agrRes: (GetAgreement | ClubInfoResponse)[] = checkAgreementReinstate(agreements, storeReinstatementAllowed);
    setagreementInfo(agrRes);

    return agrRes;
}

// Helper function to set customer and agreement info
export function processSplitAgrInfo(activeAgreementInfo: GetAgreementByIdResponse, activeAgreements: (GetAgreement | ClubInfoResponse)[], setSplitCoCustomerAvailble: React.Dispatch<React.SetStateAction<boolean>>,
    setsplitEligibleAgrs: React.Dispatch<React.SetStateAction<(GetAgreement | ClubInfoResponse)[]>>,
    setShowSplit: React.Dispatch<React.SetStateAction<boolean>>) {
    if (activeAgreementInfo.coCustomerId) {
        setSplitCoCustomerAvailble(true);
    }
    setsplitEligibleAgrs(activeAgreements);
    if(activeAgreements?.length > 0){
        setShowSplit(true)
    }
}

// checkbox functionality
export const CheckboxClick = (agreement_id: string, isReinstate: boolean, e: any,
    setagrCheckBoxId: React.Dispatch<React.SetStateAction<{ id: string; checkBox: boolean; }>>,
    setcheckAction: React.Dispatch<number[] | string>) => {
    setagrCheckBoxId({ id: agreement_id, checkBox: e.target.checked });
    if (e.target.checked === true && isReinstate === true) {
        setcheckAction(agreement_id);
    } else {
        setcheckAction('');
    }
};