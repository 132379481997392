/* eslint-disable */
import {
  Box,
  Grid,
  makeStyles,
  RACBadge,
  RACBadgecard,
  RACTextbox,
  RACToggle,
  Typography,
} from "@rentacenter/racstrap";
import React, { ChangeEvent, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  textCenterSSN: {
    "& input": {
      textAlign: "center",
    },
  },
  errorField:{
    color: "#ef0000",
    fontSize: "0.8125rem",
    fontFamily: "OpenSans-regular",
  }
}));

const EMPTY_STRING: string = "";
interface SSNFieldInterface {
  value: string;
  disable?: boolean;
  required?: boolean;
  unmaskSSN: boolean;
  errorMessage?: string;
  onChange: (changedValue: string) => any;
}

export function SSNField(props: SSNFieldInterface) {
  const { value, disable, required, unmaskSSN, errorMessage, onChange } = props;

  const [ssnOne, setSsnOne] = useState<string>(
    value ? value.slice(0, 3) : EMPTY_STRING
  );
  const [ssnTwo, setSsnTwo] = useState<string>(
    value ? value.slice(3, 5) : EMPTY_STRING
  );
  const [ssnThree, setSsnThree] = useState<string>(
    value ? value.slice(5) : EMPTY_STRING
  );
  const classes = useStyles();

  useEffect(() => {
    setSsnOne(value ? value.slice(0, 3) : EMPTY_STRING);
    setSsnTwo(value ? value.slice(3, 5) : EMPTY_STRING);
    setSsnThree(value ? value.slice(5) : EMPTY_STRING);
  }, [value]);

  const handleOnChange = (value: string, fieldName: string): void => {
    try {
      let ssn1: string = ssnOne;
      let ssn2: string = ssnTwo;
      let ssn3: string = ssnThree;
      value = value.replace(/\D/g, EMPTY_STRING);

      if (fieldName === "ssnOne") {
        if (value.length === 3) {
          document.getElementById("ssnTwo")?.focus();
        }
        setSsnOne(value);
        ssn1 = value;
      } else if (fieldName === "ssnTwo") {
        if (value.length === 2) {
          document.getElementById("ssnThree")?.focus();
        }
        setSsnTwo(value);
        ssn2 = value;
      } else if(fieldName==='ssnThree') {
        setSsnThree(value);
        ssn3 = value;
      }
      const formedSSn = ssn1 + ssn2 + ssn3;
      onChange(formedSSn);
    } catch (error: any) {
      console.log("Error in Ssn handleOnChange: ", error?.message);
    }
  };

  const handleSsnTwoKeyDown = (key: any) => {
    try {
      if (key === "Backspace" && ssnTwo.length === 0) {
        document.getElementById("ssnOne")?.focus();
      }
    } catch (error: any) {
      console.log("Error in SSN", error?.message);
    }
  };

  const handleSsnThreeKeyDown = (key: string) => {
    try {
      if (key === "Backspace" && ssnThree.length === 0) {
        document.getElementById("ssnTwo")?.focus();
      }
    } catch (error: any) {
      console.log("Error in SSN", error?.message);
    }
  };

  return (
    <>
      <Grid container key={'SSNTitleContainer'} id={'SSNTitleContainer'}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={'SsnTitleLabel'} id={'SsnTitleLabel'}>
          <Typography component={"label"} key={'SsnTitle'} id={'SsnTitle'}>
            Social Security#/ ITIN#
            {required ? (
              <Typography 
              component={"span"} 
              style={{ color: "red" }} 
              key={'ssnMandatory'} 
              id={'ssnMandatory'}>
                {" "}
                *
              </Typography>
            ) : null}
          </Typography>
        </Grid>

        <Grid 
        container 
        spacing={2} 
        key={'SsnInputContainer'} 
        id={'SsnInputContainer'}>
          <Grid 
          item 
          xs={4} 
          sm={4} 
          md={4} 
          lg={4} 
          xl={4} 
          key={'SsnOneContainer'} 
          id={'SsnOneContainer'}>
            <RACTextbox
              type={unmaskSSN ? "text" : "password"}
              id="ssnOne"
              disabled={disable}
              placeHolder={"xxx"}
              maxlength={3}
              className={`${classes.textCenterSSN}`}
              value={ssnOne}
              OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(event.target.value, "ssnOne");
              }}
              key={'SsnOneField'}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={3} xl={3} key={'SsnTwoContainer'} id={'SsnTwoContainer'}>
            <RACTextbox
              type={unmaskSSN ? "text" : "password"}
              id="ssnTwo"
              disabled={disable}
              maxlength={2}
              placeHolder={"xx"}
              className={classes.textCenterSSN}
              value={ssnTwo}
              OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(event.target.value, "ssnTwo");
              }}
              OnKeydown={(event: KeyboardEvent) => {
                handleSsnTwoKeyDown(event.key);
              }}
              key={'SsnTwoField'}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={'SsnThreeContainer'} id={'SsnThreeContainer'}>
            <RACTextbox
              type={"text"}
              id="ssnThree"
              disabled={disable}
              maxlength={4}
              placeHolder={"xxxx"}
              className={classes.textCenterSSN}
              value={ssnThree}
              OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleOnChange(event.target.value, "ssnThree");
              }}
              OnKeydown={(event: KeyboardEvent) => {
                handleSsnThreeKeyDown(event.key);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={'SsnErrorField'} id={'SsnErrorField'}>
          <Box
           className={classes.errorField}
            key={'SsnErrorMessage'} 
            id={'SsnErrorMessage'}
          >
            {errorMessage ? errorMessage : EMPTY_STRING}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
