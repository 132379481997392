/* eslint-disable */
/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppRoute } from '../config/route-config';
import { GetSalesLeadExpirationDays } from '../api/user';
import CustomerUpdate from '../components/manageCustomer/ManageCustomer';
import SearchResultRacstrap from '../racstrapComponent/customerComponent/SearchCustomerResult';
import { FormRacstrap } from '../racstrapComponent/customerComponent/customerValidation';
import { ManageCustomerInfoRacstrap } from '../racstrapComponent/manageCustomer/ManageCustomer';
import {
  FeatureFlagContext,
  MakeFeatureFlagObjFn,
} from '../context/FeatureFlagContext';
import { DeliveryContextData } from '../context/DeliveryContext';
import { UpdateCustomer } from '../CustomerRefactor/components/updateCustomer/UpdateCustomer';
import { UpdateCustomerContext, UpdateCustomerContextProvider } from '../CustomerRefactor/context/UpdateCustomerContext';
import ScreenLoader from '../CustomerRefactor/components/sharedComponents/ScreenLoader';
export const routesTestId = 'routesTestId';

export const Routes = () => {
  const { setFeatureFlagDetails, featureFlagDetails } = useContext(FeatureFlagContext);
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    const featureFlagCall = async () => {
      setLoader(true);
      const StoreId = window.sessionStorage.getItem('storeNumber')
      const featureFlagReq = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: ['CustomerOrder', 'SplitAgreement', 'AgreementTransfer', 'PromoAgreement', 'DiscountedAgreement', 'EnableGoogleAddressValidation', 'RewardsEligibilityAmount',
          'CustomerRewards', 'CustomerVerificationOptimization', 'Verification_Expiry_Days', 'LaunchIntellicheck', 'IdentityIntellicheckVerification', 'IntellicheckTimer', 'IntellicheckDocumentSides', 'IntellicheckPassScoreLimit'],
      };

      let FeatureFlagResponse
      if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
        FeatureFlagResponse = await GetSalesLeadExpirationDays(
          featureFlagReq
        );
      }
      setLoader(false);
      // eslint-disable-next-line no-console
      console.log('FeatureFlagResponse', FeatureFlagResponse);
      if (FeatureFlagResponse) {
        const FeatureFlagObj = MakeFeatureFlagObjFn(
          FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails
        );
        console.log('FeatureFlagObj', FeatureFlagObj);
        setFeatureFlagDetails(FeatureFlagObj);
      }
    };
    featureFlagCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div data-testid={routesTestId}>
      {loader ? <ScreenLoader /> : null}
      <DeliveryContextData>
        <Switch>
          <Route exact path={AppRoute.Index}>
            <Redirect to={AppRoute.SearchResult} />
          </Route>
          {/* <Route exact path={AppRoute.Placeholder1}>
            <Placeholder1 />
          </Route>
          <Route exact path={AppRoute.Placeholder2}>
            <Placeholder2 />
          </Route> */}
          {/* <Route path={AppRoute.Customer}>
            <CustomerRoute />
          </Route> */}
          <Route
            exact
            path={AppRoute.SearchResult}
            key="/customer/searchcustomer"
            component={SearchResultRacstrap}
          />
          <Route
            exact
            path={AppRoute.UpdateCustomer}
            key="/customer/updateCustomer"
            component={CustomerUpdate}
          />
          <Route
            exact
            path={AppRoute.CreateCustomer}
            key="/customer/createcustomer"
            component={FormRacstrap}
          />
          {/* <Route
            exact
            path={AppRoute.CreateCustomerInfo}
            key="/createcustomer/customervalidation"
            component={Info}
          /> */}
          <Route
            exact
            path={AppRoute.GetCustomerById}
            key="/customer/createcustomer/:customerId/:pageType"
            component={ManageCustomerInfoRacstrap}
          />

          {featureFlagDetails?.CustomerVerificationOptimization == 1 ? (
            <UpdateCustomerContextProvider>
              <Route
                exact
                path={"/customer/update/:customerId/:pageType"}
                component={UpdateCustomer}
              />
            </UpdateCustomerContextProvider>
          ) : featureFlagDetails?.CustomerVerificationOptimization == 0 ? (
            <Route
              exact
              path={AppRoute.UpdateCustomerById}
              key="/customer/update/:customerId/:pageType"
              component={ManageCustomerInfoRacstrap}
            />
          ) : <></>}
          {/*Racstrap */}
          <Route
            exact
            path={AppRoute.SearchResultRacstrap}
            key="/customer/searchcustomer"
            component={SearchResultRacstrap}
          />
          <Route
            exact
            path={AppRoute.CreateCustomerRacstrap}
            key="/customer/searchcustomer"
            component={FormRacstrap}
          />
          <Route
            exact
            path={AppRoute.UpdateCustomerByIdRacstrap}
            key="/customer/searchcustomer"
            component={ManageCustomerInfoRacstrap}
          />{" "}
          <Route
            exact
            path={AppRoute.GetCustomerByIdRacstrap}
            key="/customer/searchcustomer"
            component={ManageCustomerInfoRacstrap}
          />
        </Switch>
      </DeliveryContextData>
    </div>
  );
};
