/* eslint-disable */

export default function validateAddressFn(response: any, addressDr?: any, onlyZipStateCountry?: boolean, request?: any) {
    // const verdict= response.result.verdict;
    const address = response.result.address;
    const confirmedComponents = ["postal_code", "locality", "administrative_area_level_1"];
    const confirmationCompLevel = ['CONFIRMED', 'UNCONFIRMED_BUT_PLAUSIBLE'];

    const validateAddressComponent = () => {
        // Filter address components that are confirmed
        const filteredComponents = address.addressComponents.filter((item: any) => confirmedComponents.includes(item.componentType));

        // Check if every filtered component meets the confirmation criteria
        return filteredComponents.every((item: any) => confirmationCompLevel.includes(item.confirmationLevel));
    };

    const formatgoooleAddress = () => {
        const state = address.addressComponents.filter((item: any) => {
            return item.componentType == 'administrative_area_level_1' ? true : false
        })
        address.postalAddress.administrativeArea = state[0].componentName.text ? state[0].componentName.text : request.Addresses.StateProvince;
        return {
            message: '',
            status: true,
            suggestedAddress: address.formattedAddress,
            postalAddress: address.postalAddress
        }
    }

    const formatAddressFromAddressDr = () => {
        const addressDoctorResponse = addressDr.Addresses;
        const formattedAddress = [
            addressDoctorResponse.StreetAddress,
            addressDoctorResponse.Building,
            addressDoctorResponse.Unit,
            `${addressDoctorResponse.City}, ${addressDoctorResponse.StateProvince}, ${address.postalAddress.postalCode}`
        ].filter(Boolean).join(', ');

        address.formattedAddress = formattedAddress;
        address.postalAddress.addressLines = [
            addressDoctorResponse.Building,
            addressDoctorResponse.Unit,
            addressDoctorResponse.StreetAddress
        ].filter(Boolean);
    }

    try {
        const unconfirmedComponentValidation = address.unconfirmedComponentTypes &&
            address.unconfirmedComponentTypes?.some((item: any) => { return confirmedComponents.includes(item) });

        if (onlyZipStateCountry) {
            if (unconfirmedComponentValidation && !validateAddressComponent()) {
                return {
                    status: false,
                    message: 'Address could not be corrected. Please enter the valid address.'
                }
            }
            else if (!(unconfirmedComponentValidation && !validateAddressComponent()) && (addressDr?.Addresses &&
                (addressDr.Addresses.MatchCode == '1' || addressDr.Addresses.MatchCode == '2'))) {
                formatAddressFromAddressDr();
                return {
                    message: '',
                    status: true,
                    matchCode: addressDr.Addresses.MatchCode,
                    suggestedAddress: address.formattedAddress,
                    postalAddress: address.postalAddress
                }
            }
            else if (!addressDr && !(unconfirmedComponentValidation && !validateAddressComponent())) {
                return formatgoooleAddress();
            }
            else {
                formatAddressFromAddressDr();
                return {
                    status: true,
                    suggestedAddress: address.formattedAddress,
                    postalAddress: address.postalAddress
                }
            }
        }
        else {
            if (!(unconfirmedComponentValidation && !validateAddressComponent())) {
                return formatgoooleAddress();
            }
            else
                return {
                    status: false,
                    message: 'Address could not be corrected. Please enter the valid address.'
                }
        }
    }
    catch (e: any) {
        console.log('Error occured in the validate Address fn', e)
        return {
            status: false,
            message: 'Address validation currently Unavailable.'
        }
    }
}
