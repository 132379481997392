import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  RACButton,
  RACTextbox,
  RACModalCard,
} from '@rentacenter/racstrap';
import Modal from 'react-modal';
import RACLogo from '../../assets/images/RAC-Logo.svg';
import Erroricon from '../../assets/images/erroricon.png';
import PinField from 'react-pin-field';
import {
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
} from '../../api/user';
export type PropsInterface = {
  setTwoFactorCancelClick?: any;
  setTwoFactorCompleted?: any;
  setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
};
export default function SecondFactor(props: PropsInterface) {
  const { setTwoFactorCancelClick, setTwoFactorCompleted, setEmployeeDetails } =
    props;
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [openPinModal, setOpenPinModal] = useState(false);
  // eslint-disable-next-line prefer-const
  let [otp, setotp] = useState('');
  const [loader, setloader] = useState(true);
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup'
  );
  const [somethingtwo, setsomethingtwo] = React.useState('modal fade');
  const [InvalidPin, setInvalidPin] = React.useState<any>();
  //   const [InvalidPin1, setInvalidPin1] = React.useState(false);
  const [Continue, setContinue] = React.useState(true);
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };
  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };
  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setloader(false);
    UserValidate(result);
  };
  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        setTwoFactorCompleted !== undefined
          ? setTwoFactorCompleted(true)
          : null;
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
  };
  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };
  useEffect(() => {
    setsomethingone('modal fade show expandPopup');
    setsomethingtwo('modal fade');
    const payload = {
      module: window.localStorage.getItem('moduleName') || props.moduleName,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      currentRole:
        window.localStorage.getItem('currentRole') || props.currentRole,
    };
    const PageLoad = async () => {
      const result = await getprofileaccess(payload);
      setloader(false);
      setRole(result.data.role);
      popupSelect(result);
    };
    PageLoad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };
  const openModelCancelClick = () => {
    setErrorLine(false);
    setOpenModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);
  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    setEmployeeDetails !== undefined
      ? setEmployeeDetails(userResponse.data)
      : null;
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    const result = await getPinDetails(payload);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setTwoFactorCompleted !== undefined ? setTwoFactorCompleted(true) : null;
    }
  };
  const noClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };
  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };
  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };
  const pinValue = (event: any) => {
    otp = event;
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onCloseClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
  };
  const onCloseSecondfacClick = () => {
    setOpenModal(false);
  };
  //eslint-disable-next-line sonarjs/no-identical-functions
  const onPinCloseClick = () => {
    setOpenPinModal(false);
    setTwoFactorCancelClick !== undefined
      ? setTwoFactorCancelClick(true)
      : null;
  };
  const openModalContent = () => {
    return (
      <div>
        <Grid className="text-center">
          <img className="logo-responsive" src={RACLogo} alt="Logo" />
        </Grid>
        {errorline === true ? (
          <Grid className="float-left my-2 mx-1">
            <Typography className="racLoginAlert">
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className="mt-2">
          <Grid item>
            <Typography
              variant="body2"
              component="label"
              style={{ fontFamily: 'OpenSans-semibold' }}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              className="racSecFactorTextbox"
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event: any) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item className="p-2">
            <Typography
              variant="body2"
              component="label"
              style={{ fontFamily: 'OpenSans-semibold' }}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event: any) =>
                secondfactorEnter(event, userID, password)
              }
              isCurrency={false}
              className="racSecFactorTextbox"
              type="password"
              value={password}
              OnChange={(event: any) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid className="floatRight mt-5 mb-3">
          <RACButton
            className="btn  btn-secondary me-2"
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          {userID !== '' && password !== '' ? (
            <RACButton
              className="racSearch racSearchPrimary me-2"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton className="racSearch disabled racSearchPrimary me-2">
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  const openAlertModalContent = () => {
    return (
      <div>
        <Grid className="text-center">
          <img className="racErrorIcon" src={Erroricon} alt="Logo" />
          <Typography className="racErrorFunction">
            The user you entered can not authorize the use of this function.
          </Typography>
        </Grid>
        <Grid className="text-center">
          <RACButton
            autoFocus
            className="racSearch racSearchPrimary me-2"
            onClick={okClick}
          >
            OK
          </RACButton>
        </Grid>
      </div>
    );
  };
  const openPinModalContent = () => {
    return (
      <div>
        <Grid container className="racPinContainer">
          <Grid item md={12}>
            <Typography className="racPinLabel">PIN</Typography>
          </Grid>
          <Grid onKeyPress={pinEnter}>
            <PinField
              id="pininput"
              className="racPinField"
              length={4}
              validate="0123456789"
              type="password"
              autoFocus={true}
              onComplete={(event: any) => pinValue(event)}
              onChange={(event: any) => otpchange(event)}
            />
          </Grid>
          {InvalidPin == 400 ? (
            <Grid>
              <Typography id="InvalidPin" className="racPinError">
                Invalid Pin{' '}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
        <Grid className="floatRight mt-5 mb-3">
          <RACButton className="btn btn-secondary me-2" onClick={noClick}>
            Cancel
          </RACButton>
          <RACButton
            className="racSearch racSearchPrimary me-2"
            disabled={Continue}
            onClick={continueClick}
          >
            Continue
          </RACButton>
        </Grid>
      </div>
    );
  };
  return (
    <div>
      <Modal
        isOpen={loader}
        className={loader ? somethingone : somethingtwo}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" data-testid="modalrender">
          <div>
            <div className="loader">
              <CircularProgress />
            </div>
          </div>
        </div>
      </Modal>
      <RACModalCard
        isOpen={openModal}
        onClose={onCloseSecondfacClick}
        maxWidth={'xs'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openSecondModal}
        closeIcon={true}
        onClose={onCloseClick}
        maxWidth={'sm'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openAlertModalContent()}
      ></RACModalCard>
      <RACModalCard
        isOpen={openPinModal}
        closeIcon={true}
        onClose={onPinCloseClick}
        maxWidth={'xs'}
        title={'Enter PIN to continue'}
        //eslint-disable-next-line react/no-children-prop
        children={openPinModalContent()}
      ></RACModalCard>
    </div>
  );
}
