import React, { useContext } from 'react';
import { Grid, ListItem, List, Box } from '@rentacenter/racstrap';
import { customerValidationContext } from '../../context/customerContext/customerContext';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function HeaderWizards(props: any) {
  const classes = props.styles();
  const { wizardClass, cName, customerId, customerQsrc } = useContext(
    customerValidationContext
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageClass, setpageClass] = wizardClass;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [className, setclassName] = cName;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerIdQ, setcustomerIdQ] = customerId;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSrc, setcustomerSrc] = customerQsrc;

  //  console.log(pageClass)
  return (
    <Grid container className={classes.fullWidth}>
      {/*Tab starts here*/}
      <Grid item id="smartwizard" className={classes.fullWidth}>
        <Grid item id="smartwizard" className={classes.swThemeDefault}>
          <Grid item className={classes.swThemeDefaultContainer}>
            <List className={classes.smartWizard}>
              <ListItem className={classes.smartWizardList}>
                <a className={classes.smartWizardListLinkActive}>
                  <Box component="span" className={classes.borderActive}>
                    1
                  </Box>
                  {customerIdQ == null || customerSrc !== null
                    ? 'Customer Validation'
                    : 'Co-Customer Validation'}
                </a>
              </ListItem>
              <ListItem className={classes.smartWizardList}>
                <a className={classes.smartWizardListLinkInActive}>
                  <Box component="span" className={classes.borderDefault}>
                    2
                  </Box>
                  Customer Information
                </a>
              </ListItem>
              <ListItem className={classes.smartWizardList}>
                <a className={classes.smartWizardListLinkInActive}>
                  <Box component="span" className={classes.borderDefault}>
                    3
                  </Box>
                  Employment Information
                </a>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
