/* eslint-disable  */
import moment from 'moment';



export const generateOrderForm = (
  phoneNumberProps: any,
  customerInformation: any,
  editInputBox: any,
  empProps: any,
  cocustomerDetail: any,
  isGovtNumOnFocus: any,
  residences: any,
  referenceProps: any,
  updatePrimaryAddress: any

) => {



  const customerInfoDetail = customerInfoTemplate(phoneNumberProps, customerInformation, editInputBox, updatePrimaryAddress);

  const employerInfoDetail = employerInfoTemplate(empProps);
  let coRenterDetail;
  let coRenterEmpDetail;
  if (cocustomerDetail?.customerId) {
    //corenterInfo: any, customerInformation:any, isGovtNumOnFocus:any
    coRenterDetail = corenterInfoTemplate(cocustomerDetail, customerInformation, isGovtNumOnFocus);
    coRenterEmpDetail = corenterEmpInfoTemplate(cocustomerDetail)
  }
  const residenceInfoDetail = residenceInfoTemplate(residences);
  const additionalInfoDetail = additionalInfoTemplate()
  const referenceInfoDetail = referenceInfoTemplate(referenceProps);
  const notesDetail = notesTemplate()
  const statementSigningDetail = statementSigningTemplate(cocustomerDetail, customerInformation);



  let html: any = header + customerInfoDetail + employerInfoDetail
  if (cocustomerDetail?.customerId) {
    html += coRenterDetail || ''
    html += coRenterEmpDetail || ''
  }
  html += residenceInfoDetail
  html += additionalInfoDetail
  html += referenceInfoDetail
  html += notesDetail
  html += statementSigningDetail

  const footer = () => {
    return (`</div></body></html>`)
  }
  html += footer()
 
  return html;
}

const header = `<html lang="en">
<head>
<meta charset="UTF-8">
<title>Rental Order Form</title>
</head>
<body style="font-family: Arial; background-color: #ECF0F3;">
  <div style="width: 100%;">
      <div style="width:100%;float:left;border-bottom:1px solid #DFDFDF">
          <div style="width:13%;float:left; margin-bottom: 24px;">
            <svg  xmlns="http://www.w3.org/2000/svg" width="57.025" height="57.029" viewBox="0 0 57.025 57.029">
      <g id="logo" transform="translate(-8029.456 2416.974)">
        <g id="Group_3871" data-name="Group 3871" transform="translate(8029.456 -2416.975)">
          <path id="Path_13080" data-name="Path 13080" d="M8086.477-2387.991a28.505,28.505,0,0,1-29.134,28.04,28.523,28.523,0,0,1-27.887-28.471,28.527,28.527,0,0,1,29.189-28.545A28.51,28.51,0,0,1,8086.477-2387.991Z" transform="translate(-8029.456 2416.974)" fill="#ec1c24"/>
          <path id="Path_13081" data-name="Path 13081" d="M8208.249-2257.5a20,20,0,0,1-20.3,19.984,19.91,19.91,0,0,1-19.756-20.127,19.988,19.988,0,0,1,20.359-19.83A19.916,19.916,0,0,1,8208.249-2257.5Z" transform="translate(-8159.704 2286.006)" fill="#034693"/>
          <path id="Path_13082" data-name="Path 13082" d="M8505.023-1590.292c-.229.729-.447,1.394-.639,2.067a.381.381,0,0,1-.427.317c-.245,0-.2-.186-.239-.315-.271-.979-.529-1.962-.809-2.939-.051-.185,0-.224.164-.245.479-.06.478-.065.589.389a15.6,15.6,0,0,0,.447,1.535q.236-.729.471-1.458c.049-.155.09-.313.146-.465.034-.087.024-.21.167-.228s.161.1.207.183c.276.5.548,1,.826,1.495a.5.5,0,0,0,.206.265c.034-.584.063-1.147.1-1.71.01-.109-.068-.279.089-.32a2.236,2.236,0,0,1,.575-.051c.078,0,.042.1.038.154-.083,1.065-.163,2.13-.252,3.195-.007.093.036.25-.147.245-.155,0-.323.1-.441-.129C8505.765-1588.957,8505.4-1589.593,8505.023-1590.292Z" transform="translate(-8473.925 1642.261)" fill="#fefefe"/>
          <path id="Path_13083" data-name="Path 13083" d="M8079.692-1963.319c-.675,0-1.345-.011-2.013.007-.187.005-.27-.024-.252-.22a1.121,1.121,0,0,0,0-.274c-.029-.177.063-.2.209-.2,1-.032,1.992-.076,2.987-.1.115,0,.3-.1.343.068a.5.5,0,0,1-.116.556c-.656.594-1.294,1.208-1.937,1.815-.064.06-.155.1-.158.151.656,0,1.315.03,1.97-.011.418-.026.262.257.3.434.049.21-.049.275-.264.28-.977.022-1.952.067-2.928.1-.13,0-.324.1-.376-.071a.493.493,0,0,1,.13-.552c.546-.488,1.072-1,1.606-1.5Z" transform="translate(-8074.484 1991.849)" fill="#fefefe"/>
          <path id="Path_13084" data-name="Path 13084" d="M8533.744-2367.658c-.111.66-.229,1.31-.324,1.963-.033.213-.107.285-.316.207a1.249,1.249,0,0,0-.27-.045c-.107-.014-.154-.053-.131-.179.186-1.062.361-2.124.545-3.187.014-.078.02-.178.137-.162.174.024.357,0,.475.206.287.5.6.991.9,1.485.207.336.414.671.666,1.077.113-.656.215-1.239.314-1.821a.2.2,0,0,1,.006-.061c.057-.123-.021-.338.131-.368a1.239,1.239,0,0,1,.537.074c.121.033.064.158.051.241q-.249,1.5-.514,3.007c-.018.087.029.251-.1.251-.162,0-.355.019-.473-.142-.086-.115-.152-.241-.227-.363-.42-.685-.838-1.371-1.26-2.054C8533.865-2367.574,8533.854-2367.646,8533.744-2367.658Z" transform="translate(-8501.906 2371.993)" fill="#fefefe"/>
          <path id="Path_13085" data-name="Path 13085" d="M8834.236-1853.793c-.687-.239-1.309-.462-1.937-.668-.189-.063-.259-.127-.176-.338.2-.5.1-.43.552-.274q1.326.456,2.647.928c.109.039.322.01.3.178s-.056.371-.291.459c-.646.243-1.283.514-1.924.774l-.865.351c.684.24,1.313.474,1.948.679.214.069.23.149.157.34-.16.407-.148.409-.554.266-.835-.293-1.671-.581-2.5-.879-.151-.055-.418-.047-.422-.215a.57.57,0,0,1,.442-.54c.771-.284,1.531-.6,2.293-.912C8834-1853.682,8834.085-1853.725,8834.236-1853.793Z" transform="translate(-8782.117 1889.562)" fill="#fefefe"/>
          <path id="Path_13086" data-name="Path 13086" d="M8582.958-1603.317a1.841,1.841,0,0,1,1.886-1.832,1.794,1.794,0,0,1,1.851,1.815,1.9,1.9,0,0,1-1.921,1.855A1.824,1.824,0,0,1,8582.958-1603.317Z" transform="translate(-8549.092 1654.82)" fill="#fefefe"/>
          <path id="Path_13087" data-name="Path 13087" d="M8858.625-1919.078a1.831,1.831,0,0,1-1.788,1.894,1.812,1.812,0,0,1-1.875-1.834,1.841,1.841,0,0,1,1.823-1.9A1.811,1.811,0,0,1,8858.625-1919.078Z" transform="translate(-8804.455 1951.266)" fill="#fefefe"/>
          <path id="Path_13088" data-name="Path 13088" d="M8860.518-1968.418a.905.905,0,0,1,.557-1.247,3.108,3.108,0,0,0,.746-.575c-.359,0-.719-.011-1.077,0-.209.009-.315-.02-.3-.272.028-.534-.05-.44.431-.444.937-.008,1.873,0,2.809-.015.187,0,.261.032.262.244a9.02,9.02,0,0,1-.01,1.065.975.975,0,0,1-.839.944.966.966,0,0,1-1.109-.6c-.085-.187-.146-.107-.238-.038Z" transform="translate(-8809.595 1998.255)" fill="#fefefe"/>
          <path id="Path_13089" data-name="Path 13089" d="M8261.121-1657.18a.6.6,0,0,1,.081.011.916.916,0,0,1,.66,1.094,6.048,6.048,0,0,0-.009.987l.1.022c.2-.31.418-.613.6-.933.111-.2.213-.207.368-.07a.782.782,0,0,0,.179.117c.149.072.175.148.075.3-.566.858-1.12,1.723-1.676,2.587-.065.1-.111.19-.255.087-.314-.224-.655-.413-.955-.655a.933.933,0,0,1-.326-1.192.948.948,0,0,1,1.125-.614c.213.032.159-.082.153-.178C8261.2-1656.133,8261.161-1656.649,8261.121-1657.18Z" transform="translate(-8245.766 1703.667)" fill="#fefefe"/>
          <path id="Path_13090" data-name="Path 13090" d="M8117.735-2145.386c-.1.213-.189.384-.259.563a.37.37,0,0,1-.349.242c-.475.056-.947.124-1.418.187-.015.138.1.134.157.166.234.123.479.225.709.353.108.061.32.063.3.209a1.724,1.724,0,0,1-.237.522c-.04.073-.105.021-.159-.005q-1.425-.7-2.85-1.39c-.115-.056-.148-.115-.085-.235.182-.34.333-.7.536-1.025a.952.952,0,0,1,1.148-.466.955.955,0,0,1,.737,1.022c0,.207.074.176.213.148C8116.685-2145.2,8117.193-2145.286,8117.735-2145.386Z" transform="translate(-8108.369 2162.875)" fill="#fefefe"/>
          <path id="Path_13091" data-name="Path 13091" d="M8088.657-1902.572c.054.276.09.5.145.723.036.145-.064.2-.141.28-.343.342-.689.681-1.034,1.021l.046.074c.353-.07.708-.13,1.058-.214.228-.055.345.007.356.245.008.182.183.39-.2.453-.973.162-1.937.371-2.9.571-.2.041-.292,0-.318-.221-.035-.281-.112-.558-.16-.838a1.034,1.034,0,0,1,1.117-1.33.8.8,0,0,1,.7.412c.094.164.149.143.256.021C8087.925-1901.764,8088.273-1902.146,8088.657-1902.572Z" transform="translate(-8082.054 1934.045)" fill="#fefefe"/>
          <path id="Path_13092" data-name="Path 13092" d="M8392.5-1595.986a1.344,1.344,0,0,1,1.437,1.712c-.115.629-.27,1.251-.39,1.879-.077.4-.32.129-.488.136-.211.009-.241-.115-.2-.3.139-.615.268-1.231.394-1.848a.709.709,0,0,0-.824-.922.681.681,0,0,0-.561.606c-.126.586-.24,1.174-.371,1.759-.035.161.014.412-.321.323-.427-.114-.431-.1-.357-.451.129-.616.243-1.236.385-1.849A1.212,1.212,0,0,1,8392.5-1595.986Z" transform="translate(-8368.67 1646.217)" fill="#fefefe"/>
          <path id="Path_13093" data-name="Path 13093" d="M8097.864-1843.1a1.351,1.351,0,0,1,1.276,1.114,1.221,1.221,0,0,1-.587,1.493c-.712.347-1.481.555-2.223.835-.091.035-.137.02-.187-.079-.237-.474-.227-.511.261-.689.564-.205,1.131-.4,1.688-.622a.643.643,0,0,0,.436-.7.744.744,0,0,0-.562-.648.813.813,0,0,0-.476.07c-.595.209-1.19.414-1.779.64-.189.073-.278.048-.318-.154-.02-.1-.07-.19-.1-.287-.021-.066-.052-.139.054-.173a19.746,19.746,0,0,1,2.426-.8A.524.524,0,0,1,8097.864-1843.1Z" transform="translate(-8091.246 1878.213)" fill="#fefefe"/>
          <path id="Path_13094" data-name="Path 13094" d="M8095.234-2096.387a1.371,1.371,0,0,1-1.877,1.413c-.6-.183-1.2-.387-1.8-.563-.2-.058-.242-.141-.157-.317a.57.57,0,0,0,.041-.146c.036-.228.136-.284.367-.2.586.2,1.179.385,1.771.567a.694.694,0,0,0,.853-.206.7.7,0,0,0-.343-1.111c-.614-.215-1.231-.425-1.859-.59-.31-.081-.233-.227-.168-.416s.064-.389.384-.271c.609.227,1.239.4,1.855.605A1.2,1.2,0,0,1,8095.234-2096.387Z" transform="translate(-8087.569 2117.765)" fill="#fefefe"/>
          <path id="Path_13095" data-name="Path 13095" d="M8265.424-2300.857c-.122-.673-.242-1.319-.358-1.967-.1-.551-.188-1.1-.294-1.651-.029-.143,0-.24.129-.3s.217-.231.4-.1c1.01.7,2.023,1.4,3.061,2.112l-.165.11c-.456.3-.462.309-.9-.011a.305.305,0,0,0-.426-.008c-.254.187-.525.351-.793.516-.127.078-.2.159-.145.319.03.085.016.187.052.269.11.257-.012.4-.225.506C8265.655-2301.007,8265.556-2300.937,8265.424-2300.857Z" transform="translate(-8250.364 2311.777)" fill="#fefefe"/>
          <path id="Path_13096" data-name="Path 13096" d="M8472.3-2367.8c-.083.008-.122.013-.161.014-.567.012-.572.014-.788-.525a.276.276,0,0,0-.31-.214c-.324.017-.651.016-.976,0a.278.278,0,0,0-.312.208c-.234.57-.241.567-.95.511.238-.568.475-1.126.71-1.684s.491-1.136.718-1.712c.075-.189.2-.167.342-.167s.247-.019.316.154C8471.348-2370.085,8471.819-2368.963,8472.3-2367.8Z" transform="translate(-8441.923 2374.158)" fill="#fefefe"/>
          <path id="Path_13097" data-name="Path 13097" d="M8649.97-2331.421c-.03.326-.253.451-.551.3-.2-.1-.412-.178-.6-.3s-.237-.034-.31.119c-.213.46-.217.457.235.683l.271.136c.344.172.37.319.13.623-.078.1-.132.053-.205.016-.245-.122-.5-.235-.736-.364-.13-.069-.2-.074-.277.079-.336.642-.341.639.3.961l.161.084c.318.159.353.364.115.634-.069.079-.117.042-.184.01-.489-.244-.979-.49-1.473-.728-.112-.054-.115-.106-.062-.211.47-.944.94-1.89,1.4-2.838.055-.11.094-.156.223-.09.478.246.962.482,1.441.721C8649.918-2331.546,8649.991-2331.527,8649.97-2331.421Z" transform="translate(-8608.983 2337.508)" fill="#fefefe"/>
          <path id="Path_13098" data-name="Path 13098" d="M8356.483-2351.814a1.09,1.09,0,0,1,1.093.818.973.973,0,0,1-.452,1.206,1.514,1.514,0,0,1-.42.175c-.268.06-.373.163-.238.448.1.218.138.468.229.693.069.17.025.222-.141.273-.462.143-.459.151-.605-.3-.283-.879-.561-1.76-.854-2.637-.065-.2-.042-.278.174-.333A9.737,9.737,0,0,1,8356.483-2351.814Z" transform="translate(-8335.141 2355.801)" fill="#fefefe"/>
          <path id="Path_13099" data-name="Path 13099" d="M8309.047-2332.286a1.035,1.035,0,0,1-.647.771c-.457.241-.456.242-.232.723a2.1,2.1,0,0,0,.155.33c.186.261.1.39-.176.507-.223.092-.326.11-.44-.15-.4-.9-.823-1.79-1.246-2.679-.076-.158-.083-.237.1-.311.32-.127.621-.3.942-.427C8308.3-2333.829,8309.054-2333.29,8309.047-2332.286Z" transform="translate(-8289.466 2338.708)" fill="#fefefe"/>
          <path id="Path_13100" data-name="Path 13100" d="M8454.434-1586.236c-.065,1-.127,1.919-.186,2.842-.007.109,0,.2-.16.184-.414-.042-.834-.048-1.242-.116a1.013,1.013,0,0,1-.837-1.161.988.988,0,0,1,1.021-.992c.217-.01.5.121.632-.049.108-.142.055-.411.071-.623,0-.061,0-.122.008-.183.021-.428.021-.418.447-.388.223.016.31.091.248.317A.456.456,0,0,0,8454.434-1586.236Z" transform="translate(-8426.15 1637.533)" fill="#fefefe"/>
          <path id="Path_13101" data-name="Path 13101" d="M8302.158-1628.089l.5.239c.541.258.528.251.806-.267.128-.236.109-.36-.15-.448a4.481,4.481,0,0,1-.5-.228c-.315-.15-.351-.375-.111-.637.071-.078.126-.037.186-.008.485.231.968.467,1.456.7.118.056.136.113.078.233q-.691,1.425-1.367,2.856c-.059.124-.121.127-.234.071q-.725-.356-1.456-.695c-.106-.05-.115-.1-.077-.209.153-.415.175-.427.563-.244a4.24,4.24,0,0,1,.49.24c.2.126.3.111.4-.12.192-.432.207-.426-.232-.632-.11-.052-.219-.107-.328-.158-.3-.141-.339-.4-.094-.638A.481.481,0,0,1,8302.158-1628.089Z" transform="translate(-8284.636 1677.657)" fill="#fefefe"/>
          <path id="Path_13102" data-name="Path 13102" d="M8141.011-2193.686c-.048.076-.074.119-.1.162q-.167.256-.335.51c-.241.364-.235.354.108.6.2.142.338.188.46-.085.073-.165.206-.3.291-.463.116-.219.226-.288.46-.114.181.135.247.222.086.411a3.924,3.924,0,0,0-.285.431c-.216.335-.215.335.118.554.438.287.438.287.721-.155a3.067,3.067,0,0,0,.211-.334c.11-.234.223-.278.45-.106.18.135.241.21.093.414-.287.395-.549.807-.806,1.222-.09.145-.159.137-.284.055q-1.295-.859-2.6-1.707c-.114-.074-.149-.127-.06-.259.3-.435.581-.882.872-1.324.043-.065.084-.159.187-.094C8140.739-2193.88,8140.875-2193.78,8141.011-2193.686Z" transform="translate(-8132.756 2207.634)" fill="#fefefe"/>
          <path id="Path_13103" data-name="Path 13103" d="M8792.475-2193.007c-.134.093-.272.2-.419.292-.118.075-.132-.055-.169-.109-.295-.428-.58-.862-.878-1.287-.089-.128-.086-.192.053-.284q1.285-.851,2.559-1.724c.144-.1.212-.091.307.057.275.427.557.853.853,1.265.175.24-.049.267-.16.352s-.229.218-.356-.014-.31-.455-.457-.687c-.077-.123-.138-.157-.279-.065-.468.3-.471.292-.167.748a1.868,1.868,0,0,0,.188.277c.217.225.142.351-.1.493-.171.1-.259.113-.354-.069a3.59,3.59,0,0,0-.235-.356c-.291-.437-.291-.437-.728-.147-.331.221-.332.221-.107.556.142.211.283.422.427.633C8792.451-2193.066,8792.454-2193.056,8792.475-2193.007Z" transform="translate(-8744.356 2209.693)" fill="#fefefe"/>
          <path id="Path_13104" data-name="Path 13104" d="M8832.927-2115.545c-.027.136-.178.113-.271.167-.193.115-.333.1-.383-.155a1.786,1.786,0,0,0-.1-.255c-.234-.59-.231-.579-.836-.363-.216.078-.263.163-.16.366s.161.44.269.645.045.275-.156.366c-.256.117-.355.072-.444-.2-.144-.432-.325-.853-.506-1.272-.067-.161-.062-.242.121-.313q1.436-.555,2.858-1.135c.159-.065.214-.026.268.116.186.483.368.968.577,1.442.089.2-.028.236-.158.26s-.3.267-.413-.04c-.094-.257-.213-.506-.3-.764-.06-.177-.134-.2-.307-.133-.485.2-.487.187-.294.674C8832.768-2115.946,8832.843-2115.757,8832.927-2115.545Z" transform="translate(-8781.303 2136.029)" fill="#fefefe"/>
          <path id="Path_13105" data-name="Path 13105" d="M8217.789-1675.954a1.358,1.358,0,0,1-1.262-1.089c-.025-.193.1-.206.22-.2s.3-.212.355.076a.648.648,0,0,0,.384.515.372.372,0,0,0,.429-.043.333.333,0,0,0,.042-.421c-.1-.2-.21-.4-.3-.6a.955.955,0,0,1,.2-1.248,1.1,1.1,0,0,1,1.325-.174,1.348,1.348,0,0,1,.761,1.293c-.01.325-.289.156-.44.21s-.175-.063-.172-.19a.752.752,0,0,0-.374-.69.434.434,0,0,0-.563.042.4.4,0,0,0-.1.523c.122.235.258.464.36.707A.907.907,0,0,1,8217.789-1675.954Z" transform="translate(-8205.078 1724.422)" fill="#fefefe"/>
          <path id="Path_13106" data-name="Path 13106" d="M8771.569-1725.118c.063.169,0,.217-.207.2a.706.706,0,0,0-.646.34.458.458,0,0,0,.006.57.406.406,0,0,0,.545.115c.238-.115.466-.25.705-.36a.832.832,0,0,1,.965.13.931.931,0,0,1,.311.992,1.377,1.377,0,0,1-1.026.989c-.159.047-.25-.009-.23-.165.016-.133-.256-.331.083-.4a.61.61,0,0,0,.531-.517.306.306,0,0,0-.429-.362c-.208.078-.4.2-.6.3a1,1,0,0,1-1.411-.26,1.141,1.141,0,0,1,.068-1.461,1.311,1.311,0,0,1,.872-.51C8771.545-1725.571,8771.544-1725.576,8771.569-1725.118Z" transform="translate(-8724.63 1767.846)" fill="#fefefe"/>
          <path id="Path_13107" data-name="Path 13107" d="M8687.471-2304.4a1.428,1.428,0,0,1,1.242.935c.054.171-.041.215-.151.218-.133,0-.289.25-.4-.006a.749.749,0,0,0-.442-.473.364.364,0,0,0-.42.08.309.309,0,0,0-.011.419c.127.207.271.4.4.609a.944.944,0,0,1-.014,1.164,1.083,1.083,0,0,1-1.148.455,1.389,1.389,0,0,1-1.105-1.225c-.021-.379.3-.217.461-.284.191-.081.131.113.146.2a.761.761,0,0,0,.37.566.465.465,0,0,0,.615-.05.417.417,0,0,0,.035-.582c-.136-.2-.28-.4-.416-.6A.928.928,0,0,1,8687.471-2304.4Z" transform="translate(-8645.293 2311.289)" fill="#fefefe"/>
          <path id="Path_13108" data-name="Path 13108" d="M8797.032-1760.505a1.128,1.128,0,0,0,.924-1.372,1.27,1.27,0,0,0-1.369-.927c-.6.069-.954.589-.947,1.419-.194-.123-.371-.237-.552-.343-.1-.058-.1-.128-.081-.231a1.867,1.867,0,0,1,1.635-1.54,1.833,1.833,0,0,1,1.873,1.14,1.887,1.887,0,0,1-.6,2.166.282.282,0,0,1-.384.013C8797.384-1760.291,8797.227-1760.381,8797.032-1760.505Z" transform="translate(-8748.16 1803.491)" fill="#fefefe"/>
          <path id="Path_13109" data-name="Path 13109" d="M8852.08-2066.147c.306-.755.163-1.317-.39-1.619a1.269,1.269,0,0,0-1.586.351c-.372.519-.255,1.087.363,1.671-.1.031-.191.063-.282.086-.519.131-.607.1-.8-.421a1.782,1.782,0,0,1,.365-1.981,1.74,1.74,0,0,1,1.965-.439,1.79,1.79,0,0,1,1.191,1.626c.045.445-.022.521-.467.635Z" transform="translate(-8799.082 2089.944)" fill="#fefefe"/>
          <path id="Path_13110" data-name="Path 13110" d="M8643.14-1623.1c.83.108,1.315-.146,1.49-.766a1.269,1.269,0,0,0-.682-1.45c-.577-.258-1.077-.034-1.568.712-.053-.1-.105-.2-.154-.3-.221-.443-.212-.476.2-.783a1.861,1.861,0,0,1,2.118-.091,1.84,1.84,0,0,1,.791,1.938,1.871,1.871,0,0,1-1.6,1.432c-.161.02-.271,0-.338-.171S8643.229-1622.914,8643.14-1623.1Z" transform="translate(-8604.598 1674.45)" fill="#fefefe"/>
          <path id="Path_13111" data-name="Path 13111" d="M8605.482-2345.716c-.5-.674-1.023-.851-1.575-.553a1.275,1.275,0,0,0-.569,1.525c.226.594.776.811,1.574.614.032.1-.033.175-.06.255-.175.521-.228.55-.782.459a1.839,1.839,0,0,1-1.514-1.927,1.81,1.81,0,0,1,1.8-1.69,1.83,1.83,0,0,1,1.191.385c.15.116.215.22.125.407A4.877,4.877,0,0,0,8605.482-2345.716Z" transform="translate(-8567.487 2351.313)" fill="#fefefe"/>
          <path id="Path_13112" data-name="Path 13112" d="M8118.951-1781.232c-.075-.009-.088-.074-.115-.126-.234-.449-.462-.9-.712-1.345-.1-.177.019-.232.124-.255.136-.029.267-.313.421-.034.133.24.259.483.38.728.057.113.106.134.232.079.539-.239.541-.233.266-.759a1.9,1.9,0,0,0-.132-.24c-.143-.193-.064-.273.125-.38.231-.131.346-.117.45.141.094.232.2.576.374.627s.44-.192.666-.307a2.474,2.474,0,0,0,.324-.17c.256-.183.391-.125.53.163.119.244.081.325-.15.44-.88.441-1.747.905-2.619,1.36C8119.062-1781.284,8119.006-1781.259,8118.951-1781.232Z" transform="translate(-8112.664 1822.396)" fill="#fefefe"/>
          <path id="Path_13113" data-name="Path 13113" d="M8082.552-2039.273c-.1-.024-.148.024-.162.125-.018.131-.053.26-.062.391-.011.193-.088.205-.274.19-.323-.026-.373-.146-.306-.444.118-.524.188-1.059.282-1.589.022-.131.018-.324.24-.228.145.062.446-.082.371.305-.1.543-.081.545.461.638.681.118,1.357.253,2.04.355.229.034.272.118.2.312a.179.179,0,0,0-.01.06c.012.3-.125.368-.411.309-.736-.15-1.477-.274-2.216-.407C8082.654-2039.265,8082.6-2039.267,8082.552-2039.273Z" transform="translate(-8078.524 2063.866)" fill="#fefefe"/>
          <path id="Path_13114" data-name="Path 13114" d="M8344.534-1608.627c-.039.112-.064.188-.092.265-.253.728-.5,1.459-.767,2.183-.071.191-.078.3.152.339a.846.846,0,0,1,.144.049c.332.116.392.332.176.612-.07.094-.139.049-.214.023-.594-.209-1.184-.425-1.78-.625-.278-.093-.085-.243-.051-.356s0-.314.247-.225c.192.068.419.254.582.134.135-.1.148-.364.216-.555.222-.622.448-1.241.655-1.867.063-.191.138-.248.319-.153C8344.257-1608.734,8344.424-1608.727,8344.534-1608.627Z" transform="translate(-8322.882 1658.293)" fill="#fefefe"/>
          <path id="Path_13115" data-name="Path 13115" d="M8861.584-2019.078c.093.21-.044.261-.247.284s-.341.035-.368-.239c-.051-.5-.065-.5-.565-.437-.707.089-1.415.166-2.121.266-.21.03-.257-.035-.271-.231-.033-.421-.047-.427.364-.483.716-.1,1.432-.193,2.149-.27.229-.024.35-.072.265-.327-.021-.065,0-.146-.023-.211-.088-.264.064-.292.272-.325.241-.038.318.047.342.272C8861.436-2020.222,8861.512-2019.667,8861.584-2019.078Z" transform="translate(-8807.303 2045.283)" fill="#fefefe"/>
          <path id="Path_13116" data-name="Path 13116" d="M8817.149-2155.279c.013.133-.1.14-.174.178-.707.379-1.413.759-2.126,1.126-.167.086-.225.159-.106.333a3.457,3.457,0,0,1,.256.483c.04.084.169.183.076.262a1.634,1.634,0,0,1-.456.232c-.047.018-.086-.071-.114-.125-.219-.413-.433-.828-.659-1.235-.073-.133-.043-.186.083-.252q1.375-.72,2.742-1.455c.134-.072.218-.08.284.077C8817.011-2155.524,8817.085-2155.4,8817.149-2155.279Z" transform="translate(-8765.816 2171.762)" fill="#fefefe"/>
          <path id="Path_13117" data-name="Path 13117" d="M8405.1-2361.511c.176-.068.136.134.154.22.158.755.312,1.51.448,2.269.044.243.118.366.386.268a2.693,2.693,0,0,1,.356-.079c.376-.081.364-.077.459.3.044.175,0,.231-.165.262-.438.081-.878.172-1.313.269-.167.037-.233-.018-.267-.184q-.292-1.494-.6-2.986c-.032-.15-.036-.259.166-.265A2.386,2.386,0,0,0,8405.1-2361.511Z" transform="translate(-8381.594 2364.917)" fill="#fefefe"/>
          <path id="Path_13118" data-name="Path 13118" d="M8445.665-2364.314c-.029.1.115.25-.031.31a1.1,1.1,0,0,1-.568.062c-.094-.013-.069-.112-.076-.178-.121-1.019-.237-2.039-.361-3.058-.021-.173.026-.247.2-.241a.7.7,0,0,0,.242-.022c.2-.065.248.034.266.2C8445.445-2366.268,8445.556-2365.3,8445.665-2364.314Z" transform="translate(-8419.222 2370.491)" fill="#fefefe"/>
          <path id="Path_13119" data-name="Path 13119" d="M8082.616-1992.4c-.023.473-.023.47-.432.431-.941-.089-1.884-.19-2.828-.253-.423-.028-.2-.3-.227-.476-.035-.21.084-.25.273-.232.982.095,1.966.182,2.948.268C8082.551-1992.649,8082.713-1992.618,8082.616-1992.4Z" transform="translate(-8076.059 2018.882)" fill="#fefdfe"/>
          <path id="Path_13120" data-name="Path 13120" d="M8824.839-1790.675c-.063.128-.128.278-.209.419-.134.234-.265.028-.374-.025-.869-.419-1.729-.857-2.6-1.271-.2-.1-.229-.187-.117-.361a1.417,1.417,0,0,0,.119-.247c.041-.1.094-.134.206-.078.952.476,1.908.946,2.863,1.421C8824.776-1790.793,8824.852-1790.78,8824.839-1790.675Z" transform="translate(-8773.016 1830.487)" fill="#fefefe"/>
          <path id="Path_13121" data-name="Path 13121" d="M8731.1-1653.355a.718.718,0,0,1-.729-.734.713.713,0,0,1,.71-.724.731.731,0,0,1,.753.718A.736.736,0,0,1,8731.1-1653.355Z" transform="translate(-8687.483 1701.445)" fill="#fefdfd"/>
          <path id="Path_13122" data-name="Path 13122" d="M8763.174-2240.009a.707.707,0,0,1,.749.71.727.727,0,0,1-.731.737.718.718,0,0,1-.728-.736A.693.693,0,0,1,8763.174-2240.009Z" transform="translate(-8717.616 2250.836)" fill="#fefdfd"/>
          <path id="Path_13123" data-name="Path 13123" d="M8179.144-1718.042a.7.7,0,0,1-.733-.722.714.714,0,0,1,.733-.727.734.734,0,0,1,.73.742A.73.73,0,0,1,8179.144-1718.042Z" transform="translate(-8169.297 1762.165)" fill="#fefefe"/>
          <path id="Path_13124" data-name="Path 13124" d="M8208.066-2236.1a.727.727,0,0,1-.74.729.74.74,0,0,1-.726-.717.748.748,0,0,1,.728-.75A.74.74,0,0,1,8208.066-2236.1Z" transform="translate(-8195.763 2247.856)" fill="#fefdfd"/>
          <path id="Path_13125" data-name="Path 13125" d="M8260.645-2104.332c.341-.155.666-.3.983-.449a2.616,2.616,0,0,0,.755-.513.96.96,0,0,0,.172-1.2.955.955,0,0,0-1.021-.56,2.21,2.21,0,0,0-.592.141c-.506.185-1.007.386-1.516.561-.216.075-.265.154-.176.383.836,2.2,1.654,4.417,2.492,6.621.1.257.077.359-.2.452-.731.247-1.453.522-2.174.8-.186.073-.239.022-.3-.145q-1.617-4.327-3.247-8.648c-.061-.161-.051-.223.121-.287,2.057-.767,4.1-1.57,6.171-2.3a1.965,1.965,0,0,1,2.177.67,2.655,2.655,0,0,1-.174,3.83c-.308.308-.309.309.048.575.542.406,1.088.812,1.673,1.248.073-.743.146-1.437.206-2.131q.25-2.844.488-5.69a.259.259,0,0,1,.2-.257c.9-.333,1.792-.664,2.681-1.012.173-.067.241.021.335.114.852.849,1.7,1.7,2.559,2.543,1.264,1.229,2.543,2.443,3.8,3.672.183.177.274.164.394-.052.285-.513.285-.5-.163-.878-2.205-1.842-3.047-5.8.469-8.071a10.723,10.723,0,0,1,3.97-1.508c.189-.034.268,0,.331.183.188.536.4,1.066.6,1.6.135.354.134.348-.262.38a6.37,6.37,0,0,0-2.544.688,2.64,2.64,0,0,0-1.54,1.864,2.806,2.806,0,0,0,3.248,3.354,5.577,5.577,0,0,0,2.531-1.22l.372-.274c.254.67.5,1.319.748,1.966.043.111-.006.171-.086.229a8.038,8.038,0,0,1-4.934,1.773.33.33,0,0,0-.351.21,11.752,11.752,0,0,1-4.923,5.191,9.013,9.013,0,0,1-5.422,1.084,8.715,8.715,0,0,1-3.849-1.61,41.8,41.8,0,0,1-3.6-2.933C8260.953-2104.065,8260.808-2104.191,8260.645-2104.332Z" transform="translate(-8241.94 2134.824)" fill="#fed100"/>
          <path id="Path_13126" data-name="Path 13126" d="M8257.176-2181.557c.021.147-.121.136-.2.167-.885.337-1.77.667-2.654,1q-11.019,4.139-22.035,8.284c-.262.1-.354.067-.445-.2-.207-.626-.449-1.243-.7-1.854-.094-.231-.053-.322.184-.41q4.33-1.613,8.652-3.244,8.007-3.007,16.012-6.02c.244-.093.324-.051.4.184.221.633.469,1.257.705,1.885C8257.125-2181.7,8257.148-2181.635,8257.176-2181.557Z" transform="translate(-8218.758 2198.145)" fill="#ec1c24"/>
          <path id="Path_13127" data-name="Path 13127" d="M8604.366-1857.941c-.525.5-1.037,1-1.552,1.485-.142.133-.06.243.035.337a.323.323,0,0,0,.484.055,2.39,2.39,0,0,0,.442-.582c.053-.083.1-.113.2-.062s.148.084.127.206a1.615,1.615,0,0,1-1.13,1.091.665.665,0,0,1-.689-.294,2.016,2.016,0,0,1,.455-2.411.8.8,0,0,1,1.172.077l.5-.533c.042-.045.083-.089.124-.136a.438.438,0,0,1,.4-.179c.1.023.065.217.11.364.105-.123.2-.236.295-.34.192-.2.4-.425.707-.31.277.1.241.4.268.635.043.343.05.691.082,1.035.014.145,0,.288.17.394.148.095.067.382-.1.454-.662.281-.659.28-.705-.435-.021-.315-.042-.63-.087-.942-.02-.145.06-.41-.228-.365a.371.371,0,0,0-.322.443c.043.456.07.915.131,1.368.033.248.011.41-.275.458a3.04,3.04,0,0,0-.432.146c-.072.024-.152.093-.213-.012a.189.189,0,0,1,.006-.253.638.638,0,0,0,.13-.572C8604.43-1857.2,8604.4-1857.577,8604.366-1857.941Z" transform="translate(-8567.024 1893.331)" fill="#fefefe"/>
          <path id="Path_13128" data-name="Path 13128" d="M8384.42-1776.569c-.046-.565-.084-1.092-.135-1.618-.016-.145.046-.379-.208-.361a.355.355,0,0,0-.346.419c.033.4.064.791.106,1.185.062.554.065.554-.462.738-.087.03-.172.064-.259.094-.056.02-.129.057-.164-.006s-.113-.159-.044-.224c.275-.265.152-.591.145-.9s-.048-.582-.075-.894c-.413.4-.811.784-1.206,1.171-.095.092-.176.2-.278.284-.15.127-.2.254-.038.4.145.138.3.215.485.064a2.136,2.136,0,0,0,.442-.578c.063-.1.118-.106.224-.057s.138.11.094.211c-.217.5-.479.951-1.073,1.065a.66.66,0,0,1-.739-.3,2.041,2.041,0,0,1,.447-2.388.792.792,0,0,1,1.095,0c.111.082.145.011.194-.04.169-.176.334-.355.5-.537a.446.446,0,0,1,.423-.191c.129.033.031.219.116.344a5.612,5.612,0,0,1,.468-.508c.406-.306.7-.2.767.3.052.393.078.789.114,1.184.013.176-.017.345.188.472.146.09.058.355-.118.422C8384.874-1776.731,8384.664-1776.658,8384.42-1776.569Z" transform="translate(-8359.198 1818.37)" fill="#fefefe"/>
          <path id="Path_13129" data-name="Path 13129" d="M8700.729-1893.483c.088-.142.154-.27.24-.382a.311.311,0,0,1,.551.022.9.9,0,0,1-.143,1.016c-.229.19-.377.087-.463-.34-.2.107-.2.3-.2.471.006.315.034.63.047.945,0,.121.026.225.166.253.2.041.162.211.176.341s-.128.129-.216.162c-.237.09-.477.173-.716.258-.064.022-.144.074-.185-.018-.025-.059-.091-.144-.036-.2.309-.294.146-.651.142-.983,0-.269-.044-.538-.061-.738-.512.479-1.047.975-1.576,1.476-.147.139-.011.245.074.33a.31.31,0,0,0,.461.041,2.241,2.241,0,0,0,.411-.529c.063-.1.1-.18.256-.109s.119.167.072.266a1.492,1.492,0,0,1-1.081,1.017.657.657,0,0,1-.711-.3,2.032,2.032,0,0,1,.471-2.4.754.754,0,0,1,1.066.012c.126.1.159.007.216-.051.181-.192.352-.4.539-.581S8700.6-1893.695,8700.729-1893.483Z" transform="translate(-8656.843 1926.017)" fill="#fefefe"/>
          <path id="Path_13130" data-name="Path 13130" d="M8339.48-1757.836c-.027-.186-.045-.323.08-.437a.38.38,0,0,0,.092-.341q-.07-.928-.142-1.855a.4.4,0,0,0-.143-.287c-.178-.16-.131-.337.1-.42.371-.136.744-.266,1.118-.4.438-.156.7-.118.889.129a.838.838,0,0,1,.018,1.073.512.512,0,0,0-.043.771c.122.175.127.412.367.531.173.087.108.369-.078.444-.507.2-.5.2-.729-.291-.084-.185-.174-.368-.256-.553-.071-.162-.186-.185-.349-.144-.181.045-.142.172-.136.288.02.325.034.651.073.973a.221.221,0,0,1-.187.285C8339.932-1758.007,8339.715-1757.919,8339.48-1757.836Z" transform="translate(-8320.305 1801.763)" fill="#fefefe"/>
          <path id="Path_13131" data-name="Path 13131" d="M8499.133-1816.013a.492.492,0,0,0,.034.141c.244.387.245.386-.162.529-.114.04-.229.089-.345.119-.078.02-.176.133-.237.007-.046-.093-.08-.229.021-.309a.659.659,0,0,0,.2-.431q.248-1.006.5-2.012c.032-.127.105-.248-.12-.285-.112-.018-.135-.176-.152-.279-.023-.131.117-.11.191-.139.228-.088.463-.156.688-.251.167-.072.241-.043.3.138.226.664.468,1.321.7,1.981.058.159.091.314.287.4.175.072.137.277-.05.349-.256.1-.515.191-.774.274-.068.022-.161.12-.214.036a.3.3,0,0,1-.011-.319,1.767,1.767,0,0,1,.122-.135c.105-.121.023-.245-.026-.341s-.162-.015-.243.01c-.145.046-.284.113-.434.145C8499.194-1816.342,8499.139-1816.205,8499.133-1816.013Z" transform="translate(-8469.688 1855.547)" fill="#fefefe"/>
          <path id="Path_13132" data-name="Path 13132" d="M8564.572-1845.4c.019.358.029.694.055,1.028.012.158-.076.206-.2.259-.156.068-.162-.067-.216-.139s-.114-.169-.181-.246a.324.324,0,0,0-.526-.022,1.83,1.83,0,0,0-.093,2.077.42.42,0,0,0,.675-.089,1.064,1.064,0,0,0,.149-.461c.02-.219.2-.229.348-.284.088-.033.1.041.1.1a1.532,1.532,0,0,1-.95,1.494.775.775,0,0,1-.909-.309,2.344,2.344,0,0,1,.132-2.736.989.989,0,0,1,1.132-.385c.086.032.115,0,.128-.066C8564.256-1845.353,8564.4-1845.364,8564.572-1845.4Z" transform="translate(-8529.837 1880.369)" fill="#fefefe"/>
          <path id="Path_13133" data-name="Path 13133" d="M8453.5-1797.665l.387-.129c.128.258.1.44-.222.5-.11.019-.134.1-.125.205.039.5.072.994.108,1.491.015.2.1.252.275.132.058-.04.111-.1.187-.041s.053.144.024.223a.816.816,0,0,1-.785.472.408.408,0,0,1-.353-.4c-.046-.486-.079-.973-.133-1.459-.013-.115.047-.258-.154-.338-.1-.037-.1-.286.062-.411a.843.843,0,0,0,.372-.572.2.2,0,0,1,.237-.184c.13.005.081.138.1.216A2.3,2.3,0,0,1,8453.5-1797.665Z" transform="translate(-8426.746 1836.04)" fill="#fdfdfe"/>
          <path id="Path_13134" data-name="Path 13134" d="M8675.76-1876.927a.682.682,0,0,1,.187-.046c.086,0,.213-.181.255.042.023.128.106.292-.126.33s-.3.177-.27.41c.053.443.081.89.112,1.335.017.2.091.262.27.142.058-.039.117-.1.19-.046s.049.148.024.225a.833.833,0,0,1-.785.474.45.45,0,0,1-.364-.464c-.037-.414-.061-.83-.115-1.243-.023-.171.1-.4-.179-.508a.3.3,0,0,1,.057-.374.944.944,0,0,0,.408-.619c.024-.155.155-.17.25-.17.116,0,.054.127.066.195A2.149,2.149,0,0,1,8675.76-1876.927Z" transform="translate(-8635.426 1910.452)" fill="#fefefe"/>
          <path id="Path_13135" data-name="Path 13135" d="M8483.815-1786.2a.927.927,0,0,1-.19.572.3.3,0,0,1-.319.132.291.291,0,0,1-.2-.246.888.888,0,0,1,.07-.595c.075-.162.188-.325.383-.293C8483.785-1786.593,8483.8-1786.381,8483.815-1786.2Z" transform="translate(-8455.33 1825.2)" fill="#fcfcfd"/>
          <path id="Path_13136" data-name="Path 13136" d="M8542.329-1807.027a.788.788,0,0,1,.22-.622c.189-.184.386-.135.465.115a.811.811,0,0,1-.041.6c-.081.185-.186.371-.419.32S8542.334-1806.882,8542.329-1807.027Z" transform="translate(-8510.95 1845.035)" fill="#fbfcfd"/>
          <path id="Path_13137" data-name="Path 13137" d="M8757.656-1859.7a.386.386,0,0,1-.378.407.375.375,0,0,1-.405-.393.363.363,0,0,1,.391-.379A.365.365,0,0,1,8757.656-1859.7Z" transform="translate(-8712.366 1894.135)" fill="#dee7f1"/>
          <path id="Path_13138" data-name="Path 13138" d="M8595.659-1592.441a1.2,1.2,0,0,1-1.162-1.209,1.175,1.175,0,0,1,1.137-1.166,1.222,1.222,0,0,1,1.178,1.236A1.151,1.151,0,0,1,8595.659-1592.441Z" transform="translate(-8559.926 1645.119)" fill="#ec1c24"/>
          <path id="Path_13139" data-name="Path 13139" d="M8866.633-1909.078a1.183,1.183,0,0,1,1.2,1.167,1.178,1.178,0,0,1-1.171,1.132,1.207,1.207,0,0,1-1.214-1.163A1.154,1.154,0,0,1,8866.633-1909.078Z" transform="translate(-8814.298 1940.153)" fill="#ec1c24"/>
          <path id="Path_13140" data-name="Path 13140" d="M8892.543-1959.586c-.017.426-.206.63-.548.611a.58.58,0,0,1-.5-.684c.011-.126.065-.152.172-.154.205,0,.409,0,.611-.019C8892.5-1959.854,8892.589-1959.776,8892.543-1959.586Z" transform="translate(-8838.752 1987.805)" fill="#ec2028"/>
          <path id="Path_13141" data-name="Path 13141" d="M8272.074-1618.267c-.1-.075-.212-.146-.312-.232a.447.447,0,0,1-.109-.625.484.484,0,0,1,.632-.214c.356.122.381.2.182.5-.11.171-.223.34-.335.51A.428.428,0,0,1,8272.074-1618.267Z" transform="translate(-8256.758 1668.177)" fill="#ec1d25"/>
          <path id="Path_13142" data-name="Path 13142" d="M8127.984-2134.043c-.016.058-.043.154-.073.25-.059.187-.152.269-.347.139-.108-.073-.246-.1-.35-.178s-.337-.039-.306-.26a.686.686,0,0,1,.574-.51A.543.543,0,0,1,8127.984-2134.043Z" transform="translate(-8120.944 2151.878)" fill="#ec1e26"/>
          <path id="Path_13143" data-name="Path 13143" d="M8095.4-1878.234a.446.446,0,0,1,.246-.462.481.481,0,0,1,.546.029.772.772,0,0,1,.258.566c.011.1-.074.105-.145.119-.169.032-.339.062-.507.1C8095.431-1877.809,8095.4-1877.836,8095.4-1878.234Z" transform="translate(-8091.359 1911.697)" fill="#ec1e26"/>
          <path id="Path_13144" data-name="Path 13144" d="M8277.384-2288.55l1.056.754-.8.525C8277.552-2287.7,8277.473-2288.094,8277.384-2288.55Z" transform="translate(-8262.215 2296.408)" fill="#ec1f26"/>
          <path id="Path_13145" data-name="Path 13145" d="M8489.5-2352.735c.162-.4.31-.775.486-1.222.163.411.3.745.425,1.08.042.109-.011.145-.117.143C8490.047-2352.737,8489.795-2352.735,8489.5-2352.735Z" transform="translate(-8461.356 2357.813)" fill="#ed2129"/>
          <path id="Path_13146" data-name="Path 13146" d="M8369.795-2341a.429.429,0,0,1,.469.344.426.426,0,0,1-.213.54,1.045,1.045,0,0,1-.106.055c-.367.172-.375.168-.493-.208-.021-.068-.041-.135-.062-.2C8369.245-2340.916,8369.3-2340.992,8369.795-2341Z" transform="translate(-8348.526 2345.646)" fill="#ec1d25"/>
          <path id="Path_13147" data-name="Path 13147" d="M8321.347-2322.887a.4.4,0,0,1,.382.254.425.425,0,0,1-.009.485.826.826,0,0,1-.2.181c-.345.227-.354.223-.531-.152a.92.92,0,0,1-.062-.138c-.042-.148-.239-.3-.134-.425A.746.746,0,0,1,8321.347-2322.887Z" transform="translate(-8302.938 2328.644)" fill="#ec1d25"/>
          <path id="Path_13148" data-name="Path 13148" d="M8464.561-1555.973a.325.325,0,0,1-.01.121c-.072.143.132.425-.214.431-.4.007-.658-.163-.682-.456a.463.463,0,0,1,.428-.531C8464.535-1556.473,8464.579-1556.433,8464.561-1555.973Z" transform="translate(-8437.088 1609.084)" fill="#ec1e26"/>
          <path id="Path_13149" data-name="Path 13149" d="M8467.215-1934.764c.022-.355.053-.71.06-1.066a.244.244,0,0,1,.178-.269q1.641-.607,3.277-1.229a.232.232,0,0,1,.308.077q.712.8,1.445,1.572c.113.121.128.192,0,.311a6.875,6.875,0,0,1-5.146,1.894c-.16-.008-.2-.061-.187-.225.038-.354.05-.71.071-1.065Z" transform="translate(-8440.364 1966.701)" fill="#034693"/>
          <path id="Path_13150" data-name="Path 13150" d="M8470.677-1993.526c.616.6,1.23,1.2,1.878,1.834-.64.242-1.239.476-1.843.7-.208.076-.146-.11-.143-.2.018-.63.039-1.259.071-1.888A1.11,1.11,0,0,1,8470.677-1993.526Z" transform="translate(-8443.569 2019.434)" fill="#044693"/>
          <path id="Path_13151" data-name="Path 13151" d="M8610.337-1835.85a.986.986,0,0,1,.149-.933.292.292,0,0,1,.472-.022c.182.164.092.27-.039.391A4.959,4.959,0,0,1,8610.337-1835.85Z" transform="translate(-8574.752 1872.405)" fill="#064895"/>
          <path id="Path_13152" data-name="Path 13152" d="M8388.476-1755.971a.886.886,0,0,1,.1-.846c.166-.224.384-.246.541-.077.107.113.135.217,0,.334C8388.9-1756.371,8388.7-1756.175,8388.476-1755.971Z" transform="translate(-8366.449 1797.384)" fill="#064895"/>
          <path id="Path_13153" data-name="Path 13153" d="M8705.985-1869.755a1.113,1.113,0,0,1,.089-.9c.168-.252.378-.253.564-.019.066.083.088.146,0,.233C8706.428-1870.23,8706.225-1870.007,8705.985-1869.755Z" transform="translate(-8664.545 1904.263)" fill="#074895"/>
          <path id="Path_13154" data-name="Path 13154" d="M8354.546-1751.34a3.985,3.985,0,0,1-.082-.7c-.021-.153.048-.215.184-.258.2-.064.4-.195.532.1a.52.52,0,0,1-.232.715C8354.819-1751.429,8354.684-1751.389,8354.546-1751.34Z" transform="translate(-8334.575 1793.049)" fill="#044794"/>
          <path id="Path_13155" data-name="Path 13155" d="M8513.076-1801.884c.08-.388.147-.725.219-1.061.011-.047.036-.092.07-.183.1.286.182.533.283.77.073.175.067.281-.146.325A3.614,3.614,0,0,0,8513.076-1801.884Z" transform="translate(-8483.485 1840.684)" fill="#074995"/>
          <path id="Path_13156" data-name="Path 13156" d="M8758.588-1858.237c.105-.069-.014-.164.07-.172s.156.066.2.163c-.213.117-.385.077-.469-.147a.275.275,0,0,1,.205-.379c.189-.056.307.057.367.234.016.059.033.132-.043.154a.1.1,0,0,1-.117-.1c0-.151,0-.278-.211-.2s-.07.191-.049.3C8758.549-1858.347,8758.566-1858.3,8758.588-1858.237Z" transform="translate(-8713.767 1892.938)" fill="#3168a7"/>
        </g>
      </g>
    </svg> 
          </div>
          <div style="width:79%; float:left;margin:0px 4%;font-size: 14px; margin-bottom:10px; text-align: center; margin-top:34px;">
            <h4 style="font-size:18px; margin:0px;padding-bottom:5px;">Rental Order Form</h4>
          </div>
      </div>`;


const customerInfoTemplate = (
  phoneNumberProps: any, customerInformation: any, editInputBox: any, updatePrimaryAddress: any
) => {
  const phoneDetail: any = phoneNumberProps.find((el: any) => el.primary == 'Y')
  return (`
  <div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
    <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
      <h5 style="color: black;  margin: 0;">Customer Information</h5>
      <div style="width: 100%; float: left;">
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Last Name</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${customerInformation?.lastName}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Legal First Name</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${customerInformation?.firstName}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">MI</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${customerInformation?.middleInitial}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Preferred First Name</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Date of Birth</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${customerInformation?.dateOfBirth !==
      undefined &&
      customerInformation?.dateOfBirth !==
      null &&
      customerInformation?.dateOfBirth !== ''
      ? buildDateFormat2(
        customerInformation?.dateOfBirth
      ).substring(0, 6) + '****'
      : 'MM/DD/YYYY'}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Social Security #</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${customerInformation?.ssn3 !== undefined &&
      customerInformation?.ssn3 !== null &&
      customerInformation?.ssn3 !== ''
      ? `***-**-${customerInformation?.ssn3}`
      : ''}</span>
        </div>

        <div style="width: 50%; float: left; margin-top: 30px; display: flex; flex-direction: column; height: 90px;">
          <label style="width: 100%; float: left; margin-bottom: 10px;">ID Type:</label>
          <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="State-Id" value="option1" ${validategovtTypeId(customerInformation.governmentIdType, 'ST') ? 'checked' : ''}>
                <label>State Id</label>
            </div>
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="License" value="option2" ${validategovtTypeId(customerInformation.governmentIdType, 'DL') ? 'checked' : ''}>
                <label  for="License">Driver's License</label>
            </div>
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="Passport" value="option3" ${validategovtTypeId(customerInformation.governmentIdType, 'PASS') ? 'checked' : ''}>
                <label  for="Passport">Passport</label>
            </div>
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="National" value="option4" ${validategovtTypeId(customerInformation.governmentIdType, 'NATL') ? 'checked' : ''}>
                <label  for="National">National ID</label>
            </div>  
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="Provided" value="option5" ${validategovtTypeId(customerInformation.governmentIdType, 'NONE') ? 'checked' : ''}>
                <label  for="Provided">Not Provided</label>
            </div>
          </div>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex; height: 90px;">
          <label style="width: 40%; float: left;">Gov. ID #</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000; height: 18px;">${customerInformation?.governmentId !==
      undefined &&
      customerInformation?.governmentId !== null &&
      customerInformation?.governmentId !== ''
      ? customerInformation?.governmentId
        .substring(
          0,
          customerInformation?.governmentId
            .length - 4
        )
        .replace(/[A-Za-z0-9.,-]/g, '*') +
      customerInformation?.governmentId.substring(
        customerInformation?.governmentId
          .length - 4
      )
      : ''}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">State</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${customerInformation?.state}</span>
        </div>

        <div style="width: 50%; float: left; margin-top: 30px; display: flex; height:20px">
          <label style="width: 40%; float: left;">Address</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${editInputBox === true
      ? `${customerInformation?.addressLine1}`
      : updatePrimaryAddress.address}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Apt# /Unit#</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${(customerInformation?.addressLine2 || '')}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">PO Box</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
        </div>

        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">City</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${editInputBox === true
      ? customerInformation?.city
      : updatePrimaryAddress.city}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">State</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${editInputBox === true
      ? customerInformation?.state
      : updatePrimaryAddress.state}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px;">
          <label style="width: 40%; float: left;">ZIP</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${editInputBox === true
      ? customerInformation?.zip?.trim()
      : updatePrimaryAddress?.zip?.trim()}</span>
        </div>

        <div style="width: 50%; float: left; margin-top: 30px; display: flex; height:20px;">
          <input float:"left"; type="checkbox" id="address" value="option1" ${isPrimarySameAsMailing(customerInformation.addressess) ? 'checked' : ''}>
          <label style="width: 90%; float: left;"for="address">Mailing Address Same as Primary</label>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px;">
          <label style="width: 50%; float: left;">Primary Phone Number</label>
          <span style="width: 40%; float: left; border-bottom: 1px solid #000;">${formatPhone(phoneDetail?.phoneNumber)}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
          <label style="width: 40%; float: left;">Ext</label>
          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${(phoneDetail?.extension ? phoneDetail?.extension : '')}</span>
        </div>

        <div style="width: 50%; float: left; margin-top: 30px; display: flex; flex-direction: column; height: 90px">
          <label style="width: 100%; float: left; margin-bottom: 10px;">Type</label>
          <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="State-Id" value="option1" ${phoneDetail.phoneType == 'CELL' ? 'checked' : ''}>
                <label  for="State-Id">Cell Phone</label>
            </div>
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="License" value="option1" ${phoneDetail.phoneType == 'HOME' ? 'checked' : ''}>
                <label  for="License">Home Phone</label>
            </div>
            <div style="width: 50%; float: left; display: inline-block;">
              <input  type="checkbox" id="Passport" value="option1" ${phoneDetail.phoneType == 'WORK' ? 'checked' : ''}>
                <label  for="Passport">Work Phone</label>
            </div>
          </div>
        </div>
        <div style="width: 50%; float: left; margin-top: 30px; display: flex; height: 90px">
          <label style="width: 40%; float: left;">Email</label>
          <span style="width:50%; float: left; border-bottom: 1px solid #000;height:54px; word-break: break-word;">${customerInformation?.emailAddress}</span>
        </div>
      </div>
    </div>
  </div>`)
}
const employerInfoTemplate = (EmpProps: any) => {
  return EmpProps.map((el: any) => {
    return (
      `<div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Employment Information</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Renter's Employer</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.employerName !== undefined ? el?.employerName : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 50%; float: left;">Employer Phone Number</label>
                      <span style="width: 40%; float: left; border-bottom: 1px solid #000;">${el?.employerPhoneNumber !== undefined ? formatPhone(el?.employerPhoneNumber) : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Ext</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 60%; float: left;">Supervisor's First Name</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 60%; float: left;">Supervisor's Last Name</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Renter's Job Title</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 60%; float: left;">Hours Worked From</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">To</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 60px; display: flex; flex-direction: column; height: 90px;">
                      <label style="width: 100%; float: left; margin-bottom: 10px;">Payment Schedule:</label>
                      <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Daily" value="option1" ${el.employerPayschedule == 'DAILY' ? 'checked' : ''}>
                          <label  for="Daily">Daily</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Weekly" value="option1" ${el.employerPayschedule == 'WK' ? 'checked' : ''}>
                          <label  for="Weekly">Weekly</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Bi-Weekly" value="option1" ${el.employerPayschedule == 'BIWK' ? 'checked' : ''}>
                          <label  for="Bi-Weekly">Bi-Weekly</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Semi-Monthly" value="option1" ${el.employerPayschedule == 'SEMI' ? 'checked' : ''}>
                          <label  for="Semi-Monthly">Semi-Monthly</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Monthly" value="option1" ${el.employerPayschedule == 'MON' ? 'checked' : ''}>
                          <label  for="Monthly">Monthly</label>
                      </div>
                      </div>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 60px; display: flex; height: 90px">
                      <label style="width: 40%; float: left;">Take Home Pay</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000; height: 18px;">$${el?.takeHomePay !== undefined &&
        el?.takeHomePay !== null &&
        el?.takeHomePay !== 'NaN'
        ? Number(el?.takeHomePay).toFixed(2)
        : '0.00'}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Day Paid</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.daysPaid !== undefined &&
        el?.daysPaid !== null &&
        el?.daysPaid !== ''
        ? el?.daysPaid
        : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 60%; float: left;">Length of Employment</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;">${el?.lengthOfEmploymentYears || ''}</span>
                  </div>
                  
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Years</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Months</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Address</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.addressLine1 !== undefined ? el.addressLine1 : ''
      } ${el?.addressLine2 !== undefined ? el?.addressLine2 : ''}</span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label  for="address" style="width: 40%; float: left;">City</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.city !== undefined ? el?.city : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">State</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.state || ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">ZIP</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.postalCode !== undefined ? el?.postalCode : ''
      }</span>
                  </div>
              </div>
              
          </div>
      </div>`
    )
  })

}
const corenterInfoTemplate = (corenterInfo: any, customerInformation: any, isGovtNumOnFocus: any) => {
  if (!corenterInfo || !corenterInfo.personalReferences || !corenterInfo.personalReferences[0]) return '';
  const releationShip: any = corenterInfo?.personalReferences[0]?.relationshipTypeDesc
  const addressDetail: any = corenterInfo.addresses.find((el: any) => el.addressType == 'PRIM')
  const phoneDetail: any = corenterInfo.phones.find((el: any) => el.primary == 'Y')
  return (
    `
      <div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Co-Renter Information</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex; flex-direction: column; height: 90px;">
                      <label style="width: 100%; float: left; margin-bottom: 10px;">Salutation:</label>
                      <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Mr" value="option1" ${customerInformation?.salutation == 'MR' ? 'checked' : ''}>
                          <label  for="Mr">Mr.</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Mrs" value="option1" ${customerInformation?.salutation == 'MRS' ? 'checked' : ''}>
                          <label  for="Mrs">Mrs.</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Ms" value="option1" ${customerInformation?.salutation == 'MS' ? 'checked' : ''}>
                          <label  for="Ms">Ms.</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Jr" value="option1" ${customerInformation?.suffix == 'JR' ? 'checked' : ''}>
                          <label  for="Jr">Jr.</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Sr" value="option1" ${customerInformation?.suffix == 'SR' ? 'checked' : ''}>
                          <label  for="Sr">Sr.</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="III" value="option1" ${customerInformation?.suffix == 'III' ? 'checked' : ''}>
                          <label  for="III">III</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="IV" value="option1" ${customerInformation?.suffix == 'IV' ? 'checked' : ''}>
                          <label  for="IV">IV</label>
                      </div>
                      </div>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex; height: 90px;">
                      <label style="width: 40%; float: left;">Relationship</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000; height: 18px;">${releationShip}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex;">
                      <label style="width: 40%; float: left;">Last Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${corenterInfo.lastName}</span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex;">
                      <label style="width: 40%; float: left;">Legal First Name </label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${corenterInfo.firstName}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex;">
                      <label style="width: 40%; float: left;">MI</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${corenterInfo.middleInitial || ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex;">
                      <label style="width: 40%; float: left;">Preferred First Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex;">
                      <label style="width: 40%; float: left;">Date of Birth</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${buildDateFormat2(corenterInfo?.dateOfBirth).substring(0, 6) + '****'}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Social Security #</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${corenterInfo?.taxId !== undefined &&
      corenterInfo?.taxId !== null &&
      corenterInfo?.taxId !== ''
      ? `***-**-${corenterInfo?.taxId?.substr(-4)}`
      : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 40px;; display: flex; flex-direction: column; height: 110px;">
                      <label style="width: 100%; float: left; margin-bottom: 10px;">Id Type</label>
                      <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="StateId" value="option1" ${corenterInfo?.governmentIdType == 'ST' ? 'checked' : ''}>
                          <label  for="StateId">State Id</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="License" value="option1" ${corenterInfo?.governmentIdType == 'DL' ? 'checked' : ''}>
                          <label  for="License">Driver's License</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Passport" value="option1" ${corenterInfo?.governmentIdType == 'PASS' ? 'checked' : ''}>
                          <label  for="Passport">Passport</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="National" value="option1" ${corenterInfo?.governmentIdType == 'NATL' ? 'checked' : ''}>
                          <label  for="National">National ID</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="NotProvided" value="option1" ${corenterInfo?.governmentIdType == 'NONE' ? 'checked' : ''}>
                          <label  for="NotProvided">Not Provided.</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="III" value="option1" ${corenterInfo?.suffix == 'III' ? 'checked' : ''}>
                          <label  for="III">III</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="IV" value="option1" ${corenterInfo?.suffix == 'IV' ? 'checked' : ''}>
                          <label  for="IV">IV</label>
                      </div>
                      </div>  
                  </div>      
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex; height: 110px;">
                          <label style="width: 40%; float: left;">Gov. ID #</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000; height: 18px;">${corenterInfo?.governmentId !==
      undefined &&
      corenterInfo?.governmentId !== null &&
      corenterInfo?.governmentId !== ''
      ? corenterInfo?.governmentId.length >
        4 && isGovtNumOnFocus !== true
        ? corenterInfo?.governmentId
          .substring(
            0,
            corenterInfo?.governmentId
              .length - 4
          )
          .replace(/[A-Za-z0-9.,-]/g, '*') +
        corenterInfo?.governmentId.substring(
          corenterInfo?.governmentId
            .length - 4
        )
        : corenterInfo?.governmentId
      : ''}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
            <label style="width: 40%; float: left;">State</label>
            <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${addressDetail?.state}</span>
        </div>
        <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
            <label style="width: 40%; float: left;">Address</label>
            <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${addressDetail?.addressLine1}</span>
        </div>

                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 40%; float: left;">Apt# /Unit#</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                      </div>
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 40%; float: left;">PO Box</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                      </div>
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 40%; float: left;">City</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${addressDetail?.city}</span>
                      </div>

                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 40%; float: left;">State</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${addressDetail?.state}</span>
                      </div>
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 40%; float: left;">ZIP</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${addressDetail?.postalCode}</span>
                      </div>
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <input  type="checkbox" id="Mailing" value="option1" ${isPrimarySameAsMailing(corenterInfo.addressess) ? 'checked' : ''}>
                          <label  for="Mailing">Mailing Address Same as Primary</label>
                      </div>

                      <div style="width: 50%; float: left; margin-top: 40px; display: flex; flex-direction: column; height: 60px;">
                          <label style="width: 100%; float: left; margin-bottom: 10px;">Best Time to Call:</label>
                          <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="Morning" value="option1" ${phoneDetail.callTimeType == 'MORN' ? 'checked' : ''}>
                              <label  for="Morning">Morning</label>
                          </div>
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="Afternoon" value="option1" ${phoneDetail.callTimeType == 'AFTN' ? 'checked' : ''}>
                              <label  for="Afternoon">Afternoon</label>
                          </div>
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="Evening" value="option1" ${phoneDetail.callTimeType == 'EVEN' ? 'checked' : ''}>
                              <label  for="Evening">Evening</label>
                          </div>
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="AnyTime" value="option1">
                              <label  for="AnyTime">Any Time</label>
                          </div>
                          </div>  
                      </div>   
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 50%; float: left;">Primary Phone Number</label>
                          <span style="width: 40%; float: left; border-bottom: 1px solid #000;">${formatPhone(phoneDetail?.phoneNumber)}</span>
                      </div>
                      <div style="width: 50%; float: left; margin-top: 40px; display: flex;">
                          <label style="width: 40%; float: left;">Ext.</label>
                          <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                      </div> 

                      <div style="width: 50%; float: left; margin-top: 40px; display: flex; flex-direction: column; height: 60px;">
                          <label style="width: 100%; float: left; margin-bottom: 10px;">Type:</label>
                          <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="CellPhone" value="option1" ${phoneDetail.phoneType == 'CELL' ? 'checked' : ''}>
                              <label  for="CellPhone">Cell Phone</label>
                          </div>
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="HomePhone" value="option1" ${phoneDetail.phoneType == 'HOME' ? 'checked' : ''}>
                              <label  for="HomePhone">Home Phone</label>
                          </div>
                          <div style="width: 50%; float: left; display: inline-block;">
                              <input  type="checkbox" id="WorkPhone" value="option1" ${phoneDetail.phoneType == 'WORK' ? 'checked' : ''}>
                              <label  for="WorkPhone">Work Phone</label>
                          </div>
                          </div>  
                      </div>  
              </div>
              
          </div>
      </div>`
  )
}
const corenterEmpInfoTemplate = (corenterInfo: any) => {
  return corenterInfo.employerReferences?.map((el: any) => {
    return (
      `<div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Co-Renter Employment Information</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:40px">
                      <label style="width: 50%; float: left;">Co-Renter's Employer</label>
                      <span style="width: 40%; float: left; border-bottom: 1px solid #000;">${el?.employerName !== undefined ? el?.employerName : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:40px">
                      <label style="width: 50%; float: left;">Employer Phone Number</label>
                      <span style="width: 40%; float: left; border-bottom: 1px solid #000;">${el?.employerPhoneNumber !== undefined ? formatPhone(el?.employerPhoneNumber) : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Ext</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 60%; float: left;">Supervisor's First Name</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 60%; float: left;">Supervisor's Last Name</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Renter's Job Title</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 60%; float: left;">Hours Worked From</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">To</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 50px; display: flex; flex-direction: column; height: 90px;">
                      <label style="width: 100%; float: left; margin-bottom: 10px;">Payment Schedule:</label>
                      <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Daily" value="option1" ${el.employerPayschedule == 'DAILY' ? 'checked' : ''}>
                          <label  for="Daily">Daily</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Weekly" value="option1" ${el.employerPayschedule == 'WK' ? 'checked' : ''}>
                          <label  for="Weekly">Weekly</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Bi-Weekly" value="option1" ${el.employerPayschedule == 'BIWK' ? 'checked' : ''}>
                          <label  for="Bi-Weekly">Bi-Weekly</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Semi-Monthly" value="option1" ${el.employerPayschedule == 'SEMI' ? 'checked' : ''}>
                          <label  for="Semi-Monthly">Semi-Monthly</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Monthly" value="option1" ${el.employerPayschedule == 'MON' ? 'checked' : ''}>
                          <label  for="Monthly">Monthly</label>
                      </div>
                      </div>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 50px; display: flex; height: 90px">
                      <label style="width: 40%; float: left;">Take Home Pay</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000; height: 18px;">$${el?.takeHomePay !== undefined &&
        el?.takeHomePay !== null &&
        el?.takeHomePay !== 'NaN'
        ? Number(el?.takeHomePay).toFixed(2)
        : '0.00'}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Day Paid</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.daysPaid !== undefined &&
        el?.daysPaid !== null &&
        el?.daysPaid !== ''
        ? el?.daysPaid
        : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 60%; float: left;">Length of Employment</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Years</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Months</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Address</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.addressLine1 ? el.addressLine1 : ''
      } ${el?.addressLine2 ? el?.addressLine2 : ''}</span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">City</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.city ? el?.city : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">State</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.state || ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">ZIP</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${el?.postalCode ? el?.postalCode : ''
      }</span>
                  </div>
              </div>
              
          </div>
      </div>`
    )
  })

}
const residenceInfoTemplate = (Residences: any) => {
  return (`
      <div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Residence Information</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex; flex-direction: column; height: 18px;">
                      <div style="width: 100%; float: left; flex-wrap: wrap; display: flex ;">
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Rent" value="option1" ${Residences?.residenceType == 'RENT'
      ? 'checked'
      : ''
    }>
                          <label  for="Rent">Rent</label>
                      </div>
                      <div style="width: 50%; float: left; display: inline-block;">
                          <input  type="checkbox" id="Own " value="option1" ${Residences?.residenceType == 'OWN'
      ? 'checked'
      : ''
    }>
                          <label  for="Own ">Own </label>
                      </div>
                      </div>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 60%; float: left;">Landlord/Mortgage Name</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;">${Residences?.mortgageCompanyName !==
      undefined
      ? Residences?.mortgageCompanyName
      : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex; height: 40px;">
                      <label style="width: 55%; float: left;">Landlord/Mortgage Phone Number</label>
                      <span style="width: 35%; float: left; border-bottom: 1px solid #000;">${Residences?.phoneNumber !== undefined
      ? formatPhone(Residences.phoneNumber)
      : ''}</span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex; height: 40px;">
                      <label style="width: 40%; float: left;">Ext</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000; height: 18px;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 60%; float: left;">Length of Residency</label>
                      <span style="width: 30%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Years</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Months</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <label style="width: 100%; float: left; margin-top: 10px; margin-bottom: 10px; font-style: italic;">If length of residency is less than 1 year, please provide previous address</label>


                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Address</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Apt# /Unit#</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">PO Box</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">City</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">State</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">ZIP</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
              </div>   
          </div>
      </div>`)
}
const additionalInfoTemplate = () => {
  return (`<div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Additional Information</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Lease/Mortgage in Whose Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Length of Lease/Mortgage</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Monthly Payment</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Utilities in Whose Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Phone in Whose Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Make/Model of Auto</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Auto Color</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Auto License Plate #</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">State</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;">
                      <label style="width: 40%; float: left;">Where is Auto Financed</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
              </div>
              
          </div>
      </div>`)
}
const referenceInfoTemplate = (ReferenceProps: any) => {
  return ReferenceProps.map((data: any) => {
    return (`<div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Reference Information</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Last Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.lastName !== undefined &&
        data?.lastName !== null
        ? data?.lastName
        : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">First Name</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.firstName !== undefined &&
        data?.firstName !== null
        ? data?.firstName
        : ''
      }</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">MI</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Address</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.addressLine1 !== undefined &&
        data?.addressLine1 !== null &&
        data?.addressLine1 !== ''
        ? data?.addressLine1
        : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Apt# /Unit#</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.addressLine2 !== undefined && data?.addressLine2 !== null && data?.addressLine2 !== ''
        ? data?.addressLine2
        : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">PO Box</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>

                  
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">City</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.city !== undefined && data?.city !== null && data?.city !== '' ? data?.city : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">State</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.state !== null &&
        data?.state !== undefined &&
        data?.state !== ''
        ? data?.state
        : ''}</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">ZIP</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.postalCode !== undefined && data?.postalCode !== null && data?.postalCode !== '' ? data?.postalCode : ''}</span>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Phone Number</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.phoneNumber !== undefined
        ? formatPhone(data?.phoneNumber)
        : ''
      }</span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Ext</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;"></span>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px; display: flex;height:20px">
                      <label style="width: 40%; float: left;">Relationship</label>
                      <span style="width: 50%; float: left; border-bottom: 1px solid #000;">${data?.relationshipTypeDesc !== undefined &&
        data?.relationshipTypeDesc !== null &&
        data?.relationshipTypeDesc !== ''
        ? data?.relationshipTypeDesc
        : ''}</span>
                  </div>
              </div>
              
          </div>
      </div>`)
  })
}

const notesTemplate = () => {
  return (`<div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 20px;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Notes</h5>
              <div style="width: 100%; float: left;">
                  <div style="width: 100%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 100%;">
                  </div>
                  <div style="width: 100%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 100%;">
                  </div>
                  <div style="width: 100%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 100%;">
                  </div>
                  <div style="width: 100%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 100%;">
                  </div>
                  <div style="width: 100%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 100%;">
                  </div>

              </div>
              
          </div>
      </div>`)
}

const formatPhone = (input: any) => {
  // //;
  //alert(e.target.value);
  if (input && input?.length > 0) {
    const cleaned = ('' + input).replace(/\D/g, '');
    //This is raw data to pass on props
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    //Isvalid = temp.length == 14 ? true:false
    //alert(temp);
    return cleaned.replace(PhoneRegex, '($1) $2-$3');
  }
  return ''
};
const buildDateFormat2 = (date: any, type?: 'V') => {
  // eslint-disable-next-line
  const d = date == '' ? new Date() : date;
  return moment(d).format('MM/DD/YYYY');
};

const isPrimarySameAsMailing = (address: any) => {
  const primAddress = address?.find((el: any) => el.addressType == 'PRIM')
  const mailAddress = address?.find((el: any) => el.addressType == 'MAIL')
  if (mailAddress && Object.keys(mailAddress).length == 0) {
    return false
  }
  if (primAddress && Object.keys(primAddress).length == 0) {
    return false
  }
  if (primAddress?.addressLine1 == mailAddress?.addressLine1 &&
    primAddress?.addressLine2 == mailAddress?.addressLine2 &&
    primAddress?.city == mailAddress?.city &&
    primAddress?.postalCode == mailAddress?.postalCode &&
    primAddress?.state == mailAddress?.state) {
    return true
  }
  return false
}
const getName = (ln: any, fn: any) => {
  ln = ln ? ln : ''
  fn = fn ? fn : ''
  return `${ln} ${fn}`
}

const validategovtTypeId = (obj: any, ref: any) => {
  if (obj && obj == ref) {
    return true
  } else {
    return false
  }
}

const statementSigningTemplate = (cocustomerDetail: any, customerInformation: any) => {
  return (`<div style="width: 98%; padding-left: 1%; padding-right: 1%; float: left; margin-bottom: 20px; border-radius: 8px; margin-top: 100px; page-break-after: always;">
          <div style="background-color: #ffff; border-radius: 20px; padding: 2%; width: 97%; float: left;">
              <h5 style="color: black;  margin: 0;">Read Statement before signing</h5>
              <div style="width: 100%; float: left;">
                  <label style="width: 100%; float: left; margin-top: 10px;"> I certify that the information I have provided in this rental order form is correct, that I am not aware of any facts that could lead to my filing for bankruptcy and that I have not consulted with an attorney regarding bankruptcy in the past 90 days. I understand that Rent-A-Center will not fulfill my rental order if any of the information I provide is inaccurate. I authorize Rent-A-Center to contact any person or business (including employers and landlords) I have provided on this rental order form to verify the information or to obtain location information about me throughout the duration of my agreement. This authorization ends when a person or business asks Rent-A-Center not to contact them or I make the request in writing to Rent-A-Center.  I further certify that I agree to the terms of Rent-A-Center’s privacy policy posted at:  http://www.rentacenter.com/en/privacy-policy.
                  </label>
                  </div>
                  <div style="width: 100%; float: left; margin-top: 5px;">
                  <label>By signing below, I am providing written instructions to Rent-A-Center under the Fair Credit Reporting Act authorizing Rent-A-Center to obtain information about me from credit reporting agencies (including but not limited to Experian, Clarity, Equifax, and TransUnion ) or others, including my creditors and other third parties, (1) in connection with this transaction for the purpose of evaluating my rental purchase application, to confirm my identity, and to avoid fraudulent transactions, and (2) at any time and use that information for the purposes of (a) considering me for new offers, programs, and services that Rent-A-Center, its affiliates, or third parties may offer in the future, and (b) for marketing such programs to me.
                  </label>
                  </div>
                  <div style="width: 100%; float: left;margin-top: 5px;">
                  <label style="float:left">
                  Furthermore, by signing below, I authorize Rent-A-Center to contact me regarding my account by email or phone using any email address, phone number, or mobile device number provided above or updated by me in the future. I consent to receive calls and messages generated from an automatic telephone dialing system, including artificial and prerecorded voice messages and text messages, even if I am charged for the call or message under my wireless plan. To provide written notice of withdrawal, write to: RAC Opt Out, Attn: Marketing Dept., 5501 Headquarters Drive, Plano, Texas 75024.
                  </label>

                  <div style="width: 50%; float: left; margin-top: 30px;">
                  <input style="border-top: none; border-left: none; border-right: none; width: 80%;" value="${getName(customerInformation?.lastName, customerInformation?.firstName)}">
                  
                      <label style="width: 100%; float: left; display: flex; margin-top: 10px;">Printed Name Renter 1</label>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px;">
                  <input style="border-top: none; border-left: none; border-right: none; width: 80%;" value="${getName(cocustomerDetail?.lastName, cocustomerDetail?.firstName)}">                    
                     <label style="width: 100%; float: left; display: flex; margin-top: 10px;">Printed Name Renter 2 </label>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 80%;">
                      <label style="width: 100%; float: left; display: flex; margin-top: 10px;">Signature Renter 1</label>
                  </div>

                  <div style="width: 50%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 80%;">
                      <label style="width: 100%; float: left; display: flex; margin-top: 10px;">Signature Renter 2</label>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 80%;">
                      <label style="width: 100%; float: left; display: flex; margin-top: 10px;">Date</label>
                  </div>
                  <div style="width: 50%; float: left; margin-top: 30px;">
                      <input type="text" style="border-top: none; border-left: none; border-right: none; width: 80%;">
                      <label style="width: 100%; float: left; display: flex; margin-top: 10px;">Date</label>
                  </div>

              </div>
              
          </div>
      </div>`)
}