/* eslint-disable */
import {
    Card,
    CardContent,
    Grid,
    RACButton,
    RACCheckBox,
    RACSelect,
    Typography,
} from '@rentacenter/racstrap';
import { API_ERROR_MESSAGE, DEFAULT_DROPDOWN_OPTION, EMPTY_STRING } from '../../../constants/constants';
import { valueExistsInDropdown } from '../../../utils/utils';
import { updateCustomerStyles } from '../../../jsStyles/updateCustomerStyles';
import { DropdownOptions } from '../../../interface/globalInterface';
import { SkipHardStatus } from '../../../interface/updateCustomerInterface';
import { UpdateCustomerContext } from '../../../context/UpdateCustomerContext';
import { useContext, useState } from 'react';
import SecondFactor from '../../../../racstrapComponent/manageCustomer/secondAuthen';
import { useParams } from 'react-router-dom';
import { ManageRiskAssessment } from '../../../../api/user';

interface Legalprops {
  legalHoldStatus: boolean;
  skip: boolean;
  hard: boolean;
  legalHoldReason: string;
  legalHoldDropdown: DropdownOptions[];
  dropdownLoader: boolean;
  currentRole: string;
  setSkipStolenHardStatus: (status: Partial<SkipHardStatus>) => void;
  setScreenLoader: (status: boolean) => void;
}

const updateLegalStatus = async (customerId: string, legalStatus: { skip: boolean, hard: boolean }) => {
  try {
    const payload = {
      customerId: customerId,
      ...legalStatus
    };
    return await ManageRiskAssessment(payload);
  } catch (error) {
    console.log("Exception when updating customer legal status.");
  }
};

export function Legal(props: Legalprops) {
  const classes = updateCustomerStyles();
  const {
    legalHoldStatus,
    skip,
    hard,
    legalHoldReason,
    legalHoldDropdown,
    dropdownLoader,
    currentRole,
    setSkipStolenHardStatus,
    setScreenLoader,
  } = props;

  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [showSecondLevelAuth, setShowSecondLevelAuth] = useState(false);
  const [updatedSkipHard, setUpdatedSkipHard] = useState({ skip, hard });
  const { configurations } = useContext(UpdateCustomerContext)
  const customerVerificationOptimization = configurations?.customerVerificationOptimization;
  const editRiskAssessmentRoles = configurations?.editRiskAssessmentRoles;
  const { customerId } = useParams<{ customerId: string }>();
  const saveButtonDisable = updatedSkipHard.skip === skip && updatedSkipHard.hard === hard;

  return (
    <>
    {showSecondLevelAuth ? (
      <SecondFactor
        key={'SLAComponent'}

          setTwoFactorCancelClick={() => setShowSecondLevelAuth(false)}
          setTwoFactorCompleted={async () => {
            setShowSecondLevelAuth(false);
            setScreenLoader(true);
            const updateCustomerResponse = await updateLegalStatus(customerId, updatedSkipHard);
            if (updateCustomerResponse?.status === 'success') {
              setSkipStolenHardStatus(updatedSkipHard);
              setIsEditEnabled(false);
            }
            setScreenLoader(false);
          }}
          moduleName={'Modify Customer Route'}
          currentRole={ currentRole }
        />
      ) : null}

      <Grid key={"legalContainer"} id={"legalContainer"} container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.mb10}
          key={"legalTitleContainer"}
        >
          <Typography
            component="label"
            id="legalInformation"
            className={`${classes.title}`}
          >
            Legal
          </Typography>
        </Grid>

        <Grid
          item
          md={12}
          key={"legalContentContainer"}
          id={"legalContentContainer"}
        >
          <Card className={classes.cardStyle}>
            <CardContent>
              <Grid container>
                <Grid item style={{display:'flex', alignItems:'center'}}>
                  <RACCheckBox
                    key={"legalCheckBox"}
                    label="Legal Hold"
                    size="small"
                    name={"legalCustomer"}
                    checked={legalHoldStatus || false}
                    disabled={true}
                  />
                </Grid>

                <Grid item style={{display:'flex', alignItems:'center'}}>
                  <RACCheckBox
                    key={"skipCheckBox"}
                    label="Skip"
                    size="small"
                    name={"skipCustomer"}
                    checked={(isEditEnabled ? updatedSkipHard.skip : skip) || false}
                    disabled={!isEditEnabled}
                    onChange={(event) => setUpdatedSkipHard({ ...updatedSkipHard, skip: event.target.checked })}
                  />
                </Grid>

                <Grid item style={{display:'flex', alignItems:'center'}}>
                  <RACCheckBox
                    key={"hardCheckBox"}
                    label="Hard"
                    size="small"
                    name={"hardCustomer"}
                    checked={(isEditEnabled ? updatedSkipHard.hard : hard) || false}
                    disabled={!isEditEnabled}
                    onChange={(event) => setUpdatedSkipHard({ ...updatedSkipHard, hard: event.target.checked })}
                  />
                </Grid>

                {legalHoldStatus ? (
                  <Grid item md={2} style={{marginLeft:'30px', display:'flex', alignItems:'center'}}>
                    <RACSelect
                      key={"legalHoldReason"}
                      inputLabel="Legal Hold Reason"
                      loading={dropdownLoader || false}
                      options={legalHoldDropdown}
                      defaultValue={valueExistsInDropdown(
                        legalHoldDropdown,
                        legalHoldReason
                      )}
                      isDisabled={true}
                      errorMessage={
                        !dropdownLoader && legalHoldDropdown?.length === 1
                          ? API_ERROR_MESSAGE
                          : EMPTY_STRING
                      }
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {customerVerificationOptimization && editRiskAssessmentRoles.includes(currentRole) ? (
                  <Grid item md={6} className={`${classes.textAlignRight}`}>
                    {isEditEnabled ? (
                      <>
                        <RACButton
                          variant="outlined"
                          color="primary"
                          id="cancel"
                          className={`${classes.buttonsPadding} ${classes.mr16} ${classes.mt11px}`}
                          onClick={() => setIsEditEnabled(false)}
                        >
                          Cancel
                        </RACButton>
                        <RACButton
                          variant="contained"
                          color="primary"
                          id="save"
                          disabled={saveButtonDisable}
                          className={`${classes.buttonsPadding} ${classes.mr25p} ${classes.mt11px}`}
                          onClick={() => {
                            setShowSecondLevelAuth(true);
                          }}
                        >
                          Save
                        </RACButton>
                      </>
                    ) : (
                      <RACButton
                        variant="contained"
                        color="primary"
                        id="edit"
                        className={`${classes.buttonsPadding} ${classes.mr25p} ${classes.mt11px}`}
                        onClick={() => {
                          setUpdatedSkipHard({ skip, hard });
                          setIsEditEnabled(true);
                        }}
                      >
                        Edit
                      </RACButton>
                    )}
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
