/* eslint-disable prettier/prettier */
import React from "react";
import { Grid, RACButton, RACTooltip, Typography } from "@rentacenter/racstrap";
import { globalStyles } from "../../jsStyles/globalStyles";
import { ContactInfoPropsObject } from "../../interface/globalInterface";
import { ReactComponent as BlueInfoIcon } from "./../../../assets/images/blue-info-icon.svg";

export default function ContactInfoHeader(props: ContactInfoPropsObject) {
  const classes = globalStyles();
  return (
    <Grid container>
      <Grid container className={`${classes.width100p}`}>
        <Grid item className={`${classes.width50p}`}>
          <Typography
            className={`${classes.fontOpensansBold} ${classes.floatLeft} ${classes.fontSize16px}`}
          >
            {props.value.heading}
          </Typography>
          {props.value.infoIcon ? (
            <>
              <RACTooltip
                placement={"right"}
                className={classes.tooltipcss}
                classes={{
                  tooltip: classes.toolTipStyle1,
                  arrow: classes.arrowStyle,
                }}
                title={props.value.infoIconContent}
              >
                <BlueInfoIcon
                  style={{
                    height: "15px",
                    marginLeft: "0px",
                    marginTop: "6px",
                  }}
                />
              </RACTooltip>
            </>
          ) : null}
        </Grid>
        {props.value.popupHeading &&  props.value.popupFunctionality ? (
          <Grid item className={`${classes.width50p}`}>
            <Typography
              className={`${classes.racBlue} ${classes.fontOpensansBold} ${classes.fontSize14px} ${classes.floatRight}`}
            >
              <RACButton
                variant="outlined"
                color="primary"
                className={`${classes.backgroundNone} ${classes.borderNone} ${classes.removeButtonHover}`}
                onClick={() =>
                  props.value.popupFunctionality &&
                  props.value.popupFunctionality(
                    props.value.params ? props.value.params : false
                  )
                }
              >
                {props.value.popupHeading}
              </RACButton>
            </Typography>
          </Grid>
        ) : null}
        {props.value.popupHeading && !props.value.popupFunctionality ? (
          <Grid item className={`${classes.width50p}`}>
            <Typography
              className={`${classes.racRed} ${classes.fontOpensansBold} ${classes.fontSize14px} ${classes.floatRight}`}
            >
                {props.value.popupHeading}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}