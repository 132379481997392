/* eslint-disable */
import { ContactInformations, ReferenceInformation } from "./updateCustomerInterface";
export interface ApiResponse {
  status: number,
  data: any
}

export interface DropdownOptions {
  label: string;
  value: string;
}

export interface PathParams {
  customerId: string;
  pageType: string;
}

export interface ConfigurationsRequest {
  storeNumbers: string[],
  paramKeyNames: string[],
}

export interface UpdateCustomerPayload {
  customerId: string;
  globalCustomerId: string;
  emailAddress?: string;
  linkOnlineAccount?: string;
  firstName?: string,
  lastName?: string,
  middleInitial?: string,
  coworker?: string,
  assignRoute?: {
    storeNumber: string,
    customerId: number,
    routeRefCode: string,
  },
  phones?: any[]
}

export interface ContactInfoHeaderProps {
  heading: string;
  popupHeading: string | null;
  popupFunctionality: React.Dispatch<React.SetStateAction<boolean>> | null;
  params: boolean | null;
  infoIcon: boolean;
  infoIconContent: string;
}

export interface ContactInfoPropsObject {
  value: ContactInfoHeaderProps;
}

export interface AddressDetails {
  addressId?: number;
  addressIds?: string[];
  agreementId: string;
  agreementIds?: string[];
  agreementNumber: string;
  agreementNumbers?: string[];
  storeNumbers?: string[];
  agreementNumbersCount?: string;
  storeNumber: string;
  addressType: string;
  addressTypeDesc: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  postalCode: string;
}

export interface AddressInfo {
  primaryAddress: AddressDetails[] | null;
  deliveryAddress: AddressDetails[] | null;
  mailingAddresses: AddressDetails[] | null;
}

export interface AddressInformationProps {
  activeAddresses: AddressDetails[];
  inActiveAddresses: AddressDetails[];
}

export interface TableHeadings {
  heading: string;
}

export interface AddressHistoryObject {
  value: AddressDetails[];
}

export interface AddressInformationProps {
  activeAddresses: AddressDetails[];
  inActiveAddresses: AddressDetails[];
}

export interface AddressInformationPropsObject {
  value: any;
  setterData: {
    editedAddress: AddressDetailsBinder[];
    setEditedAddress: React.Dispatch<
      React.SetStateAction<AddressDetailsBinder[]>
    >;
    setActiveAddress: React.Dispatch<
      React.SetStateAction<AddressDetailsBinder[]>
    >;
    primaryAddress: AddressDetails[]
  };
  addressValidationPayload: any;
}

export interface AddressInfoPropsObject {
  value: AddressDetails[];
  setterData: {
    editedAddress: AddressDetailsBinder[];
    setEditedAddress: React.Dispatch<
      React.SetStateAction<AddressDetailsBinder[]>
    >;
  };
}

export interface AddressDetailsBinder {
  addressId?: number;
  isEdited: boolean;
  isEditEnabled: boolean;
  showAddress: boolean;
  heading: string;
  toggleEnable: boolean;
  isDelivery: boolean;
  storeNumber: string | null;
  agreementNumber: string[] | null | undefined;
  addressLine1: string | null;
  addressLine2: string | null;
  zipCode: string | null;
  city: string | null;
  state: string | null;
  isMailAddSameAsPrimAdd: boolean;
  isEmptyMailAddress: boolean;
  addressType: string;
  agreementIds: string[] | null | undefined;
}

export interface AddressInfoObject {
  value: AddressInfo;
}

export interface AddressDetailsBinderObject {
  value: {
    addressDetails: AddressDetailsBinder[];
  };
  setterData: {
    editedAddress: AddressDetailsBinder[];
    setEditedAddress: React.Dispatch<
      React.SetStateAction<AddressDetailsBinder[]>
    >;
    setActiveAddress: React.Dispatch<
      React.SetStateAction<AddressDetailsBinder[]>
    >;
    primaryAddress: AddressDetails[]
  };
  addressValidationPayload: any;
}

export interface EmployerDetails {
  basicInfo: BasicInfo;
  incomeVerification: IncomeVerification;
  addressInfo: EmployerAddress;
  verified: boolean;
  active: any;
  verifiedDate: string;
  employerDetailsId: string | null;
  overrideEnabled?: boolean;
  isEditEnabled: boolean;
  verifiedSource: string;
  verifiedBy: string;
  decisionEngineId: string;
  verifyCode: string;
  isCancellable: boolean;
}

export interface BasicInfo {
  companyName: string;
  employmentYears: string;
  phoneNumber: string;
  doNotCallEnabled: boolean;
  isEdited: boolean;
}

export interface IncomeVerification {
  paydayFrequency: string;
  payDayFreqDesc: string;
  daysPaidDesc: string;
  dayPaid: string;
  takeHomePay: string;
  isEdited: boolean;
}

export interface EmployerAddress {
  addressLine1: string;
  addressLine2: string;
  zip: string;
  city: string;
  state: string;
  doNotVisit?: boolean;
  isEdited: boolean;
}

export interface EmployerDetailsProps {
  employerDetails: EmployerDetails[];
  setEmployerDetails: React.Dispatch<React.SetStateAction<EmployerDetails[]>>;
  errorMessage: EmployerDetails[];
  setErrorMessage: React.Dispatch<React.SetStateAction<EmployerDetails[]>>;
}

export interface ResidenceDetailsProps {
  residenceInfo: ResidenceInfo;
  setresidenceInfo: React.Dispatch<React.SetStateAction<ResidenceInfo[]>>;
  errorMessage: ResidenceInfo;
  setErrorMessage: React.Dispatch<React.SetStateAction<ResidenceInfo[]>>;
  actualResidenceDetails: ResidenceInfo;
  setActualResidenceDetails: React.Dispatch<
    React.SetStateAction<ResidenceInfo>
  >;

}

export interface IdentityDetailsProps {
  identityDetails: IdentityDetailsInfo;
  setIdentityDetails: React.Dispatch<React.SetStateAction<IdentityDetailsInfo>>;
  errorMessage: IdentityDetailsInfo;
  setErrorMessage: React.Dispatch<React.SetStateAction<IdentityDetailsInfo>>;
  actualIdentityDetails: IdentityDetailsInfo;
  setActualIdentityDetials: React.Dispatch<
    React.SetStateAction<IdentityDetailsInfo>
  >;
  setIdentityErrorMessage: React.Dispatch<
    React.SetStateAction<IdentityDetailsInfo>
  >;
}

export interface IdentityDetailsInfo {
  governmentId: string;
  governmentIdType: string;
  issuedState: string;
  verified: boolean;
  verifiedDate: string;
  overrideEnabled: boolean;
  decisionEngineId: string;
  verifiedSource: string;
  govtIdDecrpted: string;
  verifyCode: string;
  verifiedBy: string;
}

export interface ReferenceInfo {
  relativeFirstName: string;
  relativeLastName: string;
  phoneNumber: string;
  bestTimeToCall: string;
  relationShip: string;
  doNotCall: boolean;
  isEdited: boolean;
}

export interface ReferenceDetail {
  referenceInfo: ReferenceInformation;
  referenceAddressInfo: EmployerAddress;
  verified: boolean;
  verifiedDate: string;
  referenceDetailsId: string;
  active: boolean;
  overrideEnabled?: boolean;
  isEditEnabled: boolean;
  decisionEngineId: string;

}

export interface EmployersInfoProps {
  value: EmployerDetails;
  isVerifiedReq?: boolean;
  setterData: {
    index: number;
    employerDetails: EmployerDetails[];
    setEmployerDetails: React.Dispatch<React.SetStateAction<EmployerDetails[]>>;
    errorMessage: EmployerDetails[];
  };
}

export interface ResidenceInfo {
  rentalType: string;
  mortgage: boolean;
  ownResidenceDetails: {
    paymentAmount: string;
    mortgageCompanyName: string;
    moveInDate: string;
  };
  rentalDetails: {
    landlordsFirstName: string;
    landlordsLastName: string;
    moveInDate: string;
    landlordsPhoneNumber: string;
    paymentAmount: string;
  };
  verified: boolean;
  verifiedDate: string;
  overrideEnabled?: boolean;
  decisionId: string,
  verifyCode: string,
  verifiedBy?: string,
  verifiedSource?: string,
  verifiedOn?: string,
  residenceVerificationToggle?: boolean,
  residenceInfoError: {
    landlordsFirstNameError: boolean,
    landlordsLastNameError: boolean,
    landlordsPhoneNumberError: boolean,
    mortgageCompanyNameError: boolean,
  }

}

export interface ResidenceInfoProps {
  value: ResidenceInfo;
  setterData: {
    residenceInfo: ResidenceInfo;
    setResidenceInfo: React.Dispatch<React.SetStateAction<ResidenceInfo>>;
  };
}

export interface ReferenceInfoProps {
  value?: ReferenceInformation;
  setterData: {
    index: number;
    referenceInfo: ReferenceInformation[];
    setReferenceInfo: React.Dispatch<React.SetStateAction<ReferenceInformation[]>>;
  };
}

export interface ReferenceAddressInfoProps {
  value?: EmployerAddress;
  setterData: {
    index: number;
    referenceInfo: ReferenceDetail[];
    setReferenceInfo: React.Dispatch<React.SetStateAction<ReferenceDetail[]>>;
  };
}

export interface ParamType {
  customerId?: string;
  pageType: string;
}
export interface DaysPaidDropDownValue {
  weekly: DropdownOptions[];
  monthly: DropdownOptions[];
  semiMonthly: DropdownOptions[];
}

export interface DynamicVerification {
  isButtonEnabled: boolean;
  residenceEditClicked?: boolean;
  editEnabled?: boolean;
  validateInputFields: boolean;
  actualVerifiedValue:
  | Partial<ResidenceInfo>
  | Partial<IdentityDetailsInfo>
  | Partial<ReferenceDetail>
  | Partial<EmployerDetails>;
  updatedVerifiedValue:
  | Partial<ResidenceInfo>
  | Partial<IdentityDetailsInfo>
  | Partial<ReferenceDetail>
  | Partial<EmployerDetails>;
  setterFunction: any;
  isArrayOfObject?: boolean,
  index?: number,
  stateVariable?: ReferenceDetail[] | EmployerDetails[],
  IdentityIntellicheckVerification?: boolean,
  intellicheckStatus?: string,
  intellicheckmismatchedValues?: string
}

interface Phone {
  phoneId?: string;
  phoneNumber?: string;
  phoneType?: string;
  phoneTypeDesc?: string;
  extension?: string | null;
  primary?: string;
  callTimeType?: string;
  callTimeTypeDesc?: string;
  active?: string;
  note?: string | null;
  isNDNCOpted?: string;
  isTrnsMsgAllowed?: string;
  isMktgMsgAllowed?: string;
  textMessageReminderOri?: boolean;
  textMarketingOffersOri?: boolean;
  phoneNumberOri?: string;
}

interface EmployerReference {
  employerReferenceId?: string;
  employerName?: string;
  takeHomePay?: string;
  supervisorFirstName?: string;
  supervisorLastName?: string;
  jobTitle?: string;
  employerPhoneNumber?: string;
  employerPhoneExtension?: string | null;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  doNotVisit?: string;
  doNotCall?: string;
  employmentBeginDate?: string;
  workStartTime?: string | null;
  workEndTime?: string | null;
  employerPayschedule?: string;
  employerPayscheduleDesc?: string;
  lengthOfEmploymentYears?: string;
  daysPaid?: string;
  daysPaidDesc?: string;
  employmentEndDate?: string | null;
  verifiedDate?: string;
  active?: string;
}

interface PersonalReference {
  personalReferenceId?: string;
  verifiedDate?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  relationshipType?: string;
  relationshipTypeDesc?: string;
  bestTimeToCall?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  state?: string;
  active?: string;
  doNotCall?: string;
}

interface LandlordReference {
  landlordReferenceId?: string;
  landlordFirstName?: string;
  landlordLastName?: string;
  leaseLengthYears?: string;
  leaseLengthMonths?: string;
  leaseTerm?: string | null;
  leaseCompanyName?: string | null;
  phoneNumber?: string;
  phoneExtension?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string;
  leaseHolderName?: string | null;
  monthlyRent?: string;
  verifiedDate?: string;
  active?: string;
  bestTimeToCall?: string | null;
  bestTimeToCallDesc?: string | null;
  moveInDate?: string;
}

interface AssignRoute {
  storeNumber?: string;
  customerId?: number;
}

export interface UpdateCustomer {
  isManageApproval: boolean;
  decisionId?: string;
  customerId?: string;
  globalCustomerId?: string;
  firstName?: string;
  lastName?: string;
  middleInitial?: string;
  coworker?: string;
  suffix?: string;
  emailAddress?: string;
  taxId?: string;
  taxIdType?: string;
  salutation?: string;
  governmentId?: string;
  governmentIdType?: string;
  governmentIdIssuedState?: string;
  preferredContactMethod?: string;
  preferredLanguage?: string;
  printReceiptMethod?: string;
  phoneSolicitationAllowed?: string;
  emailSolicitationAllowed?: string;
  mailSolicitationAllowed?: string;
  paymentReminderCallsAllowed?: string;
  verified?: string;
  verifiedDate?: string;
  skip?: string;
  stolen?: string;
  hard?: string;
  skipDate?: string;
  mortgageCompanyName?: string;
  residenceType?: string;
  residenceSince?: string | null;
  monthlyMortgagePayment?: string;
  phones?: ContactInformations[];
  employerReferences?: EmployerReference[];
  personalReferences?: PersonalReference[];
  landlordReferences?: LandlordReference[];
  ownVerifiedDate?: string;
  assignRoute?: AssignRoute;
}

export interface DEHistoryRequest {
  globalCustomerId: string;
  limit: string;
  offset: string;
}

export interface CustomerApproval {
  customerApprovalId: string;
  approvalDate: string;
  approvalExpirationDate: string;
  approvalStatusCode: string;
  approvalStatusDesc: string;
  decisionStatusReason: string;
  decisionId: string;
  storeNumber: string;
  originalApprovalAmount: any;
  weeklyOriginalApprovalAmount: any;
  clientId: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}

export interface EventIdRequest {
  storeNumbers: Array<String>,
  paramKeyNames: Array<String>,
};

export interface VehicleInfoProps {
  stateVariables: {
    Agrtransferinit: boolean;
    vehicleInformation: any;
    dropDownLoaded: boolean;
    stateInfo: DropdownOptions[]
  };
  setterFuncton: {
    setcustomerInfoChange: (value: any) => void;
    setVehicleInformation: (value: any) => void;
  };
}

export interface VehicleInfomation {
  vehicleId?: string,
  make: string,
  model: string,
  year: string,
  color: string,
  vehicleVin: string,
  licensePlate: string,
  regState: string,
  planExpirationDate: string,
  financedFrom: string,
  monthlyPayment: string,
  vehicleIndex?: string,
  vehicleTagNumber?: string
}

export interface AgrHistoryEventId {
  paramCategoryName: string
  paramGroupName: string
  paramHierarchy: string
  paramKeyId: string
  paramKeyName: string
  paramValue: string
}

export interface GetAgreementByIdResponse {
  agreement: (GetAgreement | ClubInfoResponse)[];
  clubInfoResponse: ClubInfoResponse[];
  customerId: string | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  customerCOAAmount: string | null;
  coCustomerId: string | null;
  coCustomerFirstName: string | null;
  coCustomerLastName: string | null;
  coCustomerCOAAmount: string | null;
}

export interface GetAgreement {
  storeNumber: string;
  storeCity: string;
  stateProvinceAbbreviation: string;
  parentAgreementId: string;
  agreementRefCode: string;
  isNoteAgreement: string;
  agreementId: string;
  agreementNumber: string;
  agreementDescription: string;
  agreementType: string;
  agreementTypeRefCode: string;
  isDeliveryConfirmed: string;
  deliveredDate?: string;
  totalCost: string;
  paymentSchedule: string;
  nextDueDate: string;
  numberOfPayments: string;
  amountDue: string;
  agreementStatus: string;
  agreementSubstatus: string;
  autoPay: boolean;
  currentDueDate: string;
  rentPaid: string;
  pastDueRent: string;
  lateLast10: number;
  currentDayslate: number;
  reInstated?: string;
  customers: Customer[];
  isLDWActive: string;
  suspenseAmount: string;
  agreementOpenDate: string;
  customerPaymentMethodId?: string;
  envelopeId?: string;
  envelopeStatus?: string;
  signerStatus?: SignerStatus[]
  route: string;
  closeDate: string;
  actualRate: string;
  actualTerm: string;
  agreementStatusReason: string;
  agreementSource: string;
  packagePriceId: string;
  packageName: string;
  items?: Items[]
  isEppAgreement: boolean;
  [key: string]: any;
}

export interface Items {
  rmsItemNumber: string,
  rmsItemMasterId: string,
  inventoryId?: string,
  inventoryNumber?: string,
  inventoryDesc?: string,
  storeNumber: string,
  extendedAisle: string
}

export interface ClubInfoResponse {
  agreementId: string;
  agreementNumber: string;
  swithout: string;
  isTransferInitiated: string;
  agreementDescription: string;
  agreementType: string;
  storeNumber: number;
  numberOfPayments: number;
  paymentSchedule: string;
  nextDueDate: string;
  amountDue: string;
  agreementSubstatus: string;
  storeCity: string;
  stateProvinceAbbreviation: string;
  agreementStatus: string;
  agreementStatusRef: string;
  currentDayslate?: string | number;
  currentDueDate?: string;
  autoPay?: boolean;
  clubBeginDate: string;
  customerPaymentMethodId?: string;
  route?: string;
  actualRate?: number;
  [key: string]: any;
}

export interface Customer {
  customerId: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  emailId: string,
  priority: string,
  isClubApplied: string
}

export interface SignerStatus {
  signerName: string;
  signStatus: string;
  signType: string;
  signerEmail: string;
  customerId: string;
}

export interface DropdownValue {
  label: string;
  value: string;
}

export interface SuggestedAddress {
  storeConfig: any;
  validateAddress: any
}

export interface AddressResponse {
  message?: string | undefined;
  status: boolean;
  matchCode?: string | undefined;
  suggestedAddress?: string | undefined;
  postalAddress?: PostalAddress | undefined;
  enteredAddress?: string | undefined;
}
export interface PostalAddress {
  addressLines: string[];
  administrativeArea: string;
  postalCode: string;
  locality: string;
}

export interface ReferenceDetailsProps {
  referenceInfo: ReferenceInformation[];
  setReferenceInfo: React.Dispatch<React.SetStateAction<ReferenceInformation[]>>;
  errorMessage: ReferenceInformation[];
  setErrorMessage: React.Dispatch<React.SetStateAction<ReferenceInformation[]>>;
}

export interface VerificationStatus {
  verifiedBy: string,
  verifiedOn: string,
  verifiedSource: string
}


export interface EmploymentRequest {
  employerReferenceId?: string | null;
  employerName?: string | null;
  takeHomePay?: string | null;
  supervisorFirstName?: string | null;
  supervisorLastName?: string | null;
  jobTitle?: string | null;
  employerPhoneNumber?: string | null;
  employerPhoneExtension?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  doNotVisit?: string | null;
  doNotCall?: string | null;
  employmentBeginDate?: string | null;
  workStartTime?: string | null;
  workEndTime?: string | null;
  employerPayschedule?: string | null;
  lengthOfEmploymentYears?: string | null;
  daysPaid?: string | null;
  employmentEndDate?: string | null;
  verifiedDate?: string | null;
  active?: string | null;
  employerPayscheduleDesc?: string | null;
  daysPaidDesc?: string | null;
}
export enum tabList {
  CUSTOMERINFO,
  IDENTITY,
  RESIDENCE,
  EMPLOYMENTINFO,
  REFERENCE,
  AGREEMENTHISTORY,
  DEHISTORY,
}