/* eslint-disable */

import React from "react";
import {
  Grid,
  RACButton,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { ReferenceAddressInfoProps } from "../../../interface/globalInterface";
import { formatText, formatZipCode, removeObjectFromArray, updateReferenceInfo } from "../Utils";
import { dropdownOptions } from "../../../constants/constants";

export default function ReferenceAddressDetails({ value, setterData }: any ) {
  const Agrtransferinit = false;
  const classes = globalStyles();
  return (
      <Grid container spacing={2} className={`${classes.mt10px}`}>
        <Grid container>
          <Grid item md={12}>
            <Typography
              variant="h6"
              className={`${classes.fontOpensansBold} ${classes.mt10px}`}
            >
              Address Information
            </Typography>
          </Grid>
          <Grid container spacing={2} className={`${classes.mt10px} ${classes.ml5px}`}>
            <Grid item md={4} lg={2}>
              <RACTextbox
                isCurrency={false}
                type={"text"}
                inputlabel={`Address Line 1`}
                placeHolder={`Address Line 1`}
                  disabled={Agrtransferinit ? true : !setterData.referenceInfo[setterData.index].active}
                  OnChange={(e: any) => updateReferenceInfo(setterData.index, setterData.referenceInfo, setterData.setReferenceInfo, { referenceAddressInfo: { ...setterData.referenceInfo[setterData.index].referenceAddressInfo, addressLine1: e.target.value } })}
                value={value?.addressLine1 || ''}
                maxlength={30}
              />
            </Grid>
            <Grid item md={4} lg={2}>
              <RACTextbox
                isCurrency={false}
                required={false}
                type={"text"}
                inputlabel="Address Line 2"
                infoIcon={true}
                infoTitle={"Address 2, APT #/Unit #, P.O.Box"}
                placeHolder={"Address 2, APT #/Unit #, P.O.Box"}
                disabled={Agrtransferinit ? true : !setterData.referenceInfo[setterData.index].active}
                OnChange={(e: any) => updateReferenceInfo(setterData.index, setterData.referenceInfo, setterData.setReferenceInfo, { referenceAddressInfo: { ...setterData.referenceInfo[setterData.index].referenceAddressInfo, addressLine2: e.target.value } })}
                maxlength={30}
                value={value?.addressLine2 || ''}
              />
            </Grid>
            <Grid item md={4} lg={2}>
              <RACTextbox
                isCurrency={false}
                type={"text"}
                disabled={Agrtransferinit ? true : !setterData.referenceInfo[setterData.index].active}
                placeHolder="Zip Code"
                value={formatZipCode(value?.zip) || ''}
                OnChange={(e: any) => updateReferenceInfo(setterData.index, setterData.referenceInfo, setterData.setReferenceInfo, { referenceAddressInfo: { ...setterData.referenceInfo[setterData.index].referenceAddressInfo, zip: e.target.value } })}
                maxlength={10}
                errorMessage={""}
                inputlabel="Zip"
              />
            </Grid>
            <Grid item md={4} lg={2}>
              <RACTextbox
                type={"text"}
                inputlabel="City"
                placeHolder="City"
                disabled={Agrtransferinit ? true : !setterData.referenceInfo[setterData.index].active}
                value={value?.city || ''}
                OnChange={(e: any) => updateReferenceInfo(setterData.index, setterData.referenceInfo, setterData.setReferenceInfo, { referenceAddressInfo: { ...setterData.referenceInfo[setterData.index].referenceAddressInfo, city: formatText(e.target.value) } })}
                maxlength={15}
              />
            </Grid>
            <Grid item md={4} lg={1}>
              <RACSelect
                inputLabel="State"
                  options={dropdownOptions}
                defaultValue={value?.state}
                isDisabled={Agrtransferinit ? true : !setterData.referenceInfo[setterData.index].active}
                required={false}
                loading={dropdownOptions.length == 0 ? true : false}
                onChange={(e: any) => updateReferenceInfo(setterData.index, setterData.referenceInfo, setterData.setReferenceInfo, { referenceAddressInfo: { ...setterData.referenceInfo[setterData.index].referenceAddressInfo, state: e.target.value } })}
                //   {...(state.length == 0 &&
                //     dropDownLoaded && {
                //       errorMessage: API_ERROR_MESSAGE,
                //     })}
              />
            </Grid>

            {setterData.referenceInfo[setterData.index].referenceDetailsId ? (
              <Grid item md={3} lg={1} className={classes.mt16pxRem}>
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={classes.focusButton}
                  onClick={() => removeObjectFromArray(setterData.referenceInfo, setterData.index, setterData.setReferenceInfo)}
                >
                  Cancel
                </RACButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
  );
}
