/* eslint-disable prettier/prettier */
import React from "react";
import { Card, CardContent, Grid } from "@rentacenter/racstrap";
import { ContactInfoHeaderProps, IdentityDetailsProps } from "../../../interface/globalInterface";
import { globalStyles } from "../../../jsStyles/globalStyles";
import CustomerBasicInfo from "../CustomerBasicInfo/CustomerBasicInfo";
import ContactInfoHeader from "../ContactInfoHeader";
import IdentityDetails from "./IdentityDetails";

export default function IdentityInformation(props: {
  value: IdentityDetailsProps;
}) {
  const classes = globalStyles();

  const contentHeading: ContactInfoHeaderProps = {
    heading: "Identity",
    popupHeading: null,
    popupFunctionality: null,
    params: null,
    infoIcon: true,
    infoIconContent: 'US Drivers License,\n US Passport, Canada ID,\n Mexico ID, and \nother international documents.'
  };

  return (
    <Grid container className={`${classes.pt05p}`}>
      <Grid container>
        <ContactInfoHeader value={contentHeading}/>
        <Card className={`${classes.width100p} ${classes.mt10px} ${classes.card}`}>
          <CardContent className={`${classes.width100p}`}>
            <CustomerBasicInfo/>
            <IdentityDetails value={props.value} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
