/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
  RACButton,
  Grid,
  Typography,
  RACModalCard,
} from "@rentacenter/racstrap";
import { ReactComponent as TickIcon } from './../../assets/images/TickIcon.svg';
import { useHistory } from "react-router-dom";
import { customerStyles } from "./../customerStyles";
import { postAgreementActivity } from "../../api/user";
export default function RACExchange(props: any){
    const classes = customerStyles();
    const rentPaid = props?.value?.rentPaid;
    const [suspensePopup, setSuspensePopup] = useState<any>(false);
    const [exchangePopup, setExchangePopup] = useState<any>(false);
    const history = useHistory();

    useEffect(() => {
        validateAndOpenPopup()
    }, [])

    function showExchangePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                {!props.value.racExchangeFuncFlag ? <TickIcon/> : null}
                {props.value.racExchangeFuncFlag ?
                    <>
                        <Typography className={`${classes.customerRacpopup12}`}>
                            Customer is eligible for ${rentPaid ? parseFloat(rentPaid)?.toFixed(2) : 0.00} for Exchange.
                        </Typography>
                        <Typography className={`${classes.customerRacpopup12}`}>
                            Do you want to proceed with agreement creation?
                        </Typography>
                    </>
                    :
                    <Typography className={`${classes.customerRacpopup12} ${classes.spacerMT3}`}>
                        Please follow the new agreement creation workflow to complete the RAC Exchange Program.
                    </Typography>
                }
                <Grid className={`${classes.spacerMT32} ${classes.centerButton}`}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            props.value.setExchangeNavigationPopup(false);
                        }}
                    >
                        {props.value.racExchangeFuncFlag ? 'No' : 'Cancel'}
                    </RACButton>
                    {props.value.racExchangeFuncFlag ? <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            postAgreementActivity({agreementActivities : [{
                                agreementId: props?.value?.agreementId,
                                activityDescription: `Agreement Exchange Selected`,
                                agreementActivityType: "AGMXNGS",
                              }]});
                            const url = `/agreement/rental/itemsearch/${props?.value?.customerId}?isExchange=1&agreementId=${props?.value?.agreementId}`;
                            history.push(url);
                        }}
                    >
                        Create Agreement
                    </RACButton> : null}
                </Grid>
            </Grid>
        )
    }

    function showSuspensePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                <Typography className={`${classes.customerRacpopup12}`}>
                    The suspense amount of ${props?.value?.suspenseAmount ? props?.value?.suspenseAmount : 0.00 } on Agreement # {props?.value?.exchangeAgreementNumber ? props?.value?.exchangeAgreementNumber : props.value.agreementId } will be
                </Typography>
                <Typography className={`${classes.customerRacpopup12}`}>
                    transferred to the Exchange agreement after making the initial payment.
                </Typography>
                <Grid className={`${classes.spacerMT32} ${classes.centerButton}`}>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            setExchangePopup(true);
                            setSuspensePopup(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        )
    }

    function validateAndOpenPopup() {
        if(props?.value?.suspenseAmount){
            setSuspensePopup(true);
        }
        else {
            setExchangePopup(true);
        }
    }
    
    return(
        <>
            <RACModalCard
                isOpen={exchangePopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                onClose={() => {
                    props.value.setExchangeNavigationPopup(false);
                }}
                className={props.value.racExchangeFuncFlag ? `${classes.customModal3}` : ''}
            >
                {showExchangePopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={suspensePopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                onClose={() => {
                    setSuspensePopup(false);
                    props.value.setExchangeNavigationPopup(false);
                }}
                className={props.value.racExchangeFuncFlag ? `${classes.customModal4}` : ''}
            >
                {showSuspensePopup()}
            </RACModalCard>
        </>
    );
}