/* eslint-disable */
import axios, { AxiosRequestConfig, Method } from "axios";
import { API_ERROR, EMPTY_STRING } from "../constants/constants";
import Auth from "@aws-amplify/auth";
import { ApiResponse } from "../interface/globalInterface";

export async function getJWToken(): Promise<string> {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error in getJWToken: ", error);
    return EMPTY_STRING;
  }
}

async function getAccessToken(): Promise<string> {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("Error in getAccessToken: ", error);
    return EMPTY_STRING;
  }
}

export async function client(
  method: Method,
  baseUrl: string,
  endpoint: string,
  body?: any
): Promise<ApiResponse> {
  try {
    const jwtToken = await getJWToken();

    const requestHeaders: any = {
      "Content-Type": "application/json; charset=UTF-8",
      storeNumber: window.sessionStorage.getItem("storeNumber"),
    };

    const accessToken: string = await getAccessToken();

    if (accessToken) {
      requestHeaders.AccessToken = accessToken;
    }

    if (jwtToken) {
      requestHeaders.Authorization = `Bearer ${jwtToken}`;
    }

    const axiosRequest: AxiosRequestConfig = {
      method: method,
      headers: requestHeaders,
    };

    if (body) {
      axiosRequest.data = JSON.stringify(body);
    }

    return await axios(`${baseUrl}/${endpoint}`, axiosRequest)
      .then((response) => {
        return { status: response.status, data: response.data };
      })
      .catch((error: any) => {
        return {
          status: error?.status ? error.status : 500,
          data: EMPTY_STRING,
        };
      });
  } catch (error) {
    console.log("Error in client: ", error);
    return API_ERROR;
  }
}
