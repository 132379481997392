/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-debugger */
/* eslint-disable use-isnan */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import {
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACRadio,
  RACModalCard,
  RACButton,
  RACCheckBox,
  CircularProgress,
  Card,
  Grid,
  CardContent,
  Typography,
  Box,
} from "@rentacenter/racstrap";
import clsx from "clsx";
import { ReactComponent as WarningIcon } from "../../assets/images/no-records-found.svg";
import { ReactComponent as DEApproved } from "../../assets/images/DE-Approved.svg";
import { ReactComponent as Alerticon } from "../../assets/images/No-records.svg";
import { ReactComponent as DEDeclined } from "../../assets/images/DE-Declined.svg";
import { ReactComponent as DEConditionallyApproved } from "../../assets/images/DE-VerificationRequired.svg";
import { ReactComponent as SortDownIcon } from "../../assets/images/sort-down-icon.svg";
import { ReactComponent as SortUpIcon } from "../../assets/images/sort-up-icon.svg";
// import { ReactComponent as AccordianOpenIcon } from '../../assets/images/Accordion-down.svg';
// import { ReactComponent as AccordianCloseIcon } from '../../assets/images/Accordion-close.svg';
import AccordionDown from "../../assets/images/Accordion-down-updated.png";
import AccordionOpen from "../../assets/images/Accordion-close-updated.png";
import { ReactComponent as CrossIcon } from "../../assets/images/CrossIcon.svg";
// import Searchresult from './SearchCustomerResult';
import moment from "moment";
import HeaderWizards from "./HeaderWizards";
import { headerWizardStyles } from "../headerWizardStyles";
import {
  agreementGridHeader,
  SearchGridHeader,
} from "../../constants/constants";
import { API_ERROR_MESSAGE } from "../../constants/constants";
//import { useParams } from 'react-router-dom';
//import {useLocation} from "react-router-dom";
// eslint-disable-next-line react-hooks/rules-of-hooks
import {
  GetSearchResult,
  GetAgreementById,
  UpdateCustomerInfo,
  GetWebLead,
  CreateCustomerInValidPage,
  GetNODEReason,
  GetStateFromZip,
  GetSuggestedAddress,
  GetCustomerBasicInfo,
  RunDE,
  UpdateNoDeReason,
  GetWebLeadById,
  GetState,
  RefundLeadAmount,
  GetSalesLeadExpirationDays,
  getmenuandstore,
  GetLegalSatatus,
  GetReferenceValues,
  storeException,
} from "../../api/user";
import CreateCustomerComponent from "./CreateCustomer";
import { stateCC } from "../../constants/constants";
import { ContainerContext } from "../../app/App";
import { CustomPropInterface } from "../../index";
// import Grid from '@mui/material/Grid';
import { AddCoCustomer } from "../manageCustomer/AddCoCustomer";
// import { GetSearchResult, GetAgreementById } from "../../api/user";
// import Flatpickr from 'react-flatpickr';
// import Empinfo from './employementInformation';
import Modal from "react-modal";
import { customerStyles } from "../customerStyles";
import { ManageCustomerStyles } from "../ManageCustomerStyles";
//import Moment from 'react-moment';
import { customerValidationContext } from "../../context/customerContext/customerContext";
import { useLocation } from "react-router-dom";
import { FeatureFlagContext } from "../../context/FeatureFlagContext";
import validateAddressFn from "../../common/validateAddress";
import { CUSTOMER_APPROVAL_FLOW, DE_RUNNED_CA_STATUS } from "../../CustomerRefactor/constants/constants";
export interface sortColumns {
  columnId: string;
  sortOrder: string;
  IsSorted: boolean;
  IsSortable: boolean;
}
//let salesLeadModelNumber: any = '';
//let salesLeadIdQuery: any = '';
export const FormRacstrap: React.FC = (props: any) => {
  const { featureFlagDetails } = useContext(FeatureFlagContext);
  const classesManageCustomerStyles = ManageCustomerStyles();
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const classes = customerStyles();
  const search = useLocation().search;
  const [canSuggestAddress, setCanSuggestAddress] = useState(false);
  const [cantSuggestAddress, setCantSuggestAddress] = useState(false);
  // salesLeadIdQuery =
  //   new URLSearchParams(search).get('salesLeadId') !== null &&
  //   (salesLeadIdQuery == undefined ||
  //     salesLeadIdQuery == null ||
  //     salesLeadIdQuery == '')
  //     ? new URLSearchParams(search).get('salesLeadId')
  //     : salesLeadIdQuery;
  const customerIdQueryParams: any =
    new URLSearchParams(search).get("customerId") !== null
      ? new URLSearchParams(search).get("customerId")
      : null;
  const customerIdSrc: any =
    new URLSearchParams(search).get("src") !== null
      ? new URLSearchParams(search).get("src")
      : null;
  const agrQuery =
    new URLSearchParams(search).get("agrId") !== null &&
    new URLSearchParams(search).get("agrId") !== undefined &&
    new URLSearchParams(search).get("agrId") !== ""
      ? new URLSearchParams(search).get("agrId")
      : null;
  const [IsRunDE, setIsRunDE] = useState<boolean>(false);
  const [ssnValid, setSsnValid] = useState(false);
  const [agreements, setagreements] = useState<any>([]);
  const [postnodeReason, setpostnodeReason] = useState("0");
  const [storeExceptionReason, setStoreExceptionReason] = useState<any>("");

  const [custID, setcustID] = useState<any>(undefined);
  const [showcocustomerpopup, Setshowcocustomerpopup] = useState(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const dt = new Date();
  //const [salesLeadIdQuery, setsalesLeadIdQuery] = useState<any>('');
  const [salesLeadModelNumber, setsalesLeadModelNumber] = useState<any>("");
  const [ssonpopupforRunDE, setssonpopupforRunDE] = useState<any>(false);
  // customer validation page store
  const {
    searchVal,
    page1,
    validateEnablebtn,
    validatedisabledbtn,
    runValidbtn,
    runCustbtn,
    clearbtn,
    destatus,
    cName,
    clrBtnDisable,
    customerId,
    customerQsrc,
    agrQ,
    inventory,
    setsaleLeadCoCreate,
  } = useContext(customerValidationContext);
  const [formField, setFormField] = useState<any>({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    emailAddress: "",
    mobilePhoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "0",
    zipCode: "",
    ssn1: "",
    ssn2: "",
    ssn3: "",
  });
  const [fieldValidationforblur, setfieldValidation] = useState<any>({
    dateOfBirth: false,
    emailAddress: false,
    mobilePhoneNumber: false,
    zipCode: false,
  });
  const [blurLoaderActive, setblurLoaderActive] = useState<any>(false);
  const [checkBoxActive, setcheckBoxActive] = useState<any>(undefined);
  //const [customerIdQ, setcustomerIdQ] = useState<any>(null);
  const [sortOrder, setSortOrder] = useState<any>({ fN: "asc", lN: "asc" });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValues, setsearchVal] = searchVal;
  const [salesLeadSelected, setSalesLeadSelected] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableValidatebtn, setenableValidatebtn] = validateEnablebtn;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disabledValidatebtn, setdisabledValidatebtn] = validatedisabledbtn;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [runValidatebtn, setrunValidatebtn] = runValidbtn;
  const [runNewCustomerbtn, setrunNewCustomerbtn] = runCustbtn;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerIdQ, setcustomerIdQ] = customerId;
  const [customerSrc, setcustomerSrc] = customerQsrc;
  const [agrId, setagrId] = agrQ;
  const [selectedinventoryNumber, setselectedinventoryNumber] = inventory;
  const [runclearbtn, setrunclearbtn] = clearbtn;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [DEStatus, setDEStatus] = destatus;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [clearbtndisable, setclearbtndisable] = clrBtnDisable;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerValidation, setcustomerValidation] = page1;
  const [newCustValidBtnHidden, setnewCustValidBtnHidden] = useState<any>(true);
  // const [FirstName, setFirstName] = useState('');
  // const [LastName, setLastName] = useState('');
  // const [Dob, SetDob] = useState('');
  const [clearbtnHidden, setclearbtnHidden] = useState(false);
  // const [Ssn1, setssn1] = useState('');
  // const [Ssn2, setssn2] = useState('');
  // const [Ssn3, setssn3] = useState('');
  // const [Phonenumber, setPhonenumber] = useState('');
  const [PhonenumberforrcustValidatoion, setPhonenumberforrcustValidatoion] =
    useState("");

  // const [emailaddress, setemailaddress] = useState('');
  // const [Addressline1, setAddressline1] = useState('');
  // const [Addressline2, setAddressline2] = useState('');
  // const [Apt, setApt] = useState('');
  // const [Pobox, Setpobox] = useState('');
  // const [zip, setzip]: any = useState('');
  // const [city, setcity] = useState('');
  // const [stateinfo, setstateinfo] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SearchCustomerActive, setSearchCustomerActive] = useState(false);
  const [Basicinfoactive, setbasicinfoactive] = useState(false);
  const [initialNoRecPopUp, setinitialNoRecPopUp] = useState<any>(true);

  const [emailerrMsg, setemailerrMsg] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Buttonactive, SetButtonactive] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Isdisabled, setIsdisabled] = useState(true);
  const [statedisabled, setstatedisabled] = useState(true);
  const [searchResults, setsearchResults] = useState<any>([]);
  const [ValidateIsOpen, setValidateIsOpen] = useState(false);
  const [depopupisopen, setdepopupisopen] = React.useState(false);
  const [custpopup, setcustpopup] = useState(false);
  const [liketoopen, setliketoopen] = React.useState(false);
  const [enterssnisopen, setenterssnisopen] = React.useState(false);
  const [checkSSNEnter, setcheckSSNEnter] = React.useState(false);
  const [reasonDEopen, setreasonDEopen] = React.useState(false);
  const [dedeclineisopen, setdedeclineisopen] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [FinishVisible, setFinishVisible] = useState(false);
  const [SocialSecurityBorderOne, setSocialSecurityBorderOne] =
    useState("none");
  const [SocialSecurityBorderTwo, setSocialSecurityBorderTwo] =
    useState("none");
  const [SocialSecurityBorderThree, setSocialSecurityBorderThree] =
    useState("none");
  console.log(featureFlagDetails, "featureFlagDetails in customer validation");
  const [defaultSuggestedAddress, setdefaultSuggestedAddress] = useState("SUG");
  const loginStore = sessionStorage.getItem("storeNumber");
  const [webLeads, setWebLeads] = useState<any>([]);
  const [noDEReasonDD, setnoDEReasonDD] = useState<any>([]);
  const [stateDD, setDD] = useState<any>([]);

  const [searchBTNClicked, setsearchBTNClicked] = useState<any>(false);
  //searchBTNClicked
  const [showCustomerAgrIndex, setshowCustomerAgrIndex] =
    useState<any>(undefined);
  const [SuggestedAddress, setSuggestedAddress] = useState<any>();
  const [searchResultActive, setsearchResultActive] = useState<any>(false);
  const [isclsAlertMsg, setisclsAlertMsg] = useState<any>(false);
  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);
  const [liketoCreateAgreement, setliketoCreateAgreement] =
    useState<any>(false);
  const [somethingone, setsomethingone] = React.useState(
    "modal fade show expandPopup modal-top"
  );
  const [somethingtwo, setsomethingtwo] = React.useState(
    "modal fade modal-top"
  );
  const [validatedAddresses, setValidatedAddress] = useState<any>({});
  const [salesLeadIdQuery, setsalesLeadId] = useState<any>(undefined);
  const [addressErrorMsg, setAddressErrorMsg] = useState<any>("");
  const [suggestionAddresPopup, setSuggestionAddresPopup] = useState(false);
  const [refundResponseMsg, setrefundResponseMsg] = useState<any>("");
  const [refundpopup, setrefundpopup] = useState(false);
  const [comingSoonPopup, setcomingSoonPopup] = useState<boolean>(false);
  const [agreementMandatoryFieldpopup, setagreementMandatoryFieldpopup] =
    useState<boolean>(false);
  const [leadExpDate, setLeadExpDate] = useState<any>(21);
  const [coWorkerRole, setcoWorkerRole] = useState<any>();
  const [legalHoldStatus, setlegalHoldStatus] = useState<any>(false);
  const [wLeadResponse, setwLeadResponse] = useState<any>();
  const [createCustomerByGCIDPopup, setcreateCustomerByGCIDPopup] =
    useState<any>(false);
  const [checkBoxgcid, setcheckBoxgcid] = useState<any>();
  const [activeGcid, setactiveGcid] = useState<any>(undefined);
  const [matchCode, setmatchCode] = useState<any>("");
  const [createcustomerPopup, setcreatecustomerPopup] = useState<any>(false);
  const [agrDetails, setagrDetails] = useState<any>();
  const [webleadPaymentId, setwebleadPaymentId] = useState<any>(null);
  const pendingEsclatedLeadHeaderList = [
    "",
    "Last Name",
    "First Name",
    "Phone#",
    "Product",
    "Description",
    "Expiration Date",
    "WL Deposit",
    "Order ID",
    "Text Response",
  ];
  // const propsdata = {
  //   newfirstname: FirstName,
  //   newlastname: LastName,
  //   newSsn1: Ssn1,
  //   newSsn2: Ssn2,
  //   newSsn3: Ssn3,
  //   newemail: emailaddress,
  //   newAddress1: Addressline1,
  //   newAddress2: Addressline2,
  //   newApt: Apt,
  //   newPobox: Pobox,
  //   newZip: zip,
  //   newcity: city,
  //   newstate: stateinfo,
  //   newphonenumber: Phonenumber,
  //   rawPoneNumber: PhonenumberforrcustValidatoion,
  // };
  useEffect(() => {
    console.log("props 318", props);
    //GetLeadExpirationDays();
    setenableValidatebtn(true);
    setdisabledValidatebtn(true);
    setcustomerIdQ(customerIdQueryParams);
    console.log("customerIdSrc", customerIdSrc);
    setcustomerSrc(customerIdSrc);
    console.log("agrQuery", agrQuery);
    setagrId(agrQuery);
    ////debugger
    // eslint-disable-next-line no-console
    console.log("use effect for header class");
    const salesLeadIdQuery =
      new URLSearchParams(search).get("salesLeadId") !== null &&
      new URLSearchParams(search).get("salesLeadId") !== undefined &&
      new URLSearchParams(search).get("salesLeadId") !== ""
        ? new URLSearchParams(search).get("salesLeadId")
        : "";
    console.log("salesLeadIdQuery", salesLeadIdQuery);
    const salesLeadModelNumber =
      new URLSearchParams(search).get("modelNumber") !== null &&
      new URLSearchParams(search).get("modelNumber") !== undefined &&
      new URLSearchParams(search).get("modelNumber") !== ""
        ? new URLSearchParams(search).get("modelNumber")
        : "";
    const gcid =
      new URLSearchParams(search).get("gcid") !== null &&
      new URLSearchParams(search).get("gcid") !== undefined &&
      new URLSearchParams(search).get("gcid") !== ""
        ? new URLSearchParams(search).get("gcid")
        : "";
    //debugger;
    if (
      salesLeadIdQuery !== undefined &&
      salesLeadIdQuery !== null &&
      salesLeadIdQuery !== "" &&
      gcid !== undefined &&
      gcid !== null &&
      gcid !== ""
    ) {
      setsalesLeadId(salesLeadIdQuery);
      getCustomerByGCID(gcid, salesLeadIdQuery);
    } else if (
      salesLeadIdQuery == undefined ||
      salesLeadIdQuery == null ||
      salesLeadIdQuery == ""
    ) {
      GetWebLeadS();
    } else if (customerIdQueryParams == "" || customerIdQueryParams == null) {
      GetWebL(salesLeadIdQuery);
    }
    const getCoWorkerRole = async () => {
      try {
        if (containerData !== undefined) {
          console.log("containerData", containerData);
          console.log("containerDataRole", containerData.GetRole());
          const roleObj = containerData.GetRole();
          setcoWorkerRole(
            roleObj == "SM" ||
              roleObj == "CAR" ||
              roleObj == "AM" ||
              roleObj == "LAM" ||
              roleObj == "DMT"
              ? 1
              : roleObj == "DM" || roleObj == "RD"
              ? 2
              : 0
          );
        } else {
          const coWorkerRoleObj: any = await getmenuandstore();
          if (
            coWorkerRoleObj?.coworkerProfile !== undefined &&
            coWorkerRoleObj?.coworkerProfile !== null &&
            coWorkerRoleObj?.coworkerProfile.role !== undefined &&
            coWorkerRoleObj?.coworkerProfile.role !== null
          ) {
            const roleObj = coWorkerRoleObj?.coworkerProfile.role;
            // setcoWorkerRole(roleObj);
            setcoWorkerRole(
              roleObj == "SM" ||
                roleObj == "CAR" ||
                roleObj == "AM" ||
                roleObj == "LAM" ||
                roleObj == "DMT"
                ? 1
                : roleObj == "DM" || roleObj == "RD"
                ? 2
                : 0
            );
          }
        }
      } catch (e: any) {
        /* eslint-disable no-console */
        console.log("Error in co worker role");
      }
    };
    setsalesLeadModelNumber(salesLeadModelNumber);
    setwebleadPaymentId(props?.location?.state);
    setsalesLeadId(salesLeadIdQuery);
    GetStateDD();
    // GetId(salesLeadIdQuery);
    getCoWorkerRole();
    fieldValidation();
    setselectedinventoryNumber(props?.location?.state);
    console.log("props Val", props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCustomerByGCID = async (gcid: any, salesLeadIdQuery: any) => {
    const payload: any = {
      globalCustomerId: gcid,
    };
    setblurLoaderActive(true);
    try {
      const res: any = await GetSearchResult(payload);
      setblurLoaderActive(false);
      if (
        res.GetCustomers !== undefined &&
        res.GetCustomers.customers !== undefined &&
        res.GetCustomers.customers.length !== undefined &&
        res.GetCustomers.customers.length > 0
      ) {
        res.GetCustomers.customers[0].salesLeadId = salesLeadIdQuery;
        setagrDetails(res.GetCustomers.customers);
        setcreatecustomerPopup(true);
        console.log("getCustomerByGCID res", res.GetCustomers.customers[0]);
      } else {
        GetWebL(salesLeadIdQuery);
      }
    } catch (e: any) {
      console.log(e);
      setblurLoaderActive(false);
    }
  };
  const GetWebL = async (salesLeadIdQuery: any) => {
    setblurLoaderActive(true);
    await webLeadById(salesLeadIdQuery);
    setblurLoaderActive(false);
  };
  const getCoWorkerRole = async () => {
    try {
      // eslint-disable-next-line
      // //debugger
      //if (isLegalHold) {
      const coWorkerRoleObj: any = await getmenuandstore();
      if (
        coWorkerRoleObj?.coworkerProfile !== undefined &&
        coWorkerRoleObj?.coworkerProfile !== null &&
        coWorkerRoleObj?.coworkerProfile.role !== undefined &&
        coWorkerRoleObj?.coworkerProfile.role !== null
      ) {
        const roleObj = coWorkerRoleObj?.coworkerProfile.role;
        // setcoWorkerRole(roleObj);
        setcoWorkerRole(
          roleObj == "SM" ||
            roleObj == "CAR" ||
            roleObj == "AM" ||
            roleObj == "LAM" ||
            roleObj == "DMT"
            ? 1
            : roleObj == "DM" || roleObj == "RD"
            ? 2
            : 0
        );
      }
      /* eslint-disable no-console */
      console.log("coWorkerRoleObj", coWorkerRoleObj);
      // } else {
      //   setLegalHoldApplied(0);
      // }
    } catch (e: any) {
      /* eslint-disable no-console */
      console.log("Error in co worker role");
    }
  };
  // const GetLeadExpirationDays = async () => {
  //   const payload: any = {
  //     storeNumbers: [],
  //     paramKeyNames: ['Sales_Lead_Expiration_Days'],
  //   };
  //   payload.storeNumbers.push(loginStore);
  //   try {
  //     const response = await GetSalesLeadExpirationDays(payload);
  //     console.log('salesLeadExp Res', response);
  //     if (
  //       response.data.storeProfileResponse !== undefined &&
  //       response.data.storeProfileResponse !== null &&
  //       response.data.storeProfileResponse !== '' &&
  //       response.data.storeProfileResponse.configDetails !== undefined &&
  //       response.data.storeProfileResponse.configDetails !== null &&
  //       response.data.storeProfileResponse.configDetails !== '' &&
  //       response.data.storeProfileResponse.configDetails[0].configDetails !==
  //         undefined &&
  //       response.data.storeProfileResponse.configDetails[0].configDetails !==
  //         null &&
  //       response.data.storeProfileResponse.configDetails[0].configDetails !==
  //         '' &&
  //       response.data.storeProfileResponse.configDetails[0].configDetails[0]
  //         .paramValue !== undefined &&
  //       response.data.storeProfileResponse.configDetails[0].configDetails[0]
  //         .paramValue !== null &&
  //       response.data.storeProfileResponse.configDetails[0].configDetails[0]
  //         .paramValue !== ''
  //     ) {
  //       console.log('salesLeadExp Res True', response);
  //       setLeadExpDate(
  //         response.data.storeProfileResponse.configDetails[0].configDetails[0]
  //           .paramValue
  //       );
  //     } else {
  //       setLeadExpDate(21);
  //     }
  //   } catch (err) {
  //     setLeadExpDate(21);
  //   }
  // };
  const webLeadById = async (id: any) => {
    try {
      ////debugger;
      // setblurLoaderActive(true);
      const response = await GetWebLeadById(id);
      console.log("webloead res", response);
      const responseObj: any = {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        emailAddress: "",
        mobilePhoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipCode: "",
        ssn1: "",
        ssn2: "",
        ssn3: "",
      };
      // setblurLoaderActive(false);
      if (response !== undefined && response !== null && response !== "") {
        setwLeadResponse(response);
        setsaleLeadCoCreate(response);
        if (
          response.firstName !== undefined &&
          response.firstName !== null &&
          response.firstName !== ""
        ) {
          responseObj.firstName = response.firstName;
          //setFormField({ ...formField, firstName: response.firstName });
          //setFirstName(response.firstName);
        }
        if (
          response.lastName !== undefined &&
          response.lastName !== null &&
          response.lastName !== ""
        ) {
          responseObj.lastName = response.lastName;
          //setFormField({ ...formField, lastName: response.lastName });
          //setLastName(response.lastName);
        }
        if (
          response.dateOfBirth !== undefined &&
          response.dateOfBirth !== null &&
          response.dateOfBirth !== ""
        ) {
          responseObj.dateOfBirth = response.dateOfBirth;
          //setFormField({ ...formField, dateOfBirth: response.dateOfBirth });
          //  SetDob(response.dateOfBirth);
        }
        if (
          response.emailAddress !== undefined &&
          response.emailAddress !== null &&
          response.emailAddress !== ""
        ) {
          //setFormField({ ...formField, emailAddress: response.emailAddress });
          responseObj.emailAddress = response.emailAddress;
          //setemailaddress(response.emailAddress);
        }
        if (
          response.mobilePhoneNumber !== undefined &&
          response.mobilePhoneNumber !== null &&
          response.mobilePhoneNumber !== ""
        ) {
          // setFormField({
          //   ...formField,
          //   mobilePhoneNumber: response.mobilePhoneNumber.replace(
          //     /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          //     // eslint-disable-next-line sonarjs/no-duplicate-string
          //     '($1) $2-$3'
          //   ),
          // });
          responseObj.mobilePhoneNumber = response.mobilePhoneNumber.replace(
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            // eslint-disable-next-line sonarjs/no-duplicate-string
            "($1) $2-$3"
          );
          // setPhonenumber(
          //   response.mobilePhoneNumber.replace(
          //     /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          //     // eslint-disable-next-line sonarjs/no-duplicate-string
          //     '($1) $2-$3'
          //   )
          // );
        }
        if (
          response.address !== undefined &&
          response.address !== null &&
          response.address !== ""
        ) {
          if (
            response.address.addressLine1 !== undefined &&
            response.address.addressLine1 !== null &&
            response.address.addressLine1 !== ""
          ) {
            // setFormField({
            //   ...formField,
            //   addressLine1: response.address.addressLine1,
            // });
            responseObj.addressLine1 = response.address.addressLine1;
            //setAddressline1(response.address.addressLine1);
          }
          if (
            response.address.addressLine2 !== undefined &&
            response.address.addressLine2 !== null &&
            response.address.addressLine2 !== ""
          ) {
            // setFormField({
            //   ...formField,
            //   addressLine2: response.address.addressLine2,
            // });
            responseObj.addressLine2 = response.address.addressLine2;
            //setAddressline2(response.address.addressLine2);
          }
          if (
            response.address.city !== undefined &&
            response.address.city !== null &&
            response.address.city !== ""
          ) {
            //setFormField({ ...formField, city: response.address.city });
            responseObj.city = response.address.city;
            //setcity(response.address.city);
          }
          if (
            response.address.state !== undefined &&
            response.address.state !== null &&
            response.address.state !== ""
          ) {
            //setFormField({ ...formField, state: response.address.state });
            responseObj.state = response.address.state;
            // setstateinfo(response.address.state);
          }
          if (
            response.address.zipCode !== undefined &&
            response.address.zipCode !== null &&
            response.address.zipCode !== ""
          ) {
            //setFormField({ ...formField, zipCode: response.address.zipCode });
            responseObj.zipCode = response.address.zipCode;
            // setzip(response.address.zipCode);
          }
          if (
            response.socialSecurityNumber !== undefined &&
            response.socialSecurityNumber !== null &&
            response.socialSecurityNumber !== ""
          ) {
            // setFormField({
            //   ...formField,
            //   ssn1: response.socialSecurityNumber.slice(0, 3),
            //   ssn2: response.socialSecurityNumber.slice(3, 5),
            //   ssn3: response.socialSecurityNumber.slice(5),
            // });

            responseObj.ssn1 = response.socialSecurityNumber.slice(0, 3);
            responseObj.ssn2 = response.socialSecurityNumber.slice(3, 5);
            responseObj.ssn3 = response.socialSecurityNumber.slice(5);
            // setssn1(response.socialSecurityNumber.slice(0, 3));
            // setssn2(response.socialSecurityNumber.slice(3, 5));
            // setssn3(response.socialSecurityNumber.slice(5));
          }
        }
      }
      //debugger;
      setFormField(responseObj);
      setsalesLeadModelNumber(response.modelNumber);
      setsalesLeadId(id);
      fieldValidation();
      return responseObj;
      // setblurLoaderActive(false);
      // salesLeadIdQuery = id;
      // salesLeadModelNumber = response.modelNumber;
      console.log("modelnumber", response);
    } catch (err: any) {
      if (err) {
        console.log(err);
      }
    }
  };
  const GetId = () => {
    if (
      salesLeadIdQuery !== undefined &&
      salesLeadIdQuery !== null &&
      salesLeadIdQuery !== ""
    ) {
      setsalesLeadId(salesLeadIdQuery);
    } else {
      setsalesLeadId(undefined);
    }
  };
  const GetStateDD = async () => {
    if (loginStore !== undefined) {
      const res1 = await GetState();
      if (res1 !== null && res1.length > 0) {
        const buildStateDropDown: any[] = [];
        res1.map((value: any, index: any) => {
          if (index == 0) {
            buildStateDropDown.push({ label: "Select", value: "0" });
          }
          buildStateDropDown.push({
            label: value.stateAbbreviation,
            value: value.stateAbbreviation,
          });
        });
        // const stateDDStructure = res1.map((el: any, i: any) => {
        //   res1[i].description = el.stateAbbreviation;
        //   res1[i].label = el.stateAbbreviation;
        //   res1[i].value = el.stateAbbreviation;
        //   return el;
        // });
        // console.log('stateDDStructure', stateDDStructure);
        // const samp = { description: 'Select', label: 'Select', value: '0' };
        // stateDDStructure.unshift(samp);
        setDD(buildStateDropDown);
        console.log("DDStructure", buildStateDropDown);
        setDropDownLoaded(true);
        // return stateDD.map((el: any, i: any) => {
        //   return (
        //     <option key={i} value={el.stateAbbreviation}>
        //       {el.stateAbbreviation}
        //     </option>
        //   );
        // });
      } else {
        setDropDownLoaded(true);
        setDD([]);
      }
      // eslint-disable-next-line no-console
      console.log("state DD", res1);

      // setDD(res1);
    }
  };
  const GetWebLeadS = async () => {
    // const EndDate: any = new Date();
    // //eslint-disable-next-line no-console
    // console.log('endDate', EndDate);
    // const endyear = EndDate.getFullYear();
    // const endmonth = ('0' + (EndDate.getMonth() + 1)).slice(-2);
    // const endday = ('0' + EndDate.getDate()).slice(-2);
    // const EndDateReq: any = `${endyear}/${endmonth}/${endday}`;
    // const StartDateNew: any = new Date().setDate(
    //   EndDate.getDate() - parseInt(leadExpDate)
    // );
    // const date = new Date(StartDateNew);
    // const year = date.getFullYear();
    // const month = ('0' + (date.getMonth() + 1)).slice(-2);
    // const day = ('0' + date.getDate()).slice(-2);
    // const startDateReq: any = `${year}/${month}/${day}`;
    // console.log('webleadExp', leadExpDate);
    const req: any = {
      storeNumbers: [],
      leadStatues: [
        "New",
        "Text Sent",
        "Call Made",
        "Commitment",
        "Follow-Up",
        "Transferred from Agent",
      ],
      leadSource: ["Web"],
      // startDate: startDateReq,
      // endDate: EndDateReq,
      numberOfDays: "30",
      escalateOrderFlag: "0",
      includeConversion: "1",
    };
    //leadExpDate
    if (loginStore !== undefined) {
      req.storeNumbers.push(loginStore);
    }
    // req.storeNumbers = storeNumbers;
    const res = await GetWebLead(req);
    // eslint-disable-next-line no-console
    console.log(res);

    if (!res || !res.resForWebLeads || !res.resForWebLeads.leads) {
      setWebLeads([]);
      return;
    }
    const getWebLeadDetail = (webLeadId: any) => {
      let wlead = "";
      if (!webLeadId) return "";
      res?.refundStatus?.value?.webleads.forEach((webLead: any) => {
        const key = String(webLead.orderNumber);
        if (
          (key.startsWith("0") && key == webLeadId) ||
          String(webLeadId) == String(webLead.entWebLeadPaymentId)
        ) {
          wlead = webLead;
        }
      });
      return wlead;
    };
    if (
      res.refundStatus &&
      res.refundStatus.value &&
      res.refundStatus.value.webleads
    ) {
      const refundProp: any = res.resForWebLeads.leads.map(
        (el: any, i: any) => {
          const mappedWebLeadElement: any = getWebLeadDetail(
            el.webleadPaymentId
          );
          el.webLeadRes = mappedWebLeadElement;
          if (
            !mappedWebLeadElement ||
            mappedWebLeadElement?.paymentInfo?.balanceAmount == 0 ||
            !mappedWebLeadElement?.paymentInfo?.externalTransactionId
          ) {
            el.type = undefined;
            el.refundName = undefined;
            return el;
          }
          if (
            ["PR", "PA", "REFUND"].includes(
              mappedWebLeadElement.status.toUpperCase()
            )
          ) {
            el.type = undefined;
            el.refundName = "Reimbursed";
            return el;
          }
          if (
            ["NEW", "ATT"].includes(mappedWebLeadElement.status.toUpperCase())
          ) {
            let type = "";
            if (
              el.downPaymentAmount &&
              el.inventories &&
              el.inventories.length > 0 &&
              el.inventories[0].inventoryNumber
            ) {
              type = "Reserved";
            } else if (
              el.inventories &&
              el.inventories.length > 0 &&
              el.inventories[0].modelNumber &&
              !el.inventories[0].inventoryNumber
            ) {
              type = "Cust Order";
            }
            if (el.leadStatus && el.leadStatus == "Converted") {
              el.downPaymentAmount =
                mappedWebLeadElement?.paymentInfo?.balanceAmount;
            } else {
              el.downPaymentAmount =
                mappedWebLeadElement?.paymentInfo?.downPaymentAmount;
            }
            el.type = type;
            el.refundName = "Refund";
          }
          return el;
        }
      );
      console.log("refundProp", refundProp);
      const leadWithoutConverted: any = refundProp.filter((el: any, i: any) => {
        if (
          el.leadStatus !== undefined &&
          el.leadStatus !== "" &&
          el.leadStatus !== null &&
          el.leadStatus == "Converted" &&
          el.leadConvertedDate !== null &&
          el.leadConvertedDate !== undefined &&
          el.leadConvertedDate !== ""
        ) {
          const leadConvertedDate =
            el.leadConvertedDate !== null && el.leadConvertedDate !== undefined
              ? el.leadConvertedDate
              : "-";
          const now = +new Date();
          const createdAt = +new Date(Date.parse(leadConvertedDate.toString()));

          const oneDay = 24 * 60 * 60 * 1000;
          const diffDays = moment(dt).diff(
            moment(el.leadConvertedDate, "YYYY-MM-DD"),
            "days"
          );
          console.log("diffDays", diffDays);
          console.log("now date", now);
          console.log("createdAt date", createdAt);
          console.log("oneDay date", oneDay);
          if (now - createdAt < oneDay) {
            return el;
          }
        } else {
          return el;
        }
      });
      const sortedWebLead: any = leadWithoutConverted.sort((a: any, b: any) =>
        a.createdDate > b.createdDate ? -1 : 1
      );
      const reimburesedLead: any = sortedWebLead.filter((el: any, i: any) => {
        if (
          el.refundName == "Reimbursed" ||
          (el.refundName == "Reimbursed" && el.leadStatus == "Converted")
        ) {
          return el;
        }
      });
      const convertedLead: any = sortedWebLead.filter((el: any, i: any) => {
        if (
          el.leadStatus !== undefined &&
          el.leadStatus !== "" &&
          el.leadStatus !== null &&
          el.leadStatus == "Converted" &&
          el.refundName !== "Reimbursed"
        ) {
          return el;
        }
      });
      const leads: any = sortedWebLead.filter((el: any, i: any) => {
        if (
          el.leadStatus !== undefined &&
          el.leadStatus !== "" &&
          el.leadStatus !== null &&
          el.leadStatus !== "Converted" &&
          el.refundName !== "Reimbursed"
        ) {
          return el;
        }
      });
      console.log("leadsArr", leads);
      setWebLeads([...leads, ...reimburesedLead, ...convertedLead]);
    } else if (res.refundStatus !== undefined && res.refundStatus !== null) {
      const leadWithoutConverted: any = res.resForWebLeads.leads.filter(
        (el: any, i: any) => {
          if (
            el.leadStatus !== undefined &&
            el.leadStatus !== "" &&
            el.leadStatus !== null &&
            el.leadStatus == "Converted" &&
            el.leadConvertedDate !== null &&
            el.leadConvertedDate !== undefined &&
            el.leadConvertedDate !== ""
          ) {
            const leadConvertedDate =
              el.leadConvertedDate !== null &&
              el.leadConvertedDate !== undefined
                ? el.leadConvertedDate
                : "-";
            const now = +new Date();
            const createdAt = +new Date(
              Date.parse(leadConvertedDate.toString())
            );

            const oneDay = 24 * 60 * 60 * 1000;
            const diffDays = moment(dt).diff(
              moment(el.leadConvertedDate, "YYYY-MM-DD"),
              "days"
            );
            console.log("diffDays", diffDays);
            console.log("now date", now);
            console.log("createdAt date", createdAt);
            console.log("oneDay date", oneDay);
            if (now - createdAt < oneDay) {
              return el;
            }
          } else {
            return el;
          }
        }
      );
      const sortedWebLead: any = leadWithoutConverted.sort((a: any, b: any) =>
        a.createdDate > b.createdDate ? -1 : 1
      );
      const reimburesedLead: any = sortedWebLead.filter((el: any, i: any) => {
        if (
          el.refundName == "Reimbursed" ||
          (el.refundName == "Reimbursed" && el.leadStatus == "Converted")
        ) {
          return el;
        }
      });
      const convertedLead: any = sortedWebLead.filter((el: any, i: any) => {
        if (
          el.leadStatus !== undefined &&
          el.leadStatus !== "" &&
          el.leadStatus !== null &&
          el.leadStatus == "Converted" &&
          el.refundName !== "Reimbursed"
        ) {
          return el;
        }
      });
      const leads: any = sortedWebLead.filter((el: any, i: any) => {
        if (
          el.leadStatus !== undefined &&
          el.leadStatus !== "" &&
          el.leadStatus !== null &&
          el.leadStatus !== "Converted" &&
          el.refundName !== "Reimbursed"
        ) {
          return el;
        }
      });
      setWebLeads([...leads, ...reimburesedLead, ...convertedLead]);
    }
  };
  useEffect(() => {
    const runWebLead = async () => {
      if (
        salesLeadIdQuery !== undefined &&
        salesLeadIdQuery !== salesLeadSelected &&
        (customerIdQueryParams == undefined ||
          customerIdQueryParams == null ||
          customerIdQueryParams == "")
      ) {
        console.log(
          "salesLeadSelected, salesLeadIdQuery",
          salesLeadSelected,
          salesLeadIdQuery
        );
        //setSalesLeadSelected(salesLeadId);
        await webLeadById(salesLeadIdQuery);
      }
    };
    runWebLead();
    console.log("use 1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesLeadIdQuery]);
  useEffect(() => {
    console.log("use 2", searchValues);
    //debugger;
    if (runNewCustomerbtn) {
      NewCustomerClick();
    }
    if (runclearbtn) {
      clear();
    }
    setFormField({
      ...formField,
      firstName: searchValues.firstName ? searchValues.firstName : "",
      lastName: searchValues.lastName ? searchValues.lastName : "",
      dateOfBirth: searchValues.dob ? searchValues.dob : "",
      ssn1: searchValues.ssn1 ? searchValues.ssn1 : "",
      ssn2: searchValues.ssn2 ? searchValues.ssn2 : "",
      ssn3: searchValues.ssn3 ? searchValues.ssn3 : "",
      mobilePhoneNumber: searchValues.phoneNumber
        ? searchValues.phoneNumber
        : "",
      addressLine1: searchValues.addressLine1 ? searchValues.addressLine1 : "",
      addressLine2: searchValues.addressLine2 ? searchValues.addressLine2 : "",
      city: searchValues.city ? searchValues.city : "",
      zipCode: searchValues.zip ? searchValues.zip : "",
      state: searchValues.state ? searchValues.state : ""
    });
    if (
      searchValues.dob !== undefined &&
      searchValues.dob !== null &&
      searchValues.dob != ""
    ) {
      const stDate = moment(searchValues.dob, "YYYY-MM-DD");
      const lt18 = moment(moment().subtract(18, "years"), "YYYY-MM-DD");
      const lt110 = moment(moment().subtract(110, "years"), "YYYY-MM-DD");
      const lt18Res: any = lt18.diff(stDate, "days");
      const lt110Res: any = lt110.diff(stDate, "days");
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      setfieldValidation({
        ...fieldValidationforblur,
        dateOfBirth: !res,
      });
    }
    console.log("props 1116", props);
    // eslint-disable-next-line no-console
    console.log("use effect for footer click and bind the search value");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValues, runValidatebtn, runNewCustomerbtn]);
  const GetNoDEReason = async () => {
    setDropDownLoaded(false);
    const res: any = await GetNODEReason();
    // eslint-disable-next-line no-console
    console.log(res);
    if (res !== undefined && res.referenceDetails !== undefined) {
      const sortedlist = res.referenceDetails.sort((a: any, b: any) =>
        a["description"] < b["description"] ? -1 : 1
      );
      const referenceDetailsObj: any = [];
      sortedlist.map((value: any, index: any) => {
        if (index === 0) {
          referenceDetailsObj.push({
            label: "Select",
            value: "0",
          });
        }
        referenceDetailsObj.push({
          label: value.description,
          value: value.referenceCode,
        });
      });
      // const stateDDStructure = sortedlist.map((el: any, i: any) => {
      //   if(i== 0){

      //   }
      //   res.referenceDetails[i].label = el.description;
      //   res.referenceDetails[i].value = el.referenceCode;
      //   return el;
      // });
      setnoDEReasonDD(referenceDetailsObj);
      setDropDownLoaded(true);
    }
  };
  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const getSearchValues = async (payload: any) => {
    // eslint-disable-next-line no-console
    console.log("payload", payload);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
    const res = await GetSearchResult(payload);
    // eslint-disable-next-line no-console
    console.log("data", res);
    if (res.GetCustomers.customers.length === 0) {
      setblurLoaderActive(false);
      setsearchResultActive(false);
      setenableValidatebtn(true);
      setcustpopup(true);
    } else if (
      res.GetCustomers !== undefined &&
      res.GetCustomers.customers !== undefined &&
      res.GetCustomers.customers.length !== undefined &&
      res.GetCustomers.customers.length > 0
    ) {
      setblurLoaderActive(false);
      setsearchResultActive(false);
      setenableValidatebtn(false);
      setcustpopup(false);
      const getCustomerList: any = [];
      res.GetCustomers.customers.forEach((customer: any) => {
        const agreementCount =
          customer.agreementCounts !== undefined
            ? activeAgreementCount(customer.agreementCounts)
            : 0;
        customer.AgreementCount = agreementCount;
        getCustomerList.push(customer);
      });
      // getCustomerList = getCustomerList.sort((a: any, b: any) =>
      //   a['firstName'] < b['firstName'] ? -1 : 1
      // );
      // let getCustomerOrginStore = getCustomerList.filter(
      //   (value: any) => value.originStore == loginStore
      // );
      // getCustomerOrginStore = getCustomerOrginStore.sort((a: any, b: any) =>
      //   a['AgreementCount'] < b['AgreementCount'] ? -1 : 1
      // );
      // const getCustomerNonOrginStore = getCustomerList.filter(
      //   (value: any) => value.originStore != loginStore
      // );
      // getCustomerList = [];
      // getCustomerOrginStore.forEach((element: any) => {
      //   getCustomerList.push(element);
      // });
      // getCustomerNonOrginStore.forEach((element: any) => {
      //   getCustomerList.push(element);
      // });
      console.log("getCustomerList", getCustomerList);
      setsearchResults(getCustomerList);
    } else {
      setsearchResults([]);
    }
    /* eslint-disable no-console */
    console.log(res);
    /* eslint-enable no-console */
  };
  const sortGrid = (columnToSort: any, col: any) => {
    //debugger;
    const searchResultslocal = searchResults;
    const sortedData =
      col == "FN"
        ? searchResultslocal.sort(function (a: any, b: any) {
            const x: any =
                a["firstName"] !== undefined && a["firstName"] !== null
                  ? a["firstName"].toUpperCase()
                  : "",
              y =
                b["lastName"] !== undefined && b["lastName"] !== null
                  ? b["lastName"].toUpperCase()
                  : "";
            // if (x == y) {
            //   return 0;
            // } else if (x > y && columnToSort.fN != 'asc') {
            //   return 1;
            // } else if (x > y && columnToSort.fN == 'asc') {
            //   return -1;
            // }
            return x == y ? 0 : x > y && columnToSort.fN != "asc" ? 1 : -1;
          })
        : searchResultslocal.sort(function (a: any, b: any) {
            const x =
                a["firstName"] !== undefined && a["firstName"] !== null
                  ? a["firstName"].toUpperCase()
                  : "",
              y =
                b["lastName"] !== undefined && b["lastName"] !== null
                  ? b["lastName"].toUpperCase()
                  : "";
            // if (x == y) {
            //   return 0;
            // } else if (x > y && columnToSort.fN != 'asc') {
            //   return 1;
            // } else if (x > y && columnToSort.fN == 'asc') {
            //   return -1;
            // }
            return x == y ? 0 : x > y && columnToSort.lN != "asc" ? 1 : -1;
          });
    setsearchResults([...sortedData]);
    //setSortOrder({ fN: 'asc', lN: 'asc' });
    let data: any;
    if (col == "FN") {
      data = { fN: columnToSort, lN: sortOrder.lN };
    } else {
      data = { fN: sortOrder.fN, lN: columnToSort };
    }
    // })
    setSortOrder(data);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const showAgreement = async (i: any, gcId: any) => {
    //debugger;
    /* eslint-disable no-console */
    console.log(gcId);
    //setcheckBoxActive(undefined);
    let res: any = "";
    if (i != showCustomerAgrIndex) {
      res = await getcustomerbyid("C", gcId);
    }
    // eslint-disable-next-line no-console
    console.log("response for customer id", res);
    if (res == undefined) {
      setcheckBoxActive(undefined);
      setshowCustomerAgrIndex(undefined);
    } else {
      setcheckBoxActive(i == showCustomerAgrIndex ? undefined : i);
      setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
      if (i != showCustomerAgrIndex) {
        if (
          res !== undefined &&
          res !== null &&
          res.customerId !== undefined &&
          res.customerId !== null
        ) {
          // eslint-disable-next-line no-console
          console.log("inside if condition", showCustomerAgrIndex);
          setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
          const response = await getAgreement(res.customerId);
          setcheckBoxActive(i == showCustomerAgrIndex ? undefined : i);
          // eslint-disable-next-line no-console
          console.log("response for agreement", response);
        }

        // if (response) {
        //   const res = await getcustomerbyid('C', gcId);
        //   // eslint-disable-next-line no-console
        //   console.log('response for customer id', res);
        //   if (res == undefined) {
        //     setcheckBoxActive(undefined);
        //     setshowCustomerAgrIndex(undefined);
        //   } else {
        //     setcheckBoxActive(i == showCustomerAgrIndex ? undefined : i);
        //     setshowCustomerAgrIndex(i == showCustomerAgrIndex ? undefined : i);
        //   }
        // }
      } else {
        // eslint-disable-next-line no-console
        console.log("inside else condition");
        setcheckBoxActive(i == showCustomerAgrIndex ? i : undefined);
        //setcheckBoxActive(undefined);
        setshowCustomerAgrIndex(undefined);
      }
    }
    /* eslint-enable no-console */
  };
  function showRecordsByStoreNumber(agreement: []) {
    if (agreement.length > 0) {
      const GetStoreByActiveOrPending = agreement.filter((element: any) => {
        if (
          element.agreementStatus !== undefined &&
          (element.agreementStatus.toLowerCase() == "active" ||
            element.agreementStatus.toLowerCase() == "pending")
        ) {
          return element;
        }
      });
      /* eslint-disable no-console */
      console.log("311", GetStoreByActiveOrPending);
      /* eslint-enable no-console */
      const filterStoreNum = GetStoreByActiveOrPending.map(
        (el: any) => el.storeNumber
      );
      /* eslint-disable no-console */
      console.log("317", filterStoreNum);
      /* eslint-enable no-console */
      const uniqueSN = Array.from(new Set(filterStoreNum));
      /* eslint-disable no-console */
      console.log("322", uniqueSN);
      let data: { storeNumber: string; agreements: [] }[] = [];
      if (
        GetStoreByActiveOrPending !== undefined &&
        GetStoreByActiveOrPending.length > 0 &&
        uniqueSN.length > 0
      ) {
        uniqueSN.forEach((val) => {
          const agreementValue = GetStoreByActiveOrPending.filter(
            (value: any) => val == value.storeNumber
          );
          const storeNumberWithAgreement: any = {
            storeNumber: val,
            agreements: agreementValue,
          };
          data.push(storeNumberWithAgreement);
        });
      }
      if (loginStore !== undefined && loginStore !== null) {
        const getCurrentStoreAgreements = data.filter(
          (val: any) => val.storeNumber == loginStore
        );
        const getNotCurrentStoreAgreements = data.filter(
          (val: any) => val.storeNumber != loginStore
        );
        data = [];
        getCurrentStoreAgreements.forEach((agreemts) => {
          data.push(agreemts);
        });
        getNotCurrentStoreAgreements.forEach((agreemts) => {
          data.push(agreemts);
        });
      }
      /* eslint-enable no-console */
      // const data = uniqueSN.filter((element: any) => {
      //   /* eslint-disable no-console */
      //   console.log("326", element);
      //   /* eslint-enable no-console */
      //   storeNumberWithAgreement.storeNumber = element;
      //   agreements.forEach((el: any) => {
      //     if (el.storeNumber == element) {
      //       storeNumberWithAgreement.agreements.push(el);
      //     }
      //   });
      //   /* eslint-disable no-console */
      //   console.log(storeNumberWithAgreement);
      //   /* eslint-enable no-console */
      //   return storeNumberWithAgreement;
      // });
      /* eslint-disable no-console */
      console.log("345", data);
      /* eslint-enable no-console */
      return data;
    }
  }
  const getAgreement = async (id: any) => {
    try {
      setagreements(undefined);
      const res = await GetAgreementById(id, "N");
      /* eslint-disable no-console */
      const agreement =
        res != undefined && res.agreement !== undefined ? res.agreement : [];
      if (!agreement) {
        //showCustomerAgrIndex(undefined);
        setagreements([]);
      } else {
        const agreementList = showRecordsByStoreNumber(res.agreement);
        setagreements(agreementList);
      }
      console.log("getAgreement", res);
      return true;
    } catch (err: any) {
      if (err) {
        setagreements(null);
        return false;
      }
    }
  };
  const checkBoxActiveOnclick = async (index: number, gcd: any) => {
    const agr: any = searchResults.filter((el: any) => {
      if (gcd == el.globalCustomerId) {
        return el;
      }
    });
    setagrDetails(agr);
    setcheckBoxgcid(gcd);
    setcheckBoxActive(undefined);
    setshowCustomerAgrIndex(undefined);
    const currentCUstomerChecked: number | undefined =
      checkBoxActive == index ? undefined : index;
    if (currentCUstomerChecked !== undefined) {
      // setsomethingone('modal fade show expandPopup');
      // setsomethingtwo('modal fade');
      setblurLoaderActive(true);
      setactiveGcid(gcd);
      const res = await getcustomerbyid("C", gcd);
      /* eslint-disable no-console */
      console.log("custID.customerId", res);
      if (res == undefined) {
        setcheckBoxActive(undefined);
      } else {
        setcheckBoxActive(currentCUstomerChecked);
      }
    } else {
      setactiveGcid(undefined);
      setcheckBoxActive(currentCUstomerChecked);
    }
  };
  const getcustomerbyid = async (type: any, id: any) => {
    try {
      const agr: any = searchResults.filter((el: any) => {
        if (id == el.globalCustomerId) {
          return el;
        }
      });
      setagrDetails(agr);
      setblurLoaderActive(true);
      const res: any =
        activeGcid !== id
          ? await GetCustomerBasicInfo(id)
          : { customerId: custID?.customerId };
      if (res && res.customerId) {
        setblurLoaderActive(false);
        console.log(res);
        if (type == "C") {
          setcustID(res);
          return res;
        } else if (type == "R") {
          props.history.push(`/customer/update/${res.customerId}/customer`);
        }
      } else {
        setblurLoaderActive(false);
        setisclsAlertMsg(true);
      }
    } catch (err: any) {
      if (err) {
        setblurLoaderActive(false);
        setisclsAlertMsg(true);
        // eslint-disable-next-line no-console
        console.log("error while fetching customerid", err, isclsAlertMsg);
      }
    }
  };
  const category = (el: any) => {
    const skip = el.skip !== undefined ? el.skip : "N";
    const stolen = el.stolen !== undefined ? el.stolen : "N";
    const hard = el.hard !== undefined ? el.hard : "N";
    if (skip == "N" && stolen == "N" && hard == "N") {
      return "-";
    } else if (skip !== "N" && hard !== "N") {
      return "Skip/Hard";
    } else if (skip !== "N") {
      return "Skip";
    } else if (stolen !== "N") {
      return "Stolen";
    } else if (hard !== "N") {
      return "Hard";
    }
  };
  const agreementTable = (i: any) => {
    return showCustomerAgrIndex == i ? (
      <RACTableRow
        className={`{${classes.customerAccordianopen} ${classes.customerTablecellbgcolor}`}
      >
        <td
          colSpan={15}
          className={`${classes.customerHiddenrow} ${classes.spacerP0}`}
        >
          <div id="row-1">
            {agreements === undefined ? (
              <div className={classes.customerNorecordsAgr}>
                <CircularProgress />
              </div>
            ) : agreements === null ? (
              <div className={classes.customerTextcenter}>
                <div
                  className={`${classes.customerRow} ${classes.customerJustifycontentcenter}`}
                >
                  <div className={classes.customerColmd}>
                    <Alerticon></Alerticon>
                    <p className={classes.spacerMT3}>
                      No rental agreement found
                    </p>
                  </div>
                </div>
              </div>
            ) : agreements !== undefined &&
              agreements !== null &&
              agreements.length > 0 ? (
              <RACTable
                renderTableHead={renderAgreementTableHead}
                renderTableContent={renderAgreementTableContent}
                className={`${classes.agreementGrid}`}
              />
            ) : (
              <div className={classes.customerTextcenter}>
                <div
                  className={
                    (classes.customerRow, classes.customerJustifycontentcenter)
                  }
                >
                  <div className={classes.customerColmd}>
                    <Alerticon></Alerticon>
                    <p className={classes.spacerMT3}>
                      No rental agreements found
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </td>
      </RACTableRow>
    ) : null;
  };
  const isDueDatePassed = (date: any) => {
    /* eslint-disable no-console */
    console.log(date);
    const date1: any = new Date(date);
    const cMonth = dt.getMonth() + 1;
    const cDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate();
    const cYear = dt.getFullYear();
    const currentDate: any = new Date(`${cMonth}/${cDate}/${cYear}`);
    const diffTime = Math.abs(currentDate - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log("diffDays", diffDays);
    return diffDays > 0 ? false : true;
  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || "...";
    return str !== undefined &&
      str !== null &&
      typeof str === "string" &&
      str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const nextDueDayWithDate = (date: any) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date(date);
    const index = today.getDay();
    return days[index] !== undefined && days[index] !== null
      ? days[index] + ", " + date
      : date;
  };
  const showSearchResults = () => {
    setSortOrder({ fN: "asc", lN: "asc" });
    setsearchResults([]);
    const phones: any = [
      {
        phoneNumber: "",
      },
    ];

    const payloadReq: any = {
      firstName: formField?.firstName,
      lastName: formField?.lastName,
      dateOfBirth: formField?.dateOfBirth,
      addresses: [
        {
          city: formField?.city,
          postalCode: formField?.zipCode,
          addressLine1: formField?.addressLine1,
          state: formField?.state,
        },
      ],
      emailAddress: formField?.emailAddress,
    };
    // if (FirstName != '') {
    //   payloadReq.firstName = FirstName;
    // }
    // if (LastName != '') {
    //   payloadReq.lastName = LastName;
    // }
    // if (Dob != '') {
    //   payloadReq.dateOfBirth = Dob;
    // }
    if (
      formField?.ssn1.length == 3 &&
      formField?.ssn2.length == 2 &&
      formField?.ssn3.length == 4
    ) {
      payloadReq.taxId = formField?.ssn1 + formField?.ssn2 + formField?.ssn3;
    }

    if (
      formField?.mobilePhoneNumber !== "" &&
      formField?.mobilePhoneNumber.length > 9
    ) {
      const PhonenumberObj = formField?.mobilePhoneNumber.replace(/\D/g, "");
      phones[0].phoneNumber =
        PhonenumberforrcustValidatoion !== undefined &&
        PhonenumberforrcustValidatoion !== null &&
        PhonenumberforrcustValidatoion !== ""
          ? PhonenumberforrcustValidatoion
          : PhonenumberObj;
      payloadReq.phones = phones;
    }
    setinitialNoRecPopUp(false);
    getSearchValues(payloadReq);
  };
  const activeAgreementCount = (data: any) => {
    let initialAgreement = 0;
    if (data !== undefined && data !== null && data.length > 0) {
      data.forEach((el: any) => {
        if (el.lob !== undefined && el.lob !== null) {
          initialAgreement +=
            el.lob.toUpperCase() == "RTO" ? parseInt(el.active) : 0;
        }
      });
    }
    return initialAgreement;
  };
  // newcustomer button click functionality PC-CC-12
  const NewCustomerClick = async () => {
    setsearchResults([]);
    setblurLoaderActive(true);
    await getAddress();
    // if (Ssn1.length + Ssn2.length + Ssn3.length == 9) {
    //   setcustpopup(false);
    //   setValidateIsOpen(false);
    //   const payload: any = {
    //     firstName: FirstName,
    //     lastName: LastName,
    //     dateOfBirth: Dob,
    //     storeNumber: loginStore,
    //     taxId: Ssn1 + Ssn2 + Ssn3,
    //     languagePreference: 'ENG',
    //     customerId: custID.customerId,
    //     address: {
    //       addressType: 'B',
    //       addressLine1: Addressline1,
    //       addressLine2: Addressline2,
    //       city: city,
    //       stateCode: stateinfo,
    //       zipCode: zip,
    //     },
    //     phone: {
    //       phoneNumber: Phonenumber.replace(/\D/g, ''),
    //       phoneType: 'CELL',
    //       primary: 'Y',
    //       callTimeType: 'MORN',
    //     },
    //   };
    //   await runDEaxiosCall(payload);
    // } else if (Ssn1.length + Ssn2.length + Ssn3.length < 9) {
    //   setblurLoaderActive(false);
    //   setliketoopen(true);
    // }
  };
  const fieldValidation = (gridObj?: any) => {
    console.log("formFields", formField, gridObj);
    const DobObj =
      gridObj !== undefined ? gridObj.dateOfBirth : formField?.dateOfBirth;
    const stDate = moment(DobObj, "YYYY-MM-DD");
    const lt18 = moment(moment().subtract(18, "years"), "YYYY-MM-DD");
    const lt110 = moment(moment().subtract(110, "years"), "YYYY-MM-DD");
    const lt18Res: any = lt18.diff(stDate, "days");
    const lt110Res: any = lt110.diff(stDate, "days");
    console.log("lt18Res", lt18Res);
    console.log("lt110Res", lt110Res);
    const diffDays = moment().diff(
      moment(formField?.dateOfBirth, "YYYY-MM-DD"),
      "days"
    );
    console.log("diffDays", diffDays);
    const firstName =
      gridObj !== undefined ? gridObj.firstName : formField?.firstName;
    const lastName =
      gridObj !== undefined ? gridObj.lastName : formField?.lastName;
    const mobilePhoneNumber =
      gridObj !== undefined
        ? gridObj.mobilePhoneNumber
        : formField?.mobilePhoneNumber;
    const ad1 =
      gridObj !== undefined ? gridObj.addressLine1 : formField?.addressLine1;
    const cityObj = gridObj !== undefined ? gridObj.city : formField?.city;
    const state = gridObj !== undefined ? gridObj.state : formField?.state;
    const zipCode =
      gridObj !== undefined ? gridObj.zipCode : formField?.zipCode;
    const ssn1Obj = gridObj !== undefined ? gridObj.ssn1 : formField?.ssn1;
    const ssn2Obj = gridObj !== undefined ? gridObj.ssn2 : formField?.ssn2;
    const ssn3Obj = gridObj !== undefined ? gridObj.ssn3 : formField?.ssn3;

    if (
      firstName !== "" &&
      lastName !== "" &&
      DobObj !== "" &&
      lt18Res >= 0 &&
      lt110Res < 0 &&
      /* Ssn1 !== "" &&
        Ssn2 !== "" &&
        Ssn3 !== "" &&
        ValidateEmail(emailaddress) &&
        PhonenumberforrcustValidatoion.length > 9 */
      mobilePhoneNumber.length > 9 &&
      ad1 !== "" &&
      cityObj !== "" &&
      state !== "0" &&
      (zipCode.length == 5 || zipCode.length == 10)
    ) {
      //setenableValidatebtn(true);
      setdisabledValidatebtn(false);

      setrunNewCustomerbtn(false);
      setIsdisabled(false);
    } else {
      setdisabledValidatebtn(true);
      setIsdisabled(true);
    }
    // const lt110: any = moment(Dob).subtract(110, 'years');
    // const lt18: any = moment(Dob).subtract(18, 'years');
    // console.log('lt110', Dob);
    // console.log('lt18', lt18);
    // const el: any = document.getElementsByName('stateinfo');
    // if (el[0][0].selected) {
    //   setdisabledValidatebtn(true);
    // }
    /*if (
      FirstName == '' ||
      LastName == '' ||
      Dob == '' ||
       Ssn1 !== "" &&
      Ssn2 !== "" &&
      Ssn3 !== "" &&
      ValidateEmail(emailaddress) &&
      PhonenumberforrcustValidatoion.length > 9
      Addressline1 == '' ||
      city == '' ||
      stateinfo == '' ||
      zip == ''
    ) {
      setenableValidatebtn(true);
      // setrunNewCustomerbtn(true)
      setValidateIsOpen(false);
    }*/

    /* let email:any = document.getElementsByName("emailaddress")
    if(emailaddress == ""){
      setemailerrMsg(false)
    }*/
    if (ssn1Obj !== "") {
      setSocialSecurityBorderOne("Grey");
    }
    if (ssn2Obj !== "") {
      setSocialSecurityBorderTwo("Grey");
    }
    if (ssn3Obj !== "") {
      setSocialSecurityBorderThree("Grey");
    }

    if (Basicinfoactive) {
      setValidateIsOpen(false);
      //setcustomerValidation(propsdata);
      // props.history.push('/createcustomerinfo');
    }
    if (
      firstName !== "" ||
      lastName !== "" ||
      DobObj !== "" ||
      ssn1Obj !== "" ||
      ssn2Obj !== "" ||
      ssn3Obj !== "" ||
      mobilePhoneNumber !== "" ||
      ad1 !== "" ||
      formField?.addressLine2 !== "" ||
      cityObj !== "" ||
      state !== "" ||
      zipCode != ""
    ) {
      setclearbtndisable(false);
    } else {
      setclearbtndisable(true);
    }
    // eslint-disable-next-line no-console
    console.log("use effect for customer validation");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (searchResults.length > 0) {
      setSearchCustomerActive(true);
    } else {
      setSearchCustomerActive(false);
    }
    if (checkBoxActive == undefined) {
      setnewCustValidBtnHidden(true);
    } else {
      setenableValidatebtn(true);
      setnewCustValidBtnHidden(false);
    }

    // if (zip.length >= 5) {
    //   getZipFromState();
    // }
  };
  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   FirstName,
  //   LastName,
  //   Addressline1,
  //   zip,
  //   Addressline2,
  //   city,
  //   stateinfo,
  //   Dob,
  //   Basicinfoactive,
  //   searchResults,
  //   checkBoxActive,
  //   Phonenumber,
  //   SuggestedAddress,
  //   PhonenumberforrcustValidatoion,
  // ]);
  // const getZipFromState = async () => {
  //   if (zip.length == 5) {
  //     const data: any = await GetStateFromZip(zip);
  //     console.log(data);
  //     const filterState = stateDD.filter(
  //       (el: any) => el.stateProvinceName.toUpperCase() == data.city
  //     );
  //     setcity(data.city);
  //     setstateinfo(filterState.stateProvinceName);
  //   } else if (zip.length > 5) {
  //     console.log(zip);
  //     const postal: any = zip.split('-')[0];
  //     const data: any = await GetStateFromZip(postal);
  //     const filterState = stateDD.filter(
  //       (el: any) => el.stateProvinceName.toUpperCase() == data.city
  //     );
  //     setcity(data.city);
  //     setstateinfo(filterState.stateProvinceName);
  //   }
  // };
  // clear button -> value set to null PC-CC-07
  const clear = () => {
    setFormField({
      firstName: "",
      lastName: "",
      dateOfBirth: "",
      emailAddress: "",
      mobilePhoneNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      ssn1: "",
      ssn2: "",
      ssn3: "",
    });
    setfieldValidation({
      dateOfBirth: false,
      mobilePhoneNumber: false,
      zipCode: false,
    });
    setSalesLeadSelected("");
    setclearbtnHidden(true);
    // setFirstName('');
    // setLastName('');
    // SetDob('');
    // setssn1('');
    // setssn2('');
    // setssn3('');
    // setPhonenumber('');
    // setemailaddress('');
    // setAddressline1('');
    // setAddressline2('');
    // setApt('');
    // Setpobox('');
    // setzip('');
    // setcity('');
    // setstateinfo('');
    setIsdisabled(true);
    SetButtonactive(true);
  };
  // onchange functionality PC-CC-08
  // const handleOnChangeItemTxtboxOnlyNumber = (e: any) => {
  //   const number = /^[0-9\b]+$/;
  //   const alpha = /^[A-Za-z\b]+$/;
  //   if (e.target.id == "a11y_Item") {
  //     if (e.target.value === '' || number.test(e.target.value) && e.target.value.length <= 13) {
  //       setTxtValues({ ...TxtValues, [e.target.name]: e.target.value })
  //     }
  //   }
  // }
  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (inputText.match(mailformat)) {
      //document.getElementsByName("Addressline1")[0].focus();
      setemailerrMsg(false);
      return true;
    } else {
      setemailerrMsg(true);
      return false;
    }
  }
  const onBlurANdOnfocusForEmail = (e: any) => {
    if (e.target.value == "") {
      setemailerrMsg(false);
    }
  };
  const ssn2Handler = (e: any) => {
    const ssn3El: any = document.getElementById("a11y_ssnthree");
    const ssn1El: any = document.getElementById("a11y_ssnone");
    const ssn2El: any = document.getElementById("a11y_ssntwo");
    if (e.target.value.length === 2) {
      if (ssn2El == document.activeElement) {
        ssn3El.focus();
      }
    }
    if (e.target.value.length === 0 && formField?.ssn1.length > 0) {
      if (ssn2El == document.activeElement) {
        ssn1El.focus();
      }
    }
  };
  const ssn1Handler = (e: any) => {
    const ssn2El: any = document.getElementById("a11y_ssntwo");
    const ssn1El: any = document.getElementById("a11y_ssnone");
    if (e.target.value.length === 3) {
      if (ssn1El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };
  const ssn3Handler = (e: any) => {
    const ssn3El: any = document.getElementById("a11y_ssnthree");
    const ssn2El: any = document.getElementById("a11y_ssntwo");
    if (e.target.value.length === 0 && formField?.ssn2.length > 0) {
      if (ssn3El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };
  // const dateChecker = (year: any) => {
  //   if (year !== undefined) {
  //     const curYear = new Date().getFullYear();
  //     const minYear: any = curYear - 110;
  //     const maxYear: any = curYear - 18;

  //     const res: any = year >= minYear && year <= maxYear ? true : false;
  //     return res;
  //   }
  // };
  // const ssnFoc: any = (e: any) => {
  //   if (e !== undefined) {
  //     const TempArr = e.split('-');
  //     if (
  //       TempArr !== undefined &&
  //       TempArr[0] !== undefined &&
  //       dateChecker(TempArr[0])
  //     ) {
  //       const el: any = document.getElementById('a11y_ssnone');
  //       el.focus();
  //     }
  //   }
  // };
  const ssnFoc: any = (e: any) => {
    if (e !== undefined) {
      const stDate = moment(e, "YYYY-MM-DD");
      const lt18 = moment(moment().subtract(18, "years"), "YYYY-MM-DD");
      const lt110 = moment(moment().subtract(110, "years"), "YYYY-MM-DD");
      const lt18Res: any = lt18.diff(stDate, "days");
      const lt110Res: any = lt110.diff(stDate, "days");
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      if (res) {
        const el: any = document.getElementById("a11y_ssnone");
        el.focus();
      }
    }
  };
  const capitalize = (input: any) => {
    const words: any = input.split(" ");
    const CapitalizedWords: any = [];
    words.forEach((element: any) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  };
  const HandleDateChange = (InputTxt: any) => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    const format: any = moment(InputTxt).format("MM/DD/YYYY");
    const temp = InputTxt.replace(format);
    setFormField({ ...formField, dateOfBirth: temp });
    // SetDob(temp);
    ssnFoc(InputTxt);
    // }
  };

  const HandleChange = (e: any) => {
    setenableValidatebtn(true);
    if (e.target.name === "FirstName") {
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== " "
      ) {
        const char: any = capitalize(e.target.value);
        setFormField({
          ...formField,
          firstName: formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, "")),
        });
      } else {
        setFormField({
          ...formField,
          firstName: formatTextChange(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ""))
          ),
        });
      }
      // setFirstName(
      //   formatTextChange(e.target.value.replace(/[^A-Z'.,’ a-z]/gi, ''))
      // );
      // fieldValidation();
    }
    if (e.target.name === "LastName") {
      // setLastName(
      //   formatTextChange(e.target.value.replace(/[^A-Z'.,’ a-z]/gi, ''))
      // );
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== " "
      ) {
        const char: any = capitalize(e.target.value);
        console.log("customerlastname", char);
        setFormField({
          ...formField,
          lastName: formatTextChange(char.replace(/[^A-Z-'.,’ a-z]/gi, "")),
        });
      } else {
        setFormField({
          ...formField,
          lastName: formatTextChange(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ""))
          ),
        });
      }

      //fieldValidation();
    }
    if (e.target.name === "Dob") {
      const InputTxt = e.target.value;
      const format: any = moment(InputTxt).format("MM/DD/YYYY");
      const temp = InputTxt.replace(format);
      //SetDob(temp);
      setFormField({ ...formField, dateOfBirth: temp });

      // fieldValidation();
    }
    if (e.target.name === "Ssn1") {
      setSocialSecurityBorderOne("none");
      // setssn1(e.target.value.replace(/\D/g, ''));
      setFormField({ ...formField, ssn1: e.target.value.replace(/\D/g, "") });
      ssn1Handler(e);
      //fieldValidation();
    }
    if (e.target.name === "Ssn2") {
      // if(Ssn2!==""){
      //   setSocialSecurityBorderTwo("none")
      // }
      setSocialSecurityBorderOne("none");
      //fieldValidation();
      // setssn2(e.target.value.replace(/\D/g, ''));
      setFormField({ ...formField, ssn2: e.target.value.replace(/\D/g, "") });

      ssn2Handler(e);

      // setssn2(e.target.value.replace(/[0-9]/g, '*'));
    }
    if (e.target.name === "Ssn3") {
      setSocialSecurityBorderOne("none");
      //setssn3(e.target.value.replace(/\D/g, ''));
      setFormField({ ...formField, ssn3: e.target.value.replace(/\D/g, "") });
      //fieldValidation();
      ssn3Handler(e);
    }
    if (e.target.id === "a11y_phonenumber") {
      //alert(e.target.value);
      const cleaned = ("" + e.target.value).replace(/\D/g, "");
      //This is raw data to pass on props
      if (cleaned.length <= 10) {
        setPhonenumberforrcustValidatoion(e.target.value.replace(/\D/g, ""));
        const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        const temp = cleaned.replace(PhoneRegex, "($1) $2-$3");
        //alert(temp);
        //  setPhonenumber(temp);
        setFormField({ ...formField, mobilePhoneNumber: temp });

        //fieldValidation();
      }
    }
    if (e.target.name === "emailaddress") {
      //setemailaddress(formatTextChange(e.target.value));
      setFormField({
        ...formField,
        emailAddress: formatTextChange(e.target.value),
      });
      //fieldValidation();
    }
    if (e.target.name === "Addressline1") {
      //setAddressline1(formatTextChange(e.target.value));
      setFormField({
        ...formField,
        addressLine1: formatTextChange(e.target.value),
      });
      //fieldValidation();
    }
    if (e.target.name === "Addressline2") {
      setFormField({
        ...formField,
        addressLine2: formatTextChange(e.target.value),
      });
      //setAddressline2(formatTextChange(e.target.value));
      //fieldValidation();
    }
    // if (e.target.name === 'Apt') {
    //   setFormField({
    //     ...formField,
    //     addressLine2: formatTextChange(e.target.value),
    //   });
    //   setApt(e.target.value.replace(/[^\w\s]/gi, ''));
    //   fieldValidation();
    // }
    // if (e.target.name === 'pobox') {
    //   Setpobox(e.target.value.replace(/\D/g, ''));
    //   fieldValidation();
    // }
    if (e.target.name === "zip") {
      setstatedisabled(false);
      let cleaned = ("" + e.target.value).replace(/\D/g, "");
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + "-" + cleaned.slice(5)
          : cleaned;
      //setzip(temp);
      setFormField({
        ...formField,
        zipCode: cleaned,
      });
      //fieldValidation();
    }
    if (e.target.name === "city") {
      setFormField({
        ...formField,
        city: formatTextChange(e.target.value.replace(/[^a-zA-Z ]/g, "")),
      });
      //setcity(formatTextChange(e.target.value.replace(/[^a-zA-Z ]/g, '')));
      //fieldValidation();
    }
    if (e.target.name === "stateinfo") {
      setFormField({
        ...formField,
        state: e.target.value,
      });
      //setstateinfo(e.target.value);
      //fieldValidation();
    }
  };
  useEffect(() => {
    fieldValidation();
    console.log("props 2173", props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField, searchResults, checkBoxActive]);
  // loadstate for the state dropdown PC-CC-09
  // const phonenumberval = (e: any) => {
  //   e = e ? e : window.event;
  //   alert(e.target.value);
  //   const charCode = e.which ? e.which : e.keyCode;

  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     alert("wrong");
  //     return false;
  //   }
  //   alert(e.target.value);
  //   setPhonenumber(e.target.value);
  //   return true;
  // };
  // continue button click functionality PC-CC-10

  const continueclick = (type: any, mc?: any) => {
    /* eslint-disable */
    //debugger
    //setenableValidatebtn(false)
    const address: any = {
      Addresses: {
        StreetAddress: "",
        City: "",
        PostalCode: "",
        StateProvince: "",
      },
    };
    setCanSuggestAddress(false);
    setsearchBTNClicked(true);
    setValidateIsOpen(false);
    setblurLoaderActive(true);
    if (type == "SUG") {
      setFormField({
        ...formField,
        addressLine1: SuggestedAddress.Addresses.StreetAddress,
        addressLine2:
          formField?.addressLine2 +
          " " +
          SuggestedAddress.Addresses?.Building +
          " " +
          SuggestedAddress.Addresses?.Unit +
          " " +
          SuggestedAddress.Addresses?.POBox,
        city: SuggestedAddress.Addresses.City,
        zipCode: SuggestedAddress.Addresses.PostalCode,
        state: SuggestedAddress.Addresses.StateProvince,
      });
      // setAddressline1(SuggestedAddress.Addresses.StreetAddress),
      //   setAddressline2(
      //     Addressline2 +
      //       ' ' +
      //       SuggestedAddress.Addresses?.Building +
      //       ' ' +
      //       SuggestedAddress.Addresses?.Unit +
      //       ' ' +
      //       SuggestedAddress.Addresses?.POBox
      //   );
      // setcity(SuggestedAddress.Addresses.City);
      // setstateinfo(SuggestedAddress.Addresses.StateProvince);
      // setzip(SuggestedAddress.Addresses.PostalCode);
    } else if (mc == "MC1" && type == "ENT") {
      setFormField({
        ...formField,
        addressLine1: formField.addressLine1.toUpperCase(),
        addressLine2: formField.addressLine2.toUpperCase(),
        city: formField.city.toUpperCase(),
        zipCode: formField.zipCode,
        state: formField.state,
      });
    } else if (type == "ENT" && mc == undefined) {
      address.Addresses.StreetAddress = formField.addressLine1.toUpperCase();
      address.Addresses.addressLine2 = formField.addressLine2
        ? formField.addressLine2
        : undefined;
      address.Addresses.PostalCode = formField.zipCode;
      address.Addresses.StateProvince = formField.state;
      address.Addresses.City = formField.city.toUpperCase();
    } else if (type == "GA") {
      const postalAddress = validatedAddresses?.postalAddress;
      if (postalAddress?.addressLines.length > 1) {
        setFormField({
          ...formField,
          addressLine1: postalAddress?.addressLines[1],
          addressLine2: postalAddress?.addressLines[0],
          city: postalAddress?.locality,
          zipCode: postalAddress?.postalCode,
          state: postalAddress?.administrativeArea,
        });
        address.Addresses.addressLine2 = postalAddress?.addressLines[0];
        address.Addresses.StreetAddress = postalAddress?.addressLines[1];
      } else {
        address.Addresses.StreetAddress =
          postalAddress?.addressLines.join(", ");
        formField.addressLine2 = "";
        setFormField({
          ...formField,
          addressLine1: postalAddress?.addressLines.join(", "),
          addressLine2: "",
          city: postalAddress?.locality,
          zipCode: postalAddress?.postalCode,
          state: postalAddress?.administrativeArea,
        });
      }
      address.Addresses.PostalCode = postalAddress?.postalCode;
      address.Addresses.StateProvince = postalAddress?.administrativeArea;
      address.Addresses.City = postalAddress?.locality;
    }
    checkIfSSNEnter(
      type == "SUG"
        ? SuggestedAddress
        : type == "GA"
        ? address
        : type == "ENT" && mc == undefined
        ? address
        : undefined
    );
    //showSearchResults();
    //setdepopupisopen(true)
  };
  // OK button click functionality PC-CC-11
  const Okclick = () => {
    setValidateIsOpen(false);
    setdepopupisopen(false);
    //setbasicinfoactive(true);
    setFinishVisible(true);

    //props.history.push("/CustomerInformation")
  };
  // Dummy data for pending escalted sales lead table PC-CC-05
  const wlDeposit = "10$/Refund";
  const objPendingSalesLead = [
    {
      CustomerName: "Tony",
      PhoneNumber: "9789004563",
      Product: "Mobile",
      Description: "Samsung",
      ExpirationDate: "27/08/2021",
      WLDeposit: wlDeposit,
      OrderId: "123",
      TextResponse: "-",
    },
    {
      CustomerName: "Steve",
      PhoneNumber: "1232323324",
      Product: "Mobile",
      Description: "Samsung",
      ExpirationDate: "16/05/2021",
      WLDeposit: wlDeposit,
      OrderId: "123",
      TextResponse: "-",
    },
    {
      CustomerName: "Banner",
      PhoneNumber: "9789004563",
      Product: "Mobile",
      Description: "Samsung",
      ExpirationDate: "15/01/2021",
      WLDeposit: wlDeposit,
      OrderId: "123",
      TextResponse: "-",
    },
  ];
  const salesLeadGridData: any = async (val: any) => {
    //debugger;
    clear();
    if (val.leadId !== undefined && val.leadId !== null && val.leadId !== "") {
      setSalesLeadSelected(val.leadId);
      setblurLoaderActive(true);
      try {
        const responseObj = await GetCustomerBasicInfo(val.globalCustomerId);
        if (responseObj.customerId) {
          props.history.push(
            `/customer/update/${responseObj.customerId}/customer`
          );
        }
      } catch (err) {
        setrefundResponseMsg("Something went wrong");
      }
      const responseObj = await webLeadById(val.leadId);
      fieldValidation(responseObj);
      setblurLoaderActive(false);
      // setclearbtnHidden(false);
      // setblurLoaderActive(false);
    }

    // const name: any = document.getElementById(`cname_${d}`);
    // const num: any = document.getElementById(`num_${d}`);
    /*setFirstName(val.firstName);
    setLastName(val.lastName);
    if (
      val.dateOfBirth !== '' &&
      val.dateOfBirth == null &&
      val.dateOfBirth !== null
    ) {
      SetDob(val.dateOfBirth);
    }
    if (
      val.mobilePhoneNumber !== '' &&
      val.mobilePhoneNumber !== null &&
      val.mobilePhoneNumber !== undefined
    ) {
      setPhonenumber(
        val.mobilePhoneNumber.replace(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          '($1) $2-$3'
        )
      );
      setPhonenumberforrcustValidatoion(val.mobilePhoneNumber);
    }*/
  };
  const formatNum = (num1: any) => {
    if (num1 !== undefined && num1 !== null && num1 !== "") {
      const cleaned = ("" + num1).replace(/\D/g, "");
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return cleaned.replace(PhoneRegex, "($1) $2-$3");
    }
  };
  const renderpendingEsclatedLeadHeaderList = () => (
    <>
      {pendingEsclatedLeadHeaderList.map((col, index) => {
        return (
          <RACTableCell
            key={index}
            className={col == "WL Deposit" ? `${classes.textCenter}` : ""}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  const renderpendingEsclatedLeadContent = () => {
    if (webLeads.length > 0 && webLeads !== null && webLeads !== undefined) {
      return webLeads.map((val: any, index: any) => {
        const typeReserved = "Reserved";

        // eslint-disable-next-line  sonarjs/no-duplicate-string

        const typeCustomerOrder = "Customer Order";
        const typeReimbursed = "Reimbursed";

        const type =
          val.refundName == "Reimbursed"
            ? typeReimbursed
            : val.webleadPaymentId !== null &&
              val.inventories[0]?.inventoryNumber !== null
            ? typeReserved
            : val.inventories[0]?.modelNumber !== "" &&
              val.inventories[0]?.inventoryNumber !== null
            ? typeCustomerOrder
            : "-";

        const product =
          type == typeReserved || val.refundName == "Reimbursed"
            ? val.inventories[0]?.inventoryNumber
            : type == typeCustomerOrder
            ? val.inventories[0]?.modelNumber
            : type == "-" && val.inventories[0]?.modelNumber !== ""
            ? val.inventories[0]?.modelNumber
            : "-";
        return (
          <RACTableRow
            key={index}
            hover
            backgroundColor={"white"}
            className={
              val.leadStatus !== undefined &&
              val.leadStatus !== "" &&
              val.leadStatus !== null &&
              val.leadStatus == "Converted"
                ? `${classes.tablebggreen}`
                : ""
            }
            title={
              val.leadStatus !== undefined &&
              val.leadStatus !== "" &&
              val.leadStatus !== null &&
              val.leadStatus == "Converted"
                ? "Converted"
                : ""
            }
          >
            <RACTableCell>
              <RACRadio
                id={`salesLeadGridData`}
                name={`salesLeadGridData`}
                checked={salesLeadSelected == val.leadId ? true : false}
                onClick={() => salesLeadGridData(val)}
                value={undefined}
                disabled={
                  val.leadStatus !== undefined &&
                  val.leadStatus !== "" &&
                  val.leadStatus !== null &&
                  val.leadStatus == "Converted"
                    ? true
                    : false
                }
              />
            </RACTableCell>
            <RACTableCell id={`cname_${index}`}>{val.lastName}</RACTableCell>
            <RACTableCell id={`cname_${index}`}>{val.firstName}</RACTableCell>
            <RACTableCell id={`num_${index}`}>
              {formatNum(val.mobilePhoneNumber)}
            </RACTableCell>
            <RACTableCell>{product}</RACTableCell>
            <RACTableCell title={val.productDescription}>
              {val.productDescription !== undefined &&
              val.productDescription !== null &&
              val.productDescription !== ""
                ? truncString(val.productDescription, 30, "...")
                : "-"}
            </RACTableCell>
            <RACTableCell>
              {val.createdDate !== undefined &&
              val.createdDate !== null &&
              val.createdDate !== ""
                ? moment(val.createdDate)
                    .format("MM/DD/YYYY")
                    .replaceAll("-", "/")
                : "-"}
            </RACTableCell>
            <RACTableCell className={classes.textCenter}>
              {val.refundName == "Reimbursed"
                ? ""
                : val.downPaymentAmount == null ||
                  val.downPaymentAmount == 0 ||
                  val.refundName == undefined
                ? "-"
                : `$${val.downPaymentAmount}`}
              {val.type !== undefined && val.refundName !== "Reimbursed"
                ? val.type
                : ""}
              {val.refundName !== undefined &&
              val.refundName !== "Reimbursed" ? (
                <a
                  className={classes.racpadLink}
                  onClick={() =>
                    refundClick(val.webleadPaymentId, val.salesLeadId, val)
                  }
                >
                  {`/`}
                  {val.refundName}
                </a>
              ) : val.refundName == "Reimbursed" ? (
                val.refundName
              ) : val.refundName == undefined ? (
                ""
              ) : null}
            </RACTableCell>
            <RACTableCell className={classes.textCenter}>
              {val.webleadPaymentId !== null &&
              val.webleadPaymentId !== undefined &&
              val.webleadPaymentId !== ""
                ? val.webleadPaymentId
                : "-"}
            </RACTableCell>
            <RACTableCell className={classes.textCenter}>{"-"}</RACTableCell>
          </RACTableRow>
        );
      });
    }
  };
  const refundClick = async (id: any, salesLeadId: any, leadObj: any) => {
    try {
      const {
        customerInfo: { firstName, lastName },
        paymentInfo,
        orderNumber,
      } = leadObj.webLeadRes;
      const payload: any = {
        firstName: firstName,
        lastName: lastName,
        amount: paymentInfo.balanceAmount,
        originalExtTransactionId: paymentInfo.externalTransactionId,
        customerId: orderNumber, //Web lead order number
        cardType: paymentInfo.cardType,
        paymentType: paymentInfo.paymentType,
        globalCustomerId: leadObj.globalCustomerId,
      };
      const isValidPayload = Object.values(payload).every((el: any) =>
        Boolean(el)
      );
      if (isValidPayload) {
        setblurLoaderActive(true);
        const response: any = await RefundLeadAmount(payload);
        if (response !== undefined && response !== null) {
          if (
            response?.statusCode !== undefined &&
            response?.statusCode !== null
          ) {
            window.location.reload();
            // return
            // const refundWebleads: any = webLeads.filter((el: any) => {
            //   return el.salesLeadId == salesLeadId;
            // });
            // if (refundWebleads !== null && refundWebleads.length > 0) {
            //   const curWebLead: any = refundWebleads.filter((el: any) => {
            //     el.refundName = 'Reimbursed';
            //     return el.salesLeadId == salesLeadId;
            //   });

            //   const reimburesedLead: any = webLeads.filter(
            //     (el: any, i: any) => {
            //       if (
            //         el.refundName == 'Reimbursed' &&
            //         el.salesLeadId !== curWebLead[0].salesLeadId
            //       ) {
            //         return el;
            //       }
            //     }
            //   );
            //   reimburesedLead.unshift(curWebLead[0]);
            //   const convertedLead: any = webLeads.filter((el: any, i: any) => {
            //     if (
            //       el.leadStatus !== undefined &&
            //       el.leadStatus !== '' &&
            //       el.leadStatus !== null &&
            //       el.leadStatus == 'Converted'
            //     ) {
            //       return el;
            //     }
            //   });
            //   const leads: any = webLeads.filter((el: any, i: any) => {
            //     if (
            //       el.leadStatus !== undefined &&
            //       el.leadStatus !== '' &&
            //       el.leadStatus !== null &&
            //       el.leadStatus !== 'Converted' &&
            //       el.refundName !== 'Reimbursed' &&
            //       el.salesLeadId !== curWebLead[0].salesLeadId
            //     ) {
            //       return el;
            //     }
            //   });
            //   console.log('leadsArr', leads);
            //   setWebLeads([...leads, ...reimburesedLead, ...convertedLead]);
            // }
            // setrefundResponseMsg(response.StatusInfo.StatusCodeDetail);
            // setrefundpopup(true);
          } else {
            setblurLoaderActive(false);
            setrefundResponseMsg(response.StatusInfo.StatusCodeDetail);
            setrefundpopup(true);
          }
        }
        setblurLoaderActive(false);
        console.log("Refund response", response);
      }
    } catch (e: any) {
      setblurLoaderActive(false);
      setrefundResponseMsg("Something went wrong.");
      setrefundpopup(true);
    }
  };

  // Radiobutton click for suggested address popup PC-CC-15
  const RadioBtnclick = (type: any) => {
    if (type === "SUG") {
      setBtnIsdisabled1(true);
      setBtnIsdisabled2(false);
      setdefaultSuggestedAddress("SUG");
    } else if (type === "ENT") {
      setBtnIsdisabled1(false);
      setBtnIsdisabled2(true);
      setdefaultSuggestedAddress("ENT");
    } else if (type == "GA") {
      setdefaultSuggestedAddress("GA");
    }
  };

  // createnewHandlechange functionality to open popup PC-CC-17
  const CreatenewHandleChange = async () => {
    setcustpopup(false);
    setblurLoaderActive(true);
    await getAddress();
    //checkIfSSNEnter();
    //as of noe
    //setValidateIsOpen(true);
  };
  const CreateCust = async (AddressAry?: any) => {
    debugger;
    const phonesNum: any = [
      {
        phoneType: "CELL",
        primary: "Y",
        callTimeType: "MORN",
        active: "Y",
      },
    ];
    if (formField?.mobilePhoneNumber != "") {
      const PhonenumberObj = formField?.mobilePhoneNumber.replace(/\D/g, "");
      phonesNum[0].phoneNumber =
        PhonenumberforrcustValidatoion !== undefined &&
        PhonenumberforrcustValidatoion !== null &&
        PhonenumberforrcustValidatoion !== ""
          ? PhonenumberforrcustValidatoion
          : PhonenumberObj;
    }
    const addresse: any = [
      {
        addressType: "PRIM",
        addressLine1:
          AddressAry == undefined
            ? formField?.addressLine1
            : AddressAry.Addresses.StreetAddress,
        city:
          AddressAry == undefined ? formField?.city : AddressAry.Addresses.City,
        postalCode:
          AddressAry == undefined
            ? formField?.zipCode
            : AddressAry.Addresses.PostalCode,
        state:
          AddressAry == undefined
            ? formField?.state
            : AddressAry.Addresses.StateProvince,
      },
    ];
    if (formField?.addressLine2 !== "") {
      addresse[0].addressLine2 = formField?.addressLine2;
    }
    if (featureFlagDetails.EnableGoogleAddressValidation == "1") {
      if (AddressAry?.Addresses.addressLine2) {
        addresse[0].addressLine2 = AddressAry.Addresses.addressLine2;
      } else {
        addresse[0].addressLine2 = undefined;
      }
    }
    const payloadReq: any = {
      firstName: formField?.firstName?.trim(),
      lastName: formField?.lastName?.trim(),
      dateOfBirth: formField?.dateOfBirth,
      preferredLanguage: "ENG",
      phones: phonesNum,
      addresses: addresse,
      textSolicitationAllowed: "Y",
      mailSolicitationAllowed: "Y",
    };

    if (
      formField?.ssn1.length +
        formField?.ssn2.length +
        formField?.ssn3.length ==
      9
    ) {
      payloadReq.taxId = formField?.ssn1 + formField?.ssn2 + formField?.ssn3;
    }
    if (formField?.emailAddress !== "") {
      payloadReq.emailAddress = formField?.emailAddress;
    }
    if (wLeadResponse !== undefined && wLeadResponse !== null) {
      if (
        wLeadResponse.leadId !== undefined &&
        wLeadResponse.leadId !== null &&
        wLeadResponse.leadId !== "" &&
        wLeadResponse.webleadPaymentId !== undefined &&
        wLeadResponse.webleadPaymentId !== null &&
        wLeadResponse.webleadPaymentId !== ""
      ) {
        payloadReq.salesLeadId = wLeadResponse.leadId;
        payloadReq.webleadPaymentId = wLeadResponse.webleadPaymentId;
      } else if (
        wLeadResponse.leadId !== undefined &&
        wLeadResponse.leadId !== null &&
        wLeadResponse.leadId !== ""
      ) {
        payloadReq.salesLeadId = salesLeadIdQuery;
      }
      if (wLeadResponse.globalCustomerId) {
        payloadReq.globalCustomerId = wLeadResponse.globalCustomerId;
      }
    }
    const result: any = await CreateCustomerInValidPage(payloadReq);
    console.log("createCustomerResult", result);
    // eslint-disable-next-line sonarjs/no-all-duplicated-branches
    if (result?.globalCustomerId && result?.customerId) {
      setcustID(result);
      console.log("resultss", result);
      return result;

      //  checkIfSSNEnter();
    }
  };

  const checkIfSSNEnter = async (AddressAry?: any) => {
    debugger;
    if (
      formField?.ssn1.length +
        formField?.ssn2.length +
        formField?.ssn3.length ==
      9
    ) {
      setblurLoaderActive(true);
      const result = await CreateCust(AddressAry);
      await runDecisionEngine(result);
    } else {
      setblurLoaderActive(false);
      setliketoopen(true);
    }
    //   if (response !== undefined) {
    //     // props.history.push(`/update/${cid}/customer`);
    //     setisLoaderActive(false);
    //     setDEStatus('');
    //     setdedeclineisopen(true);
    //   }
    // } else {
    //   setisLoaderActive(false);
    //   setliketoopen(true);
    // }
  };

  const runDecisionEngine = async (custID: any) => {
    //customerId
    // eslint-disable-next-line

    const postalAddress = validatedAddresses?.postalAddress;
    if (searchResults !== undefined && searchResults.length > 0) {
      if (custID !== undefined && custID.globalCustomerId !== undefined) {
        const gcd = custID.globalCustomerId;
        const filterData = searchResults.filter((el: any) => {
          return el.globalCustomerId == gcd;
        });
        console.log("filterData", filterData);
        if (
          filterData == undefined ||
          filterData[0].taxId == null ||
          filterData[0].taxId == ""
        ) {
          setenterssnisopen(true);
          setssonpopupforRunDE(true);
        } else {
          const address: any =
            filterData !== undefined &&
            filterData !== null &&
            filterData.length > 0 &&
            filterData[0].addresses !== undefined &&
            filterData[0].addresses !== null &&
            filterData[0].addresses.length > 0
              ? filterData[0].addresses.filter(
                  (e: any) => e.addressType == "PRIM" || e.addressType == "P"
                )
              : [];
          const phones: any =
            filterData !== undefined &&
            filterData !== null &&
            filterData.length > 0 &&
            filterData[0].phones !== undefined &&
            filterData[0].phones !== null &&
            filterData[0].phones.length > 0
              ? filterData[0].phones.filter((e: any) => e.isPrimary == "Y")
              : [];
          const payload: any = {
            firstName:
              filterData !== undefined && filterData[0].firstName
                ? filterData[0].firstName
                : "",
            lastName:
              filterData !== undefined && filterData[0].lastName
                ? filterData[0].lastName
                : "",
            dateOfBirth:
              filterData !== undefined && filterData[0].dateOfBirth
                ? filterData[0].dateOfBirth
                : "",
            storeNumber: loginStore,
            taxId:
              filterData !== undefined && filterData[0].taxId
                ? filterData[0].taxId
                : "",
            customerId: custID.customerId,
            globalCustomerId: custID.globalCustomerId,
            address: {
              addressType:
                filterData !== undefined && address !== undefined
                  ? address[0].addressType
                  : "",
              addressLine1:
                filterData !== undefined && address !== undefined
                  ? address[0].addressLine1
                  : "",
              addressLine2:
                filterData !== undefined && address !== undefined
                  ? address[0].addressLine2
                  : "",
              city:
                filterData !== undefined && address !== undefined
                  ? address[0].city
                  : "",
              state:
                filterData !== undefined && address !== undefined
                  ? address[0].stateCode
                  : "",
              postalCode:
                filterData !== undefined && address !== undefined
                  ? address[0].postalCode
                  : "",
            },
            phone: {
              phoneNumber:
                filterData !== undefined && phones !== undefined
                  ? phones[0].phoneNumber
                  : "",
              phoneType:
                filterData !== undefined && phones !== undefined
                  ? phones[0].phoneType
                  : "",
              primary:
                filterData !== undefined && phones !== undefined
                  ? phones[0].isPrimary
                  : "",
              callTimeType: "MORN",
            },
          };
          setblurLoaderActive(true);
          setIsRunDE(true);
          await runDEaxiosCall(payload);
        }
      }
    } else {
      const payload: any = {
        firstName: formField?.firstName,
        lastName: formField?.lastName,
        dateOfBirth: formField?.dateOfBirth,
        storeNumber: loginStore,
        taxId: formField?.ssn1 + formField?.ssn2 + formField?.ssn3,
        customerId: custID.customerId,
        globalCustomerId: custID.globalCustomerId,
        address: {
          addressType: "PRIM",
          addressLine1:
            defaultSuggestedAddress === "SUG"
              ? SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.StreetAddress !== undefined
                ? `${SuggestedAddress.Addresses.StreetAddress}`
                : formField?.addressLine1
              : defaultSuggestedAddress === "GA"
              ? postalAddress?.addressLines.length > 1
                ? postalAddress?.addressLines[1]
                : postalAddress?.addressLines[0]
              : formField?.addressLine1,
          addressLine2:
            defaultSuggestedAddress === "SUG"
              ? (
                  formField?.addressLine2 +
                  " " +
                  ((SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress !== "" &&
                  SuggestedAddress.Addresses !== undefined &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses !== "" &&
                  SuggestedAddress.Addresses.Building !== undefined &&
                  SuggestedAddress.Addresses.Building !== null &&
                  SuggestedAddress.Addresses.Building !== ""
                    ? SuggestedAddress.Addresses?.Building
                    : "") +
                    " " +
                    (SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress !== "" &&
                    SuggestedAddress.Addresses !== undefined &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses !== "" &&
                    SuggestedAddress.Addresses.Unit !== undefined &&
                    SuggestedAddress.Addresses.Unit !== null &&
                    SuggestedAddress.Addresses.Unit !== ""
                      ? SuggestedAddress.Addresses?.Unit
                      : "") +
                    " " +
                    (SuggestedAddress !== undefined &&
                    SuggestedAddress !== null &&
                    SuggestedAddress !== "" &&
                    SuggestedAddress.Addresses !== undefined &&
                    SuggestedAddress.Addresses !== null &&
                    SuggestedAddress.Addresses !== "" &&
                    SuggestedAddress.Addresses.POBox !== undefined &&
                    SuggestedAddress.Addresses.POBox !== null &&
                    SuggestedAddress.Addresses.POBox !== ""
                      ? SuggestedAddress.Addresses?.POBox
                      : ""))
                ).trim()
              : formField?.addressLine2 !== undefined &&
                formField?.addressLine2 !== null &&
                formField?.addressLine2 !== ""
              ? formField?.addressLine2
              : defaultSuggestedAddress === "GA"
              ? postalAddress?.addressLines.length > 1
                ? postalAddress?.addressLines[0]
                : ""
              : "",
          city:
            defaultSuggestedAddress === "SUG"
              ? SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.City !== undefined
                ? `${SuggestedAddress.Addresses.City}`
                : formField?.city
              : defaultSuggestedAddress === "GA"
              ? postalAddress?.locality
              : formField?.city,
          state:
            defaultSuggestedAddress === "SUG"
              ? SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.StateProvince !== undefined
                ? `${SuggestedAddress.Addresses.StateProvince}`
                : formField?.state
              : defaultSuggestedAddress === "GA"
              ? postalAddress?.administrativeArea
              : formField?.state,
          postalCode:
            defaultSuggestedAddress === "SUG"
              ? SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.PostalCode !== undefined
                ? `${SuggestedAddress.Addresses.PostalCode}`
                : formField?.zipCode
              : defaultSuggestedAddress === "GA"
              ? postalAddress?.postalCode
              : formField?.zipCode,
        },
        phone: {
          phoneNumber: formField?.mobilePhoneNumber.replace(/\D/g, ""),
          phoneType: "CELL",
          primary: "Y",
          callTimeType: "MORN",
        },
      };
      await runDEaxiosCall(payload);
    }
  };
  const runDEaxiosCall = async (payload: any) => {
    try {
      const response = await RunDE(payload);
      if (
        response !== undefined &&
        response.approvalResponse !== undefined &&
        (response.approvalResponse == "1" ||
          response.approvalResponse == "2" ||
          response.approvalResponse == "4")
      ) {
        setDEStatus(response.approvalResponse);
        setblurLoaderActive(false);
        setdedeclineisopen(true);
        console.log("Run DE REsp", response);
      } else {
        setblurLoaderActive(false);
        setDEStatus("");
        setdedeclineisopen(true);
      }
      console.log("Run DE REsp1", response);
    } catch (e) {
      setblurLoaderActive(false);
      setDEStatus("");
      setdedeclineisopen(true);
      // console.log('payloaad', payload);
      // const runderesponse = await RunDE(payload);

      // console.log('runderesponse', runderesponse);
    }
  };
  // yesHandlechange functionality for the yes button PC-CC-18
  const YesHandleChange = () => {
    setliketoopen(false);
    if (
      formField?.ssn1.length + formField?.ssn2.length + formField?.ssn3.length <
      9
    ) {
      setenterssnisopen(true);
    }
  };
  // NOHandleChange functionality to open reason for NO-DE PC-CC-19
  const NOHandleChange = async () => {
    setliketoopen(false);
    setreasonDEopen(true);
    await GetNoDEReason();
  };
  // Deokclick functionality to open basic information page PC-CC-20
  const DeOkclick = async () => {
    // eslint-disable-next-line
    //debugger;
    console.log("DeOkclick", DEStatus);
    // props.history.push(`/update/${cid}/customer`)
    // const customer = await CreateCust();
    setdedeclineisopen(false);
    // setbasicinfoactive(true);
    // if (custID !== undefined && custID.customerId !== undefined) {
    //   props.history.push(
    //     `/customer/createcustomer/${custID.customerId}/create`
    //   );
    // }
    if (
      (DEStatus == "1" || DEStatus == "2" || DEStatus == "4") &&
      DEStatus !== ""
    ) {
      const path: any =
        wLeadResponse !== undefined &&
        wLeadResponse !== null &&
        wLeadResponse !== "" &&
        wLeadResponse.leadStatus !== undefined &&
        wLeadResponse.leadStatus !== null &&
        wLeadResponse.leadStatus !== ""
          ? wLeadResponse.leadStatus
          : "";
      if (custID !== undefined && custID.customerId !== undefined && !IsRunDE) {
        customerIdQueryParams == null
          ? props.history.push(
              salesLeadIdQuery !== undefined &&
                salesLeadIdQuery !== null &&
                salesLeadIdQuery !== "" &&
                path !== "Converted"
                ? `/customer/createcustomer/${custID.customerId}/create?salesLeadId=${salesLeadIdQuery}`
                : `/customer/createcustomer/${custID.customerId}/create`
            )
          : props.history.push(
              salesLeadIdQuery !== undefined &&
                salesLeadIdQuery !== null &&
                salesLeadIdQuery !== "" &&
                path !== "Converted"
                ? `/customer/createcustomer/${custID.customerId}/create?customerId=${customerIdQueryParams}&&salesLeadId=${salesLeadIdQuery}`
                : `/customer/createcustomer/${custID.customerId}/create?customerId=${customerIdQueryParams}`
            );
        // props.history.push(
        //   `/customer/createcustomer/${custID.customerId}/create`
        // );
      }
    } else if (DEStatus == "") {
      setreasonDEopen(true);
      await GetNoDEReason();
    }
    setIsRunDE(false);
  };
  // OkHandleChange to open popup PC-CC-21
  const OkHandleChange = () => {
    setcheckSSNEnter(true);
    setenterssnisopen(false);
    setSocialSecurityBorderOne("red");
    setSocialSecurityBorderTwo("red");
    setSocialSecurityBorderThree("red");
  };
  // Cancelclick for the Cancel button PC-CC-22
  const Cancelclick = () => {
    setreasonDEopen(false);
  };
  const buildAd2 = (ad: any) => {
    return ad !== null && ad !== undefined && ad !== "" && ad.length > 0
      ? ad + ","
      : "";
  };
  const addressDoctorPopup = () => {
    return (
      <>
        <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
            Entered address is not located
          </Typography>
          <Grid
            item
            md={12}
            className={`${classes.spacerMB2} ${classes.spacerMT2}`}
          >
            <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == "SUG" ? true : false}
                onClick={() => RadioBtnclick("SUG")}
                value={"SUG"}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
              >
                {SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.StreetAddress !== undefined
                  ? `${SuggestedAddress.Addresses.StreetAddress},`
                  : ""}

                {SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.City !== undefined
                  ? `${SuggestedAddress.Addresses.City},`
                  : ""}
                {SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.StateProvince !== undefined
                  ? `${SuggestedAddress.Addresses.StateProvince},`
                  : ""}
                {SuggestedAddress !== undefined &&
                SuggestedAddress !== null &&
                SuggestedAddress.Addresses !== null &&
                SuggestedAddress.Addresses.PostalCode !== undefined
                  ? `${SuggestedAddress.Addresses.PostalCode}`
                  : ""}
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} className={classes.spacerMB3} />
          <Typography className={`${classes.RACPOPMsg} ${classes.semiBold}`}>
            Entered Address
          </Typography>
          <div className={classes.formCheck}>
            <RACRadio
              id="inlineRadio2"
              name="inlineRadioOptions"
              checked={defaultSuggestedAddress == "ENT" ? true : false}
              onClick={() => RadioBtnclick("ENT")}
              value={"ENT"}
            />
            <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
            >
              {formField?.addressLine1}, {buildAd2(formField?.addressLine2)}{" "}
              {formField?.city}, {formField?.state}, {formField?.zipCode}
            </Typography>
          </div>
        </Grid>
        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setValidateIsOpen(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              className={classes.spacerME2}
              onClick={() => continueclick("ENT")}
              disabled={BtnIsdisabled1}
            >
              Override
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              disabled={BtnIsdisabled2}
              onClick={() => continueclick("SUG")}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const stateForCloaseAddressDoctoer = () => {
    setValidateIsOpen(false);
  };
  // validation message popup for suggested and entered address PC-CC-23
  function ValidationMessagePopup() {
    return (
      <RACModalCard
        isOpen={ValidateIsOpen}
        maxWidth="sm"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => stateForCloaseAddressDoctoer()}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title="Validation Message"
      />
    );
  }
  const addCoCustomerChildrenPopup = () => {
    return (
      <AddCoCustomer
        customerId={
          custID !== undefined && custID.customerId !== undefined
            ? custID.customerId
            : null
        }
        handleClosePopUp={(action: boolean) => {
          Setshowcocustomerpopup(action);
        }}
        showCustomerInfo={(customerId: string) => {
          props.history.push(`/customer/update/${customerId}/customer`);
        }}
        history={props.history}
        styles={customerStyles}
      ></AddCoCustomer>
    );
  };
  function AddCoCustomerPopup() {
    return (
      <RACModalCard
        isOpen={showcocustomerpopup}
        maxWidth="lg"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => Setshowcocustomerpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addCoCustomerChildrenPopup()}
        title="Co-Customer Information"
      />
    );
  }
  const custNotFoundPopup = () => {
    return (
      <Grid item md={12} className={classes.spacerPX2}>
        <Grid
          container
          classes={{
            root: clsx(classes.customerJustifycontentcenter, classes.spacerMB2),
          }}
        >
          <Alerticon style={{ width: "30px", height: "30px" }} />
          <Typography
            classes={{
              root: clsx(classes.spacerMS2, classes.spacerMT1),
            }}
          >
            No Records Found
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            className={`${classes.RACPOPMsgforCC} ${classes.textCenter}`}
          >
            Do you want to create{" "}
            <Typography
              variant="caption"
              className={`${classes.bold} ${classes.font16}`}
            >
              {formField?.lastName} {formField?.firstName}
            </Typography>{" "}
            as a new customer?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => setcustpopup(false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => CreatenewHandleChange()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  // popup for want to create New customer PC-CC-25
  function Createnew() {
    return (
      <RACModalCard
        isOpen={custpopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setcustpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={custNotFoundPopup()}
      />
    );
  }
  const runDEResponsePopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Would you like to run the decision engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => NOHandleChange()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => YesHandleChange()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const addressDoctorErrorPopup = () => {
    return (
      <Grid item md={12} className={classes.spacerPX2}>
        <Grid item md={12} className={classes.customerTextcenter}>
          <Typography className={classes.customerRacpopup}>
            {addressErrorMsg}
          </Typography>
        </Grid>
        <Grid
          container
          classes={{
            root: clsx(classes.customerJustifycontentcenter, classes.spacerMT4),
          }}
        >
          {matchCode == "1" || matchCode == "2" ? (
            <Grid item md={12} className={classes.customerTextcenter}>
              <RACButton
                className={`${classes.spacerMR2}`}
                variant="outlined"
                color="primary"
                isRounded={false}
                onClick={() => {
                  setSuggestionAddresPopup(false);
                  // continueclick('ENT');
                }}
              >
                No
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                isRounded={false}
                onClick={() => {
                  setSuggestionAddresPopup(false);
                  if (defaultSuggestedAddress == "GA") continueclick("GA");
                  else continueclick("ENT", "MC1");
                }}
              >
                Yes
              </RACButton>
            </Grid>
          ) : (
            <Grid item md={12} className={classes.customerTextcenter}>
              <RACButton
                variant="contained"
                color="primary"
                isRounded={false}
                onClick={() => {
                  setSuggestionAddresPopup(false);
                  continueclick("ENT");
                }}
              >
                Ok
              </RACButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
  function AddressDoctorErrorMsg() {
    return (
      <RACModalCard
        isOpen={suggestionAddresPopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setSuggestionAddresPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorErrorPopup()}
        title="Validate Address"
      />
    );
  }
  // Would you like to Run DE popup PC-CC-26
  function Liketo() {
    return (
      <RACModalCard
        isOpen={liketoopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setliketoopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={runDEResponsePopup()}
      />
    );
  }
  const enterSSNPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            Enter the SSN# to run the decision engine.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          {ssonpopupforRunDE === true ? (
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              style={{
                textTransform: "none",
                // eslint-disable-next-line sonarjs/no-duplicate-string
                width: "100px !important",
                height: "35px",
                marginTop: "29px",
              }}
              onClick={() =>
                custID !== undefined && custID.customerId !== undefined
                  ? props.history.push(
                      `/customer/update/${custID.customerId}/customer`
                    )
                  : ""
              }
            >
              Ok
            </RACButton>
          ) : null}
          {ssonpopupforRunDE === false ? (
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              style={{
                textTransform: "none",
                // eslint-disable-next-line sonarjs/no-duplicate-string
                width: "100px !important",
                height: "35px",
                marginTop: "29px",
              }}
              onClick={() => OkHandleChange()}
            >
              Ok
            </RACButton>
          ) : null}
        </Grid>
      </Grid>
    );
  };
  // popup to enter SSn PC-CC-27
  function Enterssn() {
    return (
      <RACModalCard
        isOpen={enterssnisopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setenterssnisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={enterSSNPopup()}
      />
    );
  }
  const RefundRespPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            {refundResponseMsg}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => setrefundpopup(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // popup to enter SSn PC-CC-27
  function RefundResp() {
    return (
      <RACModalCard
        isOpen={refundpopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setrefundpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={RefundRespPopup()}
      />
    );
  }
  const noReasonDEPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Please specify the reason, why you are not running the decision
            engine?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACSelect
            inputLabel="Reason"
            options={noDEReasonDD}
            name="postNODEReason"
            defaultValue={
              postnodeReason !== undefined &&
              postnodeReason !== null &&
              postnodeReason !== ""
                ? postnodeReason
                : "0"
            }
            loading={noDEReasonDD.length == 0 && !dropDownLoaded ? true : false}
            onChange={(e: any) => {
              e.target.name = `postNODEReason`;
              setpostnodeReason(e.target.value);
              setStoreExceptionReason(e?.currentTarget?.innerText);
            }}
            {...(noDEReasonDD.length == 0 &&
              dropDownLoaded && {
                errorMessage: API_ERROR_MESSAGE,
              })}
          />
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => Cancelclick()}
          >
            Cancel
          </RACButton>
          <RACButton
            size="small"
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => postNoDESubmitClick()}
            disabled={postnodeReason == "0" ? true : false}
          >
            Submit
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function NoDEReason() {
    return (
      <RACModalCard
        isOpen={reasonDEopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => Cancelclick()}
        // eslint-disable-next-line react/no-children-prop
        children={noReasonDEPopup()}
      />
    );
  }
  function CustNotFoundPopUp() {
    return (
      <Grid item md={12} className={classes.px2}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.RACPOPMsg}>
            Customer record is incomplete, please create new customer.
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            color="primary"
            variant="contained"
            onClick={() => {
              setisclsAlertMsg(false);
              setcreatecustomerPopup(true);
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );

    // Reason popup for Not running DE PC-CC-29
  }
  function GetCustIDAlert() {
    return (
      <RACModalCard
        isOpen={isclsAlertMsg}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setisclsAlertMsg(false)}
        // eslint-disable-next-line react/no-children-prop
        children={CustNotFoundPopUp()}
        title=""
      />
    );
  }
  // const stateDropDownFN = () => {
  //   if (stateDD.length > 0) {
  //     const buildStateDropDown: any[] = [];
  //     // const stateDDStructure = stateDD.map((el: any, i: any) => {
  //     //   stateDD[i].label = el.stateAbbreviation;
  //     //   stateDD[i].value = el.stateAbbreviation;
  //     //   return el;
  //     // });
  //     stateDD.map((value: any, index: any) => {
  //       if (index == 0) {
  //         buildStateDropDown.push({ label: 'Select', value: '0' });
  //       }
  //       buildStateDropDown.push({
  //         label: value.stateAbbreviation,
  //         value: value.stateAbbreviation,
  //       });
  //     });
  //     console.log('stateDDStructure', buildStateDropDown);
  //     setDD(buildStateDropDown);
  //     // return stateDD.map((el: any, i: any) => {
  //     //   return (
  //     //     <option key={i} value={el.stateAbbreviation}>
  //     //       {el.stateAbbreviation}
  //     //     </option>
  //     //   );
  //     // });
  //   }
  // };
  const createcustomermodalPopup = () => {
    return (
      <CreateCustomerComponent
        basicInfo={agrDetails}
        handleClosePopUp={(action: boolean) => {
          setcreatecustomerPopup(action);
        }}
        salesLeadId={salesLeadIdQuery}
        webleadPaymentId={webleadPaymentId && webleadPaymentId.wleadPaymentId}
      />
    );
  };
  function CreateCustomerModal() {
    return (
      <RACModalCard
        isOpen={createcustomerPopup}
        borderRadius="20px"
        maxWidth="lg"
        closeIcon={true}
        onClose={() => setcreatecustomerPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createcustomermodalPopup()}
        title=""
      />
    );
  }
  const getNoDEDropDown = () => {
    if (noDEReasonDD.length > 0) {
      return noDEReasonDD.map((el: any, i: any) => {
        return (
          <option key={i} value={el.referenceCode}>
            {el.description}
          </option>
        );
      });
    }
  };
  // eslint-disable-next-line sonarjs/no-identical-functions
  const postNoDESubmitClick = async () => {
    setreasonDEopen(false);
    setblurLoaderActive(true);
    console.log("custID", custID);
    // eslint-disable-next-line
    //debugger
    if (
      custID !== undefined &&
      custID.globalCustomerId !== undefined &&
      custID.customerId !== undefined
    ) {
      const res: any = {
        globalCustomerId: custID.globalCustomerId,
        customerId: custID.customerId,
        deExceptionReason: postnodeReason,
      };
      console.log("res", res);
      const payload: any = {
        storeException: [
          {
            identifierId: Number(custID.customerId),
            identifierType: "CUSTOMER",
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            exceptionTypeRefCode: "DEB",
            exceptionText: storeExceptionReason,
          },
        ],
      };
      const response = await UpdateNoDeReason(res);
      storeException(payload);

      console.log(response);
      setdedeclineisopen(false);
      setblurLoaderActive(false);
      if (searchResults.length > 0) {
        if (custID.customerId !== undefined) {
          props.history.push(`/customer/update/${custID.customerId}/customer`);
          // props.history.push(
          //   `/customer/createcustomer/${custID.customerId}/create`
          // );
        }
        // if (custID.customerId !== undefined) {
        //   props.history.push(`/customer/update/${custID.customerId}/customer`);
        // props.history.push(
        //   `/customer/createcustomer/${custID.customerId}/create`
        // );
      } else {
        const path: any =
          wLeadResponse !== undefined &&
          wLeadResponse !== null &&
          wLeadResponse !== "" &&
          wLeadResponse.leadStatus !== undefined &&
          wLeadResponse.leadStatus !== null &&
          wLeadResponse.leadStatus !== ""
            ? wLeadResponse.leadStatus
            : "";
        customerIdQueryParams == null
          ? props.history.push(
              salesLeadIdQuery !== undefined &&
                salesLeadIdQuery !== null &&
                salesLeadIdQuery !== "" &&
                path !== "Converted"
                ? `/customer/createcustomer/${custID.customerId}/create?salesLeadId=${salesLeadIdQuery}&&modelNumber=${salesLeadModelNumber}`
                : `/customer/createcustomer/${custID.customerId}/create`
            )
          : props.history.push(
              salesLeadIdQuery !== undefined &&
                salesLeadIdQuery !== null &&
                salesLeadIdQuery !== "" &&
                path !== "Converted"
                ? `/customer/createcustomer/${custID.customerId}/create?customerId=${customerIdQueryParams}&&salesLeadId=${salesLeadIdQuery}&&modelNumber=${salesLeadModelNumber}`
                : `/customer/createcustomer/${custID.customerId}/create?customerId=${customerIdQueryParams}`
            );
        //}
      }
    } else {
      const resp: any = await CreateCust();
      if (
        resp !== undefined &&
        resp !== null &&
        resp.globalCustomerId !== undefined &&
        resp.customerId !== undefined
      ) {
        const res: any = {
          globalCustomerId: resp.globalCustomerId,
          customerId: resp.customerId,
          deExceptionReason: postnodeReason,
        };
        const payload: any = {
          storeException: [
            {
              identifierId: Number(resp.customerId),
              identifierType: "CUSTOMER",
              storeNumber: window.sessionStorage.getItem("storeNumber"),
              exceptionTypeRefCode: "DEB",
              exceptionText: storeExceptionReason,
            },
          ],
        };
        console.log("res", res);
        const response = await UpdateNoDeReason(res);
        storeException(payload);
        console.log(response);
      }
      setdedeclineisopen(false);
      setblurLoaderActive(false);
      if (
        resp !== undefined &&
        resp !== null &&
        resp?.customerId !== undefined
      ) {
        const path: any =
          wLeadResponse !== undefined &&
          wLeadResponse !== null &&
          wLeadResponse !== "" &&
          wLeadResponse.leadStatus !== undefined &&
          wLeadResponse.leadStatus !== null &&
          wLeadResponse.leadStatus !== ""
            ? wLeadResponse.leadStatus
            : "";
        customerIdQueryParams == null
          ? props.history.push(
              salesLeadIdQuery !== undefined &&
                salesLeadIdQuery !== null &&
                salesLeadIdQuery !== "" &&
                path !== "Converted"
                ? `/customer/createcustomer/${resp.customerId}/create?salesLeadId=${salesLeadIdQuery}&&modelNumber=${salesLeadModelNumber}`
                : `/customer/createcustomer/${resp.customerId}/create`
            )
          : props.history.push(
              salesLeadIdQuery !== undefined &&
                salesLeadIdQuery !== null &&
                salesLeadIdQuery !== "" &&
                path !== "Converted"
                ? `/customer/createcustomer/${resp.customerId}/create?customerId=${customerIdQueryParams}&&salesLeadId=${salesLeadIdQuery}&&modelNumber=${salesLeadModelNumber}`
                : `/customer/createcustomer/${resp.customerId}/create?customerId=${customerIdQueryParams}`
            );
      }
    }
  };

  const dedeclineMsgPopup = () => {
    return (
      <>
        <Grid item md={12}>
          <Grid item md={12} className={classes.textCenter}>
            {DEStatus == "" ? (
              <Typography variant={"body2"} className={classes.RACPOPMsg}>
                Error in approval process. Please proceed without approval
              </Typography>
            ) : (
              <Typography variant={"body2"} className={classes.RACPOPMsg}>
                {`Decision engine has ${
                  DEStatus == "1"
                    ? "approved"
                    : DEStatus == "2"
                    ? "declined"
                    : DEStatus == "4"
                    ? CUSTOMER_APPROVAL_FLOW ? DE_RUNNED_CA_STATUS : 'conditionally approved'
                    : ""
                } the customer`}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              color="primary"
              variant="contained"
              onClick={() => DeOkclick()}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  // DE decline popup PC-CC-30
  function Dedecline() {
    return (
      <RACModalCard
        isOpen={dedeclineisopen}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setdedeclineisopen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={dedeclineMsgPopup()}
      />
    );
  }
  const validateSSN = () => {
    let ssnValidation = true;
    if (
      formField?.ssn1 !== undefined &&
      formField?.ssn1 !== null &&
      formField?.ssn2 !== undefined &&
      formField?.ssn2 !== null &&
      formField?.ssn3 !== undefined &&
      formField?.ssn3 !== null
    ) {
      if (
        formField?.ssn1.length +
          formField?.ssn2.length +
          formField?.ssn3.length !==
          0 &&
        formField?.ssn1.length +
          formField?.ssn2.length +
          formField?.ssn3.length <
          9
      ) {
        ssnValidation = false;
      }
    } else if (
      formField?.ssn1 !== undefined &&
      formField?.ssn1 !== null &&
      formField?.ssn1.length > 0
    ) {
      ssnValidation = false;
    } else if (
      formField?.ssn2 !== undefined &&
      formField?.ssn2 !== null &&
      formField?.ssn2.length > 0
    ) {
      ssnValidation = false;
    } else if (
      formField?.ssn3 !== undefined &&
      formField?.ssn3 !== null &&
      formField?.ssn3.length > 0
    ) {
      ssnValidation = false;
    }
    return ssnValidation;
  };
  // validate customer button functionality PC-CC-31
  const ValidateCustomerClcik = async () => {
    if (
      formField?.emailAddress.length > 0 ||
      formField?.ssn1.length + formField?.ssn2.length + formField?.ssn3.length >
        0
    ) {
      const res =
        formField?.emailAddress.length > 0
          ? ValidateEmail(formField?.emailAddress)
          : true;
      const validateSSNObj = validateSSN();

      if (res && validateSSNObj) {
        setclearbtnHidden(true);
        setSsnValid(false);
        setWebLeads([]);
        setsearchResults([]);
        setblurLoaderActive(true);
        setsearchResultActive(true);
        await showSearchResults();
        // getAddress();
        setenableValidatebtn(true);
      }
      validateSSNObj === false ? setSsnValid(true) : setSsnValid(false);
    } else {
      setclearbtnHidden(true);
      setSsnValid(true);
      setWebLeads([]);
      setsearchResults([]);
      setblurLoaderActive(true);
      setsearchResultActive(true);
      await showSearchResults();
      // getAddress();
      setenableValidatebtn(true);
    }

    //SetButtonactive(false)
  };
  interface AddressResponse {
    message?: string;
    status: boolean;
    matchCode?: string | undefined;
    suggestedAddress?: string;
    postalAddress?: PostalAddress;
  }

  interface PostalAddress {
    addressLines: string[];
    administrativeArea: string;
    postalCode: string;
    locality: string;
  }
  const getAddress = async () => {
    const addressPayload: any = {
      MessageID: "CMS006-93589345",
      MessageType: "CMS006",
      MessageDTS: "2014-05-23-14:30:15:254633",
      MessageDTSTZOffset: "-06:00",
      RegionID: "1",
      ClientID: "8",
      ClientLocationNumber: "00006",
      ClientSource: "1",
      ClientOriginator: "1",
      LocationNumber: loginStore,
      EncryptionMethod: "0",
      Addresses: [
        {
          AddressID: "11114",
          StreetAddress: "",
          City: "",
          StateProvince: "",
          PostalCode: "",
          Country: "",
        },
      ],
      StoreConfig: {
        storeNumbers: [loginStore],
        paramKeyNames: ["AddressDoctorEnabled"],
      },
    };
    addressPayload.Addresses[0].City = formField?.city;
    addressPayload.Addresses[0].StreetAddress = formField?.addressLine1;
    addressPayload.Addresses[0].StateProvince = formField?.state;
    addressPayload.Addresses[0].PostalCode = formField?.zipCode.replaceAll(
      "-",
      ""
    );
    addressPayload.Addresses[0].Country = "USA";
    try {
      if (featureFlagDetails.EnableGoogleAddressValidation == "1") {
        setdefaultSuggestedAddress("GA");
        const googleAddressValidationPayload: any = JSON.parse(
          JSON.stringify(addressPayload)
        );
        googleAddressValidationPayload.enableGoogleAddressValidation = true;
        const arr = [
          formField?.addressLine1,
          formField?.city,
          formField?.state,
          formField?.zipCode,
        ];
        if (formField?.addressLine2) {
          addressPayload.Addresses[0].StreetAddress += ` ${formField?.addressLine2}`;
          arr.unshift(formField?.addressLine2);
        }
        const validateAddressPayload = {
          postalCode: formField?.zipCode,
          addressLines: arr,
        };
        googleAddressValidationPayload.validateAddress = validateAddressPayload;
        const response: any = await Promise.all([
          GetSuggestedAddress(addressPayload),
          GetSuggestedAddress(googleAddressValidationPayload),
        ]);
        setblurLoaderActive(false);

        console.log(response, "The response from the getSuggested Address");
        debugger;
        if (response[0] && response[1].validateAddress) {
          const result: AddressResponse = validateAddressFn(
            response[1].validateAddress,
            response[0]?.validateAddress,
            response[0] &&
              response[0].storeConfig &&
              response[0].storeConfig.storeProfileResponse.configDetails[0]
                .configDetails[0].paramValue == "1"
              ? true
              : false,
            googleAddressValidationPayload
          );
          console.log(result, "validate address function results");
          if (result.status) {
            if (result.matchCode) {
              setmatchCode(result.matchCode);
              const errorMessage: string =
                result.matchCode == "1"
                  ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                  : result.matchCode == "2"
                  ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                  : "";
              setAddressErrorMsg(errorMessage);
              setSuggestionAddresPopup(true);
              // setSuggestedAddress(undefined);
              const postalAddress = {
                addressLines: [formField?.addressLine1],
                locality: result?.postalAddress?.locality,
                postalCode: result?.postalAddress?.postalCode,
                administrativeArea: result.postalAddress?.administrativeArea,
              };
              if (formField?.addressLine2)
                postalAddress.addressLines.unshift(formField?.addressLine2);
              setValidatedAddress({ postalAddress: postalAddress });
              setdefaultSuggestedAddress("GA");
            } else {
              setCanSuggestAddress(true);
              setValidatedAddress(result);
            }
          } else {
            setCantSuggestAddress(true);
            setValidatedAddress(result);
          }
        } else {
          throw new Error("Something went wrong");
        }
      } else {
        const response: any = await GetSuggestedAddress(addressPayload);
        console.log("AddressDoctor", response);
        if (
          response !== undefined &&
          response !== null &&
          response.storeConfig !== undefined &&
          (response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == "Y" ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == "1" ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == 1)
        ) {
          setblurLoaderActive(false);
          //setSuggestedAddress(response.validateAddress);
          if (
            response.validateAddress !== undefined &&
            response.validateAddress.Addresses !== undefined &&
            (response.validateAddress.Addresses.MatchCode == "1" ||
              response.validateAddress.Addresses.MatchCode == "2")
          ) {
            setmatchCode(response.validateAddress.Addresses.MatchCode);
            const errorMessage: string =
              response.validateAddress.Addresses.MatchCode == "1"
                ? `Address could not be corrected. Unlikely to match. Do you want to override?`
                : response.validateAddress.Addresses.MatchCode == "2"
                ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
                : "";
            setAddressErrorMsg(errorMessage);
            setSuggestionAddresPopup(true);
            setSuggestedAddress(undefined);
          } else {
            setSuggestedAddress(response.validateAddress);
            setSuggestionAddresPopup(false);
            setValidateIsOpen(true);
          }
        } else if (
          response !== undefined &&
          response !== null &&
          response.storeConfig !== undefined &&
          (response.storeConfig.storeProfileResponse.configDetails[0]
            .configDetails[0].paramValue == "N" ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == "0" ||
            response.storeConfig.storeProfileResponse.configDetails[0]
              .configDetails[0].paramValue == 0)
        ) {
          setblurLoaderActive(false);
          setAddressErrorMsg(
            "Automatic Address validation is Unavailable for the store # Please validate the address Manually."
          );
          setSuggestionAddresPopup(true);
          setSuggestedAddress(undefined);
          //setnotAvailableAD(true);
        } else {
          setAddressErrorMsg("Address validation currently Unavailable");
          setSuggestionAddresPopup(true);
          setSuggestedAddress(undefined);
          setblurLoaderActive(false);
        }
      }
      //setnotAvailableAD
    } catch (e: any) {
      setAddressErrorMsg("Address validation currently Unavailable");
      setSuggestionAddresPopup(true);
      setSuggestedAddress(undefined);
      setblurLoaderActive(false);
      //setnotAvailableAD(true);
      console.log("Error In Address Doctor", e);
    }
  };
  const paymentRedirect = () => {
    if (custID !== undefined && custID.customerId !== undefined) {
      props.history.push(`/payment1/paymentinformation/${custID.customerId}/0`);
    }
  };
  const agreementRedirect = () => {
    if (custID !== undefined && custID.customerId !== undefined) {
      props.history.push(
        custID.salesLeadId !== undefined &&
          custID.salesLeadId !== null &&
          custID.salesLeadId !== "" &&
          custID.salesLeadId === salesLeadIdQuery
          ? `/agreement/rental/itemsearch/${custID.customerId}?salesLeadId=${custID.salesLeadId}`
          : `/agreement/rental/itemsearch/${custID.customerId}`
      );
      // props.history.push(`/agreement/rental/itemsearch/${custID.customerId}`);
    }
  };

  const createAgreementHandler = async () => {
    console.log("agreement handler", custID);
    try {
      if (
        custID.coworker !== undefined &&
        custID.coworker !== null &&
        custID.coworker == "Y"
      ) {
        setcomingSoonPopup(true);
      } else {
        // if (
        //   custID !== undefined &&
        //   custID.governmentIdType !== undefined &&
        //   custID.governmentIdType !== null &&
        //   custID.governmentIdType !== '' &&
        //   custID.emailAddress !== undefined &&
        //   custID.emailAddress !== null &&
        //   custID.emailAddress !== ''
        // ) {
        //   setliketoCreateAgreement(true);
        // } else {
        //   setagreementMandatoryFieldpopup(true);
        // }
        if (
          salesLeadIdQuery !== undefined &&
          salesLeadIdQuery !== null &&
          salesLeadIdQuery !== ""
        ) {
          const payload = {
            customerId: custID.customerId,
            salesLeadId: salesLeadIdQuery,
          };
          setblurLoaderActive(true);
          const result = await UpdateCustomerInfo(payload, custID.customerId);
          setblurLoaderActive(false);
        }
        agreementRedirect();
      }
    } catch (e: any) {
      console.log("agreement handler");
    }
    // agreementRedirect();
  };
  const comingsoonpopUp = () => {
    return (
      <>
        <Grid className={`${classes.infoTextStyle}`}>
          <Typography>Coming Soon!</Typography>
        </Grid>
        <Grid
          container
          item
          xl={12}
          className={`${classes.px2} ${classes.pt2}`}
        >
          <CardContent className={`${classes.infoTextStyle} ${classes.mb5}`}>
            <Typography>
              {coWorkerRole == 1
                ? "Selected customer is a RAC employee. Only a DM (and above) can create an employee discounted Agreement"
                : coWorkerRole == 2
                ? "Selected customer is a RAC employee, please use SIMS for creating discounted agreement"
                : ""}
            </Typography>
          </CardContent>
        </Grid>
      </>
    );
  };

  function suggestAddressFn() {
    return (
      <>
        <Grid item md={12} className={classes.spacerPX2}>
          <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
            Entered Address
          </Typography>
          <div className={classes.formCheck}>
            <RACRadio
              id="inlineRadio1"
              name="inlineRadioOptions"
              checked={defaultSuggestedAddress == "ENT" ? true : false}
              onClick={() => RadioBtnclick("ENT")}
              value={"ENT"}
            />
            <Typography
              className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
            >
              {formField?.addressLine1}, {buildAd2(formField?.addressLine2)}{" "}
              {formField?.city}, {formField?.state}, {formField?.zipCode}
            </Typography>
          </div>
          <Grid
            item
            md={12}
            className={`${classes.spacerMB2} ${classes.spacerMT2}`}
          >
            <Typography className={`${classes.RACPOPMsg} ${classes.bold}`}>
              Suggested Addresses
            </Typography>
            <div className={classes.formCheck}>
              <RACRadio
                id="inlineRadio1"
                name="inlineRadioOptions"
                checked={defaultSuggestedAddress == "GA" ? true : false}
                onClick={() => RadioBtnclick("GA")}
                value={"GA"}
              />
              <Typography
                className={`${classes.RACPOPMsg} ${classes.semiBold} ${classes.dispInlineFlex}`}
              >
                {validatedAddresses.suggestedAddress}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.spacerMT4} ${classes.w100} ${classes.floatLeft}`}
        >
          <Grid className={classes.floatLeft}>
            <RACButton
              variant="outlined"
              color="primary"
              className={classes.foc}
              onClick={() => setCanSuggestAddress(false)}
            >
              Cancel
            </RACButton>
          </Grid>
          <Grid className={`${classes.floatRight}`}>
            <RACButton
              variant="contained"
              color="primary"
              // disabled={BtnIsdisabled2}
              onClick={() => continueclick(defaultSuggestedAddress)} // Need to work on the continue click
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  }
  function suggestErrorInAddress() {
    return (
      <>
        <Grid item md={12}>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
              marginBottom: "2%",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid item md={12} className={classes.textCenter}>
            <Typography variant={"body2"} className={classes.RACPOPMsg}>
              {validatedAddresses?.message}
            </Typography>
          </Grid>
          <Grid
            container
            className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
          >
            <RACButton
              variant="contained"
              color="primary"
              isRounded={false}
              onClick={() => setCantSuggestAddress(false)}
            >
              Ok
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  }

  function ComingSoonMsg() {
    return (
      <RACModalCard
        isOpen={comingSoonPopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setcomingSoonPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={comingsoonpopUp()}
        title=""
      />
    );
  }
  const createAgreementYesHandleChange = () => {
    setliketoCreateAgreement(false);
    Setshowcocustomerpopup(true);
  };
  const renderSearchTableHead = () => (
    <>
      {SearchGridHeader.map((col: string, index: number) => {
        if (col == "Last Name") {
          return (
            <RACTableCell
              className={`${classes.customerTablecellbgcolor} ${classes.gridBorderBottom}`}
              key={index}
            >
              {col}
              <span>
                <a
                  onClick={() =>
                    sortGrid(sortOrder.lN == "asc" ? "desc" : "asc", "LN")
                  }
                >
                  {sortOrder.lN == "asc" ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
            </RACTableCell>
          );
        } else if (col == "First Name") {
          return (
            <RACTableCell
              className={`${classes.customerTablecellbgcolor} ${classes.gridBorderBottom}`}
              key={index}
            >
              {col}
              <span>
                <a
                  onClick={() =>
                    sortGrid(sortOrder.fN == "asc" ? "desc" : "asc", "FN")
                  }
                >
                  {sortOrder.fN == "asc" ? (
                    <SortDownIcon className={classes.spacerMS1}></SortDownIcon>
                  ) : (
                    <SortUpIcon className={classes.spacerMS1}></SortUpIcon>
                  )}
                </a>
              </span>
            </RACTableCell>
          );
        } else {
          return (
            <RACTableCell
              className={`${classes.customerLineBreak} ${classes.customerTablecellbgcolor} ${classes.gridBorderBottom}`}
              key={index}
            >
              {col}
            </RACTableCell>
          );
        }
      })}
    </>
  );

  const renderSearchTableContent = () => {
    //if (loading) return <></>;

    return searchResults !== undefined &&
      searchResults !== null &&
      searchResults.length > 0
      ? renderSearchTableContentFilled()
      : renderContentNoRecordFound();
  };

  /* eslint-disable sonarjs/cognitive-complexity */
  const renderSearchTableContentFilled = () => {
    return searchResults.map((el: any, i: any) => {
      const address: any =
        el.addresses !== undefined &&
        el.addresses !== null &&
        el.addresses.length > 0
          ? el.addresses.filter(
              (e: any) => e.addressType == "PRIM" || e.addressType == "P"
            )
          : [];
      const phones: any =
        el.phones !== undefined && el.phones !== null && el.phones.length > 0
          ? el.phones.filter((e: any) => e.isPrimary == "Y")
          : [];
      return (
        <React.Fragment key={i}>
          <RACTableRow
            key={i}
            className={
              showCustomerAgrIndex !== i
                ? `${classes.customerTablecellbgcolor}`
                : `${classes.customerTablecellbgcolor} ${classes.cusomerInfo}`
            }
          >
            {el.AgreementCount != 0 ? (
              <RACTableCell
                data-bs-toggle="collapse"
                onClick={() => showAgreement(i, el.globalCustomerId)}
                data-bs-target="#row-1"
                aria-expanded={showCustomerAgrIndex == i ? true : false}
                className={`${classes.accordianWidth}`}
              >
                {showCustomerAgrIndex !== i ? (
                  <img
                    className={`${classes.accordionIconGrid} ${classes.me2}`}
                    src={AccordionOpen}
                  />
                ) : (
                  <img
                    className={`${classes.accordionIconGrid} ${classes.me2}`}
                    src={AccordionDown}
                  />
                )}
              </RACTableCell>
            ) : (
              <RACTableCell className={classes.customerTablecellbgcolor}>
                <span>{""}</span>
              </RACTableCell>
            )}
            <RACTableCell
              classes={{
                root: clsx(
                  classes.checkBoxwidth,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              <RACCheckBox
                label=""
                size="small"
                className={classes.customerCheckbox}
                name={`checkbox_${i}`}
                onClick={() => checkBoxActiveOnclick(i, el.globalCustomerId)}
                checked={
                  checkBoxActive !== undefined && checkBoxActive == i
                    ? true
                    : false
                }
              />
            </RACTableCell>
            <RACTableCell className={classes.customerTablecellbgcolor}>
              <a
                onClick={() => getcustomerbyid("R", el.globalCustomerId)}
                className={classes.customerRaclink}
              >
                {el.lastName}
              </a>
            </RACTableCell>
            <RACTableCell className={classes.customerTablecellbgcolor}>
              <a
                onClick={() => getcustomerbyid("R", el.globalCustomerId)}
                className={classes.customerRaclink}
              >
                {el.firstName}
              </a>
            </RACTableCell>
            <RACTableCell
              className={
                el.middleName !== undefined &&
                el.middleName !== null &&
                el.middleName !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.middleName !== undefined &&
              el.middleName !== null &&
              el.middleName !== ""
                ? el.middleName
                : "-"}
            </RACTableCell>
            <RACTableCell
              className={
                el.suffix !== undefined &&
                el.suffix !== null &&
                el.suffix !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.suffix !== undefined && el.suffix !== null && el.suffix !== ""
                ? el.suffix
                : "-"}
            </RACTableCell>
            <RACTableCell
              className={
                el.dateOfBirth !== undefined &&
                el.dateOfBirth !== null &&
                el.dateOfBirth !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {el.dateOfBirth !== undefined && el.dateOfBirth != null
                ? el.dateOfBirth.split("-")[1] +
                  "/" +
                  el.dateOfBirth.split("-")[2] +
                  "/" +
                  "****"
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                address !== null &&
                address.length > 0 &&
                address[0].addressLine1 !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].addressLine1
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                address !== null &&
                address.length > 0 &&
                address[0].city !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].city
                : ""}
            </RACTableCell>
            <RACTableCell
              className={
                address !== undefined &&
                address !== null &&
                address.length > 0 &&
                address[0].stateCode !== ""
                  ? classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].stateCode
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerLinebreak,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              {address !== undefined && address !== null && address.length > 0
                ? address[0].postalCode
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerLinebreak,
                  classes.customerTablecellbgcolor
                ),
              }}
            >
              {phones !== undefined && phones !== null && phones.length > 0
                ? formatNum(phones[0].phoneNumber)
                : ""}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerPointercursor,
                  classes.customerTablecellbgcolor
                ),
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title={
                el.approvalStatus !== undefined && el.approvalStatus !== null
                  ? el.approvalStatus.toUpperCase() == "APPROVE"
                    ? "DE : Approved"
                    : el.approvalStatus.toUpperCase() == "DECLINE"
                    ? "DE : Declined"
                    : el.approvalStatus.toUpperCase() ==
                        "MANUAL_INTERVENTION" ||
                      el.approvalStatus.toUpperCase() == "CONDITIONAL APPROVAL"
                    ? "DE : Conditionally Approved"
                    : "No DE"
                  : "No DE"
              }
            >
              {el.approvalStatus !== undefined && el.approvalStatus !== null ? (
                el.approvalStatus.toUpperCase() == "APPROVE" ? (
                  <DEApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEApproved>
                ) : el.approvalStatus.toUpperCase() == "DECLINE" ? (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                ) : el.approvalStatus.toUpperCase() == "MANUAL_INTERVENTION" ||
                  el.approvalStatus.toUpperCase() == "CONDITIONAL APPROVAL" ? (
                  <DEConditionallyApproved
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEConditionallyApproved>
                ) : (
                  <DEDeclined
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                  ></DEDeclined>
                )
              ) : (
                <DEDeclined
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                ></DEDeclined>
              )}
            </RACTableCell>
            <RACTableCell
              className={
                category(el) !== "-"
                  ? `${classes.customerPointercursor} ${classes.customerTablecellbgcolor} ${classes.textCenter}`
                  : classes.textCenter
              }
            >
              {category(el)}
            </RACTableCell>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.customerTextend,
                  classes.customerTablecellbgcolor,
                  classes.textCenter
                ),
              }}
              className={
                el.AgreementCount !== undefined &&
                el.AgreementCount !== null &&
                el.AgreementCount !== ""
                  ? // el.AgreementCount !== NaN
                    classes.customerTablecellbgcolor
                  : classes.textCenter
              }
            >
              {isNaN(el.AgreementCount) ? "-" : el.AgreementCount}
            </RACTableCell>
          </RACTableRow>
          {agreementTable(i)}
        </React.Fragment>
      );
    });
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderContentNoRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Records Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderAgreementTableHead = () => (
    <>
      {agreementGridHeader.map((col: string, index: number) => {
        return (
          <RACTableCell
            className={
              col == "Total Cost" || col == "Amount Due"
                ? `${classes.colRight} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`
                : col == "Next Due Date" || col == "# Payments"
                ? `${classes.textCenter} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`
                : `${classes.lineBreak} ${classes.customerTablecellbgcolor}`
            }
            key={index}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  const HandleDecimal = (value: any) => {
    const formatedValue: any = value.toString().replace(",", "");
    // eslint-disable-next-line no-console
    console.log("formatedValue", formatedValue);
    const nfObject = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });
    return nfObject.format(formatedValue);
  };
  const renderAgreementTableContent = () => {
    //if (loading) return <></>;

    return agreements !== undefined &&
      agreements !== null &&
      agreements.length > 0
      ? renderAgreementTableContentFilled()
      : renderContentNoRecordFound();
  };
  const renderAgreementTableContentFilled = () => {
    return agreements.map((el: any, i: any) => {
      return (
        <React.Fragment key={i}>
          <RACTableRow key={i}>
            <RACTableCell
              className={`${classes.customerTextsubtilegrid} ${classes.customerTablecellbgcolor}`}
              colSpan={15}
            >
              {el.storeNumber +
                " -  " +
                el.agreements[0].storeCity +
                ", " +
                el.agreements[0].stateProvinceAbbreviation}
            </RACTableCell>
          </RACTableRow>
          {el.agreements.map((el1: any, index: any) => {
            const passDue =
              el1.currentDayslate !== undefined &&
              el1.currentDayslate !== null &&
              el1.currentDayslate !== "" &&
              el1.currentDayslate !== "0" &&
              el1.currentDayslate !== 0
                ? false
                : true;
            return (
              <RACTableRow
                key={index}
                className={
                  passDue === false
                    ? `${classes.paymentIssue} ${classes.paymentIssue} ${classes.customerTablecellbgcolor}`
                    : `${classes.agreementRow} ${classes.customerTablecellbgcolor}`
                }
              >
                <RACTableCell>
                  {(el1.agreementStatus == "Pending" ||
                    el1.agreementStatus == "Active") &&
                  el1.agreementType !== "club" ? (
                    <a
                      className={`${classes.racpadAgrLink} ${
                        classes.focforLinkPaymentIssue
                      } ${
                        passDue === false
                          ? classes.racpadPaymentFails
                          : classes.racpadPaymentSuccess
                      } ${classes.racpadLink}`}
                      style={{ padding: "3px !important" }}
                      href={`/agreement/info/details/${
                        custID !== undefined && custID.customerId !== undefined
                          ? custID.customerId
                          : null
                      }/${el1.agreementId}`}
                    >
                      {el1.agreementNumber}
                    </a>
                  ) : (
                    <a
                      className={`${classes.racpadAgrLink} ${
                        classes.focforLinkPaymentIssue
                      } ${
                        passDue === false
                          ? classes.racpadPaymentFails
                          : classes.racpadPaymentSuccess
                      } ${classes.racpadClubLink}`}
                      style={{ padding: "3px !important" }}
                    >
                      {el1.agreementNumber}
                    </a>
                  )}
                  {el1.autoPay !== null &&
                  el1.autoPay !== undefined &&
                  el1.autoPay ? (
                    <span className={classes.badgeContainer}>AP</span>
                  ) : null}
                </RACTableCell>
                <RACTableCell
                  title={el1.agreementDescription}
                  className={
                    el1.agreementDescription !== undefined &&
                    el1.agreementDescription !== null &&
                    el1.agreementDescription !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementDescription !== undefined &&
                  el1.agreementDescription !== null &&
                  el1.agreementDescription !== ""
                    ? truncString(el1.agreementDescription, 35, "...")
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  title={el1.agreementType}
                  className={
                    el1.agreementType !== undefined &&
                    el1.agreementType !== null &&
                    el1.agreementType !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementType !== undefined &&
                  el1.agreementType !== null &&
                  el1.agreementType !== ""
                    ? truncString(el1.agreementType, 17, "...")
                    : ""}
                </RACTableCell>
                <RACTableCell className={classes.textCenter}>
                  {el1.numberOfPayments !== undefined &&
                  el1.numberOfPayments !== null &&
                  el1.numberOfPayments !== ""
                    ? el1.numberOfPayments
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.totalCost !== undefined &&
                    el1.totalCost !== null &&
                    el1.totalCost !== ""
                      ? `${classes.lineBreak} ${classes.textRight}`
                      : `${classes.textCenter}`
                  }
                >
                  {el1.totalCost !== undefined &&
                  el1.totalCost !== null &&
                  el1.totalCost !== ""
                    ? "$ "
                    : ""}
                  {el1.totalCost !== undefined &&
                  el1.totalCost !== null &&
                  el1.totalCost !== ""
                    ? HandleDecimal(el1.totalCost)
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.paymentSchedule !== undefined &&
                    el1.paymentSchedule !== null &&
                    el1.paymentSchedule !== ""
                      ? classes.lineBreak
                      : `${classes.textCenter}`
                  }
                >
                  {el1.paymentSchedule !== undefined &&
                  el1.paymentSchedule !== null &&
                  el1.paymentSchedule !== ""
                    ? el1.paymentSchedule
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={`${classes.lineBreak} ${classes.textCenter}`}
                >
                  {el1.nextDueDate !== undefined &&
                  el1.nextDueDate !== null &&
                  el1.nextDueDate !== ""
                    ? nextDueDayWithDate(el1.nextDueDate)
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.amountDue !== undefined &&
                    el1.amountDue !== null &&
                    el1.amountDue !== ""
                      ? `${classes.textRight}`
                      : `${classes.textCenter}`
                  }
                >
                  {el1.amountDue !== undefined &&
                  el1.amountDue !== null &&
                  el1.amountDue !== ""
                    ? "$ "
                    : "-"}
                  {el1.amountDue !== undefined &&
                  el1.amountDue !== null &&
                  el1.amountDue !== ""
                    ? HandleDecimal(el1.amountDue)
                    : ""}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.agreementStatus !== undefined &&
                    el1.agreementStatus !== null &&
                    el1.agreementStatus !== ""
                      ? ""
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementStatus !== undefined &&
                  el1.agreementStatus !== null &&
                  el1.agreementStatus !== ""
                    ? el1.agreementStatus
                    : "-"}
                </RACTableCell>
                <RACTableCell
                  className={
                    el1.agreementSubstatus !== undefined &&
                    el1.agreementSubstatus !== null &&
                    el1.agreementSubstatus !== ""
                      ? `${classes.textAlign} ${classes.lineBreak}`
                      : `${classes.textCenter}`
                  }
                >
                  {el1.agreementSubstatus !== undefined &&
                  el1.agreementSubstatus !== null &&
                  el1.agreementSubstatus !== ""
                    ? el1.agreementSubstatus
                    : "-"}
                </RACTableCell>
              </RACTableRow>
            );
          })}
        </React.Fragment>
      );
    });
  };
  const createAgreementPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Typography className={classes.RACPOPMsg}>
            Would you like to add the Co-Customer for agreements?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            isRounded={false}
            onClick={() => agreementRedirect()}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => createAgreementYesHandleChange()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function CreateAgreementrMsg() {
    return (
      <RACModalCard
        isOpen={liketoCreateAgreement}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setliketoCreateAgreement(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createAgreementPopup()}
        title=""
      />
    );
  }
  const AgreementMandotoryPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography variant={"body2"} className={classes.RACPOPMsg}>
            Enter the mandatory fields to create agreement
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            // eslint-disable-next-line sonarjs/no-identical-functions
            onClick={() =>
              custID !== undefined && custID.customerId !== undefined
                ? props.history.push(
                    `/customer/update/${custID.customerId}/customer`
                  )
                : ""
            }
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function AgreementMandatoryFieldpopup() {
    return (
      <RACModalCard
        isOpen={agreementMandatoryFieldpopup}
        maxWidth="xs"
        closeIcon={true}
        borderRadius="20px"
        onClose={() => setagreementMandatoryFieldpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={AgreementMandotoryPopup()}
        title=""
      />
    );
  }
  const fieldValidationOnblur = (field: any, e?: any) => {
    if (field == "ZIP") {
      formField?.zipCode.length !== 5 && formField?.zipCode.length !== 10
        ? setfieldValidation({ ...fieldValidationforblur, zipCode: true })
        : setfieldValidation({ ...fieldValidationforblur, zipCode: false });
    } else if (field == "PHONE") {
      formField?.mobilePhoneNumber.length < 10
        ? setfieldValidation({
            ...fieldValidationforblur,
            mobilePhoneNumber: true,
          })
        : setfieldValidation({
            ...fieldValidationforblur,
            mobilePhoneNumber: false,
          });
    } else if (field == "DOB") {
      const stDate = moment(e.target.value, "YYYY-MM-DD");
      const lt18 = moment(moment().subtract(18, "years"), "YYYY-MM-DD");
      const lt110 = moment(moment().subtract(110, "years"), "YYYY-MM-DD");
      const lt18Res: any = lt18.diff(stDate, "days");
      const lt110Res: any = lt110.diff(stDate, "days");
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      setfieldValidation({
        ...fieldValidationforblur,
        dateOfBirth: !res,
      });
    }
  };
  return (
    /*Basicinfoactive === true ?*/
    <>
      <Modal
        isOpen={blurLoaderActive}
        className={classes.loaderStyle}
        id="openpopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-hidden="true"
      >
        <div>
          <div className={classes.RACLoaderPage}>
            <CircularProgress />
          </div>
        </div>
      </Modal>
      <HeaderWizards styles={headerWizardStyles} />
      <div className={classes.racFixedFooterMargin}>
        <Grid container id="step-1">
          <ValidationMessagePopup />
          <Createnew />
          <Liketo />
          <Enterssn />
          <Dedecline />
          <GetCustIDAlert />
          <CreateAgreementrMsg />
          <AddCoCustomerPopup />
          <NoDEReason />
          <AddressDoctorErrorMsg />
          <RefundResp />
          <ComingSoonMsg />
          <AgreementMandatoryFieldpopup />
          <CreateCustomerModal />
          <Grid container className={classes.spacer}>
            <Card className={classes.card}>
              <CardContent>
                <Grid container className={classes.spacerMB3}>
                  <Grid item md={12}>
                    <Typography variant="h3" className={classes.widgetTitle}>
                      Basic Information
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="text"
                        name="LastName"
                        value={formField?.lastName}
                        maxlength={30}
                        required={true}
                        inputlabel="Last Name"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        OnChange={(e: any) => HandleChange(e)}
                        isCurrency={false}
                      />
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="text"
                        name="FirstName"
                        value={formField?.firstName}
                        required={true}
                        maxlength={30}
                        inputlabel="First Name"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        OnChange={(e: any) => HandleChange(e)}
                        isCurrency={false}
                      />
                    </Grid>
                    <Grid item md={3} lg={2} className={classes.datePicker}>
                      <Box
                        component="div"
                        className={`${classes.formLabel} ${classes.datePickerMB}`}
                      >
                        Date of Birth
                        <Box
                          component="span"
                          className={`${classes.mandatoryfield} ${classes.spacerMS1}`}
                        >
                          *
                        </Box>
                      </Box>
                      <RACDatePicker
                        id="a11y_dob"
                        label=""
                        required={true}
                        value={formField?.dateOfBirth}
                        name={"Dob"}
                        onChange={(e: any) => HandleDateChange(e)}
                        inputProps={{
                          min: moment()
                            .subtract(110, "years")
                            .format("YYYY-MM-DD"),
                          max: moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD"),
                        }}
                        classes={{
                          input:
                            fieldValidationforblur.dateOfBirth === true
                              ? `${classes.ssnBorderRed}`
                              : "",
                        }}
                        // onBlur={(e: any) => fieldValidationOnblur('DOB', e)}
                      />
                      {/* <RACDatePicker
                        id="a11y_dob"
                        value={Dob}
                        required={true}
                        label="Date of Birth"
                        onChange={(e: any) => HandleDateChange(e)}
                        inputProps={{
                          min: moment()
                            .subtract(110, 'years')
                            .format('YYYY-MM-DD'),
                          max: moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD'),
                        }}
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                      /> */}
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <Box component="label" className={classes.formLabel}>
                        Social Security# / ITIN#
                      </Box>
                      <Grid
                        container
                        spacing={2}
                        className={classes.ssnpadding}
                      >
                        <Grid
                          item
                          md={4}
                          className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                        >
                          <RACTextbox
                            type="password"
                            name="Ssn1"
                            value={formField?.ssn1}
                            id="a11y_ssnone"
                            placeHolder={"xxx"}
                            maxlength={3}
                            className={
                              checkSSNEnter && formField?.ssn1.length == 0
                                ? `${classes.ssnBorderRed} ${classes.textCenterSSN}`
                                : classes.textCenterSSN
                            }
                            OnClick={() => {
                              setenableValidatebtn(true);
                              setclearbtnHidden(false);
                            }}
                            OnChange={(e: any) => HandleChange(e)}
                            isCurrency={false}
                          />
                        </Grid>
                        <Grid
                          item
                          md={3}
                          className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                        >
                          <RACTextbox
                            type="password"
                            name="Ssn2"
                            value={formField?.ssn2}
                            placeHolder={"xx"}
                            formControlClassName={classes.ssnTextField}
                            id="a11y_ssntwo"
                            maxlength={2}
                            OnClick={() => {
                              setenableValidatebtn(true);
                              setclearbtnHidden(false);
                            }}
                            className={
                              checkSSNEnter && formField?.ssn2.length == 0
                                ? `${classes.ssnBorderRed} ${classes.textCenterSSN}`
                                : classes.textCenterSSN
                            }
                            OnChange={(e: any) => HandleChange(e)}
                            isCurrency={false}
                          />
                        </Grid>
                        <Grid
                          item
                          md={5}
                          className={`${classesManageCustomerStyles.ssnPaddingTop}`}
                        >
                          <RACTextbox
                            type="text"
                            name="Ssn3"
                            value={formField?.ssn3}
                            id="a11y_ssnthree"
                            placeHolder={"xxxx"}
                            maxlength={4}
                            className={
                              checkSSNEnter && formField?.ssn3.length == 0
                                ? `${classes.ssnBorderRed} ${classes.textCenterSSN}`
                                : classes.textCenterSSN
                            }
                            OnClick={() => {
                              setenableValidatebtn(true);
                              setclearbtnHidden(false);
                            }}
                            OnChange={(e: any) => HandleChange(e)}
                            isCurrency={false}
                          />
                        </Grid>
                      </Grid>
                      {validateSSN() === false && ssnValid === true ? (
                        <Box className={classes.mandatoryfield}>
                          Please enter valid SSN Number
                        </Box>
                      ) : null}
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="phoneno"
                        inputlabel="Phone Number"
                        maxlength={10}
                        id="a11y_phonenumber"
                        name="Phonenumber"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        value={formField?.mobilePhoneNumber}
                        OnChange={(e: any) => HandleChange(e)}
                        required={true}
                        isCurrency={false}
                        className={
                          fieldValidationforblur.mobilePhoneNumber === true &&
                          formField?.mobilePhoneNumber.length < 10
                            ? `${classes.ssnBorderRed}`
                            : ""
                        }
                        Onblur={() => fieldValidationOnblur("PHONE")}
                      />
                    </Grid>
                    <Grid item md={3} lg={2}>
                      <RACTextbox
                        type="text"
                        inputlabel="Email Address"
                        maxlength={50}
                        id="a11y_emailaddress"
                        name="emailaddress"
                        OnClick={() => {
                          setenableValidatebtn(true);
                          setclearbtnHidden(false);
                        }}
                        value={formField?.emailAddress}
                        OnChange={(e: any) => HandleChange(e)}
                        isCurrency={false}
                      />
                      {emailerrMsg ? (
                        <label className={classes.mandatoryfield}>
                          Please enter valid email address
                        </label>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={`${classes.spacerMB3} ${classes.spacerMT3}`}
                  >
                    <Grid item md={12}>
                      <Typography variant="h3" className={classes.widgetTitle}>
                        Address Information
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="Address Line 1"
                          maxlength={30}
                          id="a11y_address-1"
                          name="Addressline1"
                          placeHolder="Address Line 1"
                          OnClick={() => {
                            setenableValidatebtn(true);
                            setclearbtnHidden(false);
                          }}
                          value={formField?.addressLine1}
                          OnChange={(e: any) => HandleChange(e)}
                          required={true}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="Address Line 2"
                          maxlength={30}
                          infoIcon={true}
                          infoTitle={"Address 2, APT #/Unit #, P.O.Box"}
                          placeHolder="Address 2, APT #/Unit #, P.O.Box"
                          id="a11y_address-2"
                          name="Addressline2"
                          OnClick={() => {
                            setenableValidatebtn(true);
                            setclearbtnHidden(false);
                          }}
                          value={formField?.addressLine2}
                          OnChange={(e: any) => HandleChange(e)}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="Zip"
                          maxlength={10}
                          id="a11y_zip"
                          placeHolder="Zip Code"
                          name="zip"
                          OnClick={() => {
                            setenableValidatebtn(true);
                            setclearbtnHidden(false);
                          }}
                          value={formField?.zipCode}
                          OnChange={(e: any) => HandleChange(e)}
                          required={true}
                          isCurrency={false}
                          className={
                            fieldValidationforblur.zipCode === true &&
                            formField?.zipCode.length !== 5 &&
                            formField?.zipCode.length !== 10
                              ? `${classes.ssnBorderRed}`
                              : ""
                          }
                          Onblur={() => fieldValidationOnblur("ZIP")}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACTextbox
                          type="text"
                          inputlabel="City"
                          maxlength={30}
                          id="a11y_city"
                          placeHolder="City"
                          name="city"
                          value={formField?.city}
                          OnChange={(e: any) => HandleChange(e)}
                          required={true}
                          isCurrency={false}
                        />
                      </Grid>
                      <Grid item md={3} lg={2}>
                        <RACSelect
                          inputLabel="State"
                          options={stateDD}
                          defaultValue={
                            formField?.state !== "" ? formField?.state : "0"
                          }
                          loading={
                            stateDD.length == 0 && !dropDownLoaded
                              ? true
                              : false
                          }
                          onChange={(e: any) => {
                            e.target.name = `stateinfo`;
                            setFormField({
                              ...formField,
                              state: e.target.value,
                            });
                            //setstateinfo(e.target.value);
                          }}
                          required={true}
                          {...(stateDD.length == 0 &&
                            dropDownLoaded && {
                              errorMessage: API_ERROR_MESSAGE,
                            })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {webLeads.length > 0 &&
          webLeads != undefined &&
          !SearchCustomerActive &&
          !searchBTNClicked ? (
            <Grid container className={classes.spacerMB3PX1}>
              <Grid item md={12}>
                <Typography variant="h6" className={classes.sideTitle}>
                  Pending Escalated Sales Lead
                </Typography>
              </Grid>
              <Card className={classes.card}>
                <CardContent>
                  <RACTable
                    className={classes.racGrid}
                    renderTableHead={renderpendingEsclatedLeadHeaderList}
                    renderTableContent={renderpendingEsclatedLeadContent}
                  />
                </CardContent>
              </Card>
            </Grid>
          ) : SearchCustomerActive ? (
            <>
              <Grid
                container
                classes={{
                  root: clsx(classes.spacerMB1, classes.spacerPX2),
                }}
              >
                <Grid item md={6}>
                  <Typography variant="h6" className={classes.sideTitle}>
                    Search Results
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                classes={{
                  root: clsx(classes.spacerPB2, classes.spacerPX2),
                }}
              >
                <Card className={classes.card}>
                  <CardContent className={classes.spacerP0}>
                    <Grid item md={12}>
                      <RACTable
                        renderTableHead={renderSearchTableHead}
                        renderTableContent={renderSearchTableContent}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : null}
          {/* <Footer /> */}
          <Grid
            item
            classes={{
              root: clsx(classes.customerToolbar, classes.fixedBottom),
            }}
            style={{ zIndex: 0 }}
          >
            <Grid item className={`${classes.customerFloatleft}`}>
              <RACButton
                color="primary"
                variant="outlined"
                className={classes.foc}
                onClick={() => {
                  setisclsAlertMsg(false);
                  props.history.push(`/dashboard`);
                }}
              >
                Cancel
              </RACButton>
            </Grid>
            <Grid item spacing={2} className={classes.customerFloatright}>
              {!clearbtnHidden ? (
                <RACButton
                  variant="outlined"
                  color="primary"
                  className={`${classes.spacerMR2} ${classes.foc}`}
                  disabled={clearbtndisable}
                  data-testid="clrbtn"
                  onClick={() => {
                    clear();
                  }}
                >
                  Clear
                </RACButton>
              ) : null}
              {enableValidatebtn && newCustValidBtnHidden ? (
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={disabledValidatebtn}
                  onClick={() => {
                    ValidateCustomerClcik();
                  }}
                >
                  Validate Customer
                </RACButton>
              ) : !enableValidatebtn && newCustValidBtnHidden ? (
                <RACButton
                  variant="contained"
                  color="primary"
                  disabled={disabledValidatebtn}
                  id="validate"
                  onClick={() => NewCustomerClick()}
                >
                  New customer
                </RACButton>
              ) : null}
              {searchResults && searchResults.length ? (
                <>
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.spacerMR2}
                    isRounded={false}
                    onClick={() => runDecisionEngine(custID)}
                    disabled={checkBoxActive == undefined ? true : false}
                  >
                    Run Decision Engine
                  </RACButton>
                  <RACButton
                    variant="contained"
                    color="primary"
                    className={classes.spacerMR2}
                    isRounded={false}
                    disabled={checkBoxActive == undefined ? true : false}
                    onClick={() => paymentRedirect()}
                  >
                    Take Payment
                  </RACButton>
                  <RACButton
                    variant="contained"
                    color="primary"
                    isRounded={false}
                    disabled={checkBoxActive == undefined ? true : false}
                    onClick={() => createAgreementHandler()}
                  >
                    Create Agreement
                  </RACButton>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
      {featureFlagDetails.EnableGoogleAddressValidation == "1" ? (
        <>
          <RACModalCard
            isOpen={canSuggestAddress}
            maxWidth="sm"
            closeIcon={true}
            borderRadius="20px"
            onClose={() => setCanSuggestAddress(false)}
            // eslint-disable-next-line react/no-children-prop
            children={suggestAddressFn()}
            title="Validation Message"
          />
          <RACModalCard
            isOpen={cantSuggestAddress}
            maxWidth="xs"
            //  closeIcon={true}
            borderRadius="20px"
            //  onClose={() => setCantSuggestAddress(false)}
            // eslint-disable-next-line react/no-children-prop
            children={suggestErrorInAddress()}
          />
        </>
      ) : null}
    </>
  );
};

// {/*Customer Validation ends here*/}
// {/* <Footer firstname={FirstName} lastname={LastName} addressline1={Addressline2} zip={zip} city={city} state={stateinfo} setIsdisabled={setIsdisabled} Isdisabled={Isdisabled} clear={clear}/> */}
// export default Form;
