/* eslint-disable */
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACCOLOR,
  RACDatePicker,
  RACSelect,
  RACTextbox,
  RACToggle,
  Typography,
} from "@rentacenter/racstrap";
import {
  API_ERROR_MESSAGE,
  DEFAULT_DROPDOWN_OPTION,
  EMPTY_STRING,
  REQUIRED_ERROR,
  TAX_EXEMPT_TEMPLATE,
} from "../../constants/constants";
import {
  CustomerBasicInformation,
  TaxExemptInformation,
} from "../../interface/updateCustomerInterface";
import { DropdownOptions, ParamType, PathParams } from "../../interface/globalInterface";
import { updateCustomerStyles } from "../../jsStyles/updateCustomerStyles";
import { valueExistsInDropdown } from "../../utils/utils";
import { useParams } from "react-router-dom";
import { FaxSheetTemplate } from "../updateCustomer/microComponents/FaxSheetDetails";
import React, { ChangeEvent } from "react";
import { UpdateCustomerDetails } from "../../../api/user";

interface TaxExemptProps {
  data: {
    customerInformation: CustomerBasicInformation;
    taxExemptInformation: TaxExemptInformation;
    federalTaxId: string;
    requiredField: boolean;
    dropdownLoader: boolean;
    agreementTransferInit: boolean;
    toggle: boolean;
    dropdownOption: DropdownOptions[];
    faxSheetLoading:boolean,
    faxSheetDetails:any
  };
  function: {
    setToggle: React.Dispatch<React.SetStateAction<boolean>>;
    setTaxExemptInformation: React.Dispatch<
      React.SetStateAction<TaxExemptInformation>
    >;
    setFaxSheetLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setFaxSheetDetails:React.Dispatch<React.SetStateAction<any>>;
  };
}
export function TaxExempt(props: TaxExemptProps) {
  const classes = updateCustomerStyles();
  const { customerId } = useParams<PathParams>();

  const {
    customerInformation,
    dropdownOption,
    federalTaxId,
    taxExemptInformation,
    requiredField,
    agreementTransferInit,
    dropdownLoader,
    toggle,
    faxSheetLoading,
    faxSheetDetails
  } = props.data;

  const { setToggle, setTaxExemptInformation, setFaxSheetLoading, setFaxSheetDetails } = props.function;

  const faxCustomerDetails = {
    lastName: customerInformation?.lastName || EMPTY_STRING,
    firstName: customerInformation?.firstName || EMPTY_STRING,
    customerId: customerId || EMPTY_STRING,
  };

  const saveOnChangeValue = (field: string, value: string) => {
    try {
      setTaxExemptInformation({ ...taxExemptInformation, [field]: value });
    } catch (error: any) {
      console.log("Error in handleTaxId: ", error?.message);
    }
  };

  const printFaxCoverSheet = async () => {
    try {
      const udpateCustomerDetails = UpdateCustomerDetails({
        customerId,
        taxExempts : [
          {
            federalTaxId : taxExemptInformation.federalTaxId
          }
        ]
      });
      console.log('UpdateCustomerResponse', udpateCustomerDetails);
      const html = document?.getElementById("faxSheet")?.innerHTML;

      if (html) {
        const printableContent: any = window.open(
          "",
          "",
          "width=1000,height=650,scrollbars=1,menuBar=1"
        );
        printableContent.document.write("<html>");
        printableContent.document.write(html);
        printableContent.document.write("</html>");
        printableContent.document.close();
        printableContent.print();
      }
    } catch (error: any) {
      console.log("Error in printFaxCoverSheet: ", error?.message);
    }
  };

  return (
    <>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ display: "flex", alignItems: "baseline" }}
      >
        <Typography
          component="label"
          style={{ marginRight: "8px" }}
          className={classes.title}
        >
          Tax Exempt
        </Typography>
        <RACToggle
          name={"TaxExemptToggle"}
          disabled={agreementTransferInit}
          checked={federalTaxId ? true : toggle}
          toggleColor={RACCOLOR.NORMAL_BLUE}
          OnClick={() => {
            setToggle(!toggle);
          }}
        />
      </Grid>

      {federalTaxId || toggle ? (
        <Grid container xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.cardStyle}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <RACTextbox
                    required={true}
                    type={federalTaxId ? "password" : "text"}
                    inputlabel="Tax ID"
                    name={`federalTaxId`}
                    maxlength={30}
                    disabled={federalTaxId ? true : false}
                    errorMessage={
                      requiredField && !taxExemptInformation.federalTaxId
                        ? REQUIRED_ERROR
                        : ""
                    }
                    value={taxExemptInformation.federalTaxId}
                    OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                      saveOnChangeValue(event.target.name, event.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <RACSelect
                    inputLabel="Status"
                    name={"status"}
                    options={dropdownOption}
                    isDisabled={true}
                    defaultValue={valueExistsInDropdown(
                      dropdownOption,
                      taxExemptInformation.status
                    )}
                    loading={dropdownLoader}
                    errorMessage={
                      !dropdownLoader && dropdownOption.length === 1
                        ? API_ERROR_MESSAGE
                        : EMPTY_STRING
                    }
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <RACDatePicker
                    label="Effective Date"
                    name="effectiveDate"
                    value={taxExemptInformation.effectiveDate}
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <RACDatePicker
                    value={taxExemptInformation.expirationDate}
                    label="Expiration Date"
                    name="expirationDate"
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <RACDatePicker
                    value={taxExemptInformation.approvalOrDenialDate}
                    label="App/Denial Date"
                    name="approvalOrDenialDate"
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={6} sm={4} md={2} lg={2}>
                  <RACButton
                    variant="contained"
                    color="primary"
                    loading={faxSheetLoading}
                    disabled={!taxExemptInformation.federalTaxId ? true : false}
                    onClick={printFaxCoverSheet}
                    style={{ marginTop: "15px" }}
                  >
                    Print Fax Cover Sheet
                  </RACButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ) : null}

      <div id="printHtmlDiv" style={{ display: "none" }}>
        {<FaxSheetTemplate 
        faxSheetDetails={faxSheetDetails} 
        customerInformation={faxCustomerDetails}  
        setLoader={setFaxSheetLoading} 
        setFaxSheetDetails={setFaxSheetDetails} />}
      </div>
    </>
  );
}
