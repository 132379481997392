/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import {
    Grid,
    RACButton,
    RACModalCard,
    Typography,
    RACSelect,
    RACTextbox
} from "@rentacenter/racstrap";
import { storeException } from "../../../api/user";
import ScreenLoader from "./ScreenLoader";
import { UpdateCustomerContext } from "../../context/UpdateCustomerContext";

interface VerificationNotrequired {
    customerId: string,
    documentType: string,
    verificationNotRequiredCompletedFn: any;
    verificationNotRequiredCancelledFn: any;
    activetabType: string;
}


export function VerificationNotrequired(props: VerificationNotrequired) {

    const { verificationNotRequiredReason } = useContext(UpdateCustomerContext)

    const { customerId, documentType, verificationNotRequiredCompletedFn, verificationNotRequiredCancelledFn, activetabType } = props
    console.log("propsprops", props)
    const [screenLoader, setScreenLoader] = useState(false)
    const [verificationNotrequired, setVerificationNotRequired] = useState<boolean>(true)
    const [overRidePopup, setOverRidePopUp] = useState<boolean>(false)
    const [inputFieldValues, setInputFieldValues] = useState<any>({

        overRideReason: '0',
        enteredReason: ''
    })
    const NextBtnClick = async () => {
        try {
            setVerificationNotRequired(false)
            setOverRidePopUp(false)

            setScreenLoader(true)
            const payload: any = {
                storeException: [
                    {
                        identifierId: Number(customerId),
                        identifierType: 'CUSTOMER',
                        storeNumber: window.sessionStorage.getItem('storeNumber'),
                        exceptionTypeRefCode: "CVO",
                        exceptionText: inputFieldValues?.overRideReason == 'Other' ? `${documentType + '-' + inputFieldValues?.enteredReason}` : `${documentType + '-' + inputFieldValues?.overRideReason}`
                    }
                ]
            }
            setVerificationNotRequired(false)

            const storeExceptionRes = await storeException(payload)

            setScreenLoader(false)
            setOverRidePopUp(false)
            if (storeExceptionRes?.status == 200) {
                console.log("storeExceptionRes", storeExceptionRes)
                verificationNotRequiredCompletedFn(activetabType)
                setInputFieldValues({ ...inputFieldValues, enteredReason: '', overRideReason: '0' })

            }
            else {
                verificationNotRequiredCancelledFn()
            }
        }
        catch (e) {
            console.log('Inside catch in Not Required ', e)
            setOverRidePopUp(false)
            setScreenLoader(false)
            verificationNotRequiredCancelledFn()
        }



    }


    const verificationNotRequired = () => (
        <>
            <Grid item md={12}>
                <Grid item md={12} style={{ textAlign: "center" }}>
                    <Typography
                        style={{ fontWeight: 'bold', fontSize: '20px', fontFamily: 'OpenSans-bold' }}

                    >
                        Verification Not Required
                    </Typography>
                </Grid>
                <Grid>

                    <Typography style={{ fontSize: '17px', textAlign: 'center', marginTop: '25px', fontFamily: 'OpenSans-semibold', padding: '4px' }}>The Decision Engine concluded that this tab's verification isn't necessary. Asking for it could lead to lost sales, returns, and unneeded customer difficulty.
                    </Typography>
                    <Typography style={{ marginTop: '24px', textAlign: 'center', fontFamily: 'OpenSans-semibold', fontSize: '16px' }}>  Would you still like to continue?</Typography>


                    <Grid style={{ marginTop: '13px', textAlign: 'center' }}>
                        <RACButton
                            variant='contained'
                            color='primary'
                            style={{ marginRight: '20px' }}
                            onClick={() => { setOverRidePopUp(true), setVerificationNotRequired(false) }}
                        >
                            Yes
                        </RACButton>
                        <RACButton
                            variant='outlined'
                            color='secondary'
                            onClick={() => {
                                setVerificationNotRequired(false), verificationNotRequiredCancelledFn()
                            }}
                        >
                            No
                        </RACButton>
                    </Grid>
                </Grid>

            </Grid>
        </>
    );
    const verificationOverRide = () => (
        <>
            <Grid item md={12}>
                <Grid container md={12}>
                    <Typography>Please select an override reason below.</Typography>

                    <Grid item md={10} style={{ marginTop: '10px' }}>
                        <RACSelect
                            inputLabel="Reason"
                            required={true}
                            options={verificationNotRequiredReason}
                            onChange={(e) => {
                                setInputFieldValues({ ...inputFieldValues, overRideReason: e.target.value })
                            }}
                            defaultValue={inputFieldValues.overRideReason}
                        />
                        {
                            inputFieldValues.overRideReason == 'Other' ?
                                <Grid style={{ marginTop: '12px' }}>
                                    <Typography>Please enter the Reason<span style={{ color: 'red' }}>*</span></Typography>
                                    <textarea
                                        placeholder="Please enter the Reason"
                                        value={inputFieldValues.enteredReason}
                                        rows={8}
                                        cols={44}
                                        maxLength={200}
                                        onChange={(e) => { setInputFieldValues({ ...inputFieldValues, enteredReason: e.target.value.trimStart() }) }}
                                        style={{ resize: 'none', borderRadius: '5px', marginTop: '5px', padding: '5px' }}
                                    >

                                    </textarea>
                                </Grid>
                                : null
                        }

                    </Grid>
                </Grid>


                <Grid style={{ marginTop: '16px', textAlign: 'right' }}>

                    <RACButton
                        variant='outlined'
                        color='secondary'
                        style={{ marginRight: '12px' }}
                        onClick={() => {
                            setVerificationNotRequired(true), setOverRidePopUp(false),
                                setInputFieldValues({ inputFieldValues, enteredReason: '', overRideReason: '0' })
                        }}
                    >

                        Cancel
                    </RACButton>
                    <RACButton
                        variant='contained'
                        color='primary'
                        style={{ marginRight: '20px' }}
                        disabled={inputFieldValues.overRideReason != 'Other' && inputFieldValues.overRideReason != '0' ? false : !inputFieldValues.enteredReason}
                        onClick={() => { NextBtnClick() }}
                    >
                        Next
                    </RACButton>
                </Grid>


            </Grid>
        </>
    );



    return (
        <>

            {screenLoader ?
                <ScreenLoader /> : null
            }
            <RACModalCard
                borderRadius="20px"
                isOpen={verificationNotrequired}
                maxWidth="sm"
                closeIcon={true}
                onClose={() => {
                    setVerificationNotRequired(false), verificationNotRequiredCancelledFn()
                }}
                children={verificationNotRequired()}
            />
            <RACModalCard
                title="Override Reason"
                borderRadius="20px"
                isOpen={overRidePopup}
                maxWidth="xs"
                closeIcon={true}
                onClose={() => {
                    setVerificationNotRequired(true), setOverRidePopUp(false),
                        setInputFieldValues({ inputFieldValues, enteredReason: '', overRideReason: '0' })

                }}
                children={verificationOverRide()}
            />
        </>
    );
}
