/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import { VehicleInfo } from "../microComponents/VehicleInformation";
import { UpdateCustomerContext } from "./../../../context/UpdateCustomerContext";

export default function VehicleInfoTab():JSX.Element {
    const { Agrtransferinit, vehicleInformation, setcustomerInfoChange, setVehicleInformation, 
        dropDownLoaded, stateOptions } = useContext(UpdateCustomerContext);

    return (<>
        <VehicleInfo
            stateVariables={{
                Agrtransferinit: Agrtransferinit,
                vehicleInformation: vehicleInformation,
                dropDownLoaded: dropDownLoaded,
                stateInfo: stateOptions
            }}
            setterFuncton={{
                setcustomerInfoChange: setcustomerInfoChange,
                setVehicleInformation: setVehicleInformation
            }}
        />
    </>)
}