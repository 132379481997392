/* eslint-disable */
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import { ReactComponent as Alerticon } from "../../assets/images/No-records.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/success-icon.svg";
import { ReactComponent as ErrorIcon } from "../../assets/images/Error.svg";
import { useEffect, useState } from "react";
import { EMPTY_STRING } from "../../constants/constants";

interface DynamicAlertPopupProps {
  alertMessage: string;
  alertType: string;
  closeIcon: boolean;
  onCloseFn: () => any;
}

export function DynamicAlertPopup(props: DynamicAlertPopupProps) {
  const { alertMessage, alertType, closeIcon, onCloseFn } = props;
  const [message, setMessage] = useState<string>(alertMessage || EMPTY_STRING);

  const getAlertIcon = () => {
    try {
      switch (alertType) {
        case "ERROR":
          <ErrorIcon />;
          break;
        case "SUCCESS":
          <SuccessIcon />;
          break;
        case "NORECORD":
          <Alerticon />;
          break;
        default:
          <></>;
      }
    } catch (error: any) {
      console.log("Error in getAlertIcon: ", error?.message);
    }
  };

  useEffect(() => {}, [message, alertType]);

  const popupContent = () => (
    <Grid container>
      <Grid item md={12} style={{ textAlign: "center" }}>
        {getAlertIcon()}
        <Typography
          variant={"body2"}
          style={{
            fontFamily: "OpenSans-bold",
            fontSize: "16px",
            letterSpacing: 0,
            marginBottom: "25px",
          }}
        >
          {message}
        </Typography>

        <RACButton
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          onClick={() => {
            onCloseFn();
          }}
        >
          ok
        </RACButton>
      </Grid>
    </Grid>
  );

  // const popup2 = () => (
  //     <>
  //         <Grid container>
  //             <Grid style={{ marginLeft: "20px", marginRight: "20px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
  //                 Web Link did not occur. No e-pay email account found .
  //                 Have Customer create account online.
  //             </Grid>

  //             <RACButton style={{ marginTop: "30px", marginLeft: "180px" }}
  //                 variant="contained"
  //                 color="primary"
  //                 id="assignAlertBtn"
  //                 onClick={() => { }}
  //             >
  //                 ok
  //             </RACButton>

  //         </Grid>
  //     </>
  // )

  // const popup3 = () => (
  //     <>
  //         < Grid container>
  //             <Alerticon style={{ marginLeft: "180px", marginBottom: "10px" }}></Alerticon>
  //             {getAlertIcon()}
  //             <Grid style={{ fontFamily: 'OpenSans-bold', fontSize: "15px" }}>
  //                 Email address successfully linked with online account.
  //             </Grid>
  //             <RACButton style={{ marginTop: "20px", marginLeft: "180px" }}
  //                 variant="contained"
  //                 color="primary"
  //                 id="assignAlertBtn"

  //                 onClick={() => {
  //                     // setWebLinked(false)
  //                 }}
  //             >
  //                 ok
  //             </RACButton>

  //         </Grid>
  //     </>
  // );

  // const popup4 = () => (
  //     <>
  //         <Grid container>
  //             <Grid style={{ marginLeft: "20px", marginRight: "20px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
  //                 Web Link did not occur. Please contact RACPad support or solution center
  //             </Grid>

  //             <RACButton style={{ marginTop: "30px", marginLeft: "180px" }}
  //                 variant="contained"
  //                 color="primary"
  //                 id="assignAlertBtn"

  //                 onClick={() => {
  //                     closePopupFn();
  //                 }}
  //             >
  //                 ok
  //             </RACButton>

  //         </Grid>
  //     </>
  // );

  return (
    <>
      <RACModalCard
        borderRadius="20px"
        isOpen={true}
        maxWidth="xs"
        closeIcon={closeIcon}
        onClose={() => {
          onCloseFn();
        }}
        children={popupContent()}
      />
    </>
  );
}
