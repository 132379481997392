export enum AppRoute {
  Index = '/customer',
  CreateCustomer = '/customer/createcustomer',
  UpdateCustomerById = '/customer/update/:customerId/:pageType',
  GetCustomerById = '/customer/createcustomer/:customerId/:pageType',
  UpdateCustomer = '/customer/updateCustomer',
  SearchResult = '/customer/searchcustomer',
  accountManagementCustomer = '/am/customer',

  //Racstrap
  SearchResultRacstrap = '/customer/racstrap/searchcustomer',
  UpdateCustomerRacstrap = '/customer/racstrap/updateCustomer',
  CreateCustomerRacstrap = '/customer/racstrap/createcustomer',
  UpdateCustomerByIdRacstrap = '/customer/racstrap/update/:customerId/:pageType',
  GetCustomerByIdRacstrap = '/customer/racstrap/createcustomer/:customerId/:pageType',
}
