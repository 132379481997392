/* eslint-disable */
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import { useState } from "react";
import { EMPTY_STRING } from "../../constants/constants";

interface DynamicConfirmation {
  alertMessage: string;
  closeIcon: boolean;
  closePopupFn: () => any;
  confirmFn: () => any;
}

export function DynamicConfirmPopup(props: DynamicConfirmation) {
  const { alertMessage, confirmFn, closeIcon, closePopupFn } = props;

  const popupContent = () => (
    <>
      <Grid item md={12}>
        <Grid item md={12} style={{ marginTop: "15px ", textAlign: "center" }}>
          <Typography
            variant={"body2"}
            style={{
              fontFamily: "OpenSans-bold",
              fontSize: "16px",
              letterSpacing: 0,
              marginTop: "-28px",
              marginBottom: "16px",
            }}
          >
            {alertMessage}
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "24px", width: "100%" }}>
          <RACButton
            style={{
              marginLeft: "28%",
              paddingRight: "8%",
              paddingLeft: "8%",
            }}
            variant="contained"
            color="inherit"
            onClick={() => {
              closePopupFn();
            }}
          >
            No
          </RACButton>
          <RACButton
            style={{
              marginLeft: "15px",
              paddingRight: "8%",
              paddingLeft: "8%",
            }}
            variant="contained"
            color="primary"
            onClick={() => {
              confirmFn();
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <RACModalCard
        borderRadius="20px"
        isOpen={true}
        maxWidth="xs"
        closeIcon={closeIcon}
        onClose={() => {
          closePopupFn();
        }}
        children={popupContent()}
      />
    </>
  );
}
