/* eslint-disable prettier/prettier */
import React, { useContext } from "react";
import { Grid, Typography } from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { trimContent } from "../AddressInformation/AddressInfoUtils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";


export default function CustomerBasicInfo() {
  const classes = globalStyles();
  const { customerBasicInformation } = useContext(UpdateCustomerContext);
  // eslint-disable-next-line no-console
  console.log(customerBasicInformation, 'customerBasicInformation');
  return (
    <Grid container>
      <Grid container className={`${classes.width100p} ${classes.mt10px}`}>
        <Grid container md={12}>
          <Typography
            className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
          >
            Name and Address Information
          </Typography>
        </Grid>
        <Grid container className={`${classes.mt10px} ${classes.width100p}`}>
          <Grid item className={`${classes.displayFlex} ${classes.width15p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
            >
              First Name
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.requiredColor} ${classes.ml5px}`}
            >
              *
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
            >
              {customerBasicInformation.firstName ? customerBasicInformation.firstName : '-'}
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.width15p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
            >
              Last Name
            </Typography>
            <Typography
              component={"span"}
              className={`${classes.requiredColor} ${classes.ml5px}`}
            >
              *
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
            >
              {customerBasicInformation.lastName ? customerBasicInformation.lastName : '-'}
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.width20p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
            >
              Address line 1
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
              title={customerBasicInformation.addressLine1}
            >
              {customerBasicInformation.addressLine1 ? trimContent(customerBasicInformation.addressLine1, 22) : '-' }
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.width20p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
              title={customerBasicInformation.addressLine2}
            >
              Address line 2
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
            >
              {customerBasicInformation.addressLine2 ? trimContent(customerBasicInformation.addressLine2, 22) : '-'}
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.width10p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
            >
              City
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
            >
              {customerBasicInformation.city ? customerBasicInformation.city : '-'}
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.width10p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
            >
              State
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
            >
              {customerBasicInformation.state ? customerBasicInformation.state: '-'}
            </Typography>
          </Grid>
          <Grid item className={`${classes.displayFlex} ${classes.width10p}`}>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold}`}
            >
              Zip
            </Typography>
            <Typography
              className={`${classes.fontSize14px} ${classes.fontOpensansSemiBold} ${classes.greyTextColor} ${classes.ml10px}`}
            >
              {customerBasicInformation.zip ? customerBasicInformation.zip : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
