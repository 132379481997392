/* eslint-disable */

import { Grid, RACButton, RACModalCard, RACRadio, RACTable, RACTableCell, RACTableRow, Typography } from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { headerPrimAdd } from "../../../constants/constants";
import React, { useContext, useEffect } from "react";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { AddressDetails } from "../../../interface/globalInterface";
import { DeliveryContext } from "../../../../context/DeliveryContext";
import { useHistory, useParams } from "react-router-dom";
import { updateAddressInformation } from "../../../apiHandler/updateCustomerApiHandler";
import { ReactComponent as Alerticon } from '../../../assets/images/No-records.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/images/success-icon.svg';


export function MultiplePrimaryFuntion() {

    const classes = globalStyles();
    const history: any = useHistory();
    const { customerId } = useParams<{ customerId: string }>();

    const { primaryAddPopUp, setPrimaryAddPopUp, primaryAddress, primaryAddressIndex,
        setPrimaryAddressIndex, multipleAgreementPrimaryAddress, setMultipleAgreementPrimaryAddress,
        radioIndex, setRadioIndex, setScreenLoader, primaryAddressUpdated, setPrimaryAddressUpdated,
        updatePopUp, setShowUpdatePopUp, cantAccess, isSuccess } = useContext(UpdateCustomerContext);
    const { customerSearchValue } = useContext(DeliveryContext);
    const [searchValCustomerEditor, setSearchValCustomerEditor] = customerSearchValue;

    useEffect(() => {
        if (primaryAddress.length > 1) {
            setPrimaryAddPopUp(true)
        }
    }, [primaryAddress])

    const onClickPrimaryAddressSave = async (type: string | undefined) => {
        const primarySavePayload: any[] = [];
        const selectedPrimaryAddress = primaryAddress[Number(primaryAddressIndex)];
        // const primDeliveAddressId: string[] = [];

        // Helper function to determine address status
        const determineAddressStatus = (address: AddressDetails, isSelected: boolean) => {
            let active = "Y";
            let addressType = "PRIM";

            if (isSelected) {
                return { active, addressType };
            }

            if (address.agreementIds && address.agreementIds.length > 0) {
                if (type === "No") {
                    addressType = "DELIV";
                } else if (type === "Yes") {
                    active = "N"; // Mark as inactive if type is Yes
                }
            } else {
                active = "N"; // No agreement IDs means inactive
            }

            return { active, addressType };
        };

        // Process addresses
        for (let j = 0; j < primaryAddress?.length; j++) {
            const currentAddress = primaryAddress[j];
            const isSelectedPrimary = String(currentAddress.addressId) === String(primaryAddress[Number(primaryAddressIndex)].addressId);

            // Determine the status of the current address
            const { active, addressType } = determineAddressStatus(currentAddress, isSelectedPrimary);

            // Handle agreement IDs if the current address is not the selected primary address
            if (!isSelectedPrimary && type === "Yes" && active === "N") {
                primaryAddress[Number(primaryAddressIndex)].agreementIds = [
                    ...primaryAddress[Number(primaryAddressIndex)].agreementIds,
                    ...currentAddress.agreementIds
                ];
                currentAddress.agreementIds = []; // Clear the current address's agreement IDs
            }

            // Push the current address to the payload if it's not the selected primary address
            if (!isSelectedPrimary) {
                primarySavePayload.push({
                    addressType,
                    active,
                    agreementIds: currentAddress.agreementIds,
                    addressId: currentAddress.addressId
                });
            }
        }

        // Finally, add the selected primary address to the payload
        primarySavePayload.push({
            addressType: "PRIM",
            active: "Y",
            agreementIds: selectedPrimaryAddress.agreementIds,
            addressId: selectedPrimaryAddress.addressId
        });

        // Prepare the update request
        const UpdateCustomerRequest = {
            customerId,
            addresses: primarySavePayload
        };

        console.log(UpdateCustomerRequest, 'UpdateCustomerRequest');

        // Show loader and make the update request
        setScreenLoader(true);
        setPrimaryAddPopUp(false);
        setMultipleAgreementPrimaryAddress(false);

        const result = await updateAddressInformation(UpdateCustomerRequest);
        setScreenLoader(false);

        // Handle the result of the update request
        if (result?.updateAddress?.value?.customerId !== undefined) {
            setPrimaryAddPopUp(false);
            setPrimaryAddressUpdated(true);
        } else {
            setShowUpdatePopUp(true);
            setPrimaryAddPopUp(false);
        }
    };

    const redirectionToCustomersearch = () => {
        const request: any = searchValCustomerEditor;
        request.cancelClick = false;
        setSearchValCustomerEditor(request);
        history.push({
            pathname: `/customer/searchcustomer`,
        });
    };

    const hideLengthyText = (Text: any, MaxLength: number, Mask: any) => {
        return Text == "" || Text == null ? "-" : Text.length > MaxLength ? Text.substring(0, MaxLength) + Mask : Text;
    };

    const handleChangeForPrimAddress = (index: any) => {
        setPrimaryAddressIndex(String(index));
        setRadioIndex(radioIndex === index ? null : index);
    }

    const onClickSavePrimaryAddress = async () => {
        let agreementOtherPrimaryCount = 0;
        for (let i = 0; i < primaryAddress?.length; i++) {
            if (primaryAddress[i].agreementIds?.length > 0 && String(primaryAddress[i].addressId) != String(primaryAddress[Number(primaryAddressIndex)].addressId)) {
                agreementOtherPrimaryCount++;
            }
        }
        setPrimaryAddPopUp(false)
        if (agreementOtherPrimaryCount > 0) {
            setMultipleAgreementPrimaryAddress(true)
        } else {
            onClickPrimaryAddressSave(undefined)
        }
    }

    const renderHeadPrimAddress = () => (
        <>
            {headerPrimAdd.map((val: any, index: any) => {
                if (val !== 'Radio') {
                    return (
                        <RACTableCell
                            style={{ borderBottomStyle: 'groove' }}
                            key={index}
                        >
                            {val}
                        </RACTableCell>
                    )
                }
                else {
                    return (
                        <RACTableCell
                            style={{ borderBottomStyle: 'groove' }}
                            key={index}
                        >
                        </RACTableCell>
                    )
                }

            })}
        </>
    )

    const renderBodyPrimAddress = (primAddDetails: AddressDetails[]) => {
        return (
            <>
                {primAddDetails.length > 0 ? (
                    primAddDetails.map((obj: AddressDetails, index: number) => (
                        <React.Fragment key={index}>
                            <RACTableRow style={{ backgroundColor: 'white', paddingLeft: '5%', paddingRight: '5%' }}>
                                <RACTableCell>
                                    <RACRadio
                                        value=''
                                        checked={radioIndex === index}
                                        onClick={() => { handleChangeForPrimAddress(index) }}
                                    />
                                </RACTableCell>
                                <RACTableCell style={{ paddingRight: '1%' }}>
                                    {obj.addressLine1}
                                </RACTableCell>
                                <RACTableCell>
                                    {obj.addressLine2 === "" ? "-" : obj.addressLine2}
                                </RACTableCell>
                                <RACTableCell>
                                    {obj.postalCode}
                                </RACTableCell>
                                <RACTableCell>
                                    {obj.city}
                                </RACTableCell>
                                <RACTableCell>
                                    {obj.state}
                                </RACTableCell>
                                <RACTableCell title={obj.storeNumber}>
                                    {hideLengthyText((obj.storeNumbers)?.join(', '), 15, "...")}
                                </RACTableCell>
                                <RACTableCell title={obj.agreementNumber}>
                                    {(String(obj.agreementIds?.length))}
                                </RACTableCell>
                            </RACTableRow>
                        </React.Fragment>
                    ))
                ) : (
                    <></>
                )}
            </>
        );
    };

    const renderPrimaryAddress = () => {
        return (
            <>
                <Grid style={{ marginLeft: '32%', fontFamily: 'OpenSans-bold', fontSize: 'medium' }}>Customer is having multiple primary address.</Grid>
                <Grid style={{ marginLeft: '31%', fontFamily: 'OpenSans-bold', fontSize: 'medium' }}>Please select any one of the address as primary</Grid>
                <Grid>
                    <RACTable
                        renderTableHead={renderHeadPrimAddress}
                        renderTableContent={() => renderBodyPrimAddress(primaryAddress)}
                    />
                </Grid>
                <Grid style={{ marginTop: '3%' }}>
                    <RACButton
                        variant="outlined"
                        color="inherit"
                        style={{ marginLeft: '75%', padding: '8px 26px' }}
                        onClick={() => redirectionToCustomersearch()}
                    >
                        Cancel
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.me1}
                        style={{ float: 'right', marginRight: '2%', padding: '8px 20px' }}
                        disabled={!Number(primaryAddressIndex >= 0)}
                        onClick={() => onClickSavePrimaryAddress()}
                    >
                        Save
                    </RACButton>
                </Grid>
            </>
        )
    }

    const otherPrimaryAddressAGR = () => {
        return (
            <Grid container>
                <Grid style={{ marginLeft: "20px", marginRight: "20px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
                    Other Primary Address are associated with agreements. Do you want to change it to new primary address
                </Grid>

                <Grid
                    container
                    className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
                >
                    <RACButton
                        color="primary"
                        variant="outlined"
                        className={`${classes.me2} ${classes.foc}`}
                        onClick={() => { onClickPrimaryAddressSave("No") }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        onClick={() => { onClickPrimaryAddressSave("Yes") }}
                    >
                        Yes
                    </RACButton>
                </Grid>


            </Grid>
        )
    }

    const primaryAddresspopupok = () => {
        setPrimaryAddressUpdated(false)
        window.location.reload()
    }

    const primaryAddressupdatedSuccessfully = () => {
        return (
            <Grid container>
                <Grid style={{ marginLeft: "15px", marginRight: "15px", fontFamily: 'OpenSans-bold', fontSize: "16px" }}>
                    Primary Address is updated successfully
                </Grid>
                <RACButton style={{ marginTop: "20px", marginLeft: "180px" }}
                    variant="contained"
                    color="primary"
                    id="assignAlertBtn"
                    className={classes.mt1}
                    onClick={() => { primaryAddresspopupok() }}
                >
                    ok
                </RACButton>


            </Grid>
        )
    }

    const UpdateInfoMsgPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    {cantAccess ? <Alerticon /> : isSuccess ? <SuccessIcon></SuccessIcon> : <Alerticon></Alerticon>}
                    {cantAccess ?
                        <>
                            <Typography
                                variant={'body1'}
                                style={{ margin: '1px' }}
                                className={`${classes.my3} ${classes.RACPOPMsg}`}
                            >{'The selected customer is a RAC Coworker. '}</Typography>
                            <Typography
                                variant={'body2'}
                                style={{ marginTop: '1px' }}
                                className={`${classes.my3} ${classes.RACPOPMsg}`}
                            >{'Only DM ( and above ) can create agreements for RAC Coworkers.'}</Typography>
                        </> :
                        <Typography
                            variant={'body2'}
                            className={`${classes.my3} ${classes.RACPOPMsg}`}
                        >
                            {isSuccess
                                ? 'Customer information is updated successfully'
                                : 
                                'Something went wrong'}
                        </Typography>}
                </Grid>
                <Grid
                    item
                    className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
                >
                    <RACButton
                        variant="contained"
                        color="primary"
                        onClick={() => setShowUpdatePopUp(false)}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };

    return (<>
        <RACModalCard
            borderRadius="20px"
            isOpen={primaryAddPopUp}
            maxWidth="md"
            title=""
            closeIcon={true}
            onClose={() => redirectionToCustomersearch()}
            children={renderPrimaryAddress()}
        />

        <RACModalCard
            borderRadius="20px"
            isOpen={multipleAgreementPrimaryAddress}
            maxWidth="xs"
            title=""
            onClose={() => {
                setMultipleAgreementPrimaryAddress(false)
            }}
            closeIcon={true}
            children={(otherPrimaryAddressAGR())}
        />

        <RACModalCard
            borderRadius="20px"
            isOpen={primaryAddressUpdated}
            maxWidth="xs"
            title=""
            onClose={() => {
                setPrimaryAddressUpdated(false)
            }}
            closeIcon={true}
            children={primaryAddressupdatedSuccessfully()}
        />

        <RACModalCard
            borderRadius="20px"
            isOpen={updatePopUp}
            maxWidth="xs"
            closeIcon={true}
            onClose={() => setShowUpdatePopUp(false)}
            children={UpdateInfoMsgPopup()}
        />

    </>)
}




// if (
//     customerAddressActive?.GetCustomerAddress &&
//     customerAddressActive?.GetCustomerAddress?.value &&
//     customerAddressActive?.GetCustomerAddress?.value?.addresses?.length > 0
// ) {
//     const filteredDeliveryAddress = customerAddressActive?.GetCustomerAddress?.value?.addresses
//     setSecondaryFilterArr(filteredDeliveryAddress);
//     const addressID: any = []
//     for (let i = 0; i < customerAddressActive?.GetCustomerAddress?.value?.addresses?.length; i++) {
//         const filteredAddress1 = customerAddressActive?.GetCustomerAddress?.value?.addresses?.filter((val: any) =>
//             val.addressId == customerAddressActive?.GetCustomerAddress?.value?.addresses[i].addressId
//         )
//         console.log(filteredAddress1, "filteredAddress1");
//         if (filteredAddress1?.length > 0 && !addressID?.includes(customerAddressActive?.GetCustomerAddress?.value?.addresses[i].addressId)) {
//             addressID.push(customerAddressActive?.GetCustomerAddress?.value?.addresses[i].addressId)
//             console.log(addressID, "addressID");
//             let multipleAgreementNumber: any = ""
//             let multipleAggrementId: any = ""
//             const splitarr: any = []
//             const splitAgrIdArr: any = []
//             for (let j = 0; j < filteredAddress1?.length; j++) {
//                 multipleAgreementNumber = multipleAgreementNumber == "" ? filteredAddress1[j].agreementNumber : multipleAgreementNumber + ", " + filteredAddress1[j].agreementNumber
//                 multipleAggrementId = multipleAggrementId == "" ? filteredAddress1[j].agreementId : multipleAggrementId + ", " + filteredAddress1[j].agreementId
//                 splitarr.push(filteredAddress1[j].agreementNumber)
//                 splitAgrIdArr.push(filteredAddress1[j].agreementId)
//             }

//             console.log(splitarr, 'splitarr');
//             console.log(multipleAgreementNumber, "multipleAgreementNumber");
//             console.log(multipleAggrementId, 'multipleAggrementId');
//             allAddressDataActive.push({
//                 agreementNumbersCount: filteredAddress1?.length,
//                 agreementNumber: multipleAgreementNumber,
//                 agreementNumberArr: splitarr,
//                 agreementIdArr: splitAgrIdArr,
//                 agreementDescription: filteredAddress1[0].agreementDescription,
//                 agreementStatus: filteredAddress1[0].agreementStatus,
//                 agreementStatusDesc: filteredAddress1[0].agreementStatusDesc,
//                 agreementId: multipleAggrementId,
//                 addressTypeDesc: filteredAddress1[0].addressTypeDesc,
//                 storeNumber: filteredAddress1[0].storeNumber,
//                 addressId: filteredAddress1[0].addressId,
//                 addressType: filteredAddress1[0].addressType,
//                 addressLine1: filteredAddress1[0].addressLine1,
//                 addressLine2: filteredAddress1[0].addressLine2,
//                 city: filteredAddress1[0].city,
//                 state: filteredAddress1[0].state,
//                 stateName: filteredAddress1[0].stateName,
//                 postalCode: filteredAddress1[0].postalCode,
//                 latitude: null,
//                 longitude: null,
//                 createdBy: filteredAddress1[0].createdBy,
//                 createdDate: filteredAddress1[0].createdDate,
//                 lastModifiedBy: filteredAddress1[0].lastModifiedBy,
//                 lastModifiedDate: filteredAddress1[0].lastModifiedDate,
//                 entAddressId: filteredAddress1[0].entAddressId,
//                 active: filteredAddress1[0].active,
//                 createdSource: filteredAddress1[0].createdSource,
//                 lastModifiedSource: filteredAddress1[0].lastModifiedSource,
//                 verifiedDate: filteredAddress1[0].verifiedDate,
//                 plus4: filteredAddress1[0].plus4,
//             })
//             console.log(allAddressDataActive, 'deleiveryaddarray');
//         }
//     }
// }
