/* eslint-disable */
import { CardContent, Grid, RACButton, RACModalCard, RACTextbox, Typography } from "@rentacenter/racstrap";
import { UpdateCustomerContext } from "../../../../context/UpdateCustomerContext";
import { useContext } from "react";
import { globalStyles } from "../../../../jsStyles/globalStyles";
import { ReactComponent as Alerticon } from '../../../../assets/images/No-records.svg';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GetActivateClub } from "../../../../api/user";
import { AddCoCustomer } from "../../../../../racstrapComponent/manageCustomer/AddCoCustomer";

export function FooterPopUp() {

    const { errorPopup, setErrorPopup, setValidateResult, comingSoonPopup, validateResult,
        currentRole, setcomingSoonPopup, splitBlockOpen, setSplitBlockOpen, setactivateClubObj,
        activateClubObj, clubInfo, activateClub, setactivateClub, setrestatementPopUp,
        restatementPopUp, checkAction, createCoCustomerPopUp, setcreateCoCustomerPopUp,
        getCustomerInfoResponse, showcocustomerpopup, setshowcocustomerpopup,
        restatementCustomer, setrestatementCustomer, customerBasicInformation,
        skipStolenHardStatus, isPrimaryNotSet, setClubInfo
    } = useContext(UpdateCustomerContext);

    const search = useLocation().search;
    const salesLeadIdQuery: any = new URLSearchParams(search).get('salesLeadId') || '';
    const modelNumberQueryParams: any = new URLSearchParams(search).get('modelNumber') || '';
    const salesLeadId = getCustomerInfoResponse?.GetCustomer?.value?.salesLeadId;

    const { customerId } = useParams<any>();
    const history = useHistory();

    const classes = globalStyles();

    const hideErrorPopUp = () => {
        setErrorPopup(false);
        setValidateResult([]);
    };

    const ErrorMsgPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography variant={'h6'} className={classes.RACPOPMsg}>
                        {' '}
                        {'Please fill all the mandatory fields in the below listed sections'}
                    </Typography>
                    {validateResult.map((element: any, index: any) => {
                        return (
                            <Typography
                                className={classes.RACPOPMsg}
                                key={index}
                            >
                                {element}
                            </Typography>
                        );
                    })}
                    {isPrimaryNotSet ? (
                        <Typography className={classes.RACPOPMsg}>
                            {isPrimaryNotSet}
                        </Typography>
                    ) : null}
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={classes.foc}
                        onClick={() => hideErrorPopUp()}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };

    const comingsoonpopUp = () => {
        const roleGroup1 = ['SM', 'CAR', 'AM', 'LAM', 'DMT'];
        const roleGroup2 = ['DM', 'RD'];

        const coWorkerRoleForCSpopup = roleGroup1.includes(currentRole) ? 1 :
            roleGroup2.includes(currentRole) ? 2 : 0;

        return (
            <>
                <Grid className={`${classes.infoTextStyle}`}>
                    <Typography>Coming Soon!</Typography>
                </Grid>
                <Grid
                    container
                    item
                    xl={12}
                    className={`${classes.px2} ${classes.pt2}`}
                >
                    <CardContent className={`${classes.infoTextStyle} ${classes.mb5}`}>
                        <Typography>
                            {coWorkerRoleForCSpopup == 1
                                ? 'Selected customer is a RAC employee. Only a DM (and above) can create an employee discounted Agreement'
                                : coWorkerRoleForCSpopup == 2
                                    ? 'Selected customer is a RAC employee, please use SIMS for creating discounted agreement'
                                    : ''}
                        </Typography>
                    </CardContent>
                </Grid>
            </>
        );
    };

    const splitBlockPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={classes.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography className={classes.RACPOPMsg}>
                        {
                            'Please activate the pending agreements before performing the split'
                        }
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${classes.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={classes.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setSplitBlockOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };

    const activateClubObjPopup = () => {
        return (
            <Grid container>
                <Grid item className={classes.racCol12}>
                    <Grid container spacing={2} className={classes.mb3}>
                        <Grid item className={classes.racCol6}>
                            <label
                                htmlFor="a11y_Member"
                                className={classes.formLabel}
                            >
                                Member Id
                            </label>
                        </Grid>
                        <Grid className={classes.racCol6}>
                            <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                disabled={true}
                                value={clubInfo?.membershipNumber || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.mb3}>
                        <Grid item className={classes.racCol6}>
                            <label
                                htmlFor="a11y_Benefits"
                                className={classes.formLabel}
                            >
                                Level Of Benefits
                            </label>
                        </Grid>
                        <Grid className={classes.racCol6}>
                            <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                disabled={true}
                                value={clubInfo?.clubName || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.mb3}>
                        <Grid item className={classes.racCol6}>
                            <label
                                htmlFor="a11y_Start"
                                className={classes.formLabel}
                            >
                                Start date
                            </label>
                        </Grid>
                        <Grid className={classes.racCol6}>
                            <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                disabled={true}
                                value={clubInfo?.startDate || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.mb3}>
                        <Grid item className={classes.racCol6}>
                            <label
                                htmlFor="a11y_Date"
                                className={classes.formLabel}
                            >
                                Next Fee Date
                            </label>
                        </Grid>
                        <Grid className={classes.racCol6}>
                            <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                disabled={true}
                                value={clubInfo?.nextDueDate || ''}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} className={classes.mb3}>
                        <Grid item className={classes.racCol6}>
                            <label
                                htmlFor="a11y_Frequency"
                                className={classes.formLabel}
                            >
                                Payment Frequency
                            </label>
                        </Grid>
                        <Grid className={classes.racCol6}>
                            <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                disabled={true}
                                value={clubInfo?.paymentSchedule || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.mb3}>
                        <Grid item className={classes.racCol6}>
                            <label
                                htmlFor="a11y_Fee"
                                className={classes.formLabel}
                            >
                                Fee
                            </label>
                        </Grid>
                        <Grid className={classes.racCol6}>
                            <RACTextbox
                                isCurrency={false}
                                type={'text'}
                                disabled={true}
                                value={clubInfo?.clubAmount ? '$ ' + clubInfo?.clubAmount : ''}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    className={`${classes.w100} ${classes.textRight} ${classes.floatLeft} ${classes.mb3}`}
                >
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.me2} ${classes.foc}`}
                        onClick={() => {
                            setactivateClubObj(false);
                            setactivateClub(true);
                        }}
                    >
                        Deactivate
                    </RACButton>

                    <RACButton
                        variant="contained"
                        color="primary"
                        onClick={() => setactivateClubObj(false)}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    };

    const activateCLub = async () => {
        try {
            const payload: any = {
                isCreate: 0,
                customerId: Number(customerId),
                paymentSchedule: null,
                agreementId: null,
                clubStatusRefCode: 'C',
            };
            setactivateClub(!activateClub);
            const response: any = await GetActivateClub(payload);
            if (response.status == 200 && response.data.customerClubId) {
                setClubInfo({
                    ...clubInfo,
                    clubStatus: 'D',
                });
            }
        } catch (e: any) {
            setactivateClub(!activateClub);
            console.log('activateCLub', e);
        }
    };

    const activateClubPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography className={classes.RACPOPMsg}>
                        {'Club will be immediately deactivated if you click "Yes". '}
                        {'Club cannot be "Re-activated" without a payment being made.'}
                    </Typography>
                    <Typography className={classes.RACPOPMsg}>
                        {'Are you sure you want to continue?'}
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
                >
                    <RACButton
                        color="primary"
                        variant="outlined"
                        className={`${classes.me2} ${classes.foc}`}
                        onClick={() => setactivateClub(!activateClub)}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="contained"
                        color="primary"
                        onClick={() => activateCLub()}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };

    const instatementPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography className={classes.RACPOPMsg}>
                        Is it a Transfer of Reinstatement Rights?
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={classes.me2}
                        onClick={() => {
                            setrestatementPopUp(false);
                            history.push(
                                `/agreement/reinstate/itemsearch/${customerId}/${checkAction || ''}`
                            );
                        }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.me2} ${classes.foc}`}
                        onClick={() => {
                            setrestatementPopUp(false);
                            setrestatementCustomer(true);
                            setshowcocustomerpopup(true);
                            //   setagrId(checkAction);
                        }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };

    const liketoRunAgreementPopup = () => {
        return (
            <Grid item md={12}>
                <Grid item md={12} className={classes.textCenter}>
                    <Typography className={classes.RACPOPMsg}>
                        Would you like to add the Co-Customer for agreement?
                    </Typography>
                </Grid>
                <Grid
                    container
                    className={`${classes.justifyContentCenter} ${classes.spacerMT4}`}
                >
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.me2} ${classes.foc}`}
                        onClick={() => {
                            setcreateCoCustomerPopUp(false);
                            setrestatementCustomer(false);
                            history.push(
                                (salesLeadIdQuery) || (salesLeadId)
                                    ? `/agreement/rental/itemsearch/${customerId}?salesLeadId=${salesLeadIdQuery || salesLeadId}&&coCustomerId=N`
                                    : `/agreement/rental/itemsearch/${customerId}?coCustomerId=N`
                            );
                        }}
                    >
                        No
                    </RACButton>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setrestatementCustomer(false);
                            setcreateCoCustomerPopUp(false);
                            setshowcocustomerpopup(true);
                        }}
                    >
                        Yes
                    </RACButton>
                </Grid>
            </Grid>
        );
    };

    const addCoCustomerChildrenPopup = () => {
        let customerInformation = { ...customerBasicInformation };
        customerInformation.skip = skipStolenHardStatus.skip ? 'Y' : 'N';
        customerInformation.hard = skipStolenHardStatus.hard ? 'Y' : 'N';
        customerInformation.stolen = skipStolenHardStatus.stolen ? 'Y' : 'N';

        return (
            <AddCoCustomer
                customerId={customerId}
                handleClosePopUp={(action: boolean) => {
                    if (restatementCustomer !== true) {
                        setshowcocustomerpopup(action);
                        setcreateCoCustomerPopUp(true);
                    } else {
                        setrestatementPopUp(true);
                        setshowcocustomerpopup(action);
                    }
                }}
                showCustomerInfo={(customerId: string) => {
                    history.push(`/customer/update/${customerId}/customer`);
                }}
                history={history}
                salesLeadIdQuery={salesLeadIdQuery}
                modelNumberQueryParams={modelNumberQueryParams}
                styles={globalStyles}
                exceptionSt={customerInformation}
                reinstatementConsition={restatementCustomer}
                agreementId={checkAction || ''}
            ></AddCoCustomer>
        );
    };

    return (
        <>
            <RACModalCard
                borderRadius="20px"
                isOpen={comingSoonPopup}
                maxWidth="xs"
                closeIcon={true}
                onClose={() => setcomingSoonPopup(false)}
                children={comingsoonpopUp()}
                title=""
            />

            <RACModalCard
                borderRadius="20px"
                isOpen={errorPopup}
                maxWidth="xs"
                closeIcon={true}
                onClose={() => hideErrorPopUp()}
                children={ErrorMsgPopup()}
            />

            <RACModalCard
                isOpen={splitBlockOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={'20px !important'}
            >
                {splitBlockPopup()}
            </RACModalCard>

            <RACModalCard
                borderRadius="20px"
                isOpen={activateClubObj}
                maxWidth="xs"
                title=""
                onClose={() => setactivateClubObj(false)}
                closeIcon={true}
                children={activateClubObjPopup()}
            />

            <RACModalCard
                borderRadius="20px"
                isOpen={activateClub}
                maxWidth="sm"
                closeIcon={true}
                onClose={() => setactivateClub(!activateClub)}
                children={activateClubPopup()}
                title="Activate Club"
            />

            <RACModalCard
                borderRadius="20px"
                isOpen={restatementPopUp}
                maxWidth="xs"
                closeIcon={true}
                onClose={() => setrestatementPopUp(false)}
                children={instatementPopup()}
                title=""
            />

            <RACModalCard
                borderRadius="20px"
                isOpen={createCoCustomerPopUp}
                maxWidth="xs"
                closeIcon={true}
                onClose={() => setcreateCoCustomerPopUp(false)}
                children={liketoRunAgreementPopup()}
                title=""
            />

            <RACModalCard
                borderRadius="20px"
                isOpen={showcocustomerpopup}
                maxWidth="lg"
                closeIcon={true}
                onClose={() => {
                    if (restatementCustomer !== true) {
                        setshowcocustomerpopup(false);
                        setcreateCoCustomerPopUp(true);
                    } else {
                        setrestatementPopUp(true);
                        setshowcocustomerpopup(false);
                    }
                }}
                children={addCoCustomerChildrenPopup()}
                title={
                    restatementCustomer === true
                        ? 'Customer Information'
                        : 'Co-Customer Information'
                }
            />
        </>
    )
} 