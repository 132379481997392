/* eslint-disable prettier/prettier */
import React, { useState, createContext } from 'react';

export const DeliveryContext = createContext<any>('');

export function DeliveryContextData(props: any) {


  const [searchValCustomerEditor, setSearchValCustomerEditor] = useState<any>({
    firstName: '',
    lastName: '',
    dob: '',
    taxId: '',
    phones: [
      {
        phoneNumber: ''
      }
    ],
    cancelClick: true,
  });


  return (
    <div>
      <DeliveryContext.Provider
        value={{
          customerSearchValue: [searchValCustomerEditor, setSearchValCustomerEditor]
        }}
      >
        {props.children}
      </DeliveryContext.Provider>
    </div>
  );
}
