/* eslint-disable */
import { getAddressValidation } from "../../../apiHandler/updateCustomerApiHandler";
import validateAddressFn from "../../../../common/validateAddress";
import { AddressDetailsBinder, AddressResponse } from "../../../interface/globalInterface";

const loginStore = sessionStorage.getItem('storeNumber');

export async function addressValidation(props: any) {
  const { updatedAddress, enableGoogleAddressValidation, setScreenLoader, setAddressErrorMsg,
    setSuggestedAddress, setValidateIsOpen
  } = props
  if (validateAddressFnc(updatedAddress)) {
    try {
      const addressPayload = createAddressPayload(loginStore);
      updateAddressFields(addressPayload.Addresses[0], updatedAddress)

      const validateAddress = async (enableGoogleAddressValidation: string, addressPayload: any, updatedAddress: any) => {

        if (enableGoogleAddressValidation === '1') {
          await handleGoogleAddressValidation(addressPayload, updatedAddress, props);
        } else {
          await handleAddressDoctorValidation(addressPayload, props);
        }

      };

      validateAddress(enableGoogleAddressValidation, addressPayload, updatedAddress)
    }
    catch (e: any) {
      setScreenLoader(false);
      setAddressErrorMsg('Address validation currently Unavailable');
      setSuggestedAddress('Error');
      setValidateIsOpen(true);
    }
  }
}

const validateAddressFnc = (updatedAddress: any) => {
  let validAddress = true;

  if (!updatedAddress.addressLine1) {
    validAddress = false;
  } else if (!updatedAddress.zipCode) {
    validAddress = false;
  }
  else if (
    updatedAddress.zipCode &&
    updatedAddress.zipCode.length != 5 &&
    updatedAddress.zipCode.length != 10
  ) {
    validAddress = false;
  } else if (!updatedAddress.city) {
    validAddress = false;
  } else if (!updatedAddress.state || updatedAddress.state == '0') {
    validAddress = false;
  }
  return validAddress
};

const createAddressPayload = (loginStore: any) => {
  return {
    MessageID: 'CMS006-93589345',
    MessageType: 'CMS006',
    MessageDTS: '2014-05-23-14:30:15:254633',
    MessageDTSTZOffset: '-06:00',
    RegionID: '1',
    ClientID: '8',
    ClientLocationNumber: '00006',
    ClientSource: '1',
    ClientOriginator: '1',
    LocationNumber: loginStore,
    EncryptionMethod: '0',
    Addresses: [
      {
        AddressID: '11114',
        StreetAddress: '',
        City: '',
        StateProvince: '',
        PostalCode: '',
        Country: '',
      },
    ],
    StoreConfig: {
      storeNumbers: [loginStore],
      paramKeyNames: ['AddressDoctorEnabled'],
    },
  };
};

const updateAddressFields = (address: any, updatedAddress: any) => {
  address.City = updatedAddress?.city;
  address.StreetAddress = updatedAddress?.addressLine1;
  address.StateProvince = updatedAddress?.state;
  address.PostalCode = updatedAddress?.zipCode?.replaceAll('-', '');
  address.Country = 'USA';
};

const createGoogleAddressPayload = (addressPayload: any, updatedAddress: any) => {
  const payload = JSON.parse(JSON.stringify(addressPayload));
  payload.enableGoogleAddressValidation = true;

  const addressLines = [updatedAddress?.addressLine1, updatedAddress?.city, updatedAddress?.state, updatedAddress?.zipCode];

  if (updatedAddress?.addressLine2) {
    addressLines.unshift(updatedAddress?.addressLine2);
    addressPayload.Addresses[0].StreetAddress += ` ${updatedAddress?.addressLine2}`;
  }

  payload.validateAddress = {
    postalCode: updatedAddress?.zipCode,
    addressLines: addressLines,
  };

  return payload;
};

const isAddressValidationEnabled = (response: any) => {
  return response.storeConfig.storeProfileResponse?.configDetails[0]?.configDetails[0]?.paramValue === 'Y' ||
    response.storeConfig.storeProfileResponse?.configDetails[0]?.configDetails[0]?.paramValue === '1' ||
    response.storeConfig.storeProfileResponse?.configDetails[0]?.configDetails[0]?.paramValue === 1;
};

const getErrorMessage = (matchCode: string, updatedAddress: any) => {
  if (matchCode === '1') {
    return `Address could not be corrected. Unlikely to match. Do you want to override?`;
  } else if (matchCode === '2') {
    return `Zip code found: ${updatedAddress.zipCode}. Do you want to override?`;
  }
  return '';
};

const handleGoogleAddressValidation = async (addressPayload: any, updatedAddress: any, props: any) => {
  props?.setScreenLoader(true);
  const googleAddressValidationPayload = createGoogleAddressPayload(addressPayload, updatedAddress);
  const [addressValidationResponse, googleAddressValidationResponse] = await Promise.all([
    getAddressValidation(addressPayload),
    getAddressValidation(googleAddressValidationPayload),
  ]);
  props?.setScreenLoader(false);

  if (addressValidationResponse && googleAddressValidationResponse.validateAddress) {
    const storeConfig = addressValidationResponse.storeConfig?.storeProfileResponse?.configDetails[0]?.
      configDetails[0]?.paramValue === '1';

    const result: AddressResponse = validateAddressFn(
      googleAddressValidationResponse.validateAddress,
      addressValidationResponse.validateAddress,
      storeConfig,
      googleAddressValidationPayload
    );

    if (result.status) {
      handleValidationSuccess(result, updatedAddress, props);
    } else {
      props?.setCantSuggestAddress(true);
      props?.setValidatedAddress(result);
    }
  } else {
    throw new Error('Something went wrong');
  }
};

const handleValidationSuccess = (result: AddressResponse, updatedAddress: AddressDetailsBinder, props: any) => {
  const { setmatchCode, setAddressErrorMsg, setSuggestedAddress, setValidateIsOpen, setValidatedAddress,
    setCanSuggestAddress, setDefaultAddress } = props
  setDefaultAddress('GA');

  result.enteredAddress = formatEnteredAddress(updatedAddress);

  if (result.matchCode) {
    setmatchCode(result.matchCode);
    const errorMessage = getErrorMessage(result.matchCode, updatedAddress);
    setAddressErrorMsg(errorMessage);
    setSuggestedAddress('Error');
    setValidateIsOpen(true);
  } else {
    setCanSuggestAddress(true);
    setValidatedAddress(result);
  }
};

const handleAddressDoctorValidation = async (addressPayload: any, props: any) => {
  const { setAddressDoc, setAddressErrorMsg, setSuggestedAddress, setValidateIsOpen, updatedAddress } = props
  setValidateIsOpen(true);
  setAddressDoc(true);
  const response: any = await getAddressValidation(addressPayload);
  setAddressDoc(false);

  if (response && response.storeConfig && isAddressValidationEnabled(response)) {
    handleAddressValidationResponse(response, props, updatedAddress);
  }
  else {
    setSuggestedAddress('Error');
    setAddressErrorMsg('Automatic Address validation is Unavailable for the store # Please validate the address Manually.');
  }
};

const handleAddressValidationResponse = (response: any, props: any, updatedAddress: any) => {
  const { setmatchCode, setAddressErrorMsg, setSuggestedAddress, RadioBtnclick } = props
  const matchCode = response.validateAddress.Addresses.MatchCode;
  const addressResponse = response.validateAddress && response.validateAddress.Addresses;
  
  if (addressResponse && (matchCode == '1' || matchCode == '2')) {
    setmatchCode(matchCode);
    const errorMessage = getErrorMessage(matchCode, updatedAddress);
    setAddressErrorMsg(errorMessage);
    setSuggestedAddress('Error');
  } else if (addressResponse) {
    RadioBtnclick('SUG')
    setSuggestedAddress(response.validateAddress?.Addresses);
  }
};

const formatEnteredAddress = (updatedAddress: AddressDetailsBinder): string => {
  let enteredAddress = '';
  const { addressLine2, addressLine1, city, state, zipCode } = updatedAddress;
  if (addressLine2) {
    enteredAddress = [addressLine1, addressLine2, city, state, zipCode].join(', ')
  } else {
    enteredAddress = [addressLine1, city, state, zipCode].join(', ')
  }
  return enteredAddress;
}