/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */

import React from "react";
import {
  Grid,
  RACCheckBox,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { EmployersInfoProps } from "../../../interface/globalInterface";
import {
  formatMobileNumber,
  formatText,
  isDropDownValueExist,
  isNotNullVariable,
  updateEmploymentDetails,
  validateEmploymentInfo,
} from "../Utils";
import { HOW_LONG_DROPDOWN } from "../../../constants/constants";
import moment from "moment";

export default function BasicInfo({
  value,
  setterData,
  isVerifiedReq,
}: EmployersInfoProps) {
  const { index, employerDetails, setEmployerDetails, errorMessage } =
    setterData;
  const classes = globalStyles();
  const Agrtransferinit = false;
  const verfDisable = validateEmploymentInfo(value);
  console.log("verfDisable", verfDisable);
  return (
    <Grid container>
      <Grid item md={10}>
        <Typography
          className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
        >
          Basic Information
        </Typography>
      </Grid>
      <Grid item md={2} className={`${classes.alignRight}`}>
        <Grid container md={12}>
          <Grid container spacing={2}>
            {isVerifiedReq ? (
              <>
                <Grid item md={6}>
                  <Grid item className={classes.alignCheckBox}>
                    <RACCheckBox
                      label="Verified"
                      size="small"
                      className={classes.checkBoxColor}
                      onChange={(e: any) => {
                        updateEmploymentDetails(
                          index,
                          employerDetails,
                          setEmployerDetails,
                          {
                            verified: e.target.checked,
                            verifiedDate: e.target.checked
                              ? moment().format("YYYY-MM-DD")
                              : "",
                          }
                        );
                      }}
                      disabled={
                        Agrtransferinit ? true : verfDisable || !value.active
                      }
                      checked={isNotNullVariable(value.verifiedDate)}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid item className={classes.alignCheckBox}>
                    <RACCheckBox
                      label="Active"
                      disabled={Agrtransferinit}
                      size="small"
                      className={classes.checkBoxColor}
                      onChange={(e: any) => {
                        updateEmploymentDetails(
                          index,
                          employerDetails,
                          setEmployerDetails,
                          {
                            active: e.target.checked,
                          }
                        );
                      }}
                      checked={value.active}
                    />
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={4} lg={2}>
          <RACTextbox
            inputlabel="Company Name"
            required={value.active}
            disabled={
              Agrtransferinit || value.isEditEnabled ? true : !value.active
            }
            type={"text"}
            maxlength={30}
            value={value.basicInfo.companyName || ""}
            OnChange={(e: any) => {
              updateEmploymentDetails(
                index,
                employerDetails,
                setEmployerDetails,
                {
                  verifiedDate: "",
                  basicInfo: {
                    ...value.basicInfo,
                    isEdited: true,
                    companyName: formatText(e.target.value),
                  },
                }
              );
            }}
            errorMessage={errorMessage[index]?.basicInfo?.companyName}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <RACSelect
            inputLabel="How Long?"
            options={HOW_LONG_DROPDOWN}
            isDisabled={
              Agrtransferinit || value.isEditEnabled ? true : !value.active
            }
            loading={HOW_LONG_DROPDOWN.length == 0 ? true : false}
            defaultValue={
              isDropDownValueExist(
                HOW_LONG_DROPDOWN,
                value.basicInfo.employmentYears
              )
                ? value.basicInfo.employmentYears
                : "0"
            }
            onChange={(e: any) => {
              updateEmploymentDetails(
                index,
                employerDetails,
                setEmployerDetails,
                {
                  basicInfo: {
                    ...value.basicInfo,
                    employmentYears: e.target.value,
                    isEdited: true,
                  },
                }
              );
            }}
          />
        </Grid>
        <Grid item md={4} lg={2}>
          <RACTextbox
            required={value.active ? true : false}
            type={value.basicInfo.doNotCallEnabled ? "password" : "text"}
            inputlabel="Employer Phone Number"
            OnChange={(e: any) => {
              updateEmploymentDetails(
                index,
                employerDetails,
                setEmployerDetails,
                {
                  verifiedDate: "",
                  basicInfo: {
                    ...value.basicInfo,
                    phoneNumber: e.target.value,
                    isEdited: true,
                  },
                }
              );
            }}
            disabled={
              Agrtransferinit || value.isEditEnabled
                ? true
                : !value.active || value.basicInfo.doNotCallEnabled
                ? true
                : false
            }
            maxlength={14}
            value={formatMobileNumber(value.basicInfo.phoneNumber)}
            errorMessage={errorMessage[index]?.basicInfo?.phoneNumber}
          />
        </Grid>
        <Grid item md={3} lg={2}>
          <Grid
            item
            className={`${classes.alignCheckBox} ${classes.mt19pxRem} ${classes.pl0}`}
          >
            <RACCheckBox
              label="Do Not Call"
              size="small"
              disabled={Agrtransferinit || value.isEditEnabled}
              className={classes.checkBoxColor}
              onChange={(e: any) => {
                updateEmploymentDetails(
                  index,
                  employerDetails,
                  setEmployerDetails,
                  {
                    basicInfo: {
                      ...value.basicInfo,
                      doNotCallEnabled: e.target.checked,
                      isEdited: true,
                    },
                  }
                );
              }}
              checked={value.basicInfo.doNotCallEnabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}