/* eslint-disable */

import React, { useState } from 'react';
import { CardContent, Grid, RACModalCard, Card } from '@rentacenter/racstrap';
import { globalStyles } from '../../../jsStyles/globalStyles';
import { AddressDetails, AddressInfo, AddressInformationPropsObject, ContactInfoHeaderProps } from '../../../interface/globalInterface';
import ContactInfoHeader from '../ContactInfoHeader';
import AddressHistoryModal from './AddressHistoryModal';
import DynamicDetailsBinder from './DynamicDetailsBinder';

export default function AddressInformation(props: AddressInformationPropsObject) {
  const classes = globalStyles();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const addressDetails = props?.value.activeAddresses;
  const setActiveAddress = props?.setterData?.setActiveAddress;
  const editedAddress = props?.setterData?.editedAddress;
  const setEditedAddress = props?.setterData?.setEditedAddress;
  const primaryAddress = props?.setterData?.primaryAddress;

  const inActiveAddressDetails: AddressDetails[] = props.value.inActiveAddresses;

  const props1: ContactInfoHeaderProps = {
    heading: 'Address Information',
    popupHeading: 'Address History',
    popupFunctionality: setOpenPopup,
    params: true,
    infoIcon: false,
    infoIconContent: ''
  };

  function renderAddressHistory() {
    return (
      <AddressHistoryModal value={inActiveAddressDetails} />
    )
  }
  return (
    <Grid container>
      <Grid container>
        <ContactInfoHeader value={props1} />
        <Card className={`${classes.width100p} ${classes.card}`}>
          <CardContent className={`${classes.width100p}`}>
            <DynamicDetailsBinder value={{ addressDetails }}
              setterData={{ editedAddress, setEditedAddress, setActiveAddress, primaryAddress }}
              addressValidationPayload={props?.addressValidationPayload} />
          </CardContent>
        </Card>
      </Grid>

      <RACModalCard
        borderRadius="20px"
        isOpen={openPopup}
        maxWidth="lg"
        closeIcon={true}
        title='Address History'
        onClose={() => setOpenPopup(false)}
        className={`${classes.width100p}`}
        // eslint-disable-next-line react/no-children-prop
        children={renderAddressHistory()}
      />
    </Grid>
  );
}
