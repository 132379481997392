/* eslint-disable */
import { useContext } from "react";
import {
  AddressDetails,
  AddressInfo,
  ApiResponse,
  ContactInfoHeaderProps,
  DropdownOptions,
  EmployerDetails,
  EmploymentRequest,
  ResidenceInfo,
} from "../interface/globalInterface";

import {
  AgreementDetails,
  CustomerBasicInformation,
  ClubInformation,
  CommunicationInformation,
  Configurations,
  ContactInformations,
  FaxSheetInformation,
  HeaderInformation,
  LegalHoldInfomation,
  ReferenceInformation,
  ResidenceInformation,
  SkipHardStatus,
  TaxExemptInformation,
  VehicleInfomation,
} from "../interface/updateCustomerInterface";

import { buildVechicleYearDropdown } from "../utils/utils";
//Error messages :

const REQUIRED_ERROR: string = "Required";
const INVALID_EMAIL_FORMAT: string = "Please enter valid email address";
const API_ERROR_MESSAGE: string = "Unable to fetch the data";
const NATIONAL_DNC_INFO: string =
  "Phone numbers that are on the National Do Not Call Registry are not allowed to receive Marketing solicitations. Customers can contact the National Do Not Call Registry at 1-888-382-1222.";
const PHONE_ERROR_MESSAGE: string = "Please enter valid phone number";

//Regular Expressions:

const NAME_REPLACEABLE_REGEX: RegExp = /[^A-Z-'.,’ a-z]/gi;
const ONLY_ALPHABETS_REGEX: RegExp = /[^A-Za-z]/gi;
const MULTIPLE_SPACE_REMOVAL_REGEX: RegExp = /\s+/g;
const MAIL_REGEX: RegExp =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
const GOVT_ID_REGEX: RegExp = /[^A-Z-'., a-z0-9]/gi;
const AMOUNT_FORMAT_REGEX: RegExp = /\B(?=(\d{3})+(?!\d))/g;

//Available Decision Engine Statuses:

const DECISION_ENGINE_APPROVED: string = "Approved";
const DECISION_ENGINE_PREAPPROVED: string = "Conditionally Approved";
const DECISON_ENGINE_DECLINED: string = "Declined";
const DECISON_ENGINE_EXPIRED: string = "DE Expired";
const NO_DECISION_ENGINE: string = "No DE";

//Default Values:
const HIGHER_AUTHORITY_ROLES : string[]=['RD','DM'];
const LOGGED_IN_STORE: string =
  window.sessionStorage.getItem("storeNumber") ?? "";
const VERIFICATION_EXPIRY_DAYS: string = "60";
const TODAY_DATE_TIME: Date = new Date();
const STRING_ZERO_AMOUNT: string = "0.00";
const STRING_ZERO: string = "0";
const EMPTY_STRING: string = "";
const STRING_YES: string = "Y";
const STRING_NO: string = "N";
const SSN: string = "SSN";
const DEFAULT_DROPDOWN_OPTION: DropdownOptions[] = [
  { label: "Select", value: EMPTY_STRING },
];
const API_ERROR: ApiResponse = { status: 500, data: EMPTY_STRING };

//Templates starts here ---->

const HEADER_INFORMATION_TEMPLATE: HeaderInformation = {
  approvalAmount: EMPTY_STRING,
  weeklyApprovalAmount: EMPTY_STRING,
  approvalExpirationDate: EMPTY_STRING,
  approvalStatus: EMPTY_STRING,
};

const CONFIGURATION_INFO_TEMPLATE: Configurations = {
  verificationDays: VERIFICATION_EXPIRY_DAYS,
  agreementTransferApplicable: false,
  reinstatementAllowed: false,
  rewardsEligibilityAmount: EMPTY_STRING,
  customerRewards: EMPTY_STRING,
  customerVerificationOptimization: false,
  intellicheck: false,
  editRiskAssessmentRoles: []
};

const AGREEMENT_DETAILS: AgreementDetails = {
  transferInitiated: false,
  transferAppilicable: false,
  tranferAgreementInfo: EMPTY_STRING,
};

const BASIC_INFORMATION_TEMPLATE: CustomerBasicInformation = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  globalCustomerId: EMPTY_STRING,
  coCustomerId: EMPTY_STRING,
  dateOfBirth: EMPTY_STRING,
  ssn: EMPTY_STRING,
  emailAddress: EMPTY_STRING,
  governmentIdType: EMPTY_STRING,
  governmentId: EMPTY_STRING,
  governmentIdIssuedState: EMPTY_STRING,
  salutation: EMPTY_STRING,
  middleInitial: EMPTY_STRING,
  suffix: EMPTY_STRING,
  racCoworker: EMPTY_STRING,
  dateOfBirthEnc: EMPTY_STRING,
  taxIdEnc: EMPTY_STRING,
  governmentIdEnc: EMPTY_STRING,
  verified: STRING_NO,
  verifiedDate: EMPTY_STRING,
  oktaId: EMPTY_STRING,
  isEditEnabled: false,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  state: EMPTY_STRING,
  zip: EMPTY_STRING,
  city: EMPTY_STRING,
  dateOfBirthDec: EMPTY_STRING,
  govtIdDecr: EMPTY_STRING,
  taxIdDecr: EMPTY_STRING,
};

const CONTACT_INFORMATION_TEMPLATE: ContactInformations = {
  phoneId: EMPTY_STRING,
  phoneNumber: EMPTY_STRING,
  phoneType: EMPTY_STRING,
  phoneTypeDesc: EMPTY_STRING,
  extension: EMPTY_STRING,
  primary: STRING_NO,
  primaryFlag: STRING_NO,
  callTimeType: EMPTY_STRING,
  callTimeTypeDesc: EMPTY_STRING,
  active: STRING_NO,
  instructions: EMPTY_STRING,
  isNDNCOpted: STRING_NO,
  isTrnsMsgAllowed: STRING_NO,
  isMktgMsgAllowed: STRING_NO,
  cancelable: false,
  isEditEnabled: true,
  textMarketingOffersOri: false,
  textMessageReminderOri: false,
  phoneNumberOri: EMPTY_STRING
};

const INACTIVE_CONTACT_GRID_HEADER: string[] = [
  "Phone Numbers",
  "Ext",
  "Best Time To Call",
  "Phone Type",
  "Phone Instructions",
  "Is Active",
  "Is Primary",
];

const COMMUNICATION_PREFERENCE_TEMPLATE: CommunicationInformation = {
  paymentRemainderCall: EMPTY_STRING,
  contactMethod: EMPTY_STRING,
  textMessageRemainder: EMPTY_STRING,
  paymentReceipt: EMPTY_STRING,
  language: EMPTY_STRING,
  phoneSolicitation: EMPTY_STRING,
  mailSolicitation: EMPTY_STRING,
  emailSolicitation: EMPTY_STRING,
  route: EMPTY_STRING,
  isEditEnabled: false,
};

const LEGAL_HOLD_INFO_TEMPLATE: LegalHoldInfomation = {
  legalHoldStatus: false,
  refCode: EMPTY_STRING,
};

const SKIP_HARD_TEMPLATE: SkipHardStatus = {
  skip: false,
  stolen: false,
  hard: false,
};

const TAX_EXEMPT_TEMPLATE: TaxExemptInformation = {
  federalTaxId: EMPTY_STRING,
  status: EMPTY_STRING,
  effectiveDate: EMPTY_STRING,
  expirationDate: EMPTY_STRING,
  approvalOrDenialDate: EMPTY_STRING,
};

const FAX_SHEET_INFO_TEMPLATE: FaxSheetInformation = {
  storeName: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  city: EMPTY_STRING,
  stateName: EMPTY_STRING,
  storeNumber: EMPTY_STRING,
  fullZip: EMPTY_STRING,
  workPhoneNumber: EMPTY_STRING,
};

const RESIDENCE_INFO_TEMPLATE: ResidenceInfo = {
  rentalType: EMPTY_STRING,
  mortgage: false,
  ownResidenceDetails: {
    paymentAmount: EMPTY_STRING,
    mortgageCompanyName: EMPTY_STRING,
    moveInDate: EMPTY_STRING,
  },
  rentalDetails: {
    landlordsFirstName: EMPTY_STRING,
    landlordsLastName: EMPTY_STRING,
    moveInDate: EMPTY_STRING,
    landlordsPhoneNumber: EMPTY_STRING,
    paymentAmount: EMPTY_STRING,
  },
  verified: false,
  verifiedDate: EMPTY_STRING,
  overrideEnabled: false,
  decisionId: EMPTY_STRING,
  verifyCode: EMPTY_STRING,
  verifiedBy: EMPTY_STRING,
  verifiedSource: EMPTY_STRING,
  verifiedOn: EMPTY_STRING,
  residenceInfoError: {
    landlordsFirstNameError: false,
    landlordsLastNameError: false,
    landlordsPhoneNumberError: false,
    mortgageCompanyNameError: false,
  }
};

const REFERENCE_INFO_TEMPLATE: ReferenceInformation = {
  referenceInfo: {
    relativeFirstName: EMPTY_STRING,
    relativeLastName: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
    bestTimeToCall: EMPTY_STRING,
    relationShip: EMPTY_STRING,
    doNotCall: false,
    isEdited: false,
    relationshipTypeDesc: EMPTY_STRING,
  },
  referenceAddressInfo: {
    addressLine1: EMPTY_STRING,
    addressLine2: EMPTY_STRING,
    zip: EMPTY_STRING,
    city: EMPTY_STRING,
    state: EMPTY_STRING,
    isEdited: false,
  },
  verified: false,
  verifiedDate: EMPTY_STRING,
  active: false,
  referenceDetailsId: EMPTY_STRING,
  decisionEngineId: EMPTY_STRING,
  isEditEnabled: false,
  verifyCode: EMPTY_STRING,
  verifiedCV: false,
  verifiedBy: EMPTY_STRING,
  verifiedOn: EMPTY_STRING,
  verifiedSource: EMPTY_STRING,
  isCancellable: false
};

const VEHICLE_INFORMATION_TEMPLATE: VehicleInfomation = {
  vehicleId: EMPTY_STRING,
  make: EMPTY_STRING,
  model: EMPTY_STRING,
  year: EMPTY_STRING,
  color: EMPTY_STRING,
  vehicleVin: EMPTY_STRING,
  licensePlate: EMPTY_STRING,
  regState: EMPTY_STRING,
  planExpirationDate: EMPTY_STRING,
  financedFrom: EMPTY_STRING,
  monthlyPayment: EMPTY_STRING,
  vehicleIndex: EMPTY_STRING,
  vehicleTagNumber: EMPTY_STRING,
};

const VEHICLE_YEAR_DROPDOWN: DropdownOptions[] = buildVechicleYearDropdown();

const CLUB_INFO_TEMPLATE: ClubInformation = {
  membershipNumber: EMPTY_STRING,
  clubStatus: EMPTY_STRING,
  clubName: EMPTY_STRING,
  startDate: EMPTY_STRING,
  nextDueDate: EMPTY_STRING,
  paymentSchedule: EMPTY_STRING,
  clubAmount: EMPTY_STRING,
  clubActivatedStore: EMPTY_STRING,
  originStoreNumber: EMPTY_STRING
};

const ADDRESS_INFO_TEMPLATE: AddressDetails = {
  addressId: 0,
  addressIds: [],
  agreementId: EMPTY_STRING,
  agreementIds: [],
  agreementNumber: EMPTY_STRING,
  agreementNumbers: [],
  storeNumbers: [],
  agreementNumbersCount: EMPTY_STRING,
  storeNumber: EMPTY_STRING,
  addressType: EMPTY_STRING,
  addressTypeDesc: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  lastModifiedBy: EMPTY_STRING,
  lastModifiedDate: EMPTY_STRING,
  postalCode: EMPTY_STRING,
};

const AddressHistoryHeader: string[] = [
  "Type",
  "Agreement #",
  "Store #",
  "Address 1",
  "Address 2",
  "Zip",
  "City",
  "State",
  "Last Modified",
  "Last Modified By",
];

const dropdownOptions = [
  { label: "Select", value: "0" },
  { label: "Option 1", value: "1" },
  { label: "Option 2", value: "2" },
  { label: "Option 3", value: "3" },
  { label: "Option 4", value: "4" },
  { label: "Option 5", value: "5" },
];

const REQUIRED_FIELD_ERROR = "Required";

const DEHistoryHeader = [
  "DE Date",
  "Status",
  "Approval Amount",
  "Weekly Approval Amount",
  "Source",
  "DE Expiry Date",
];

const agreementHistoryHeader = [
  "Agreement #",
  "Description",
  "Type",
  "Store",
  "# Payments",
  "Total Cost",
  "Schedule",
  "Next Due Date",
  "Amount Due",
  "Sub Status",
];

const getAgreementByIdTemplate = {
  agreement: [],
  clubInfoResponse: [],
  customerId: EMPTY_STRING,
  customerFirstName: EMPTY_STRING,
  customerLastName: EMPTY_STRING,
  customerCOAAmount: EMPTY_STRING,
  coCustomerId: EMPTY_STRING,
  coCustomerFirstName: EMPTY_STRING,
  coCustomerLastName: EMPTY_STRING,
  coCustomerCOAAmount: EMPTY_STRING,
};

const dateFormat = "MM/DD/YYYY";

const EMPLOYER_INFO_TEMPLATE: EmployerDetails = {
  basicInfo: {
    companyName: EMPTY_STRING,
    employmentYears: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
    doNotCallEnabled: false,
    isEdited: false,
  },
  incomeVerification: {
    paydayFrequency: EMPTY_STRING,
    dayPaid: EMPTY_STRING,
    takeHomePay: EMPTY_STRING,
    isEdited: false,
    payDayFreqDesc: EMPTY_STRING,
    daysPaidDesc: EMPTY_STRING,
  },
  addressInfo: {
    addressLine1: EMPTY_STRING,
    addressLine2: EMPTY_STRING,
    zip: EMPTY_STRING,
    city: EMPTY_STRING,
    state: EMPTY_STRING,
    doNotVisit: false,
    isEdited: false,
  },
  verified: false,
  active: true,
  verifiedDate: EMPTY_STRING,
  employerDetailsId: null,
  overrideEnabled: false,
  isEditEnabled: false,
  verifiedBy: EMPTY_STRING,
  decisionEngineId: EMPTY_STRING,
  verifiedSource: EMPTY_STRING,
  verifyCode: EMPTY_STRING,
  isCancellable: false
};

const IDENTITY_DETAILS = {
  governmentId: EMPTY_STRING,
  governmentIdType: EMPTY_STRING,
  issuedState: EMPTY_STRING,
  verified: false,
  verifiedDate: EMPTY_STRING,
  overrideEnabled: false,
  decisionEngineId: EMPTY_STRING,
  verifiedSource: EMPTY_STRING,
  govtIdDecrpted: EMPTY_STRING,
  verifyCode: EMPTY_STRING,
  verifiedBy: EMPTY_STRING,
};

const headerPrimAdd = [
  "Radio",
  "Address Line 1",
  "Address Line 2",
  "Zip",
  "City",
  "State",
  "Store#",
  "Agreement Count",
];

const HOW_LONG_DROPDOWN: DropdownOptions[] = [
  {
    value: "0",
    label: "Select",
  },
  {
    value: "1",
    label: "0 to 1 year",
  },
  {
    value: "2",
    label: "1 to 5 years",
  },
  {
    value: "3",
    label: "5+ years",
  },
];

const DATE_FORMAT = "YYYY-MM-DD";

const EMPLOYEMNET_UPDATE_REQUEST: EmploymentRequest = {
  employerReferenceId: EMPTY_STRING,
  employerName: null,
  takeHomePay: null,
  supervisorFirstName: EMPTY_STRING,
  supervisorLastName: EMPTY_STRING,
  jobTitle: EMPTY_STRING,
  employerPhoneNumber: null,
  employerPhoneExtension: null,
  addressLine1: null,
  addressLine2: null,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  postalCode: EMPTY_STRING,
  doNotVisit: STRING_YES,
  doNotCall: STRING_YES,
  employmentBeginDate: "2001-10-18",
  workStartTime: null,
  workEndTime: null,
  employerPayschedule: null,
  employerPayscheduleDesc: null,
  lengthOfEmploymentYears: STRING_ZERO,
  daysPaid: null,
  daysPaidDesc: null,
  employmentEndDate: null,
  verifiedDate: EMPTY_STRING,
  active: EMPTY_STRING,
};

const GOVT_ID_TYPE_CVO = ['UDL', 'UPASS', 'CID', 'MID', 'OID'];

const REFERENCE_INFO_PRIMARY_HEADING : ContactInfoHeaderProps = {
  heading: "Primary Reference",
  popupHeading: null,
  popupFunctionality: null,
  params: null,
  infoIcon: false,
  infoIconContent: "",
};

const REFERENCE_INFO_SECONDARY_HEADING : ContactInfoHeaderProps = {
  heading: "Secondary Reference",
  popupHeading: null,
  popupFunctionality: null,
  params: null,
  infoIcon: false,
  infoIconContent: "",
};

const RELATIVE_RELATIONS : string[] = ['PARENT', 'SPO', 'CHILD', 'SIBLING'];
const NON_FAMILY_RELATION_TYPES: string[] = ['FRI', 'OTH', 'LND', 'CWR'];
const REFERENCE_INFO_ERROR_MESSAGE : string = 'Required - Please add minimum 1 family/relative reference or 2 non-family/relative references';

const enum MODULE_NAME {
  IDENTITY_VERIFICATION = 'IdentityVerification',
  EMPLOYMENT_VERIFICATION = 'EmpVerification',
  REFERENCE_VERIFICATION = 'RefVerification',
  RESIDENCE_VERIFICATION = 'ResidentVerification',
  OVERRIDE_VERIFICATION = 'VerificationOverride',
  IDENTITY_DOCUMENT = 'IdentityDocAccess'

}

const CUSTOMER_APPROVAL_FLOW = true;
const DE_RUNNED_CA_STATUS = 'approved'
export {
  STRING_YES,
  STRING_NO,
  TODAY_DATE_TIME,
  PHONE_ERROR_MESSAGE,
  NATIONAL_DNC_INFO,
  AGREEMENT_DETAILS,
  VERIFICATION_EXPIRY_DAYS,
  API_ERROR_MESSAGE,
  GOVT_ID_REGEX,
  MAIL_REGEX,
  ONLY_ALPHABETS_REGEX,
  LOGGED_IN_STORE,
  NAME_REPLACEABLE_REGEX,
  AMOUNT_FORMAT_REGEX,
  STRING_ZERO,
  INVALID_EMAIL_FORMAT,
  STRING_ZERO_AMOUNT,
  EMPTY_STRING,
  REQUIRED_ERROR,
  DEFAULT_DROPDOWN_OPTION,
  DECISION_ENGINE_APPROVED,
  DECISION_ENGINE_PREAPPROVED,
  DECISON_ENGINE_DECLINED,
  DECISON_ENGINE_EXPIRED,
  NO_DECISION_ENGINE,
  HEADER_INFORMATION_TEMPLATE,
  BASIC_INFORMATION_TEMPLATE,
  MULTIPLE_SPACE_REMOVAL_REGEX,
  TAX_EXEMPT_TEMPLATE,
  COMMUNICATION_PREFERENCE_TEMPLATE,
  RESIDENCE_INFO_TEMPLATE,
  SKIP_HARD_TEMPLATE,
  CONTACT_INFORMATION_TEMPLATE,
  VEHICLE_INFORMATION_TEMPLATE,
  VEHICLE_YEAR_DROPDOWN,
  REFERENCE_INFO_TEMPLATE,
  LEGAL_HOLD_INFO_TEMPLATE,
  CONFIGURATION_INFO_TEMPLATE,
  CLUB_INFO_TEMPLATE,
  API_ERROR,
  FAX_SHEET_INFO_TEMPLATE,
  INACTIVE_CONTACT_GRID_HEADER,
  AddressHistoryHeader,
  dropdownOptions,
  REQUIRED_FIELD_ERROR,
  DEHistoryHeader,
  agreementHistoryHeader,
  dateFormat,
  getAgreementByIdTemplate,
  ADDRESS_INFO_TEMPLATE,
  EMPLOYER_INFO_TEMPLATE,
  SSN,
  IDENTITY_DETAILS,
  headerPrimAdd,
  HOW_LONG_DROPDOWN,
  DATE_FORMAT,
  EMPLOYEMNET_UPDATE_REQUEST,
  GOVT_ID_TYPE_CVO,
  HIGHER_AUTHORITY_ROLES,
  REFERENCE_INFO_PRIMARY_HEADING,
  REFERENCE_INFO_SECONDARY_HEADING,
  RELATIVE_RELATIONS,
  NON_FAMILY_RELATION_TYPES,
  REFERENCE_INFO_ERROR_MESSAGE,
  MODULE_NAME,
  CUSTOMER_APPROVAL_FLOW,
  DE_RUNNED_CA_STATUS
};