/* eslint-disable */
import {
  Card,
  CardContent,
  FormLabel,
  Grid,
  Link,
  RACButton,
  RACCheckBox,
  RACCOLOR,
  RACInfoIcon,
  RACRadio,
  RACSelect,
  RACTextbox,
  RACToggle,
  Typography,
} from "@rentacenter/racstrap";
import {
  API_ERROR_MESSAGE,
  EMPTY_STRING,
  NATIONAL_DNC_INFO,
  STRING_NO,
  PHONE_ERROR_MESSAGE,
  REQUIRED_ERROR,
  STRING_YES,
  CONTACT_INFORMATION_TEMPLATE,
  MAIL_REGEX,
  INVALID_EMAIL_FORMAT,
} from "../../constants/constants";
import {
  formatByNumeric,
  formatPhone,
  trimTextvalue,
  valueExistsInDropdown,
} from "../../utils/utils";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ContactInformations, CustomerBasicInformation } from "../../interface/updateCustomerInterface";
import { DropdownOptions, PathParams } from "../../interface/globalInterface";
import { updateCustomerStyles } from "../../jsStyles/updateCustomerStyles";
import { InactiveContactDetails } from "./InactiveContactDetails";
import { DynamicAlertPopup } from "./DynamicAlertPopup";
import { DynamicConfirmPopup } from "./DynamicConfirmPopup";
import { UpdateCustomerContext } from "../../context/UpdateCustomerContext";
import {
  updateArrayOfObjectState,
  updateCustomerDetails,
  updateObjectStateVariable,
  validateEmail,
} from "./Utils";
import { handleWebLink } from "./BasicInformation";
import { useParams } from "react-router-dom";
import { UpdateCustomerDetails } from "../../../api/user";

interface ContactInformationProps {
  data: {
    activeContactInformation: ContactInformations[];
    inactiveContactInformation: ContactInformations[];
    basicInformation:CustomerBasicInformation
    agreementTransferInit: boolean;
    requiredField: boolean;
    dropdownLoader: boolean;
    route:string
    updatedContactInfo: ContactInformations[];
    contactInfoErrorMessage: ContactInformations[];
  };
  dropdown: {
    phoneTypeOptions: DropdownOptions[];
    bestTimeToCallOptions: DropdownOptions[];
  };
  function: {
    setActiveContactInformation: React.Dispatch<
      React.SetStateAction<ContactInformations[]>
    >;
    setUpdatedContactInfo: React.Dispatch<
      React.SetStateAction<ContactInformations[]>
    >;
    setContactInfoErrorMessage: React.Dispatch<
      React.SetStateAction<ContactInformations[]>
    >;
    setInactiveContactInformation: React.Dispatch<
    React.SetStateAction<ContactInformations[]>
  >;
  };
}

export function ContactInformation(props: ContactInformationProps) {
  /**
   * In our Customer Verification implementation, we introduced a new object key called isEditEnabled.
   * This key indicates whether the CustomerVerificationOptimization feature flag is active and shows
   * whether the data is in an unedited state.
   * When the Edit button is clicked, isEditEnabled is set to false.
   * This change triggers the display of duplicate values, referred to as updatedContactInformation.
   * While editing, any modifications are saved to the updatedContactInformation variable.
   * If no edits are made, the original values remain in the activeContactInformation variable,
   * maintaining the existing data flow.
   */
  const {
    activeContactInformation,
    inactiveContactInformation,
    dropdownLoader,
    agreementTransferInit,
    requiredField,
    updatedContactInfo,
    contactInfoErrorMessage,
    route,
    basicInformation
  } = props.data;
  const { phoneTypeOptions, bestTimeToCallOptions } = props.dropdown;
  const [showInactivePhones, setShowInactivePhones] = useState<boolean>(false);
  const [showPopup, setContactInfoShowPopup] = useState<boolean>(false);

  const [popupMessage, setContactInfoPopupMessage] =
    useState<string>(EMPTY_STRING);
  const [deactivateMsg, setDeactiveMsg] = useState<string>(EMPTY_STRING);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { setActiveContactInformation, setUpdatedContactInfo, setContactInfoErrorMessage, setInactiveContactInformation } = props.function;
  const classes = updateCustomerStyles();
  const {
    configurations,
    emailErrorMessage,
    setEmailErrorMessage,
    customerBasicInformation,
    updatedCustomerBasicInfo,
    setCustomerBasicInformation,
    setUpdatedCustomerBasicInfo,
    setShowPopup,
    setPopupType,
    setPopupMessage,
    setScreenLoader,
  } = useContext(UpdateCustomerContext);
  const { customerId } = useParams<PathParams>();
  const isCustVerfFlow: boolean =
    configurations?.customerVerificationOptimization;

  console.log('Inside the contact Infor', activeContactInformation, updatedContactInfo);
  const [editEnabled, setEditEnabled] = useState<boolean>(activeContactInformation[0].isEditEnabled);
  const formatFieldValue = (field: string, value: string) => {
    try {
      let formattedValue: string = value;
      switch (field) {
        case "instructions":
          formattedValue = trimTextvalue(value);
          break;
        case "phoneNumber":
          formattedValue = formatPhone(value);
          break;
        case "extension":
          formattedValue = formatByNumeric(value);
          break;
      }
      return formattedValue;
    } catch (error: any) {
      console.log("Error in validateByRegex: ", error?.message);
      return value;
    }
  };

  const handlePhoneType = (value: string, index: number) => {
    try {
      const currentActiveContacts = JSON.parse(
        JSON.stringify(
          !editEnabled
            ? updatedContactInfo
            : activeContactInformation
        )
      );
      currentActiveContacts[index].phoneType = value;
      if (
        value == "WORK" &&
        currentActiveContacts[index].primary == STRING_YES &&
        currentActiveContacts.length !== 1
      ) {
        let primaryAssigned = false;

        currentActiveContacts.forEach((contactDetail: any) => {
          if (!primaryAssigned && contactDetail.phoneType !== "WORK") {
            primaryAssigned = true;
            contactDetail.primary = STRING_YES;
          }
        });
      }
      currentActiveContacts[index].primary =
        value !== "WORK" ? currentActiveContacts[index].primary : STRING_NO;
      if (
        !editEnabled
      ) {
        setUpdatedContactInfo([...currentActiveContacts]);
      } else {
        setActiveContactInformation([...currentActiveContacts]);
      }
    } catch (error: any) {
      console.log("Error in handlePhoneType: ", error?.message);
    }
  };

  const handlePrimaryToggle = (checkedStatus: boolean, index: number) => {
    try {
      debugger;
      let number: any = 0;
      const deepCopiedContacts = JSON.parse(
        JSON.stringify(
          editEnabled
            ? activeContactInformation
            : updatedContactInfo
        )
      );

      for (let i = 0; i < deepCopiedContacts.length; i++) {
        if (
          deepCopiedContacts[i].primary === STRING_YES &&
          deepCopiedContacts.length >= 1
        ) {
          number = number + 1;
        }
      }

      if (
        deepCopiedContacts.length >= 1 &&
        deepCopiedContacts[index].primary === 'Y' &&
        deepCopiedContacts[index].active === 'Y' &&
        number == 1
      ) {
        setContactInfoPopupMessage(
          "Unable to set inprimary. Customer must have atleast one Phone Number marked as Primary"
        );
        setContactInfoShowPopup(true);
      }

      if (
        deepCopiedContacts.length > 1 &&
        deepCopiedContacts[index].primary === 'Y' &&
        deepCopiedContacts[index].active === 'Y' &&
        number > 1
      ) {
        deepCopiedContacts[index].primary = STRING_NO;
      }

      if (deepCopiedContacts[index].primary !== STRING_YES) {
        deepCopiedContacts[index].primary =
          checkedStatus &&
          (deepCopiedContacts[index].phoneType == "CELL" ||
            deepCopiedContacts[index].phoneType == "HOME") &&
          deepCopiedContacts[index].phoneNumber &&
          (deepCopiedContacts[index].phoneNumber.length == 10 ||
            deepCopiedContacts[index].phoneNumber.length == 14) &&
          deepCopiedContacts[index].callTimeType
            ? STRING_YES
            : STRING_NO;
        deepCopiedContacts[index].primary =
          deepCopiedContacts.length == 1 &&
          deepCopiedContacts[index].phoneType !== "WORK" &&
          deepCopiedContacts[index].phoneType &&
          (deepCopiedContacts[index].phoneType == "CELL" ||
            deepCopiedContacts[index].phoneType == "HOME") &&
          deepCopiedContacts[index].phoneNumber &&
          (deepCopiedContacts[index].phoneNumber.length == 10 ||
            deepCopiedContacts[index].phoneNumber.length == 14) &&
          deepCopiedContacts[index].callTimeType
            ? STRING_YES
            : deepCopiedContacts[index].primary;

        if (
          checkedStatus &&
          deepCopiedContacts.length > 1 &&
          deepCopiedContacts[index].primary === STRING_YES
        ) {
          for (let i = 0; i < deepCopiedContacts.length; i++) {
            if (i !== index) {
              deepCopiedContacts[i].primary = STRING_NO;
            }
          }
        }
        if (
          checkedStatus &&
          deepCopiedContacts.length > 1 &&
          deepCopiedContacts[index].primary === STRING_YES &&
          deepCopiedContacts[index].active === STRING_NO
        ) {
          deepCopiedContacts[index].primary = STRING_YES;
          deepCopiedContacts[index].active = STRING_YES;
        }
        if (
          !editEnabled
        ) {
          setActiveContactInformation([...deepCopiedContacts]);
        } else {
          setUpdatedContactInfo([...deepCopiedContacts]);
        }
      } else if (deepCopiedContacts[index].primary === STRING_YES) {
        const isPrimaryArg = deepCopiedContacts.filter((phoneObj: any) => {
          if (phoneObj.primary === STRING_YES) {
            return phoneObj;
          }
        });
        if (isPrimaryArg.length > 1) {
          deepCopiedContacts[index].primary = STRING_NO;
        }
        if (
          !editEnabled
        ) {
          setActiveContactInformation([...deepCopiedContacts]);
        } else {
          setUpdatedContactInfo([...deepCopiedContacts]);
        }
      }
    } catch (error: any) {
      console.log("Error in  handlePrimaryToggle: ", error?.message);
    }
  };

  const handleActiveToggle = (checkStatus: boolean, index: number) => {
    try {
      const deepCopiedContacts = JSON.parse(
        JSON.stringify(
          !editEnabled
            ? activeContactInformation
            : updatedContactInfo
        )
      );
      if (
        deepCopiedContacts.length >= 1 &&
        deepCopiedContacts[index].active == STRING_YES &&
        deepCopiedContacts[index].primary == STRING_YES
      ) {
        setContactInfoPopupMessage(
          "Unable to inactive. Customer must have atleast one Phone Number marked as Primary"
        );
        setContactInfoShowPopup(true);
      } else if (
        deepCopiedContacts.length > 1 &&
        deepCopiedContacts[index].active == STRING_YES &&
        deepCopiedContacts[index].primary == STRING_NO
      ) {
        setShowConfirmation(true);
        setDeactiveMsg("Are you sure you want to inactive this Phone number");
        setActiveIndex(index);
      } else if (
        deepCopiedContacts.length > 1 &&
        deepCopiedContacts[index].active == STRING_NO
      ) {
        deepCopiedContacts[index].active = STRING_YES;
      } else if (
        deepCopiedContacts.length > 1 &&
        deepCopiedContacts[index].active == STRING_YES
      ) {
        deepCopiedContacts[index].active = STRING_NO;
      }
      if (
        !editEnabled
      ) {
        setActiveContactInformation([...deepCopiedContacts]);
      } else {
        setUpdatedContactInfo([...deepCopiedContacts]);
      }
    } catch (error: any) {
      console.log("Error in handleActiveToggle: ", error?.message);
    }
  };

  const handleCancelActive = () => {
    try {
      const deepCopiedContacts = JSON.parse(
        JSON.stringify(activeContactInformation)
      );

      deepCopiedContacts[activeIndex].active = STRING_YES;
      setActiveContactInformation(deepCopiedContacts);

      setShowConfirmation(false);
    } catch (error: any) {
      console.log("Error in handleCancelActive: ", error?.message);
    }
  };

  const handleSaveActive = () => {
    try {
      const deepCopiedContacts = JSON.parse(
        JSON.stringify(activeContactInformation)
      );

      deepCopiedContacts[activeIndex].active = STRING_NO;
      setActiveContactInformation(deepCopiedContacts);

      setShowConfirmation(false);
    } catch (error: any) {
      console.log("Error in handleCancelActive: ", error?.message);
    }
  };

  const saveOnChangeValues = (
    index: number,
    field: string,
    value: string
  ): void => {
    try {
      const deepCopiedContacts = JSON.parse(
        JSON.stringify(
          editEnabled
            ? activeContactInformation
            : updatedContactInfo
        )
      );
      ["phoneType", "callTimeType"].includes(field)
        ? (deepCopiedContacts[index][
            field == "phoneType" ? "phoneTypeDesc" : "callTimeTypeDesc"
          ] = formatFieldValue(
            field == "phoneType" ? "phoneTypeDesc" : "callTimeTypeDesc",
            bestTimeToCallOptions.find((ele) => ele.value == value)?.label ||
              EMPTY_STRING
          ))
        : null;
      deepCopiedContacts[index][field] = formatFieldValue(field, value);
      if (editEnabled) {
        setActiveContactInformation(deepCopiedContacts);
      } else {
        console.log("deepCopiedContacts", deepCopiedContacts);
        setUpdatedContactInfo(deepCopiedContacts);
      }
    } catch (error: any) {
      console.log("Error in saveOnChangeValues: ", error?.message);
    }
  };

  const validateContactDetails = () => {
    let isRequiredDetailsPresent: boolean = true;
    let errorMessages: ContactInformations[] = JSON.parse(
      JSON.stringify(contactInfoErrorMessage)
    );
    updatedContactInfo.forEach(
      (element: ContactInformations, index: number) => {
        if (!element.phoneNumber) {
          errorMessages[index].phoneNumber = REQUIRED_ERROR;
          isRequiredDetailsPresent = false;
        } else if (element.phoneNumber.length < 10) {
          errorMessages[index].phoneNumber = PHONE_ERROR_MESSAGE;
          isRequiredDetailsPresent = false;
        } else {
          errorMessages[index].phoneNumber = EMPTY_STRING;
        }
        if (!element.callTimeType) {
          isRequiredDetailsPresent = false;
          errorMessages[index].callTimeType = REQUIRED_ERROR;
        } else {
          errorMessages[index].callTimeType = EMPTY_STRING;
        }
        if (!element.phoneType) {
          isRequiredDetailsPresent = false;
          errorMessages[index].phoneType = REQUIRED_ERROR;
        } else {
          errorMessages[index].phoneType = EMPTY_STRING;
        }
        if (!updatedCustomerBasicInfo.emailAddress) {
          setEmailErrorMessage(REQUIRED_ERROR);
          isRequiredDetailsPresent = false;
        } else if (!MAIL_REGEX.test(updatedCustomerBasicInfo.emailAddress)) {
          setEmailErrorMessage(INVALID_EMAIL_FORMAT);
          isRequiredDetailsPresent = false;
        } else {
          setEmailErrorMessage(EMPTY_STRING);
        }
      }
    );
    setContactInfoErrorMessage(errorMessages);
    return isRequiredDetailsPresent;
  };

  async function updateContactDetails() {
    try {
      if (validateContactDetails()) {
        console.log('Update Phone Details', updatedContactInfo, activeContactInformation);
        setScreenLoader(true);
        const paylaod = {
          customerId: customerId,
          emailAddress:
            updatedCustomerBasicInfo.emailAddress !=
            customerBasicInformation.emailAddress
              ? updatedCustomerBasicInfo.emailAddress
              : undefined,
          phones: updatedContactInfo.map(
            (element: ContactInformations, index: number) => ({
              phoneId: element.phoneId,
              phoneNumber: element.phoneNumber?.replace(/[^\d]/g, ""),
              phoneType: element.phoneType,
              phoneTypeDesc: element.phoneTypeDesc,
              extension: element.extension,
              primary: element.primary,
              callTimeType: element.callTimeType,
              callTimeTypeDesc: element.callTimeTypeDesc,
              active: element.active,
              note: element.instructions,
              isNDNCOpted: element.isNDNCOpted,
              isTrnsMsgAllowed: element.isTrnsMsgAllowed
                ? STRING_YES
                : STRING_NO,
              isMktgMsgAllowed: element.isMktgMsgAllowed
                ? STRING_YES
                : STRING_NO,
              phoneNumberOri: element.phoneNumber?.replace(/[^\d]/g, ""),
            })
          ),
        };
        const updateResponse = await UpdateCustomerDetails(paylaod);
        console.log("Updated Response", updateResponse);
        if (updateResponse?.UpdateCustomer?.value?.customerId) {
          const updatedContactInformation = updatedContactInfo.map(
            (item: ContactInformations) => ({
              ...item,
              cancelable: false,
            })
          );
          setEditEnabled(true)
          setActiveContactInformation(updatedContactInformation);
          setCustomerBasicInformation(updatedCustomerBasicInfo);
        }
        setScreenLoader(false);
      }
    } catch (e: any) {
      setScreenLoader(false);
      console.log("Error in Update Contact Info", e);
    }
  };

  const renderEmailAndEditContext = () => {
    console.log("CustomerBasicInfo", customerBasicInformation);
    return (
      <>
        <Grid container md={12} spacing={2} style={{ padding: '8px' }}>
          {renderAddNewContactElement()}
          <Grid item md={4}>
            <RACTextbox
              inputlabel="Email Address"
              name={`emailAddress`}
              id={"emailAddress"}
              key={"EmailInput"}
              type={"text"}
              maxlength={50}
              required={true}
              disabled={agreementTransferInit || editEnabled}
              value={
                !editEnabled
                  ? updatedCustomerBasicInfo.emailAddress
                  : customerBasicInformation.emailAddress
              }
              Onblur={() => {
                validateEmail(
                  updatedCustomerBasicInfo.emailAddress,
                  setEmailErrorMessage
                );
              }}
              OnChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateObjectStateVariable(
                  !editEnabled
                    ? setUpdatedCustomerBasicInfo
                    : setCustomerBasicInformation,
                  event.target.name,
                  event.target.value?.trim()
                );
              }}
              errorMessage={emailErrorMessage}
            />
          </Grid>
          <Grid item md={4}>
            <Grid
              title={
                customerBasicInformation.oktaId
                  ? "This account is already linked with e-pay login"
                  : undefined
              }
              className={`${classes.pt9px} ${classes.pl9px}`}
            >
              <RACButton
                variant="contained"
                color="primary"
                id="webLink"
                disabled={
                  customerBasicInformation.oktaId !== EMPTY_STRING ||
                  editEnabled
                }
                onClick={() => {
                  handleWebLink({
                    setShowPopup,
                    setPopupType,
                    customerBasicInformation,
                    setPopupMessage,
                    setScreenLoader,
                    customerId,
                  });
                }}
              >
                Web Link
              </RACButton>
            </Grid>
          </Grid>
          {editEnabled ? (
            <Grid item md={4}>
              <Grid
                className={`${classes.floatRight} ${classes.pt9px} ${classes.pl9px}`}
              >
                <RACButton
                  variant="contained"
                  color="primary"
                  className={`${classes.buttonsPadding}`}
                  onClick={() => {
                    setEditEnabled(false);
                  }}
                >
                  Edit
                </RACButton>
              </Grid>
            </Grid>
          ) : (
            <Grid item md={4} className={`${classes.floatRight}`}>
              <Grid
                className={`${classes.floatRight} ${classes.pt9px} ${classes.pl9px}`}
              >
                <RACButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    removeContactDetailsAndDisable();
                  }}
                >
                  Cancel
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateContactDetails();
                  }}
                  className={classes.ml20px}
                >
                  Save
                </RACButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const renderContactInfoContext = (
    contactInfo: ContactInformations,
    index: number
  ): JSX.Element => {
    return (
      <Grid
        container
        md={12}
        spacing={2}
        className={index != 0 ? `${classes.mt8}` : ""}
      >
        <Grid item md={4} lg={4} xl={3}>
          <RACTextbox
            inputlabel="Phone Number"
            name={`phonenumber_${index}`}
            id={`phonenumber_${index}`}
            maxlength={13}
            type={"text"}
            required
            disabled={agreementTransferInit || editEnabled}
            value={
              !editEnabled
                ? formatPhone(updatedContactInfo[index]?.phoneNumber || '')
                : formatPhone(activeContactInformation[index].phoneNumber || '')
            }
            OnChange={(event: ChangeEvent<HTMLInputElement>) => {
              saveOnChangeValues(index, "phoneNumber", event.target.value);
              contactInfoErrorMessage[index].phoneNumber
                ? validateContactDetails()
                : null;
            }}
            errorMessage={contactInfoErrorMessage[index]?.phoneNumber || ''}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <RACTextbox
            inputlabel="Ext"
            type={"text"}
            name={`ExtforContact_${index}`}
            id={`ExtforContact_${index}`}
            maxlength={5}
            disabled={agreementTransferInit || editEnabled}
            value={
              !editEnabled
                ? updatedContactInfo[index]?.extension || ''
                : activeContactInformation[index]?.extension || ''
            }
            OnChange={(event: ChangeEvent<HTMLInputElement>) => {
              saveOnChangeValues(index, "extension", event.target.value);
            }}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <RACSelect
            inputLabel="Best Time to Call"
            required
            isDisabled={agreementTransferInit || editEnabled}
            options={bestTimeToCallOptions}
            loading={dropdownLoader}
            defaultValue={valueExistsInDropdown(
              bestTimeToCallOptions,
              !editEnabled
                ? updatedContactInfo[index]?.callTimeType
                : activeContactInformation[index].callTimeType
            )}
            errorMessage={
              contactInfoErrorMessage[index]?.callTimeType
                ? contactInfoErrorMessage[index]?.callTimeType
                : !dropdownLoader && bestTimeToCallOptions?.length == 1
                ? API_ERROR_MESSAGE
                : EMPTY_STRING
            }
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              saveOnChangeValues(index, "callTimeType", event.target.value);
              console.log('e.target', event.target);
              contactInfoErrorMessage[index].callTimeType
                ? validateContactDetails()
                : null;
            }}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <RACSelect
            inputLabel="Phone Type"
            required
            isDisabled={agreementTransferInit || editEnabled}
            options={phoneTypeOptions}
            defaultValue={valueExistsInDropdown(
              phoneTypeOptions,
              !editEnabled
                ? updatedContactInfo[index]?.phoneType
                : activeContactInformation[index].phoneType
            )}
            loading={dropdownLoader}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              handlePhoneType(event.target.value, index);
              contactInfoErrorMessage[index].phoneType
                ? validateContactDetails()
                : null;
            }}
            errorMessage={
              contactInfoErrorMessage[index]?.phoneType
                ? contactInfoErrorMessage[index]?.phoneType
                : !dropdownLoader && phoneTypeOptions?.length == 1
                ? API_ERROR_MESSAGE
                : EMPTY_STRING
            }
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <RACTextbox
            value={
              !editEnabled
                ? updatedContactInfo[index]?.instructions
                : activeContactInformation[index].instructions
            }
            disabled={agreementTransferInit || editEnabled}
            inputlabel="Phone Instructions"
            name={`PhoneInstructions_${index}`}
            id={`PhoneInstructions_${index}`}
            type={"text"}
            maxlength={50}
            OnChange={(event: ChangeEvent<HTMLInputElement>) => {
              saveOnChangeValues(index, "instructions", event.target.value);
            }}
          />
        </Grid>
        <Grid item md={4} lg={4} xl={3}>
          <Grid container>
            <Grid item lg={6} xl={6}>
              <Typography component="label">
                National Do Not Contact
                <RACInfoIcon infoTitle={NATIONAL_DNC_INFO} />
              </Typography>

              <Grid>
                <Grid style={{ float: "left" }}>
                  <RACRadio
                    disabled={true}
                    value={STRING_YES}
                    checked={
                      activeContactInformation[index].isNDNCOpted &&
                      activeContactInformation[index].isNDNCOpted === STRING_YES
                        ? true
                        : false
                    }
                    label={"Yes"}
                    name="ndnc"
                  />
                </Grid>
                <Grid style={{ float: "left" }}>
                  <RACRadio
                    value={STRING_NO}
                    disabled={true}
                    checked={
                      activeContactInformation[index].isNDNCOpted === STRING_NO ? true : false
                    }
                    label="No"
                    name="ndnc"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} xl={6}>
              <RACCheckBox
                label="Text Message Reminder"
                size="small"
                name={`isTrnsMsgAllowed_${index}`}
                disabled={
                  agreementTransferInit ||
                  !activeContactInformation[index].textMessageReminderOri ||
                  editEnabled
                }
                value={
                  !editEnabled
                    ? updatedContactInfo[index]?.isTrnsMsgAllowed === STRING_YES
                      ? STRING_NO
                      : STRING_YES
                    : activeContactInformation[index].isTrnsMsgAllowed == STRING_YES
                    ? STRING_NO
                    : STRING_YES
                }
                checked={
                  activeContactInformation[index].isTrnsMsgAllowed == STRING_YES &&
                  activeContactInformation[index].isNDNCOpted &&
                  activeContactInformation[index].isNDNCOpted === STRING_NO
                    ? true
                    : false
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  saveOnChangeValues(
                    index,
                    "isTrnsMsgAllowed",
                    event.target.value
                  );
                }}
              />

              <RACCheckBox
                label="Text Marketing Offers"
                size="small"
                name={`isMktgMsgAllowed_${index}`}
                disabled={
                  agreementTransferInit ||
                  !activeContactInformation[index].textMarketingOffersOri ||
                  activeContactInformation[index].isNDNCOpted === STRING_YES ||
                  editEnabled
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  saveOnChangeValues(
                    index,
                    "isMktgMsgAllowed",
                    event.target.value
                  );
                }}
                value={
                  !editEnabled
                    ? updatedContactInfo[index]?.isMktgMsgAllowed == "Y"
                      ? "N"
                      : "Y"
                    : activeContactInformation[index].isMktgMsgAllowed == "Y"
                    ? "N"
                    : "Y"
                }
                checked={
                  activeContactInformation[index].isMktgMsgAllowed === STRING_YES &&
                  activeContactInformation[index].isNDNCOpted &&
                  activeContactInformation[index].isNDNCOpted === STRING_NO
                    ? true
                    : false
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={8} xl={3} style={{ display: "flex" }}>
          <Grid item md={4} className={`${classes.bottomAlign}`}>
            <RACToggle
              rightLabel={"Is Primary?"}
              rightText={true}
              disabled={editEnabled}
              name={`Is_Primary_${index}`}
              id={`Is_Primary_${index}`}
              toggleColor={RACCOLOR.NORMAL_BLUE}
              GridClassName={classes.toggleGrid}
              labelGridClassName={classes.toggleLabelGrid}
              labelClassName={classes.toggleLabel}
              checked={
                !editEnabled
                  ? updatedContactInfo.length > 0 &&
                    updatedContactInfo[index]?.primary === STRING_YES
                  : activeContactInformation.length > 0 &&
                  activeContactInformation[index].primary === STRING_YES
              }
              OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                handlePrimaryToggle(event.target.checked, index);
              }}
            />
          </Grid>

          <Grid item md={4} className={`${classes.bottomAlign}`}>
            <RACToggle
              rightLabel={"Is Active?"}
              rightText={true}
              toggleColor={RACCOLOR.NORMAL_BLUE}
              GridClassName={classes.toggleGrid}
              labelGridClassName={classes.toggleLabelGrid}
              labelClassName={classes.toggleLabel}
              checked={
                editEnabled
                  ? updatedContactInfo[index]?.active == STRING_YES
                  : activeContactInformation[index].active == STRING_YES
              }
              disabled={editEnabled}
              OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleActiveToggle(event.target.checked, index);
              }}
            />
          </Grid>

          {activeContactInformation[index].cancelable && activeContactInformation[index].primary === STRING_NO ? (
            <Grid item md={4} className={`${classes.textAlignRight}`}>
              <RACButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  const deepCopiedContacts: ContactInformations[] = JSON.parse(
                    JSON.stringify(activeContactInformation)
                  );

                  deepCopiedContacts.splice(index, 1);
                  setActiveContactInformation(deepCopiedContacts);
                }}
              >
                Cancel
              </RACButton>
            </Grid>
          ) : null}
        </Grid>
        {isCustVerfFlow && activeContactInformation.length - 1 == index ? (
          <Grid container md={12} className={`${classes.mt2}`}>
            {renderEmailAndEditContext()}
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const buildActiveContactInfo = (): JSX.Element => {
    return (
      <>
        {activeContactInformation.map(
          (contactInfo: ContactInformations, index: number) => {
            return (
              <>
                <Card
                  style={{
                    width: "100%",
                    borderRadius: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <CardContent>
                    {renderContactInfoContext(contactInfo, index)}
                  </CardContent>
                </Card>
              </>
            );
          }
        )}
      </>
    );
  };

  const removeContactDetailsAndDisable = () => {
    setEditEnabled(true);
    const updatedContactInformation = activeContactInformation.filter(
      (item) => !item.cancelable
    );
    console.log('Inside the removal', updatedContactInformation);
    setActiveContactInformation(updatedContactInformation);
    setUpdatedContactInfo(updatedContactInformation);
    setUpdatedCustomerBasicInfo(customerBasicInformation);
    setEmailErrorMessage("");
  };

  const buildInlineEditableContactInfo = (): JSX.Element => {
    return (
      <>
        <Card
          style={{
            width: "100%",
            borderRadius: "15px",
            marginBottom: "15px",
          }}
        >
          <CardContent>
            {activeContactInformation.map(
              (contactInfo: ContactInformations, index: number) =>
                renderContactInfoContext(contactInfo, index)
            )}
          </CardContent>
        </Card>
      </>
    );
  };

  const renderAddNewContactElement = () => {
    return (
      <Grid
        container
        md={12}
        className={isCustVerfFlow ? `${classes.p13px}` : ``}
      >
        <Link
          style={{ color: RACCOLOR.NORMAL_BLUE, textDecoration: "none" }}
          className={
            !editEnabled ? `${classes.cursorPointer}` : `${classes.cursorNone}`
          }
          onClick={() => {
            if (!editEnabled && validateContactDetails()) {
              const newContact: ContactInformations = {
                phoneId: EMPTY_STRING,
                phoneNumber: EMPTY_STRING,
                phoneType: EMPTY_STRING,
                phoneTypeDesc: EMPTY_STRING,
                extension: EMPTY_STRING,
                primary: STRING_NO,
                primaryFlag: STRING_NO,
                callTimeType: EMPTY_STRING,
                callTimeTypeDesc: EMPTY_STRING,
                instructions: EMPTY_STRING,
                isNDNCOpted: STRING_NO,
                isTrnsMsgAllowed: STRING_NO,
                isMktgMsgAllowed: STRING_NO,
                cancelable: true,
                isEditEnabled: editEnabled,
                active: STRING_YES,
                textMarketingOffersOri: false,
                textMessageReminderOri: false,
                phoneNumberOri: EMPTY_STRING,
              };
              setActiveContactInformation([...updatedContactInfo, newContact]);
              setUpdatedContactInfo([...updatedContactInfo, newContact]);
              setContactInfoErrorMessage([
                ...contactInfoErrorMessage,
                newContact,
              ]);
            }
          }}
        >
          + Add another Contact
        </Link>
      </Grid>
    );
  };

  return (
    <>
      {showInactivePhones ? (
        <InactiveContactDetails
          data={{
            inactivePhone: inactiveContactInformation,
            activePhone: activeContactInformation,
            bestTimeToCallOptions: bestTimeToCallOptions,
            phoneTypeOptions: phoneTypeOptions,
            communicationRoute: route,
            customerInformation: basicInformation,
          }}
          function={{
            onCloseFn: () => {
              setShowInactivePhones(false);
            },
            setActivePhone: setActiveContactInformation,
            setInactivePhone: setInactiveContactInformation,
          }}
        />
      ) : (
        <></>
      )}

      {showPopup ? (
        <DynamicAlertPopup
          alertType=""
          alertMessage={popupMessage}
          closeIcon={true}
          onCloseFn={() => {
            setContactInfoShowPopup(false);
          }}
        />
      ) : null}

      {showConfirmation ? (
        <DynamicConfirmPopup
          alertMessage={deactivateMsg}
          closeIcon={true}
          closePopupFn={() => {
            handleCancelActive();
          }}
          confirmFn={() => {
            handleSaveActive();
          }}
        />
      ) : null}

      <Grid container md={12}>
        <Grid
          item
          md={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className={classes.title}>Contact Information</Typography>

          <FormLabel
            className={classes.title}
            style={{ color: RACCOLOR.NORMAL_BLUE, fontSize: "14px" }}
            onClick={() => {
              setShowInactivePhones(true);
            }}
          >
            Inactive Phone Numbers
          </FormLabel>
        </Grid>

        {isCustVerfFlow
          ? buildInlineEditableContactInfo()
          : buildActiveContactInfo()}

        {!isCustVerfFlow ? renderAddNewContactElement() : null}
      </Grid>
    </>
  );
}