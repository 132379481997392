/* eslint-disable */
import { Grid, List, ListItem, RACButton } from "@rentacenter/racstrap";
import React, { Fragment, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  LOGGED_IN_STORE,
} from "../../../constants/constants";
import { AppRoute } from "../../../../config/route-config";
import { GetCustomer } from "../../../api/user";
import { updateCustomerStyles } from "../../../jsStyles/updateCustomerStyles";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { validateApiResponse } from "../../../utils/utils";
import { FeatureFlagContext } from "../../../../context/FeatureFlagContext";
import { agreementRedirect } from "./FooterValidations/AgreementRedirect";
import { ParamType } from "../../../interface/globalInterface";
import { FooterPopUp } from "./FooterValidations/FooterPopUp";
import { generateOrderForm } from "./PrintOrderForm";
import SecondFactor from "../../../../racstrapComponent/manageCustomer/secondAuthen";

export default function UpdateCustomerFooter(): JSX.Element {
  const {
    updateOccured,
    agreementTransferInitiated,
    showSplit,
    agreementInformation,
    legalInformation,
    currentRole,
    customerBasicInformation,
    coCustomerInformation,
    setCoCustomerInformation,
    setScreenLoader,
    setIsRequired,
    Agrtransferinit,
    createAgrClicked,
    setcreateAgrClicked,
    PopupClass,
    setPopupClass,
    checkAction,
    setcomingSoonPopup,
    activeAddress,
    contactInformation,
    taxExemptInformation,
    federalTaxId,
    taxToggle,
    bestTimeToCallOptions,
    setValidateResult,
    skipStolenHardStatus,
    isMaskedDataHasChanged,
    isCustomerInfoUpdate,
    phoneTypeOptions,
    employerDetails,
    residenceInfo,
    getCustomerInfoResponse,
    vehicleInformation,
    communicationInformation,
    setCreateAgreementClick,
    activetab,
    setErrorPopup,
    isAuth,
    setisAuth,
    setrestatementPopUp,
    setcreateCoCustomerPopUp,
    setShowUpdatePopUp,
    setisSuccess,
    secondLevelAuthenOn,
    setSecondLevelAuthenOn,
    setCantAccess,
    setIsCustomerInfoUpdate,
    configurations,
    getAgrbyIdresponse,
    setSplitBlockOpen,
    splitCoCustomerAvailble,
    clubInfo,
    setactivateClubObj,
    govIdFocused,
    referenceInformation,
    payDayFrequencyOptions,
    relationshipOptions,
    setIsPrimaryNotSet,
    isPrimaryNotSet,
    btnClicked, 
    setBtnClicked
  } = useContext(UpdateCustomerContext);

  const { featureFlagDetails } = useContext(FeatureFlagContext);

  const search = useLocation().search;
  const { customerId, pageType } = useParams<ParamType>();
  const salesLeadIdQuery: any = new URLSearchParams(search).get('salesLeadId') || '';
  const salesLeadId = getCustomerInfoResponse?.GetCustomer?.value?.salesLeadId;
  const loginStore = sessionStorage.getItem("storeNumber");

  const history = useHistory();
  const classes = updateCustomerStyles();

  const canShowButton =
    !legalInformation ||
    !legalInformation.legalHoldStatus ||
    (legalInformation.legalHoldStatus &&
      (currentRole === "RD" || currentRole === "DM"));

  const agreementRedirectPayload = {
    setCreateAgreementClick,
    customerInformation: customerBasicInformation,
    featureFlagDetails,
    activeAddress,
    setcomingSoonPopup,
    setValidateResult,
    isCustomerInfoUpdate,
    setIsCustomerInfoUpdate,
    setIsRequired,
    residenceInfo,
    isMaskedDataHasChanged,
    activetab,
    salesLeadIdQuery,
    setErrorPopup,
    setisAuth,
    isAuth,
    setScreenLoader,
    federalTaxId,
    taxToggle,
    salesLeadId,
    setrestatementPopUp,
    setcreateCoCustomerPopUp,
    setShowUpdatePopUp,
    setisSuccess,
    contactInformation,
    taxExemptInformation,
    skipStolenHardStatus,
    bestTimeToCallOptions,
    phoneTypeOptions,
    employerDetails,
    vehicleInformation,
    communicationInformation,
    setSecondLevelAuthenOn,
    customerId, pageType,
    history, configurations,
    referenceInformation,
    payDayFrequencyOptions,
    relationshipOptions,
    setIsPrimaryNotSet,
    isPrimaryNotSet
  }

  const redirectToPreviousPage = () => {
    try {
      const previousPageUrl = document.referrer;
      if (updateOccured) {
      } else if (previousPageUrl && previousPageUrl.includes("am/customer")) {
        history.push(`${AppRoute.accountManagementCustomer}/${customerId}`);
      } else {
        history.push(`/dashboard`);
      }
    } catch (error: any) {
      console.log("Error in redirectToPreviousPage: ", error?.message);
      history.push(`/dashboard`);
    }
  };

  const printOrderForm = async (): Promise<void> => {
    try {
      let coCustomerInfo;
      if (
        (customerBasicInformation?.coCustomerId &&
          !coCustomerInformation?.customerId) ||
        (customerBasicInformation?.coCustomerId &&
          coCustomerInformation?.customerId !==
          customerBasicInformation?.coCustomerId)
      ) {
        setScreenLoader(true);
        let coCustomerResponse = await GetCustomer(
          customerBasicInformation.coCustomerId,
          LOGGED_IN_STORE
        );
        if (
          validateApiResponse(coCustomerResponse) &&
          coCustomerResponse.data?.GetCustomer?.value
        ) {
          coCustomerResponse = coCustomerResponse.data?.GetCustomer?.value;
        }
        setCoCustomerInformation(coCustomerResponse);
        coCustomerInfo = coCustomerResponse;
        setScreenLoader(false);
      }

      const orderFormHtml = generateOrderForm(
        contactInformation,
        customerBasicInformation,
        activeAddress,
        employerDetails,
        residenceInfo,
        referenceInformation,
        coCustomerInfo,
        govIdFocused
      );

      const a: any = window.open(
        "",
        "",
        "width=1600,height=650,scrollbars=1,menuBar=1"
      );
      a.document.write(orderFormHtml);
      a.document.close();
      a.print();
    } catch (error: any) {
      setScreenLoader(false);
      console.log("Error in printOrderForm: ", error?.message);
    }
  };

  const agreementMenu = () => {
    if (createAgrClicked) {
      setcreateAgrClicked(false);
    } else {
      setcreateAgrClicked(true);
    }
    if (PopupClass === `${classes.customMenuContainer} ${classes.popUpHide}`) {
      setPopupClass(`${classes.customMenuContainer} ${classes.popUp}`);
    }
    if (PopupClass === `${classes.customMenuContainer} ${classes.popUp}`) {
      setPopupClass(`${classes.customMenuContainer} ${classes.popUpHide}`);
    }
  };

  const handleEPPAuthentication = (type: string) => {
    setBtnClicked(type);
    setcreateAgrClicked(false)
    if (customerBasicInformation.racCoworker == "Y") {
      if (
        ["DM", "FSC-DM", "RDT", "FSC-RDT", "RD", "FSC-RD"].includes(currentRole)
      ) {
        setSecondLevelAuthenOn("RAC Coworker");
      } else {
        setShowUpdatePopUp(true);
        setCantAccess(true);
      }
    } else {
      callrespfunction(type);
    }
  };

  const callrespfunction = (type: string) => {
    setBtnClicked(null);
    if (type == "AGREEMENT")
      agreementRedirect(agreementRedirectPayload, "AGREEMENT");
    else if (type == "CO") customerOrderRedirection();
    else if (type == "PROMO") promoRedirection();
    else if (type == "ReInstatement")
      agreementRedirect(agreementRedirectPayload, "ReInstatement");
  };

  const customerOrderRedirection = () => {
    history.push(`/agreement/customerorder/itemsearch/${customerId}`);
  };

  const promoRedirection = () => {
    history.push(`/agreement/promo/itemsearch/${customerId}`);
  };

  const splitBtnClick = () => {
    const inActiveAgr = getAgrbyIdresponse?.filter(
      (obj: any) => obj?.agreementRefCode != "ACTIVE"
    );
    if (inActiveAgr?.length > 0) {
      setSplitBlockOpen(true);
    } else {
      history.push(`/agreement/split/${customerId}`);
    }
  };

  const seconduthenResponses = (e: any) => {
    if (e === true && btnClicked) {
      setSecondLevelAuthenOn(null);
      callrespfunction(btnClicked)
    }
}

  return (
    <Fragment key={"UpdateCustomerFooter"}>
      <Grid
        key={"FooterHolder"}
        id={"FooterHolder"}
        container
        style={{ justifyContent: "space-between" }}
        className={classes.fixedFooter}
      >
        <Grid key={"CancelBtnHolder"} id={"CancelBtnHolder"} item>
          <RACButton
            key={"footerCancelBtn"}
            id={"footerCancelBtn"}
            variant="outlined"
            color="primary"
            className={`${classes.mr4}`}
            onClick={() => {
              redirectToPreviousPage();
            }}
          >
            Cancel
          </RACButton>
        </Grid>

        <Grid
          key={"SplitBtnHolder"}
          id={"SplitBtnHolder"}
          item
          style={{ float: "right", justifyContent: "space-between" }}
        >
          {showSplit &&
            activetab == 5 &&
            featureFlagDetails?.SplitAgreement == 1 &&
            canShowButton ? (
            <Grid
              title={splitCoCustomerAvailble ? "" : "Co-Customer not found"}
              className={`${classes.floatLeft}`}
            >
              <RACButton
                key={"SplitBtn"}
                id={"SplitBtn"}
                className={`${classes.mr4}`}
                variant="contained"
                color="primary"
                disabled={splitCoCustomerAvailble ? false : true}
                onClick={() => {
                  splitBtnClick();
                }}
              >
                Split
              </RACButton>
            </Grid>
          ) : null}

          {configurations.agreementTransferApplicable && activetab == 0 ? (
            <RACButton
              key={"TransferAgrBtn"}
              id={"TransferAgrBtn"}
              variant="contained"
              color="primary"
              className={`${classes.floatLeft} ${classes.mr4}`}
              disabled={
                Agrtransferinit || !agreementInformation.transferAppilicable
              }
              onClick={() => history.push(`/agreement/transfer/${customerId}`)}
            >
              Transfer Agreement
            </RACButton>
          ) : null}

          <RACButton
            key={"AmBtn"}
            id={"AmBtn"}
            variant="contained"
            color="primary"
            className={`${classes.floatLeft} ${classes.mr4}`}
            disabled={agreementTransferInitiated}
            onClick={() => {
              history.push({
                pathname: `${AppRoute.accountManagementCustomer}/${customerId}`,
                state: {
                  redirectSource: "customer",
                },
              });
            }}
          >
            Account Management
          </RACButton>

          {customerId !== null ?
            (
              (!legalInformation || !legalInformation.legalHoldStatus) ? (
                <Grid
                  item
                  className={`${classes.floatLeft} ${classes.mr4} ${classes.pr}`}
                  role="group"
                >
                  {' '}
                  <RACButton
                    variant="contained"
                    color="primary"
                    disabled={Agrtransferinit}
                    onClick={() => agreementMenu()}
                  >
                    Create Agreement
                    <span className={classes.dropdowntoggle}></span>
                  </RACButton>
                  {createAgrClicked ? (
                    <Grid
                      className={
                        checkAction == ''
                          ? `${classes.hideRental}`
                          : `${classes.popupRental}`
                      }
                    >
                      <List
                        data-testid="UlButtonsId"
                        aria-labelledby="btnGroupDrop1"
                        style={{
                          left: '947 !important',
                          top: '-35px !important',
                        }}
                      >
                        <ListItem>
                          <a
                            className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                            data-bs-toggle="modal"
                            data-testid="TerminateLDWId"
                            onClick={() => handleEPPAuthentication('AGREEMENT')}
                            data-bs-target="#terminateldw"
                          >
                            Rental
                          </a>
                        </ListItem>
                        {featureFlagDetails?.CustomerOrder == '1' ?
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-bs-toggle="modal"
                              data-testid="TerminateLDWId"
                              onClick={() => handleEPPAuthentication('CO')}
                              data-bs-target="#terminateldw"
                            >
                              Customer order
                            </a>
                          </ListItem>
                          : null}
                        {featureFlagDetails?.PromoAgreement == '1' ?
                          <ListItem>
                            <a
                              className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                              data-bs-toggle="modal"
                              data-testid="TerminateLDWId"
                              onClick={() => handleEPPAuthentication('PROMO')}
                              data-bs-target="#terminateldw"
                            >
                              Promo
                            </a>
                          </ListItem>
                          : null}
                        {
                          checkAction && configurations.reinstatementAllowed ? (
                            <ListItem>
                              <a
                                className={`${classes.dropdownitemcustom} ${classes.semiBold} ${classes.bgBlue}`}
                                data-testid="ActiveLDWId"
                                data-bs-toggle="modal"
                                onClick={() => {
                                  handleEPPAuthentication('ReInstatement');
                                }}
                                data-bs-target="#terminateldw"
                              >
                                Reinstatement
                              </a>
                            </ListItem>
                          ) : null}
                      </List>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null
            )
            : null}

          {canShowButton ?
            <RACButton
              key={"TakePymBtn"}
              id={"TakePymBtn"}
              variant="contained"
              color="primary"
              className={`${classes.mr4}`}
              disabled={agreementTransferInitiated}
              onClick={() =>
                agreementRedirect(agreementRedirectPayload, 'PAYMENT')
              }
            >
              Take Payment
            </RACButton> : null}

          {canShowButton ? (
            <RACButton
              key={"PrintOrderBtn"}
              id={"PrintOrderBtn"}
              className={`${classes.mr4}`}
              variant="contained"
              color="primary"
              onClick={() => printOrderForm()}
            >
              Print Order Form
            </RACButton>
          ) : null}

          {canShowButton &&
            clubInfo?.clubStatus ? (
            (['Active', 'A', 'P'].includes(clubInfo?.clubStatus)) ? (
              <RACButton
                variant="contained"
                color="primary"
                className={classes.mr4}
                onClick={() => setactivateClubObj(true)}
                disabled={
                  Agrtransferinit ||
                  (clubInfo?.originStoreNumber == loginStore
                    ? false
                    : true)
                }
              >
                {'Club Is Active'}
              </RACButton>
            ) : (
              <RACButton
                variant="contained"
                color="primary"
                className={classes.mr4}
                disabled={true}
              >
                {'Club Is Not Active'}
              </RACButton>
            )
          ) : null}

        </Grid>
      </Grid>
      <FooterPopUp />
      {secondLevelAuthenOn !== null ? (
        <SecondFactor
          setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
          setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
          moduleName={secondLevelAuthenOn}
          currentRole={currentRole}
        ></SecondFactor>
      ) : null}
    </Fragment>
  );
}
