/* eslint-disable prettier/prettier */
import React from "react";
import {
  Grid,
  RACTable,
  RACTableCell,
  RACTableRow,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import {
  AddressDetails,
  AddressHistoryObject,
} from "../../../interface/globalInterface";
import { AddressHistoryHeader } from "./../../../constants/constants";
import { formatAddress, trimContent } from "./AddressInfoUtils";
// import { ContactInfoPropsObject } from '../../../interface/GlobalInterface';

export default function AddressHistoryModal(props: AddressHistoryObject) {
  const classes = globalStyles();
  // eslint-disable-next-line no-console

  const renderAddressHeader = () => {
    return (
      <>
        {AddressHistoryHeader.map((value: string, index: number) => {
          return <RACTableCell key={index} className={`${classes.borderBottom}`}>{value}</RACTableCell>;
        })}
      </>
    );
  };

  const renderAddressBody = () => {
    return props.value.length > 0 ? (
      <>
        {props.value.map((element: AddressDetails, index: number) => (
          <RACTableRow
            key={index}
            className={`${classes.tableBackgroundColor}`}
          >
            <RACTableCell>{element.addressTypeDesc}</RACTableCell>
            <RACTableCell title={element.agreementNumber}>
              {element.agreementNumbersCount}
            </RACTableCell>
            <RACTableCell>{element.storeNumber}</RACTableCell>
            <RACTableCell title={element.addressLine1.length > 20 ? element.addressLine1 : ''}>{trimContent(element.addressLine1, 20)}</RACTableCell>
            <RACTableCell title={element.addressLine1.length > 20 ? element.addressLine2 : ''}>{trimContent(element.addressLine2, 20)}</RACTableCell>
            <RACTableCell>{element.postalCode}</RACTableCell>
            <RACTableCell>{element.city}</RACTableCell>
            <RACTableCell>{element.state}</RACTableCell>
            <RACTableCell>{formatAddress(element.lastModifiedDate)}</RACTableCell>
            <RACTableCell title={element.lastModifiedBy.length > 20 ? element.lastModifiedBy : ''}>{trimContent(element.lastModifiedBy, 20)}</RACTableCell>
          </RACTableRow>
        ))}
      </>
    ) : (
      <RACTableRow className={`${classes.tableBackgroundColor}`}>
        <RACTableCell colSpan={10}>No Records Found</RACTableCell>
      </RACTableRow>
    );
  };

  return (
    <Grid container className={`${classes.width100p}`}>
      <RACTable
        renderTableHead={() => renderAddressHeader()}
        renderTableContent={() => renderAddressBody()}
      />
    </Grid>
  );
}
