/* eslint-disable */
import React, { useContext, useState } from 'react';
import { Grid } from '@rentacenter/racstrap';
import { ContactInfoHeaderProps, ResidenceInfo } from '../../../interface/globalInterface';
import ContactInfoHeader from '../ContactInfoHeader';
import ResidenceDetails from './ResidenceDetails';
import { UpdateCustomerContext } from '../../../context/UpdateCustomerContext';
import { globalStyles } from '../../../jsStyles/globalStyles';

export default function ResidenceInformation() {
  const classes = globalStyles();
  const { residenceInfo, setResidenceInfo } = useContext(UpdateCustomerContext)

  const contentHeading: ContactInfoHeaderProps = {
    heading: "Residence",
    popupHeading: '',
    popupFunctionality: null,
    params: null,
    infoIcon: true,
    infoIconContent: 'Recent Utility bill or copy of lease'
  };

  return (
    <Grid container className={`${classes.pt05p}`}>
      <Grid container>
        <ContactInfoHeader value={contentHeading} />
        <ResidenceDetails value={residenceInfo} setterData={{ residenceInfo, setResidenceInfo }} />
      </Grid>
    </Grid>
  );
}
