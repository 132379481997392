/* eslint-disable */
import { Card, CardContent, CircularProgress, Grid, RACTable, RACTableCell, RACTableRow, Typography } from "@rentacenter/racstrap";
import { manageCustomerStyles } from "../../../jsStyles/manageCustomerStyles";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useContext, useEffect } from "react";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { CustomerApproval, DEHistoryRequest } from "../../../interface/globalInterface";
import { DEHistoryHeader } from "../../../constants/constants";
import moment from "moment";
import { getDEHistory } from "../../../apiHandler/updateCustomerApiHandler";

export function DEHistory() {
    const classes = manageCustomerStyles();
    const { customerBasicInformation, setDEHistoryResponse, dEHistoryResponse,
        sethasMore, hasMore, deHistoryQueryParams, setdeHistoryQueryParams, dEHistoryPageDisplay, setDEHistoryPageDisplay
    } = useContext(UpdateCustomerContext);

    useEffect(() => {
    // If DE History data is already available in the context variable, then API call will be skipped.
        if (dEHistoryResponse && dEHistoryResponse.length == 0 && dEHistoryPageDisplay !== 200) {
            setDEHistoryPageDisplay(1);
            getDEHistoryData({
                globalCustomerId: customerBasicInformation?.globalCustomerId,
                limit: '',
                offset: '',
            })
        }
    }, [])

    // Function for fetching the customer DE History data
    async function getDEHistoryData(request: DEHistoryRequest) {
        try {
            const existingData: CustomerApproval[] = dEHistoryResponse;
            const payload: DEHistoryRequest = {
                globalCustomerId: request.globalCustomerId,
                limit: request.limit || '',
                offset: request.offset || '',
            };
            const dEResponse: CustomerApproval[] = await getDEHistory(payload);
            setDEHistoryPageDisplay(200);
            setDEHistoryResponse([...existingData, ...dEResponse]);

            return dEResponse;
        } catch (error) {
            setDEHistoryPageDisplay(500);
            return []
        }
    }

    const formatCurrency = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD'
    });

    // Function for infinite scroll
    const nextscroll = async () => {
        try {
            setDEHistoryPageDisplay(2);
            setdeHistoryQueryParams({
                limit: deHistoryQueryParams?.limit + 10,
                offset: deHistoryQueryParams?.offset + 10,
            });
            const payload: DEHistoryRequest = {
                globalCustomerId: customerBasicInformation?.globalCustomerId,
                limit: deHistoryQueryParams?.limit + 10,
                offset: deHistoryQueryParams?.offset + 10,
            };
            const dEResponse: CustomerApproval[] = await getDEHistoryData(payload);

            if (dEResponse?.length >= 40) {
                sethasMore(false);
            }
            setDEHistoryPageDisplay(0);
        } catch (err) {
            if (dEHistoryResponse?.length > 0) {
                sethasMore(false);
            }
            setDEHistoryPageDisplay(0);
        }
    };

    // For rendering the header content of the DE History tab
    const renderDEHistoryHeader = () => (
        <>
            {DEHistoryHeader.map((col: string, index: number) => {
                return (
                    <RACTableCell
                        className={`${classes.textCenter} ${classes.lineBreak} ${classes.customerTablecellbgcolor}`}
                        key={index}
                    >
                        {col}
                    </RACTableCell>
                );
            })}
        </>
    );

    // For rendering the body content of the DE History tab
    const renderDEHistoryContent = () => {
        return dEHistoryResponse.map((val: CustomerApproval, index: number) => {
            return (
                <RACTableRow key={index} className={classes.customerTablecellbgcolor}>
                    <RACTableCell className={`${classes.textCenter}`}>
                        {val.approvalDate ? moment(val.approvalDate).format('MM/DD/YYYY') : ''}
                    </RACTableCell>
                    <RACTableCell className={`${classes.textCenter}`}>
                        {val.approvalStatusDesc ? val.approvalStatusDesc : ''}
                    </RACTableCell>
                    <RACTableCell className={`${classes.textCenter}`}>
                        {val.originalApprovalAmount ? `${formatCurrency.format(val.originalApprovalAmount)}` : ''}
                    </RACTableCell>
                    <RACTableCell className={`${classes.textCenter}`}>
                        {val.weeklyOriginalApprovalAmount ? `${formatCurrency.format(val.weeklyOriginalApprovalAmount)}` : ''}
                    </RACTableCell>
                    <RACTableCell className={`${classes.textCenter}`}>
                        {val.createdBy ? val.createdBy : ''}
                    </RACTableCell>
                    <RACTableCell className={`${classes.textCenter}`}>
                        {val.approvalExpirationDate ? moment(val.approvalExpirationDate).format('MM/DD/YYYY') : ''}
                    </RACTableCell>
                </RACTableRow>
            );
        });
    };
    
    // For rendering the no records content in DE History tab body
    const renderContentNoDEHistoryRecordFound = () => {
        return (
            <RACTableRow hover backgroundColor="white">
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell style={{ textAlign: 'center' }}>
                    No Records Found
                </RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
            </RACTableRow>
        );
    };

    const renderDEHistoryData = () => {
        return dEHistoryResponse && dEHistoryResponse.length > 0
            ? renderDEHistoryContent()
            : renderContentNoDEHistoryRecordFound();
    };

    // For rendering the table head and body of DE History Tab
    const infiniteScrollDEHistory = () => {
        return (
            <Grid className={`${classes.fixTableHead} ${classes.fixTableHeight400}`}>
                {dEHistoryResponse?.length > 0 ? (
                    <InfiniteScroll
                        dataLength={dEHistoryResponse?.length}
                        next={nextscroll}
                        hasMore={hasMore}
                        height={400}
                        loader={
                            dEHistoryPageDisplay == 2 ? (
                                <Grid className={classes.GridLoader}>
                                    <CircularProgress></CircularProgress>
                                </Grid>
                            ) : null
                        }
                    >
                        <RACTable
                            renderTableHead={renderDEHistoryHeader}
                            renderTableContent={renderDEHistoryData}
                            stickyHeader
                        />
                    </InfiniteScroll>
                ) : (
                    <RACTable
                        renderTableHead={renderDEHistoryHeader}
                        renderTableContent={renderDEHistoryData}
                    />
                )}
            </Grid>
        );
    };

    // DE History Html rendering part
    return (
        <>
            <Grid container id="dehistoryinfo" className={`${classes.mb16} ${classes.px2}`}>
                <Grid item md={12}>
                    <Typography className={classes.title}>DE History</Typography>
                </Grid>
                <Grid item md={12} className={classes.mb120}>
                    <Card className={`${classes.card} ${classes.w100}`}>
                        <CardContent>
                            <Grid md={12}>
                                {dEHistoryPageDisplay == 1 ? (
                                    <Grid md={12} className={classes.textCenter}>
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {infiniteScrollDEHistory()}
                                    </>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}