/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useContext } from "react";
import { Grid, RACSelect, RACTextbox, Typography } from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { EmployersInfoProps } from "../../../interface/globalInterface";
import { isDropDownValueExist, updateEmploymentDetails } from "../Utils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { DEFAULT_DROPDOWN_OPTION } from "../../../constants/constants";

export default function IncomeVerification({
  value,
  setterData,
}: EmployersInfoProps) {
  const classes = globalStyles();
  const { index, errorMessage } = setterData;
  const Agrtransferinit = false;
  const { payDayFrequencyOptions, weeklyDropdownOptions, monthlyDropdownOptions, semiMonthlyDropdownOptions  } = useContext(UpdateCustomerContext);

  return (
    <Grid container spacing={2} className={`${classes.mt10px}`}>
      <Grid item md={12}>
        <Typography
          className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
        >
          Income Verification
        </Typography>
      </Grid>
      <Grid item md={3} lg={2} className={classes.pt0}>
        <RACSelect
          inputLabel="Payday Frequency"
          options={payDayFrequencyOptions}
          defaultValue={
            isDropDownValueExist(
              payDayFrequencyOptions,
              value.incomeVerification.paydayFrequency
            )
              ? value.incomeVerification.paydayFrequency
              : "0"
          }
          required={value.active}
          loading={payDayFrequencyOptions.length == 0 ? true : false}
          isDisabled={
            Agrtransferinit || value.isEditEnabled
              ? true
              : !value.active
          }
          onChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                verifiedDate: "",
                incomeVerification: {
                  ...value.incomeVerification,
                  paydayFrequency: e.target.value,
                  payDayFreqDesc: e.target.name,
                  dayPaid: "",
                },
              }
            );
          }}
          errorMessage={
            errorMessage[index]?.incomeVerification?.paydayFrequency
          }
        />
      </Grid>
      <Grid item md={2} lg={2} className={classes.pt0}>
        <RACSelect
          inputLabel="Day Paid"
          options={
            value.incomeVerification.paydayFrequency == "WK" ||
            value.incomeVerification.paydayFrequency == "BIWK"
              ? weeklyDropdownOptions
              : value.incomeVerification.paydayFrequency == "MON"
              ? monthlyDropdownOptions
              : value.incomeVerification.paydayFrequency == "SEMI"
              ? semiMonthlyDropdownOptions
              : [...DEFAULT_DROPDOWN_OPTION]
          }
          defaultValue={
            isDropDownValueExist(
              value.incomeVerification.paydayFrequency == "WK" ||
                value.incomeVerification.paydayFrequency == "BIWK"
                ? weeklyDropdownOptions
                : value.incomeVerification.paydayFrequency == "MON"
                ? monthlyDropdownOptions
                : value.incomeVerification.paydayFrequency == "SEMI"
                ? semiMonthlyDropdownOptions
                : [...DEFAULT_DROPDOWN_OPTION],
              value.incomeVerification.dayPaid
            )
              ? value.incomeVerification.dayPaid
              : "0"
          }
          required={
            value.active &&
            value.incomeVerification
              .paydayFrequency &&
            value.incomeVerification
              .paydayFrequency != "0" &&
            value.incomeVerification
              .paydayFrequency != "DAILY"
              ? true
              : false
          }
          isDisabled={
            Agrtransferinit || value.isEditEnabled
              ? true
              : !value.active ||
                value.incomeVerification
                  .paydayFrequency == "DAILY" ||
                !value.incomeVerification
                  .paydayFrequency ||
                value.incomeVerification
                  .paydayFrequency == "0"
          }
          onChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                verifiedDate: "",
                incomeVerification: {
                  ...value.incomeVerification,
                  dayPaid: e.target.value,
                  daysPaidDesc: e.target.name,
                },
              }
            );
          }}
          errorMessage={errorMessage[index]?.incomeVerification?.dayPaid}
        />
      </Grid>
      <Grid item md={2} lg={2} className={classes.pt0}>
        <RACTextbox
          type={"number"}
          isCurrency={true}
          digitFormat={"currency"}
          inputlabel="Take-Home Pay"
          maxlength={6}
          OnChange={(e: any) => {
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                incomeVerification: {
                  ...value
                    .incomeVerification,
                  takeHomePay: e.target.value,
                },
              }
            );
          }}
          value={value.incomeVerification
                  .takeHomePay
                }
          disabled={
            Agrtransferinit || value.isEditEnabled
              ? true
              : !value.active
          }
          className={classes.currentTextBoxStyle}
          dollarTextClassName={classes.customerDollerInputFiled}
        />
      </Grid>
    </Grid>
  );
}