/* eslint-disable */
import { EMPLOYEMNET_UPDATE_REQUEST, EMPTY_STRING, STRING_ZERO } from "../../../../constants/constants";
import {
  AddressDetailsBinder,
  EmployerDetails,
  ResidenceInfo,
  VehicleInfomation,
} from "../../../../interface/globalInterface";
import { ContactInformations, ReferenceInformation } from "../../../../interface/updateCustomerInterface";
import {
  buildDate,
  validateEmail,
  validateValue,
} from "../../../../utils/utils";
import { isDropDownValueExist } from "../../../sharedComponents/Utils";

export const validateTabOne = (props: any, phoneValidNotReq?: boolean) => {
  let {
    customerInformation,
    federalTaxId,
    taxToggle,
    taxExemptInformation,
    activeAddress,
    contactInformation,
    setIsPrimaryNotSet
  } = props;
  let isValidate = true;

  isValidate = validateValue(customerInformation.firstName);

  if (isValidate !== false) {
    isValidate = validateValue(customerInformation.lastName);
  }
  if (isValidate !== false) {
    isValidate = validateValue(customerInformation.emailAddress);
    isValidate =
      isValidate !== false
        ? validateEmail(customerInformation?.emailAddress)
        : isValidate;
  }
  if (isValidate !== false) {
    isValidate = validateValue(customerInformation.dateOfBirth);
  }
  if (isValidate !== false) {
    isValidate = validateAddresses(activeAddress);
  }

  const phoneValidate: any = validatePhone(
    contactInformation,
    phoneValidNotReq
  );
  isValidate = isValidate ? phoneValidate.isValidate : isValidate;

  if ((federalTaxId || taxToggle) && isValidate !== false) {
    isValidate = validateValue(taxExemptInformation.federalTaxId);
  }
  if (phoneValidate.isPrimaryValid === false && phoneValidNotReq !== true) {
    isValidate = false;
    setIsPrimaryNotSet("Customer must have atleast one primary phone number.")
  }
  return isValidate;
};

export const validateTabTwo = (
  employerDetails: EmployerDetails[],
  createClick?: boolean
) => {
  let isValidate = true;
  let activeEmployerCount = 0;

  employerDetails.forEach((val: EmployerDetails) => {
    if (val.active) {
      let count = 0;

      // Validate company name
      if (!validateValue(val.basicInfo.companyName)) {
        isValidate = false;
        count++;
      }

      // Validate phone number length
      const phoneNumberLength = val.basicInfo.phoneNumber?.length || 0;
      if (phoneNumberLength < 10) {
        isValidate = false;
        count++;
      }

      // Validate payday frequency
      if (
        !val.incomeVerification.paydayFrequency ||
        val.incomeVerification.paydayFrequency === "0"
      ) {
        isValidate = false;
        count++;
      }

      // Validate payment day if payday frequency is not DAILY
      if (
        val.incomeVerification.paydayFrequency !== "DAILY" &&
        (!val.incomeVerification.dayPaid ||
          val.incomeVerification.dayPaid === "0")
      ) {
        isValidate = false;
      }

      // Validate ZIP code length
      const zipLength = val.addressInfo.zip?.length || 0;
      if (zipLength > 0 && zipLength !== 5 && zipLength !== 10) {
        isValidate = false;
        count++;
      }

      // Increment count of valid employers
      if (count === 4) {
        activeEmployerCount++;
      }
    }
  });

  const updatedEmpInfo =
    createClick === undefined || activeEmployerCount !== employerDetails.length;

  return {
    isValidate: updatedEmpInfo ? isValidate : true,
    UpdatedInfo: updatedEmpInfo,
  };
};

export const validateTabThree = (
  residenceInfo: ResidenceInfo,
  createClick?: boolean
) => {
  let count = 0;
  let isValidObj = true;
  const { rentalType, rentalDetails, mortgage, ownResidenceDetails } = residenceInfo;

  // Validation for RENT type
  if (rentalType === "RENT") {
    const phoneNumberLength = rentalDetails.landlordsPhoneNumber?.length || 0;

    if (phoneNumberLength < 10) {
      isValidObj = false;
      count++;
    }

    if (!validateValue(rentalDetails.landlordsFirstName)) {
      isValidObj = false;
      count++;
    }

    if (!validateValue(rentalDetails.landlordsLastName)) {
      isValidObj = false;
      count++;
    }
  }

  // Validation for OWN type with mortgage
  if (
    rentalType === "OWN" &&
    mortgage &&
    !validateValue(ownResidenceDetails.mortgageCompanyName)
  ) {
    isValidObj = false;
    count = 3; // Set count to 3 to indicate a specific validation failure
  }

  // Determine if updated info is valid
  const updatedInfo =
    createClick === undefined
      ? true
      : (count === 3 && rentalType === "RENT") ||
        (count === 1 && rentalType !== "RENT" && mortgage)
        ? false
        : true;

  return {
    isValidate: updatedInfo ? isValidObj : true,
    UpdatedInfo: updatedInfo,
  };
};

export const validateTabFour = (referenceInformation: ReferenceInformation[], createClick?: boolean) => {
  let isValid = true;
  let count = 0;
  let activeReferenceCount = 0;
  console.log(referenceInformation, ' referenceInformation build')
  if (referenceInformation && referenceInformation.length > 0) {
    referenceInformation.forEach((element: ReferenceInformation) => {
      if (element.active) {

        const { phoneNumber, relativeFirstName, relativeLastName, relationShip } = element.referenceInfo;
        const { zip } = element.referenceAddressInfo;

        // Validate phone number length
        if (!phoneNumber || phoneNumber.length < 10) {
          isValid = false;
          count++;
        }

        // Validate names
        if (!validateValue(relativeFirstName) || !validateValue(relativeLastName)) {
          isValid = false;
          count++;
        }

        // Validate relationship
        if (!relationShip || relationShip === '0') {
          isValid = false;
          count++;
        }

        // Validate zip code length
        if (zip && zip.length !== 5 && zip.length !== 10) {
          isValid = false;
          count++;
        }
      }
      activeReferenceCount = count === 4 ? activeReferenceCount + 1 : activeReferenceCount;
    });
  }
  const updatedInfo = createClick === undefined ? true : activeReferenceCount !== referenceInformation.length;
  console.log(updatedInfo, 'updatedInfo reference')

  return {
    isValidate: updatedInfo ? isValid : true,
    UpdatedInfo: updatedInfo,
  };
};

export const validateAddresses = (activeAddress: AddressDetailsBinder[]) => {
  let isValidate = true;
  activeAddress?.forEach((val: AddressDetailsBinder) => {
    if (!val.isEmptyMailAddress) {
      if (!validateValue(val.addressLine1)) {
        isValidate = false;
      } else if (
        !validateValue(val.zipCode) ||
        (val.zipCode && val.zipCode?.length != 5 && val.zipCode?.length != 10)
      ) {
        isValidate = false;
      } else if (!validateValue(val.city)) {
        isValidate = false;
      } else if (!validateValue(val.state) || val.state == "0") {
        isValidate = false;
      }
    }
  });
  return isValidate;
};

export const validatePhone = (
  contactInformation: ContactInformations[],
  phoneValidNotReq?: boolean
) => {
  let isPrimaryValid = false;
  let isValidate = true;
  if (phoneValidNotReq !== true) {
    contactInformation?.forEach((val: ContactInformations) => {
      if (!val.phoneNumber || val.phoneNumber?.length < 10) {
        isValidate = false;
      }
      if (!val.callTimeType || val.callTimeType == "0") {
        isValidate = false;
      }
      if (!val.phoneType || val.phoneType == "0") {
        isValidate = false;
      }
      if (!val.phoneType || val.phoneType == "0") {
        isValidate = false;
      }
      if (val.primary == "Y" && isPrimaryValid === false) {
        isPrimaryValid = true;
      }
    });
  }
  return { isPrimaryValid, isValidate };
};

export const buildAddressPayload = (
  activeAddress: AddressDetailsBinder[],
  customerId: string
) => {
  let addressPayload: any;
  const addressToAdd: any = [];
  const address = activeAddress || [];
  const primaryAddress = address.filter((val: AddressDetailsBinder) => val.addressType == "PRIM")
  address.forEach((val: AddressDetailsBinder) => {
    if (!val.showAddress) {
      addressToAdd.push({
        city: primaryAddress[0]?.city,
        addressType: "MAIL",
        postalCode: primaryAddress[0]?.zipCode,
        active: "Y",
        addressLine1: primaryAddress[0]?.addressLine1,
        addressLine2: primaryAddress[0]?.addressLine2,
        state: primaryAddress[0]?.state,
        agreementIds: [],
        ...(val.addressId && val.addressId != 0 ? { addressId: val.addressId } : {}),
      });
    } else {
      addressToAdd.push({
        city: val.city,
        addressType: val.addressType,
        postalCode: val.zipCode,
        active: "Y",
        addressLine1: val.addressLine1,
        addressLine2: val.addressLine2,
        state: val.state,
        agreementIds: val.agreementIds,
        addressId: val.addressId,
      });
    }
  });

  addressPayload = {
    customerId: customerId,
    addresses: addressToAdd,
  };
  return addressPayload;
};

export const buildPhones = (props: any) => {
  const { contactInformation, bestTimeToCallOptions, phoneTypeOptions } = props;
  console.log(contactInformation, 'contactInformation in build phones');
  const updatedPhoneArray: any = [];
  if (contactInformation && contactInformation.length > 0) {
    contactInformation.forEach((val: ContactInformations) => {
      const phoneInfo: any = {};
      phoneInfo.phoneId = val.phoneId || "";
      phoneInfo.phoneNumber = val.phoneNumber
        ? val.phoneNumber.replace(/\D/g, "")
        : EMPTY_STRING;
      phoneInfo.phoneType = val.phoneType && isDropDownValueExist(phoneTypeOptions, val.phoneType)
        ? val?.phoneType
        : "";
      phoneInfo.phoneTypeDesc = val.callTimeType &&
        isDropDownValueExist(phoneTypeOptions, val.phoneType)
        ? val?.phoneTypeDesc
        : "";
      phoneInfo.extension = val.extension,
        phoneInfo.primary = val.primary,
        phoneInfo.callTimeType = val.callTimeType &&
          isDropDownValueExist(bestTimeToCallOptions, val.callTimeType)
          ? val.callTimeType
          : "";
      phoneInfo.callTimeTypeDesc = val.callTimeType &&
        isDropDownValueExist(bestTimeToCallOptions, val.callTimeType)
        ? val.callTimeTypeDesc
        : "";
      phoneInfo.active = val.active;
      phoneInfo.note = val.instructions;
      phoneInfo.isNDNCOpted = val.isNDNCOpted;
      phoneInfo.isTrnsMsgAllowed = val.isTrnsMsgAllowed;
      phoneInfo.isMktgMsgAllowed = val.isMktgMsgAllowed;
      phoneInfo.textMessageReminderOri = val.textMessageReminderOri;
      phoneInfo.textMarketingOffersOri = val.textMarketingOffersOri;
      phoneInfo.phoneNumberOri = val.phoneNumberOri;

      updatedPhoneArray.push(phoneInfo);
    });
  }
  return updatedPhoneArray;
};

export const landlordReferences = (residenceInfo: ResidenceInfo) => {
  const { rentalType, rentalDetails, verifiedDate } = residenceInfo;
  let residenceBuildObj: any = [];
  try {
    residenceBuildObj = [
      {
        landlordFirstName: rentalDetails.landlordsFirstName,
        landlordLastName: rentalDetails.landlordsLastName,
        phoneNumber:
          rentalDetails.landlordsPhoneNumber?.replace(/\D/g, "") || "",
        monthlyRent: rentalDetails.paymentAmount?.replace(",", "") || "0.00",
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        postalCode: "",
        phoneExtension: null,
        leaseHolderName: null,
        bestTimeToCall: null,
        leaseCompanyName: null,
        leaseTerm: null,
        leaseLengthYears: "0.000000",
        leaseLengthMonths: "0.000000",
        verifiedDate: buildDate(verifiedDate || "") || "",
        active: rentalType == "RENT" ? "Y" : "N",
        moveInDate: buildDate(rentalDetails.moveInDate || "") || null,
      },
    ];
  } catch (e) {
    console.log(e, "landlordReferences");
  }
  return residenceBuildObj;
};

export const buildEmpObj = (
  employerDetails: EmployerDetails[],
  payDayfrequency: any
) => {
  console.log(employerDetails, 'employerDetails in buildldldl')
  return employerDetails.map((val: EmployerDetails) => {
    const { basicInfo, incomeVerification, addressInfo } = val;
    const { companyName, phoneNumber, employmentYears, doNotCallEnabled } =
      basicInfo || {};
    const { takeHomePay, paydayFrequency, dayPaid } = incomeVerification || {};
    const { addressLine1, addressLine2, zip, city, state, doNotVisit } =
      addressInfo || {};
    const isActive = val.active == 'Y' ? true : false;

    return {
      ...EMPLOYEMNET_UPDATE_REQUEST,
      employerName: companyName || null,
      takeHomePay:
        takeHomePay && takeHomePay !== "NaN"
          ? takeHomePay.replace(",", "")
          : isActive ? STRING_ZERO : null,
      employerPhoneNumber: phoneNumber
        ? phoneNumber.replace(/\D/g, "")
        : null,
      addressLine1: addressLine1 || "",
      addressLine2: addressLine2 || "",
      postalCode: !isActive ?
        (zip && (zip.length === 5 || zip.length === 9)
          ? zip : EMPTY_STRING) : (zip || EMPTY_STRING),
      city: city || "",
      state: state && state !== "0" ? state : "",
      employerPayschedule:
        paydayFrequency &&
          isDropDownValueExist(payDayfrequency, paydayFrequency)
          ? paydayFrequency
          : null,
      employerPayscheduleDesc:
        paydayFrequency &&
          isDropDownValueExist(payDayfrequency, paydayFrequency)
          ? incomeVerification.payDayFreqDesc
          : null,
      daysPaidDesc:
        dayPaid && isDropDownValueExist(payDayfrequency, dayPaid)
          ? incomeVerification.daysPaidDesc
          : null,
      lengthOfEmploymentYears: employmentYears || STRING_ZERO,
      verifiedDate: val.verifiedDate ? buildDate(val.verifiedDate) : "",
      employerReferenceId: val.employerDetailsId || "",
      doNotCall: doNotCallEnabled !== true ? "N" : "Y",
      doNotVisit: doNotVisit !== true ? "N" : "Y",
      active: val.active
    };
  });
};

export const buildVechicle = (vehicleInformation: VehicleInfomation[]) => {
  const vechicleObj: any = [];
  if (vehicleInformation && vehicleInformation.length > 0) {
    vehicleInformation.forEach((val: VehicleInfomation) => {
      if (val.licensePlate) {
        const payload = {
          customerVehicleId: val.vehicleId,
          vehicleIndex: "",
          vehicleVin: val.vehicleVin,
          vehicleMake: val.make,
          vehicleModel: val.model,
          vehicleYear: val.year,
          vehicleColor: val.color,
          vehicleLicensePlate: val.licensePlate,
          vehPlanExpirationDate: buildDate(val.planExpirationDate || ""),
          vehFinancedFrom: val.financedFrom,
          vehMonthlyPayment:
            val.monthlyPayment && val.monthlyPayment !== "NaN"
              ? val.monthlyPayment.replace(",", "")
              : "0",
          vehRegState: val.regState,
          vehicleTagNumber: val.vehicleTagNumber,
        };
        vechicleObj.push(payload);
      }
    });
  }
  return vechicleObj;
};

export const buildReferenceObj = (
  ReferenceInformation: ReferenceInformation[],
  relationshipOptions: any,
  bestTimeToCallOptions: any
) => {
  const referenceObjArray: any = [];
  ReferenceInformation.map((val: ReferenceInformation) => {
    const referenceRequest: any = {};
    const { referenceInfo, referenceAddressInfo, referenceDetailsId } = val;
    const {
      relativeFirstName,
      relativeLastName,
      phoneNumber,
      relationShip,
      bestTimeToCall,
      doNotCall,
      relationshipTypeDesc
    } = referenceInfo;

    const { addressLine1, addressLine2, zip, city, state } = referenceAddressInfo;

    referenceRequest.personalReferenceId = referenceDetailsId || '';
    referenceRequest.verifiedDate = val.verifiedDate ? buildDate(val.verifiedDate) : '';
    referenceRequest.firstName = relativeFirstName || '';
    referenceRequest.lastName = relativeLastName || '';
    referenceRequest.phoneNumber = val.active ? (phoneNumber?.replace(/\D/g, '') || '') : (phoneNumber || '');
    referenceRequest.relationshipType = relationShip && isDropDownValueExist(relationshipOptions, relationShip) ? relationShip : '';
    referenceRequest.relationshipTypeDesc = relationShip && isDropDownValueExist(relationshipOptions, relationShip) ? relationshipTypeDesc : '';
    referenceRequest.bestTimeToCall = bestTimeToCall && isDropDownValueExist(bestTimeToCallOptions, bestTimeToCall) ?
      bestTimeToCall : '';
    referenceRequest.addressLine1 = addressLine1 || '';
    referenceRequest.addressLine2 = addressLine2 || '';
    referenceRequest.postalCode = !val.active ? (zip && (zip.length === 5 || zip.length === 9) ? zip : '') : (zip || '');
    referenceRequest.city = city || '';
    referenceRequest.state = state && state !== '0' ? state : '';
    referenceRequest.active = val.active ? 'Y' : 'N';
    referenceRequest.doNotCall = doNotCall ? 'Y' : 'N';

    referenceObjArray.push(referenceRequest);
  });
  return referenceObjArray;
};
