/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { dropdown } from './ManageCustomerTypeScripts';
import { ReactComponent as RACLogo } from '../../assets/images/RAC-Logo.svg';
//import Alert from '../../assets/images/erroricon.png';
import OtpInput from 'react-otp-input';
import SecondFactor from './secondAuthen';
import {
  GetCustomerByIdInfo,
  GetCustomerInfoOptions,
  GetCustomerAlert,
  GetEmployInfoOptions,
  UpdateAlert,
  GetAgreementById,
  UpdateCustomerInfo,
  GetSuggestedAddress,
  GetLegalSatatus,
  GetActivateClub,
  getCurrentInfo,
  getPinDetails,
  getprofileaccess,
  getsecondfactor,
  PrintFaxSheet,
  GetAlert,
  getmenuandstore,
  GetCustomerCoverage,
} from '../../api/user';
import {
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACRadio,
  RACToggle,
  RACCheckBox,
  RACButton,
  RACModalCard,
  RACTableCell,
  RACTable,
  RACTableRow,
  Card,
  Grid,
  CardContent,
  Typography,
  ListItem,
  List,
  Box,
  CircularProgress,
} from '@rentacenter/racstrap';
import { AddCoCustomer } from './AddCoCustomer';
import { ReactComponent as PreviousBtn } from '../../assets/images/previous-btn-arrow.svg';
import { ReactComponent as Calendar } from '../../assets/images/calendar.svg';
import { ReactComponent as NextBtn } from '../../assets/images/next-btn-arrow.svg';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as ExpandAlert } from '../../assets/images/expand-alert-icon.svg';
import { ReactComponent as ExpandCancel } from '../../assets/images/alert-cancel-icon.svg';
import Modal from 'react-modal';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  API_ERROR_MESSAGE,
  howLongObj,
  relationShipObj,
  buildVechicleYear,
  PHONE_ERROR_MESSAGE,
  INVALID_ZIP,
  FIELD_ERROR,
  agreementHistoryHeader,
} from '../../constants/constants';
interface ParamType {
  customerId: string;
  pageType: string;
}

enum tabList {
  CUSTOMERINFO,
  EMPLOYMENTINFO,
  RESIDENCE,
  REFERENCE,
  VEHICLEINFO,
  AGREEMENTHISTORY,
}
let coWorkerRole = '';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function ManageCustomerInfoRacstrap(props: any) {
  // const useStyles = makeStyles({
  //   card: {
  //     borderRadius: '16px!important',
  //     boxShadow: '0px 1px 4px 0px  #00000013 !important',
  //   },
  //   widthauto: {
  //     width: 'auto',
  //   },
  //   padding0: {
  //     paddingTop: '0px !important',
  //   },
  //   btnprimary: {
  //     borderRadius: '14!important',
  //     backgroundColor: '#2179fe!important',
  //     color: '#fff!important',
  //     textTransform: 'none',
  //     paddingLeft: '20px !important',
  //     paddingRight: '20px !important',
  //   },
  // });
  // const classes = useStyles();
  const { customerId, pageType } = useParams<ParamType>();
  const search = useLocation().search;
  const customerIdQueryParam: any =
    new URLSearchParams(search).get('customerId') !== undefined &&
    new URLSearchParams(search).get('customerId') !== null &&
    new URLSearchParams(search).get('customerId') !== ''
      ? new URLSearchParams(search).get('customerId')
      : null;
  // // eslint-disable-next-line no-console
  // console.log('customerIdQueryParam', customerIdQueryParam);
  const dt = new Date();
  const inactiveTabClass = 'nav-link inactive';

  const activeTavClass = 'nav-link inactive active';
  const completedClass = 'nav-link inactive done';
  const activeTabStyle1 = 'nav-link p-3 active';
  const activeTabStyle2 = 'nav-link p-3';
  const widgardActive = 'widgard-active';
  const widgardCompleted = 'widgard-completed';
  const [activetab, setactivetab] = useState<tabList | undefined>(
    tabList.CUSTOMERINFO
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [DEStatus, setDEStatus] = useState<any>();
  const [AddressDoc, setAddressDoc] = useState<boolean>(false);
  // customer information state variables
  const [SuggestedAddress, setSuggestedAddress] = useState<any>();
  const [createAgreementClick, setCreateAgreementClick] =
    useState<boolean>(false);
  //const [Addressline1, setAddressline1] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [phoneNumberProps, setphoneNumberProps] = useState<any>([]);
  const [AlertWizard, setAlertWizard] = useState<any>([]);
  const [CustomAlerttxt, setCustomAlerttxt] = useState('');
  const [Istextboxdisable, setIstextboxdisable] = useState(false);
  const [editInputBox, seteditInputBox] = useState(true);
  const [editInputBoxM, seteditInputBoxM] = useState(true);
  const [TaxIDActive, setTaxIDActive] = useState(false);
  const [taxexempt_toggle, setTaxexempt] = useState(false);
  const [AlertIsOpen, setIsOpen] = useState(false);
  const [Enabletxt, setEnabletxt] = useState(true);
  const [customerInfoChange, setcustomerInfoChange] = useState<boolean>(false);
  const [customerInfoChangePopUp, setcustomerInfoChangePopUp] =
    useState<boolean>(false);
  const [customerInformation, setcustomerInformation] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerInf, setcustomerInf] = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerCoverageInf, setcustomerCoverageInf] = useState<any>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [agreementInf, setagreementInf] = useState<[] | any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLegalHold, setIsLegalHold] = useState<boolean>(false);
  // employement information state variables
  const [ValidateIsOpen, setValidateIsOpen] = useState(false);
  const [EmpProps, setEmpProps] = useState<any | []>([]);
  const [payDayfrequency, setpayDayfrequency] = useState<dropdown[] | []>([]); //state variable for PayDayFrequency dropdown
  const [payDayfrequencyAdded, setpayDayfrequencyAdded] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dayPaid1, setdayPaid1] = useState<dropdown[] | []>([]);
  const [dayPaid3, setdayPaid3] = useState<dropdown[] | []>([]);
  const [dayPaid5, setdayPaid5] = useState<dropdown[] | []>([]);
  const [activateClubObj, setactivateClubObj] = useState<boolean>(false);
  const [Residences, setResidencesvalues] = useState<any | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ReferenceProps, setReferenceProps] = useState<any | []>([]);
  const [Vehicle, setVehiclevalues] = useState<any | []>([]);
  const [validateResult, setValidateResult] = useState<any | []>([]);
  // agreement history state variables
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkAction, setcheckAction] = useState<number[]>([]);
  const [activeExpand, setactiveExpand] = useState(true);
  const [inActiveExpand, setinActiveExpand] = useState(true);
  const [pending, setpending] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [takePaymentOpen, settakePaymentOpen] = useState(true);
  const [LegalHold, setLegalHold] = useState<any>([]);
  const [LegalOption, setLegalOption] = useState<any>([]);
  //const NotProvided = 'Not Provided';
  const loginStore = sessionStorage.getItem('storeNumber');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [govTypeId, setgovTypeId] = useState<dropdown[]>([]);
  const [salutation, setsalutation] = useState<dropdown[] | []>([]);
  const [state, setstate] = useState<dropdown[] | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addressInfoState, setaddressinfoState] = useState<dropdown[] | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bestTimeToCall, setbestTimeToCall] = useState<dropdown[] | []>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [phoneType, setphoneType] = useState<dropdown[] | []>([]);
  const [preferredMethod, setpreferredMethod] = useState<dropdown[] | []>([]);
  const [addressInfoRoute, setaddressInfoRoute] = useState<dropdown[] | []>([]);
  const [taxExemptStatus, settaxExemptStatus] = useState<dropdown[] | []>([]);
  const [disableAlert, setdisableAlert] = useState<boolean>(false);
  // Footer Component
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activateClub, setactivateClub] = useState(false);
  const [objAlertdata, setobjAlertdata] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [AlertUpdateObj, setAlertUpdateObj] = useState<any | []>([]);
  const [createActiveTab, setCreateActiveTab] = useState<number>(0);
  const [nextBtnText, setnextBtnText] = useState<string>('Next');
  const [activeAlert, setactiveAlert] = useState<[] | any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isrequired, setisrequired] = useState<any>(false);
  const [updatePopUp, setShowUpdatePopUp] = useState<boolean>(false);
  const [isSuccess, setisSuccess] = useState<boolean>(false);
  const [isDOBOnFocus, setIsDOBOnFocus] = useState<boolean>(false);
  const [isGovtNumOnFocus, setisGovtNumOnFocus] = useState<boolean>(false);
  //const [inActiveAlerts, setinActiveAlerts] = useState<any | []>([]);
  const [AlertAuthenticationNeeded, setAlertAuthenticationNeeded] =
    useState<any>();
  const [preferredRoute, setPreferrefRoute] = useState<any>();
  const [createAgreementPopUp, setcreateAgreementPopUp] =
    useState<boolean>(false);
  const [isAlertLoading, setIsAlertLoading] = useState<boolean>(false);
  const [createCoCustomerPopUp, setcreateCoCustomerPopUp] =
    useState<boolean>(false);
  const addressUpdate: any = {
    address: '',
    address1: '',
    city: '',
    zip: '',
    state: '',
  };
  const [addressErrorMsg, setAddressErrorMsg] = useState<any>('');
  const [updatePrimaryAddress, setUpdatePrimaryAddress] =
    useState<any>(addressUpdate);
  const [updateMailingAddress, setupdateMailingAddress] =
    useState<any>(addressUpdate);
  const [defaultSuggestedAddress, setdefaultSuggestedAddress] = useState('sug');
  const [BtnIsdisabled1, setBtnIsdisabled1] = useState(true);
  const [BtnIsdisabled2, setBtnIsdisabled2] = useState(false);
  const [runAddressDoc, setrunAddressDoc] = useState<'P' | 'M' | ''>('');
  const [skipHardObj, setskipHardObj] = useState<'S' | 'H' | ''>('');
  //User Authentication
  const [openModal, setOpenModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [userID, setUserID] = useState('');
  const [password, setPassword] = useState('');
  const [errorline, setErrorLine] = useState(false);
  const [popupFlag, setpopupFlag] = useState(false);
  const [role, setRole] = useState('');
  const [authenType, setauthenType] = useState<
    'RACWORKER' | 'ALERT' | 'ROUTES' | 'SKIP' | ''
  >('');
  const [openPinModal, setOpenPinModal] = useState(false);
  const [otp, setotp] = useState('');
  const [somethingone, setsomethingone] = React.useState(
    'modal fade show expandPopup modal-top'
  );
  const [somethingtwo, setsomethingtwo] = React.useState(
    'modal fade modal-top'
  );
  const [loader, setloader] = useState<boolean>(false);
  const [InvalidPin, setInvalidPin] = React.useState<any>();
  const [Continue, setContinue] = React.useState(true);
  const [getStore, setGetStore] = useState<any>();
  const [showcocustomerpopup, Setshowcocustomerpopup] = useState(false);
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [secondLevelAuthenOn, setSecondLevelAuthenOn] = useState<any>(null);
  const [suggestionAddresPopup, setSuggestionAddresPopup] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [legalHoldApplied, setLegalHoldApplied] = useState<0 | 1 | 2>(0);
  const [isSecondAuthenValid, setIsSecondAuthenValid] =
    useState<boolean>(false);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const PageLoad = async () => {
        const isLegalHold = await getlegalHold();
        getCoWorkerRole(isLegalHold);
        if (customerId != undefined) {
          try {
            const result = await GetCustomerByIdInfo(customerId, loginStore);

            const CustomerInfo = getCustomerInfo(
              result.GetCustomer?.value !== undefined &&
                result.GetCustomer?.value.code == undefined
                ? result.GetCustomer.value
                : undefined
            );
            const CustomerDEInfo = createApprovalBuild(result);
            setDEStatus(CustomerDEInfo);
            setcustomerInformation(CustomerInfo);
            setcustomerInf(result.GetCustomer?.value);
            // setcustomerCoverageInf(
            //   result.GetCustomerCoverage?.value !== undefined &&
            //     result.GetCustomerCoverage?.value.code == undefined &&
            //     result.GetCustomerCoverage?.value.clubInfo !== undefined
            //     ? result.GetCustomerCoverage?.value.clubInfo
            //     : undefined
            // );
            setphoneNumberProps(
              result.GetCustomer?.value !== undefined &&
                result.GetCustomer?.value.phones !== undefined
                ? result.GetCustomer.value.phones
                : [
                    {
                      phoneId: '',
                      phoneNumber: '',
                      phoneType: null,
                      extension: '',
                      primary: 'N',
                      callTimeType: '',
                      note: '',
                    },
                  ]
            );
            /* eslint-disable no-console */
            console.log('result inside useeffect', result);
          } catch (e: any) {
            /* eslint-disable no-console */
            console.log('Error in get customer');
          }
          try {
            setIsLoading(false);
            await getcustomerCoverage();
            const getOptions = await GetCustomerInfoOptions(loginStore);
            /* eslint-disable no-console */

            /* eslint-enable no-console */
            /* eslint-disable no-console */
            console.log('result inside getOptions', getOptions);
            /* eslint-enable no-console */
            pageLoadStateVariable(getOptions);
            getAlerts();
            getEmployOptions();
          } catch (e: any) {
            /* eslint-disable no-console */
            console.log('Error in get options');
          }
        }
      };

      PageLoad();
      //setLoadAlerts(false);
      //getAlerts();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId]
  );
  const getcustomerCoverage = async () => {
    try {
      const result = await GetCustomerCoverage(customerId, loginStore);
      /* eslint-disable no-console */
      console.log('result inside getOptions GetCustomerCoverage', result);
      setcustomerCoverageInf(
        result !== undefined && result.clubInfo !== undefined
          ? result.clubInfo
          : undefined
      );
    } catch (e: any) {
      console.log('Error in co worker role GetCustomerCoverage');
    }
  };
  const getCoWorkerRole = async (isLegalHold: boolean) => {
    try {
      // eslint-disable-next-line
      // debugger
      //if (isLegalHold) {
      const coWorkerRoleObj: any = await getmenuandstore();
      if (
        coWorkerRoleObj?.coworkerProfile !== undefined &&
        coWorkerRoleObj?.coworkerProfile !== null &&
        coWorkerRoleObj?.coworkerProfile.role !== undefined &&
        coWorkerRoleObj?.coworkerProfile.role !== null
      ) {
        const roleObj = coWorkerRoleObj?.coworkerProfile.role;
        coWorkerRole = roleObj;
        setLegalHoldApplied(
          (roleObj == 'Store Manager' ||
            roleObj == 'Customer Account Rep' ||
            roleObj == 'Assistant Manager' ||
            roleObj == 'Lead Assistant Manager' ||
            roleObj == 'District Manager') &&
            isLegalHold
            ? 1
            : isLegalHold &&
              (roleObj == 'DM in Training' || roleObj == 'Regional Director')
            ? 2
            : 0
        );
        setIsSecondAuthenValid(
          roleObj == 'DM in Training' ||
            roleObj == 'Regional Director' ||
            roleObj == 'District Manager'
            ? true
            : false
        );
      }
      /* eslint-disable no-console */
      console.log('coWorkerRoleObj', coWorkerRoleObj);
      // } else {
      //   setLegalHoldApplied(0);
      // }
    } catch (e: any) {
      setLegalHoldApplied(0);
      /* eslint-disable no-console */
      console.log('Error in co worker role');
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getCustomerInfo = (result: any) => {
    let CustomerInfo: any = undefined;
    if (result !== undefined) {
      CustomerInfo = buildCustomerOptions(result);
    }
    return CustomerInfo;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildCustomerOptions = (result: any) => {
    const address: any = result.addresses.filter(
      (val: any) => val.addressType == 'PRIM'
    );
    const mailingAddress: any = result.addresses.filter(
      (val: any) => val.addressType == 'MAIL'
    );
    /* eslint-disable no-console */
    console.log('result inside mailingAddress', mailingAddress);
    /* eslint-enable no-console */
    setUpdatePrimaryAddress({
      address:
        address !== undefined && address !== null && address.length > 0
          ? address[0].addressLine1
          : '',
      address1:
        address !== undefined &&
        address !== null &&
        address.length > 0 &&
        address[0].addressLine2 !== null &&
        address[0].addressLine2 !== 'null'
          ? address[0].addressLine2
          : '',
      city:
        address !== undefined && address !== null && address.length > 0
          ? address[0].city
          : '',
      zip:
        address !== undefined &&
        address !== null &&
        address.length > 0 &&
        address[0].postalCode !== '     -    '
          ? address[0].postalCode
          : '',
      state:
        address !== undefined && address !== null && address.length > 0
          ? address[0].state
          : '',
    });
    setupdateMailingAddress({
      address:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].addressLine1
          : '',
      address1:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0 &&
        mailingAddress[0].addressLine2 !== null &&
        mailingAddress[0].addressLine2 !== 'null'
          ? mailingAddress[0].addressLine2
          : '',
      city:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].city
          : '',
      zip:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0 &&
        address[0].postalCode !== '     -    '
          ? mailingAddress[0].postalCode
          : '',
      state:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].state
          : '',
    });
    const verifedDate =
      result?.verifiedDate !== null &&
      result?.verifiedDate !== undefined &&
      result?.verifiedDate !== ''
        ? verification(result?.verifiedDate)
        : result?.verifiedDate;
    const CustomerInfo: any = {
      firstName: result?.firstName,
      lastName: result?.lastName,
      globalCustomerId: result?.globalCustomerId,
      dateOfBirth:
        result?.dateOfBirth !== undefined
          ? buildDateFormat2(result?.dateOfBirth)
          : '',
      ssn1:
        result?.taxId !== undefined && result?.taxId !== null
          ? result?.taxId.slice(0, 3)
          : '',
      ssn2:
        result?.taxId !== undefined && result?.taxId !== null
          ? result?.taxId.slice(3, 5)
          : '',
      ssn3:
        result?.taxId !== undefined && result?.taxId !== null
          ? result?.taxId.slice(5)
          : '',
      emailAddress: result?.emailAddress,
      governmentIdType: result?.governmentIdType,
      governmentId: result?.governmentId,
      governmentIdIssuedState: result?.governmentIdIssuedState,
      salutation: result?.salutation,
      middleInitial: result?.middleInitial,
      suffix: result?.suffix,
      racCoworker: result?.coworker,
      createdBy: result?.createdBy,
      createdDate: result?.createdDate,
      lastModifiedBy: result?.lastModifiedBy,
      lastModifiedDate: result?.lastModifiedDate,
      // address information
      addressess: result?.addresses,
      addressLine1Id:
        address !== undefined && address !== null && address.length > 0
          ? address[0].addressId
          : '',
      addressLine1:
        address !== undefined && address !== null && address.length > 0
          ? address[0].addressLine1
          : '',
      addressLine2:
        address !== undefined &&
        address !== null &&
        address.length > 0 &&
        address[0].addressLine2 !== null &&
        address[0].addressLine2 !== 'null'
          ? address[0].addressLine2
          : '',
      zip:
        address !== undefined &&
        address !== null &&
        address.length > 0 &&
        address[0].postalCode !== '     -    '
          ? address[0].postalCode
          : '',
      city:
        address !== undefined && address !== null && address.length > 0
          ? address[0].city
          : '',
      state:
        address !== undefined && address !== null && address.length > 0
          ? address[0].state
          : '',
      addressLine1IdM:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].addressId
          : '',
      addressLine1M:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].addressLine1
          : '',
      addressLine2M:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0 &&
        mailingAddress[0].addressLine2 !== null &&
        mailingAddress[0].addressLine2 !== 'null'
          ? mailingAddress[0].addressLine2
          : '',
      zipM:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0 &&
        mailingAddress[0].postalCode !== '     -    '
          ? mailingAddress[0].postalCode
          : '',
      cityM:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].city
          : '',
      stateM:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? mailingAddress[0].state
          : '',
      mailAddress:
        mailingAddress !== undefined &&
        mailingAddress !== null &&
        mailingAddress.length > 0
          ? 'N'
          : 'Y',
      // tax exempt

      taxId: result?.taxId,
      status: '',
      effectiveDate: '',
      expirationDate: '',
      appDenialDate: '',
      skip: result?.skip !== undefined ? result?.skip : 'N',
      stolen: result?.stolen == 'Y' ? result?.stolen : 'N',
      hard: result?.hard == 'Y' ? result?.hard : 'N',
      federalTaxId:
        result?.federalTaxId !== undefined ? result?.federalTaxId : '',
      // communication preferences
      paymentRemainderCall: result?.paymentReminderCallsAllowed,
      textMessageRemainder: result?.textSolicitationAllowed,
      preferredMethod: result?.preferredContactMethod,
      preferredPaymentReceipt: result?.printReceiptMethod,
      preferredLanguage: result?.preferredLanguage,
      phoneSolicitationAllowed: result?.phoneSolicitationAllowed,
      emailSolicitationAllowed: result?.emailSolicitationAllowed,
      mailSolicitationAllowed: result?.mailSolicitationAllowed,
      route:
        result?.customerRoutes !== undefined &&
        result?.customerRoutes !== null &&
        result?.customerRoutes.length > 0
          ? result?.customerRoutes[0].routeCode
          : 'M',
      verified: result?.verified,
      verifiedDate: verifedDate,
    };
    setTabValues(result);
    /* eslint-enable no-console */
    // eslint-disable-next-line no-console
    console.log('CustomerInfo', CustomerInfo);
    return CustomerInfo;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const setTabValues = (result: any) => {
    setTaxexempt(
      result?.federalTaxId !== undefined &&
        result?.federalTaxId !== null &&
        result?.federalTaxId !== ''
        ? true
        : false
    );

    const isOwned: boolean =
      (result?.residenceSince !== undefined &&
        result?.residenceSince !== null) ||
      (result?.mortgageCompanyName !== undefined &&
        result?.mortgageCompanyName !== null &&
        result?.mortgageCompanyName !== '')
        ? true
        : false;
    setResidencesvalues({
      rented: !isOwned,
      owned: isOwned,
      mortgage:
        result?.mortgageCompanyName !== undefined &&
        result?.mortgageCompanyName !== null &&
        result?.mortgageCompanyName !== ''
          ? true
          : false,
      mortgageCompanyName:
        result?.mortgageCompanyName !== undefined &&
        result?.mortgageCompanyName !== null
          ? result?.mortgageCompanyName
          : '',
      monthlyMortgagePayment:
        result?.monthlyMortgagePayment !== undefined &&
        result?.monthlyMortgagePayment !== null
          ? parseFloat(result?.monthlyMortgagePayment)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '0.00',
      landlordValue:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? result?.landlordReferences
          : undefined,
      residenceSince:
        result?.residenceSince !== undefined && result?.residenceSince !== null
          ? result?.residenceSince
          : '',
      landlordFirstName:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].landlordFirstName
          : '',
      landlordLastName:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].landlordLastName
          : '',
      moveInDate:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].moveInDate
          : '',
      monthlyRent:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? parseFloat(result?.landlordReferences[0].monthlyRent)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '',
      phoneNumber:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].phoneNumber
          : '',
      verifiedCheckBox:
        result?.landlordReferences !== undefined &&
        result?.landlordReferences.length > 0
          ? result?.landlordReferences[0].verifiedDate
          : null,
    });
    setReferenceProps(
      result?.personalReferences !== undefined &&
        result?.personalReferences !== null &&
        result?.personalReferences.length > 0
        ? buildRefernceLoad(result?.personalReferences)
        : [
            {
              personalReferenceId: '',
              verifiedDate: '',
              firstName: '',
              lastName: '',
              phoneNumber: '',
              relationshipType: '',
              bestTimeToCall: '',
              addressLine1: '',
              addressLine2: '',
              postalCode: '',
              city: '',
              state: '',
              active: 'Y',
              doNotCall: '',
            },
          ]
    );

    setEmpProps(
      result?.employerReferences !== undefined &&
        result?.employerReferences !== null &&
        result?.employerReferences.length > 0
        ? empInfoTakeHomeHandle(result?.employerReferences)
        : [
            {
              employerReferenceId: '',
              employerName: '',
              takeHomePay: '',
              supervisorFirstName: '',
              supervisorLastName: '',
              jobTitle: '',
              employerPhoneNumber: '',
              employerPhoneExtension: null,
              addressLine1: '',
              addressLine2: '',
              postalCode: '',
              city: '',
              state: '',
              doNotCall: 'N',
              employmentBeginDate: '2021-01-01',
              workStartTime: null,
              workEndTime: null,
              lengthOfEmploymentYears: '0',
              employerPayschedule: '',
              employmentEndDate: null,
              daysPaid: '',
              doNotVisit: 'N',
              active: 'Y',
              verifiedDate: '',
            },
          ]
    );
    //buildEmpDayPaidOptions(result);
    setVehiclevalues(
      result?.vehicles !== undefined &&
        result?.vehicles !== null &&
        result?.vehicles.length > 0
        ? result?.vehicles
        : [
            {
              customerVehicleId: '',
              vehicleMake: null,
              vehicleModel: null,
              vehicleYear: null,
              vehicleColor: null,
              vehicleVin: null,
              vehicleLicensePlate: null,
              vehRegState: null,
              vehPlanExpirationDate: null,
              vehFinancedFrom: null,
              vehMonthlyPayment: null,
              vehicleIndex: null,
            },
          ]
    );
    setTaxIDActive(
      result?.federalTaxId !== undefined &&
        result?.federalTaxId !== null &&
        result?.federalTaxId !== ''
        ? true
        : false
    );
  };
  const empInfoTakeHomeHandle = (ObjEmp: any) => {
    if (ObjEmp !== undefined && ObjEmp !== null) {
      for (let i = 0; i < ObjEmp.length; i++) {
        ObjEmp[i].takeHomePay = parseFloat(ObjEmp[i].takeHomePay)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        ObjEmp[i].lengthOfEmploymentYears =
          ObjEmp[i].lengthOfEmploymentYears !== undefined &&
          ObjEmp[i].lengthOfEmploymentYears !== null &&
          ObjEmp[i].lengthOfEmploymentYears !== '' &&
          ObjEmp[i].lengthOfEmploymentYears !== 'NaN'
            ? ObjEmp[i].lengthOfEmploymentYears
            : '0';
      }
    }
    return ObjEmp;
  };
  //eslint-disable-next-line sonarjs/cognitive-complexity
  const createApprovalBuild = (result: any) => {
    const approvalValue: any =
      result.GetApproval?.value !== undefined &&
      result.GetApproval?.value.customerApprovalDetails !== undefined
        ? result.GetApproval?.value.customerApprovalDetails
        : null;
    // eslint-disable-next-line no-console
    console.log('approvalValue', approvalValue);
    const CustomerDEInfo: any = {
      outstandingBalance:
        approvalValue !== null &&
        approvalValue?.outstandingBalance !== undefined &&
        approvalValue?.outstandingBalance !== null &&
        approvalValue?.outstandingBalance !== ''
          ? approvalValue?.outstandingBalance
          : null,
      weeklyOutstandingBalance:
        approvalValue !== null &&
        approvalValue?.weeklyOutstandingBalance !== undefined &&
        approvalValue?.weeklyOutstandingBalance !== null &&
        approvalValue?.weeklyOutstandingBalance !== ''
          ? approvalValue?.weeklyOutstandingBalance
          : null,
      approvalExpirationDate:
        approvalValue !== null &&
        approvalValue?.approvalExpirationDate !== undefined &&
        approvalValue?.approvalExpirationDate !== null &&
        approvalValue?.approvalExpirationDate !== ''
          ? approvalValue?.approvalExpirationDate
          : null,
      approvalStatus:
        approvalValue !== null &&
        approvalValue?.approvalStatus !== undefined &&
        approvalValue?.approvalStatus !== null &&
        approvalValue?.approvalStatus !== ''
          ? approvalValue?.approvalStatus == 'M'
            ? 'Conditional Approval'
            : approvalValue?.approvalStatus == 'D'
            ? 'Declined'
            : approvalValue?.approvalStatus == 'A'
            ? 'Approved'
            : 'No DE'
          : 'No DE',
    };

    // eslint-disable-next-line no-console
    console.log('approvalExpirationDate', CustomerDEInfo);

    return CustomerDEInfo;
  };
  const getlegalHold = async () => {
    let isLeaglHold = false;
    try {
      const getLegalHold = await GetLegalSatatus(customerId);
      /* eslint-disable no-console */
      console.log('getLegalHold', getLegalHold);

      setLegalHold(
        getLegalHold !== undefined &&
          getLegalHold !== null &&
          getLegalHold?.legalHoldStatusRes !== undefined &&
          getLegalHold?.legalHoldStatusRes !== null &&
          getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0]
          : null
      );
      isLeaglHold =
        getLegalHold !== undefined &&
        getLegalHold !== null &&
        getLegalHold?.legalHoldStatusRes !== undefined &&
        getLegalHold?.legalHoldStatusRes !== null &&
        getLegalHold?.legalHoldStatusRes.length > 0
          ? getLegalHold.legalHoldStatusRes[0].legalHoldStatus == 'true' ||
            getLegalHold.legalHoldStatusRes[0].legalHoldStatus === true
            ? true
            : false
          : false;
      setIsLegalHold(isLeaglHold);
    } catch (e: any) {
      setLegalHold(null);
      /* eslint-disable no-console */
      console.log('getLegalHold', e);
    }
    return isLeaglHold;
  };
  const activateCLub = async () => {
    try {
      const payload: any = {
        isCreate: 0,
        customerId: customerId,
        storeNumber: loginStore,
        paymentSchedule: null,
        agreementId: null,
        clubStatusId: 4,
      };
      hideButtons();
      const response: any = await GetActivateClub(payload);
      // eslint-disable-next-line no-console
      setcustomerInformation({
        ...customerInformation,
        clubStatus: 'D',
      });
      console.log('activateCLub', response);
    } catch (e: any) {
      hideButtons();
      // eslint-disable-next-line no-console
      console.log('activateCLub', e);
    }
  };
  const buildValidData = (dataObj: any) => {
    return dataObj !== undefined && dataObj !== null && dataObj !== ''
      ? dataObj
      : null;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildRefernceLoad = (refernce: any) => {
    const referenceObj: any = [];
    if (refernce !== undefined && refernce !== null && refernce.length > 0) {
      refernce.forEach((element: any) => {
        referenceObj.push({
          personalReferenceId: element?.personalReferenceId,
          verifiedDate: '',
          firstName: buildValidData(element?.firstName),
          lastName: buildValidData(element?.lastName),
          phoneNumber: buildValidData(element?.phoneNumber),
          relationshipType: buildValidData(element?.relationshipType),
          bestTimeToCall: buildValidData(element?.bestTimeToCall),
          addressLine1: buildValidData(element?.addressLine1),
          addressLine2: buildValidData(element?.addressLine2),
          postalCode:
            element?.zip !== undefined && element?.zip !== '     -    '
              ? buildValidData(element?.zip)
              : element?.postalCode !== undefined &&
                element?.postalCode !== '     -    '
              ? buildValidData(element?.postalCode)
              : '',
          city: buildValidData(element?.city),
          state: element?.state !== '0' ? buildValidData(element?.state) : null,
          active: element?.active,
          doNotCall: element?.doNotCall,
        });
      });
    }
    return referenceObj;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateUpdateCustomer = () => {
    const validateResultObj = [];
    let isValid = true;
    if (legalHoldApplied !== 1 && isLegalHold !== true) {
      let firstTabValid =
        validateTabOne() === true && validateCustomerTab() === true
          ? true
          : false;
      if (firstTabValid !== false) {
        const SSN: any =
          customerInformation.ssn1 +
            customerInformation.ssn2 +
            customerInformation.ssn3 ==
          ''
            ? null
            : customerInformation.ssn1 +
              customerInformation.ssn2 +
              customerInformation.ssn3;
        firstTabValid =
          SSN != undefined && SSN != null && SSN != ''
            ? SSN.length < 9
              ? false
              : true
            : true;
      }
      if (firstTabValid === false) {
        validateResultObj.push('Customer Information');
        isValid = false;
      }
      if (validateTabTwo().isValidate === false) {
        validateResultObj.push('Employment Information');
        isValid = false;
      }
      if (validateTabThree().isValidate === false) {
        validateResultObj.push('Residence');
        isValid = false;
      }
      if (validateTabFour().isValidate === false) {
        validateResultObj.push('Reference');
        isValid = false;
      }
      setValidateResult(validateResultObj);
    }
    if (isValid) {
      setisrequired(false);
      //setisPrimary(true);
      updateCustomerInfo();
    } else {
      setErrorPopup(true);
      setisrequired(true);
    }
  };
  const validateValue = (value: any) => {
    let notValid = false;
    if (value == undefined || value == null || value == '') {
      notValid = true;
    }
    return notValid;
  };
  // eslint-disable-next-line sonarjs/no-duplicated-branches
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabOne = () => {
    // eslint-disable-next-line no-console
    console.log(customerInformation);
    let isValidate = true;

    isValidate = !validateValue(customerInformation.firstName);
    // eslint-disable-next-line no-console
    console.log('firstNamei', isValidate);
    if (isValidate !== false) {
      isValidate = !validateValue(customerInformation.lastName);
    }
    // eslint-disable-next-line no-console
    console.log('lastName', isValidate);
    if (isValidate !== false) {
      isValidate = !validateValue(customerInformation.emailAddress);
      isValidate =
        isValidate !== false
          ? ValidateEmail(customerInformation?.emailAddress)
          : isValidate;
    }
    // eslint-disable-next-line no-console
    console.log('emailAddress', isValidate);
    if (isValidate !== false) {
      isValidate = !validateValue(customerInformation.dateOfBirth);
    }
    // eslint-disable-next-line no-console
    console.log('dateOfBirth', isValidate);
    if (
      customerInformation.governmentIdType == undefined ||
      customerInformation.governmentIdType == null ||
      customerInformation.governmentIdType == '0'
    ) {
      isValidate = false;
    }
    if (
      customerInformation.governmentIdType != undefined &&
      customerInformation.governmentIdType != null &&
      customerInformation.governmentIdType != '0' &&
      customerInformation.governmentIdType != 'NONE' &&
      customerInformation.governmentIdType != 'PASS' &&
      (customerInformation?.governmentId == undefined ||
        customerInformation?.governmentId == null ||
        customerInformation?.governmentId == '')
    ) {
      isValidate = false;
    }
    if (
      customerInformation.governmentIdType != undefined &&
      customerInformation.governmentIdType != null &&
      customerInformation.governmentIdType != '0' &&
      customerInformation.governmentIdType != 'NONE' &&
      customerInformation.governmentIdType != 'PASS' &&
      (customerInformation?.governmentIdIssuedState == undefined ||
        customerInformation?.governmentIdIssuedState == null ||
        customerInformation?.governmentIdIssuedState == '0')
    ) {
      isValidate = false;
    }
    // eslint-disable-next-line no-console
    console.log('governmentIdType', isValidate);
    if (isValidate !== false) {
      isValidate = validateCustomerTab();
    }
    // eslint-disable-next-line no-console
    console.log('validateCustomerTab', isValidate);
    let isPrimaryValid = false;
    //phoneNumberProps.forEach((element: any) => {
    for (let i = 0; i < phoneNumberProps.length; i++) {
      const formatedPhoneNumber: any =
        phoneNumberProps[i].phoneNumber !== undefined &&
        phoneNumberProps[i].phoneNumber !== null &&
        phoneNumberProps[i].phoneNumber !== ''
          ? phoneNumberProps[i].phoneNumber.length
          : 0;
      if (
        formatedPhoneNumber == 0 ||
        (formatedPhoneNumber !== 0 && formatedPhoneNumber < 10)
      ) {
        isValidate = false;
      }

      // eslint-disable-next-line no-console
      //console.log('phoneNumber', element.phoneNumber.match(/(\d+)/).length);
      // eslint-disable-next-line no-console
      console.log('phoneNumber', isValidate);
      if (
        phoneNumberProps[i].callTimeType == undefined ||
        phoneNumberProps[i].callTimeType == null ||
        phoneNumberProps[i].callTimeType == '' ||
        phoneNumberProps[i].callTimeType == '0'
      ) {
        isValidate = false;
      }
      if (phoneNumberProps[i].primary == 'Y' && isPrimaryValid === false) {
        isPrimaryValid = true;
      }
    }
    if (TaxIDActive === true && isValidate !== false) {
      isValidate = !validateValue(customerInformation.federalTaxId);
    }
    // eslint-disable-next-line no-console
    console.log('callTimeType', isValidate);
    // });
    if (isPrimaryValid === false) {
      isValidate = false;
      //setisPrimary(false);
    }
    return isValidate;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateCustomerTab = () => {
    // eslint-disable-next-line no-console
    console.log(customerInformation);
    let isValidate = true;
    if (validateValue(customerInformation.addressLine1)) {
      isValidate = false;
      // eslint-disable-next-line no-console
      console.log('addressLine1', isValidate);
    } else if (
      (customerInformation.zip == undefined ||
        customerInformation.zip == null ||
        customerInformation.zip == '') &&
      customerInformation.zip.length != 5 &&
      customerInformation.zip.length != 10
    ) {
      isValidate = false;
      // eslint-disable-next-line no-console
      console.log('zip', isValidate);
    } else if (validateValue(customerInformation.city)) {
      isValidate = false;
    } else if (
      customerInformation.state == undefined ||
      customerInformation.state == null ||
      customerInformation.state == '0'
    ) {
      isValidate = false;
      // eslint-disable-next-line no-console
      console.log('city', isValidate);
    } else if (customerInformation.mailAddress == 'N') {
      if (validateValue(customerInformation.addressLine1M)) {
        isValidate = false;
        // eslint-disable-next-line no-console
        console.log('addressLine1M', isValidate);
      }
      if (
        (customerInformation.zipM == undefined ||
          customerInformation.zipM == null ||
          customerInformation.zipM == '') &&
        (customerInformation.zipM.length != 5 ||
          customerInformation.zipM.length != 10)
      ) {
        isValidate = false;
        // eslint-disable-next-line no-console
        console.log('zipM', isValidate);
      }
      if (
        customerInformation.stateM == undefined ||
        customerInformation.stateM == null ||
        customerInformation.stateM == '0'
      ) {
        isValidate = false;
        // eslint-disable-next-line no-console
        console.log('stateM', isValidate);
      }
      isValidate = !validateValue(customerInformation.cityM);
      // eslint-disable-next-line no-console
      console.log('cityM', isValidate);
    }
    return isValidate;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabTwo = (createClick?: boolean) => {
    let isValidate = true;
    let UpdatedEmpInfo = true;
    let count = 0;
    let EmpInfoCount = 0;
    EmpProps.forEach((element: any) => {
      count = 0;
      if (element.active == 'Y') {
        if (validateValue(element.employerName)) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('employerName', isValidate);
        }
        const phoneNumberFormated: any =
          element.employerPhoneNumber !== undefined &&
          element.employerPhoneNumber !== null &&
          element.employerPhoneNumber !== ''
            ? element.employerPhoneNumber.length
            : 0;
        // eslint-disable-next-line no-console
        console.log('phoneNumberFormated', phoneNumberFormated);
        if (phoneNumberFormated < 10) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('callTimeType', isValidate);
        }
        if (
          element.employerPayschedule == undefined ||
          element.employerPayschedule == null ||
          element.employerPayschedule == '0' ||
          element.employerPayschedule == ''
        ) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('employerPayschedule', isValidate);
        }
        if (
          isValidate !== false &&
          element.employerPayschedule != 'DAILY' &&
          (element.daysPaid == undefined ||
            element.daysPaid == null ||
            element.daysPaid == '0' ||
            element.daysPaid == '')
        ) {
          isValidate = false;
          // eslint-disable-next-line no-console
          console.log('daysPaid', isValidate);
        }
        if (validateValue(element.addressLine1)) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('addressLine1', isValidate);
        }
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        if (
          element.postalCode == undefined ||
          element.postalCode == null ||
          element.postalCode == ''
        ) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('callTimeType', isValidate);
        }
        if (
          element.postalCode != undefined &&
          element.postalCode != null &&
          element.postalCode.length != 0 &&
          element.postalCode.length != 5 &&
          element.postalCode.length != 10
        ) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('callTimeType', isValidate);
        }
        if (validateValue(element.city)) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('city', isValidate);
        }

        // eslint-disable-next-line no-console
        console.log('city', isValidate);

        if (
          element.state == undefined ||
          element.state == null ||
          element.state == '0' ||
          element.state == ''
        ) {
          isValidate = false;
          count = count + 1;
          // eslint-disable-next-line no-console
          console.log('state', isValidate);
        }
      }
      EmpInfoCount = count === 7 ? EmpInfoCount + 1 : EmpInfoCount;
    });
    //if(pageType !== 'customer' && EmpProps.length == 1 && EmpInfoCount ){
    // eslint-disable-next-line no-console
    console.log('createAgreementClick', createAgreementClick);
    UpdatedEmpInfo =
      createClick == undefined
        ? true
        : EmpInfoCount == EmpProps.length
        ? false
        : true;
    // eslint-disable-next-line no-console
    console.log('UpdatedEmpInfo', UpdatedEmpInfo);
    // }
    return {
      isValidate: UpdatedEmpInfo === true ? isValidate : true,
      UpdatedInfo: UpdatedEmpInfo,
    };
  };
  // eslint-disable-next-line sonarjs/no-duplicated-branches
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabThree = (createClick?: boolean) => {
    // eslint-disable-next-line no-console
    console.log(Residences);
    let count = 0;
    let isValidObj = true;
    // eslint-disable-next-line no-console
    console.log(Residences);
    if (Residences.rented === true) {
      const formatedPhoneNumber: any =
        Residences.phoneNumber !== undefined &&
        Residences.phoneNumber !== null &&
        Residences.phoneNumber !== ''
          ? Residences.phoneNumber.length
          : 0;
      if (
        formatedPhoneNumber == 0 ||
        (formatedPhoneNumber !== 0 && formatedPhoneNumber < 10)
      ) {
        isValidObj = false;
        count = count + 1;
        // eslint-disable-next-line no-console
        console.log('phoneNumber', isValidObj);
      }
      if (validateValue(Residences.landlordFirstName)) {
        isValidObj = false;
        count = count + 1;
        // eslint-disable-next-line no-console
        console.log('landlordFirstName', isValidObj);
      }
      if (validateValue(Residences.landlordLastName)) {
        isValidObj = false;
        count = count + 1;
        // eslint-disable-next-line no-console
        console.log('landlordLastName', isValidObj);
      }
    }
    if (
      Residences.owned === true &&
      Residences.mortgage === true &&
      validateValue(Residences.mortgageCompanyName)
    ) {
      isValidObj = false;
      count = 3;
      // eslint-disable-next-line no-console
      console.log('mortgageCompanyName', isValidObj);
    }
    const UpdatedInfo =
      createClick == undefined ? true : count === 3 ? false : true;

    // eslint-disable-next-line no-console
    console.log('isValidObj', isValidObj);
    return {
      isValidate: UpdatedInfo === true ? isValidObj : true,
      UpdatedInfo: UpdatedInfo,
    };
  };
  // eslint-disable-next-line sonarjs/no-duplicated-branches
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateTabFour = (createClick?: boolean) => {
    // eslint-disable-next-line no-console
    console.log(ReferenceProps);
    let count = 0;
    let isValid = true;
    let ReferenceInfoCount = 0;
    if (ReferenceProps !== undefined && ReferenceProps.length > 0) {
      ReferenceProps.forEach((element: any) => {
        if (element.active == 'Y') {
          const phoneNumberFormated: any =
            element.phoneNumber !== undefined &&
            element.phoneNumber !== null &&
            element.phoneNumber !== ''
              ? element.phoneNumber.length
              : 0;
          if (phoneNumberFormated < 10) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('callTimeType', isValid);
          }
          if (validateValue(element.firstName)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('callTimeType', isValid);
          }
          if (validateValue(element.lastName)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('callTimeType', isValid);
          }
          if (
            element.relationshipType == undefined ||
            element.relationshipType == null ||
            element.relationshipType == '0' ||
            element.relationshipType == ''
          ) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('relationshipType', isValid);
          }
          if (validateValue(element.addressLine1)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('addressLine1', isValid);
          }
          if (validateValue(element.postalCode)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('addressLine1', isValid);
          }
          if (
            isValid !== false &&
            element.postalCode.length !== 5 &&
            element.postalCode.length !== 10
          ) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('addressLine1', isValid);
          }
          if (validateValue(element.city)) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('addressLine1', isValid);
          }
          if (
            element.state == undefined ||
            element.state == null ||
            element.state == '0' ||
            element.state == ''
          ) {
            isValid = false;
            count = count + 1;
            // eslint-disable-next-line no-console
            console.log('addressLine1', isValid);
          }
        }
        ReferenceInfoCount =
          count === 8 ? ReferenceInfoCount + 1 : ReferenceInfoCount;
      });
    }
    const UpdatedInfo =
      createClick == undefined
        ? true
        : ReferenceInfoCount == ReferenceProps.length
        ? false
        : true;
    //isValid = count === 3 ? true : isValid;
    // eslint-disable-next-line no-console
    console.log('UpdatedInfo', UpdatedInfo);
    return {
      isValidate: UpdatedInfo === true ? isValid : true,
      UpdatedInfo: UpdatedInfo,
    };
  };
  const buildDate = (date: string) => {
    const d = date == '' ? new Date() : new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };
  const buildDateFormat2 = (date: any) => {
    const d = date == '' ? new Date() : new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const verification = (isloadVerificationdate?: any) => {
    // eslint-disable-next-line
    //debugger
    let empTabVerified = true;
    let residenceverified = true;
    let referenceVerified = true;
    if (EmpProps !== undefined && EmpProps !== null && EmpProps.length > 0) {
      for (let i = 0; i < EmpProps.length; i++) {
        if (
          empTabVerified === true &&
          EmpProps[i].active == 'Y' &&
          (EmpProps[i].verifiedDate == undefined ||
            EmpProps[i].verifiedDate == null ||
            EmpProps[i].verifiedDate == '' ||
            EmpProps[i].verifiedDate == 'null')
        ) {
          empTabVerified = false;
        }
      }
    }
    if (
      ReferenceProps !== undefined &&
      ReferenceProps !== null &&
      ReferenceProps.length > 0 &&
      empTabVerified === true
    ) {
      for (let i = 0; i < ReferenceProps.length; i++) {
        if (
          referenceVerified === true &&
          ReferenceProps[i].active == 'Y' &&
          (ReferenceProps[i].verifiedDate == undefined ||
            ReferenceProps[i].verifiedDate == null ||
            ReferenceProps[i].verifiedDate == '' ||
            ReferenceProps[i].verifiedDate == 'null')
        ) {
          referenceVerified = false;
        }
      }
    }
    if (
      (Residences.verifiedCheckBox == undefined ||
        Residences.verifiedCheckBox == null ||
        Residences.verifiedCheckBox == '') &&
      empTabVerified === true &&
      referenceVerified === true
    ) {
      residenceverified = false;
    }
    const IsVerified: any =
      empTabVerified === true &&
      referenceVerified === true &&
      residenceverified === true
        ? 'Y'
        : 'N';
    setcustomerInformation({
      ...customerInformation,
      verified: IsVerified,
    });
    if (IsVerified == 'Y' && isloadVerificationdate == undefined) {
      const myCurrentDate = new Date();
      //const myFutureDate = new Date(myCurrentDate);
      //myFutureDate.setDate(myFutureDate.getDate() + 60);
      const customerInformationObj = customerInformation;
      customerInformationObj.verified = 'Y';
      customerInformationObj.verifiedDate = buildDateFormat2(myCurrentDate);
      setcustomerInformation({ ...customerInformationObj });
    }
    if (isloadVerificationdate !== undefined && IsVerified == 'Y') {
      const myCurrentDate = new Date(isloadVerificationdate);
      //const myFutureDate = new Date(myCurrentDate);
      //myFutureDate.setDate(myFutureDate.getDate() + 60);
      return buildDateFormat2(myCurrentDate);
    }
  };
  const buildVerification = () => {
    // eslint-disable-next-line no-console
    console.log('customerInformation', customerInformation);
    let myFutureDate: any = '';
    if (
      customerInformation?.verifiedDate !== undefined &&
      customerInformation?.verifiedDate !== null &&
      customerInformation?.verifiedDate !== ''
    ) {
      myFutureDate = new Date(customerInformation?.verifiedDate);
      myFutureDate.setDate(myFutureDate.getDate() + 60);
    }
    return customerInformation?.verified !== undefined &&
      customerInformation?.verified !== null &&
      customerInformation?.verified !== '' &&
      customerInformation?.verified == 'Y' ? (
      <Grid item className="floatLeft text-center mt-2 verifiedtag me-3">
        <Box
          component="span"
          className="badge racpad-badge bg-success badge-font "
        >
          Verified
        </Box>
        <Box component="span" className="d-block rac-de-date badge-font ">
          Expires on{' '}
          {customerInformation?.verifiedDate !== undefined &&
          customerInformation?.verifiedDate !== null &&
          customerInformation?.verifiedDate !== ''
            ? buildDateFormat2(myFutureDate)
            : ''}
        </Box>
      </Grid>
    ) : customerInformation?.verified == 'N' ? (
      <Grid item className="floatLeft text-center mt-1 me-1 verifiedtag">
        <Box
          component="span"
          className="badge racpad-badge bg-failed badge-font badge-color"
        >
          Verification Required
        </Box>
      </Grid>
    ) : null;
  };
  //eslint-disable-next-line sonarjs/cognitive-complexity
  const updateCustomerInfo = async () => {
    try {
      // eslint-disable-next-line no-debugger
      //debugger;
      let UpdateCustomerRequest: any = [];
      if (legalHoldApplied !== 1 && isLegalHold !== true) {
        UpdateCustomerRequest = {
          customerId: customerId,
          globalCustomerId: customerInformation.globalCustomerId,
          firstName: customerInformation.firstName,
          lastName: customerInformation.lastName,
          middleInitial: customerInformation.middleInitial,
          coworker: customerInformation.racCoworker,
          suffix: customerInformation.suffix,
          salutation: customerInformation.salutation,
          taxId:
            customerInformation.ssn1 +
              customerInformation.ssn2 +
              customerInformation.ssn3 ==
            ''
              ? null
              : customerInformation.ssn1 +
                customerInformation.ssn2 +
                customerInformation.ssn3,
          taxIdType: 'SSN',
          governmentId: customerInformation.governmentId,
          governmentIdType: customerInformation.governmentIdType,
          dateOfBirth: buildDate(customerInformation.dateOfBirth),
          governmentIdIssuedState: customerInformation.governmentIdIssuedState,
          emailAddress: customerInformation.emailAddress,
          preferredContactMethod: customerInformation.preferredMethod,
          preferredLanguage: customerInformation.preferredLanguage,
          printReceiptMethod: customerInformation.preferredPaymentReceipt,
          phoneSolicitationAllowed:
            customerInformation.phoneSolicitationAllowed,
          emailSolicitationAllowed:
            customerInformation.emailSolicitationAllowed,
          mailSolicitationAllowed: customerInformation.mailSolicitationAllowed,
          textSolicitationAllowed: customerInformation.textMessageRemainder,
          paymentReminderCallsAllowed: customerInformation.paymentRemainderCall,
          verified: customerInformation.verified,
          verifiedDate:
            customerInformation.verifiedDate !== undefined &&
            customerInformation.verifiedDate !== null &&
            customerInformation.verifiedDate !== ''
              ? buildDate(customerInformation.verifiedDate)
              : null,
          skip: customerInformation.skip,
          stolen: customerInformation.stolen,
          hard: customerInformation.hard,
          skipDate: buildDate(''),
        };
        UpdateCustomerRequest.mortgageCompanyName =
          Residences.rented === false
            ? Residences.mortgage === true
              ? Residences.mortgageCompanyName
              : null
            : null;
        UpdateCustomerRequest.residenceSince =
          Residences.rented === false ? Residences.residenceSince : null;
        UpdateCustomerRequest.monthlyMortgagePayment =
          Residences.rented === false
            ? Residences.mortgage === true
              ? Residences.monthlyMortgagePayment == 'NaN'
                ? ''
                : Residences.monthlyMortgagePayment
              : ''
            : '';
        //let addressArray : any = buildAddress()
        UpdateCustomerRequest.addresses = buildAddress();
        // eslint-disable-next-line no-console
        console.log(UpdateCustomerRequest.addresses);
        //looping to changing phone number to normal
        const phonePropsArray: any = buildPhones();

        if (
          phoneNumberProps !== undefined &&
          phoneNumberProps !== null &&
          phoneNumberProps.length > 0
        ) {
          UpdateCustomerRequest.phones = phonePropsArray;
        }
        // eslint-disable-next-line no-console
        console.log(UpdateCustomerRequest.phones);

        const EmpPropsArray: any = buildEmpObj();

        UpdateCustomerRequest.employerReferences = EmpPropsArray;
        if (DEStatus.approvalStatus !== 'Approved' || pageType == 'customer') {
          const ReferencePropsArray: any = buildReferenceObj();
          // eslint-disable-next-line no-console
          console.log('ReferenceProps', ReferencePropsArray);

          UpdateCustomerRequest.personalReferences = ReferencePropsArray;
          if (Residences.rented === true) {
            UpdateCustomerRequest.landlordReferences = landlordReferences();
          }
          const vechicleObj: any = buildVechicle();
          UpdateCustomerRequest.vehicles = vechicleObj;
        }
      } else {
        UpdateCustomerRequest = {
          customerId: customerId,
          globalCustomerId: customerInformation.globalCustomerId,
          firstName: customerInformation.firstName,
          lastName: customerInformation.lastName,
        };
      }
      UpdateCustomerRequest.assignRoute = {
        storeNumber: loginStore,
        customerId: parseInt(customerId),
        coWorkerId: '6789',
        routeRefCode: customerInformation.route,
      };
      // /* eslint-enable no-console */
      // console.log('UpdateCustomerRequest', JSON.stringify(UpdateCustomerRequest));
      // eslint-disable-next-line no-console
      console.log('UpdateCustomerRequest', UpdateCustomerRequest);
      // /* eslint-enable no-console */
      setIsLoading(true);
      const result = await UpdateCustomerInfo(
        UpdateCustomerRequest,
        customerId
      );
      setIsLoading(false);
      // eslint-disable-next-line no-console
      showUpdateStatusPopUp(result);
      // eslint-disable-next-line no-console
      console.log('response', result);
      // /* eslint-enable no-console */
    } catch (e: any) {
      setIsLoading(false);
      setisSuccess(false);
      setShowUpdatePopUp(true);
      // eslint-disable-next-line no-console
      console.log(e, 'Error in Update Customer');
    }
  };
  const showUpdateStatusPopUp = (result: any) => {
    if (
      result !== undefined &&
      result !== null &&
      result.UpdateCustomer !== undefined &&
      result.UpdateCustomer !== null &&
      result.UpdateCustomer.value !== undefined &&
      result.UpdateCustomer.value !== null &&
      result.UpdateCustomer.value.customerId !== undefined &&
      result.UpdateCustomer.value.customerId !== null &&
      result.UpdateCustomer.value.customerId == customerId
    ) {
      customerIdQueryParam == null
        ? pageType == undefined || pageType == 'customer'
          ? setShowUpdatePopUp(true)
          : setcreateAgreementPopUp(true)
        : props.history.push(
            `/agreement/rental/itemsearch/${customerIdQueryParam}?coCustomerId=${customerId}`
          );

      setisSuccess(true);
    } else {
      setisSuccess(false);
      setShowUpdatePopUp(true);
    }
  };
  const hideupdatePopUp = () => {
    setShowUpdatePopUp(false);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildVechicle = () => {
    const vechicleObj: any = [];
    if (Vehicle !== undefined && Vehicle !== null && Vehicle.length > 0) {
      Vehicle.forEach((el: any) => {
        if (
          el.vehicleLicensePlate !== undefined &&
          el.vehicleLicensePlate !== '' &&
          el.vehicleLicensePlate !== null
        ) {
          el.vehicleIndex = '';
          el.vehPlanExpirationDate =
            el.vehPlanExpirationDate !== undefined &&
            el.vehPlanExpirationDate !== null &&
            el.vehPlanExpirationDate !== ''
              ? buildDate(el.vehPlanExpirationDate)
              : '';
          el.vehMonthlyPayment =
            el.vehMonthlyPayment !== undefined &&
            el.vehMonthlyPayment !== null &&
            el.vehMonthlyPayment !== 'NaN' &&
            el.vehMonthlyPayment !== ''
              ? el.vehMonthlyPayment.replace(',', '')
              : '0';

          vechicleObj.push(el);
        }
      });
    }
    return vechicleObj;
  };
  const buildPhones = () => {
    const phonePropsArray: any = [];
    if (
      phoneNumberProps !== undefined &&
      phoneNumberProps !== null &&
      phoneNumberProps.length > 0
    ) {
      phoneNumberProps.forEach((el: any) => {
        el.phoneId =
          el.phoneId !== undefined && el.phoneId !== null && el.phoneId !== ''
            ? el.phoneId
            : '';
        el.phoneNumber =
          el.phoneNumber !== undefined && el.phoneNumber !== null
            ? el.phoneNumber.replace(/\D/g, '')
            : null;
        phonePropsArray.push(el);
      });
    }
    return phonePropsArray;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildReferenceObj = () => {
    const ReferencePropsArray: any = [];
    if (ReferenceProps !== undefined && ReferenceProps.length > 0) {
      ReferenceProps.forEach((el: any) => {
        if (el.active !== 'Y') {
          el.phoneNumber =
            el.phoneNumber !== undefined &&
            el.phoneNumber !== null &&
            el.phoneNumber !== ''
              ? el.phoneNumber
              : null;
          el.relationshipType =
            el.relationshipType !== undefined &&
            el.relationshipType !== null &&
            el.relationshipType !== ''
              ? el.relationshipType
              : null;
          el.bestTimeToCall =
            el.bestTimeToCall !== undefined &&
            el.bestTimeToCall !== null &&
            el.bestTimeToCall !== ''
              ? el.bestTimeToCall
              : null;
          el.addressLine1 =
            el.addressLine1 !== undefined &&
            el.addressLine1 !== null &&
            el.addressLine1 !== ''
              ? el.addressLine1
              : null;
          el.addressLine2 =
            el.addressLine2 !== undefined &&
            el.addressLine2 !== null &&
            el.addressLine2 !== ''
              ? el.addressLine2
              : null;
          el.postalCode =
            el.postalCode !== undefined &&
            el.postalCode !== null &&
            el.postalCode !== ''
              ? el.postalCode
              : null;
          el.city =
            el.city !== undefined && el.city !== null && el.city !== ''
              ? el.city
              : null;
          el.state =
            el.state !== undefined && el.state !== null && el.state !== ''
              ? el.state
              : null;
          el.doNotCall = el.doNotCall !== 'Y' ? 'N' : 'Y';
          el.verifiedDate =
            el.verifiedDate !== undefined &&
            el.verifiedDate !== null &&
            el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : null;
        } else {
          el.phoneNumber =
            el.phoneNumber !== undefined && el.phoneNumber !== null
              ? el.phoneNumber.replace(/\D/g, '')
              : null;
          el.verifiedDate =
            el.verifiedDate !== undefined &&
            el.verifiedDate !== null &&
            el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : null;
        }
        ReferencePropsArray.push(el);
      });
    }
    return ReferencePropsArray;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildEmpObj = () => {
    const EmpPropsArray: any = [];
    if (EmpProps !== undefined && EmpProps !== null && EmpProps.length > 0) {
      EmpProps.forEach((el: any) => {
        if (el.active !== 'Y') {
          el.employerName =
            el.employerName !== undefined &&
            el.employerName !== null &&
            el.employerName !== ''
              ? el.employerName
              : null;
          el.takeHomePay =
            el.takeHomePay !== undefined &&
            el.takeHomePay !== null &&
            el.takeHomePay !== '' &&
            el.takeHomePay !== 'NaN'
              ? el.takeHomePay.replace(',', '')
              : null;
          el.employerPhoneNumber =
            el.employerPhoneNumber !== undefined &&
            el.employerPhoneNumber !== null &&
            el.employerPhoneNumber !== ''
              ? el.employerName.replace(/\D/g, '')
              : null;
          el.addressLine1 =
            el.addressLine1 !== undefined &&
            el.addressLine1 !== null &&
            el.addressLine1 !== ''
              ? el.addressLine1
              : null;
          el.addressLine2 =
            el.addressLine2 !== undefined &&
            el.addressLine2 !== null &&
            el.addressLine2 !== ''
              ? el.addressLine2
              : null;
          el.postalCode =
            el.postalCode !== undefined &&
            el.postalCode !== null &&
            el.postalCode !== ''
              ? el.postalCode
              : null;
          el.city =
            el.city !== undefined && el.city !== null && el.city !== ''
              ? el.city
              : null;
          el.state =
            el.state !== undefined && el.state !== null && el.state !== ''
              ? el.state
              : null;
          el.employerPayschedule =
            el.employerPayschedule !== undefined &&
            el.employerPayschedule !== null &&
            el.employerPayschedule !== ''
              ? el.employerPayschedule
              : null;
          el.daysPaid =
            el.daysPaid !== undefined &&
            el.daysPaid !== null &&
            el.daysPaid !== ''
              ? el.daysPaid
              : null;
          el.lengthOfEmploymentYears =
            el.lengthOfEmploymentYears !== undefined &&
            el.lengthOfEmploymentYears !== null &&
            el.lengthOfEmploymentYears !== ''
              ? el.lengthOfEmploymentYears
              : '0';
          el.workStartTime = null;
          el.workEndTime = null;
          el.supervisorFirstName = '';
          el.supervisorLastName = '';
          el.jobTitle = '';
          el.employerPhoneExtension = null;
          el.employmentEndDate = null;
          el.verifiedDate =
            el.verifiedDate !== undefined &&
            el.verifiedDate !== null &&
            el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : null;
          el.doNotCall = el.doNotCall !== 'Y' ? 'N' : 'Y';
          el.doNotVisit = el.doNotVisit !== 'Y' ? 'N' : 'Y';
        } else {
          const lengthOfEmploymentYearsObj: any =
            el.lengthOfEmploymentYears !== ''
              ? parseInt(el.lengthOfEmploymentYears)
              : '';
          //lengthOfEmploymentYearsObj = lengthOfEmploymentYearsObj !== undefined && lengthOfEmploymentYearsObj !== null && lengthOfEmploymentYearsObj !== NaN ?
          el.employerPhoneNumber =
            el.employerPhoneNumber !== undefined &&
            el.employerPhoneNumber !== null
              ? el.employerPhoneNumber.replace(/\D/g, '')
              : null;
          el.takeHomePay =
            el.takeHomePay !== undefined &&
            el.takeHomePay !== null &&
            el.takeHomePay !== 'NaN' &&
            el.takeHomePay !== ''
              ? el.takeHomePay.replace(',', '')
              : '0';
          el.supervisorFirstName = '';
          el.supervisorLastName = '';
          el.jobTitle = '';
          el.employerPhoneExtension = null;
          el.employerReferenceId =
            el.employerReferenceId !== undefined &&
            el.employerReferenceId !== null &&
            el.employerReferenceId !== ''
              ? el.employerReferenceId
              : '';
          el.employmentBeginDate = '2001-10-18';
          el.workStartTime = null;
          el.workEndTime = null;
          el.lengthOfEmploymentYears =
            lengthOfEmploymentYearsObj !== ''
              ? lengthOfEmploymentYearsObj.toString()
              : null;
          el.employmentEndDate = null;
          el.verifiedDate =
            el.verifiedDate !== undefined &&
            el.verifiedDate !== null &&
            el.verifiedDate !== ''
              ? buildDate(el.verifiedDate)
              : null;
        }

        EmpPropsArray.push(el);
      });
    }
    return EmpPropsArray;
  };
  const buildAddress = () => {
    const addressToAdd = [];
    try {
      const address = customerInformation.addressess;

      const addressObj: any = address.filter(
        (val: any) => val.addressType == 'PRIM'
      );
      const mailingAddress: any = address.filter(
        (val: any) => val.addressType == 'MAIL'
      );
      const anotherAddress: any = address.filter(
        (val: any) => val.addressType != 'MAIL' && val.addressType != 'PRIM'
      );
      if (addressObj.length > 0) {
        addressObj[0].addressType = 'PRIM';
        addressObj[0].addressLine1 = customerInformation.addressLine1;
        addressObj[0].addressLine2 = customerInformation.addressLine2;
        addressObj[0].city = customerInformation.city;
        addressObj[0].state = customerInformation.state;
        addressObj[0].postalCode = customerInformation.zip;
        addressToAdd.push(addressObj[0]);
      }
      if (mailingAddress.length > 0 && customerInformation.mailAddress == 'N') {
        mailingAddress[0].addressType = 'MAIL';
        mailingAddress[0].addressLine1 = customerInformation.addressLine1M;
        mailingAddress[0].addressLine2 = customerInformation.addressLine2M;
        mailingAddress[0].city = customerInformation.cityM;
        mailingAddress[0].state = customerInformation.stateM;
        mailingAddress[0].postalCode = customerInformation.zipM;
        addressToAdd.push(mailingAddress[0]);
      }
      if (addressObj.length == 0) {
        addressToAdd.push({
          addressId: '',
          addressType: 'PRIM',
          addressLine1: customerInformation.addressLine1,
          addressLine2: customerInformation.addressLine2,
          city: customerInformation.city,
          state: customerInformation.state,
          postalCode: customerInformation.zip,
          latitude: null,
          longitude: null,
        });
      }
      if (
        mailingAddress.length == 0 &&
        customerInformation.mailAddress == 'N'
      ) {
        addressToAdd.push({
          addressId: '',
          addressType: 'MAIL',
          addressLine1: customerInformation.addressLine1M,
          addressLine2: customerInformation.addressLine2M,
          city: customerInformation.cityM,
          state: customerInformation.stateM,
          postalCode: customerInformation.zipM,
          latitude: null,
          longitude: null,
        });
      }
      if (anotherAddress.length > 0) {
        anotherAddress.forEach((element: any) => {
          element.postalCode =
            element?.postalCode !== undefined &&
            element?.postalCode !== '     -    '
              ? element?.postalCode
              : '';
          addressToAdd.push(element);
        });
      }
      // eslint-disable-next-line no-console
      console.log('addressToAdd', addressToAdd);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e, 'Error in buildAddress');
    }
    return addressToAdd;
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const landlordReferences = () => {
    let ResidencesObj = Residences.landlordValue;
    try {
      // eslint-disable-next-line no-console
      console.log(customerInformation);
      if (
        ResidencesObj !== undefined &&
        ResidencesObj !== null &&
        ResidencesObj.length > 0
      ) {
        ResidencesObj[0].landlordFirstName = Residences.landlordFirstName;
        ResidencesObj[0].landlordLastName = Residences.landlordLastName;
        // eslint-disable-next-line prettier/prettier
        ResidencesObj[0].phoneNumber = Residences.phoneNumber !== undefined && Residences.phoneNumber !== null ? Residences.phoneNumber.replace(/\D/g, '') : '';
        ResidencesObj[0].monthlyRent =
          Residences.monthlyRent !== undefined &&
          Residences.monthlyRent !== null &&
          Residences.monthlyRent !== ''
            ? Residences.monthlyRent.replace(',', '')
            : '0.00';
        ResidencesObj[0].addressLine1 = null;
        ResidencesObj[0].addressLine2 = null;
        ResidencesObj[0].city = null;
        ResidencesObj[0].state = null;
        ResidencesObj[0].postalCode = '';
        ResidencesObj[0].phoneExtension = null;
        ResidencesObj[0].leaseHolderName = null;
        ResidencesObj[0].bestTimeToCall = null;
        ResidencesObj[0].leaseCompanyName = null;
        ResidencesObj[0].leaseTerm = null;
        ResidencesObj[0].leaseLengthYears = '0.000000';
        ResidencesObj[0].leaseLengthMonths = '0.000000';
        ResidencesObj[0].verifiedDate = null;
        ResidencesObj[0].active = 'Y';
        ResidencesObj[0].moveInDate =
          Residences.moveInDate !== undefined &&
          Residences.moveInDate !== null &&
          Residences.moveInDate !== ''
            ? buildDate(Residences.moveInDate)
            : null;
        for (let i = 1; i < ResidencesObj.length; i++) {
          ResidencesObj[i].postalCode =
            ResidencesObj[i].postalCode !== undefined &&
            ResidencesObj[i].postalCode !== '     -    '
              ? ResidencesObj[i].postalCode
              : '';
          ResidencesObj[i].verifiedDate =
            ResidencesObj[i].verifiedDate !== undefined &&
            ResidencesObj[i].verifiedDate !== null &&
            ResidencesObj[i].verifiedDate !== ''
              ? buildDate(ResidencesObj[i].verifiedDate)
              : null;
        }
      } else {
        ResidencesObj = [];
        ResidencesObj = [
          {
            landlordFirstName: Residences.landlordFirstName,
            landlordLastName: Residences.landlordLastName,
            phoneNumber:
              Residences.phoneNumber !== undefined &&
              Residences.phoneNumber !== null
                ? Residences.phoneNumber.replace(/\D/g, '')
                : '9999999999',
            monthlyRent:
              Residences.monthlyRent !== undefined &&
              Residences.monthlyRent !== null &&
              Residences.monthlyRent !== ''
                ? Residences.monthlyRent.replace(',', '')
                : '0.00',
            moveInDate:
              Residences.moveInDate !== undefined &&
              Residences.moveInDate !== null &&
              Residences.moveInDate !== ''
                ? buildDate(Residences.moveInDate)
                : null,
            active: 'Y',
            leaseLengthYears: '0.000000',
            leaseLengthMonths: '0.000000',
            leaseTerm: null,
            leaseCompanyName: null,
            phoneExtension: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            state: null,
            postalCode: '',
            leaseHolderName: null,
            bestTimeToCall: null,
          },
        ];
      }
      // eslint-disable-next-line no-console
      console.log('ResidencesObj', ResidencesObj);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'landlordReferences');
    }
    return ResidencesObj;
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const validateNextCustomer = (Type: string) => {
    // eslint-disable-next-line no-debugger
    //debugger;
    // if (createActiveTab == 0) {
    //   getEmployOptions();
    // }
    if (Type == 'Next') {
      const validate = validateCreateCustomer();
      if (!validate) {
        setErrorPopup(true);
        setisrequired(true);
      } else {
        if (
          validate &&
          ((DEStatus.approvalStatus != 'Approved' && createActiveTab == 2) ||
            (DEStatus.approvalStatus == 'Approved' && createActiveTab == 0))
        ) {
          setCreateActiveTab(createActiveTab + 1);
          setnextBtnText('Finish');
        } else if (validate && nextBtnText != 'Finish') {
          setCreateActiveTab(createActiveTab + 1);
        } else if (validate && nextBtnText == 'Finish') {
          updateCustomerInfo();
        }
        setisrequired(false);
      }
    } else if (Type == 'Back') {
      setCreateActiveTab(createActiveTab - 1);
      setnextBtnText('Next');
    }
  };
  const validateCreateCustomer = () => {
    let isValid = false;
    try {
      if (createActiveTab == 0) {
        isValid = validateTabOne() && validateCustomerTab() ? true : false;
      } else if (createActiveTab == 1) {
        isValid = validateTabTwo().isValidate;
      } else if (createActiveTab == 2) {
        isValid = validateTabThree().isValidate;
      } else if (createActiveTab == 3) {
        isValid = validateTabFour().isValidate;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'landlordReferences');
    }
    return isValid;
  };
  const hideErrorPopUp = () => {
    setErrorPopup(false);
    setValidateResult([]);
  };
  const pageLoadStateVariable = (getOptions: any) => {
    //const No = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    /* eslint-disable no-console */
    console.log('getOptions', getOptions);

    setgovTypeId(buildDropdownList(getOptions.governmentIdTypeOptions));
    setLegalOption(buildDropdownList(getOptions.resLegalHoldList));
    setsalutation(buildDropdownList(getOptions.salutationOptions));
    const buildStateDropDown: dropdown[] = [];
    if (
      getOptions.stateList != undefined &&
      getOptions.stateList != null &&
      getOptions.stateList.getStateResponse !== undefined &&
      getOptions.stateList.getStateResponse !== null &&
      getOptions.stateList.getStateResponse.length > 0
    ) {
      getOptions.stateList.getStateResponse.map((value: any, index: any) => {
        if (index == 0) {
          buildStateDropDown.push({ label: 'Select', value: '0' });
        }
        buildStateDropDown.push({
          label: value.stateAbbreviation,
          value: value.stateAbbreviation,
        });
      });
    }
    setstate(buildStateDropDown);
    setaddressinfoState(buildStateDropDown);
    setbestTimeToCall(buildDropdownList(getOptions.callTimeList, true));
    setphoneType(buildDropdownList(getOptions.phoneTypeList, true));
    setpreferredMethod(buildDropdownList(getOptions.contactMethodList));
    const buildRouteDropDown: dropdown[] = [];
    if (
      getOptions.getStoreRoutes != undefined &&
      getOptions.getStoreRoutes != null &&
      getOptions.getStoreRoutes.routeIds !== undefined &&
      getOptions.getStoreRoutes.routeIds !== null &&
      getOptions.getStoreRoutes.routeIds.length > 0
    ) {
      getOptions.getStoreRoutes.routeIds.map((value: any) => {
        buildRouteDropDown.push({
          label: value.description,
          value: value.routeRefCode,
        });
      });
    }
    setaddressInfoRoute(buildRouteDropDown);
    settaxExemptStatus(buildDropdownList(getOptions.taxExemptStatusList));
    setDropDownLoaded(true);
  };
  const buildDropdownList = (optionsList: any, sortSeq?: boolean) => {
    const options: dropdown[] = [];
    if (
      optionsList != undefined &&
      optionsList != null &&
      optionsList.length > 0
    ) {
      sortSeq === true
        ? optionsList.sort((a: any, b: any) =>
            a['displaySeq'] < b['displaySeq'] ? -1 : 1
          )
        : optionsList;
      optionsList.map((value: any, index: any) => {
        if (index == 0) {
          options.push({ label: 'Select', value: '0' });
        }
        options.push({ label: value.description, value: value.referenceCode });
      });
    }
    return options;
  };
  function openAlert() {
    setIsOpen(true);
  }

  function closeAlert() {
    setIsOpen(false);
  }
  const getAlerts = async () => {
    const getAlerts = await GetAlert(customerId);
    /* eslint-disable no-console */
    console.log('result inside getAlerts', getAlerts);
    buildActiveAlerts();
    //Alert list
    setobjAlertdata(getAlerts.alert !== undefined ? getAlerts.alert : []);
  };
  const buildActiveAlerts = async () => {
    let getAlerts: any = [];
    //if (updatedAlert == undefined || updatedAlert == null) {
    getAlerts = await GetCustomerAlert(customerId);
    getAlerts =
      getAlerts?.customerAlertByIdDTO?.value?.customeralert !== undefined
        ? getAlerts?.customerAlertByIdDTO?.value?.customeralert
        : undefined;

    const activeAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter((val: any) => val.alertCleared == 0)
        : [];
    const popupAlerts: any =
      getAlerts !== undefined
        ? getAlerts.filter(
            (val: any) =>
              val.alertCleared == 0 &&
              val.alertTypeId !== '3' &&
              val.alertTypeId !== 3
          )
        : [];
    // const inActiveAlerts: any =
    //   getAlerts !== undefined
    //     ? getAlerts.filter(
    //         (val: any) =>
    //           val.alertCleared != 0 &&
    //           val.alertTypeId !== '3' &&
    //           val.alertTypeId !== 3
    //       )
    //     : [];
    setAlertWizard(getAlerts !== undefined ? getAlerts : []);
    //Inactive alert
    //setinActiveAlerts(inActiveAlerts);
    //active alert
    setactiveAlert(activeAlerts);
    //Alerts without custom alerts
    setAlertUpdateObj(popupAlerts);
  };

  const CustomAlertval = (e: any) => {
    setCustomAlerttxt(e.target.value);
    if (
      e.target.value == undefined ||
      e.target.value == null ||
      e.target.value == ''
    ) {
      setdisableAlert(true);
    } else {
      setdisableAlert(false);
    }
  };
  const Assignalertclear = (
    action: 'add' | 'remove',
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // eslint-disable-next-line no-debugger
    //debugger;
    if (alertTypeId == '2' || alertTypeId == '4' || alertTypeId == '5') {
      setAlertAuthenticationNeeded(
        action ? newAlert : { removeAlert: true, alertTypeId: alertTypeId }
      );
      setauthenType('ALERT');
      racCoWorkerFunc('Assign Alert');
    }
    // const val: any = AlertWizard;
    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == '3'
    ) {
      if (action == 'remove') {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    activeAlertObj = alterAlert(action, newAlert, alertTypeId);
    /* eslint-disable no-console */
    // console.log('val', val);

    setAlertUpdateObj([...activeAlertObj]);
  };
  const alterAlert = (
    action: 'add' | 'remove',
    newAlert: any,
    alertTypeId?: any
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    let activeAlertObj: any = AlertUpdateObj;
    if (
      alertTypeId !== undefined &&
      alertTypeId !== null &&
      alertTypeId == '3'
    ) {
      if (action == 'remove') {
        setEnabletxt(true);
        setdisableAlert(false);
      } else {
        setEnabletxt(false);
        setdisableAlert(true);
      }
    }
    if (action == 'remove') {
      if (alertTypeId != '3') {
        const editAlert = activeAlertObj.findIndex(
          (val: any) => val.alertTypeId == alertTypeId
        );
        if (editAlert !== -1) {
          activeAlertObj[editAlert].alertCleared = 1;
        }
      } else {
        activeAlertObj = activeAlertObj.filter(
          (val: any) => val.alertTypeId != '3'
        );
      }
    } else {
      if (alertTypeId !== '2' && alertTypeId !== '4' && alertTypeId !== '5') {
        newAlert.alertCleared = 0;
        activeAlertObj.push(newAlert);
      }
    }
    return activeAlertObj;
  };

  const RemoveAlert = async (removeAlert: any, index: any) => {
    try {
      const ObjAlertWizard: any = AlertWizard;
      ObjAlertWizard.splice(index, 1);
      const payload: any = {
        alertTypes: removeAlert,
        customerId: parseInt(customerId),
        userId: '191197',
      };
      // setAlertWizard([...ObjAlertWizard]);
      // setAlertUpdateObj([...ObjAlertWizard]);
      /* eslint-disable no-console */
      console.log('payload', payload);
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      /* eslint-disable no-console */
      console.log('response', response);
      /* eslint-disable no-console */
      if (response !== undefined && response !== null) {
        buildActiveAlerts();
        //getAlerts();
      }
      setIsAlertLoading(false);
    } catch (e: any) {
      console.log('error while removing alert');
      setIsAlertLoading(false);
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignalert = () => {
    if (
      objAlertdata !== undefined &&
      objAlertdata !== null &&
      objAlertdata.length > 0
    ) {
      const objAlertdataL = objAlertdata.sort((a: any, b: any) =>
        a['alertTypeDescEn'] < b['alertTypeDescEn'] ? -1 : 1
      );
      return objAlertdataL.map((val: any, index: any) => {
        //const AlertWizardList: any = [];
        let inActiveAlert: any = [];
        const isAlertActive: boolean =
          AlertUpdateObj.filter(
            (val1: any) =>
              val1.alertTypeId == val.alertTypeId && val1.alertCleared == 0
          ).length > 0
            ? true
            : false;
        if (isAlertActive === false) {
          // inActiveAlert = inActiveAlerts.filter(
          //   (val1: any) => val1.alertTypeId == val.alertTypeId
          // );
          inActiveAlert = {
            alertText: '',
            alertTypeDescEn: val.alertTypeDescEn,
            alertTypeDescEs: val.alertTypeDescEs,
            alertCleared: 0,
            alertTypeId: val.alertTypeId,
            customerAlertId: '',
          };
          // inActiveAlert !== undefined &&
          // inActiveAlert !== null &&
          // inActiveAlert.length > 0
          //   ? {
          //       alertText: '',
          //       alertTypeDescEn: inActiveAlert[0].alertTypeDescEn,
          //       alertTypeDescEs: inActiveAlert[0].alertTypeDescEs,
          //       alertCleared: 0,
          //       alertTypeId: inActiveAlert[0].alertTypeId,
          //       customerAlertId: '',
          //     }
          //   : {
          //       alertText: '',
          //       alertTypeDescEn: val.alertTypeDescEn,
          //       alertTypeDescEs: val.alertTypeDescEs,
          //       alertCleared: 0,
          //       alertTypeId: val.alertTypeId,
          //       customerAlertId: '',
          //     };
        }
        //AlertWizard.filter((val1:any)=>val1.alertTypeId == val.alertTypeId && val.alertTypeId != "3")

        //let OtherAlert: any = [];
        let isOtherAlert = false;
        //let OtherAlertObj: any = [];
        if (index == objAlertdataL.length - 1) {
          // OtherAlert = objAlertdataL.filter(
          //   (val: any) => val.alertTypeId == '3'
          // );
          isOtherAlert =
            AlertUpdateObj.filter((val1: any) => val1.alertTypeId == '3')
              .length > 0
              ? true
              : false;
        }
        // const disableAlert =
        //   val.alertTypeId == '2' ||
        //   val.alertTypeId == '4' ||
        //   val.alertTypeId == '5'
        //     ? !isSecondAuthenValid
        //     : false;
        return index == objAlertdataL.length - 1 ? (
          <React.Fragment>
            {buildAlert(isAlertActive, index, inActiveAlert, val)}
            {buildAlert(
              isOtherAlert,
              index + 1,
              {
                alertCleared: 0,
                alertText: '',
                alertTypeDescEn: 'Other',
                alertTypeDescEs: null,
                alertTypeId: '3',
                customerAlertId: '',
              },
              {
                alertTypeDescEn: 'Other',
                alertTypeDescEs: null,
                alertTypeId: '3',
              }
            )}
          </React.Fragment>
        ) : val.alertTypeId !== '3' ? (
          buildAlert(isAlertActive, index, inActiveAlert, val)
        ) : null;
      });
    }
  };
  // const buildAlert = (
  //   isAlertExist: boolean,
  //   index: any,
  //   addAlertObj: any,
  //   val: any,
  //   disableAlert: boolean
  // ) => {
  //   return (
  //     <label className="list-group-item border-0 p-0 mb-3" key={index}>
  //       <input
  //         className="form-check-input me-1"
  //         type="checkbox"
  //         name="assignAlertChecked"
  //         checked={isAlertExist}
  //         onChange={() =>
  //           Assignalertclear(
  //             isAlertExist === false ? 'add' : 'remove',
  //             addAlertObj,
  //             val.alertTypeId
  //           )
  //         }
  //         disabled={disableAlert}
  //       />
  //       {val.alertTypeDescEn}
  //     </label>
  //   );
  // };
  const buildAlert = (
    isAlertExist: boolean,
    index: any,
    addAlertObj: any,
    val: any
  ) => {
    return (
      <Grid className="list-group-item border-0 p-0 mb-3" key={index}>
        <RACCheckBox
          className="form-check-input me-1 cust-checkbox"
          name="assignAlertChecked"
          checked={isAlertExist}
          onChange={() =>
            Assignalertclear(
              isAlertExist === false ? 'add' : 'remove',
              addAlertObj,
              val.alertTypeId
            )
          }
        />
        {val.alertTypeDescEn}
      </Grid>
    );
  };
  const AlertMessages = () => {
    if (activeAlert !== undefined && activeAlert !== null) {
      return activeAlert.map((val: any, index: any) => {
        if (val.alertCleared == '0' || val.alertCleared == 0) {
          return (
            <span className="alerts-widgarts-style me-2" key={index} id={index}>
              {
                /*customAlertSpan ? null : (*/
                val.alertTypeId !== '3' && val.alertTypeId !== 3 ? (
                  <a
                    onClick={(e: any) => ScrollToPage(val.alertTypeId, e)}
                    className="me-2"
                  >
                    <ExpandAlert className="alert-img-resize"></ExpandAlert>
                  </a>
                ) : null
                /*)*/
              }
              {val.alertTypeDescEn == 'Other'
                ? val.alertText
                : val.alertTypeDescEn}
              <a className="ms-2">
                <ExpandCancel
                  className="alertcancel-img-resize"
                  onClick={() =>
                    RemoveAlert(
                      [
                        {
                          alertTypeId: parseInt(val.alertTypeId),
                          customerAlertId: parseInt(val.customerAlertId),
                          alertText: '',
                          alertClear: 1,
                        },
                      ],
                      index
                    )
                  }
                ></ExpandCancel>
              </a>
            </span>
          );
        }
      });
    }
  };
  const ScrollToPage = (Id: any, e: any) => {
    e.preventDefault();
    const elementId: string =
      Id == '6' ? 'addressDiv' : Id == '9' ? 'ParentContactInformation' : '';

    const Tab: any = Id == '7' ? 1 : Id == '11' ? 2 : '';
    if (elementId !== '') {
      const titleElement = document.getElementById(elementId);
      if (titleElement !== null) {
        titleElement.scrollIntoView({ behavior: 'smooth' });
      }

      //Need to add redirection to Paymentschedule and capture agreement signture
    }
    if (Tab !== '') {
      setactivetab(Tab);
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const Assignsave_click = async () => {
    try {
      // eslint-disable-next-line no-debugger
      // debugger;
      const removeAlert = [];
      const AlertWizardObj: any = AlertWizard;
      // eslint-disable-next-line no-console
      console.log('AlertUpdateObj', AlertUpdateObj);
      for (let i = 0; i < AlertUpdateObj.length; i++) {
        const alertExist = AlertWizardObj.filter(
          (val: any) => AlertUpdateObj[i].alertTypeId == val.alertTypeId
        );
        if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared !== 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
              AlertUpdateObj[i].customerAlertId !== null &&
              AlertUpdateObj[i].customerAlertId !== ''
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != '3'
                ? parseInt(alertExist[0].customerAlertId)
                : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == '3' ? CustomAlerttxt : '',
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        } else if (
          AlertUpdateObj[i].customerAlertId !== null &&
          AlertUpdateObj[i].alertCleared == 1
          // eslint-disable-next-line sonarjs/no-duplicated-branches
        ) {
          removeAlert.push({
            alertTypeId: parseInt(AlertUpdateObj[i].alertTypeId),
            customerAlertId:
              AlertUpdateObj[i].customerAlertId !== undefined &&
              AlertUpdateObj[i].customerAlertId !== null &&
              AlertUpdateObj[i].customerAlertId !== ''
                ? parseInt(AlertUpdateObj[i].customerAlertId)
                : alertExist.length > 0 && AlertUpdateObj[i].alertTypeId != '3'
                ? parseInt(alertExist[0].customerAlertId)
                : null,
            alertText:
              AlertUpdateObj[i].alertTypeId == '3' ? CustomAlerttxt : '',
            alertClear: AlertUpdateObj[i].alertCleared,
          });
        }
      }
      const payload: any = {
        alertTypes: removeAlert,
        customerId: parseInt(customerId),
        userId: '191197',
      };
      /* eslint-disable no-console */
      console.log('payload', payload);
      /* eslint-disable no-console */
      setIsAlertLoading(true);
      const response: any = await UpdateAlert(payload, customerId);

      // eslint-disable-next-line no-console
      setCustomAlerttxt('');
      setEnabletxt(true);
      console.log('response', response);
      if (response !== undefined && response !== null) {
        await buildActiveAlerts();
      }
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    } catch (e: any) {
      setIsAlertLoading(false);
      //getAlerts();
      closeAlert();
    }
  };

  const Cancelclick = () => {
    setEnabletxt(true);
    setCustomAlerttxt('');
    closeAlert();
  };
  const Taxidclick = () => {
    setIstextboxdisable(false);
    if (taxexempt_toggle === true) {
      setTaxexempt(false);
      setTaxIDActive(false);
    } else {
      setTaxexempt(true);
      setTaxIDActive(true);
    }
  };

  function ValidateEmail(inputText: any) {
    // eslint-disable-next-line no-useless-escape
    const mailformat = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return mailformat.test(inputText);
  }

  const dateFormat = 'MM/DD/YYYY';
  // eslint-disable-next-line sonarjs/no-duplicate-string
  const phoneFormat = '($1) $2-$3';

  const ssn1Func = (e: any) => {
    if (e.target.value.length === 3) {
      (document.getElementById('a11y_ssntwo') as HTMLInputElement).focus();
    }
  };

  const ssn2Func = (e: any) => {
    if (e.target.value.length === 2) {
      (document.getElementById('a11y_ssnthree') as HTMLInputElement).focus();
    } else if (e.target.value.length === 0) {
      (document.getElementById('a11y_ssn') as HTMLInputElement).focus();
    }
  };

  const ssn3Func = (e: any) => {
    if (e.target.value.length === 0) {
      (document.getElementById('a11y_ssntwo') as HTMLInputElement).focus();
    }
  };

  const mailingAddress = () => {
    setcustomerInfoChange(true);
    const d: any = document.getElementsByName('MailingAddres');
    if (d[0].checked) {
      setcustomerInformation({ ...customerInformation, mailAddress: 'Y' });
      (
        document.getElementById('MailingAddressField') as HTMLInputElement
      ).focus();

      seteditInputBoxM(true);
    } else {
      setcustomerInformation({ ...customerInformation, mailAddress: 'N' });
      seteditInputBoxM(false);
    }
  };

  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };

  const HandleOnChange = (e: any) => {
    // customer information
    // basic information...
    // eslint-disable-next-line no-debugger
    debugger;
    setcustomerInfoChange(true);
    if (e.target.name === 'FirstName') {
      setcustomerInformation({
        ...customerInformation,
        firstName: formatTextChange(e.target.value).replace(
          /[^A-Z-'. a-z]/gi,
          ''
        ),
      });
    } else if (e.target.name === 'LastName') {
      setcustomerInformation({
        ...customerInformation,
        lastName: formatTextChange(e.target.value).replace(
          /[^A-Z-'. a-z]/gi,
          ''
        ),
      });
    } else if (e.target.name === 'Dob') {
      const InputTxt = e.target.value;
      /* eslint-disable no-console */
      console.log('InputTxt', InputTxt);
      const date: any = buildDateFormat2(InputTxt);
      /* eslint-disable no-console */
      console.log('InputTxt', InputTxt);
      const temp = InputTxt.replace(date);
      /* eslint-disable no-console */
      console.log('InputTxt', InputTxt);
      setcustomerInformation({ ...customerInformation, dateOfBirth: temp });
    }
  };
  const HandleOnChange1 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'Ssn1') {
      setcustomerInformation({
        ...customerInformation,
        ssn1: e.target.value.replace(/\D/g, ''),
      });

      ssn1Func(e);
    } else if (e.target.name === 'Ssn2') {
      setcustomerInformation({
        ...customerInformation,
        ssn2: e.target.value.replace(/\D/g, ''),
      });
      ssn2Func(e);
    } else if (e.target.name === 'Ssn3') {
      setcustomerInformation({
        ...customerInformation,
        ssn3: e.target.value.replace(/\D/g, ''),
      });
      ssn3Func(e);
    } else if (e.target.name === 'Dob1') {
      // const InputTxt = e.target.value;
      // const format: any = buildDate(e.target.value);
      // const temp = InputTxt.replace(format);
      setcustomerInformation({
        ...customerInformation,
        dateOfBirth: buildDate(e.target.value),
      });
    }
  };
  const HandleOnChange2 = (e: any) => {
    // eslint-disable-next-line
    //debugger;
    setcustomerInfoChange(true);
    if (e.target.name === 'GovernIDtype') {
      // eslint-disable-next-line no-debugger
      //debugger;
      const customerInformationObj = customerInformation;
      customerInformationObj.governmentIdType = e.target.value;
      if (e.target.value == 'NONE') {
        customerInformationObj.governmentId = '';
        customerInformationObj.governmentIdIssuedState = null;
      }
      if (e.target.value == 'PASS') {
        customerInformationObj.governmentIdIssuedState = null;
      }
      setcustomerInformation({
        ...customerInformationObj,
      });
      //governIdType(e);
    } else if (e.target.name === 'Emailaddress') {
      setcustomerInformation({
        ...customerInformation,
        emailAddress: e.target.value,
      });
      //ValidateEmail(e.target.value);
    } else if (e.target.name === 'GovernIDnum') {
      setcustomerInformation({
        ...customerInformation,
        governmentId: e.target.value.replace(/[^A-Z-'., a-z0-9]/gi, ''),
      });
    } else if (e.target.name === 'Middleinitial') {
      setcustomerInformation({
        ...customerInformation,
        middleInitial: e.target.value,
      });
    }
  };
  const HandleOnChange3 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'stateForBasicInfo') {
      setcustomerInformation({
        ...customerInformation,
        governmentIdIssuedState: e.target.value,
      });
    } else if (e.target.name === 'Salutation') {
      setcustomerInformation({
        ...customerInformation,
        salutation: e.target.value,
      });
    } else if (e.target.name === 'inlineRadioOptionsforjr') {
      setcustomerInformation({
        ...customerInformation,
        suffix: e.target.value,
      });
    } else if (e.target.name === 'RAC_Co_worker') {
      const value = customerInformation?.racCoworker == 'N' ? true : false;
      setauthenType('RACWORKER');
      racCoWorkerFunc('RAC Coworker');
    }
  };
  const handleOnchangeSkipHard = (type: 'S' | 'H') => {
    setskipHardObj(type);
    setauthenType('SKIP');
    racCoWorkerFunc('Assign Alert');
  };
  const HandleOnChange4 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'AddressLine1') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        address: formatTextChange(e.target.value),
      });
    } else if (e.target.name === 'AddressLine2') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        address1: formatTextChange(e.target.value),
      });
    }
  };
  const HandleOnChange5 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'Zip') {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      setUpdatePrimaryAddress({ ...updatePrimaryAddress, zip: cleaned });
    } else if (e.target.name === 'City') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        city: e.target.value.replace(/[^a-zA-Z ]/g, ''),
      });
    } else if (e.target.name === 'AddressInfoState') {
      setUpdatePrimaryAddress({
        ...updatePrimaryAddress,
        state: e.target.value,
      });
    } else if (e.target.name == 'MailingAddres') {
      mailingAddress();
    }
  };
  const HandleOnChange41 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'AddressLine1M') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        address: formatTextChange(e.target.value),
      });
    } else if (e.target.name === 'AddressLine2M') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        address1: formatTextChange(e.target.value),
      });
    }
  };
  const HandleOnChange51 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'ZipM') {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      setupdateMailingAddress({ ...updateMailingAddress, zip: cleaned });
    } else if (e.target.name === 'CityM') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        city: e.target.value.replace(/[^a-zA-Z ]/g, ''),
      });
    } else if (e.target.name === 'AddressInfoStateM') {
      setupdateMailingAddress({
        ...updateMailingAddress,
        state: e.target.value,
      });
    }
  };
  const HandleOnChange6 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'tax_id') {
      setcustomerInformation({
        ...customerInformation,
        federalTaxId: e.target.value,
      });
    } else if (e.target.name === 'tax_status') {
      setcustomerInformation({
        ...customerInformation,
        status: e.target.value,
      });
    } else if (e.target.name === 'tax_EffectiveDate') {
      setcustomerInformation({
        ...customerInformation,
        effectiveDate: e.target.value,
      });
    } else if (e.target.name === 'tax_ExpirationDate') {
      setcustomerInformation({
        ...customerInformation,
        expirationDate: e.target.value,
      });
    }
  };
  const HandleOnChange7 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'tax_appDenailDate') {
      setcustomerInformation({
        ...customerInformation,
        appDenialDate: e.target.value,
      });
    }

    //comm Preferences...
    else if (e.target.name == 'PaymentReminderCall') {
      setcustomerInformation({
        ...customerInformation,
        paymentRemainderCall: e.target.value,
      });
    } else if (e.target.name === 'TextMessageReminder') {
      setcustomerInformation({
        ...customerInformation,
        textMessageRemainder: e.target.value,
      });
    }
  };
  const HandleOnChange8 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'PreferredPaymentReceipt') {
      setcustomerInformation({
        ...customerInformation,
        preferredPaymentReceipt: e.target.value,
      });
    } else if (e.target.name === 'AcceptSolicitation') {
      setcustomerInformation({
        ...customerInformation,

        phoneSolicitationAllowed: e.target.value,
      });
    } else if (e.target.name === 'PreferredContactMethod') {
      setcustomerInformation({
        ...customerInformation,
        preferredMethod: e.target.value,
      });
    } else if (e.target.name === 'AcceptSolicitation1') {
      setcustomerInformation({
        ...customerInformation,

        mailSolicitationAllowed: e.target.value,
      });
    } else if (e.target.name === 'AcceptSolicitation2') {
      setcustomerInformation({
        ...customerInformation,

        emailSolicitationAllowed: e.target.value,
      });
    } else if (e.target.name === 'PreferredLanguage') {
      setcustomerInformation({
        ...customerInformation,
        preferredLanguage: e.target.value,
      });
    }
  };
  const HandleOnChange9 = (e: any) => {
    // eslint-disable-next-line
    //debugger
    if (e.target.name === 'PreferredRoute') {
      setPreferrefRoute(e.target.value);
      racCoWorkerFunc('Modify Customer Route');
      setauthenType('ROUTES');
    } else if (e.target.name === 'residenceInfoVerifiedCheckBox') {
      setResidencesvalues({
        ...Residences,
        verifiedCheckBox: e.target.checked === true ? new Date() : '',
      });
      //setresVerified(true);
    }
    setcustomerInfoChange(true);
    verification();
  };
  const HandleOnChange10 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'inlineRadioOptions') {
      setResidencesvalues({
        ...Residences,
        rented: e.target.checked,
        owned: !e.target.checked,
        mortgage: false,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'inlineRadioOptionsOwn') {
      setResidencesvalues({
        ...Residences,
        owned: e.target.checked,
        rented: !e.target.checked,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'inlinemortagechk') {
      setResidencesvalues({
        ...Residences,
        mortgage: e.target.checked,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'llname') {
      setResidencesvalues({
        ...Residences,
        landlordFirstName: formatTextChange(e.target.value).replace(
          /[^A-Z-'. a-z]/gi,
          ''
        ),
      });
      //setResidencesvalues({...Residences, verifiedCheckBox: false});
    } else if (e.target.name === 'llLastname') {
      setResidencesvalues({
        ...Residences,
        landlordLastName: formatTextChange(e.target.value).replace(
          /[^A-Z-'. a-z]/gi,
          ''
        ),
      });
    } else if (e.target.name === 'llnumber') {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      //This is raw data to pass on props
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, phoneFormat);
      setResidencesvalues({
        ...Residences,
        phoneNumber: temp,
      });
      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const HandleOnChange11 = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'llamount') {
      setResidencesvalues({
        ...Residences,
        monthlyRent: e.target.value,
      });
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'lldateMove') {
      const InputTxt = e.target.value;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);
      setResidencesvalues({
        ...Residences,
        moveInDate: temp,
      });
      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const HandleOnChange11M = (e: any) => {
    setcustomerInfoChange(true);
    if (e.target.name === 'llamountPaymentAmt') {
      const regex = /^[+]?\d*(?:[.,]\d*)?$/;
      if (regex.test(e.target.value)) {
        setResidencesvalues({
          ...Residences,
          monthlyMortgagePayment: e.target.value,
        });
      }
      //setresidenceverifiedCheckBox(false);
    } else if (e.target.name === 'llnumberCompany') {
      setResidencesvalues({
        ...Residences,
        mortgageCompanyName: formatTextChange(e.target.value),
      });
      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const setMoveInDate = (e: any, name: any) => {
    if (name === 'lldateOwn') {
      const InputTxt = e;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);
      setResidencesvalues({
        ...Residences,
        residenceSince: temp,
      });

      //setresidenceverifiedCheckBox(false);
    } else if (name === 'lldateMove') {
      const InputTxt = e;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);
      setResidencesvalues({
        ...Residences,
        moveInDate: temp,
      });

      //setresidenceverifiedCheckBox(false);
    }
    verification();
  };
  const Editclick = () => {
    seteditInputBox(false);
  };
  const EditclickM = () => {
    seteditInputBoxM(false);
  };
  const updateAddressInfo = () => {
    //seteditInputBox(true);

    setrunAddressDoc('P');
    getAddress('P');
    //seteditInputBox(true);
  };
  const updateAddressInfoM = () => {
    //seteditInputBoxM(true);

    setrunAddressDoc('M');
    getAddress('M');
  };
  const addressDocCancel = () => {
    // eslint-disable-next-line no-console
    console.log('runAddressDoc', runAddressDoc);
    //setValidateIsOpen(false);
    if (runAddressDoc == 'M') {
      seteditInputBoxM(true);
    }
    if (runAddressDoc == 'P') {
      seteditInputBox(true);
    }
  };
  // cancel button function for the edit address popup PC-CC-47
  const Cancelbutt = () => {
    const updatePrimaryAddress = {
      address: customerInformation?.addressLine1,
      address1: customerInformation?.addressLine2,
      zip: customerInformation?.zip,
      city: customerInformation?.city,
      state: customerInformation?.state,
    };
    setUpdatePrimaryAddress({ ...updatePrimaryAddress });
    seteditInputBox(true);
  };
  const CancelbuttM = () => {
    const updateMailingAddress = {
      address: customerInformation?.addressLine1M,
      address1: customerInformation?.addressLine2M,
      zip: customerInformation?.zipM,
      city: customerInformation?.cityM,
      state: customerInformation?.stateM,
    };
    setupdateMailingAddress({ ...updateMailingAddress });
    seteditInputBoxM(true);
  };

  const contactInfoArrayValidation = () => {
    let validate = true;
    phoneNumberProps.forEach((val: any) => {
      if (validate) {
        if (val.phoneNumber === '') {
          validate = false;
        }
        if (val.bestTimeToCall == '0') {
          validate = false;
        }
      }
    });
    return validate;
  };

  const addanotherContact = () => {
    if (contactInfoArrayValidation()) {
      const PhoneNumberLocal = phoneNumberProps;
      PhoneNumberLocal.push({
        phoneNumber: '',
        phoneType: 'CELL',
        extension: '',
        primary: 'N',
        callTimeType: '',
        note: '',
      });
      setphoneNumberProps([...PhoneNumberLocal]);
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeForAddToContact = (e: any) => {
    setcustomerInfoChange(true);
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    if (d == `phonenumber_${index}`) {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, phoneFormat);

      phoneNumberProps[index].phoneNumber = temp;
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `ExtforContact_${index}`) {
      phoneNumberProps[index].extension = e.target.value.replace(/\D/g, '');
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `BestTimeCall_${index}`) {
      phoneNumberProps[index].callTimeType = e.target.value;
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `PhoneType_${index}`) {
      //if(phoneNumberProps.length > 1){

      phoneNumberProps[index].phoneType = e.target.value;
      if (
        e.target.value == 'WORK' &&
        phoneNumberProps[index].primary == 'Y' &&
        phoneNumberProps.length !== 1
      ) {
        let primaryAssigned = false;
        for (let i = 0; i < phoneNumberProps.length; i++) {
          if (!primaryAssigned && phoneNumberProps[i].phoneType !== 'WORK') {
            primaryAssigned = true;
            phoneNumberProps[i].primary = 'Y';
          }
        }
      }
      phoneNumberProps[index].primary =
        e.target.value !== 'WORK' ? phoneNumberProps[index].primary : 'N';
      // }
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `PhoneInstructions_${index}`) {
      phoneNumberProps[index].note = formatTextChange(e.target.value);
      setphoneNumberProps([...phoneNumberProps]);
    } else if (d == `IsPrimary_${index}`) {
      phoneNumberProps[index].primary =
        e.target.checked &&
        (phoneNumberProps[index].phoneType == 'CELL' ||
          phoneNumberProps[index].phoneType == 'HOME' ||
          phoneNumberProps.length == 1)
          ? 'Y'
          : 'N';
      phoneNumberProps[index].primary =
        phoneNumberProps.length == 1 ? 'Y' : phoneNumberProps[index].primary;
      if (e.target.checked && phoneNumberProps.length > 1) {
        for (let i = 0; i < phoneNumberProps.length; i++) {
          if (i !== index) {
            phoneNumberProps[i].primary = 'N';
          }
        }
      }
      setphoneNumberProps([...phoneNumberProps]);
    }
  };

  const removeContactInfoDiv = (index: any) => {
    setcustomerInfoChange(true);
    const PhoneNumberLocal = phoneNumberProps;
    PhoneNumberLocal.splice(index, 1);
    setphoneNumberProps([...phoneNumberProps]);
  };

  const employementInfoArrayValidation = () => {
    let validate = true;
    EmpProps.forEach((val: any) => {
      if (
        validate !== false &&
        (val.employerName === '' ||
          val.employerPhoneNumber == '' ||
          val.addressLine1 == '' ||
          val.postalCode == null ||
          val.postalCode == '' ||
          val.city == '') &&
        val.active == 'Y'
      ) {
        validate = false;
      }
    });
    return validate;
  };

  const addanotherEmployement = () => {
    if (employementInfoArrayValidation()) {
      const EmpLocal = EmpProps;
      EmpLocal.push({
        employerReferenceId: '',
        employerName: '',
        takeHomePay: '',
        supervisorFirstName: '',
        supervisorLastName: '',
        jobTitle: '',
        employerPhoneNumber: '',
        employerPhoneExtension: null,
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        doNotCall: 'N',
        employmentBeginDate: '2021-01-01',
        workStartTime: null,
        workEndTime: null,
        lengthOfEmploymentYears: '0',
        employerPayschedule: '',
        employmentEndDate: null,
        daysPaid: '',
        doNotVisit: 'N',
        active: 'Y',
        verifiedDate: '',
      });
      setEmpProps([...EmpLocal]);
    }
  };

  const removeEmployementDiv = (index: any) => {
    setcustomerInfoChange(true);
    const EmpLocal = EmpProps;
    EmpLocal.splice(index, 1);
    setEmpProps([...EmpLocal]);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeForAddEmployeInfo = (e: any) => {
    setcustomerInfoChange(true);
    // eslint-disable-next-line
    debugger
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);
    // verified check box
    if (d == `verifiedCheckBox_${index}`) {
      EmpProps[index].verifiedDate =
        e.target.checked === true ? new Date() : '';
      setEmpProps([...EmpProps]);
    } else if (d == `verifiedActiveDiv_${index}`) {
      EmpProps[index].active = e.target.checked === true ? 'Y' : 'N';
      setEmpProps([...EmpProps]);
    }
    //company name
    else if (d == `companyName_${index}`) {
      EmpProps[index].employerName = formatTextChange(e.target.value);
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
    }
    // how long
    else if (d == `howLong_${index}`) {
      EmpProps[index].lengthOfEmploymentYears = e.target.value;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
    }

    // employer phone number
    else if (d == `employePhoneNum_${index}`) {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, phoneFormat);

      EmpProps[index].employerPhoneNumber = temp;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    }

    // do not call
    else if (d == `doNotCall_${index}`) {
      EmpProps[index].doNotCall = e.target.checked ? 'Y' : 'N';
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    }

    // pay day freq
    else if (d == `payDayFreq_${index}`) {
      EmpProps[index].employerPayschedule = e.target.value;
      EmpProps[index].daysPaid = '';
      EmpProps[index].verifiedDate = '';

      setEmpProps([...EmpProps]);
    }

    // day paid

    //date paid
    else if (d == `datePaid_${index}`) {
      EmpProps[index].daysPaid = e.target.value;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `takeHomePay_${index}`) {
      // const regex = /^[+]?\d*(?:[.,]\d*)?$/;
      // if (regex.test(e.target.value)) {
      EmpProps[index].takeHomePay = e.target.value;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      // }

      //setempInfoVerifiedCheckBox(false);
    } else if (d == `addressLine1_${index}`) {
      EmpProps[index].addressLine1 = formatTextChange(e.target.value);
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `addressLine2_${index}`) {
      EmpProps[index].addressLine2 = formatTextChange(e.target.value);
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `zip_${index}`) {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      EmpProps[index].postalCode = cleaned;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `city_${index}`) {
      // eslint-disable-next-line
      debugger
      EmpProps[index].city = e.target.value.replace(/[^a-z A-Z ]/g, '');
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `state_${index}`) {
      EmpProps[index].state = e.target.value;
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    } else if (d == `doNotVisit_${index}`) {
      EmpProps[index].doNotVisit = e.target.checked ? 'Y' : 'N';
      EmpProps[index].verifiedDate = '';
      setEmpProps([...EmpProps]);
      //setempInfoVerifiedCheckBox(false);
    }
    verification();
  };
  // const buildVechicleYear = () => {
  //   const dt = new Date();
  //   const year = dt.getFullYear() - 50;
  //   const yearList = [];
  //   for (let i = dt.getFullYear(); i > year; i--) {
  //     yearList.push(i);
  //   }
  //   return yearList.map((val, index) => {
  //     return (
  //       <option value={val} key={index}>
  //         {val}
  //       </option>
  //     );
  //   });
  // };
  const printTemplate = (data: any) => {
    // eslint-disable-next-line no-console
    console.log('data', data);
    return (
      <div style={{ display: 'block' }}>
        <div
          style={{
            width: '100%',
            fontFamily: 'Arial',
            margin: '80px auto 0px auto',
          }}
          id="printHTML"
        >
          {/*content starts*/}
          {/*Section 1 starts*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginBottom: '10px',
              }}
            >
              <div style={{ width: '66.66%', float: 'left' }}>
                <p style={{ fontSize: '13px', marginBottom: '10px' }}>
                  Rent-A- Center
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                  data.response !== undefined &&
                  data.response.length > 0
                    ? data.response[0].storeName +
                      ' ' +
                      '(' +
                      data.response[0].storeName +
                      ')'
                    : ''}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                  data.response !== undefined &&
                  data.response.length > 0
                    ? data.response[0].addressLine1
                    : ''}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                  data.response !== undefined &&
                  data.response.length > 0
                    ? data.response[0].city +
                      ' ' +
                      data.response[0].stateName +
                      ' ' +
                      data.response[0].fullZip
                    : ''}
                </p>
                <p
                  style={{
                    fontSize: '13px',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  {data !== undefined &&
                  data.response !== undefined &&
                  data.response.length > 0
                    ? data.response[0].workPhoneNumber.replace(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                        '($1) $2-$3'
                      )
                    : ''}
                </p>
              </div>
              <table style={{ width: '33.33%', float: 'right' }}>
                <thead />
                <tbody
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                  }}
                >
                  <tr
                    style={{
                      width: '15%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Date:</td>
                  </tr>
                  <tr
                    style={{
                      width: '85%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>{buildDateFormat2('')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*Section 1 ends*/}
          {/*Section 2 starts*/}
          <div style={{ width: '100%', float: 'left' }}>
            <div
              style={{
                width: '100%',
                float: 'left',
                marginBottom: '10px',
              }}
            >
              <table style={{ width: '75%', float: 'left' }}>
                <thead />
                <tbody
                  style={{
                    width: '100%',
                    float: 'left',
                    marginTop: '10px',
                  }}
                >
                  <tr
                    style={{
                      width: '4%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>To:</td>
                  </tr>
                  <tr
                    style={{
                      width: '96%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Rent-A-Center Field Service Center Tax Department
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '5%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Fax:</td>
                  </tr>
                  <tr
                    style={{
                      width: '95%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>(866) 706-2972</td>
                  </tr>
                  <tr
                    style={{
                      width: '4%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Re:</td>
                  </tr>
                  <tr
                    style={{
                      width: '96%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Tax Exempt Customer Documentation
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '33%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Pages (including cover page)
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '67%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      <span
                        style={{
                          borderBottom: '0.5px solid #707070',
                          width: '130px',
                          marginTop: '13px',
                          float: 'left',
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <p
                style={{
                  float: 'left',
                  marginTop: '5px',
                  fontSize: '13px',
                  marginLeft: '5px',
                }}
              >
                Please fax documentation to support this Tax Exempt Application.
              </p>
              <table
                style={{
                  width: '75%',
                  float: 'left',
                  marginBottom: '400px',
                }}
              >
                <thead />
                <tbody>
                  <tr
                    style={{
                      width: '100%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      Please complete the following:
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '20%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Customer Name:</td>
                  </tr>
                  <tr
                    style={{
                      width: '80%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>
                      {customerInformation?.firstName +
                        ' ' +
                        customerInformation?.lastName}
                    </td>
                  </tr>
                  <tr
                    style={{
                      width: '11%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Party ID:</td>
                  </tr>

                  <tr
                    style={{
                      width: '89%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>{customerId}</td>
                  </tr>
                  <tr
                    style={{
                      width: '25%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }}>Agreement Number:</td>
                  </tr>
                  <tr
                    style={{
                      width: '75%',
                      float: 'left',
                      textAlign: 'left',
                    }}
                  >
                    <td style={{ fontSize: '13px' }} />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/*Section 2 ends*/}
          {/*Section 3 starts*/}
          <div>
            <p
              style={{
                width: '100%',
                fontSize: '13px',
                float: 'left',
                marginBottom: '0px',
              }}
            >
              Legal Disclosure
            </p>
            <p style={{ width: '100%', fontSize: '13px', float: 'left' }}>
              TBD
            </p>
          </div>
          {/*Section 3 ends*/}
          {/*Content ends*/}
        </div>
      </div>
    );
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleChangeForAddReferenceInfo = (e: any) => {
    setcustomerInfoChange(true);
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    if (d == `verifiedCheckBox_${index}`) {
      ReferenceProps[index].verifiedDate = e.target.checked ? new Date() : '';
      setReferenceProps([...ReferenceProps]);
      //setrefVerified(true);
    } else if (d == `activeCheckBox_${index}`) {
      ReferenceProps[index].active = e.target.checked ? 'Y' : 'N';
      setReferenceProps([...ReferenceProps]);
      //setrefVerified(true);
    } else if (d == `parent-RelativeName_${index}`) {
      ReferenceProps[index].firstName = formatTextChange(
        e.target.value
      ).replace(/[^A-Z-'. a-z]/gi, '');
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `parent-RelativeLastName_${index}`) {
      ReferenceProps[index].lastName = formatTextChange(e.target.value).replace(
        /[^A-Z-'. a-z]/gi,
        ''
      );
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `phoneNumber_${index}`) {
      const cleaned = ('' + e.target.value).replace(/\D/g, '');
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, phoneFormat);

      ReferenceProps[index].phoneNumber = temp;
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `bestTimeToCall_${index}`) {
      ReferenceProps[index].bestTimeToCall = e.target.value;
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `relationShip_${index}`) {
      ReferenceProps[index].relationshipType = e.target.value;
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `addressLine1_${index}`) {
      ReferenceProps[index].addressLine1 = formatTextChange(e.target.value);
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `addressLine2_${index}`) {
      ReferenceProps[index].addressLine2 = formatTextChange(e.target.value);
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `zip_${index}`) {
      let cleaned = ('' + e.target.value).replace(/\D/g, '');
      cleaned =
        cleaned.length > 5
          ? cleaned.slice(0, 5) + '-' + cleaned.slice(5)
          : cleaned;
      ReferenceProps[index].postalCode = cleaned;
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `city_${index}`) {
      ReferenceProps[index].city = e.target.value.replace(/[^a-z A-Z ]/g, '');
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `state_${index}`) {
      ReferenceProps[index].state = e.target.value;
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    } else if (d == `doNotVisit_${index}`) {
      ReferenceProps[index].doNotCall = e.target.checked ? 'Y' : 'N';
      ReferenceProps[index].verifiedDate = '';
      setReferenceProps([...ReferenceProps]);
    }
    verification();
  };

  const referenceInfoArrayValidation = () => {
    let validate = true;
    ReferenceProps.forEach((val: any) => {
      if (
        validate !== false &&
        (val.firstName == '' ||
          val.phoneNumber == '' ||
          val.addressLine1 == '' ||
          val.postalCode == null ||
          val.postalCode == '' ||
          val.city == '') &&
        val.active == 'Y'
      ) {
        validate = false;
      }
    });
    return validate;
  };

  const addanotherReference = () => {
    if (referenceInfoArrayValidation()) {
      const RefLocal = ReferenceProps;
      RefLocal.push({
        verifiedDate: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        relationshipType: '',
        bestTimeToCall: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        city: '',
        state: '',
        active: 'Y',
        doNotCall: '',
      });
      setReferenceProps([...RefLocal]);
    }
  };

  const removeDivanotherReference = (index: any) => {
    const RefLocal = ReferenceProps;
    RefLocal.splice(index, 1);
    setReferenceProps([...RefLocal]);
  };

  const addanotherVehicle = () => {
    const VehicleLocal = Vehicle;
    VehicleLocal.push({
      vehicleMake: null,
      vehicleModel: null,
      vehicleYear: null,
      vehicleColor: null,
      vehicleVin: null,
      vehicleLicensePlate: null,
      vehRegState: null,
      vehPlanExpirationDate: null,
      vehFinancedFrom: null,
      vehMonthlyPayment: null,
    });
    setVehiclevalues([...Vehicle]);
  };

  const removeDivVehicle = (index: any) => {
    const VehicleLocal = Vehicle;
    VehicleLocal.splice(index, 1);
    setVehiclevalues([...Vehicle]);
  };

  const handleChangeForAddVehicleInfo = (e: any) => {
    // eslint-disable-next-line no-debugger
    debugger;
    setcustomerInfoChange(true);
    const d: any = e.target.name;
    const splitD: any = d.split('_');
    const index = parseInt(splitD[1]);

    if (d == `make_${index}`) {
      Vehicle[index].vehicleMake = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `model_${index}`) {
      Vehicle[index].vehicleModel = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `year_${index}`) {
      Vehicle[index].vehicleYear = e.target.value;
      setVehiclevalues([...Vehicle]);
    } else if (d == `color_${index}`) {
      Vehicle[index].vehicleColor = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `vin_${index}`) {
      //const cleaned = ('' + e.target.value).replace(/\D/g, '');
      Vehicle[index].vehicleVin = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `tag_${index}`) {
      //const cleaned = ('' + e.target.value).replace(/\D/g, '');
      Vehicle[index].vehicleLicensePlate = formatTextChange(e.target.value);
      setVehiclevalues([...Vehicle]);
    } else if (d == `tagState_${index}`) {
      Vehicle[index].vehRegState = e.target.value;
      setVehiclevalues([...Vehicle]);
    } else if (d == `tagExpirationDate_${index}`) {
      const InputTxt = e.target.value;
      const format: any = moment(InputTxt).format(dateFormat);
      const temp = InputTxt.replace(format);

      Vehicle[index].vehPlanExpirationDate = temp;
      setVehiclevalues([...Vehicle]);
    } else if (d == `whereIsAutoFinance_${index}`) {
      Vehicle[index].vehFinancedFrom = e.target.value;
      setVehiclevalues([...Vehicle]);
    } else if (d == `monthlyAutoPayment_${index}`) {
      //const regex = /^[+]?\d*(?:[,]\d*)?$/;
      //if (regex.test(e.target.value)) {
      Vehicle[index].vehMonthlyPayment = e.target.value;
      setVehiclevalues([...Vehicle]);
      //}
    }
  };

  // const CheckboxClick = (agreement_id: number, e: any) => {
  //   const checkActionValue: number[] = checkAction;
  //   if (e.target.checked === true) {
  //     settakePaymentOpen(false);
  //     checkActionValue.push(agreement_id);
  //     setcheckAction(checkActionValue);
  //   } else {
  //     const agreementsChecked = checkActionValue.filter(
  //       (value) => value !== agreement_id
  //     );
  //     setcheckAction(agreementsChecked);
  //     if (agreementsChecked.length !== 0) {
  //       settakePaymentOpen(false);
  //     } else {
  //       settakePaymentOpen(true);
  //     }
  //   }
  // };

  const GridShow = (agreementStatus: string) => {
    if (agreementStatus === 'Active') {
      setactiveExpand(!activeExpand);
    } else if (agreementStatus === 'InActive') {
      setinActiveExpand(!inActiveExpand);
    } else if (agreementStatus === 'Pending') {
      setpending(!pending);
    }
  };

  const getAgreement = async () => {
    setagreementInf(undefined);
    try {
      const res = await GetAgreementById(customerId, 'Y');
      /* eslint-disable no-console */
      const agreement =
        res != undefined && res.agreement !== undefined ? res.agreement : [];
      setagreementInf(agreement);
      console.log('getAgreement', res);
    } catch (e: any) {
      setagreementInf(null);
    }
    //setIsLoading(false);
    /* eslint-enable no-console */
  };
  const filterAgreement = (agreementStatusProp: string) => {
    let agreements = [];
    if (
      agreementInf !== undefined &&
      agreementInf !== '' &&
      agreementInf.length > 0
    ) {
      agreements = agreementInf.filter(
        (value: any) => value.agreementStatus == agreementStatusProp
      );
      if (agreementStatusProp == 'InActive') {
        agreements = agreementInf.filter(
          (value: any) =>
            value.agreementStatus != 'Active' &&
            value.agreementStatus != 'Pending'
        );
      }
    }
    return agreements;
  };
  const getClass = (agreementStatusProp: any) => {
    return agreementStatusProp == 'InActive'
      ? 'racpad-agr-link racpad-payment-inactive racpad-link'
      : agreementStatusProp == 'PaymentIssue'
      ? 'racpad-agr-link racpad-payment-fails racpad-link'
      : 'racpad-agr-link racpad-payment-success racpad-link';
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const AgreementGrid = (
    agreementStatusProp: string,
    accordianOpen: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    /* eslint-disable no-console */
    console.log('agreementInf', agreementInf);
    const agreements = filterAgreement(agreementStatusProp);
    if (
      agreements !== undefined &&
      agreements.length !== undefined &&
      agreements.length > 0
    ) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      return agreements.map((value: any, index: any) => {
        const alignCenter = 'cust-agr-center';
        const breakLine = 'line-break';
        const passDue =
          value.nextDueDate !== undefined &&
          value.nextDueDate !== null &&
          value.nextDueDate !== ''
            ? !isDueDatePassed(value.nextDueDate)
            : true;
        return (
          <React.Fragment key={'agreement' + index}>
            {index === 0 ? (
              <RACTableRow
                key={'agreement' + index}
                className="cust-agrt-title"
              >
                <RACTableCell
                  className="txt-subtile-grid"
                  colSpan={10}
                  key={'agreement' + index}
                >
                  <span
                    className={
                      accordianOpen === false
                        ? 'accordion-icon-grid me-2 accordion-grid-close'
                        : 'accordion-icon-grid me-2'
                    }
                    onClick={() => GridShow(agreementStatusProp)}
                  ></span>
                  {agreementStatusProp}
                </RACTableCell>
              </RACTableRow>
            ) : null}
            {accordianOpen === true ? (
              <RACTableRow
                className={
                  passDue === false
                    ? 'payment-issue agreement-row'
                    : 'agreement-row cust-agrt-title'
                }
              >
                <RACTableCell>
                  <RACCheckBox
                    className="form-check-input me-2 cust-checkbox"
                    //onClick={(e: any) => CheckboxClick(value.agreementId, e)}
                  />{' '}
                  <a
                    className={getClass(
                      passDue === false ? 'PaymentIssue' : agreementStatusProp
                    )}
                    style={{ padding: '3px !important' }}
                    href={`/agreement/info/details/${customerId}/${value.agreementId}`}
                  >
                    {value.agreementNumber}
                  </a>
                  {value.autoPay !== null &&
                  value.autoPay !== undefined &&
                  value.autoPay ? (
                    <span className="badge-container">AP</span>
                  ) : null}
                </RACTableCell>
                <RACTableCell
                  title={value.agreementDescription}
                  className={
                    value.agreementDescription !== undefined &&
                    value.agreementDescription !== null &&
                    value.agreementDescription !== ''
                      ? ''
                      : alignCenter
                  }
                >
                  {value.agreementDescription !== undefined &&
                  value.agreementDescription !== null &&
                  value.agreementDescription !== ''
                    ? truncString(value.agreementDescription, 35, '...')
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  title={value.agreementType}
                  className={
                    value.agreementType !== undefined &&
                    value.agreementType !== null &&
                    value.agreementType !== ''
                      ? ''
                      : alignCenter
                  }
                >
                  {value.agreementType !== undefined &&
                  value.agreementType !== null &&
                  value.agreementType !== ''
                    ? truncString(value.agreementType, 17, '...')
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.storeNumber !== undefined &&
                    value.storeNumber !== null &&
                    value.storeNumber !== ''
                      ? ''
                      : alignCenter
                  }
                >
                  {value.storeNumber !== undefined &&
                  value.storeNumber !== null &&
                  value.storeNumber !== ''
                    ? value.storeNumber
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.numberOfPayments !== undefined &&
                    value.numberOfPayments !== null &&
                    value.numberOfPayments !== ''
                      ? 'text-end'
                      : alignCenter
                  }
                >
                  {value.numberOfPayments !== undefined &&
                  value.numberOfPayments !== null &&
                  value.numberOfPayments !== ''
                    ? value.numberOfPayments
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.totalCost !== undefined &&
                    value.totalCost !== null &&
                    value.totalCost !== ''
                      ? 'line-break text-end'
                      : alignCenter
                  }
                >
                  {value.totalCost !== undefined &&
                  value.totalCost !== null &&
                  value.totalCost !== ''
                    ? '$ '
                    : '-'}

                  {value.totalCost !== undefined &&
                  value.totalCost !== null &&
                  value.totalCost !== ''
                    ? parseFloat(value.totalCost)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.paymentSchedule !== undefined &&
                    value.paymentSchedule !== null &&
                    value.paymentSchedule !== ''
                      ? breakLine
                      : alignCenter
                  }
                >
                  {value.paymentSchedule !== undefined &&
                  value.paymentSchedule !== null &&
                  value.paymentSchedule !== ''
                    ? value.paymentSchedule
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.nextDueDate !== undefined &&
                    value.nextDueDate !== null &&
                    value.nextDueDate !== ''
                      ? breakLine
                      : alignCenter
                  }
                >
                  {value.nextDueDate !== undefined &&
                  value.nextDueDate !== null &&
                  value.nextDueDate !== ''
                    ? nextDueDayWithDate(value.nextDueDate)
                    : '-'}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.amountDue !== undefined &&
                    value.amountDue !== null &&
                    value.amountDue !== ''
                      ? 'text-end line-break'
                      : alignCenter
                  }
                >
                  {value.amountDue !== undefined &&
                  value.amountDue !== null &&
                  value.amountDue !== ''
                    ? '$ '
                    : '-'}

                  {value.amountDue !== undefined &&
                  value.amountDue !== null &&
                  value.amountDue !== ''
                    ? parseFloat(value.amountDue)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : ''}
                </RACTableCell>
                <RACTableCell
                  className={
                    value.agreementSubstatus !== undefined &&
                    value.agreementSubstatus !== null &&
                    value.agreementSubstatus !== ''
                      ? breakLine
                      : alignCenter
                  }
                >
                  {value.agreementSubstatus !== undefined &&
                  value.agreementSubstatus !== null &&
                  value.agreementSubstatus !== ''
                    ? value.agreementSubstatus
                    : '-'}
                </RACTableCell>
              </RACTableRow>
            ) : null}
          </React.Fragment>
        );
      });
    }
  };
  const isDueDatePassed = (date: any) => {
    /* eslint-disable no-console */
    console.log(date);
    const date1: any = new Date(date);
    const cMonth = dt.getMonth() + 1;
    const cDate = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
    const cYear = dt.getFullYear();
    const currentDate: any = new Date(`${cMonth}/${cDate}/${cYear}`);
    const diffTime = Math.abs(currentDate - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? false : true;
  };
  const nextDueDayWithDate = (date: any) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date(date);
    // eslint-disable-next-line no-console
    console.log('today', today);
    const index = today.getDay();
    // eslint-disable-next-line no-console
    console.log('today', days[index] + ', ' + date);
    return date !== undefined && date !== null && date !== ''
      ? days[index] + ', ' + date
      : '';
  };
  const truncString = (str: any, max: number, add: any) => {
    add = add || '...';
    return str !== undefined &&
      str !== null &&
      typeof str === 'string' &&
      str.length > max
      ? str.substring(0, max) + add
      : str;
  };
  const hideButtons = () => {
    setactivateClub(!activateClub);
    //setClubSatus(!activateClub);
  };

  // const dynamicDropdown = (val: any, isSort?: boolean) => {
  //   if (val !== undefined && val !== null && val.length > 0) {
  //     val =
  //       isSort === false
  //         ? val
  //         : val.sort((a: any, b: any) => (a['Text'] < b['Text'] ? -1 : 1));
  //     return val.map((value: any) => {
  //       return (
  //         <option key={value.name} value={value.value}>
  //           {value.label}
  //         </option>
  //       );
  //     });
  //   }
  // };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const employerInfoFunc = () => {
    return EmpProps.map((data: any, index: any) => {
      const isActive: boolean =
        data?.active !== undefined &&
        data?.active !== '' &&
        data?.active !== null &&
        data?.active !== 'null' &&
        data?.active == 'Y'
          ? true
          : false;
      const dayPaidOptions: dropdown[] =
        data?.employerPayschedule == 'SEMI'
          ? dayPaid5
          : data?.employerPayschedule == 'WK' ||
            data?.employerPayschedule == 'BIWK'
          ? dayPaid1
          : data?.employerPayschedule == 'MON'
          ? dayPaid3
          : [];
      // eslint-disable-next-line no-console
      console.log(
        'employerPayschedule',
        data?.employerPayschedule == 'SEMI'
          ? dayPaid5
          : data?.employerPayschedule == 'WK' ||
            data?.employerPayschedule == 'BIWK'
          ? dayPaid1
          : data?.employerPayschedule == 'MON'
          ? dayPaid3
          : null
      );
      //Employer information starts here
      // eslint-disable-next-line no-console
      console.log('employerPayschedule', data?.employerPayschedule);
      return (
        <Grid container key={index} className="mb-1">
          <Card className="card w-100">
            <CardContent id="employe_info_div">
              <Grid container id={`anotherEmployeInfo_${index}`}>
                <Grid item md={10} className="mb-0">
                  <Typography className="sub-title">
                    Basic Information
                  </Typography>
                </Grid>
                <Grid item md={2} className="text-right">
                  <Grid container spacing={2}>
                    {pageType == undefined || pageType == 'customer' ? (
                      <Grid item md={6}>
                        <Grid item className="form-check">
                          <RACCheckBox
                            label="Verified"
                            size="small"
                            className="cust-checkbox"
                            name={`verifiedCheckBox_${index}`}
                            onChange={(e: any) => {
                              handleChangeForAddEmployeInfo(e);
                            }}
                            checked={
                              data?.verifiedDate !== undefined &&
                              data?.verifiedDate !== '' &&
                              data?.verifiedDate !== null &&
                              data?.verifiedDate !== 'null'
                                ? true
                                : false
                            }
                          />
                          {/* <input
                        className="form-check-input"
                        type="checkbox"
                        id={`verifiedCheckBoxDiv_${index}`}
                        name={`verifiedCheckBox_${index}`}
                        checked={
                          data?.verifiedDate !== undefined &&
                          data?.verifiedDate !== '' &&
                          data?.verifiedDate !== null &&
                          data?.verifiedDate !== 'null'
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          handleChangeForAddEmployeInfo(e);
                        }}
                        disabled={!isActive}
                      />
                      <label
                        className="form-check-label racpad-margin"
                        htmlFor="flexCheckDefault77"
                      >
                        Verified
                      </label> */}
                        </Grid>
                      </Grid>
                    ) : null}
                    {pageType == undefined || pageType == 'customer' ? (
                      <Grid item md={6}>
                        <Grid item className="form-check floatRight">
                          <RACCheckBox
                            label="Active"
                            size="small"
                            className="cust-checkbox"
                            name={`verifiedActiveDiv_${index}`}
                            onChange={(e: any) => {
                              handleChangeForAddEmployeInfo(e);
                            }}
                            checked={
                              data?.active !== undefined &&
                              data?.active !== '' &&
                              data?.active !== null &&
                              data?.active !== 'null' &&
                              data?.active == 'Y'
                                ? true
                                : false
                            }
                          />
                          {/* <input
                        className="form-check-input"
                        type="checkbox"
                        id={`verifiedActiveDiv_${index}`}
                        name={`verifiedActiveDiv_${index}`}
                        checked={
                          data?.active !== undefined &&
                          data?.active !== '' &&
                          data?.active !== null &&
                          data?.active !== 'null' &&
                          data?.active == 'Y'
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          handleChangeForAddEmployeInfo(e);
                        }}
                        disabled={
                          data?.employerReferenceId == '' ? true : false
                        }
                      />
                      <label
                        className="form-check-label racpad-margin"
                        htmlFor="flexCheckDefault77"
                      >
                        Active
                      </label> */}
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-3">
                <Grid item md={4} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={'text'}
                    inputlabel="Company Name"
                    name={`companyName_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.employerName !== undefined ? data?.employerName : ''
                    }
                    disabled={!isActive}
                    errorMessage={
                      data?.employerName !== undefined &&
                      data?.employerName == '' &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                  />
                  {/* <label htmlFor="company-name" className="form-label">
                  Company Name
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`companyNameDiv_${index}`}
                  name={`companyName_${index}`}
                  value={data?.employerName}
                  data-testid={`companyTextBox_${index}`}
                  maxLength={30}
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  disabled={!isActive}
                />
                {data?.employerName !== undefined &&
                data?.employerName == '' &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={4} lg={2}>
                  <RACSelect
                    inputLabel="How Long?"
                    options={howLongObj}
                    isDisabled={!isActive}
                    defaultValue={
                      data?.lengthOfEmploymentYears !== undefined &&
                      data?.lengthOfEmploymentYears !== null &&
                      data?.lengthOfEmploymentYears !== 'NaN'
                        ? data?.lengthOfEmploymentYears
                        : '0'
                    }
                    loading={
                      howLongObj.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `howLong_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    {...(howLongObj.length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="a11y_howlong" className="form-label">
                  How Long?
                </label>
                <select
                  className="form-select"
                  id={`howLongDiv_${index}`}
                  name={`howLong_${index}`}
                  data-testid={`HowLongSelect_${index}`}
                  value={
                    data?.lengthOfEmploymentYears !== undefined &&
                    data?.lengthOfEmploymentYears !== null &&
                    data?.lengthOfEmploymentYears !== 'NaN'
                      ? data?.lengthOfEmploymentYears
                      : '0'
                  }
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  disabled={!isActive}
                >
                  <option selected value="0">
                    Select
                  </option>
                  <option value="1">0 to 1 year</option>
                  <option value="2">1 to 5 years</option>
                  <option value="3">5+ years</option>
                </select> */}
                  {/* {data?.lengthOfEmploymentYears !== undefined &&
                (data?.lengthOfEmploymentYears == '' ||
                  data?.lengthOfEmploymentYears == '0') &&
                isrequired === true ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={4} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={
                      data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Employer Phone Number"
                    name={`employePhoneNum_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={14}
                    value={
                      data?.employerPhoneNumber !== undefined
                        ? formatPhone(data?.employerPhoneNumber)
                        : ''
                    }
                    disabled={
                      (data?.doNotCall !== undefined &&
                        data?.doNotCall == 'Y') ||
                      isActive === false
                        ? true
                        : false
                    }
                    errorMessage={
                      (data?.employerPhoneNumber == undefined ||
                        data?.employerPhoneNumber == null ||
                        data?.employerPhoneNumber == '') &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : isrequired === true &&
                          data?.employerPhoneNumber.length < 10 &&
                          data?.active == 'Y'
                        ? PHONE_ERROR_MESSAGE
                        : ''
                    }
                  />
                  {/* <label htmlFor="a11y_empphone" className="form-label">
                  Employer Phone Number
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <input
                  type={
                    data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                      ? 'password'
                      : 'text'
                  }
                  className="form-control"
                  id={`employePhoneNumDiv_${index}`}
                  name={`employePhoneNum_${index}`}
                  value={
                    data?.employerPhoneNumber !== undefined
                      ? formatPhone(data?.employerPhoneNumber)
                      : ''
                  }
                  data-testid={`phoneTextBox_${index}`}
                  maxLength={14}
                  disabled={
                    (data?.doNotCall !== undefined && data?.doNotCall == 'Y') ||
                    isActive === false
                      ? true
                      : false
                  }
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                /> */}
                  {/* {(data?.employerPhoneNumber == undefined ||
                  data?.employerPhoneNumber == null ||
                  data?.employerPhoneNumber == '') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : isrequired === true &&
                  data?.employerPhoneNumber.length < 10 &&
                  data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">
                    Please enter valid phone number
                  </label>
                ) : null} */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <Grid item className="form-check mt-4 ps-0">
                    <RACCheckBox
                      label="Do Not Call"
                      size="small"
                      className="cust-checkbox"
                      name={`doNotCall_${index}`}
                      onChange={(e: any) => {
                        handleChangeForAddEmployeInfo(e);
                      }}
                      checked={
                        data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                          ? true
                          : false
                      }
                    />
                    {/* <input
                    className="form-check-input"
                    type="checkbox"
                    id={`doNotCallDiv_${index}`}
                    name={`doNotCall_${index}`}
                    data-testid={`DoNotCallCheck_${index}`}
                    checked={
                      data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                        ? true
                        : false
                    }
                    disabled={!isActive}
                    onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  />
                  <label
                    className="form-check-label mt-1"
                    htmlFor="do-not-call"
                  >
                    Do Not Call
                  </label> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-3">
                <Grid item md={12}>
                  <Typography variant="h6" className="sub-title">
                    Income Verification
                  </Typography>
                </Grid>
                <Grid item md={3} lg={2} className="pt-0">
                  <RACSelect
                    inputLabel="Payday Frequency"
                    options={payDayfrequency}
                    defaultValue={
                      data?.employerPayschedule !== undefined &&
                      data?.employerPayschedule !== null &&
                      data?.employerPayschedule !== ''
                        ? data?.employerPayschedule
                        : '0'
                    }
                    required={data?.active == 'Y' ? true : false}
                    loading={
                      payDayfrequency.length == 0 && !dropDownLoaded
                        ? true
                        : false
                    }
                    isDisabled={!isActive}
                    onChange={(e: any) => {
                      e.target.name = `payDayFreq_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    errorMessage={
                      data?.employerPayschedule !== undefined &&
                      (data?.employerPayschedule == '' ||
                        data?.employerPayschedule == '0') &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(payDayfrequency.length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="ally-payday" className="form-label">
                  Payday Frequency
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <select
                  className="form-select"
                  id={`payDayFreq_${index}`}
                  name={`payDayFreq_${index}`}
                  data-testid={`PayDaySelect_${index}`}
                  value={
                    data?.employerPayschedule !== undefined
                      ? data?.employerPayschedule
                      : ''
                  }
                  disabled={!isActive}
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                >
                  <option selected value="0">
                    Select
                  </option>
                  {dynamicDropdown(payDayfrequency)}
                </select>
                {data?.employerPayschedule !== undefined &&
                (data?.employerPayschedule == '' ||
                  data?.employerPayschedule == '0') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={2} lg={2} className="pt-0">
                  <RACSelect
                    inputLabel="Day Paid"
                    options={dayPaidOptions}
                    defaultValue={
                      data?.daysPaid !== undefined &&
                      data?.daysPaid !== null &&
                      data?.daysPaid !== ''
                        ? data?.daysPaid
                        : '0'
                    }
                    required={
                      data?.active == 'Y' &&
                      data?.employerPayschedule != undefined &&
                      data?.employerPayschedule != null &&
                      data?.employerPayschedule != '0' &&
                      data?.employerPayschedule != '' &&
                      data?.employerPayschedule != 'DAILY'
                        ? true
                        : false
                    }
                    isDisabled={
                      !isActive ||
                      data?.employerPayschedule == 'DAILY' ||
                      data?.employerPayschedule == undefined ||
                      data?.employerPayschedule == null ||
                      data?.employerPayschedule == '' ||
                      data?.employerPayschedule == '0'
                    }
                    loading={
                      dayPaidOptions.length == 0 && !dropDownLoaded
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `datePaid_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    errorMessage={
                      (data?.employerPayschedule == 'WK' ||
                        data?.employerPayschedule == 'SEMI' ||
                        data?.employerPayschedule == 'MON' ||
                        data?.employerPayschedule == 'BIWK') &&
                      (data?.daysPaid == undefined ||
                        data?.daysPaid == null ||
                        data?.daysPaid == '' ||
                        data?.daysPaid == '0') &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(dayPaidOptions.length == 0 &&
                      data?.employerPayschedule != 'DAILY' &&
                      data?.employerPayschedule !== '0' &&
                      data?.employerPayschedule != null &&
                      data?.employerPayschedule != undefined &&
                      data?.employerPayschedule != '' &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="a11y_daypaid" className="form-label">
                  Day Paid
                  {data?.active == 'Y' &&
                  data?.employerPayschedule != undefined &&
                  data?.employerPayschedule != null &&
                  data?.employerPayschedule != '0' &&
                  data?.employerPayschedule != '' &&
                  data?.employerPayschedule != 'DAILY' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <select
                  className="form-select"
                  id={`datePaid_${index}`}
                  name={`datePaid_${index}`}
                  data-testid={`DayPaidSelect_${index}`}
                  value={data?.daysPaid !== undefined ? data?.daysPaid : ''}
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  disabled={
                    data?.employerPayschedule == undefined ||
                    data?.employerPayschedule == null ||
                    data?.employerPayschedule == '0' ||
                    data?.employerPayschedule == '' ||
                    data?.employerPayschedule == 'DAILY' ||
                    isActive === false
                      ? true
                      : false
                  }
                >
                  <option selected value="">
                    Select
                  </option>
                  {dynamicDropdown(dayPaidOptions, false)}
                </select> */}
                  {/* {(data?.employerPayschedule == 'WK' ||
                  data?.employerPayschedule == 'SEMI' ||
                  data?.employerPayschedule == 'MON' ||
                  data?.employerPayschedule == 'BIWK') &&
                (data?.daysPaid == undefined ||
                  data?.daysPaid == null ||
                  data?.daysPaid == '' ||
                  data?.daysPaid == '0') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={2} lg={2} className="pt-0">
                  <RACTextbox
                    isCurrency={true}
                    type={'number'}
                    digitFormat={'currency'}
                    inputlabel="Take-Home Pay"
                    name={`takeHomePay_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={10}
                    value={
                      data?.takeHomePay !== undefined &&
                      data?.takeHomePay !== null &&
                      data?.takeHomePay !== 'NaN'
                        ? data?.takeHomePay
                        : '0.00'
                    }
                    disabled={!isActive}
                    className="custmer-digit"
                    dollarTextClassName="cust-digit-dollar1"
                  />
                  {/* <label htmlFor="a11y_takehome" className="form-label">
                  Take-Home Pay
                </label>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-adon">
                    $
                  </span>
                  <input
                    type="text"
                    id={`takeHomePayDiv_${index}`}
                    className="form-control txtBox-Radius align-right"
                    name={`takeHomePay_${index}`}
                    onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    value={
                      data?.takeHomePay !== undefined &&
                      data?.takeHomePay !== null &&
                      data?.takeHomePay !== 'NaN'
                        ? data?.takeHomePay
                        : '0.00'
                    }
                    onBlur={() => {
                      EmpProps[index].takeHomePay = parseFloat(
                        data?.takeHomePay
                      )
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                      setEmpProps([...EmpProps]);
                    }}
                    maxLength={15}
                    disabled={!isActive}
                  />
                </div> */}
                </Grid>
                {/* <div className="col-md-12">
                <label className="form-label-value">
                  Approximate next three pay days:{' '}
                </label>
                {/* need to uncommand 
                <label className=" form-label ms-1">
                  May 4th, May 11th May 18th {EmpProps},{secondday},{thirddday} 
                </label>
              </div> */}
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6" className="sub-title">
                    Address Information
                  </Typography>
                </Grid>
                <Grid item md={3} lg={2} className="pt-0">
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Address Line 1"
                    name={`addressLine1_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.addressLine1 !== undefined ? data.addressLine1 : ''
                    }
                    disabled={
                      (data?.doNotVisit !== undefined &&
                        data?.doNotVisit == 'Y') ||
                      isActive === false
                        ? true
                        : false
                    }
                    errorMessage={
                      (data?.addressLine1 == undefined ||
                        data?.addressLine1 == null ||
                        data?.addressLine1 == '') &&
                      data?.active == 'Y' &&
                      isrequired === true
                        ? FIELD_ERROR
                        : ''
                    }
                  />
                  {/* <label htmlFor="a11y_address1" className="form-label">
                  Address Line 1
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <input
                  type={
                    data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                      ? 'password'
                      : 'text'
                  }
                  className="form-control"
                  id={`addressLine1Div_${index}`}
                  name={`addressLine1_${index}`}
                  maxLength={40}
                  value={
                    data?.addressLine1 !== undefined ? data.addressLine1 : ''
                  }
                  disabled={
                    (data?.doNotVisit !== undefined &&
                      data?.doNotVisit == 'Y') ||
                    isActive === false
                      ? true
                      : false
                  }
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  data-testid={`adrs1TextBox_${index}`}
                /> */}
                  {/* {(data?.addressLine1 == undefined ||
                  data?.addressLine1 == null ||
                  data?.addressLine1 == '') &&
                data?.active == 'Y' &&
                isrequired === true ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={3} lg={2} className="pt-0">
                  <RACTextbox
                    isCurrency={false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Address Line 2"
                    name={`addressLine2_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.addressLine2 !== undefined ? data?.addressLine2 : ''
                    }
                    disabled={
                      (data?.doNotVisit !== undefined &&
                        data?.doNotVisit == 'Y') ||
                      isActive === false
                        ? true
                        : false
                    }
                  />
                  {/* <label htmlFor="a11y_address2" className="form-label">
                  Address Line 2
                </label>
                <input
                  type={
                    data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                      ? 'password'
                      : 'text'
                  }
                  className="form-control"
                  id={`addressLine2Div_${index}`}
                  name={`addressLine2_${index}`}
                  maxLength={40}
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  value={
                    data?.addressLine2 !== undefined ? data?.addressLine2 : ''
                  }
                  disabled={
                    (data?.doNotVisit !== undefined &&
                      data?.doNotVisit == 'Y') ||
                    isActive === false
                      ? true
                      : false
                  }
                  data-testid={`adrs2TextBox_${index}`}
                /> */}
                </Grid>
                <Grid item md={3} lg={2} className="pt-0">
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="Zip"
                    name={`zip_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={
                      data?.postalCode !== undefined ? data?.postalCode : ''
                    }
                    disabled={
                      (data?.doNotVisit !== undefined &&
                        data?.doNotVisit == 'Y') ||
                      isActive === false
                        ? true
                        : false
                    }
                    errorMessage={
                      (data?.postalCode == undefined ||
                        data?.postalCode == null ||
                        data?.postalCode == '') &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : isrequired === true &&
                          data?.active == 'Y' &&
                          data?.postalCode.length != 5 &&
                          data?.postalCode.length != 10
                        ? INVALID_ZIP
                        : ''
                    }
                  />
                  {/* <label htmlFor="a11y_zip" className="form-label">
                  Zip
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label> */}
                  {/* <input
                  type={
                    data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                      ? 'password'
                      : 'text'
                  }
                  className="form-control"
                  id={`zipDiv_${index}`}
                  name={`zip_${index}`}
                  value={data?.postalCode !== undefined ? data?.postalCode : ''}
                  maxLength={10}
                  disabled={
                    (data?.doNotVisit !== undefined &&
                      data?.doNotVisit == 'Y') ||
                    isActive === false
                      ? true
                      : false
                  }
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  data-testid={`zipTextBox_${index}`}
                />
                {(data?.postalCode == undefined ||
                  data?.postalCode == null ||
                  data?.postalCode == '') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : isrequired === true &&
                  data?.active == 'Y' &&
                  data?.postalCode.length != 5 &&
                  data?.postalCode.length != 10 ? (
                  <label className="mandatoryfield validation">
                    Invalid Zip
                  </label>
                ) : null} */}
                </Grid>
                <Grid item md={3} lg={2} className="pt-0">
                  <RACTextbox
                    isCurrency={false}
                    required={data?.active == 'Y' ? true : false}
                    type={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? 'password'
                        : 'text'
                    }
                    inputlabel="City"
                    name={`city_${index}`}
                    OnChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                    maxlength={30}
                    value={data?.city !== undefined ? data?.city : ''}
                    disabled={
                      (data?.doNotVisit !== undefined &&
                        data?.doNotVisit == 'Y') ||
                      isActive === false
                        ? true
                        : false
                    }
                    errorMessage={
                      (data?.city == undefined ||
                        data?.city == null ||
                        data?.city == '') &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                  />
                  {/* <label htmlFor="a11y_city" className="form-label">
                  City
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <input
                  type={
                    data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                      ? 'password'
                      : 'text'
                  }
                  className="form-control"
                  id={`cityDiv_${index}`}
                  maxLength={30}
                  name={`city_${index}`}
                  value={data?.city !== undefined ? data?.city : ''}
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  data-testid={`cityTextBox_${index}`}
                  disabled={
                    (data?.doNotVisit !== undefined &&
                      data?.doNotVisit == 'Y') ||
                    isActive === false
                      ? true
                      : false
                  }
                />
                {(data?.city == undefined ||
                  data?.city == null ||
                  data?.city == '') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={4} lg={1} className="pt-0">
                  <RACSelect
                    inputLabel="State"
                    options={state}
                    defaultValue={
                      data?.state !== undefined &&
                      data?.doNotVisit !== undefined &&
                      data?.doNotVisit == 'Y'
                        ? '**'
                        : data?.state !== undefined &&
                          data?.state !== null &&
                          data?.state !== ''
                        ? data?.state
                        : '0'
                    }
                    required={data?.active == 'Y' ? true : false}
                    loading={
                      state.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `state_${index}`;
                      handleChangeForAddEmployeInfo(e);
                    }}
                    errorMessage={
                      (data?.state == undefined ||
                        data?.state == null ||
                        data?.state == '' ||
                        data?.state == '0') &&
                      isrequired === true &&
                      data?.active == 'Y'
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(state.length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="select-state" className="form-label">
                  State
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <select
                  className="form-select"
                  id={`stateDiv_${index}`}
                  name={`state_${index}`}
                  data-testid={`StateSelect_${index}`}
                  value={
                    data?.state !== undefined &&
                    data?.doNotVisit !== undefined &&
                    data?.doNotVisit == 'Y'
                      ? '*'
                      : data?.state
                  }
                  disabled={
                    (data?.doNotVisit !== undefined &&
                      data?.doNotVisit == 'Y') ||
                    isActive === false
                      ? true
                      : false
                  }
                  onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                >
                  <option selected value="0">
                    Select
                  </option>
                  {data?.state !== undefined &&
                  data?.doNotVisit !== undefined &&
                  data?.doNotVisit == 'Y' ? (
                    <option value="*">**</option>
                  ) : null}
                  {dynamicDropdown(state)}
                </select> */}
                  {/* {(data?.state == undefined ||
                  data?.state == null ||
                  data?.state == '' ||
                  data?.state == '0') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item md={3} lg={2} className="pt-0 mt-0">
                  <Grid item className="form-check mt-4 floatLeft ps-0">
                    <RACCheckBox
                      label="Do Not Visit"
                      size="small"
                      className="cust-checkbox"
                      name={`doNotVisit_${index}`}
                      onChange={(e: any) => {
                        handleChangeForAddEmployeInfo(e);
                      }}
                      checked={
                        data?.doNotVisit !== undefined &&
                        data?.doNotVisit == 'Y'
                          ? true
                          : false
                      }
                    />
                    {/* <input
                    className="form-check-input"
                    type="checkbox"
                    id={`doNotVisitDiv_${index}`}
                    name={`doNotVisit_${index}`}
                    data-testid={`DonotVisitCheck_${index}`}
                    checked={
                      data?.doNotVisit !== undefined && data?.doNotVisit == 'Y'
                        ? true
                        : false
                    }
                    disabled={!isActive}
                    onChange={(e: any) => handleChangeForAddEmployeInfo(e)}
                  />
                  <label
                    className="form-check-label racpad-margin"
                    htmlFor="do-not-visit"
                  >
                    Do Not Visit
                  </label> */}
                  </Grid>
                </Grid>
                {index != 0 &&
                (data?.employerReferenceId == undefined ||
                  data?.employerReferenceId == null ||
                  data?.employerReferenceId == '') ? (
                  <Grid item md={2} lg={1} className="pt-0 mt-3">
                    {/* <button
                      type="button"
                      className="btn btn-secondary"
                      data-testid="cancelBtnTest"
                      onClick={() => removeEmployementDiv(index)}
                    >
                      Cancel
                    </button> */}
                    <RACButton
                      className="btn btn-secondary"
                      onClick={() => removeEmployementDiv(index)}
                    >
                      Cancel
                    </RACButton>
                    {/* <a
                    className="racpad-link cursor"
                    onClick={() => removeEmployementDiv(index)}
                  >
                    X Cancel
                  </a> */}
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };
  //Employer information ends here
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildVechicleBlock = () => {
    return Vehicle.map((data: any, index: any) => {
      return (
        <Grid container key={index}>
          <Card className="card w-100 mb-2">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    inputlabel="Make"
                    name={`make_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={30}
                    value={data?.vehicleMake}
                  />
                  {/* <label htmlFor="a11y_model" className="form-label">
                  Make
                </label>
                <input
                  type="text"
                  className="form-control"
                  data-testid="makeTxtBox"
                  id={`makeDiv_${index}`}
                  name={`make_${index}`}
                  value={data?.vehicleMake}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  maxLength={30}
                /> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    inputlabel="Model"
                    name={`model_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={30}
                    value={data?.vehicleModel}
                  />
                  {/* <label htmlFor="a11y_model" className="form-label">
                  Model
                </label>
                <input
                  type="text"
                  className="form-control"
                  data-testid="modelTxtBox"
                  id={`modelDiv_${index}`}
                  name={`model_${index}`}
                  value={data?.vehicleModel}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  maxLength={30}
                /> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACSelect
                    inputLabel="Year"
                    options={buildVechicleYear()}
                    defaultValue={
                      data?.vehicleYear !== undefined &&
                      data?.vehicleYear !== null &&
                      data?.vehicleYear !== ''
                        ? data?.vehicleYear
                        : '0'
                    }
                    loading={
                      buildVechicleYear().length == 0 && !dropDownLoaded
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `year_${index}`;
                      handleChangeForAddVehicleInfo(e);
                    }}
                    {...(buildVechicleYear().length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="a11y_year" className="form-label">
                  Year
                </label>
                <select
                  className="form-select"
                  data-testid="drop1"
                  id={`yearDiv_${index}`}
                  name={`year_${index}`}
                  value={data?.vehicleYear}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                >
                  <option value="0" selected>
                    Select
                  </option>
                  {buildVechicleYear()}
                </select> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    inputlabel="Color"
                    name={`color_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={10}
                    value={data?.vehicleColor}
                  />
                  {/* <label htmlFor="a11y_color" className="form-label">
                  Color
                </label>
                <input
                  type="text"
                  className="form-control"
                  data-testid="colorTxtBox"
                  value={data?.vehicleColor}
                  id={`colorDiv_${index}`}
                  name={`color_${index}`}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  maxLength={10}
                /> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    inputlabel="VIN#"
                    name={`vin_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={10}
                    value={data?.vehicleVin}
                  />
                  {/* <label htmlFor="a11y_vin" className="form-label">
                  VIN#
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`vinDiv_${index}`}
                  name={`vin_${index}`}
                  data-testid="vinTxtBox"
                  value={data?.vehicleVin}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  maxLength={10}
                /> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    inputlabel="Tag#"
                    name={`tag_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={10}
                    value={data?.vehicleLicensePlate}
                  />
                  {/* <label htmlFor="a11y_tag" className="form-label">
                  Tag#
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`tagDiv_${index}`}
                  name={`tag_${index}`}
                  data-testid="tagTxtBox"
                  value={data?.vehicleLicensePlate}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  maxLength={10}
                /> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACSelect
                    inputLabel="Tag State"
                    options={state}
                    defaultValue={
                      data?.vehRegState !== undefined ? data?.vehRegState : '0'
                    }
                    loading={
                      state.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `tagState_${index}`;
                      handleChangeForAddVehicleInfo(e);
                    }}
                    {...(state.length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="a11y_tagstate" className="form-label">
                  Tag State
                </label>
                <select
                  className="form-select"
                  data-testid="drop2"
                  id={`tagStateDiv_${index}`}
                  name={`tagState_${index}`}
                  value={
                    data?.vehRegState !== undefined ? data?.vehRegState : '0'
                  }
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                >
                  <option value="0"> Select</option>
                  {dynamicDropdown(state)}
                </select> */}
                </Grid>
                <Grid item md={3} lg={2}>
                  {/* <label htmlFor="a11y_tagexpdate" className="form-label">
                    Tag Expiration Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    data-testid="expirydateTxtBox"
                    id={`tagExpirationDateDiv_${index}`}
                    name={`tagExpirationDate_${index}`}
                    value={data?.vehPlanExpirationDate}
                    onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  /> */}
                  <RACDatePicker
                    value={data?.vehPlanExpirationDate}
                    label="Tag Expiration Date"
                    id={`tagExpirationDateDiv_${index}`}
                    name={`tagExpirationDate_${index}`}
                    onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <RACTextbox
                    isCurrency={false}
                    type="text"
                    inputlabel="Where is Auto Financed?"
                    name={`whereIsAutoFinance_${index}`}
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={15}
                    value={data?.vehFinancedFrom}
                  />
                  {/* <label htmlFor="a11y_autofinance" className="form-label">
                  Where is Auto Financed?
                </label>
                <input
                  type="text"
                  className="form-control"
                  data-testid="financeTxtBox"
                  id={`whereIsAutoFinanceDiv_${index}`}
                  name={`whereIsAutoFinance_${index}`}
                  value={data?.vehFinancedFrom}
                  onChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                  maxLength={15}
                /> */}
                </Grid>
                <Grid item md={2} lg={2}>
                  <RACTextbox
                    isCurrency={true}
                    type={'number'}
                    digitFormat={'currency'}
                    inputlabel="Monthly Auto Payment"
                    id={`monthlyAutoPaymentDiv_${index}`}
                    name={`monthlyAutoPayment_${index}`}
                    value={
                      data?.vehMonthlyPayment !== undefined &&
                      data?.vehMonthlyPayment !== null &&
                      data?.vehMonthlyPayment !== ''
                        ? data?.vehMonthlyPayment
                        : ''
                    }
                    OnChange={(e: any) => handleChangeForAddVehicleInfo(e)}
                    maxlength={10}
                    className="cust-digit"
                    dollarTextClassName="cust-digit-dollar1"
                  />
                </Grid>
                {index != 0 &&
                (data?.customerVehicleId == undefined ||
                  data?.customerVehicleId == null ||
                  data?.customerVehicleId == '') ? (
                  <Grid item md={1} lg={4} className="mt-4 text-center">
                    {/* <button
                      type="button"
                      className="btn btn-secondary"
                      data-testid="cancelBtnTest"
                      onClick={() => removeDivVehicle(index)}
                    >
                      Cancel
                    </button> */}
                    <RACButton
                      className="btn btn-secondary"
                      onClick={() => removeDivVehicle(index)}
                    >
                      Cancel
                    </RACButton>
                    {/* <a
                      className="racpad-link cursor"
                      onClick={() => removeDivVehicle(index)}
                    >
                      X Cancel
                    </a> */}
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const buildPhone = () => {
    return phoneNumberProps.map((data: any, index: any) => {
      return (
        <Grid container key={index} className="mb-0">
          <Card
            className={`card w-100 mt-${
              index !== 0 ? (phoneNumberProps.length > 1 ? 3 : 0) : null
            }`}
            key={index}
          >
            <CardContent id="contact_info_div">
              <Grid
                container
                spacing={2}
                id={`anotherContact_${index}`}
                className="mb-0"
              >
                <Grid item lg={4} xl={2}>
                  <RACTextbox
                    isCurrency={false}
                    required={true}
                    type={'text'}
                    inputlabel="Phone Number"
                    name={`phonenumber_${index}`}
                    OnChange={(e: any) => handleChangeForAddToContact(e)}
                    maxlength={13}
                    value={
                      data?.phoneNumber !== undefined
                        ? formatPhone(data?.phoneNumber)
                        : ''
                    }
                    errorMessage={
                      data?.phoneNumber !== undefined &&
                      data?.phoneNumber !== null &&
                      data?.phoneNumber == '' &&
                      isrequired === true
                        ? FIELD_ERROR
                        : isrequired === true &&
                          data?.phoneNumber !== undefined &&
                          data?.phoneNumber !== null &&
                          data?.phoneNumber.length < 10
                        ? PHONE_ERROR_MESSAGE
                        : ''
                    }
                  />
                  {/* <label htmlFor="PhoneNumber" className="form-label">
                  Phone Number
                  <span className="mandatoryfield">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`phoneNumberDiv_${index}`}
                  name={`phonenumber_${index}`}
                  value={
                    data?.phoneNumber !== undefined
                      ? formatPhone(data?.phoneNumber)
                      : ''
                  }
                  maxLength={13}
                  onChange={(e: any) => handleChangeForAddToContact(e)}
                  required
                />

                {data?.phoneNumber !== undefined &&
                data?.phoneNumber !== null &&
                data?.phoneNumber == '' &&
                isrequired === true ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : isrequired === true &&
                  data?.phoneNumber !== undefined &&
                  data?.phoneNumber !== null &&
                  data?.phoneNumber.length < 10 ? (
                  <label className="mandatoryfield validation">
                    Please enter valid phone number
                  </label>
                ) : null} */}
                </Grid>
                <Grid item lg={4} xl={2}>
                  <RACTextbox
                    isCurrency={false}
                    type={'text'}
                    inputlabel="Ext"
                    name={`ExtforContact_${index}`}
                    OnChange={(e: any) => handleChangeForAddToContact(e)}
                    maxlength={5}
                    value={data?.extension}
                  />
                  {/* <label htmlFor="Ext" className="form-label">
                  Ext
                </label>
                <input
                  type="text"
                  className="form-control"
                  maxLength={10}
                  value={data?.extension}
                  name={`ExtforContact_${index}`}
                  id={`ExtforContactDiv_${index}`}
                  onChange={(e: any) => handleChangeForAddToContact(e)}
                /> */}
                </Grid>
                <Grid item lg={4} xl={2}>
                  <RACSelect
                    inputLabel="Best Time to Call"
                    options={bestTimeToCall}
                    defaultValue={
                      data?.callTimeType !== undefined
                        ? data?.callTimeType
                        : '0'
                    }
                    required={true}
                    loading={
                      bestTimeToCall.length == 0 && !dropDownLoaded
                        ? true
                        : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `BestTimeCall_${index}`;
                      handleChangeForAddToContact(e);
                    }}
                    errorMessage={
                      data?.callTimeType !== undefined &&
                      (data?.callTimeType == '' || data?.callTimeType == '0') &&
                      isrequired === true
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(bestTimeToCall.length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="BestTimeCall" className="form-label">
                  Best Time to Call
                  <span className="mandatoryfield">*</span>
                </label>
                <select
                  className="form-select"
                  value={
                    data?.callTimeType !== undefined ? data?.callTimeType : '0'
                  }
                  aria-label="Default select example"
                  name={`BestTimeCall_${index}`}
                  id={`BestTimeCall_${index}`}
                  onChange={(e: any) => handleChangeForAddToContact(e)}
                >
                  <option value="0">Select</option>
                  {dynamicDropdown(bestTimeToCall)}
                </select>
                {data?.callTimeType !== undefined &&
                (data?.callTimeType == '' || data?.callTimeType == '0') &&
                isrequired === true ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item lg={4} xl={2}>
                  <RACSelect
                    inputLabel="Phone Type"
                    options={phoneType}
                    defaultValue={
                      data?.phoneType !== undefined ? data?.phoneType : '0'
                    }
                    required={true}
                    loading={
                      phoneType.length == 0 && !dropDownLoaded ? true : false
                    }
                    onChange={(e: any) => {
                      e.target.name = `PhoneType_${index}`;
                      handleChangeForAddToContact(e);
                    }}
                    errorMessage={
                      data?.phoneType !== undefined &&
                      (data?.phoneType == '' || data?.phoneType == '0') &&
                      isrequired === true
                        ? FIELD_ERROR
                        : ''
                    }
                    {...(phoneType.length == 0 &&
                      dropDownLoaded && {
                        errorMessage: API_ERROR_MESSAGE,
                      })}
                  />
                  {/* <label htmlFor="PhoneType" className="form-label">
                  Phone Type
                </label>
                <select
                  className="form-select"
                  value={data?.phoneType !== undefined ? data?.phoneType : '0'}
                  aria-label="Default select example"
                  name={`PhoneType_${index}`}
                  id={`PhoneType_${index}`}
                  onChange={(e: any) => handleChangeForAddToContact(e)}
                >
                  <option value="0">Select</option>
                  {dynamicDropdown(phoneType)}
                </select>
                {data?.phoneType !== undefined &&
                (data?.phoneType == '' || data?.phoneType == '0') &&
                isrequired === true ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                </Grid>
                <Grid item lg={4} xl={2}>
                  <RACTextbox
                    isCurrency={false}
                    type={'text'}
                    inputlabel="Phone Instructions"
                    name={`PhoneInstructions_${index}`}
                    OnChange={(e: any) => handleChangeForAddToContact(e)}
                    maxlength={30}
                    value={data?.note}
                  />
                  {/* <label htmlFor="PhoneInstructions" className="form-label">
                  Phone Instructions
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={data?.note}
                  id={`PhoneInstructions_${index}`}
                  name={`PhoneInstructions_${index}`}
                  onChange={(e: any) => handleChangeForAddToContact(e)}
                /> */}
                </Grid>
                <Grid item lg={4} xl={2} className="mt-4">
                  <Typography
                    component="label"
                    className="form-check-label floatLeft mt-2"
                    htmlFor="IsPrimary"
                  >
                    Is Primary?
                  </Typography>
                  <Grid
                    item
                    className="form-check form-switch switch-blue floatLeft RAC-switch ps-2"
                  >
                    <RACToggle
                      style={{ marginLeft: 0 }}
                      id={`Is_Primary_${index}`}
                      checked={
                        phoneNumberProps.length == 1
                          ? true
                          : phoneNumberProps.length > 0 && data?.primary == 'N'
                          ? false
                          : true
                      }
                      toggleColor="#0d6efd"
                      name={`IsPrimary_${index}`}
                      OnChange={(e: any) => {
                        handleChangeForAddToContact(e);
                      }}
                    />
                    {/* <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={true}
                    checked={
                      phoneNumberProps.length == 1
                        ? true
                        : phoneNumberProps.length > 0 && data?.primary == 'N'
                        ? false
                        : true
                    }
                    name={`IsPrimary_${index}`}
                    id={`Is_Primary_${index}`}
                    onChange={(e: any) => handleChangeForAddToContact(e)}
                  /> */}
                  </Grid>
                  {data?.primary == 'N' &&
                  (data?.phoneId == undefined ||
                    data?.phoneId == null ||
                    data?.phoneId == '') ? (
                    <Grid
                      item
                      className="rac-col-md-2 rac-col-lg-1 cancel-btn floatRight"
                    >
                      {/* <button
                        type="button"
                        className="btn btn-secondary"
                        data-testid="cancelBtnTest"
                        onClick={() => removeContactInfoDiv(index)}
                      >
                        Cancel
                      </button> */}
                      <RACButton
                        className="btn btn-secondary"
                        onClick={() => removeContactInfoDiv(index)}
                      >
                        Cancel
                      </RACButton>
                      {/* <a
                    className="racpad-link cursor"
                    onClick={() => removeContactInfoDiv(index)}
                  >
                    X Cancel
                  </a> */}
                    </Grid>
                  ) : null}
                  {/* {index == 0 ? null : (
                <div className="col-md-4 col-lg-1 mb-4 mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    name={`removeDiv_${index}`}
                    onClick={() => removeContactInfoDiv(index)}
                  >
                    Remove
                  </button>
                </div>
              )} */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const getAddress = async (type: 'P' | 'M') => {
    const addressPayload = {
      MessageID: 'CMS006-93589345',
      MessageType: 'CMS006',
      MessageDTS: '2014-05-23-14:30:15:254633',
      MessageDTSTZOffset: '-06:00',
      RegionID: '1',
      ClientID: '8',
      ClientLocationNumber: '00006',
      ClientSource: '1',
      ClientOriginator: '1',
      LocationNumber: loginStore,
      EncryptionMethod: '0',
      Addresses: [
        {
          AddressID: '11114',
          StreetAddress: '',
          City: '',
          StateProvince: '',
          PostalCode: '',
          Country: '',
        },
      ],
      StoreConfig: {
        storeNumbers: [loginStore],
        paramKeyNames: ['AddressDoctorEnabled'],
      },
    };
    addressPayload.Addresses[0].City =
      type === 'P' ? updatePrimaryAddress?.city : updateMailingAddress?.city;
    addressPayload.Addresses[0].StreetAddress =
      type === 'P'
        ? updatePrimaryAddress?.address
        : updateMailingAddress?.address;
    addressPayload.Addresses[0].StateProvince =
      type === 'P' ? updatePrimaryAddress?.state : updateMailingAddress?.state;
    addressPayload.Addresses[0].PostalCode =
      type === 'P' ? updatePrimaryAddress?.zip : updateMailingAddress?.zip;
    addressPayload.Addresses[0].Country = 'USA';
    // eslint-disable-next-line no-console
    console.log('AddressDoctor', addressPayload);
    try {
      //setIsLoading(true);
      setValidateIsOpen(true);

      setAddressDoc(true);
      const response: any = await GetSuggestedAddress(addressPayload);
      //setIsLoading(false);
      // eslint-disable-next-line no-console
      console.log('AddressDoctor', response);
      setAddressDoc(false);
      if (
        response !== undefined &&
        response !== null &&
        response.storeConfig !== undefined &&
        response.storeConfig.storeProfileResponse.configDetails[0]
          .configDetails[0].paramValue == 'Y'
      ) {
        // eslint-disable-next-line no-console
        console.log('IsaddressValid');
        // addressDocCancel();
        // if (
        //   response.validateAddress !== undefined &&
        //   response.validateAddress.Addresses !== undefined &&
        //   response.validateAddress.Addresses !== null
        // ) {
        //   if (response.validateAddress.Addresses.MatchCode == '1') {
        //   }
        // }
        if (
          response.validateAddress !== undefined &&
          response.validateAddress.Addresses !== undefined &&
          (response.validateAddress.Addresses.MatchCode == '1' ||
            response.validateAddress.Addresses.MatchCode == '2')
        ) {
          const errorMessage: string =
            response.validateAddress.Addresses.MatchCode == '1' ||
            response.validateAddress.Addresses.MatchCode == 1
              ? `Invalid "zip Code ${addressPayload.Addresses[0].PostalCode}" doesnot exist in the state ${addressPayload.Addresses[0].StateProvince}.`
              : response.validateAddress.Addresses.MatchCode == '2' ||
                response.validateAddress.Addresses.MatchCode == 2
              ? `Zip code found: ${addressPayload.Addresses[0].PostalCode}. Do you Want to overrride?`
              : 'Automatic Address validation is Unavailable for the store # Please validate the address Manually.';
          setAddressErrorMsg(errorMessage);
          setSuggestedAddress(undefined);
        } else {
          setSuggestedAddress(response.validateAddress);
        }
        //setValidateIsOpen(true);
        // eslint-disable-next-line no-console
        console.log('IsaddressValid1');
      } else {
        //setIsLoading(false);
        setSuggestedAddress(undefined);
        setAddressErrorMsg(
          'Automatic Address validation is Unavailable for the store # Please validate the address Manually.'
        );
        // eslint-disable-next-line no-console
        console.log('ENT', runAddressDoc);
        buildENTAddres('ENT', runAddressDoc);
        addressDocCancel();
        setrunAddressDoc('');
      }
    } catch (e: any) {
      //setIsLoading(false);
      // eslint-disable-next-line no-console
      setAddressDoc(false);
      setSuggestedAddress(undefined);
      console.log('ENT', runAddressDoc);
      buildENTAddres('ENT', runAddressDoc);
      addressDocCancel();
      setrunAddressDoc('');
      type == 'P' ? seteditInputBox(true) : seteditInputBoxM(true);
    }
  };

  // const addressDoctorErrorPopup = () => {
  //   return (
  //     <Grid
  //       item
  //       md={12}
  //       px={2}
  //       id="confirmduedate"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       <Grid item md={12} className="text-center">
  //         <Typography className="RAC-popup-msg">{addressErrorMsg}</Typography>
  //       </Grid>
  //       <Grid container justifyContent={'center'} mt={4}>
  //         {/* <button
  //           type="button"
  //           className="btn btn-secondary mx-1"
  //           data-bs-dismiss="modal"
  //           data-testid="creaNewCustNo"
  //           onClick={() => NOHandleChange()}
  //         >
  //           No
  //         </button>
  //         <button
  //           type="button"
  //           className="btn btn-primary mx-1"
  //           data-bs-dismiss="modal"
  //           data-testid="creaNewCustYes"
  //           data-bs-toggle="modal"
  //           onClick={() => YesHandleChange()}
  //         >
  //           Yes
  //         </button> */}
  //         <RACButton
  //           className="btn-primary"
  //           size="small"
  //           variant="outlined"
  //           isRounded={false}
  //           style={{
  //             textTransform: 'none',
  //             // eslint-disable-next-line sonarjs/no-duplicate-string
  //             width: '100px !important',
  //             height: '35px',
  //             marginTop: '29px',
  //           }}
  //           onClick={() => {
  //             setSuggestionAddresPopup(false);
  //             continueClick('ENT');
  //           }}
  //         >
  //           Ok
  //         </RACButton>
  //       </Grid>
  //     </Grid>
  //   );
  // };
  // function AddressDoctorErrorMsg() {
  //   return (
  //     <RACModalCard
  //       isOpen={suggestionAddresPopup}
  //       maxWidth="xs"
  //       closeIcon={true}
  //       onClose={() => setSuggestionAddresPopup(false)}
  //       // eslint-disable-next-line react/no-children-prop
  //       children={addressDoctorErrorPopup()}
  //       title="Validate Address"
  //     />
  //   );
  // }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const addressDoctorPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        {AddressDoc === true ? (
          <div className="Norecords">
            <CircularProgress />
          </div>
        ) : SuggestedAddress == undefined ? (
          <React.Fragment>
            <Typography className="RAC-popup-msg">{addressErrorMsg}</Typography>
            <RACButton
              className="btn-primary"
              size="small"
              variant="outlined"
              isRounded={false}
              style={{
                textTransform: 'none',
                // eslint-disable-next-line sonarjs/no-duplicate-string
                width: '100px !important',
                height: '35px',
                marginTop: '29px',
              }}
              onClick={() => {
                continueclickAddressDoc('ENT', runAddressDoc);
              }}
            >
              Ok
            </RACButton>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography className="RAC-popup-msg">
              Entered address is not located
            </Typography>
            <Grid item md={12} className="mt-3 mb-2">
              <Typography className="RAC-popup-msg">
                Suggested Addresses
              </Typography>
              <div className="form-check form-check-inline">
                {/* <input
              className="form-check-input"
              type="radio"
              data-testid="ValidateInputField"
              name="inlineRadioOptions"
              id="inlineRadio1"
              defaultValue="option1"
              checked={defaultSuggestedAddress == 'SUG' ? true : false}
              onClick={() => RadioBtnclick('SUG')}
            /> */}
                <RACRadio
                  id="inlineRadio1"
                  name="inlineRadioOptions"
                  checked={defaultSuggestedAddress == 'SUG' ? true : false}
                  onClick={() => RadioBtnclick('SUG')}
                  value={'SUG'}
                />
                <Typography variant="caption" className="RAC-popup-msg">
                  {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.StreetAddress !== undefined
                    ? `${SuggestedAddress.Addresses.StreetAddress},`
                    : ''}

                  {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.City !== undefined
                    ? `${SuggestedAddress.Addresses.City},`
                    : ''}
                  {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.StateProvince !== undefined
                    ? `${SuggestedAddress.Addresses.StateProvince},`
                    : ''}
                  {SuggestedAddress !== undefined &&
                  SuggestedAddress !== null &&
                  SuggestedAddress.Addresses !== null &&
                  SuggestedAddress.Addresses.PostalCode !== undefined
                    ? `${SuggestedAddress.Addresses.PostalCode}`
                    : ''}
                </Typography>
              </div>
            </Grid>
            <Grid item md={12} className="mb-2">
              <Typography className="RAC-popup-msg">Entered Address</Typography>
              <div className="form-check form-check-inline">
                <RACRadio
                  id="inlineRadio2"
                  name="inlineRadioOptions"
                  checked={defaultSuggestedAddress == 'ENT' ? true : false}
                  onClick={() => RadioBtnclick('ENT')}
                  value={'ENT'}
                />
                <Typography variant="caption" className="RAC-popup-msg">
                  {runAddressDoc === 'P'
                    ? updatePrimaryAddress.address +
                      ',' +
                      updatePrimaryAddress.address1 +
                      ',' +
                      updatePrimaryAddress.city +
                      ',' +
                      updatePrimaryAddress.state +
                      '-' +
                      updatePrimaryAddress.zip
                    : updateMailingAddress.address +
                      ',' +
                      updateMailingAddress.address1 +
                      ',' +
                      updateMailingAddress.city +
                      ',' +
                      updateMailingAddress?.state +
                      '-' +
                      updateMailingAddress.zip}
                </Typography>
              </div>
            </Grid>
            <Grid container className="mt-4">
              <Grid item md={6}>
                <RACButton
                  className="btn btn-secondary"
                  onClick={() => continueclickAddressDoc('ENT', runAddressDoc)}
                >
                  Cancel
                </RACButton>
              </Grid>
              <Grid item md={6} className="text-center">
                <RACButton
                  className="btn btn-primary me-2"
                  onClick={() => continueclickAddressDoc('ENT', runAddressDoc)}
                  disabled={BtnIsdisabled1}
                >
                  Override
                </RACButton>
                <RACButton
                  className="btn btn-primary"
                  disabled={BtnIsdisabled2}
                  onClick={() => continueclickAddressDoc('SUG', runAddressDoc)}
                >
                  continue
                </RACButton>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  };
  function ValidationMessageMsg() {
    return (
      <RACModalCard
        isOpen={ValidateIsOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setValidateIsOpen(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addressDoctorPopup()}
        title="Validation Message"
      />
    );
  }

  const continueclickAddressDoc = (type: any, addressType: 'P' | 'M' | '') => {
    setValidateIsOpen(false);
    // eslint-disable-next-line no-console
    console.log('addressType', addressType);
    // eslint-disable-next-line no-console
    console.log('type', type);
    if (type == 'SUG' && addressType == 'P') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1 =
        SuggestedAddress.Addresses.StreetAddress;
      customerInformationObj.addressLine2 = '';
      customerInformationObj.city = SuggestedAddress.Addresses.City;
      customerInformationObj.state = SuggestedAddress.Addresses.StateProvince;
      customerInformationObj.zip = SuggestedAddress.Addresses.PostalCode;
      const updatePrimaryAddressObj = updatePrimaryAddress;
      updatePrimaryAddressObj.address =
        SuggestedAddress.Addresses.StreetAddress;
      updatePrimaryAddressObj.address1 = '';
      updatePrimaryAddressObj.city = SuggestedAddress.Addresses.City;
      updatePrimaryAddressObj.state = SuggestedAddress.Addresses.StateProvince;
      updatePrimaryAddressObj.zip = SuggestedAddress.Addresses.PostalCode;
      setcustomerInformation(customerInformationObj);
      setUpdatePrimaryAddress(updatePrimaryAddressObj);
    } else if (type == 'SUG' && addressType == 'M') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1M =
        SuggestedAddress.Addresses.StreetAddress;
      customerInformationObj.addressLine2M = '';
      customerInformationObj.cityM = SuggestedAddress.Addresses.City;
      customerInformationObj.stateM = SuggestedAddress.Addresses.StateProvince;
      customerInformationObj.zipM = SuggestedAddress.Addresses.PostalCode;
      const updateMailingAddressObj = updateMailingAddress;
      updateMailingAddressObj.address =
        SuggestedAddress.Addresses.StreetAddress;
      updateMailingAddressObj.address1 = '';
      updateMailingAddressObj.city = SuggestedAddress.Addresses.City;
      updateMailingAddressObj.state = SuggestedAddress.Addresses.StateProvince;
      updateMailingAddressObj.zip = SuggestedAddress.Addresses.PostalCode;
      setcustomerInformation(customerInformationObj);
      setupdateMailingAddress(updateMailingAddressObj);
    } else {
      buildENTAddres(type, addressType);
    }
    addressType == 'P' ? seteditInputBox(true) : seteditInputBoxM(true);
    setrunAddressDoc('');
  };
  const buildENTAddres = (type: any, addressType: 'P' | 'M' | '') => {
    if (type == 'ENT' && addressType == 'P') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1 = updatePrimaryAddress.address;
      customerInformationObj.addressLine2 = updatePrimaryAddress.address1;
      customerInformationObj.city = updatePrimaryAddress.city;
      customerInformationObj.state = updatePrimaryAddress.state;
      customerInformationObj.zip = updatePrimaryAddress.zip;

      setcustomerInformation(customerInformationObj);
    } else if (type == 'ENT' && runAddressDoc == 'M') {
      const customerInformationObj = customerInformation;
      customerInformationObj.addressLine1M = updateMailingAddress.address;
      customerInformationObj.addressLine2M = updateMailingAddress.address1;
      customerInformationObj.cityM = updateMailingAddress.city;
      customerInformationObj.stateM = updateMailingAddress.state;
      customerInformationObj.zipM = updateMailingAddress.zip;

      setcustomerInformation(customerInformationObj);
    }
    addressType == 'P' ? seteditInputBox(true) : seteditInputBoxM(true);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const agreementRedirect = async () => {
    setCreateAgreementClick(true);
    //if (pageType !== 'customer') {
    const validateResultObj = [];
    let isValid = true;
    let firstTabValid =
      validateTabOne() && validateCustomerTab() ? true : false;
    const SSN: any =
      customerInformation.ssn1 +
        customerInformation.ssn2 +
        customerInformation.ssn3 ==
      ''
        ? null
        : customerInformation.ssn1 +
          customerInformation.ssn2 +
          customerInformation.ssn3;
    firstTabValid = SSN != null ? (SSN.length < 9 ? false : true) : true;
    if (firstTabValid === false) {
      validateResultObj.push('Customer Information');
      isValid = false;
    }
    const ObjTabTwo = validateTabTwo(true);
    if (!ObjTabTwo.isValidate) {
      validateResultObj.push("Employer's Information");
      isValid = false;
    }
    const ObjTabThree = validateTabThree(true);
    if (!ObjTabThree.isValidate) {
      validateResultObj.push('Residence');
      isValid = false;
    }
    const ObjTabFour = validateTabFour(true);
    if (!ObjTabFour.isValidate) {
      validateResultObj.push('Reference');
      isValid = false;
    }
    setValidateResult(validateResultObj);

    if (isValid) {
      setisrequired(false);
      const UpdateCustomerRequest: any = {
        customerId: customerId,
        globalCustomerId: customerInformation.globalCustomerId,
        firstName: customerInformation.firstName,
        lastName: customerInformation.lastName,
        middleInitial: customerInformation.middleInitial,
        coworker: customerInformation.racCoworker,
        suffix: customerInformation.suffix,
        salutation: customerInformation.salutation,
        taxId: SSN,
        taxIdType: 'SSN',
        governmentId: customerInformation.governmentId,
        governmentIdType: customerInformation.governmentIdType,
        dateOfBirth: buildDate(customerInformation.dateOfBirth),
        governmentIdIssuedState: customerInformation.governmentIdIssuedState,
        emailAddress: customerInformation.emailAddress,
        preferredContactMethod: customerInformation.preferredMethod,
        preferredLanguage: customerInformation.preferredLanguage,
        printReceiptMethod: customerInformation.preferredPaymentReceipt,
        phoneSolicitationAllowed: customerInformation.phoneSolicitationAllowed,
        emailSolicitationAllowed: customerInformation.emailSolicitationAllowed,
        mailSolicitationAllowed: customerInformation.mailSolicitationAllowed,
        textSolicitationAllowed: customerInformation.textMessageRemainder,
        paymentReminderCallsAllowed: customerInformation.paymentRemainderCall,
        verified: customerInformation.verified,
        verifiedDate:
          customerInformation.verifiedDate !== undefined &&
          customerInformation.verifiedDate !== null &&
          customerInformation.verifiedDate !== ''
            ? buildDate(customerInformation.verifiedDate)
            : null,
        skip: customerInformation.skip,
        stolen: customerInformation.stolen,
        hard: customerInformation.hard,
        skipDate: buildDate(''),
      };
      UpdateCustomerRequest.addresses = buildAddress();
      const phonePropsArray: any = buildPhones();

      if (
        phoneNumberProps !== undefined &&
        phoneNumberProps !== null &&
        phoneNumberProps.length > 0
      ) {
        UpdateCustomerRequest.phones = phonePropsArray;
      }
      if (ObjTabTwo.UpdatedInfo === true) {
        const EmpPropsArray: any = buildEmpObj();

        UpdateCustomerRequest.employerReferences = EmpPropsArray;
      }
      if (ObjTabThree.UpdatedInfo === true) {
        if (Residences.rented === true) {
          UpdateCustomerRequest.landlordReferences = landlordReferences();
        } else {
          UpdateCustomerRequest.mortgageCompanyName =
            Residences.rented === false
              ? Residences.mortgage === true
                ? Residences.mortgageCompanyName
                : null
              : null;
          UpdateCustomerRequest.residenceSince =
            Residences.rented === false ? Residences.residenceSince : null;
          UpdateCustomerRequest.monthlyMortgagePayment =
            Residences.rented === false
              ? Residences.mortgage === true
                ? Residences.monthlyMortgagePayment == 'NaN'
                  ? ''
                  : Residences.monthlyMortgagePayment
                : ''
              : '';
        }
      }
      if (ObjTabFour.UpdatedInfo) {
        const ReferencePropsArray: any = buildReferenceObj();
        // eslint-disable-next-line no-console
        console.log('ReferenceProps', ReferencePropsArray);

        UpdateCustomerRequest.personalReferences = ReferencePropsArray;
      }
      if (pageType == 'customer') {
        const vechicleObj: any = buildVechicle();
        UpdateCustomerRequest.vehicles = vechicleObj;
      }
      UpdateCustomerRequest.assignRoute = {
        storeNumber: loginStore,
        customerId: parseInt(customerId),
        coWorkerId: localStorage.getItem('coWorkerId'),
        routeRefCode: customerInformation.route,
      };
      console.log('UpdateCustomerRequest', UpdateCustomerRequest);
      // /* eslint-enable no-console */
      setIsLoading(true);
      try {
        const result = await UpdateCustomerInfo(
          UpdateCustomerRequest,
          customerId
        );
        setIsLoading(false);
        if (
          result !== undefined &&
          result !== null &&
          result.UpdateCustomer !== undefined &&
          result.UpdateCustomer !== null &&
          result.UpdateCustomer.value !== undefined &&
          result.UpdateCustomer.value !== null &&
          result.UpdateCustomer.value.customerId !== undefined &&
          result.UpdateCustomer.value.customerId !== null &&
          result.UpdateCustomer.value.customerId == customerId
        ) {
          if (customerId !== undefined) {
            setcreateCoCustomerPopUp(true);
            //props.history.push(`/agreement/rental/itemsearch/${customerId}`);
          }
        } else {
          setisSuccess(false);
          setShowUpdatePopUp(true);
        }
      } catch (e: any) {
        setIsLoading(false);
        setErrorPopup(true);
      }
    } else {
      setErrorPopup(true);
      setisrequired(true);
    }
    setCreateAgreementClick(false);
    // } else {
    //   setCreateAgreementClick(false);
    //   if (customerId !== undefined) {
    //     props.history.push(`/agreement/rental/itemsearch/${customerId}`);
    //   }
    // }
  };

  const RadioBtnclick = (type: any) => {
    if (type === 'SUG') {
      setBtnIsdisabled1(true);
      setBtnIsdisabled2(false);
      setdefaultSuggestedAddress('SUG');
    } else if (type === 'ENT') {
      setBtnIsdisabled1(false);
      setBtnIsdisabled2(true);
      setdefaultSuggestedAddress('ENT');
    }
  };
  const formatPhone = (input: any) => {
    // debugger;
    //alert(e.target.value);
    const cleaned = ('' + input).replace(/\D/g, '');
    //This is raw data to pass on props
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    //Isvalid = temp.length == 14 ? true:false
    //alert(temp);
    return cleaned.replace(PhoneRegex, '($1) $2-$3');
  };
  // const formatCurrency = (value: any) => {
  //   const nfObject = new Intl.NumberFormat('en-US');
  //   return nfObject.format(value);
  // };
  // const HandleDecimal = (value: any) => {
  //   const formatedValue: any =
  //     value !== undefined && value !== null ? value.replace(',', '') : 0;
  //   // eslint-disable-next-line no-console
  //   console.log('formatedValue', formatedValue);
  //   const nfObject = new Intl.NumberFormat('en-US', {
  //     minimumFractionDigits: 2,
  //   });
  //   return nfObject.format(formatedValue);
  // };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const referenceInfoFunc = () => {
    return ReferenceProps.map((data: any, index: any) => {
      const activeReferencOBj: boolean =
        data?.active !== undefined &&
        data?.active !== '' &&
        data?.active !== null &&
        data?.active !== 'null' &&
        data?.active == 'Y'
          ? true
          : false;

      return (
        <>
          <Grid container>
            <Card className="card w-100 mb-3">
              <CardContent id="reference_info_div">
                <Grid container spacing={2}>
                  <Grid item md={3} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      required={data?.active == 'Y' ? true : false}
                      type={'text'}
                      inputlabel="Parent/Relative First Name"
                      name={`parent-RelativeName_${index}`}
                      OnChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      maxlength={25}
                      value={
                        data?.firstName !== undefined &&
                        data?.firstName !== null
                          ? data?.firstName
                          : ''
                      }
                      disabled={!activeReferencOBj}
                      errorMessage={
                        data?.firstName !== undefined &&
                        data?.firstName == '' &&
                        isrequired === true &&
                        data?.active == 'Y'
                          ? FIELD_ERROR
                          : ''
                      }
                    />
                    {/* <label htmlFor="a11y_parent" className="form-label">
                  Parent/Relative First Name{' '}
                  <span className="mandatoryfield">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`parent-RelativeNameDiv_${index}`}
                  name={`parent-RelativeName_${index}`}
                  value={
                    data?.firstName !== undefined && data?.firstName !== null
                      ? data?.firstName
                      : ''
                  }
                  disabled={!activeReferencOBj}
                  data-testid="parentTextBox"
                  onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                  maxLength={25}
                />
                {data?.firstName !== undefined &&
                data?.firstName == '' &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      required={data?.active == 'Y' ? true : false}
                      type={'text'}
                      inputlabel="Parent/Relative Last Name"
                      name={`parent-RelativeLastName_${index}`}
                      OnChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      maxlength={25}
                      value={
                        data?.lastName !== undefined && data?.lastName !== null
                          ? data?.lastName
                          : ''
                      }
                      disabled={!activeReferencOBj}
                      errorMessage={
                        data?.lastName !== undefined &&
                        data?.lastName == '' &&
                        isrequired === true &&
                        data?.active == 'Y'
                          ? FIELD_ERROR
                          : ''
                      }
                    />
                    {/* <label htmlFor="a11y_parent" className="form-label">
                  Parent/Relative Last Name{' '}
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`parent-RelativeNameDiv_${index}`}
                  name={`parent-RelativeLastName_${index}`}
                  value={
                    data?.lastName !== undefined && data?.lastName !== null
                      ? data?.lastName
                      : ''
                  }
                  disabled={!activeReferencOBj}
                  data-testid="parentTextBox"
                  onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                  maxLength={25}
                />
                {data?.lastName !== undefined &&
                data?.lastName == '' &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACTextbox
                      isCurrency={false}
                      required={data?.active == 'Y' ? true : false}
                      type={'text'}
                      inputlabel="Phone Number"
                      name={`phoneNumber_${index}`}
                      OnChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                      maxlength={14}
                      value={
                        data?.phoneNumber !== undefined
                          ? formatPhone(data?.phoneNumber)
                          : ''
                      }
                      disabled={!activeReferencOBj}
                      errorMessage={
                        isrequired &&
                        (data?.phoneNumber == undefined ||
                          data?.phoneNumber == null ||
                          data?.phoneNumber == '') &&
                        data?.active == 'Y'
                          ? FIELD_ERROR
                          : isrequired === true &&
                            data?.active == 'Y' &&
                            data?.phoneNumber.length < 10
                          ? 'Please enter valid phone number'
                          : ''
                      }
                    />
                    {/* <label htmlFor="a11y_phone" className="form-label">
                  Phone Number
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`phoneNumberDiv_${index}`}
                  name={`phoneNumber_${index}`}
                  value={
                    data?.phoneNumber !== undefined
                      ? formatPhone(data?.phoneNumber)
                      : ''
                  }
                  disabled={!activeReferencOBj}
                  data-testid="PhoneTextBox"
                  onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                  maxLength={14}
                />
                {isrequired &&
                (data?.phoneNumber == undefined ||
                  data?.phoneNumber == null ||
                  data?.phoneNumber == '') &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : isrequired === true &&
                  data?.active == 'Y' &&
                  data?.phoneNumber.length < 10 ? (
                  <label className="mandatoryfield validation">
                    Please enter valid phone number
                  </label>
                ) : null} */}
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACSelect
                      inputLabel="Best Time to Call"
                      options={bestTimeToCall}
                      defaultValue={
                        data?.bestTimeToCall !== undefined &&
                        data?.bestTimeToCall !== null &&
                        data?.bestTimeToCall !== ''
                          ? data?.bestTimeToCall
                          : '0'
                      }
                      isDisabled={!activeReferencOBj}
                      loading={
                        bestTimeToCall.length == 0 && !dropDownLoaded
                          ? true
                          : false
                      }
                      onChange={(e: any) => {
                        e.target.name = `bestTimeToCall_${index}`;
                        handleChangeForAddReferenceInfo(e);
                      }}
                      {...(bestTimeToCall.length == 0 &&
                        dropDownLoaded && {
                          errorMessage: API_ERROR_MESSAGE,
                        })}
                    />
                    {/* <label htmlFor="a11y_besttime" className="form-label">
                  Best Time to Call
                </label>
                <select
                  className="form-select"
                  id={`bestTimeToCall_${index}`}
                  name={`bestTimeToCall_${index}`}
                  value={
                    data?.bestTimeToCall !== undefined
                      ? data?.bestTimeToCall
                      : ''
                  }
                  disabled={!activeReferencOBj}
                  data-testid="TimeDropdown"
                  onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                >
                  <option selected value="0">
                    Select
                  </option>
                  {dynamicDropdown(bestTimeToCall)}
                </select> */}
                  </Grid>
                  <Grid item md={3} lg={2}>
                    <RACSelect
                      inputLabel="Relationship"
                      options={relationShipObj}
                      defaultValue={
                        data?.relationshipType !== undefined &&
                        data?.relationshipType !== null &&
                        data?.relationshipType !== ''
                          ? data?.relationshipType
                          : '0'
                      }
                      required={data?.active == 'Y' ? true : false}
                      loading={
                        relationShipObj.length == 0 && !dropDownLoaded
                          ? true
                          : false
                      }
                      onChange={(e: any) => {
                        e.target.name = `relationShip_${index}`;
                        handleChangeForAddReferenceInfo(e);
                      }}
                      errorMessage={
                        data?.relationshipType !== undefined &&
                        (data?.relationshipType == '' ||
                          data?.relationshipType == '0') &&
                        isrequired === true &&
                        data?.active == 'Y'
                          ? FIELD_ERROR
                          : ''
                      }
                      {...(relationShipObj.length == 0 &&
                        dropDownLoaded && {
                          errorMessage: API_ERROR_MESSAGE,
                        })}
                    />
                    {/* <label htmlFor="a11y_relationship" className="form-label">
                  Relationship
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <select
                  className="form-select"
                  id={`relationShipDiv_${index}`}
                  name={`relationShip_${index}`}
                  disabled={!activeReferencOBj}
                  value={
                    data?.relationshipType !== undefined
                      ? data?.relationshipType
                      : ''
                  }
                  data-testid="RelationDropdown"
                  onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                >
                  <option selected value="0">
                    Select
                  </option>
                  <option value="FRI">Friend</option>
                  <option value="REL">Relative</option>
                  <option value="SPO">Spouse</option>
                </select>
                {data?.relationshipType !== undefined &&
                (data?.relationshipType == '' ||
                  data?.relationshipType == '0') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                  </Grid>
                  {pageType == undefined || pageType == 'customer' ? (
                    <Grid item md={4} lg={2}>
                      <Grid item className="floatRight form-check p-0 mt-4">
                        <RACCheckBox
                          label="Verified"
                          size="small"
                          className="cust-checkbox"
                          name={`verifiedCheckBox_${index}`}
                          onChange={(e: any) => {
                            handleChangeForAddReferenceInfo(e);
                          }}
                          checked={
                            data?.verifiedDate !== undefined &&
                            data?.verifiedDate !== '' &&
                            data?.verifiedDate !== null &&
                            data?.verifiedDate !== 'null'
                              ? true
                              : false
                          }
                        />
                        {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id={`verifiedCheckBoxDiv_${index}`}
                      name={`verifiedCheckBox_${index}`}
                      disabled={!activeReferencOBj}
                      checked={
                        data?.verifiedDate !== undefined &&
                        data?.verifiedDate !== '' &&
                        data?.verifiedDate !== null &&
                        data?.verifiedDate !== 'null'
                          ? true
                          : false
                      }
                      onChange={(e: any) => {
                        handleChangeForAddReferenceInfo(e);
                      }}
                    />{' '} */}
                        {/*    referenceVerifiedCheckBox activeCheckBox_ */}
                        {/* <label
                      className="form-check-label racpad-margin"
                      htmlFor="flexCheckDefault77"
                    >
                      Verified
                    </label> */}
                      </Grid>
                      <Grid
                        item
                        className="floatRight form-check ms-2 pe-0 mt-4"
                      >
                        <RACCheckBox
                          label="Active"
                          size="small"
                          name={`activeCheckBox_${index}`}
                          className="cust-checkbox"
                          onChange={(e: any) => {
                            handleChangeForAddReferenceInfo(e);
                          }}
                          checked={
                            data?.active !== undefined &&
                            data?.active !== '' &&
                            data?.active !== null &&
                            data?.active !== 'null' &&
                            data?.active == 'Y'
                              ? true
                              : false
                          }
                          disabled={
                            data?.personalReferenceId == '' ? true : false
                          }
                        />
                        {/* <input
                      className="form-check-input"
                      type="checkbox"
                      id={`activeCheckBoxDiv_${index}`}
                      name={`activeCheckBox_${index}`}
                      checked={
                        data?.active !== undefined &&
                        data?.active !== '' &&
                        data?.active !== null &&
                        data?.active !== 'null' &&
                        data?.active == 'Y'
                          ? true
                          : false
                      }
                      onChange={(e: any) => {
                        handleChangeForAddReferenceInfo(e);
                      }}
                      disabled={data?.personalReferenceId == '' ? true : false}
                    />{' '}
                    {/*    referenceVerifiedCheckBox activeCheckBox_ */}
                        {/* <label
                      className="form-check-label racpad-margin"
                      htmlFor="flexCheckDefault77"
                    >
                      Active
                    </label> */}
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container>
                  <Grid item md={12}>
                    <Typography variant="h6" className="sub-title mt-3">
                      Address Information
                    </Typography>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={4} lg={2}>
                      {/* <label htmlFor="add-line1" className="form-label">
                        Address Line 1
                        {data?.active == 'Y' ? (
                          <span className="mandatoryfield">*</span>
                        ) : null}
                      </label>
                      <input
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        className="form-control"
                        id={`addressLine1Div_${index}`}
                        name={`addressLine1_${index}`}
                        value={
                          data?.addressLine1 !== undefined
                            ? data?.addressLine1
                            : ''
                        }
                        data-testid="Address1TextBox"
                        onChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxLength={30}
                      /> */}
                      {(data?.addressLine1 == undefined ||
                        data?.addressLine1 == null ||
                        data?.addressLine1 == '') &&
                      isrequired === true &&
                      data?.active == 'Y' ? (
                        <label className="mandatoryfield validation">
                          Required
                        </label>
                      ) : (
                        ''
                      )}
                      <RACTextbox
                        isCurrency={false}
                        required={true}
                        type={'text'}
                        inputlabel="Address Line 1"
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        id={`addressLine1Div_${index}`}
                        name={`addressLine1_${index}`}
                        value={
                          data?.addressLine1 !== undefined
                            ? data?.addressLine1
                            : ''
                        }
                        maxlength={30}
                        errorMessage={
                          (data?.addressLine1 == undefined ||
                            data?.addressLine1 == null ||
                            data?.addressLine1 == '') &&
                          isrequired === true
                            ? FIELD_ERROR
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item md={4} lg={2}>
                      {/* <label htmlFor="add-line2" className="form-label">
                        Address Line 2
                      </label>
                      <input
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        className="form-control"
                        id={`addressLine2Div_${index}`}
                        name={`addressLine2_${index}`}
                        value={
                          data?.addressLine2 !== undefined
                            ? data?.addressLine2
                            : ''
                        }
                        data-testid="Address2TextBox"
                        onChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxLength={20}
                      /> */}
                      <RACTextbox
                        isCurrency={false}
                        required={false}
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        inputlabel="Address Line 2"
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxlength={30}
                        id={`addressLine2Div_${index}`}
                        name={`addressLine2_${index}`}
                        value={
                          data?.addressLine2 !== undefined
                            ? data?.addressLine2
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item md={4} lg={2}>
                      {/* <label htmlFor="form_zip" className="form-label">
                        Zip
                        {data?.active == 'Y' ? (
                          <span className="mandatoryfield">*</span>
                        ) : null}
                      </label>
                      <input
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        className="form-control"
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        id={`zipDiv_${index}`}
                        name={`zip_${index}`}
                        value={
                          data?.postalCode !== undefined ? data?.postalCode : ''
                        }
                        data-testid="zipTextBox"
                        onChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxLength={10}
                      /> */}
                      <RACTextbox
                        isCurrency={false}
                        required={true}
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        id={`zipDiv_${index}`}
                        name={`zip_${index}`}
                        value={
                          data?.postalCode !== undefined ? data?.postalCode : ''
                        }
                        data-testid="zipTextBox"
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxlength={10}
                        errorMessage={
                          (data?.addressLine2 == undefined ||
                            data?.addressLine2 == null ||
                            data?.addressLine2 == '') &&
                          isrequired === true
                            ? FIELD_ERROR
                            : ''
                        }
                        inputlabel="Zip"
                      />
                      {(data?.postalCode == undefined ||
                        data?.postalCode == null ||
                        data?.postalCode == '') &&
                      isrequired === true &&
                      data?.active == 'Y' ? (
                        <label className="mandatoryfield validation">
                          Required
                        </label>
                      ) : isrequired === true &&
                        data?.active == 'Y' &&
                        data?.postalCode.length !== 5 &&
                        data?.postalCode.length !== 10 ? (
                        <label className="mandatoryfield validation">
                          Invalid Zip
                        </label>
                      ) : null}
                    </Grid>
                    <Grid item md={4} lg={2}>
                      {/* <label htmlFor="form_city" className="form-label">
                        City
                        {data?.active == 'Y' ? (
                          <span className="mandatoryfield">*</span>
                        ) : null}
                      </label> */}
                      {/* <input
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        className="form-control"
                        id={`cityDiv_${index}`}
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        name={`city_${index}`}
                        value={data?.city !== undefined ? data?.city : ''}
                        data-testid="cityTextBox"
                        onChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxLength={15}
                      /> */}
                      <RACTextbox
                        isCurrency={false}
                        required={true}
                        type={
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? 'password'
                            : 'text'
                        }
                        inputlabel="City"
                        id={`cityDiv_${index}`}
                        disabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        name={`city_${index}`}
                        value={data?.city !== undefined ? data?.city : ''}
                        OnChange={(e: any) =>
                          handleChangeForAddReferenceInfo(e)
                        }
                        maxlength={15}
                        errorMessage={
                          (customerInformation?.city == undefined ||
                            customerInformation?.city == null ||
                            customerInformation?.city == '') &&
                          isrequired === true
                            ? FIELD_ERROR
                            : ''
                        }
                      />
                      {(data?.city == undefined ||
                        data?.city == null ||
                        data?.city == '') &&
                      isrequired === true &&
                      data?.active == 'Y' ? (
                        <label className="mandatoryfield validation">
                          Required
                        </label>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item md={4} lg={1}>
                      <RACSelect
                        inputLabel="State"
                        options={state}
                        defaultValue={
                          data?.state !== undefined &&
                          data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? '*'
                            : data?.state !== null && data?.state !== ''
                            ? data?.state
                            : '0'
                        }
                        isDisabled={
                          !activeReferencOBj ||
                          (data?.doNotCall !== undefined &&
                          data?.doNotCall == 'Y'
                            ? true
                            : false)
                        }
                        required={data?.active == 'Y' ? true : false}
                        loading={
                          state.length == 0 && !dropDownLoaded ? true : false
                        }
                        onChange={(e: any) => {
                          e.target.name = `state_${index}`;
                          handleChangeForAddReferenceInfo(e);
                        }}
                        errorMessage={
                          (data?.state == undefined ||
                            data?.state == null ||
                            data?.state == '' ||
                            data?.state == '0') &&
                          isrequired === true &&
                          data?.active == 'Y'
                            ? FIELD_ERROR
                            : ''
                        }
                        {...(state.length == 0 &&
                          dropDownLoaded && {
                            errorMessage: API_ERROR_MESSAGE,
                          })}
                      />
                      {/* <label htmlFor="state-select" className="form-label">
                  State
                  {data?.active == 'Y' ? (
                    <span className="mandatoryfield">*</span>
                  ) : null}
                </label>
                <select
                  className="form-select"
                  id={`stateDiv_${index}`}
                  disabled={
                    !activeReferencOBj ||
                    (data?.doNotCall !== undefined && data?.doNotCall == 'Y'
                      ? true
                      : false)
                  }
                  name={`state_${index}`}
                  value={
                    data?.state !== undefined &&
                    data?.doNotCall !== undefined &&
                    data?.doNotCall == 'Y'
                      ? '*'
                      : data?.state
                  }
                  data-testid="stateDropdown"
                  onChange={(e: any) => handleChangeForAddReferenceInfo(e)}
                >
                  <option selected value="0">
                    Select
                  </option>
                  {data?.state !== undefined &&
                  data?.doNotCall !== undefined &&
                  data?.doNotCall == 'Y' ? (
                    <option value="*">**</option>
                  ) : null}
                  {dynamicDropdown(state)}
                </select> */}
                      {/* {(data?.state == undefined ||
                  data?.state == null ||
                  data?.state == '' ||
                  data?.state == '0') &&
                isrequired === true &&
                data?.active == 'Y' ? (
                  <label className="mandatoryfield validation">Required</label>
                ) : (
                  ''
                )} */}
                    </Grid>
                    <Grid item md={4} lg={2}>
                      <div className="form-check mt-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={`doNotVisitDiv_${index}`}
                          name={`doNotVisit_${index}`}
                          checked={
                            data?.doNotCall !== undefined &&
                            data?.doNotCall == 'Y'
                              ? true
                              : false
                          }
                          data-testid="DoNotVisitCheckBox"
                          onChange={(e: any) =>
                            handleChangeForAddReferenceInfo(e)
                          }
                          disabled={!activeReferencOBj}
                        />
                        <label
                          className="form-check-label racpad-margin"
                          htmlFor="not-visit"
                        >
                          Do Not Visit
                        </label>
                      </div>
                    </Grid>
                    {index != 0 &&
                    (data?.personalReferenceId == undefined ||
                      data?.personalReferenceId == null ||
                      data?.personalReferenceId == '') ? (
                      <Grid item md={3} lg={1} className="mt-3">
                        {/* <button
                    type="button"
                    className="btn btn-secondary"
                    data-testid="cancelBtnTest"
                    onClick={() => removeDivanotherReference(index)}
                  >
                    Cancel
                  </button> */}
                        <RACButton
                          className="btn btn-secondary"
                          onClick={() => removeDivanotherReference(index)}
                        >
                          Cancel
                        </RACButton>
                        {/* <a
                    className="racpad-link cursor"
                    onClick={() => removeDivanotherReference(index)}
                  >
                    X Cancel
                  </a> */}
                      </Grid>
                    ) : null}
                  </Grid>
                  {/* {index == 0 ? null : (
              <div className="col-md-10 py-1 px-0 text-start">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  name={`removeDiv_${index}`}
                  onClick={() => removeDivanotherReference(index)}
                >
                  Remove
                </button>
              </div>
            )} */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      );
    });
  };
  const getEmployOptions = async () => {
    //setIsLoading(true);
    if (
      dayPaid1 == undefined ||
      dayPaid1 == null ||
      dayPaid1.length == 0 ||
      dayPaid3 == undefined ||
      dayPaid3 == null ||
      dayPaid3.length == 0 ||
      dayPaid5 == undefined ||
      dayPaid5 == null ||
      dayPaid5.length == 0
    ) {
      await getDayPaidOption(1);

      await getDayPaidOption(3);

      await getDayPaidOption(5);
    }

    //setIsLoading(false);
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const getDayPaidOption = async (sequenceId: any) => {
    //setIsLoading(true);
    const getEmployOptions = await GetEmployInfoOptions(sequenceId);
    // setIsLoading(false);
    /* eslint-disable no-console */
    console.log('getEmployOptions', getEmployOptions);
    if (payDayfrequencyAdded === false) {
      const paidDropDown: [] | any = [];
      if (
        getEmployOptions?.referenceOptionsDTO.referenceDetails != undefined &&
        getEmployOptions?.referenceOptionsDTO.referenceDetails != null &&
        getEmployOptions?.referenceOptionsDTO.referenceDetails.length > 0
      ) {
        const sortSeq =
          getEmployOptions?.referenceOptionsDTO.referenceDetails.sort(
            (a: any, b: any) => (a['displaySeq'] < b['displaySeq'] ? -1 : 1)
          );
        sortSeq.map((value: any, index: any) => {
          if (index === 0) {
            paidDropDown.push({
              label: 'Select',
              value: '0',
              name: 'select',
            });
          }
          paidDropDown.push({
            label: value.description,
            value: value.referenceCode,
            name: value.id,
          });
        });
      }
      setpayDayfrequencyAdded(true);
      setpayDayfrequency(paidDropDown);
    }
    // eslint-disable-next-line
    //debugger
    const dayPaidDropDown: dropdown[] = [];
    if (
      getEmployOptions?.dayspaidDTO != undefined &&
      getEmployOptions?.dayspaidDTO != null &&
      getEmployOptions?.dayspaidDTO.dayPaid !== undefined &&
      getEmployOptions?.dayspaidDTO.dayPaid !== null &&
      getEmployOptions?.dayspaidDTO.dayPaid.length > 0
    ) {
      getEmployOptions.dayspaidDTO.dayPaid.map((value: any, index: any) => {
        dayPaidDropDown.push({
          label: value.daysPaidDescEn,
          value: value.refCode,
        });
      });
      // eslint-disable-next-line no-console
      let sorted: any = dayPaidDropDown;
      if (sequenceId !== 1) {
        sorted = dayPaidDropDown.sort((a: any, b: any) => {
          return a['label'].localeCompare(b['label'], undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        });
      }
      const dayPaidDD: dropdown[] = [];
      sorted.map((value: any, index: any) => {
        if (index === 0) {
          dayPaidDD.push({
            label: 'Select',
            value: '0',
          });
        }
        dayPaidDD.push(value);
      });
      // const sortAlphaNumeric = (a: any, b: any) => {
      //   return a.Text.localeCompare(b.Text);
      // };
      if (sequenceId == 1) {
        setdayPaid1(dayPaidDD);
      } else if (sequenceId == 3) {
        setdayPaid3(dayPaidDD);
        // eslint-disable-next-line no-console
        console.log('dayPaidDropDown', dayPaidDD);
      } else if (sequenceId == 5) {
        setdayPaid5(dayPaidDD);
        // eslint-disable-next-line no-console
        console.log('dayPaidDropDown', dayPaidDD);
      }
    }

    //return dayPaidDropDown;
  };
  const handleUserID = (event: any) => {
    // setErrorLine(false);
    setUserID(event.target.value);
  };

  const handlePassword = (event: any) => {
    // setErrorLine(false);
    setPassword(event.target.value);
  };

  const loginClick = async (username: any, Password: any) => {
    setOpenModal(false);
    setloader(true);
    const payload = {
      userName: username,
      role: role,
      storeNumber: window.sessionStorage.getItem('storeNumber'),
      password: Password,
    };
    setErrorLine(false);
    const result = await getsecondfactor(payload);
    setloader(false);
    UserValidate(result);
  };
  const seconduthenResponses = (e: any) => {
    if (e === true) {
      if (authenType == 'RACWORKER') {
        setcustomerInformation({
          ...customerInformation,
          racCoworker: customerInformation.racCoworker == 'Y' ? 'N' : 'Y',
        });
      } else if (authenType == 'ALERT') {
        let alertUpdateObj: any = AlertUpdateObj;
        if (AlertAuthenticationNeeded.removeAlert == undefined) {
          alertUpdateObj.push(AlertAuthenticationNeeded);
        } else {
          alertUpdateObj = alterAlert(
            'remove',
            null,
            AlertAuthenticationNeeded.alertTypeId
          );
        }
        setAlertUpdateObj([...alertUpdateObj]);
        setAlertAuthenticationNeeded('');
      } else if (authenType == 'ROUTES') {
        setcustomerInformation({
          ...customerInformation,
          route: preferredRoute,
        });
        setPreferrefRoute('');
      } else if (authenType == 'SKIP') {
        if (skipHardObj == 'S') {
          setcustomerInformation({
            ...customerInformation,
            skip: customerInformation?.skip == 'Y' ? 'N' : 'Y',
          });
        } else if (skipHardObj == 'H') {
          setcustomerInformation({
            ...customerInformation,
            hard: customerInformation?.hard == 'Y' ? 'N' : 'Y',
          });
        }
        setskipHardObj('');
      }
      setSecondLevelAuthenOn(null);
      setauthenType('');
    }
  };
  const UserValidate = (result: any) => {
    if (result.status == 200) {
      if (
        result.data.LoginAuthenticationResult.value.StatusCode === '200' &&
        result.data.CoworkerAuthenticationResult.value.statusCode === 200
      ) {
        setOpenModal(false);
        if (authenType == 'RACWORKER') {
          setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
        }
        if (authenType == 'ALERT') {
          const alertUpdateObj: any = AlertUpdateObj;
          alertUpdateObj.push(AlertAuthenticationNeeded);
          setAlertUpdateObj([...alertUpdateObj]);
          setAlertAuthenticationNeeded('');
        }
        if (authenType == 'ROUTES') {
          setcustomerInformation({
            ...customerInformation,
            route: preferredRoute,
          });
          setPreferrefRoute('');
        }
      } else {
        setOpenModal(false);
        setOpenSecondModal(true);
      }
    } else {
      setOpenModal(false);
      setOpenSecondModal(true);
    }
    setotp('');
    setUserID('');
    setPassword('');
    setauthenType('');
  };
  const popupSelect = (result: any) => {
    if (result.data.pin === 1 && result.data.secondFactor === 1) {
      setOpenPinModal(true);
      setpopupFlag(true);
    } else if (result.data.pin === 1) {
      setOpenPinModal(true);
      setpopupFlag(false);
    } else if (result.data.secondFactor === 1) {
      setOpenModal(true);
      setpopupFlag(false);
    }
  };

  const openModelCancelClick = () => {
    setOpenModal(false);
    setErrorLine(false);
  };

  const okClick = () => {
    setOpenSecondModal(false);
    setOpenModal(true);
    setErrorLine(true);
    // setUserID('');
    // setPassword('');
  };
  useEffect(() => {
    if (otp.length == 4) {
      setContinue(false);
    } else {
      setContinue(true);
    }
  }, [otp]);
  const continueClick = async () => {
    setOpenPinModal(false);
    setloader(true);
    const userResponse = await getCurrentInfo();
    // eslint-disable-next-line no-console
    console.log('getCurrentUser Response', userResponse);
    const id = userResponse.data.employeeId
      ? userResponse.data.employeeId
      : userResponse.data.email.toLowerCase();
    const pin = otp;
    const payload = {
      userId: id,
      encryptedPin: pin.toString(),
    };
    // eslint-disable-next-line no-console
    console.log('getPin Payload', payload);
    const result = await getPinDetails(payload);
    // eslint-disable-next-line no-console
    console.log('Total Result', result);
    setloader(false);
    setOpenPinModal(true);
    setInvalidPin(result.status);
    if (result.data.statusCode == 200 && popupFlag) {
      setOpenPinModal(false);
      setOpenModal(true);
    }
    if (result.data.statusCode == 200 && !popupFlag) {
      setOpenPinModal(false);
      setcustomerInformation({ ...customerInformation, racCoworker: 'Y' });
    }
  };

  const setChargeOffclose = () => {
    setOpenPinModal(false);
  };

  //   const chargeoffPopup = () => {
  //     setOpenPinModal(true);
  //   };
  const renderAgreementHistoryHeader = () => (
    <>
      {agreementHistoryHeader.map((col: string, index: number) => {
        return (
          <RACTableCell
            className={
              col == '# Payments' || col == 'Total Cost' || col == 'Amount Due'
                ? 'racstrap-tablecell-BgColor RAC-CUSTOMER-grid-text-align'
                : 'racstrap-tablecell-BgColor'
            }
            key={index}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  const renderAgreementHistoryCOntentForActiveInactive = () => {
    const payloadObj = [
      { objKey: 'Active', objVal: activeExpand },
      { objKey: 'Pending', objVal: pending },
      { objKey: 'InActive', objVal: inActiveExpand },
    ];
    return payloadObj.map((el: any) => {
      return AgreementGrid(el.objKey, el.objVal);
    });
    // AgreementGrid('Active', activeExpand);
    // AgreementGrid('Pending', pending);
    // AgreementGrid('InActive', inActiveExpand);
  };
  /* eslint-disable sonarjs/cognitive-complexity */
  const renderContentNoRecordFoundForAGreementHistory = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Agreements Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderAgreementHistoryContent: any = () => {
    //if (loading) return <></>;

    return agreementInf !== undefined &&
      agreementInf !== null &&
      agreementInf.length > 0
      ? renderAgreementHistoryCOntentForActiveInactive()
      : renderContentNoRecordFoundForAGreementHistory();
  };
  const noClick = () => {
    setOpenPinModal(false);
  };

  const pinEnter = (event: any) => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    if (event.charCode === 13 && Continue == false) {
      event.preventDefault();
      continueClick();
    }
  };

  const secondfactorEnter = (event: any, username: any, Password: any) => {
    /* eslint-enable no-console */
    if (event.charCode === 13 && userID !== '' && password !== '') {
      event.preventDefault();
      loginClick(username, Password);
    }
  };
  const otpchange = (event: any) => {
    setInvalidPin(200);
    setotp(event);
  };
  const racCoWorkerFunc = async (moduleName: string) => {
    //const d: any = document.getElementsByName('RAC_Co_worker');
    try {
      setSecondLevelAuthenOn(moduleName);
      //setloader(true);

      // if (value) {
      //   setsomethingone('modal fade show expandPopup');
      //   setsomethingtwo('modal fade');
      //   const payload = {
      //     module: moduleName,
      //     storeNumber: window.sessionStorage.getItem('storeNumber'),
      //     currentRole: coWorkerRole,
      //   };
      //   // eslint-disable-next-line no-console
      //   console.log('getprofileaccess payload', payload);
      //   const result = await getprofileaccess(payload);
      //   // eslint-disable-next-line no-console
      //   console.log('getprofileaccess result', result);
      //   setloader(false);
      //   setRole(result.data.role);
      //   popupSelect(result);
      // }
      // setloader(false);
    } catch (e: any) {
      //setloader(false);
      // eslint-disable-next-line no-console
      console.log('getprofileaccess payload', e);
    }
  };
  const printHTML = async () => {
    const response = await getPrintDetail();
    setGetStore(response);
    if (response !== undefined) {
      const html = document?.getElementById('printHTML')?.innerHTML;
      // eslint-disable-next-line no-console
      console.log('html', html);
      if (html !== undefined && html !== null) {
        //const divContents = html;
        const a: any = window.open(
          '',
          '',
          'width=1000,height=650,scrollbars=1,menuBar=1'
        );
        a.document.write('<html>');
        a.document.write(html);
        a.document.write('</html>');
        a.document.close();
        a.print();
      }
    }
  };
  const getPrintDetail = async () => {
    setIsLoading(true);
    const response = await PrintFaxSheet(loginStore);
    setIsLoading(false);
    // eslint-disable-next-line no-console
    console.log('pfs', response);
    if (response !== undefined) {
      return response;
    } else {
      return undefined;
    }
  };
  // const dateOfBirthFocus = () => {
  //   setIsDOBOnFocus(true);
  //   document.getElementById('a11y_ssn')?.focus();
  //   document.getElementById('a11y_ssn1')?.focus();
  //   document.getElementById('a11y_ssn3')?.focus();
  //   document.getElementById('a11y_ssn4')?.focus();
  //   document.getElementById('a11y_ssn5')?.focus();
  //   document.getElementById('a11y_dob6')?.focus();
  // };
  const activateClubPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          <Typography className="RAC-popup-msg">
            {'Club will be immediately deactivated if you click "Yes".'}
            {'Club cannot be "Re-activated" without a payment being made.'}
            {'Are you sure you want to continue?'}
          </Typography>
        </Grid>
        <Grid container className="justify-content-center mt-4">
          <RACButton
            className="btn btn-secondary mx-2 semi-bold floatLeft"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => hideButtons()}
          >
            No
          </RACButton>
          <RACButton
            className="btn btn-primary"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => activateCLub()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // Would you like to Run DE popup PC-CC-26
  function ActivateClubfn() {
    return (
      <RACModalCard
        isOpen={activateClub}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => hideButtons()}
        // eslint-disable-next-line react/no-children-prop
        children={activateClubPopup()}
        title="Activate Club"
      />
    );
  }
  const ErrorMsgPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          <Typography variant={'body2'}>
            {' '}
            {pageType == undefined || pageType == 'customer'
              ? 'Please fill all the mandatory fields in the below listed sections'
              : 'Please fill all the mandatory fields'}
          </Typography>
          {validateResult.map((element: any, index: any) => {
            return (
              <Typography className="error-popup" key={index}>
                {element}
              </Typography>
            );
          })}
        </Grid>
        <Grid container className="justify-content-center mt-4">
          <RACButton
            className="btn btn-primary"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => hideErrorPopUp()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // popup to enter SSn PC-CC-27
  function ErrorMgs() {
    return (
      <RACModalCard
        isOpen={errorPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => hideErrorPopUp()}
        // eslint-disable-next-line react/no-children-prop
        children={ErrorMsgPopup()}
      />
    );
  }
  const UpdateInfoMsgPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          {isSuccess ? <SuccessIcon></SuccessIcon> : <Alerticon></Alerticon>}
          <Typography variant={'body2'}>
            {isSuccess
              ? 'Customer information is updated successfully'
              : // eslint-disable-next-line sonarjs/no-duplicate-string
                'Something went wrong'}
          </Typography>
        </Grid>
        <Grid container className="text-center mt-4">
          <RACButton
            className="btn btn-primary"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => hideupdatePopUp()}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const createAgreementPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          <Typography className="RAC-popup-msg">
            Would you like to add the Co-Customer for agreements?
          </Typography>
        </Grid>
        <Grid container className="justify-content-center mt-4">
          {/* <button
            type="button"
            className="btn btn-secondary mx-1"
            data-bs-dismiss="modal"
            data-testid="creaNewCustNo"
            onClick={() => NOHandleChange()}
          >
            No
          </button>
          <button
            type="button"
            className="btn btn-primary mx-1"
            data-bs-dismiss="modal"
            data-testid="creaNewCustYes"
            data-bs-toggle="modal"
            onClick={() => YesHandleChange()}
          >
            Yes
          </button> */}
          <RACButton
            className="btn btn-secondary me-2"
            size="small"
            variant="outlined"
            isRounded={false}
            onClick={() => agreementRedirect()}
          >
            Not Now
          </RACButton>
          <RACButton
            className="btn btn-primary"
            size="small"
            variant="outlined"
            isRounded={false}
            onClick={() => {
              setcreateAgreementPopUp(false);
              setcreateCoCustomerPopUp(true);
            }}
          >
            Yes, Create new agreement
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function CreateAgreementrMsg() {
    return (
      <RACModalCard
        isOpen={createAgreementPopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcreateAgreementPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={createAgreementPopup()}
        title=""
      />
    );
  }
  // popup to enter SSn PC-CC-27
  function UpdateInfoMsg() {
    return (
      <RACModalCard
        isOpen={updatePopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => hideupdatePopUp()}
        // eslint-disable-next-line react/no-children-prop
        children={UpdateInfoMsgPopup()}
      />
    );
  }
  const liketoRunAgreementPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          <Typography className="RAC-popup-msg">
            Would you like to add the Co-Customer for agreement?
          </Typography>
        </Grid>
        <Grid container className="justify-content-center mt-4">
          <RACButton
            className="btn btn-secondary ms-2 semi-bold floatLeft me-2"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              setcreateCoCustomerPopUp(false);
              props.history.push(`/agreement/rental/itemsearch/${customerId}`);
            }}
          >
            No
          </RACButton>
          <RACButton
            className="btn btn-primary"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              setcreateCoCustomerPopUp(false);
              Setshowcocustomerpopup(true);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // Would you like to Run DE popup PC-CC-26
  function LiketoRunAgreementMsg() {
    return (
      <RACModalCard
        isOpen={createCoCustomerPopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcreateCoCustomerPopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={liketoRunAgreementPopup()}
        title=""
      />
    );
  }
  const cancelWithoutSavingtPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid item md={12} className="text-center">
          <Typography className="RAC-popup-msg">
            Cancel without saving?
          </Typography>
        </Grid>
        <Grid container className="justify-content-center mt-4">
          <RACButton
            className="btn btn-secondary ms-2 semi-bold floatLeft me-2"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              setcustomerInfoChangePopUp(false);
            }}
          >
            No
          </RACButton>
          <RACButton
            className="btn btn-primary"
            size="small"
            variant="outlined"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              props.history.push(`/agreement/rental/itemsearch/${customerId}`);
            }}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  // Would you like to Run DE popup PC-CC-26
  function CancelWithoutSavingtMsg() {
    return (
      <RACModalCard
        isOpen={customerInfoChangePopUp}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setcustomerInfoChangePopUp(false)}
        // eslint-disable-next-line react/no-children-prop
        children={cancelWithoutSavingtPopup()}
        title=""
      />
    );
  }
  // const addressDoctorPopup = () => {
  //   return (
  //     <Grid
  //       item
  //       md={12}
  //       px={2}
  //       id="confirmduedate"
  //       data-bs-backdrop="static"
  //       data-bs-keyboard="false"
  //       aria-hidden="true"
  //     >
  //       {AddressDoc === true ? (
  //         <div className="Norecords">
  //           <CircularProgress />
  //         </div>
  //       ) : SuggestedAddress == undefined ? (
  //         <Typography className="RAC-popup-msg">
  //           Automatic Address validation is Unavailable for the store # Please
  //           validate the address Manually.
  //         </Typography>
  //       ) : (
  //         <React.Fragment>
  //           <Typography className="RAC-popup-msg">
  //             Entered address is not located
  //           </Typography>
  //           <Grid
  //             item
  //             md={12}
  //             sx={{
  //               // eslint-disable-next-line sonarjs/no-duplicate-string
  //               marginBottom: '10px!important',
  //               marginTop: '20px!important',
  //             }}
  //           >
  //             <Typography className="RAC-popup-msg">
  //               Suggested Addresses
  //             </Typography>
  //             <div className="form-check form-check-inline">
  //               {/* <input
  //             className="form-check-input"
  //             type="radio"
  //             data-testid="ValidateInputField"
  //             name="inlineRadioOptions"
  //             id="inlineRadio1"
  //             defaultValue="option1"
  //             checked={defaultSuggestedAddress == 'SUG' ? true : false}
  //             onClick={() => RadioBtnclick('SUG')}
  //           /> */}
  //               <RACRadio
  //                 id="inlineRadio1"
  //                 name="inlineRadioOptions"
  //                 checked={defaultSuggestedAddress == 'SUG' ? true : false}
  //                 onClick={() => RadioBtnclick('SUG')}
  //                 value={'SUG'}
  //               />
  //               <Typography variant="caption" className="RAC-popup-msg">
  //                 {SuggestedAddress !== undefined &&
  //                 SuggestedAddress !== null &&
  //                 SuggestedAddress.Addresses !== null &&
  //                 SuggestedAddress.Addresses.StreetAddress !== undefined
  //                   ? `${SuggestedAddress.Addresses.StreetAddress},`
  //                   : ''}

  //                 {SuggestedAddress !== undefined &&
  //                 SuggestedAddress !== null &&
  //                 SuggestedAddress.Addresses !== null &&
  //                 SuggestedAddress.Addresses.City !== undefined
  //                   ? `${SuggestedAddress.Addresses.City},`
  //                   : ''}
  //                 {SuggestedAddress !== undefined &&
  //                 SuggestedAddress !== null &&
  //                 SuggestedAddress.Addresses !== null &&
  //                 SuggestedAddress.Addresses.StateProvince !== undefined
  //                   ? `${SuggestedAddress.Addresses.StateProvince},`
  //                   : ''}
  //                 {SuggestedAddress !== undefined &&
  //                 SuggestedAddress !== null &&
  //                 SuggestedAddress.Addresses !== null &&
  //                 SuggestedAddress.Addresses.PostalCode !== undefined
  //                   ? `${SuggestedAddress.Addresses.PostalCode}`
  //                   : ''}
  //               </Typography>
  //             </div>
  //           </Grid>
  //           <Grid item md={12} sx={{ marginBottom: '10px!important' }}>
  //             <Typography className="RAC-popup-msg">Entered Address</Typography>
  //             <div className="form-check form-check-inline">
  //               <RACRadio
  //                 id="inlineRadio2"
  //                 name="inlineRadioOptions"
  //                 checked={defaultSuggestedAddress == 'ENT' ? true : false}
  //                 onClick={() => RadioBtnclick('ENT')}
  //                 value={'ENT'}
  //               />
  //               <Typography variant="caption" className="RAC-popup-msg">
  //                 {runAddressDoc === 'P'
  //                   ? updatePrimaryAddress.address +
  //                     ',' +
  //                     updatePrimaryAddress.address1 +
  //                     ',' +
  //                     updatePrimaryAddress.city +
  //                     ',' +
  //                     updatePrimaryAddress.state +
  //                     '-' +
  //                     updatePrimaryAddress.zip
  //                   : updateMailingAddress.address +
  //                     ',' +
  //                     updateMailingAddress.address1 +
  //                     ',' +
  //                     updateMailingAddress.city +
  //                     ',' +
  //                     updateMailingAddress?.state +
  //                     '-' +
  //                     updateMailingAddress.zip}
  //               </Typography>
  //             </div>
  //           </Grid>
  //           <Grid container mt={4}>
  //             <Grid item md={6}>
  //               <RACButton
  //                 className="btn btn-secondary"
  //                 onClick={() => continueclickAddressDoc('ENT', runAddressDoc)}
  //               >
  //                 Cancel
  //               </RACButton>
  //             </Grid>
  //             <Grid item md={6} sx={{ textAlign: 'right' }}>
  //               <RACButton
  //                 className="btn btn-primary me-2"
  //                 onClick={() => continueclickAddressDoc('ENT', runAddressDoc)}
  //                 disabled={BtnIsdisabled1}
  //               >
  //                 Override
  //               </RACButton>
  //               <RACButton
  //                 className="btn btn-primary"
  //                 disabled={BtnIsdisabled2}
  //                 onClick={() => continueclickAddressDoc('SUG', runAddressDoc)}
  //               >
  //                 continue
  //               </RACButton>
  //             </Grid>
  //           </Grid>
  //         </React.Fragment>
  //       )}
  //     </Grid>
  //   );
  // };
  // function ValidationMessageMsg() {
  //   return (
  //     <RACModalCard
  //       isOpen={ValidateIsOpen}
  //       maxWidth="xs"
  //       closeIcon={true}
  //       onClose={() => setValidateIsOpen(false)}
  //       // eslint-disable-next-line react/no-children-prop
  //       children={addressDoctorPopup()}
  //       title="Validation Message"
  //     />
  //   );
  // }
  const openModalContentPopup = () => {
    return (
      <div>
        <Grid className="text-center">
          <RACLogo className="logo-responsive"></RACLogo>
        </Grid>
        {errorline === true ? (
          <Grid className="float-left my-2 mx-1">
            <Typography className="racLoginAlert">
              The user ID and/or password you entered are not valid. Please try
              again.
            </Typography>
          </Grid>
        ) : null}
        <Grid container spacing={2} className="mt-2">
          <Grid item>
            <Typography
              variant="body2"
              component="label"
              style={{ fontFamily: 'OpenSans-semibold' }}
            >
              User ID
            </Typography>
            <RACTextbox
              maxlength={30}
              className="racSecFactorTextbox"
              isCurrency={false}
              type="text"
              value={userID}
              OnChange={(event: any) => handleUserID(event)}
            ></RACTextbox>
          </Grid>
          <Grid item className="p-2">
            <Typography
              variant="body2"
              component="label"
              style={{ fontFamily: 'OpenSans-semibold' }}
            >
              Password
            </Typography>
            <RACTextbox
              maxlength={15}
              onKeyPress={(event: any) =>
                secondfactorEnter(event, userID, password)
              }
              isCurrency={false}
              className="racSecFactorTextbox"
              type="password"
              value={password}
              OnChange={(event: any) => handlePassword(event)}
            ></RACTextbox>
          </Grid>
        </Grid>
        <Grid className="floatRight mt-5 mb-3">
          <RACButton
            className="btn  btn-secondary me-2"
            onClick={openModelCancelClick}
          >
            Cancel
          </RACButton>
          {userID !== '' && password !== '' ? (
            <RACButton
              className="racSearch racSearchPrimary me-2"
              onClick={() => loginClick(userID, password)}
            >
              Login
            </RACButton>
          ) : (
            <RACButton className="racSearch disabled racSearchPrimary me-2">
              Login
            </RACButton>
          )}
        </Grid>
      </div>
    );
  };
  function OpenModelMsg() {
    return (
      <RACModalCard
        isOpen={openModal}
        onClose={openModelCancelClick}
        maxWidth={'xs'}
        title={''}
        //eslint-disable-next-line react/no-children-prop
        children={openModalContentPopup()}
      ></RACModalCard>
    );
  }
  const AlertPopUp = () => {
    return (
      <Grid container>
        <Grid item className="list-group w-100 floatLeft">
          {Assignalert()}
          {Enabletxt !== true ? (
            <RACTextbox
              isCurrency={false}
              //hidden={Enabletxt}
              id="CustomAlert"
              value={CustomAlerttxt}
              OnChange={(e: any) => CustomAlertval(e)}
            />
          ) : null}
        </Grid>
        <Grid item md={12} className="modal-footer border-0">
          {/* <button
                            type="button"
                            className="btn btn-secondary mx-1"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => Cancelclick()}
                          >
                            Cancel
                          </button> */}
          <RACButton
            size="small"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{
              textTransform: 'none',
              fontWeight: 'bolder',
              paddingLeft: '14px',
              paddingRight: '14px',
              float: 'right',
            }}
            className="btn btn-secondary mx-1"
            onClick={() => Cancelclick()}
          >
            Cancel
          </RACButton>
          {/* <button
                            type="button"
                            disabled={disableAlert}
                            className="btn btn-primary mx-1"
                            onClick={() => {
                              Assignsave_click();
                              closeAlert();
                            }}
                          >
                            Save
                          </button> */}
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            disabled={
              (activeAlert.length == 0 && AlertUpdateObj.length == 0) ||
              disableAlert
                ? true
                : false
            }
            style={{
              marginLeft: '8px',
              backgroundColor: '#2179fe',
              padding: '8px 10px',
              font: 'white',
              textTransform: 'none',
            }}
            className="btn btn-primary mx-1"
            onClick={() => {
              Assignsave_click();
              closeAlert();
            }}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  function AlertModelMsg() {
    return (
      <RACModalCard
        isOpen={AlertIsOpen}
        maxWidth="xs"
        title="Customer Alerts"
        onClose={() => closeAlert()}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={AlertPopUp()}
      />
    );
  }
  const activateClubObjPopup = () => {
    return (
      <Grid container>
        <Grid item className="rac-col-md-12">
          <Grid container spacing={2} className="mb-3">
            <Grid item className="rac-col-md-6 ">
              <label htmlFor="a11y_Member" className="form-label labelfont">
                Member Id
              </label>
            </Grid>
            <Grid className="col-md-6">
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                  customerCoverageInf?.membershipNumber !== undefined
                    ? customerCoverageInf?.membershipNumber
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mb-3">
            <Grid item className="rac-col-md-6 ">
              <label htmlFor="a11y_Benefits" className="form-label labelfont">
                Level Of Benefits
              </label>
            </Grid>
            <Grid className="col-md-6">
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                  customerCoverageInf?.clubName !== undefined
                    ? customerCoverageInf?.clubName
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mb-3">
            <Grid item className="rac-col-md-6 ">
              <label htmlFor="a11y_Start" className="form-label labelfont">
                Start date
              </label>
            </Grid>
            <Grid className="col-md-6">
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                  customerCoverageInf?.startDate !== undefined
                    ? customerCoverageInf?.startDate
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mb-3">
            <Grid item className="rac-col-md-6 ">
              <label htmlFor="a11y_Date" className="form-label labelfont">
                Next Fee Date
              </label>
            </Grid>
            <Grid className="col-md-6">
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                  customerCoverageInf?.nextDueDate !== undefined
                    ? customerCoverageInf?.nextDueDate
                    : ''
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-3">
            <Grid item className="rac-col-md-6 ">
              <label htmlFor="a11y_Frequency" className="form-label labelfont">
                Payment Frequency
              </label>
            </Grid>
            <Grid className="col-md-6">
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                  customerCoverageInf?.paymentSchedule !== undefined
                    ? customerCoverageInf?.paymentSchedule
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mb-3">
            <Grid item className="rac-col-md-6 ">
              <label htmlFor="a11y_Fee" className="form-label labelfont">
                Fee
              </label>
            </Grid>
            <Grid className="col-md-6">
              <RACTextbox
                isCurrency={false}
                type={'text'}
                OnChange={(e: any) => {
                  handleChangeForAddReferenceInfo(e);
                }}
                disabled={true}
                value={
                  customerCoverageInf !== undefined &&
                  customerCoverageInf?.clubAmount !== undefined
                    ? '$ ' + customerCoverageInf?.clubAmount
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="floatRight mt-5 mb-3">
          <button
            type="button"
            className="btn btn-padding btn-secondary me-2"
            onClick={() => {
              setactivateClubObj(false);
              setactivateClub(true);
            }}
          >
            Deactivate
          </button>

          <button
            type="button"
            data-testid="btnenabled"
            className="btn btn-padding btn-primary me-2"
            onClick={() => setactivateClubObj(false)}
          >
            Ok
          </button>
        </Grid>
      </Grid>
    );
  };
  function ActivateClubObjMsg() {
    return (
      <RACModalCard
        isOpen={activateClubObj}
        maxWidth="xs"
        title=""
        onClose={() => setactivateClubObj(false)}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={activateClubObjPopup()}
      />
    );
  }
  const addCoCustomerChildrenPopup = () => {
    return (
      <AddCoCustomer
        customerId={customerId}
        handleClosePopUp={(action: boolean) => {
          Setshowcocustomerpopup(action);
        }}
        showCustomerInfo={(customerId: string) => {
          props.history.push(`/customer/update/${customerId}/customer`);
        }}
        history={props.history}
      ></AddCoCustomer>
    );
  };
  function AddCoCustomerMsg() {
    return (
      <RACModalCard
        isOpen={showcocustomerpopup}
        maxWidth="lg"
        closeIcon={true}
        onClose={() => Setshowcocustomerpopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={addCoCustomerChildrenPopup()}
        title="Co-Customer Information"
      />
    );
  }
  return (
    <Grid container className="racpad-container">
      <ActivateClubfn />
      <ErrorMgs />
      <UpdateInfoMsg />
      <LiketoRunAgreementMsg />
      <CancelWithoutSavingtMsg />
      <ValidationMessageMsg />
      <OpenModelMsg />
      <AlertModelMsg />
      <ActivateClubObjMsg />
      <AddCoCustomerMsg />
      <CreateAgreementrMsg />
      {/* <AssignALertfn /> */}
      {/* {!isLoading ? ( */}
      <React.Fragment>
        {pageType == undefined || pageType == 'customer' ? (
          <Grid item md={12}>
            <List className="breadcrumb py-1">
              <ListItem className="breadcrumb-item semi-bold font14">
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.history.push(`/dashboard/Gridcomponent`)}
                >
                  Dashboard
                </a>
              </ListItem>
              <ListItem
                className="breadcrumb-item semi-bold font14 active ps-0"
                aria-current="page"
              >
                {customerInformation?.firstName
                  ? customerInformation?.firstName +
                    ' ' +
                    customerInformation?.lastName
                  : ''}{' '}
                - {customerId}
              </ListItem>
            </List>
          </Grid>
        ) : null}

        <Grid container className="mb-3">
          <Card className="card w-100 border-radius-0 mb-3">
            <CardContent className="floatLeft w-100 py-0">
              <Grid item className="header-tab floatLeft w-100 ">
                {pageType == undefined || pageType == 'customer' ? (
                  <Box className="floatLeft">
                    <List
                      className="RAC-nav nav-tabs racpad-tab py-0"
                      id="CustTab"
                      role="tabList"
                    >
                      <ListItem
                        className={
                          activetab == 0 ? activeTabStyle1 : activeTabStyle2
                        }
                        id="Customerinfo-tab"
                        data-testid="customerInfoTab"
                        data-bs-toggle="tab"
                        data-bs-target="#customerinfo"
                        role="tab"
                        aria-controls="Customerinfo-tab"
                        aria-selected="true"
                        onClick={() => setactivetab(tabList.CUSTOMERINFO)}
                      >
                        Customer Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 1 ? activeTabStyle1 : activeTabStyle2
                        }
                        id="employment-tab"
                        data-testid="employmentInfoTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.EMPLOYMENTINFO)}
                      >
                        Employment Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 2 ? activeTabStyle1 : activeTabStyle2
                        }
                        id="employment-tab"
                        data-testid="residenceTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.RESIDENCE)}
                      >
                        Residence
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 3 ? activeTabStyle1 : activeTabStyle2
                        }
                        id="employment-tab"
                        data-testid="referenceTab"
                        data-bs-toggle="tab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.REFERENCE)}
                      >
                        Reference
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 4 ? activeTabStyle1 : activeTabStyle2
                        }
                        id="employment-tab"
                        data-bs-toggle="tab"
                        data-testid="vehicleInfoTab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => setactivetab(tabList.VEHICLEINFO)}
                      >
                        Vehicle Information
                      </ListItem>
                      <ListItem
                        className={
                          activetab == 5 ? activeTabStyle1 : activeTabStyle2
                        }
                        id="employment-tab"
                        data-bs-toggle="tab"
                        data-testid="agreementHistoryTab"
                        data-bs-target="#employmentinfo"
                        role="tab"
                        aria-controls="employment-tab"
                        aria-selected="false"
                        onClick={() => {
                          getAgreement();
                          setactivetab(tabList.AGREEMENTHISTORY);
                        }}
                      >
                        Agreement History
                      </ListItem>
                    </List>
                  </Box>
                ) : (
                  <Grid
                    item
                    id="smartwizard"
                    className="p-0 sw sw-theme-default sw-justified floatLeft"
                  >
                    <Grid item className="floatLeft bg-white py-2">
                      <List className="nav racpad-wizard RAC-wizard-customer">
                        <ListItem>
                          <a className="nav-link inactive done">
                            <Box
                              component="span"
                              className="border-value  widgard-completed"
                            >
                              1
                            </Box>
                            {customerIdQueryParam == null
                              ? 'Customer Validation'
                              : 'Co-Customer Validation'}
                          </a>
                        </ListItem>
                        <ListItem>
                          <a
                            className={
                              createActiveTab == 0
                                ? activeTavClass
                                : createActiveTab > 0
                                ? completedClass
                                : inactiveTabClass
                            }
                          >
                            <Box
                              component="span"
                              className={`border-value ${
                                createActiveTab == 0
                                  ? widgardActive
                                  : createActiveTab > 0
                                  ? widgardCompleted
                                  : ''
                              }`}
                            >
                              2
                            </Box>
                            Customer Information
                          </a>
                        </ListItem>
                        <ListItem>
                          <a
                            className={
                              createActiveTab == 1
                                ? activeTavClass
                                : createActiveTab > 1
                                ? completedClass
                                : inactiveTabClass
                            }
                          >
                            <Box
                              component="span"
                              className={`border-value ${
                                createActiveTab == 1
                                  ? widgardActive
                                  : createActiveTab > 1
                                  ? widgardCompleted
                                  : ''
                              }`}
                            >
                              3
                            </Box>
                            Employment Information
                          </a>
                        </ListItem>
                        {DEStatus !== undefined &&
                        DEStatus?.approvalStatus !== undefined &&
                        DEStatus?.approvalStatus != 'Approved' ? (
                          <React.Fragment>
                            <ListItem>
                              <a
                                className={
                                  createActiveTab == 2
                                    ? activeTavClass
                                    : createActiveTab > 2
                                    ? completedClass
                                    : inactiveTabClass
                                }
                              >
                                <Box
                                  component="span"
                                  className={`border-value ${
                                    createActiveTab == 2
                                      ? widgardActive
                                      : createActiveTab > 2
                                      ? widgardCompleted
                                      : ''
                                  }`}
                                >
                                  4
                                </Box>
                                Residence
                              </a>
                            </ListItem>
                            <ListItem>
                              <a
                                className={
                                  createActiveTab == 3
                                    ? activeTavClass
                                    : createActiveTab > 3
                                    ? completedClass
                                    : inactiveTabClass
                                }
                              >
                                <Box
                                  component="span"
                                  className={`border-value ${
                                    createActiveTab == 3
                                      ? widgardActive
                                      : createActiveTab > 3
                                      ? widgardCompleted
                                      : ''
                                  }`}
                                >
                                  5
                                </Box>
                                Reference
                              </a>
                            </ListItem>
                          </React.Fragment>
                        ) : null}
                      </List>
                    </Grid>
                  </Grid>
                )}
                <Grid item className="floatRight mt-1">
                  {pageType == undefined || pageType == 'customer' ? (
                    <React.Fragment>{buildVerification()}</React.Fragment>
                  ) : null}
                  {DEStatus !== undefined &&
                  DEStatus?.outstandingBalance !== undefined &&
                  DEStatus?.outstandingBalance !== null ? (
                    <Grid item className="floatLeft text-center mt-2">
                      <Box component="span" className="alert-color ">
                        Approval Amount
                      </Box>
                      <Box component="span" className="alert-color ">
                        {'$ ' + DEStatus?.outstandingBalance}
                      </Box>
                    </Grid>
                  ) : null}
                  {DEStatus !== undefined &&
                  DEStatus?.weeklyOutstandingBalance !== undefined &&
                  DEStatus?.weeklyOutstandingBalance !== null ? (
                    <Grid item className="floatLeft text-center mt-2">
                      <Box component="span" className="alert-color ">
                        Weekly Approval Amount
                      </Box>
                      <Box component="span" className="alert-color ">
                        {'$ ' + DEStatus?.weeklyOutstandingBalance}
                      </Box>
                    </Grid>
                  ) : null}
                  {DEStatus !== undefined &&
                  DEStatus?.approvalStatus == 'Approved' ? (
                    <Grid item className="floatRight text-center mt-1">
                      <Box
                        component="span"
                        className="badge racpad-badge bg-success badge-font"
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                      <Box
                        component="span"
                        className="d-block rac-de-date badge-font"
                      >
                        Expires on{' '}
                        {buildDateFormat2(DEStatus?.approvalExpirationDate)}
                      </Box>
                    </Grid>
                  ) : DEStatus?.approvalStatus == 'Decline' ? (
                    <Grid
                      item
                      className="floatRight text-center mt-1 declinetag"
                    >
                      <Box
                        component="span"
                        className="badge racpad-badge bg-failed badge-font badge-color"
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                    </Grid>
                  ) : DEStatus?.approvalStatus == 'Conditional Approval' ? (
                    <Grid item className="floatRight text-center mt-1">
                      <Box
                        component="span"
                        className="badge racpad-badge bg-pre-approve"
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item className="floatRight text-center mt-1">
                      <Box
                        component="span"
                        className="badge racpad-badge bg-failed badge-font badge-color"
                      >
                        {DEStatus?.approvalStatus}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {isLoading === true ? (
            <Grid md={12} className="text-center">
              <CircularProgress />
            </Grid>
          ) : (
            <React.Fragment>
              {((pageType == undefined || pageType == 'customer') &&
                activetab == 0) ||
              (pageType !== 'customer' && createActiveTab == 0) ? (
                <Grid container className="mb-5" id="RACTabContent">
                  {(pageType == undefined || pageType == 'customer') &&
                  legalHoldApplied !== 1 &&
                  isLegalHold !== true ? (
                    <Card className="card py-2 w-100">
                      <Grid item className="rac-col-md-12 floatLeft ">
                        <Grid
                          item
                          className="rac-col-lg-10 rac-col-md-10 floatLeft ps-2"
                        >
                          <Grid
                            item
                            className="w-100 floatLeft mt-2 widgat-responsive"
                          >
                            {activeAlert !== undefined &&
                            activeAlert !== null &&
                            activeAlert.length > 0 ? (
                              <React.Fragment>
                                <Typography
                                  variant="h4"
                                  className="font16 bold floatLeft me-1 mt-1"
                                >
                                  Customer Alert
                                </Typography>
                                <Typography
                                  variant="h4"
                                  className="font16 bold floatLeft me-4 alert-color mt-1"
                                >
                                  ({activeAlert.length})
                                </Typography>
                              </React.Fragment>
                            ) : (
                              <Typography
                                variant="h4"
                                className="bold font16 floatLeft me-1 mt-1"
                              >
                                Customer Alert
                              </Typography>
                            )}
                            <Grid
                              item
                              className=" alert-widgart-container widget-min-width"
                            >
                              {AlertMessages()}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          className="rac-col-lg-2 rac-col-md-2 floatLeft ps-2"
                        >
                          <Grid container>
                            <Grid item className="rac-col-lg-4 rac-col-md-2">
                              {isAlertLoading ? (
                                <Grid
                                  item
                                  className="Norecords alert-Loader mt-3"
                                >
                                  <CircularProgress className="alert-Loader-size" />
                                </Grid>
                              ) : null}
                            </Grid>
                            <Grid item className="rac-col-lg-8 rac-col-md-8">
                              <RACButton
                                variant="contained"
                                color="primary"
                                isRounded={false}
                                data-testid="assignAlert"
                                style={{
                                  marginLeft: '8px',
                                  backgroundColor: '#2179fe',
                                  padding: '8px 10px',
                                  font: 'white',
                                  textTransform: 'none',
                                  float: 'right',
                                }}
                                className="btn btn-primary btn-margin mt-1"
                                onClick={openAlert}
                              >
                                Assign Alert
                              </RACButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  ) : null}
                  {/*Basic Info starts assign  here*/}
                  <Grid container className="px-2 mt-3 mb-3">
                    <Grid item md={12}>
                      <Typography className="title">
                        Basic Information
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Card className="card w-100">
                        <CardContent>
                          <Grid container spacing={2} className="mb-2">
                            <Grid item lg={3} xl={2}>
                              {pageType != 'customer' ? (
                                <label htmlFor="a11y_fn" className="form-label">
                                  First Name
                                  <span className="mandatoryfield">*</span>
                                  {'  '}
                                  <span className="mx-2">
                                    {customerInformation?.firstName}
                                  </span>
                                </label>
                              ) : null}
                              {pageType == undefined ||
                              pageType == 'customer' ? (
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'text'}
                                  inputlabel="First Name"
                                  name={`FirstName`}
                                  OnChange={(e: any) => HandleOnChange(e)}
                                  maxlength={30}
                                  value={customerInformation?.firstName}
                                  errorMessage={
                                    customerInformation?.firstName !==
                                      undefined &&
                                    customerInformation?.firstName == '' &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                />
                              ) : null}
                              {/* <label htmlFor="a11y_fn" className="form-label">
                              First Name
                              <span className="mandatoryfield">*</span>
                              {'  '}
                              <span className="mx-2">
                                {pageType != 'customer'
                                  ? customerInformation?.firstName
                                  : null}
                              </span>
                            </label>
                            {pageType == undefined || pageType == 'customer' ? (
                              <input
                                type="text"
                                className="form-control"
                                id="a11y_fn"
                                data-testid="firstName"
                                name="FirstName"
                                value={customerInformation?.firstName}
                                onChange={(e: any) => HandleOnChange(e)}
                                maxLength={30}
                                required
                              />
                            ) : null}
                            {customerInformation?.firstName !== undefined &&
                            customerInformation?.firstName == '' &&
                            isrequired === true ? (
                              <label className="mandatoryfield validation">
                                Required
                              </label>
                            ) : (
                              ''
                            )} */}
                            </Grid>
                            <Grid item lg={3} xl={2}>
                              {pageType == undefined ||
                              pageType == 'customer' ? (
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'text'}
                                  inputlabel="Last Name"
                                  name={`LastName`}
                                  OnChange={(e: any) => HandleOnChange(e)}
                                  maxlength={30}
                                  value={customerInformation?.lastName}
                                  errorMessage={
                                    customerInformation?.lastName !==
                                      undefined &&
                                    customerInformation?.lastName == '' &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                />
                              ) : null}
                              {pageType != 'customer' ? (
                                <label htmlFor="a11y_ln" className="form-label">
                                  Last Name
                                  <span className="mandatoryfield">*</span>
                                  {'  '}
                                  <span className="mx-2">
                                    {customerInformation?.lastName}
                                  </span>
                                </label>
                              ) : null}
                              {/* {pageType == undefined || pageType == 'customer' ? (
                              <input
                                type="text"
                                className="form-control"
                                id="a11y_ln"
                                data-testid="lastName"
                                name="LastName"
                                value={customerInformation?.lastName}
                                onChange={(e: any) => HandleOnChange(e)}
                                maxLength={30}
                                required
                              />
                            ) : null}
                            {customerInformation?.lastName !== undefined &&
                            customerInformation?.lastName == '' &&
                            isrequired === true ? (
                              <label className="mandatoryfield validation">
                                Required
                              </label>
                            ) : (
                              ''
                            )} */}
                            </Grid>
                            {legalHoldApplied !== 1 && isLegalHold !== true ? (
                              <React.Fragment>
                                <Grid item lg={3} xl={2}>
                                  <Typography
                                    component="div"
                                    className="RAC-CUSTOMER--MuiFormLabel-root-date"
                                  >
                                    Date of Birth
                                    <Typography
                                      component="span"
                                      className="mandatoryfield"
                                    >
                                      *
                                    </Typography>
                                    {'  '}
                                    <Typography
                                      component="span"
                                      className="mx-2"
                                    >
                                      {pageType != 'customer'
                                        ? customerInformation?.dateOfBirth !==
                                            undefined &&
                                          customerInformation?.dateOfBirth !==
                                            null &&
                                          customerInformation?.dateOfBirth !==
                                            ''
                                          ? buildDateFormat2(
                                              customerInformation?.dateOfBirth
                                            ).substring(0, 6) + '****'
                                          : ''
                                        : null}
                                    </Typography>
                                  </Typography>
                                  {(pageType == undefined ||
                                    pageType == 'customer') &&
                                  isDOBOnFocus === false ? (
                                    <Grid
                                      className={
                                        isDOBOnFocus === false
                                          ? 'row date-picker-div ms-0 w-100'
                                          : 'row date-picker-div date-picker-highlighted ms-0'
                                      }
                                    >
                                      <Box
                                        onClick={() => {
                                          setIsDOBOnFocus(true);
                                        }}
                                      >
                                        {customerInformation?.dateOfBirth !==
                                          undefined &&
                                        customerInformation?.dateOfBirth !==
                                          null &&
                                        customerInformation?.dateOfBirth !== ''
                                          ? buildDateFormat2(
                                              customerInformation?.dateOfBirth
                                            ).substring(0, 6) + '****'
                                          : 'mm/dd/yyyy'}
                                        <Calendar className="date-Picker-img"></Calendar>
                                      </Box>
                                    </Grid>
                                  ) : pageType == undefined ||
                                    pageType == 'customer' ? (
                                    <RACDatePicker
                                      id="a11y_dob"
                                      value={
                                        customerInformation?.dateOfBirth !==
                                          undefined &&
                                        customerInformation?.dateOfBirth !==
                                          null &&
                                        customerInformation?.dateOfBirth !== ''
                                          ? buildDate(
                                              customerInformation?.dateOfBirth
                                            )
                                          : ''
                                      }
                                      required={true}
                                      onChange={(e: any) => {
                                        setcustomerInformation({
                                          ...customerInformation,
                                          dateOfBirth: buildDate(e),
                                        });
                                        setIsDOBOnFocus(false);
                                      }}
                                      label=""
                                      name={'Dob'}
                                      inputProps={{
                                        max:
                                          dt.getFullYear() -
                                          18 +
                                          '-' +
                                          (dt.getMonth() + 1) +
                                          '-' +
                                          (dt.getDate() < 10
                                            ? '0' + dt.getDate()
                                            : dt.getDate()),
                                      }}
                                    />
                                  ) : // <input
                                  //   type="date"
                                  //   className="form-control"
                                  //   id="a11y_dob"
                                  //   name="Dob"
                                  //   data-testid="dateOfBirth"
                                  //   value={
                                  //     customerInformation?.dateOfBirth !==
                                  //       undefined &&
                                  //     customerInformation?.dateOfBirth !==
                                  //       null &&
                                  //     customerInformation?.dateOfBirth !== ''
                                  //       ? buildDate(
                                  //           customerInformation?.dateOfBirth
                                  //         )
                                  //       : ''
                                  //   }
                                  //   onChange={(e: any) => HandleOnChange(e)}
                                  //   max={
                                  //     dt.getFullYear() -
                                  //     18 +
                                  //     '-' +
                                  //     (dt.getMonth() + 1) +
                                  //     '-' +
                                  //     (dt.getDate() < 10
                                  //       ? '0' + dt.getDate()
                                  //       : dt.getDate())
                                  //   }
                                  //   onBlur={() => setIsDOBOnFocus(false)}
                                  //   onFocus={() => setIsDOBOnFocus(true)}
                                  //   required
                                  // />
                                  null}
                                  {/* {pageType == undefined || pageType == 'customer' ? (
                            <input
                              type="date"
                              className="form-control"
                              id="a11y_dob"
                              name="Dob"
                              data-testid="dateOfBirth"
                              value={customerInformation?.dateOfBirth}
                              onChange={(e: any) => HandleOnChange(e)}
                              max={
                                dt.getFullYear() -
                                18 +
                                '-' +
                                (dt.getMonth() + 1) +
                                '-' +
                                (dt.getDate() < 10
                                  ? '0' + dt.getDate()
                                  : dt.getDate())
                              }
                              required
                            />
                          ) : null} */}
                                  {customerInformation?.dateOfBirth !==
                                    undefined &&
                                  customerInformation?.dateOfBirth == '' &&
                                  isrequired === true ? (
                                    <label className="mandatoryfield validation">
                                      Required
                                    </label>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                                <Grid item lg={3} xl={2} className="pt-2">
                                  <label
                                    htmlFor="a11y_ssn"
                                    className="RAC-CUSTOMER--MuiFormLabel-root-date"
                                  >
                                    Social Security# / ITIN#{'   '}{' '}
                                    <span className="mx-2">
                                      <React.Fragment>
                                        {pageType != 'customer'
                                          ? '***  **  '
                                          : null}
                                        {pageType != 'customer'
                                          ? customerInformation?.ssn3
                                          : null}
                                      </React.Fragment>
                                    </span>
                                  </label>
                                  {pageType == undefined ||
                                  pageType == 'customer' ? (
                                    <Grid container spacing={2}>
                                      <Grid item md={4}>
                                        <RACTextbox
                                          isCurrency={false}
                                          required={false}
                                          id="a11y_ssn"
                                          type={'password'}
                                          name={`Ssn1`}
                                          OnChange={(e: any) =>
                                            HandleOnChange1(e)
                                          }
                                          className="cust-text-aign"
                                          maxlength={3}
                                          value={customerInformation?.ssn1}
                                        />
                                        {/* <input
                                        type="password"
                                        maxLength={3}
                                        className="form-control ssn-3"
                                        id="a11y_ssn"
                                        data-testid="SSN1"
                                        name="Ssn1"
                                        value={customerInformation?.ssn1}
                                        style={{ borderColor: 'none' }}
                                        onChange={(e: any) => HandleOnChange1(e)}
                                        required
                                      /> */}
                                      </Grid>
                                      <Grid item md={3}>
                                        <RACTextbox
                                          isCurrency={false}
                                          required={false}
                                          type={'password'}
                                          id="a11y_ssntwo"
                                          name={`Ssn2`}
                                          OnChange={(e: any) =>
                                            HandleOnChange1(e)
                                          }
                                          className="cust-text-aign"
                                          maxlength={2}
                                          value={customerInformation?.ssn2}
                                        />
                                        {/* <input
                                        type="password"
                                        maxLength={2}
                                        className="form-control ssn-2"
                                        value={customerInformation?.ssn2}
                                        data-testid="SSN2"
                                        name="Ssn2"
                                        id="a11y_ssntwo"
                                        style={{ borderColor: 'none' }}
                                        onChange={(e: any) => HandleOnChange1(e)}
                                        required
                                      /> */}
                                      </Grid>
                                      <Grid item md={4}>
                                        <RACTextbox
                                          isCurrency={false}
                                          required={false}
                                          type={'text'}
                                          name={`Ssn3`}
                                          id="a11y_ssnthree"
                                          OnChange={(e: any) =>
                                            HandleOnChange1(e)
                                          }
                                          className="cust-text-aign"
                                          maxlength={4}
                                          value={customerInformation?.ssn3}
                                        />
                                        {/* <input
                                        type="text"
                                        maxLength={4}
                                        className="form-control ssn-3"
                                        value={customerInformation?.ssn3}
                                        data-testid="SSN3"
                                        name="Ssn3"
                                        id="a11y_ssnthree"
                                        style={{ borderColor: 'none' }}
                                        onChange={(e: any) => HandleOnChange1(e)}
                                        required
                                      /> */}
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </React.Fragment>
                            ) : null}
                          </Grid>
                          {legalHoldApplied !== 1 && isLegalHold !== true ? (
                            <Grid container spacing={2}>
                              <Grid item lg={3} xl={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'email'}
                                  inputlabel="Email Address"
                                  name={`Emailaddress`}
                                  OnChange={(e: any) => HandleOnChange2(e)}
                                  maxlength={50}
                                  value={customerInformation?.emailAddress}
                                  errorMessage={
                                    customerInformation?.emailAddress !==
                                      undefined &&
                                    (customerInformation?.emailAddress ==
                                      null ||
                                      customerInformation?.emailAddress ==
                                        '') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : isrequired === true &&
                                        ValidateEmail(
                                          customerInformation?.emailAddress
                                        ) === false
                                      ? 'Please enter valid email address'
                                      : ''
                                  }
                                />
                                {/* <label
                                htmlFor="emailaddress"
                                className="form-label"
                              >
                                Email Address
                                <span className="mandatoryfield">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="emailaddress"
                                data-testid="emailId"
                                maxLength={50}
                                name="Emailaddress"
                                value={customerInformation?.emailAddress}
                                onChange={(e: any) => HandleOnChange2(e)}
                                required
                              />
                              {customerInformation?.emailAddress !==
                                undefined &&
                              (customerInformation?.emailAddress == null ||
                                customerInformation?.emailAddress == '') &&
                              isrequired === true ? (
                                <label className="mandatoryfield validation">
                                  Required
                                </label>
                              ) : isrequired === true &&
                                ValidateEmail(
                                  customerInformation?.emailAddress
                                ) === false ? (
                                <label className="mandatoryfield validation">
                                  Please enter valid email address
                                </label>
                              ) : null} */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                {/* <label htmlFor="GovID" className="form-label">
                                Gov ID Type
                                <span className="mandatoryfield">*</span>
                              </label> */}
                                <RACSelect
                                  inputLabel="Gov ID Type"
                                  options={govTypeId}
                                  defaultValue={
                                    customerInformation?.governmentIdType !==
                                    undefined
                                      ? customerInformation?.governmentIdType
                                      : '0'
                                  }
                                  required={true}
                                  name="GovernIDtype"
                                  loading={
                                    govTypeId.length == 0 && !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'GovernIDtype';
                                    HandleOnChange2(e);
                                  }}
                                  errorMessage={
                                    (customerInformation?.governmentIdType ==
                                      undefined ||
                                      customerInformation?.governmentIdType ==
                                        '' ||
                                      customerInformation?.governmentIdType ==
                                        '0') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                  {...(govTypeId.length == 0 &&
                                    dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                />
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={
                                    customerInformation?.governmentIdType !==
                                      undefined &&
                                    customerInformation?.governmentIdType !==
                                      null &&
                                    customerInformation?.governmentIdType !==
                                      '0' &&
                                    customerInformation?.governmentIdType !==
                                      'NONE' &&
                                    customerInformation?.governmentIdType !==
                                      'PASS'
                                      ? true
                                      : false
                                  }
                                  type={'text'}
                                  inputlabel="Gov ID Number"
                                  name={`GovernIDnum`}
                                  OnChange={(e: any) => HandleOnChange2(e)}
                                  maxlength={30}
                                  onFocus={() => setisGovtNumOnFocus(true)}
                                  Onblur={() => setisGovtNumOnFocus(false)}
                                  value={
                                    customerInformation?.governmentId !==
                                      undefined &&
                                    customerInformation?.governmentId !==
                                      null &&
                                    customerInformation?.governmentId !== ''
                                      ? customerInformation?.governmentId
                                          .length > 4 &&
                                        isGovtNumOnFocus !== true
                                        ? customerInformation?.governmentId
                                            .substring(
                                              0,
                                              customerInformation?.governmentId
                                                .length - 4
                                            )
                                            .replace(/[A-Za-z0-9]/g, '*') +
                                          customerInformation?.governmentId.substring(
                                            customerInformation?.governmentId
                                              .length - 4
                                          )
                                        : customerInformation?.governmentId
                                      : ''
                                  }
                                  errorMessage={
                                    customerInformation?.governmentIdType !==
                                      undefined &&
                                    customerInformation?.governmentIdType !==
                                      null &&
                                    customerInformation?.governmentIdType !==
                                      '0' &&
                                    customerInformation?.governmentIdType !==
                                      'NONE' &&
                                    customerInformation?.governmentIdType !==
                                      'PASS' &&
                                    ((customerInformation?.governmentId ==
                                      undefined &&
                                      customerInformation?.governmentId ==
                                        null) ||
                                      customerInformation?.governmentId ==
                                        '') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                />
                                {/* <label htmlFor="GovNumber" className="form-label">
                                Gov ID Number{' '}
                                {customerInformation?.governmentIdType !==
                                  undefined &&
                                customerInformation?.governmentIdType !==
                                  null &&
                                customerInformation?.governmentIdType !== '0' &&
                                customerInformation?.governmentIdType !==
                                  'NONE' &&
                                customerInformation?.governmentIdType !==
                                  'PASS' ? (
                                  <span className="mandatoryfield">*</span>
                                ) : null}
                              </label> */}
                                {/* <input
                                type="text"
                                className="form-control"
                                id="GovNumber"
                                data-testid="govIdNumber"
                                maxLength={30}
                                name="GovernIDnum"
                                disabled={
                                  customerInformation?.governmentIdType ==
                                  'NONE'
                                    ? true
                                    : false
                                }
                                value={
                                  customerInformation?.governmentId !==
                                    undefined &&
                                  customerInformation?.governmentId !== null &&
                                  customerInformation?.governmentId !== ''
                                    ? customerInformation?.governmentId.length >
                                        4 && isGovtNumOnFocus !== true
                                      ? customerInformation?.governmentId
                                          .substring(
                                            0,
                                            customerInformation?.governmentId
                                              .length - 4
                                          )
                                          .replace(/[A-Za-z0-9]/g, '*') +
                                        customerInformation?.governmentId.substring(
                                          customerInformation?.governmentId
                                            .length - 4
                                        )
                                      : customerInformation?.governmentId
                                    : ''
                                }
                                onChange={(e: any) => HandleOnChange2(e)}
                                onFocus={() => setisGovtNumOnFocus(true)}
                                onBlur={() => setisGovtNumOnFocus(false)}
                              /> */}
                                {/* {customerInformation?.governmentIdType !==
                                undefined &&
                              customerInformation?.governmentIdType !== null &&
                              customerInformation?.governmentIdType !== '0' &&
                              customerInformation?.governmentIdType !==
                                'NONE' &&
                              customerInformation?.governmentIdType !==
                                'PASS' &&
                              ((customerInformation?.governmentId ==
                                undefined &&
                                customerInformation?.governmentId == null) ||
                                customerInformation?.governmentId == '') &&
                              isrequired === true ? (
                                <label className="mandatoryfield validation">
                                  Required
                                </label>
                              ) : (
                                ''
                              )} */}
                              </Grid>
                              <Grid item lg={3} xl={1}>
                                <RACSelect
                                  inputLabel="State"
                                  options={state}
                                  defaultValue={
                                    customerInformation?.governmentIdIssuedState !==
                                      undefined &&
                                    customerInformation?.governmentIdIssuedState !==
                                      null
                                      ? customerInformation?.governmentIdIssuedState
                                      : '0'
                                  }
                                  isDisabled={
                                    customerInformation?.governmentIdType ==
                                      'PASS' ||
                                    customerInformation?.governmentIdType ==
                                      'NONE'
                                      ? true
                                      : false
                                  }
                                  required={
                                    customerInformation?.governmentIdType !==
                                      undefined &&
                                    customerInformation?.governmentIdType !==
                                      null &&
                                    customerInformation?.governmentIdType !==
                                      '0' &&
                                    customerInformation?.governmentIdType !==
                                      'NONE' &&
                                    customerInformation?.governmentIdType !==
                                      'PASS'
                                      ? true
                                      : false
                                  }
                                  loading={
                                    state.length == 0 && !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'stateForBasicInfo';
                                    HandleOnChange3(e);
                                  }}
                                  errorMessage={
                                    customerInformation?.governmentIdType !==
                                      undefined &&
                                    customerInformation?.governmentIdType !==
                                      null &&
                                    customerInformation?.governmentIdType !==
                                      '0' &&
                                    customerInformation?.governmentIdType !==
                                      'NONE' &&
                                    customerInformation?.governmentIdType !==
                                      'PASS' &&
                                    ((customerInformation?.governmentIdIssuedState ==
                                      undefined &&
                                      customerInformation?.governmentIdIssuedState ==
                                        null) ||
                                      customerInformation?.governmentIdIssuedState ==
                                        '' ||
                                      customerInformation?.governmentIdIssuedState ==
                                        '0') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                  {...(state.length == 0 &&
                                    dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                />
                              </Grid>
                              <Grid item lg={3} xl={1}>
                                <RACSelect
                                  inputLabel="Salutation"
                                  options={salutation}
                                  defaultValue={
                                    customerInformation?.salutation !==
                                      undefined &&
                                    customerInformation?.salutation !== null
                                      ? customerInformation?.salutation
                                      : '0'
                                  }
                                  loading={
                                    salutation.length == 0 && !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'Salutation';
                                    HandleOnChange3(e);
                                  }}
                                  {...(salutation.length == 0 &&
                                    dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                />
                              </Grid>
                              <Grid item lg={3} xl={1}>
                                <RACTextbox
                                  isCurrency={false}
                                  type={'text'}
                                  inputlabel="Middle initial"
                                  name={`Middleinitial`}
                                  OnChange={(e: any) => HandleOnChange2(e)}
                                  maxlength={1}
                                  value={customerInformation?.middleInitial}
                                />
                                {/* <label
                                htmlFor="Middleinitial"
                                className="form-label"
                              >
                                Middle initial
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength={5}
                                id="Middleinitial"
                                data-testid="middleInitial"
                                name="Middleinitial"
                                value={customerInformation?.middleInitial}
                                onChange={(e: any) => HandleOnChange2(e)}
                                required
                              /> */}
                              </Grid>
                              <Grid item lg={3} xl={1}>
                                <Typography
                                  component="label"
                                  htmlFor="jrsr"
                                  className="RAC-CUSTOMER--MuiFormLabel-root-date w-100 floatLeft"
                                >
                                  Jr/Sr
                                </Typography>
                                <Grid
                                  item
                                  className="form-check form-check-inline ps-0 floatLeft"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value={'JR'}
                                    checked={
                                      customerInformation?.suffix == 'JR'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange3(e)}
                                    label="Jr"
                                    name="inlineRadioOptionsforjr"
                                  />
                                  {/* <input
                                  className="form-check-input "
                                  type="radio"
                                  data-testid="jrRadio"
                                  name="inlineRadioOptionsforjr"
                                  value="JR"
                                  id="inlineRadio1"
                                  checked={
                                    customerInformation?.suffix == 'JR'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange3(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineRadio1"
                                >
                                  Jr
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value={'SR'}
                                    checked={
                                      customerInformation?.suffix == 'SR'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange3(e)}
                                    label="Sr"
                                    name="inlineRadioOptionsforjr"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="radio"
                                  data-testid="srRadio"
                                  name="inlineRadioOptionsforjr"
                                  value="SR"
                                  id="inlineRadio2"
                                  checked={
                                    customerInformation?.suffix == 'SR'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange3(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineRadio2"
                                >
                                  Sr
                                </label> */}
                                </Grid>
                              </Grid>
                              <Grid item lg={3} xl={2} className="pt-0 mt-4">
                                <Grid
                                  item
                                  className="form-check form-switch switch-blue floatLeft ps-0 RAC-switch"
                                >
                                  <RACToggle
                                    style={{ marginLeft: 0 }}
                                    name={'RAC_Co_worker'}
                                    checked={
                                      customerInformation?.racCoworker == 'N'
                                        ? false
                                        : true
                                    }
                                    toggleColor="#0d6efd"
                                    OnChange={(e: any) => {
                                      HandleOnChange3(e);
                                    }}
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    customerInformation?.racCoworker == 'N'
                                      ? false
                                      : true
                                  }
                                  id="RAC-Co-worker"
                                  name="RAC_Co_worker"
                                  onChange={(e: any) => HandleOnChange3(e)}
                                  disabled={!isSecondAuthenValid}
                                /> */}
                                  <Typography
                                    component="label"
                                    className="form-check-label floatLeft mt-2"
                                    htmlFor="RAC-Co-worker"
                                  >
                                    RAC Co-worker
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : null}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  {/*Basic Info ends here*/}
                  {/* Address Info starts here */}
                  {legalHoldApplied !== 1 && isLegalHold !== true ? (
                    <Grid container className="px-1 mb-3">
                      <Grid item md={12}>
                        <Typography className="title">
                          {' '}
                          Address Information
                        </Typography>
                      </Grid>
                      <Grid item md={12} id="addressDiv">
                        <Card className="card w-100">
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid container className="pb-0 p-2">
                                <Grid item md={9}>
                                  <Typography className="title">
                                    Primary Address
                                  </Typography>
                                </Grid>
                                <Grid item md={3} className="text-center">
                                  <Grid className="form-check form-switch switch-blue RAC-switch floatLeft ps-0">
                                    <RACToggle
                                      style={{ marginLeft: 0 }}
                                      name="MailingAddres"
                                      checked={
                                        customerInformation?.mailAddress == 'Y'
                                          ? true
                                          : false
                                      }
                                      toggleColor="#0d6efd"
                                      OnChange={(e: any) => {
                                        HandleOnChange5(e);
                                      }}
                                    />
                                    <Typography
                                      component="label"
                                      className="form-check-label floatLeft mt-2"
                                      htmlFor="MailingAddress"
                                    >
                                      Mailing address same as primary?
                                    </Typography>

                                    {/* <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="MailingAddres"
                                      checked={
                                        customerInformation?.mailAddress == 'Y'
                                          ? true
                                          : false
                                      }
                                      onChange={(e: any) => HandleOnChange5(e)}
                                    /> */}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'text'}
                                  inputlabel="Address Line 1"
                                  name={`AddressLine1`}
                                  OnChange={(e: any) => HandleOnChange4(e)}
                                  maxlength={30}
                                  value={
                                    editInputBox === true
                                      ? customerInformation?.addressLine1
                                      : updatePrimaryAddress.address
                                  }
                                  disabled={editInputBox}
                                  errorMessage={
                                    (customerInformation?.addressLine1 ==
                                      undefined ||
                                      customerInformation?.addressLine1 ==
                                        null ||
                                      customerInformation?.addressLine1 ==
                                        '') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                />
                                {/* <label className="form-label">
                                Address Line 1
                                <span className="mandatoryfield">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name-context"
                                data-testid="addressLine1"
                                disabled={editInputBox}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.addressLine1
                                    : updatePrimaryAddress.address
                                }
                                name="AddressLine1"
                                maxLength={40}
                                onChange={(e: any) => HandleOnChange4(e)}
                              />
                              {(customerInformation?.addressLine1 ==
                                undefined ||
                                customerInformation?.addressLine1 == null ||
                                customerInformation?.addressLine1 == '') &&
                              isrequired === true ? (
                                <label className="mandatoryfield validation">
                                  Required
                                </label>
                              ) : (
                                ''
                              )} */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={false}
                                  type={'text'}
                                  inputlabel="Address Line 2"
                                  name={`AddressLine2`}
                                  OnChange={(e: any) => HandleOnChange4(e)}
                                  maxlength={30}
                                  value={
                                    editInputBox === true
                                      ? customerInformation?.addressLine2
                                      : updatePrimaryAddress.address1
                                  }
                                  disabled={editInputBox}
                                />
                                {/* <label className="form-label">
                                Address Line 2
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="Email"
                                data-testid="addressLine2"
                                disabled={editInputBox}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.addressLine2
                                    : updatePrimaryAddress.address1
                                }
                                maxLength={40}
                                name="AddressLine2"
                                onChange={(e: any) => HandleOnChange4(e)}
                              /> */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'text'}
                                  inputlabel="Zip"
                                  name={`Zip`}
                                  OnChange={(e: any) => HandleOnChange5(e)}
                                  maxlength={10}
                                  value={
                                    editInputBox === true
                                      ? customerInformation?.zip
                                      : updatePrimaryAddress.zip
                                  }
                                  disabled={editInputBox}
                                  errorMessage={
                                    (customerInformation?.zip == undefined ||
                                      customerInformation?.zip == null ||
                                      customerInformation?.zip == '') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : customerInformation?.zip.length !== 5 &&
                                        customerInformation?.zip.length !==
                                          10 &&
                                        isrequired === true
                                      ? INVALID_ZIP
                                      : ''
                                  }
                                />
                                {/* <label className="form-label">
                                Zip<span className="mandatoryfield">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="Apt#3"
                                data-testid="zip"
                                disabled={editInputBox}
                                value={
                                  editInputBox === true
                                    ? customerInformation?.zip
                                    : updatePrimaryAddress.zip
                                }
                                name="Zip"
                                maxLength={10}
                                onChange={(e: any) => HandleOnChange5(e)}
                              />
                              {(customerInformation?.zip == undefined ||
                                customerInformation?.zip == null ||
                                customerInformation?.zip == '') &&
                              isrequired === true ? (
                                <label className="mandatoryfield validation">
                                  Required
                                </label>
                              ) : customerInformation?.zip.length !== 5 &&
                                customerInformation?.zip.length !== 10 &&
                                isrequired === true ? (
                                <label className="mandatoryfield validation">
                                  Invalid Zip
                                </label>
                              ) : null} */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'text'}
                                  inputlabel="City"
                                  name={`City`}
                                  OnChange={(e: any) => HandleOnChange5(e)}
                                  maxlength={30}
                                  value={
                                    editInputBox === true
                                      ? customerInformation?.city
                                      : updatePrimaryAddress.city
                                  }
                                  disabled={editInputBox}
                                  errorMessage={
                                    (customerInformation?.city == undefined ||
                                      customerInformation?.city == null ||
                                      customerInformation?.city == '') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                />
                                {/* <label className="form-label">
                                City<span className="mandatoryfield">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                disabled={editInputBox}
                                data-testid="city"
                                id="Apt#2"
                                value={
                                  editInputBox === true
                                    ? customerInformation?.city
                                    : updatePrimaryAddress.city
                                }
                                maxLength={30}
                                name="City"
                                onChange={(e: any) => HandleOnChange5(e)}
                              />
                              {(customerInformation?.city == undefined ||
                                customerInformation?.city == null ||
                                customerInformation?.city == '') &&
                              isrequired === true ? (
                                <label className="mandatoryfield validation">
                                  Required
                                </label>
                              ) : (
                                ''
                              )} */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACSelect
                                  inputLabel="State"
                                  options={state}
                                  defaultValue={
                                    editInputBox === true
                                      ? customerInformation?.state !== undefined
                                        ? customerInformation?.state
                                        : '0'
                                      : updatePrimaryAddress.state
                                  }
                                  isDisabled={editInputBox}
                                  required={true}
                                  loading={
                                    state.length == 0 && !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'AddressInfoState';
                                    HandleOnChange5(e);
                                  }}
                                  errorMessage={
                                    (customerInformation?.state == undefined ||
                                      customerInformation?.state == '' ||
                                      customerInformation?.state == '0') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                  {...(state.length == 0 &&
                                    dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                />
                              </Grid>
                              {editInputBox ? (
                                <Grid item lg={3} xl={2} className="mt-0">
                                  {/* <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    data-testid="editAddressBtn"
                                    onClick={Editclick}
                                  >
                                    Edit Address
                                  </button> */}
                                  <RACButton
                                    className="btn btn-primary"
                                    onClick={Editclick}
                                  >
                                    Edit Address
                                  </RACButton>
                                </Grid>
                              ) : (
                                <Grid item lg={3} xl={2} className="mt-0">
                                  {/* <button
                                    type="button"
                                    className="btn btn-secondary mx-1 cancel-address"
                                    data-bs-dismiss="modal"
                                    data-testid="cancelBtn"
                                    hidden={editInputBox}
                                    onClick={Cancelbutt}
                                  >
                                    Cancel
                                  </button> */}
                                  {!editInputBox && (
                                    <>
                                      <RACButton
                                        className="btn btn-secondary me-2 mt-4"
                                        onClick={Cancelbutt}
                                      >
                                        Cancel
                                      </RACButton>
                                      <RACButton
                                        className="btn btn-primary mt-4"
                                        onClick={updateAddressInfo}
                                      >
                                        Update
                                      </RACButton>
                                    </>
                                  )}
                                </Grid>
                              )}

                              {customerInformation?.mailAddress == 'N' ? (
                                <Grid container spacing={2} className="ps-2">
                                  <Grid item md={12} className="mt-3">
                                    <Typography className="title">
                                      Mailing Address
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} xl={2}>
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      id="MailingAddressField"
                                      inputlabel="Address Line 1"
                                      name={`AddressLine1M`}
                                      OnChange={(e: any) => HandleOnChange41(e)}
                                      maxlength={30}
                                      value={
                                        editInputBoxM === true
                                          ? customerInformation?.addressLine1M !==
                                              undefined &&
                                            customerInformation?.addressLine1M !==
                                              ''
                                            ? customerInformation?.addressLine
                                            : updateMailingAddress.address
                                          : updateMailingAddress.address
                                      }
                                      disabled={editInputBoxM}
                                      errorMessage={
                                        (customerInformation?.addressLine1M ==
                                          undefined ||
                                          customerInformation?.addressLine1M ==
                                            null ||
                                          customerInformation?.addressLine1M ==
                                            '') &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                      }
                                    />
                                    {/* <label className="form-label">
                                  Address Line 1
                                  <span className="mandatoryfield">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name-context"
                                  data-testid="addressLine1"
                                  disabled={editInputBoxM}
                                  value={
                                    editInputBoxM === true
                                      ? customerInformation?.addressLine1M
                                      : updateMailingAddress.address
                                  }
                                  name="AddressLine1M"
                                  maxLength={40}
                                  onChange={(e: any) => HandleOnChange41(e)}
                                />
                                {(customerInformation?.addressLine1M ==
                                  undefined ||
                                  customerInformation?.addressLine1M == null ||
                                  customerInformation?.addressLine1M == '') &&
                                isrequired === true ? (
                                  <label className="mandatoryfield validation">
                                    Required
                                  </label>
                                ) : (
                                  ''
                                )} */}
                                  </Grid>
                                  <Grid item lg={3} xl={2}>
                                    <RACTextbox
                                      isCurrency={false}
                                      required={false}
                                      type={'text'}
                                      inputlabel="Address Line 2"
                                      name={`AddressLine2M`}
                                      OnChange={(e: any) => HandleOnChange41(e)}
                                      maxlength={30}
                                      value={
                                        editInputBoxM === true
                                          ? customerInformation?.addressLine2M !==
                                              undefined &&
                                            customerInformation?.addressLine2M !==
                                              ''
                                            ? customerInformation?.addressLine2M
                                            : updateMailingAddress.address1
                                          : updateMailingAddress.address1
                                      }
                                      disabled={editInputBoxM}
                                    />
                                    {/* <label className="form-label">
                                  Address Line 2
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Email"
                                  data-testid="addressLine2"
                                  disabled={editInputBoxM}
                                  value={
                                    editInputBoxM === true
                                      ? customerInformation?.addressLine2M
                                      : updateMailingAddress.address1
                                  }
                                  name="AddressLine2M"
                                  maxLength={40}
                                  onChange={(e: any) => HandleOnChange41(e)}
                                /> */}
                                  </Grid>
                                  <Grid item lg={3} xl={2}>
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      inputlabel="Zip"
                                      name={`ZipM`}
                                      OnChange={(e: any) => HandleOnChange51(e)}
                                      maxlength={10}
                                      value={
                                        editInputBoxM === true
                                          ? customerInformation?.zipM !==
                                              undefined &&
                                            customerInformation?.zipM !== ''
                                            ? customerInformation?.zipM
                                            : updateMailingAddress.zip
                                          : updateMailingAddress.zip
                                      }
                                      disabled={editInputBoxM}
                                      errorMessage={
                                        (customerInformation?.zipM ==
                                          undefined ||
                                          customerInformation?.zipM == null ||
                                          customerInformation?.zipM == '') &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : customerInformation?.zipM.length !==
                                              5 &&
                                            customerInformation?.zipM.length !==
                                              10 &&
                                            isrequired === true
                                          ? INVALID_ZIP
                                          : ''
                                      }
                                    />
                                    {/* <label className="form-label">
                                  Zip<span className="mandatoryfield">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="Apt#3"
                                  data-testid="zipM"
                                  disabled={editInputBoxM}
                                  value={
                                    editInputBoxM === true
                                      ? customerInformation?.zipM
                                      : updateMailingAddress.zip
                                  }
                                  name="ZipM"
                                  maxLength={10}
                                  onChange={(e: any) => HandleOnChange51(e)}
                                />

                                {(customerInformation?.zipM == undefined ||
                                  customerInformation?.zipM == null ||
                                  customerInformation?.zipM == '') &&
                                isrequired === true ? (
                                  <label className="mandatoryfield validation">
                                    Required
                                  </label>
                                ) : customerInformation?.zipM.length !== 5 &&
                                  customerInformation?.zipM.length !== 10 &&
                                  isrequired === true ? (
                                  <label className="mandatoryfield validation">
                                    Invalid Zip
                                  </label>
                                ) : null} */}
                                  </Grid>
                                  <Grid item lg={3} xl={2}>
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      inputlabel="City"
                                      name={`CityM`}
                                      OnChange={(e: any) => HandleOnChange51(e)}
                                      maxlength={30}
                                      value={
                                        editInputBoxM === true
                                          ? customerInformation?.cityM !==
                                              undefined &&
                                            customerInformation?.cityM !== ''
                                            ? customerInformation?.cityM
                                            : updateMailingAddress.city
                                          : updateMailingAddress.city
                                      }
                                      disabled={editInputBoxM}
                                      errorMessage={
                                        (customerInformation?.cityM !==
                                          undefined ||
                                          customerInformation?.cityM == null ||
                                          customerInformation?.cityM == '') &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                      }
                                    />
                                    {/* <label className="form-label">
                                  City<span className="mandatoryfield">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled={editInputBoxM}
                                  data-testid="cityM"
                                  id="Apt#2"
                                  value={
                                    editInputBoxM === true
                                      ? customerInformation?.cityM
                                      : updateMailingAddress.city
                                  }
                                  name="CityM"
                                  maxLength={30}
                                  onChange={(e: any) => HandleOnChange51(e)}
                                />
                                {(customerInformation?.cityM !== undefined ||
                                  customerInformation?.cityM == null ||
                                  customerInformation?.cityM == '') &&
                                isrequired === true ? (
                                  <label className="mandatoryfield validation">
                                    Required
                                  </label>
                                ) : (
                                  ''
                                )} */}
                                  </Grid>
                                  <Grid item lg={3} xl={2}>
                                    <RACSelect
                                      inputLabel="State"
                                      options={state}
                                      defaultValue={
                                        editInputBoxM === true
                                          ? customerInformation?.stateM !==
                                            undefined
                                            ? customerInformation?.stateM !== ''
                                              ? customerInformation?.stateM
                                              : updateMailingAddress.state
                                            : '0'
                                          : updateMailingAddress.state
                                      }
                                      isDisabled={editInputBoxM}
                                      required={true}
                                      loading={
                                        state.length == 0 && !dropDownLoaded
                                          ? true
                                          : false
                                      }
                                      onChange={(e: any) => {
                                        e.target.name = 'AddressInfoStateM';
                                        HandleOnChange51(e);
                                      }}
                                      errorMessage={
                                        (customerInformation?.stateM !==
                                          undefined ||
                                          customerInformation?.stateM == '' ||
                                          customerInformation?.stateM == '0') &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                      }
                                      {...(state.length == 0 &&
                                        dropDownLoaded && {
                                          errorMessage: API_ERROR_MESSAGE,
                                        })}
                                    />
                                  </Grid>
                                  {editInputBoxM ? (
                                    <Grid item lg={3} xl={2} className="mt-1">
                                      {/* <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        data-testid="editAddressBtn"
                                        onClick={EditclickM}
                                      >
                                        Edit Address
                                      </button> */}
                                      <RACButton
                                        className="btn btn-primary"
                                        onClick={EditclickM}
                                      >
                                        Edit Address
                                      </RACButton>
                                    </Grid>
                                  ) : (
                                    <Grid item lg={3} xl={2}>
                                      {!editInputBoxM && (
                                        <>
                                          <RACButton
                                            type="button"
                                            className="btn btn-secondary me-2"
                                            data-testid="cancelBtn"
                                            onClick={CancelbuttM}
                                          >
                                            Cancel
                                          </RACButton>
                                          <RACButton
                                            className="btn btn-primary"
                                            onClick={updateAddressInfoM}
                                          >
                                            Update
                                          </RACButton>
                                        </>
                                      )}
                                    </Grid>
                                  )}
                                </Grid>
                              ) : null}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : null}
                  {/* Address Info ends here */}

                  {/*Contact Info starts here*/}
                  {legalHoldApplied !== 1 && isLegalHold !== true ? (
                    <React.Fragment>
                      <Grid
                        container
                        className="px-2"
                        id="ParentContactInformation"
                      >
                        <Typography className="title">
                          {' '}
                          Contact Information
                        </Typography>
                        {buildPhone()}
                      </Grid>
                      <Grid item md={12} className="mt-5 mb-3">
                        <a
                          className="racpad-link px-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => addanotherContact()}
                        >
                          + Add another Contact
                        </a>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {/*Contact Info ends here*/}
                  {legalHoldApplied !== 1 && isLegalHold !== true ? (
                    <Grid container className="px-2 mb-2">
                      <Grid container>
                        <Typography className="title">
                          Communication Preferences
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Card className="card w-100 p-5">
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item lg={3} xl={2}>
                                <Typography
                                  component="label"
                                  htmlFor="#"
                                  className="form-label w-100 floatLeft RAC-CUSTOMER--MuiFormLabel-root-date"
                                >
                                  Payment Reminder Call
                                </Typography>
                                <Grid
                                  item
                                  className="form-check form-check-inline ps-0"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value={'Y'}
                                    checked={
                                      customerInformation?.paymentRemainderCall ==
                                      'Y'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange7(e)}
                                    label="Opt In"
                                    name="PaymentReminderCall"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="radio"
                                  name="PaymentReminderCall"
                                  checked={
                                    customerInformation?.paymentRemainderCall ==
                                    'Y'
                                      ? true
                                      : false
                                  }
                                  value="Y"
                                  id="optin"
                                  onChange={(e: any) => HandleOnChange7(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="optin"
                                >
                                  Opt In
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value={'N'}
                                    checked={
                                      customerInformation?.paymentRemainderCall ==
                                      'N'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange7(e)}
                                    label="Opt Out"
                                    name="PaymentReminderCall"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="radio"
                                  name="PaymentReminderCall"
                                  checked={
                                    customerInformation?.paymentRemainderCall ==
                                    'N'
                                      ? true
                                      : false
                                  }
                                  value="N"
                                  id="OptOut"
                                  onChange={(e: any) => HandleOnChange7(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="OptOut"
                                >
                                  Opt Out
                                </label> */}
                                </Grid>
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <Typography
                                  component="label"
                                  htmlFor="#"
                                  className="form-label w-100 floatLeft RAC-CUSTOMER--MuiFormLabel-root-date"
                                >
                                  Text Message Reminder
                                </Typography>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft ps-0"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value={'Y'}
                                    checked={
                                      customerInformation?.textMessageRemainder ==
                                      'Y'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange7(e)}
                                    label="Opt In"
                                    name="TextMessageReminder"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="radio"
                                  name="TextMessageReminder"
                                  checked={
                                    customerInformation?.textMessageRemainder ==
                                    'Y'
                                      ? true
                                      : false
                                  }
                                  value="Y"
                                  id="txtoptin"
                                  onChange={(e: any) => HandleOnChange7(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="txtoptin"
                                >
                                  Opt In
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value={'N'}
                                    checked={
                                      customerInformation?.textMessageRemainder ==
                                      'N'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange7(e)}
                                    label="Opt Out"
                                    name="TextMessageReminder"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="radio"
                                  name="TextMessageReminder"
                                  checked={
                                    customerInformation?.textMessageRemainder ==
                                    'N'
                                      ? true
                                      : false
                                  }
                                  value="N"
                                  id="txtOptOut"
                                  onChange={(e: any) => HandleOnChange7(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="txtOptOut"
                                >
                                  Opt Out
                                </label> */}
                                </Grid>
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACSelect
                                  inputLabel="Preferred Method of Contact "
                                  options={preferredMethod}
                                  defaultValue={
                                    customerInformation?.preferredMethod !==
                                    undefined
                                      ? customerInformation?.preferredMethod
                                      : '0'
                                  }
                                  required={true}
                                  loading={
                                    preferredMethod.length == 0 &&
                                    !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'PreferredContactMethod';
                                    HandleOnChange8(e);
                                  }}
                                  {...(preferredMethod.length == 0 &&
                                    dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                />
                                {/* <label
                                htmlFor="PreferredContact"
                                className="form-label"
                              >
                                Preferred Method of Contact
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                id="PreferredContact"
                                data-testid="PreferredContact"
                                value={
                                  customerInformation?.preferredMethod !==
                                  undefined
                                    ? customerInformation?.preferredMethod
                                    : '0'
                                }
                                name="PreferredContactMethod"
                                onChange={(e: any) => HandleOnChange8(e)}
                              >
                                <option value="0">Select</option>
                                {dynamicDropdown(preferredMethod)}
                              </select> */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <Typography
                                  component="label"
                                  htmlFor="PaymentReceipt"
                                  className="form-label w-100 floatLeft RAC-CUSTOMER--MuiFormLabel-root-date"
                                >
                                  Preferred Payment Receipt
                                </Typography>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft ps-0"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value="PREM"
                                    checked={
                                      customerInformation?.preferredPaymentReceipt ==
                                      'PREM'
                                        ? true
                                        : false
                                    }
                                    onClick={(e: any) => HandleOnChange8(e)}
                                    label="Email"
                                    name="PreferredPaymentReceipt"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="PreferredPaymentReceipt"
                                  type="radio"
                                  value="PREM"
                                  checked={
                                    customerInformation?.preferredPaymentReceipt ==
                                    'PREM'
                                      ? true
                                      : false
                                  }
                                  id="inlineCheckbox1"
                                  onChange={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineCheckbox1"
                                >
                                  Email
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value="EM"
                                    checked={
                                      customerInformation?.preferredPaymentReceipt ==
                                      'EM'
                                        ? true
                                        : false
                                    }
                                    onClick={(e: any) => HandleOnChange8(e)}
                                    label="Text"
                                    name="PreferredPaymentReceipt"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="PreferredPaymentReceipt"
                                  type="radio"
                                  value="EM"
                                  checked={
                                    customerInformation?.preferredPaymentReceipt ==
                                    'EM'
                                      ? true
                                      : false
                                  }
                                  id="inlineCheckbox2"
                                  onChange={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineCheckbox2"
                                >
                                  Text
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value="PRNT"
                                    checked={
                                      customerInformation?.preferredPaymentReceipt ==
                                      'PRNT'
                                        ? true
                                        : false
                                    }
                                    onClick={(e: any) => HandleOnChange8(e)}
                                    label="Print"
                                    name="PreferredPaymentReceipt"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="PreferredPaymentReceipt"
                                  type="radio"
                                  value="PRNT"
                                  id="inlineCheckboxPrint"
                                  checked={
                                    customerInformation?.preferredPaymentReceipt ==
                                    'PRNT'
                                      ? true
                                      : false
                                  }
                                  onClick={(e: any) => HandleOnChange8(e)}
                                  // onChange={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineCheckboxPrint"
                                >
                                  Print
                                </label> */}
                                </Grid>
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <Typography
                                  component="label"
                                  htmlFor="#"
                                  className="form-label w-100 floatLeft RAC-CUSTOMER--MuiFormLabel-root-date"
                                >
                                  Preferred Language
                                </Typography>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft ps-0"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value="ENG"
                                    checked={
                                      customerInformation?.preferredLanguage ==
                                      'ENG'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange8(e)}
                                    label="English"
                                    name="PreferredLanguage"
                                  />

                                  {/* <input
                                  className="form-check-input"
                                  name="PreferredLanguage"
                                  type="radio"
                                  value="ENG"
                                  checked={
                                    customerInformation?.preferredLanguage ==
                                      'ENG'
                                      ? true
                                      : false
                                  }
                                  id="txtoptinEng"
                                  onClick={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="txtoptinEng"
                                >
                                  English
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft"
                                >
                                  <RACRadio
                                    className="cust-radioBox"
                                    value="SPA"
                                    checked={
                                      customerInformation?.preferredLanguage ==
                                      'SPA'
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => HandleOnChange8(e)}
                                    label="Spanish"
                                    name="PreferredLanguage"
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="PreferredLanguage"
                                  type="radio"
                                  value="SPA"
                                  checked={
                                    customerInformation?.preferredLanguage ==
                                      'SPA'
                                      ? true
                                      : false
                                  }
                                  id="txtOptOutSpanish"
                                  onClick={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="txtOptOutSpanish"
                                >
                                  Spanish
                                </label> */}
                                </Grid>
                                {/* <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="PreferredLanguage"
                                  id="txtoptinEng"
                                  value="ENG"
                                  checked={
                                    customerInformation?.preferredLanguage ==
                                    'ENG'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange9(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="txtoptinEng"
                                >
                                  English
                                </label>
                              </div> */}
                                {/* <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="PreferredLanguage"
                                  id="txtOptOutSpanish"
                                  value="SPA"
                                  checked={
                                    customerInformation?.preferredLanguage !==
                                    'ENG'
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => HandleOnChange9(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="txtOptOutSpanish"
                                >
                                  Spanish
                                </label>
                              </div> */}
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <Typography
                                  component="label"
                                  htmlFor="PaymentReceipt"
                                  className="form-label w-100 floatLeft RAC-CUSTOMER--MuiFormLabel-root-date"
                                >
                                  Accept Solicitation
                                </Typography>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft ps-0"
                                >
                                  <RACCheckBox
                                    label="Phone"
                                    size="small"
                                    className="cust-checkbox"
                                    name={'AcceptSolicitation'}
                                    onChange={(e: any) => {
                                      HandleOnChange8(e);
                                    }}
                                    value={
                                      customerInformation?.phoneSolicitationAllowed ==
                                      'Y'
                                        ? 'N'
                                        : 'Y'
                                    }
                                    checked={
                                      customerInformation?.phoneSolicitationAllowed ==
                                      'Y'
                                        ? true
                                        : false
                                    }
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="AcceptSolicitation"
                                  type="checkbox"
                                  checked={
                                    customerInformation?.phoneSolicitationAllowed ==
                                    'Y'
                                      ? true
                                      : false
                                  }
                                  value={
                                    customerInformation?.phoneSolicitationAllowed ==
                                    'Y'
                                      ? 'N'
                                      : 'Y'
                                  }
                                  id="inlineCheckbox1Phone"
                                  onChange={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineCheckbox1Phone"
                                >
                                  Phone
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft "
                                >
                                  <RACCheckBox
                                    label="Mail"
                                    size="small"
                                    className="cust-checkbox"
                                    name={'AcceptSolicitation1'}
                                    onChange={(e: any) => {
                                      HandleOnChange8(e);
                                    }}
                                    value={
                                      customerInformation?.mailSolicitationAllowed ==
                                      'Y'
                                        ? 'N'
                                        : 'Y'
                                    }
                                    checked={
                                      customerInformation?.mailSolicitationAllowed ==
                                      'Y'
                                        ? true
                                        : false
                                    }
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="AcceptSolicitation1"
                                  type="checkbox"
                                  value={
                                    customerInformation?.mailSolicitationAllowed ==
                                    'Y'
                                      ? 'N'
                                      : 'Y'
                                  }
                                  checked={
                                    customerInformation?.mailSolicitationAllowed ==
                                    'Y'
                                      ? true
                                      : false
                                  }
                                  id="inlineCheckbox2Mail"
                                  onChange={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineCheckbox2Mail"
                                >
                                  Mail
                                </label> */}
                                </Grid>
                                <Grid
                                  item
                                  className="form-check form-check-inline floatLeft"
                                >
                                  <RACCheckBox
                                    label="Email"
                                    size="small"
                                    className="cust-checkbox"
                                    name={'AcceptSolicitation2'}
                                    onChange={(e: any) => {
                                      HandleOnChange8(e);
                                    }}
                                    value={
                                      customerInformation?.emailSolicitationAllowed ==
                                      'Y'
                                        ? 'N'
                                        : 'Y'
                                    }
                                    checked={
                                      customerInformation?.emailSolicitationAllowed ==
                                      'Y'
                                        ? true
                                        : false
                                    }
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  name="AcceptSolicitation2"
                                  type="checkbox"
                                  value={
                                    customerInformation?.emailSolicitationAllowed ==
                                    'Y'
                                      ? 'N'
                                      : 'Y'
                                  }
                                  checked={
                                    customerInformation?.emailSolicitationAllowed ==
                                    'Y'
                                      ? true
                                      : false
                                  }
                                  id="inlineCheckbox2Email"
                                  onChange={(e: any) => HandleOnChange8(e)}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="inlineCheckbox2Email"
                                >
                                  Email
                                </label> */}
                                </Grid>
                              </Grid>
                              <Grid item lg={3} xl={2}>
                                <RACSelect
                                  inputLabel="Route"
                                  options={addressInfoRoute}
                                  defaultValue={
                                    customerInformation?.route !== undefined
                                      ? customerInformation?.route
                                      : '0'
                                  }
                                  loading={
                                    addressInfoRoute.length == 0 &&
                                    !dropDownLoaded
                                      ? true
                                      : false
                                  }
                                  onChange={(e: any) => {
                                    e.target.name = 'PreferredRoute';
                                    HandleOnChange9(e);
                                  }}
                                  {...(addressInfoRoute.length == 0 &&
                                    dropDownLoaded && {
                                      errorMessage: API_ERROR_MESSAGE,
                                    })}
                                />
                                {/* <label
                                htmlFor="PreferredContact"
                                className="form-label"
                              >
                                Route
                              </label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                data-testid="route"
                                name="PreferredRoute"
                                id="PreferredContact"
                                value={
                                  customerInformation?.route !== undefined
                                    ? customerInformation?.route
                                    : '0'
                                }
                                onChange={(e: any) => HandleOnChange9(e)}
                                disabled={!isSecondAuthenValid}
                              >
                                <option value="0">Select</option>
                                {dynamicDropdown(addressInfoRoute)}
                              </select> */}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : null}
                  {/*Legal starts here*/}
                  {pageType == undefined || pageType == 'customer' ? (
                    <Grid container className="px-1 mb-3">
                      <Grid item md={12}>
                        <Typography className="title">Legal</Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Card className="card w-100">
                          <CardContent className="pb-3">
                            {/**<label className="ReasonLabel" hidden>
                         Reason
             </label>**/}
                            <Grid container spacing={2}>
                              <Grid item className="widthauto pt-1">
                                <RACCheckBox
                                  label="Legal Hold"
                                  size="small"
                                  className="cust-checkbox"
                                  value={
                                    isLegalHold === true ? 'true' : 'false'
                                  }
                                  checked={isLegalHold}
                                />
                                {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="legalhold"
                                  value={
                                    isLegalHold === true ? 'true' : 'false'
                                  }
                                  checked={isLegalHold}
                                  disabled
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="legalhold"
                                >
                                  Legal Hold
                                </label> */}
                              </Grid>
                              <Grid item className="widthauto pt-1">
                                <Grid item className="form-check">
                                  <RACCheckBox
                                    label="Skip"
                                    size="small"
                                    className="cust-checkbox"
                                    name={'SkipCustomer'}
                                    value={
                                      customerInformation?.skip == 'Y'
                                        ? 'N'
                                        : 'Y'
                                    }
                                    checked={
                                      customerInformation?.skip == 'Y'
                                        ? true
                                        : false
                                    }
                                    onChange={() => handleOnchangeSkipHard('S')}
                                    disabled={isLegalHold}
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="skip"
                                  value={
                                    customerInformation?.skip == 'Y' ? 'N' : 'Y'
                                  }
                                  checked={
                                    customerInformation?.skip == 'Y'
                                      ? true
                                      : false
                                  }
                                  disabled={isLegalHold}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="skip"
                                >
                                  Skip
                                </label> */}
                                </Grid>
                              </Grid>
                              <Grid item className="widthauto pt-1">
                                <Grid className="form-check">
                                  <RACCheckBox
                                    label="Hard"
                                    size="small"
                                    className="cust-checkbox"
                                    name="HardCustomer"
                                    value={
                                      customerInformation?.hard == 'Y'
                                        ? 'N'
                                        : 'Y'
                                    }
                                    checked={
                                      customerInformation?.hard == 'Y'
                                        ? true
                                        : false
                                    }
                                    onChange={() => handleOnchangeSkipHard('H')}
                                    disabled={isLegalHold}
                                  />
                                  {/* <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="hard"
                                  value={
                                    customerInformation?.hard == 'Y' ? 'N' : 'Y'
                                  }
                                  checked={
                                    customerInformation?.hard == 'Y'
                                      ? true
                                      : false
                                  }
                                  disabled={isLegalHold}
                                />
                                <label
                                  className="form-check-label radio-margin"
                                  htmlFor="hard"
                                >
                                  Hard
                                </label> */}
                                </Grid>
                              </Grid>
                              {LegalHold?.legalHoldStatus !== undefined &&
                              LegalHold?.legalHoldStatus !== null &&
                              (LegalHold?.legalHoldStatus === true ||
                                LegalHold?.legalHoldStatus === 'true') ? (
                                <Grid item md={4} lg={2}>
                                  <RACSelect
                                    inputLabel="Legal Hold Reason"
                                    options={LegalOption}
                                    defaultValue={
                                      LegalHold?.legalHoldStatus !==
                                        undefined &&
                                      LegalHold?.legalHoldStatus !== null
                                        ? LegalHold?.refCode
                                        : '0'
                                    }
                                    loading={
                                      LegalOption.length == 0 && !dropDownLoaded
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      e.target.name = 'PreferredRoute';
                                      HandleOnChange9(e);
                                    }}
                                    {...(LegalOption.length == 0 &&
                                      dropDownLoaded && {
                                        errorMessage: API_ERROR_MESSAGE,
                                      })}
                                  />
                                  {/* <label
                                  htmlFor="PreferredContact"
                                  className="form-label"
                                >
                                  Legal Hold Reason
                                </label>
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  data-testid="route"
                                  name="PreferredRoute"
                                  id="PreferredContact"
                                  disabled={true}
                                  value={
                                    LegalHold?.legalHoldStatus !== undefined &&
                                    LegalHold?.legalHoldStatus !== null
                                      ? LegalHold?.refCode
                                      : '0'
                                  }
                                  onChange={(e: any) => HandleOnChange9(e)}
                                >
                                  <option value="0">Select</option>
                                  {dynamicDropdown(LegalOption)}
                                </select> */}
                                </Grid>
                              ) : null}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  ) : null}
                  {/*Legal ends here*/}
                  {/*Tax info starts here*/}
                  {legalHoldApplied !== 1 && isLegalHold !== true ? (
                    <Grid container className="px-1 mb-3">
                      <Grid md={12}>
                        <Grid
                          item
                          className="form-check form-switch switch-blue floatLeft ps-0  RAC-switch"
                        >
                          <Typography
                            component="label"
                            className="form-check-label title floatLeft mt-1"
                            htmlFor="taxexempt_toggle"
                          >
                            Tax Exempt
                          </Typography>
                          <RACToggle
                            className="floatLeft"
                            style={{ marginLeft: 0 }}
                            id={'TaxExempt'}
                            checked={taxexempt_toggle}
                            toggleColor="#0d6efd"
                            OnChange={(e: any) => {
                              Taxidclick();
                              HandleOnChange(e);
                            }}
                          />

                          {/* <input
                          className="form-check-input"
                          type="checkbox"
                          id="TaxExempt"
                          checked={taxexempt_toggle}
                          onChange={(e: any) => {
                            Taxidclick();
                            HandleOnChange(e);
                          }}
                        /> */}
                        </Grid>
                      </Grid>
                      {TaxIDActive === true ? (
                        <Card className="card w-100">
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item md={3} lg={2}>
                                <RACTextbox
                                  isCurrency={false}
                                  required={true}
                                  type={'text'}
                                  inputlabel="Tax ID"
                                  name={`tax_id`}
                                  OnChange={(e: any) => HandleOnChange6(e)}
                                  maxlength={30}
                                  value={customerInformation?.federalTaxId}
                                  disabled={Istextboxdisable}
                                  errorMessage={
                                    customerInformation?.federalTaxId !==
                                      undefined &&
                                    (customerInformation?.federalTaxId ==
                                      null ||
                                      customerInformation?.federalTaxId ==
                                        '') &&
                                    isrequired === true
                                      ? FIELD_ERROR
                                      : ''
                                  }
                                />
                                {/* <label htmlFor="TAXID" className="form-label">
                                  Tax ID
                                  <span className="mandatoryfield">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="TAXID"
                                  data-testid="taxId"
                                  name="tax_id"
                                  disabled={Istextboxdisable}
                                  value={customerInformation?.federalTaxId}
                                  onChange={(e: any) => {
                                    HandleOnChange6(e);
                                  }}
                                />
                                {customerInformation?.federalTaxId !==
                                  undefined &&
                                (customerInformation?.federalTaxId == null ||
                                  customerInformation?.federalTaxId == '') &&
                                isrequired === true ? (
                                  <label className="mandatoryfield validation">
                                    Required
                                  </label>
                                ) : (
                                  ''
                                )} */}
                              </Grid>
                              {pageType == undefined ||
                              pageType == 'customer' ? (
                                <Grid item md={3} lg={2} className="mb-3">
                                  <RACSelect
                                    inputLabel="Status"
                                    options={taxExemptStatus}
                                    isDisabled={true}
                                    defaultValue={
                                      customerInformation?.status !== undefined
                                        ? customerInformation?.status
                                        : '0'
                                    }
                                    loading={
                                      taxExemptStatus.length == 0 &&
                                      !dropDownLoaded
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      e.target.name = `tax_status`;
                                      HandleOnChange6(e);
                                    }}
                                    {...(taxExemptStatus.length == 0 &&
                                      dropDownLoaded && {
                                        errorMessage: API_ERROR_MESSAGE,
                                      })}
                                  />
                                  {/* <label
                                    htmlFor="a11y_TaxStatus"
                                    className="form-label"
                                  >
                                    Status
                                  </label>
                                  <select
                                    className="form-select"
                                    id="a11y_TaxStatus"
                                    name="tax_status"
                                    value={
                                      customerInformation?.status !== undefined
                                        ? customerInformation?.status
                                        : '0'
                                    }
                                    onChange={(e: any) => HandleOnChange6(e)}
                                    disabled
                                  >
                                    <option value="0">Select</option>
                                    {dynamicDropdown(taxExemptStatus)}
                                  </select> */}
                                </Grid>
                              ) : null}
                              {pageType == undefined ||
                              pageType == 'customer' ? (
                                <Grid item md={4} lg={2}>
                                  <RACDatePicker
                                    value={customerInformation?.effectiveDate}
                                    label="Effective Date"
                                    name="tax_EffectiveDate"
                                    disabled={true}
                                    onChange={(e: any) => HandleOnChange6(e)}
                                  />
                                  {/* <label
                                    htmlFor="a11y_eff_date"
                                    className="form-label"
                                  >
                                    Effective Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="tax_EffectiveDate"
                                    id="a11y_eff_date"
                                    disabled
                                    value={customerInformation?.effectiveDate}
                                    onChange={(e: any) => HandleOnChange6(e)}
                                  /> */}
                                </Grid>
                              ) : null}
                              {pageType == undefined ||
                              pageType == 'customer' ? (
                                <Grid item md={4} lg={2}>
                                  <RACDatePicker
                                    value={customerInformation?.expirationDate}
                                    label="Expiration Date"
                                    name="tax_ExpirationDate"
                                    disabled={true}
                                    onChange={(e: any) => HandleOnChange6(e)}
                                  />
                                  {/* <label
                                    htmlFor="a11y_exp_date"
                                    className="form-label"
                                  >
                                    Expiration Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="tax_ExpirationDate"
                                    id="a11y_exp_date"
                                    disabled
                                    value={customerInformation?.expirationDate}
                                    onChange={(e: any) => HandleOnChange6(e)}
                                  /> */}
                                </Grid>
                              ) : null}
                              {pageType == undefined ||
                              pageType == 'customer' ? (
                                <Grid item md={4} lg={2}>
                                  <RACDatePicker
                                    value={customerInformation?.appDenialDate}
                                    label="App/Denial Date"
                                    name="tax_appDenailDate"
                                    disabled={true}
                                    onChange={(e: any) => HandleOnChange7(e)}
                                  />
                                  {/* <label
                                    htmlFor="a11y_denial_date"
                                    className="form-label"
                                  >
                                    App/Denial Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="tax_appDenailDate"
                                    id="a11y_denial_date"
                                    disabled
                                    value={customerInformation?.appDenialDate}
                                    onChange={(e: any) => HandleOnChange7(e)}
                                  /> */}
                                </Grid>
                              ) : null}
                            </Grid>
                            <Grid container>
                              <Grid item md={4} lg={2} className="mt-2">
                                {/* <button
                                  type="button"
                                  className="btn btn-primary"
                                  disabled={
                                    customerInformation?.federalTaxId ==
                                      undefined ||
                                    customerInformation?.federalTaxId == null ||
                                    customerInformation?.federalTaxId == ''
                                      ? true
                                      : false
                                  }
                                  onClick={() => printHTML()}
                                >
                                  Print Fax Cover Sheet
                                </button> */}
                                <RACButton
                                  className="btn btn-primary"
                                  disabled={
                                    customerInformation?.federalTaxId ==
                                      undefined ||
                                    customerInformation?.federalTaxId == null ||
                                    customerInformation?.federalTaxId == ''
                                      ? true
                                      : false
                                  }
                                  onClick={() => printHTML()}
                                >
                                  Print Fax Cover Sheet
                                </RACButton>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ) : null}
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
              {((pageType == undefined || pageType == 'customer') &&
                activetab == 1) ||
              (pageType !== 'customer' && createActiveTab == 1) ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <Grid
                    container
                    id="employmentinfo"
                    className="cust-grid-margin mb-3 mx-1"
                  >
                    <Typography className="title">
                      Employer’s Information
                    </Typography>
                    {employerInfoFunc()}
                    <Grid container>
                      <a
                        className="racpad-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => addanotherEmployement()}
                      >
                        + Add another Employment
                      </a>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}

              {((pageType == undefined || pageType == 'customer') &&
                activetab == 2) ||
              (pageType !== 'customer' && createActiveTab == 2) ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <Grid container id="residenceinfo" className="px-1">
                    <Grid item md={12}>
                      <Typography className="title">
                        Residence Information
                      </Typography>
                    </Grid>
                    <Grid container>
                      <Card className="card w-100">
                        <CardContent>
                          <Grid container>
                            <Grid item md={12} className="mb-3">
                              <Grid item className="rac-col-md-12 floatLeft">
                                <Grid item className="floatLeft">
                                  <Typography
                                    component="label"
                                    className="form-label w-100 floatLeft"
                                  >
                                    Does the customer rent or own the residence?
                                  </Typography>

                                  <Grid
                                    item
                                    className="form-check form-check-inline ps-0"
                                  >
                                    <RACRadio
                                      name="inlineRadioOptions"
                                      id="rent"
                                      checked={
                                        Residences?.rented !== undefined &&
                                        !Residences?.rented
                                          ? false
                                          : true
                                      }
                                      onChange={(e: any) => {
                                        HandleOnChange10(e);
                                      }}
                                      className="cust-radioBox"
                                      value=""
                                      label="Rent"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    className="form-check form-check-inline"
                                  >
                                    <RACRadio
                                      name="inlineRadioOptionsOwn"
                                      id="own"
                                      checked={
                                        Residences?.owned !== undefined &&
                                        !Residences?.owned
                                          ? false
                                          : true
                                      }
                                      onChange={(e: any) => {
                                        HandleOnChange10(e);
                                      }}
                                      label="Own"
                                      className="cust-radioBox"
                                      value={undefined}
                                    />
                                  </Grid>
                                </Grid>
                                {pageType == undefined ||
                                pageType == 'customer' ? (
                                  <Grid item className="floatRight">
                                    <Grid item className="form-check">
                                      <RACCheckBox
                                        id="flexCheckDefault77"
                                        name="residenceInfoVerifiedCheckBox"
                                        checked={
                                          Residences.verifiedCheckBox !==
                                            undefined &&
                                          Residences.verifiedCheckBox !==
                                            null &&
                                          Residences.verifiedCheckBox !== ''
                                            ? true
                                            : false
                                        }
                                        className="cust-checkbox"
                                        label="Verified"
                                        onChange={(e: any) => {
                                          HandleOnChange9(e);
                                        }}
                                      />
                                      {/* <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault77"
                                      name="residenceInfoVerifiedCheckBox"
                                      checked={
                                        Residences.verifiedCheckBox !==
                                          undefined &&
                                        Residences.verifiedCheckBox !== null &&
                                        Residences.verifiedCheckBox !== ''
                                          ? true
                                          : false
                                      }
                                      onChange={(e: any) => {
                                        HandleOnChange9(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label racpad-margin"
                                      htmlFor="flexCheckDefault77"
                                    >
                                      Verified
                                    </label> */}
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid>
                              {Residences?.owned !== undefined &&
                              !Residences?.owned ? null : (
                                <Grid
                                  item
                                  className="rac-col-md-12 rac-col-lg-12 floatLeft mt-3"
                                >
                                  <Grid
                                    item
                                    className="form-check form-check-inline ps-0"
                                  >
                                    <RACCheckBox
                                      id="mortgage"
                                      name="inlinemortagechk"
                                      checked={
                                        Residences?.mortgage !== undefined &&
                                        !Residences?.mortgage
                                          ? false
                                          : true
                                      }
                                      onChange={(e: any) => {
                                        HandleOnChange10(e);
                                      }}
                                      className="cust-checkbox"
                                      label="Mortgage"
                                    />
                                    {/* <input
                                    className="form-check-input radio-margin"
                                    data-testid="mortagechk"
                                    type="checkbox"
                                    name="inlinemortagechk"
                                    id="mortgage"
                                    checked={
                                      Residences?.mortgage !== undefined &&
                                      !Residences?.mortgage
                                        ? false
                                        : true
                                    }
                                    onChange={(e: any) => {
                                      HandleOnChange10(e);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mortgage"
                                  >
                                    Mortgage
                                  </label> */}
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                            <Grid container spacing={2}>
                              {Residences?.owned === true ? (
                                <Grid
                                  item
                                  className="rac-col-md-2 rac-col-lg-2 floatLeft"
                                >
                                  <Grid
                                    item
                                    className="rac-col-md-12 rac-col-lg-12"
                                  >
                                    <RACDatePicker
                                      value={
                                        Residences?.residenceSince !== undefined
                                          ? Residences?.residenceSince
                                          : ''
                                      }
                                      id="a11y_moveindate"
                                      label="Move in Date"
                                      name="lldateOwn"
                                      disabled={false}
                                      onChange={(e: any) =>
                                        setMoveInDate(e, 'lldateOwn')
                                      }
                                    />
                                  </Grid>
                                  {/* <label
                                  htmlFor="a11y_moveindate"
                                  className="form-label"
                                >
                                  Move in Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="lldateOwn"
                                  value={
                                    Residences?.residenceSince !== undefined
                                      ? Residences?.residenceSince
                                      : ''
                                  }
                                  id="a11y_moveindate"
                                  onChange={(e: any) => HandleOnChange11M(e)}
                                /> */}
                                </Grid>
                              ) : null}
                              {Residences?.mortgage !== undefined &&
                              Residences?.mortgage === true ? (
                                <React.Fragment>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      inputlabel="Mortgage Company Name"
                                      name={`llnumberCompany`}
                                      OnChange={(e: any) =>
                                        HandleOnChange11M(e)
                                      }
                                      maxlength={30}
                                      value={
                                        Residences?.mortgageCompanyName !==
                                        undefined
                                          ? Residences?.mortgageCompanyName
                                          : ''
                                      }
                                      errorMessage={
                                        Residences?.mortgageCompanyName !==
                                          undefined &&
                                        Residences?.mortgageCompanyName == '' &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                      }
                                    />
                                    {/* <label
                                    htmlFor="a11y_LandlordPhone"
                                    className="form-label"
                                  >
                                    Mortgage Company Name
                                    <span className="mandatoryfield">
                                      *
                                    </span>{' '}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="llnumberCompany"
                                    data-testid="numberTxtBox"
                                    value={
                                      Residences?.mortgageCompanyName !==
                                      undefined
                                        ? Residences?.mortgageCompanyName
                                        : ''
                                    }
                                    id="a11y_LandlordPhone"
                                    maxLength={30}
                                    onChange={(e: any) => HandleOnChange11M(e)}
                                  />
                                  {Residences?.mortgageCompanyName !==
                                    undefined &&
                                  Residences?.mortgageCompanyName == '' &&
                                  isrequired === true ? (
                                    <label className="mandatoryfield validation">
                                      Required
                                    </label>
                                  ) : (
                                    ''
                                  )} */}
                                  </Grid>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACTextbox
                                      isCurrency={true}
                                      type={'number'}
                                      digitFormat={'currency'}
                                      inputlabel="Payment Amount"
                                      name={`llamountPaymentAmt`}
                                      OnChange={(e: any) =>
                                        HandleOnChange11M(e)
                                      }
                                      maxlength={10}
                                      value={
                                        Residences?.monthlyMortgagePayment !==
                                          undefined &&
                                        Residences?.monthlyMortgagePayment !==
                                          null &&
                                        Residences?.monthlyMortgagePayment !==
                                          'NaN'
                                          ? Residences?.monthlyMortgagePayment
                                          : '0.00'
                                      }
                                      className="cust-digit"
                                      dollarTextClassName="cust-digit-dollar"
                                    />
                                    {/* <label
                                    htmlFor="a11y_paymentamount"
                                    className="form-label"
                                  >
                                    Payment Amount
                                  </label> */}
                                    {/* <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-adon"
                                    >
                                      $
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control txtBox-Radius align-right"
                                      name="llamountPaymentAmt"
                                      value={
                                        Residences?.monthlyMortgagePayment !==
                                          undefined &&
                                        Residences?.monthlyMortgagePayment !==
                                          null &&
                                        Residences?.monthlyMortgagePayment !==
                                          'NaN'
                                          ? Residences?.monthlyMortgagePayment
                                          : ''
                                      }
                                      id="a11y_paymentamount"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      onChange={(e: any) =>
                                        HandleOnChange11M(e)
                                      }
                                      onBlur={() => {
                                        setResidencesvalues({
                                          ...Residences,
                                          monthlyMortgagePayment: parseFloat(
                                            Residences?.monthlyMortgagePayment
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ','
                                            ),
                                        });
                                      }}
                                      maxLength={10}
                                    />
                                  </div> */}
                                  </Grid>
                                </React.Fragment>
                              ) : Residences?.rented !== undefined &&
                                Residences?.rented === true ? (
                                <React.Fragment>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      inputlabel="Landlord’s First Name"
                                      name={`llname`}
                                      OnChange={(e: any) => HandleOnChange10(e)}
                                      maxlength={30}
                                      value={
                                        Residences?.landlordFirstName !==
                                        undefined
                                          ? Residences?.landlordFirstName
                                          : ''
                                      }
                                      errorMessage={
                                        Residences?.landlordFirstName !==
                                          undefined &&
                                        Residences?.landlordFirstName == '' &&
                                        Residences?.owned === false &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                      }
                                    />
                                    {/* <label
                                    htmlFor="a11y_Landlord"
                                    className="form-label"
                                  >
                                    Landlord’s First Name
                                    <span className="mandatoryfield">
                                      *
                                    </span>{' '}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="llname"
                                    data-testid="nameTxtBox"
                                    value={
                                      Residences?.landlordFirstName !==
                                      undefined
                                        ? Residences?.landlordFirstName
                                        : ''
                                    }
                                    maxLength={30}
                                    id="a11y_Landlord"
                                    onChange={(e: any) => HandleOnChange10(e)}
                                  />
                                  {Residences?.landlordFirstName !==
                                    undefined &&
                                  Residences?.landlordFirstName == '' &&
                                  Residences?.owned === false &&
                                  isrequired === true ? (
                                    <label className="mandatoryfield validation">
                                      Required
                                    </label>
                                  ) : (
                                    ''
                                  )} */}
                                  </Grid>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      inputlabel="Landlord’s Last Name"
                                      name={`llLastname`}
                                      OnChange={(e: any) => HandleOnChange10(e)}
                                      maxlength={30}
                                      value={
                                        Residences?.landlordLastName !==
                                        undefined
                                          ? Residences?.landlordLastName
                                          : ''
                                      }
                                      errorMessage={
                                        Residences?.landlordLastName !==
                                          undefined &&
                                        Residences?.landlordLastName == '' &&
                                        Residences?.owned === false &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : ''
                                      }
                                    />
                                    {/* <label
                                    htmlFor="a11y_Landlord"
                                    className="form-label"
                                  >
                                    Landlord’s Last Name
                                    <span className="mandatoryfield">
                                      *
                                    </span>{' '}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="llLastname"
                                    data-testid="nameTxtBox"
                                    value={
                                      Residences?.landlordLastName !== undefined
                                        ? Residences?.landlordLastName
                                        : ''
                                    }
                                    maxLength={30}
                                    id="a11y_Landlord"
                                    onChange={(e: any) => HandleOnChange10(e)}
                                  />
                                  {Residences?.landlordLastName !== undefined &&
                                  Residences?.landlordLastName == '' &&
                                  Residences?.owned === false &&
                                  isrequired === true ? (
                                    <label className="mandatoryfield validation">
                                      Required
                                    </label>
                                  ) : (
                                    ''
                                  )} */}
                                  </Grid>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACTextbox
                                      isCurrency={false}
                                      required={true}
                                      type={'text'}
                                      inputlabel="Landlord’s Phone Number"
                                      name={`llnumber`}
                                      OnChange={(e: any) => HandleOnChange10(e)}
                                      maxlength={13}
                                      value={
                                        Residences?.phoneNumber !== undefined
                                          ? formatPhone(Residences.phoneNumber)
                                          : ''
                                      }
                                      errorMessage={
                                        Residences?.phoneNumber !== undefined &&
                                        Residences?.phoneNumber == '' &&
                                        Residences?.owned === false &&
                                        isrequired === true
                                          ? FIELD_ERROR
                                          : isrequired === true &&
                                            Residences?.owned === false &&
                                            (Residences?.phoneNumber ==
                                              undefined ||
                                              Residences?.phoneNumber == null ||
                                              Residences?.phoneNumber.length <
                                                10)
                                          ? 'Please enter valid phone number'
                                          : ''
                                      }
                                    />
                                    {/* <label
                                    htmlFor="a11y_LandlordPhone"
                                    className="form-label"
                                  >
                                    Landlord’s Phone Number
                                    <span className="mandatoryfield">
                                      *
                                    </span>{' '}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="llnumber"
                                    data-testid="numberTxtBox"
                                    value={
                                      Residences?.phoneNumber !== undefined
                                        ? formatPhone(Residences.phoneNumber)
                                        : ''
                                    }
                                    id="a11y_LandlordPhone"
                                    maxLength={13}
                                    onChange={(e: any) => HandleOnChange10(e)}
                                  />
                                  {Residences?.phoneNumber !== undefined &&
                                  Residences?.phoneNumber == '' &&
                                  Residences?.owned === false &&
                                  isrequired === true ? (
                                    <label className="mandatoryfield validation">
                                      Required
                                    </label>
                                  ) : isrequired === true &&
                                    Residences?.owned === false &&
                                    (Residences?.phoneNumber == undefined ||
                                      Residences?.phoneNumber == null ||
                                      Residences?.phoneNumber.length < 10) ? (
                                    <label className="mandatoryfield validation">
                                      Please enter valid phone number
                                    </label>
                                  ) : null} */}
                                  </Grid>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACTextbox
                                      isCurrency={true}
                                      type={'number'}
                                      digitFormat={'currency'}
                                      inputlabel="Payment Amount"
                                      name={`llamount`}
                                      OnChange={(e: any) => HandleOnChange11(e)}
                                      maxlength={10}
                                      value={
                                        Residences?.monthlyRent !== undefined &&
                                        Residences?.monthlyRent !== null &&
                                        Residences?.monthlyRent !== 'NaN'
                                          ? Residences?.monthlyRent
                                          : '0.00'
                                      }
                                      className="cust-digit"
                                      dollarTextClassName="cust-digit-dollar"
                                    />
                                    {/* <label
                                    htmlFor="a11y_paymentamount"
                                    className="form-label"
                                  >
                                    Payment Amount
                                  </label>
                                  <div className="input-group mb-3">
                                    <span
                                      className="input-group-text"
                                      id="basic-adon"
                                    >
                                      $
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control txtBox-Radius align-right"
                                      name="llamount"
                                      value={
                                        Residences?.monthlyRent !== undefined &&
                                        Residences?.monthlyRent !== null &&
                                        Residences?.monthlyRent !== 'NaN'
                                          ? Residences?.monthlyRent
                                          : ''
                                      }
                                      maxLength={10}
                                      id="a11y_paymentamount"
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                      onChange={(e: any) => HandleOnChange11(e)}
                                      onBlur={() => {
                                        setResidencesvalues({
                                          ...Residences,
                                          monthlyRent: parseFloat(
                                            Residences?.monthlyRent
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ','
                                            ),
                                        });
                                      }}
                                    />
                                  </div> */}
                                  </Grid>
                                  <Grid
                                    item
                                    className="rac-col-md-2 rac-col-lg-2"
                                  >
                                    <RACDatePicker
                                      value={
                                        Residences?.moveInDate !== undefined
                                          ? Residences?.moveInDate
                                          : ''
                                      }
                                      label="Move in Date"
                                      name="lldateMove"
                                      onChange={(e: any) =>
                                        setMoveInDate(e, 'lldateMove')
                                      }
                                    />
                                    {/* <label
                                    htmlFor="a11y_moveindate"
                                    className="form-label"
                                  >
                                    Move in Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="lldateMove"
                                    value={
                                      Residences?.moveInDate !== undefined
                                        ? Residences?.moveInDate
                                        : ''
                                    }
                                    id="a11y_moveindate"
                                    onChange={(e: any) => HandleOnChange11(e)}
                                  /> */}
                                  </Grid>
                                </React.Fragment>
                              ) : null}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}

              {((pageType == undefined || pageType == 'customer') &&
                activetab == 3) ||
              (pageType !== 'customer' && createActiveTab == 3) ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <Grid
                    container
                    id="referenceinfo"
                    className="cust-grid-margin px-1"
                  >
                    <Grid item md={12}>
                      <Typography variant="h6" className="title">
                        Reference Information
                      </Typography>
                      {referenceInfoFunc()}
                    </Grid>
                    <Grid item md={12} className="mb-3">
                      <a
                        className="racpad-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => addanotherReference()}
                      >
                        + Add another Reference
                      </a>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}
              {((pageType == undefined || pageType == 'customer') &&
                activetab == 4) ||
              (pageType !== 'customer' && createActiveTab == 4) ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <Grid
                    container
                    id={'vehicleinfo'}
                    className="cust-grid-margin px-1"
                  >
                    <Grid item md={12}>
                      <Typography className="title">
                        Vehicle Information
                      </Typography>
                      {buildVechicleBlock()}
                    </Grid>
                    <Grid item md={12} className="mb-3">
                      <a
                        className="racpad-link"
                        style={{ cursor: 'pointer' }}
                        onClick={() => addanotherVehicle()}
                      >
                        + Add another Vehicle
                      </a>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}
              {((pageType == undefined || pageType == 'customer') &&
                activetab == 5) ||
              (pageType !== 'customer' && createActiveTab == 5) ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <Grid
                    container
                    id="agreementhistoryinfo"
                    className="mb-3 px-1"
                  >
                    <Grid item md={12}>
                      <Typography className="title">
                        Agreement History
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Card className="card w-100">
                        <CardContent>
                          <Grid md={12}>
                            {agreementInf === undefined ? (
                              <Grid md={12} className="text-center">
                                <CircularProgress />
                              </Grid>
                            ) : agreementInf !== undefined &&
                              agreementInf !== null &&
                              agreementInf.length > 0 ? (
                              <>
                                {/* <table className="table table-borderless table-header-wrap table-responsive-style racpad-table mb-0 fold-table">
                                        <thead>
                                          <tr>
                                            <th>Agreement #</th>
                                            <th>Description</th>
                                            <th>Type</th>
                                            <th>Store</th>
                                            <th># Payments</th>
                                            <th className="line-break">Total Cost</th>
                                            <th>Schedule</th>
                                            <th>Next Due Date</th>
                                            <th className="line-break">Amount Due</th>
                                            <th>Sub Status</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {AgreementGrid('Active', activeExpand)}
                                          {AgreementGrid('Pending', pending)}
                                          {AgreementGrid('InActive', inActiveExpand)}
                                        </tbody>
                                      </table> */}
                                <RACTable
                                  renderTableHead={renderAgreementHistoryHeader}
                                  renderTableContent={
                                    renderAgreementHistoryContent
                                  }
                                />
                              </>
                            ) : (
                              <Grid md={12} className="text-center">
                                <Alerticon></Alerticon>
                                <Typography className="popup-text text-center mt-3">
                                  {agreementInf !== null
                                    ? 'No rental agreements found'
                                    : // eslint-disable-next-line sonarjs/no-duplicate-string
                                      'Something went wrong'}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                ) : null
              ) : null}
            </React.Fragment>
          )}
        </Grid>

        <Grid item className="fixed-bottom-spacer">
          <Grid
            item
            className="fixed-bottom p-3 bg-white shadow"
            style={{ zIndex: 0 }}
          >
            <Grid item className="floatLeft">
              <RACButton
                className="btn btn-secondary"
                onClick={() => {
                  customerInfoChange === true
                    ? setcustomerInfoChangePopUp(true)
                    : props.history.push(`/dashboard/Gridcomponent`);
                }}
              >
                Cancel
              </RACButton>
              {pageType !== 'customer' && createActiveTab > 0 ? (
                <RACButton
                  className="btn sw-btn-prev"
                  onClick={() => validateNextCustomer('Back')}
                >
                  <PreviousBtn className="me-2"></PreviousBtn> Previous
                </RACButton>
              ) : null}
            </Grid>
            <Grid className="floatRight">
              {pageType == undefined || pageType == 'customer' ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <RACButton
                    className="btn btn-primary floatLeft me-1"
                    onClick={() =>
                      props.history.push(
                        `/payment1/paymentinformation/${customerId}/0`
                      )
                    }
                  >
                    Take Payment
                  </RACButton>
                ) : null
              ) : null}
              {legalHoldApplied !== 1 &&
              isLegalHold !== true &&
              customerCoverageInf?.clubStatus !== undefined ? (
                customerCoverageInf !== undefined &&
                customerCoverageInf?.clubStatus !== undefined &&
                (customerCoverageInf?.clubStatus == 'Active' ||
                  customerCoverageInf?.clubStatus == 'A') &&
                (pageType == undefined || pageType == 'customer') ? (
                  <RACButton
                    className="btn btn-primary me-1 floatLeft"
                    onClick={() => setactivateClubObj(true)}
                  >
                    {'Club Is Active'}
                  </RACButton>
                ) : (
                  <RACButton
                    className="btn btn-primary me-1 floatLeft"
                    disabled={true}
                  >
                    {'Club Is Not Active'}
                  </RACButton>
                )
              ) : null}
              {customerIdQueryParam == null ? (
                legalHoldApplied !== 1 && isLegalHold !== true ? (
                  <Grid
                    item
                    className="btn-group me-1 floatLeft me-1"
                    role="group"
                  >
                    {' '}
                    <RACButton
                      className="btn btn-primary"
                      onClick={() => agreementRedirect()}
                    >
                      Create Agreement
                    </RACButton>
                  </Grid>
                ) : null
              ) : null}
              {pageType == 'customer' ? (
                <RACButton
                  className="btn btn-primary floatLeft me-1"
                  onClick={() => validateUpdateCustomer()}
                >
                  Save
                </RACButton>
              ) : null}
              {pageType !== 'customer' ? (
                <RACButton
                  className="btn btn-primary floatLeft me-1"
                  onClick={() => validateNextCustomer('Next')}
                >
                  {nextBtnText}
                  {nextBtnText == 'Next' ? (
                    <NextBtn className="ms-2"></NextBtn>
                  ) : null}
                </RACButton>
              ) : null}
            </Grid>
          </Grid>
          {/* <Modal
            isOpen={errorPopup}
            className="modal fade show expandPopup modal-dialog custom-popup-widthv2 pop-position modal-top"
            id="Error-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            overlayClassName="Overlay"
          >
            <div className="modal-content" data-testid="activateClubModal">
              <div className="modal-header border-0">
                <label className="modal-title title popup-text"></label>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-testid="closebtn"
                  onClick={() => hideErrorPopUp()}
                />
              </div>
              <div className="modal-body">
                <p className="align-center">
                  {' '}
                  {pageType == undefined || pageType == 'customer'
                    ? 'Please fill all the mandatory fields in the below listed sections'
                    : 'Please fill all the mandatory fields'}
                </p>
                {validateResult.map((element: any, index: any) => {
                  return (
                    <p className="error-popup" key={index}>
                      {element}
                    </p>
                  );
                })}
              </div>
              <div className="col-md-12 p-3 text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  data-bs-dismiss="modal"
                  onClick={() => hideErrorPopUp()}
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal> */}
          {/* <Modal
            isOpen={updatePopUp}
            className="modal fade show expandPopup modal-dialog custom-popup-widthv2 pop-position modal-top"
            id="Error-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            overlayClassName="Overlay"
          >
            <div className="modal-content" data-testid="UpdateStatusModal">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-testid="closebtn"
                  onClick={() => hideupdatePopUp()}
                />
              </div>
              <div className="modal-body text-center ">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    {isSuccess === true ? (
                      <SuccessIcon></SuccessIcon>
                    ) : (
                      <Alerticon></Alerticon>
                    )}
                  </div>
                  <p className="popup-text mt-3">
                    {isSuccess
                      ? 'Customer information is updated successfully'
                      : 'Something went wrong'}
                  </p>
                </div>
              </div>
              <div className="col-md-12 py-4 text-center">
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#delivery-receipt"
                  onClick={() => hideupdatePopUp()}
                >
                  OK
                </button>
              </div>
            </div>
          </Modal> */}
          {/* <Modal
            isOpen={createAgreementPopUp}
            className="modal fade show expandPopup modal-dialog custom-popup-widthv2 pop-position modal-top"
            id="Error-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            overlayClassName="Overlay"
          >
            <div className="modal-content" data-testid="UpdateStatusModal">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-testid="closebtn"
                  onClick={() => setcreateAgreementPopUp(false)}
                />
              </div>
              <div className="modal-body text-center ">
                <div className="row justify-content-center">
                  <p className="col-md-12 popup-text">
                    The customer record has been created
                  </p>
                  <p className="mt-1 popup-font">
                    Would you like to create a new agreement?
                  </p>
                </div>
              </div>
              <div className="col-md-12 py-4 text-center">
                <button
                  type="button"
                  className="btn btn-padding btn-secondary me-2"
                  onClick={() => {
                    setcreateAgreementPopUp(false);
                    props.history.push(`/dashboard/Gridcomponent`);
                  }}
                >
                  Not Now
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#delivery-receipt"
                  onClick={() => {
                    setcreateAgreementPopUp(false);
                    setcreateCoCustomerPopUp(true);
                  }}
                >
                  Yes, Create new agreement
                </button>
              </div>
            </div>
          </Modal> */}
          {/* <Modal
            isOpen={customerInfoChangePopUp}
            className="modal fade show expandPopup modal-dialog custom-popup-widthv2 pop-position modal-top"
            id="Error-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            overlayClassName="Overlay"
          >
            <div className="modal-content" data-testid="UpdateStatusModal">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-testid="closebtn"
                  onClick={() => setcustomerInfoChangePopUp(false)}
                />
              </div>
              <div className="modal-body text-center ">
                <div className="row justify-content-center">
                  <p className="popup-text mt-3">Cancel without saving?</p>
                </div>
              </div>
              <div className="col-md-12 py-4 text-center">
                <button
                  type="button"
                  className="btn btn-padding btn-secondary me-2"
                  onClick={() => {
                    setcustomerInfoChangePopUp(false);
                  }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#delivery-receipt"
                  onClick={() => {
                    props.history.push(
                      `/agreement/rental/itemsearch/${customerId}`
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal> */}
          {/* <Modal
            isOpen={createCoCustomerPopUp}
            className="modal fade show expandPopup modal-dialog custom-popup-widthv2 pop-position modal-top"
            id="Error-popup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            overlayClassName="Overlay"
          >
            <div className="modal-content" data-testid="UpdateStatusModal">
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-testid="closebtn"
                  onClick={() => setcreateCoCustomerPopUp(false)}
                />
              </div>
              <div className="modal-body text-center ">
                <div className="row justify-content-center">
                  <p className="popup-text mt-3">Would you like to add the</p>
                  <p className="popup-text mt-1">Co-Customer for agreement?</p>
                </div>
              </div>
              <div className="col-md-12 py-4 text-center">
                <button
                  type="button"
                  className="btn btn-padding btn-secondary me-2"
                  onClick={() => {
                    setcreateCoCustomerPopUp(false);
                    props.history.push(
                      `/agreement/rental/itemsearch/${customerId}`
                    );
                  }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#delivery-receipt"
                  onClick={() => {
                    setcreateCoCustomerPopUp(false);
                    Setshowcocustomerpopup(true);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal> */}

          {/* <Modal
            isOpen={ValidateIsOpen}
            data-testid="ValidateIsOpenpopUp"
            className="modal-dialog addpop modal fade show expandPopup modal-top"
            overlayClassName="Overlay"
          >
            <div
              id="confirmduedate"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <label className="modal-title title popup-text">
                      Validation Message
                    </label>
                    <button
                      type="button"
                      className="btn-close"
                      data-testid="ValidateIsOpenClose"
                      data-bs-dismiss="modal"
                      onClick={() => setValidateIsOpen(false)}
                      aria-label="Close"
                    />
                  </div>
                  {AddressDoc === true ? (
                    <div className="Norecords">
                      <CircularProgress />
                    </div>
                  ) : SuggestedAddress == undefined ? (
                    <div className="modal-body text-left pt-0">
                      <p>
                        Automatic Address validation is Unavailable for the
                        store # Please validate the address Manually.
                      </p>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="modal-body text-left pt-0">
                        <p>Entered address is not located</p>
                        <div className="col-md-12 floatLeft mb-3 ">
                          <label
                            htmlFor="validate-1"
                            className="form-label w-100"
                          >
                            Suggested Addresses
                          </label>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              data-testid="ValidateInputField"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              defaultValue="option1"
                              checked={
                                defaultSuggestedAddress == 'SUG' ? true : false
                              }
                              onClick={() => RadioBtnclick('SUG')}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              htmlFor="inlineRadio1"
                            >
                              {SuggestedAddress !== undefined &&
                              SuggestedAddress !== null
                                ? `${SuggestedAddress.Addresses.StreetAddress} ,
                                ${SuggestedAddress.Addresses.City} ,
                                ${SuggestedAddress.Addresses.StateProvince} ,
                                ${SuggestedAddress.Addresses.PostalCode}`
                                : ''}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 floatLeft mb-3 mt-3 ">
                          <label
                            htmlFor="validate-2"
                            className="form-label w-100"
                          >
                            Entered Address
                          </label>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              data-testid="EnteredInputField"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              checked={
                                defaultSuggestedAddress == 'ENT' ? true : false
                              }
                              defaultValue="option2"
                              onClick={() => RadioBtnclick('ENT')}
                            />
                            <label
                              className="form-check-label checkbox-label"
                              htmlFor="inlineRadio2"
                            >
                              {runAddressDoc === 'P'
                                ? updatePrimaryAddress.address +
                                  ',' +
                                  updatePrimaryAddress.address1 +
                                  ',' +
                                  updatePrimaryAddress.city +
                                  ',' +
                                  updatePrimaryAddress.state +
                                  '-' +
                                  updatePrimaryAddress.zip
                                : updateMailingAddress.address +
                                  ',' +
                                  updateMailingAddress.address1 +
                                  ',' +
                                  updateMailingAddress.city +
                                  ',' +
                                  updateMailingAddress?.state +
                                  '-' +
                                  updateMailingAddress.zip}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="floatLeft col-md-12 mt-3 mb-3">
                        <div className="col-md-6 floatLeft ps-3">
                          <button
                            type="button"
                            data-testid="cancelClickValidatePopup"
                            className="btn btn-secondary mx-1"
                            data-bs-dismiss="modal"
                            onClick={() =>
                              continueclickAddressDoc('ENT', runAddressDoc)
                            }
                          >
                            Cancel
                          </button>
                        </div>
                        <div className="col-md-6 floatRight">
                          <button
                            type="button"
                            className="btn btn-primary mx-1 opacity-pop"
                            data-testid="overrideClick"
                            onClick={() =>
                              continueclickAddressDoc('ENT', runAddressDoc)
                            }
                            disabled={BtnIsdisabled1}
                          >
                            Override
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary mx-1"
                            data-testid="continueClick"
                            disabled={BtnIsdisabled2}
                            onClick={() =>
                              continueclickAddressDoc('SUG', runAddressDoc)
                            }
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </Modal> */}
          <Modal
            isOpen={loader}
            className={loader ? somethingone : somethingtwo}
            id="openpopup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm" data-testid="modalrender">
              <div>
                <div className="loader">
                  <CircularProgress />
                </div>
              </div>
            </div>
          </Modal>
          {/* <Modal
            isOpen={activateClubObj}
            className={'modal-dialog addpop modal fade show expandPopup'}
            overlayClassName="Overlay"
            id="activateClub"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm" data-testid="modalrender">
              <div className="modal-content modal-width modal-content2 club-width">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-testid="closebtn"
                    onClick={() => setactivateClubObj(false)}
                  />
                </div>

                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <div className="row mb-3">
                          <div className="col-md-5 mt-2">
                            <label
                              htmlFor="a11y_Member"
                              className="form-label labelfont"
                            >
                              Member Id
                            </label>
                          </div>
                          <div className="col-md-7">
                            <RACTextbox
                              isCurrency={false}
                              type={'text'}
                              OnChange={(e: any) => {
                                handleChangeForAddReferenceInfo(e);
                              }}
                              disabled={true}
                              value={
                                customerCoverageInf !== undefined &&
                                customerCoverageInf?.membershipNumber !==
                                  undefined
                                  ? customerCoverageInf?.membershipNumber
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-5 mt-2">
                            <label
                              htmlFor="a11y_Benefits"
                              className="form-label labelfont"
                            >
                              Level Of Benefits
                            </label>
                          </div>
                          <div className="col-md-7">
                            <RACTextbox
                              isCurrency={false}
                              type={'text'}
                              OnChange={(e: any) => {
                                handleChangeForAddReferenceInfo(e);
                              }}
                              disabled={true}
                              value={
                                customerCoverageInf !== undefined &&
                                customerCoverageInf?.clubName !== undefined
                                  ? customerCoverageInf?.clubName
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-5 mt-2">
                            <label
                              htmlFor="a11y_Start"
                              className="form-label labelfont"
                            >
                              Start date
                            </label>
                          </div>
                          <div className="col-md-7">
                            <RACTextbox
                              isCurrency={false}
                              type={'text'}
                              OnChange={(e: any) => {
                                handleChangeForAddReferenceInfo(e);
                              }}
                              disabled={true}
                              value={
                                customerCoverageInf !== undefined &&
                                customerCoverageInf?.startDate !== undefined
                                  ? customerCoverageInf?.startDate
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-5 mt-2">
                            <label
                              htmlFor="a11y_Date"
                              className="form-label labelfont"
                            >
                              Next Fee Date
                            </label>
                          </div>
                          <div className="col-md-7">
                            <RACTextbox
                              isCurrency={false}
                              type={'text'}
                              OnChange={(e: any) => {
                                handleChangeForAddReferenceInfo(e);
                              }}
                              disabled={true}
                              value={
                                customerCoverageInf !== undefined &&
                                customerCoverageInf?.nextDueDate !== undefined
                                  ? customerCoverageInf?.nextDueDate
                                  : ''
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-5 mt-2">
                            <label
                              htmlFor="a11y_Frequency"
                              className="form-label labelfont"
                            >
                              Payment Frequency
                            </label>
                          </div>
                          <div className="col-md-7">
                            <RACTextbox
                              isCurrency={false}
                              type={'text'}
                              OnChange={(e: any) => {
                                handleChangeForAddReferenceInfo(e);
                              }}
                              disabled={true}
                              value={
                                customerCoverageInf !== undefined &&
                                customerCoverageInf?.paymentSchedule !==
                                  undefined
                                  ? customerCoverageInf?.paymentSchedule
                                  : ''
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-5 mt-2">
                            <label
                              htmlFor="a11y_Fee"
                              className="form-label labelfont"
                            >
                              Fee
                            </label>
                          </div>
                          <div className="col-md-7">
                            <RACTextbox
                              isCurrency={false}
                              type={'text'}
                              OnChange={(e: any) => {
                                handleChangeForAddReferenceInfo(e);
                              }}
                              disabled={true}
                              value={
                                customerCoverageInf !== undefined &&
                                customerCoverageInf?.clubAmount !== undefined
                                  ? '$ ' + customerCoverageInf?.clubAmount
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="floatRight mt-5 mb-3">
                      <button
                        type="button"
                        className="btn btn-padding btn-secondary me-2"
                        onClick={() => {
                          setactivateClubObj(false);
                          setactivateClub(true);
                        }}
                      >
                        Deactivate
                      </button>

                      <button
                        type="button"
                        data-testid="btnenabled"
                        className="btn btn-padding btn-primary me-2"
                        onClick={() => setactivateClubObj(false)}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}

          {/* <Modal
            isOpen={openModal}
            className={openModal ? somethingone : somethingtwo}
            overlayClassName="Overlay"
            id="openpopup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm" data-testid="modalrender">
              <div className="modal-content modal-width modal-content2">
                <div className="modal-header border-0">
                  <a className="navbar-brand mr-0-responsive logoalignment">
                    <RACLogo className="logo-responsive"></RACLogo>
                  </a>
                </div>
                {errorline === true ? (
                  <div className="float-left mx-4 mx-1">
                    <p className="form-label alertfont">
                      The user ID and/or password you entered are not valid.
                      Please try again.
                    </p>
                  </div>
                ) : null}
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <div className="txtboxpadding">
                          <RACTextbox
                            isCurrency={false}
                            type={'text'}
                            inputlabel="User ID"
                            OnChange={(event: any) => handleUserID(event)}
                            maxlength={30}
                            value={userID}
                            id="a11y_phonenumber"
                          />
                        </div>
                        <div>
                          <RACTextbox
                            isCurrency={false}
                            type={'password'}
                            inputlabel="Password"
                            OnChange={(event: any) => handlePassword(event)}
                            onKeyPress={(e: any) =>
                              secondfactorEnter(e, userID, password)
                            }
                            maxlength={15}
                            value={password}
                            id="a11y_Password"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="floatRight mt-5 mb-3">
                      <button
                        type="button"
                        className="btn btn-padding btn-secondary me-2"
                        onClick={openModelCancelClick}
                      >
                        Cancel
                      </button>
                      {userID !== '' && password !== '' ? (
                        <button
                          type="button"
                          data-testid="btnenabled"
                          className="btn btn-padding btn-primary me-2"
                          onClick={() => loginClick(userID, password)}
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          type="button"
                          data-testid="btndisabled"
                          className="btn disabled btn-padding btn-primary me-2 "
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> }
          </Modal> */}
          <Modal
            overlayClassName="Overlay"
            isOpen={openSecondModal}
            className={openSecondModal ? somethingone : somethingtwo}
            id="opensecondpopup"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm">
              <div className="modal-content modal-width modalalignment modal-content1">
                <div className="modal-header border-0 bottompadding">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={okClick}
                  />
                </div>
                <div className="modal-body bodypadding">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <div>
                          {/*<img className="alertbutton" src={Alert} alt="" />*/}
                        </div>
                        <div>
                          <p className="linetext">
                            The user you entered can not authorize the use of
                            this function.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="floatRight btnlocation">
                      <button
                        type="button"
                        autoFocus
                        className="btn btn-padding btn-primary me-2 "
                        onClick={okClick}
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {/* pinpopup */}
          <Modal
            overlayClassName="Overlay"
            isOpen={openPinModal}
            className={openPinModal ? somethingone : somethingtwo}
            id="salesleads"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm">
              <div className="modal-content pinmodal">
                <div className="modal-header border-0">
                  <h5 className="modal-title">Enter PIN to continue</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setChargeOffclose()}
                  />
                </div>
                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-md-12 mb-2">
                        <div>
                          <label
                            className="form-label ps-2 fontpin"
                            htmlFor="Pin-style"
                          >
                            PIN
                          </label>
                        </div>
                        <div onKeyPress={pinEnter}>
                          <OtpInput
                            className="col-md-3 px-2 floatLeft"
                            numInputs={4}
                            shouldAutoFocus={true}
                            isInputNum={true}
                            isInputSecure={true}
                            value={otp}
                            onChange={(event: any) => otpchange(event)}
                            inputStyle="otpstyle"
                          />
                        </div>
                      </div>
                    </div>
                    {InvalidPin == 400 ? (
                      <div>
                        {' '}
                        <span
                          id="InvalidPin"
                          className="form-label ps-2 "
                          style={{ color: 'red' }}
                        >
                          Invalid PIN Entered
                        </span>
                      </div>
                    ) : (
                      !openPinModal
                    )}

                    <div className="floatRight mt-5 mb-3">
                      <button
                        type="button"
                        className="btn btn-secondary me-2"
                        onClick={() => noClick()}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary me-2"
                        id="continue"
                        disabled={Continue}
                        onClick={() => continueClick()}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </Modal>
          {secondLevelAuthenOn !== null ? (
            <SecondFactor
              setTwoFactorCancelClick={() => setSecondLevelAuthenOn(null)}
              setTwoFactorCompleted={(e: any) => seconduthenResponses(e)}
              moduleName={secondLevelAuthenOn}
              currentRole={coWorkerRole}
            ></SecondFactor>
          ) : null}
          {/* <Modal
            isOpen={showcocustomerpopup}
            id="CoCustomerInformation"
            className="boxstyle"
            ariaHideApp={false}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-hidden="true"
            overlayClassName="Overlay"
          >
            <AddCoCustomer
              customerId={customerId}
              handleClosePopUp={(action: boolean) => {
                Setshowcocustomerpopup(action);
              }}
              showCustomerInfo={(customerId: string) => {
                props.history.push(`/customer/update/${customerId}/customer`);
              }}
              history={props.history}
            ></AddCoCustomer>
          </Modal> */}
          <div id="printHtmlDiv" style={{ display: 'none' }}>
            {printTemplate(getStore)}
          </div>
        </Grid>
      </React.Fragment>
      {/* ) : (
        <div className="Norecords">
          <CircularProgress />
        </div>
      )} */}
    </Grid>
  );
}
export default ManageCustomerInfoRacstrap;
