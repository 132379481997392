/* eslint-disable */
import {
  Grid,
  RACButton,
  RACModalCard,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACToggle,
} from "@rentacenter/racstrap";

import React, { ChangeEvent, useState } from "react";
import { EMPTY_STRING, INACTIVE_CONTACT_GRID_HEADER, LOGGED_IN_STORE, STRING_NO, STRING_YES } from "../../constants/constants";
import { ContactInformations, CustomerBasicInformation, UpdateContactPayload } from "../../interface/updateCustomerInterface";
import { maskLengthyText, validateApiResponse, valueExistsInDropdown } from "../../utils/utils";
import { DynamicAlertPopup } from "./DynamicAlertPopup";
import { useParams } from "react-router-dom";
import { ApiResponse, DropdownOptions, PathParams, UpdateCustomerPayload } from "../../interface/globalInterface";
import ScreenLoader from "./ScreenLoader";
import { UpdateCustomerInfo } from "../../api/user";


export function InactiveContactDetails(props: {
  data: {
    inactivePhone: ContactInformations[];
    activePhone: ContactInformations[];
    customerInformation: CustomerBasicInformation;
    bestTimeToCallOptions: DropdownOptions[];
    phoneTypeOptions: DropdownOptions[];
    communicationRoute: string;
  };
  function: {
    setInactivePhone: React.Dispatch<
      React.SetStateAction<ContactInformations[]>
    >;
    setActivePhone: React.Dispatch<React.SetStateAction<ContactInformations[]>>;
    onCloseFn: () => any;
  };
}) {
  const { customerId } = useParams<PathParams>();
  let activeContactInformation: ContactInformations[] = [];
  const {
    activePhone,
    inactivePhone,
    customerInformation,
    bestTimeToCallOptions,
    phoneTypeOptions,
    communicationRoute,
  } = props.data;
  const { setActivePhone, setInactivePhone, onCloseFn } = props.function;

  const [popupType, setPopupType] = useState<string>(EMPTY_STRING);
  const [popupMessage, setPopupMessage] = useState<string>(EMPTY_STRING);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);

  //Building active phone information
  const buildPhones = () => {
    const phonePropsArray: any[] = [];

    if (activeContactInformation && activeContactInformation.length > 0) {
      activeContactInformation.forEach((el: ContactInformations) => {

        const {
          extension,
          instructions,
          lastModifiedDate,
          lastModifiedBy,
          createdSource,
          createdDate,
          createdBy,
          lastModifiedSource,
          entPhoneId,...existingInformation}=el;


        existingInformation.phoneId = el.phoneId || EMPTY_STRING;
        existingInformation.phoneNumber = el.phoneNumber
          ? el.phoneNumber.replace(/\D/g, EMPTY_STRING)
          : EMPTY_STRING;
        existingInformation.phoneType = valueExistsInDropdown(
          phoneTypeOptions,
          el?.phoneType
        );
        existingInformation.phoneTypeDesc =
          el?.callTimeType && el.phoneType !== EMPTY_STRING
            ? el?.phoneTypeDesc
            : EMPTY_STRING;
        existingInformation.phoneExtension = el?.extension || EMPTY_STRING;
        existingInformation.callTimeType = valueExistsInDropdown(
          bestTimeToCallOptions,
          el.callTimeType
        );
        existingInformation.callTimeTypeDesc =
          el.callTimeType !== EMPTY_STRING
            ? el?.callTimeTypeDesc
            : EMPTY_STRING;
        existingInformation.active = el.active;
        existingInformation.note = el.instructions;
        existingInformation.primary = el.primary;

        if (
          (el.phoneId != "" &&
          el.phoneNumber != "") ||
          (el.phoneId == "" && el.phoneNumber != '')
        ) {
          phonePropsArray.push(existingInformation);
        }
      });
    }
    return phonePropsArray;
  };

  //Building the update customer information and making the api call below
  const updateContactInformation = async () => {
    try {
      const UpdateCustomerRequest: UpdateCustomerPayload = {
        customerId: customerId,
        globalCustomerId: customerInformation.globalCustomerId,
        firstName: customerInformation.firstName,
        lastName: customerInformation.lastName,
        middleInitial: customerInformation.middleInitial,
        coworker: customerInformation.racCoworker,
      };
      UpdateCustomerRequest.assignRoute = {
        storeNumber: LOGGED_IN_STORE,
        customerId: parseInt(customerId),
        routeRefCode: communicationRoute,
      };
      const activePhoneArray: any = buildPhones();
      if (activePhone && activePhone.length > 0) {
        UpdateCustomerRequest.phones = activePhoneArray;
      }

      setLoadingScreen(true);
      const payload: any = UpdateCustomerRequest;
      const updateResponse: ApiResponse = await UpdateCustomerInfo(
        payload,
        customerId
      );
      setLoadingScreen(false);

      if (
        validateApiResponse(updateResponse) &&
        updateResponse.data.UpdateCustomer?.value?.customerId &&
        updateResponse.data.UpdateCustomer?.value?.customerId == customerId
      ) {
        setPopupType("SUCCESS");
        setPopupMessage("Customer information is updated successfully");
      } else {
        setPopupMessage("Something went wrong");
        setPopupType("ERROR");
      }
      setShowAlert(true);
    } catch (error: any) {
      setLoadingScreen(false);
      setPopupType("ERROR");
      setPopupMessage("Something went wrong");
      setShowAlert(true);
      console.log("Error in updateContactInformation: ", error?.message);
    }
  };

  //Handle on Change and disable functionality are defined below
  const handleSaveDisability = (): boolean => {
    try {
      const editedIndex = inactivePhone?.findIndex(
        (phoneDetail: ContactInformations) =>
          phoneDetail.active === STRING_YES ||
          phoneDetail.primary === STRING_YES
      );

      if (editedIndex && editedIndex === -1) {
        return true;
      } else {
        return false;
      }
    } catch (error: any) {
      console.log("Error in handleSaveDisability: ", error?.message);
      return true;
    }
  };

  const handleActiveToggle = (index: number): void => {
    try {
      const deepCopiedContacts: ContactInformations[] = JSON.parse(
        JSON.stringify(inactivePhone)
      );
      if (deepCopiedContacts[index].active === STRING_NO) {
        deepCopiedContacts[index].active = STRING_YES;
      } else if (
        deepCopiedContacts[index].active === STRING_YES &&
        deepCopiedContacts[index].primary === STRING_NO
      ) {
        deepCopiedContacts[index].active = STRING_NO;
      } else if (
        deepCopiedContacts[index].active === STRING_YES &&
        deepCopiedContacts[index].primary === STRING_YES
      ) {
        setPopupMessage(
          "Unable to inactive. Customer must have atleast one Phone Number marked as Primary"
        );
        setShowAlert(true);
        setPopupType(EMPTY_STRING);
      }

      setInactivePhone(deepCopiedContacts);
    } catch (error: any) {
      console.log("Error in InactiveContactDetails: ", error?.message);
    }
  };

  const handlePrimaryToggle = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    try {
      const deepCopiedContacts: ContactInformations[] = JSON.parse(
        JSON.stringify(inactivePhone)
      );

      if (
        deepCopiedContacts[index].primary !== STRING_YES &&
        deepCopiedContacts[index].active === STRING_YES &&
        deepCopiedContacts[index].phoneTypeDesc !== "Work Phone"
      ) {
        deepCopiedContacts[index].primary = STRING_YES;
        deepCopiedContacts[index].primaryFlag = STRING_YES;
      } else if (
        deepCopiedContacts[index].primary !== STRING_YES &&
        deepCopiedContacts[index].active === STRING_NO &&
        deepCopiedContacts[index].phoneTypeDesc !== "Work Phone"
      ) {
        deepCopiedContacts[index].primary = STRING_YES;
        deepCopiedContacts[index].primaryFlag = STRING_YES;
        deepCopiedContacts[index].active = STRING_YES;
      } else if (
        deepCopiedContacts[index].primary === STRING_YES &&
        deepCopiedContacts[index].active === STRING_YES
      ) {
        setPopupMessage(
          "Unable to set inprimary. Customer must have atleast one Phone Number marked as Primary"
        );
        setPopupType(EMPTY_STRING);
        setShowAlert(true);
      }

      if (
        event.target.checked &&
        deepCopiedContacts.length > 1 &&
        deepCopiedContacts[index].primary == STRING_YES &&
        deepCopiedContacts[index].active === STRING_YES &&
        deepCopiedContacts[index].phoneTypeDesc !== "Work Phone"
      ) {
        for (let i = 0; i < deepCopiedContacts.length; i++) {
          if (i !== index) {
            deepCopiedContacts[i].primary = STRING_NO;
            deepCopiedContacts[i].primaryFlag = STRING_NO;
          }
        }
      }

      setInactivePhone(deepCopiedContacts);
    } catch (error: any) {
      console.log("Error in handlePrimaryToggle: ", error?.message);
    }
  };

  const handleCancelButton = () => {
    const deepCopiedContacts = JSON.parse(JSON.stringify(inactivePhone));
    deepCopiedContacts.map((val: ContactInformations) => {
      if (val.active === STRING_YES || val.primary === STRING_YES) {
        val.active = STRING_NO;
        val.primary = STRING_NO;
        setInactivePhone(deepCopiedContacts);
      }
    });

    onCloseFn();
  };

  const handleSaveButton = () => {
    try {
      const deepCopiedActiveContacts: ContactInformations[] = JSON.parse(
        JSON.stringify(activePhone)
      );

      const toggledActivePhones = inactivePhone.filter(
        (phoneInfo: ContactInformations) => phoneInfo.active === STRING_YES
      );

       toggledActivePhones.map((val: any, index: any) => {
         if (val.primaryFlag === "Y") {
           val.primary = "Y";
           deepCopiedActiveContacts.map((x: any) => (x.primary = "N"));
           setActivePhone(deepCopiedActiveContacts);
         }
       });


      const primaryPhone = toggledActivePhones.findIndex(
        (phoneInfo: ContactInformations) => phoneInfo.primary === STRING_YES
      );

      if (primaryPhone&&primaryPhone!=-1) {
        deepCopiedActiveContacts.map((phoneInfo: ContactInformations) => {
          phoneInfo.primary = STRING_NO;
          phoneInfo.primaryFlag= STRING_NO;
        });
      }

      const currentActivePhones = [
        ...deepCopiedActiveContacts,
        ...toggledActivePhones,
      ];

      activeContactInformation = currentActivePhones;
      setActivePhone(currentActivePhones);
      updateContactInformation();
      onCloseFn();
    } catch (error: any) {
      console.log("Error in inActivePopUpSaveBtn: ", error?.message);
    }
  };

  //Html functions for the Grid
  const bindGridHead = () => (
    <>
      {INACTIVE_CONTACT_GRID_HEADER.map((heading: any, index: any) => {
        return (
          <RACTableCell
            key={index}
            style={{ borderBottomStyle: "groove", marginRight: "15px" }}
          >
            {heading}
          </RACTableCell>
        );
      })}
    </>
  );

  const bindGridBody = (): any => {
    if (inactivePhone?.length) {
      return inactivePhone.map((obj: ContactInformations, index: any) => {
        return (
          <React.Fragment key={index}>
            <RACTableRow key={index} style={{ backgroundColor: "white" }}>
              <RACTableCell style={{ marginRight: "15px" }}>
                {obj.phoneNumber}
              </RACTableCell>
              <RACTableCell>{obj.extension ? obj.extension : "-"}</RACTableCell>
              <RACTableCell title={obj.callTimeTypeDesc}>
                {maskLengthyText(obj.callTimeTypeDesc, 15, "...")}
              </RACTableCell>
              <RACTableCell>{obj.phoneTypeDesc}</RACTableCell>
              <RACTableCell title={obj.instructions}>
                {maskLengthyText(obj.instructions, 15, "...")}
              </RACTableCell>
              <RACTableCell>
                <RACToggle
                  toggleColor="#0d6efd"
                  name={`IsActive_${index}`}
                  checked={obj?.active === STRING_YES ? true : false}
                  OnChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleActiveToggle(index);
                  }}
                />
              </RACTableCell>
              <RACTableCell>
                <RACToggle
                  toggleColor="#0d6efd"
                  name={`IsPrimary_${index}`}
                  checked={obj?.primary == "Y" ? true : false}
                  OnChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handlePrimaryToggle(event, index);
                  }}
                />
              </RACTableCell>
            </RACTableRow>
          </React.Fragment>
        );
      });
    } else if (inactivePhone?.length == 0) {
      return (
        <RACTableRow style={{ backgroundColor: "white" }}>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell>No InActive Phone Numbers !!</RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
          <RACTableCell></RACTableCell>
        </RACTableRow>
      );
    }
  };

  const inactivePhoneContent = () => {
    return (
      <>
        <Grid>
          <Grid style={{ overflow: "auto", height: "400px", width: "100%" }}>
            <RACTable
              renderTableHead={bindGridHead}
              renderTableContent={() => bindGridBody()}
              stickyHeader
            />
          </Grid>
          <Grid style={{ marginTop: "3%" }}>
            <RACButton
              variant="outlined"
              color="inherit"
              style={{ marginLeft: "75%", padding: "8px 26px" }}
              onClick={() => {
                handleCancelButton();
              }}
            >
              Cancel
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              style={{ float: "right", marginRight: "2%", padding: "8px 20px" }}
              disabled={handleSaveDisability()}
              onClick={() => {
                handleSaveButton();
              }}
            >
              Save
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {loadingScreen ? <ScreenLoader /> : null}

      {showAlert ? (
        <DynamicAlertPopup
          alertType={popupType}
          closeIcon={true}
          onCloseFn={() => {
            setShowAlert(false);
          }}
          alertMessage={popupMessage}
        />
      ) : (
        <RACModalCard
          borderRadius="20px"
          isOpen={true}
          maxWidth="md"
          title="Inactive Phone Numbers"
          closeIcon={true}
          onClose={() => {
            handleCancelButton();
          }}
          children={inactivePhoneContent()}
        />
      )}
    </>
  );
}
