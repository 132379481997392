/* eslint-disable */
/* eslint-disable sonarjs/no-duplicate-string */
import { RACCOLOR, makeStyles } from '@rentacenter/racstrap';
export const headerWizardStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    fullWidth: {
      width: '100%',
    },
    swThemeDefault: {
      width: '100%',
      position: 'relative',
    },
    swThemeDefaultContainer: {
      flex: '0 0 auto',
      width: '100%',
      background: `${RACCOLOR.WHITE}`,
    },
    smartWizard: {
      display: 'inline-block',
      listStyle: 'none',
    },
    smartWizardList: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      display: 'inline-block',
      '&:after': {
        borderBottom: '2px solid #cecece',
        content: '""',
        position: 'absolute',
        width: '50px',
        right: '-30px',
        top: '50%',
      },
      '&:last-child': {
        '&:after': {
          border: 'none',
        },
      },
    },
    smartWizardListLinkActive: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      color: `${RACCOLOR.DEEP_SKY_BLUE}`,
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    smartWizardListLinkInActive: {
      textAlign: 'center',
      position: 'relative',
      marginRight: theme.typography.pxToRem(20),
      width: 'auto',
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      color: '#8e8e8e',
    },
    borderActive: {
      border: '2px solid #d9d9d9',
      padding: '4px 10px',
      margin: '0 5px',
      backgroundColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderColor: `${RACCOLOR.DEEP_SKY_BLUE}`,
      borderRadius: '50px',
      color: `${RACCOLOR.WHITE}`,
    },
    borderDefault: {
      border: '1px solid #d9d9d9',
      padding: '4px 10px',
      borderRadius: '50px',
      margin: '0 5px',
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};
