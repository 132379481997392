/* eslint-disable */
import React, { useContext } from "react";
import { Grid } from "@rentacenter/racstrap";
import {
  ContactInfoHeaderProps,
  ReferenceDetailsProps
} from "../../../interface/globalInterface";
import ContactInfoHeader from "../ContactInfoHeader";
import ReferenceDetailsContainer from "./ReferenceDetailsContainer";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";

export default function ReferenceInformation(props: {
  value: ReferenceDetailsProps;
}) {
  const { referenceInfo, setReferenceInfo } = props.value;
  const { referenceErrorTitle } = useContext(UpdateCustomerContext);
  const classes = globalStyles();
  const index = 0;
  const contentHeading: ContactInfoHeaderProps = {
    heading: "Reference Information",
    popupHeading: referenceErrorTitle,
    popupFunctionality: null,
    params: null,
    infoIcon: true,
    infoIconContent: "Either 1 family member reference OR 2 non-family members",
  };

  return (
    <Grid container className={`${classes.pt05p}`}>
      <Grid container>
        <ContactInfoHeader value={contentHeading} />
        <ReferenceDetailsContainer
          setterData={{ index, referenceInfo, setReferenceInfo }}
        />
      </Grid>
    </Grid>
  );
}
