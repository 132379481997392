/* eslint-disable */
/* eslint-disable no-constant-condition */
/* eslint-disable prettier/prettier */
import { Card, CardContent, Grid, RACButton, RACDatePicker, RACSelect, RACTextbox, Typography } from "@rentacenter/racstrap";
import { manageCustomerStyles } from "../../../jsStyles/manageCustomerStyles";
import { DropdownOptions, VehicleInfomation, VehicleInfoProps } from "../../../interface/globalInterface";
import moment from "moment";
import { API_ERROR_MESSAGE, dateFormat } from "../../../constants/constants";
import React from "react";
import { formatTextChange, isDropDownValueExist } from "../../../utils/utils";


export function VehicleInfo(props: VehicleInfoProps) {
    const classes = manageCustomerStyles();

    const { Agrtransferinit, vehicleInformation, dropDownLoaded, stateInfo } = props.stateVariables;
    const { setcustomerInfoChange, setVehicleInformation } = props.setterFuncton;
    const vehicleTemplate: VehicleInfomation = {
        make: '',
        model: '',
        year: '',
        color: '',
        vehicleVin: '',
        licensePlate: '',
        regState: '',
        planExpirationDate: '',
        financedFrom: '',
        monthlyPayment: '0.00',
    }

    // For adding the vehicle template in vehicleinformation array
    const addVehicleGrid = () => {
        const addTemplate = vehicleInformation;
        addTemplate.push(vehicleTemplate);
        setVehicleInformation([...vehicleInformation]);
    };

    // For removing the vehicle template in vehicleinformation array
    const removeVehicleGrid = (index: number) => {
        const removeVehicleTemplate = vehicleInformation;
        removeVehicleTemplate.splice(index, 1);
        setVehicleInformation([...vehicleInformation]);
    };


    // Function for building the vehicle year options for year dropdown
    const buildVehicleYearOptions = (): DropdownOptions[] => {
        const currentDate = new Date();
        const startYear = currentDate.getFullYear() - 50;
        const vehicleYearOptions: DropdownOptions[] = [];
        for (let year = currentDate.getFullYear(); year > startYear; year--) {
            if (year === currentDate.getFullYear()) {
                vehicleYearOptions.push({
                    value: "0",
                    label: "Select",
                });
            }
            vehicleYearOptions.push({
                value: year.toString(),
                label: year.toString(),
            });
        }
        return vehicleYearOptions;
    };

    // For handling onchange functionality of 
    const handleChangeForAddVehicleInfo = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setcustomerInfoChange(true);
        const { name, value } = e.target;
        const [fieldName, index] = name.split('_');
        const vehicleIndex = parseInt(index, 10);

        switch (fieldName) {
            case 'make':
                vehicleInformation[vehicleIndex].make = formatTextChange(value);
                break;
            case 'model':
                vehicleInformation[vehicleIndex].model = formatTextChange(value);
                break;
            case 'year':
                vehicleInformation[vehicleIndex].year = value;
                break;
            case 'color':
                vehicleInformation[vehicleIndex].color = formatTextChange(value);
                break;
            case 'vin':
                vehicleInformation[vehicleIndex].vehicleVin = formatTextChange(value);
                break;
            case 'tag':
                vehicleInformation[vehicleIndex].licensePlate = formatTextChange(value);
                break;
            case 'tagState':
                vehicleInformation[vehicleIndex].regState = value;
                break;
            case 'whereIsAutoFinance':
                vehicleInformation[vehicleIndex].financedFrom = value;
                break;
            case 'monthlyAutoPayment':
                vehicleInformation[vehicleIndex].monthlyPayment = value;
                break;
            default:
                break;
        }
        setVehicleInformation([...vehicleInformation]);
    };

    // For handling the onchange functionality of Tag Expiration Date field
    const handleExpirationDate = (e: any, index: number) => {
        const InputTxt = e;
        const format = moment(InputTxt).format(dateFormat);
        const temp = InputTxt.replace(format);
        vehicleInformation[index].planExpirationDate = temp;
        setVehicleInformation([...vehicleInformation]);
    };

    // For returning text box element
    const textBox = (index: number, data: string, label: string, max: number, name: string) => (
        <Grid item md={3} lg={2}>
            <RACTextbox
                isCurrency={false}
                disabled={Agrtransferinit}
                type="text"
                inputlabel={label}
                name={`${name}_${index}`}
                OnChange={(e) => handleChangeForAddVehicleInfo(e)}
                maxlength={max}
                value={data}
            />
        </Grid>
    )

    // For renturning dropdown element
    const dropdown = (index: number, options: DropdownOptions[], label: string, name: string, defaultValue: string,
        loading: boolean, condition: boolean) => (
        <Grid item md={3} lg={2}>
            <RACSelect
                inputLabel={label}
                isDisabled={Agrtransferinit}
                options={options}
                defaultValue={defaultValue}
                loading={loading}
                onChange={(e) => {
                    e.target.name = `${name}_${index}`;
                    handleChangeForAddVehicleInfo(e);
                }}
                {...(condition && {
                    errorMessage: API_ERROR_MESSAGE,
                })}
            />
        </Grid>
    )

    // For returning the datePicker element
    const datePicker = (index: number, data: string) => (
        <Grid item md={3} lg={2}>
            <RACDatePicker
                value={data}
                label="Tag Expiration Date"
                id={`tagExpirationDateDiv_${index}`}
                name={`tagExpirationDate_${index}`}
                disabled={Agrtransferinit}
                onChange={(e) => handleExpirationDate(e, index)}
            />
        </Grid>
    )

    // For returning currency element field
    const currency = (index: number, data: string) => (
        <Grid item md={2} lg={2} className={classes.pt0}>
            <RACTextbox
                isCurrency={true}
                type={'number'}
                digitFormat={'currency'}
                disabled={Agrtransferinit}
                inputlabel="Monthly Auto Payment"
                id={`monthlyAutoPaymentDiv_${index}`}
                name={`monthlyAutoPayment_${index}`}
                OnChange={(e) => handleChangeForAddVehicleInfo(e)}
                maxlength={10}
                value={data}
                className={classes.custDigit}
                dollarTextClassName={classes.currencyDollarField}
            />
        </Grid>
    )

    // For returning the button element
    const button = (index: number) => (
        <Grid item md={1} lg={4} className={`${classes.mt24} ${classes.textCenter}`}>
            <RACButton
                variant="outlined"
                color="primary"
                className={classes.foc}
                onClick={() => removeVehicleGrid(index)}
            >
                Cancel
            </RACButton>
        </Grid>
    )

    // For rendering the html component for vehicle information tab
    const buildVechicleInfo = () => {
        return vehicleInformation?.map((data: VehicleInfomation, index: number) => {

            const vehicleOptions = buildVehicleYearOptions();
            const year = isDropDownValueExist(vehicleOptions, data?.year) ? data?.year : '0';
            const vehicleLoading = vehicleOptions.length == 0 && !dropDownLoaded ? true : false;
            const vehicleCondition = vehicleOptions.length == 0 && dropDownLoaded;

            const stateValue = isDropDownValueExist(stateInfo, data?.regState) ? data?.regState : '0';
            const stateLoading = stateInfo.length == 0 && !dropDownLoaded ? true : false;
            const stateCondition = stateInfo.length == 0 && dropDownLoaded;

            return (
                <Grid container key={index}>
                    <Card className={`${classes.card} ${classes.w100} ${classes.mb8}`}>
                        <CardContent>
                            <Grid container spacing={2}>
                                {textBox(index, data?.make, "Make", 15, "make")}
                                {textBox(index, data?.model, "Model", 14, "model")}
                                {dropdown(index, vehicleOptions, "Year", "year", year, vehicleLoading, vehicleCondition)}
                                {textBox(index, data?.color, "Color", 20, "color")}
                                {textBox(index, data?.vehicleVin, "VIN#", 20, "vin")}
                                {textBox(index, data?.licensePlate, "Tag#", 15, "tag")}
                                {dropdown(index, stateInfo, "Tag state", "tagState", stateValue, stateLoading, stateCondition)}
                                {datePicker(index, (data?.planExpirationDate || ''))}
                                {textBox(index, data?.financedFrom, "Where is Auto Financed?", 30, "whereIsAutoFinance")}
                                {currency(index, (data?.monthlyPayment || '0.00'))}
                                {index != 0 && (!data?.vehicleId) ? (button(index)) : null}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            );
        });
    };

    // vehicleInformation Information component return part
    return (
        <>
            <Grid container id={'vehicleinfo'} className={`${classes.mb100} ${classes.px2}`}>
                <Grid item md={12}>
                    <Typography className={classes.title}>
                        vehicle Information
                    </Typography>
                    {buildVechicleInfo()}
                </Grid>
                <Grid item md={12} className={classes.mb16}>
                    <a
                        className={Agrtransferinit ? `${classes.racpadLink} ${classes.pointerEvent}` : `${classes.racpadLink}`}
                        onClick={() => addVehicleGrid()}
                    >
                        + Add another vehicle
                    </a>
                </Grid>
            </Grid>
        </>
    )
}