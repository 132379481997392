/* eslint-disable */
import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export const getMaskedValue = (val: string) => {
  if (val.length <= 4) {
    return val;
  }
  const maskedPart = '*'.repeat(val.length - 4);
  const visiblePart = val.slice(-4);
  return `${maskedPart}${visiblePart}`;
};
