/* eslint-disable */
import React, { useContext, useEffect } from "react";
import {
  CONTACT_INFORMATION_TEMPLATE,
  EMPTY_STRING,
} from "../../../constants/constants";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";
import { Legal } from "../microComponents/Legal";
import { CommunicationPreference } from "../../sharedComponents/CommunicationPreference";
import { BasicInformation } from "../../sharedComponents/BasicInformation";
import AddressInformation from "../../sharedComponents/AddressInformation/AddressInformation";
import { TaxExempt } from "../../sharedComponents/TaxExempt";
import { ContactInformation } from "../../sharedComponents/ContactInformation";
import { Grid } from "@rentacenter/racstrap";
import { CustomerAlerts } from "../microComponents/CustomerAlerts";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { SkipHardStatus } from "../../../interface/updateCustomerInterface";

export default function CustomerInformation(): JSX.Element {
  const {
    setScreenLoader,
    agreementInformation,
    setShowTransferAgreemnt,
    communicationInformation,
    setCommunicationInformation,
    setCustomerBasicInformation,
    saluationOptions,
    skipStolenHardStatus,
    setSkipStolenHardStatus,
    legalInformation,
    legalHoldOptions,
    routeOptions,
    methodOfContactOptions,
    editedAddress,
    setEditedAddress,
    activeAddress,
    setActiveAddress,
    inActiveAddress,
    suffixOptions,
    stateOptions,
    govtIDTypeOptions,
    customerBasicInformation,
    showCustomerInfo,
    setShowCustomerInfo,
    taxExemptInformation,
    setTaxexemptInformation,
    setShowDecryptedValues,
    showDecryptedValues,
    contactInformation,
    setContactInformation,
    bestTimeToCallOptions,
    phoneTypeOptions,
    updatedCustomerBasicInfo,
    setUpdatedCustomerBasicInfo,
    updatedCommunicationInformation,
    setUpdatedCommunicationInformation,
    primaryAddress,
    setIsCustomerInfoUpdate,
    isMaskedDataHasChanged,
    setisMaskedDataHasChanged,
    setInactivePhone,
    loadFaxSheet,
    setLoadFaxSheet,
    inactivePhone,
    taxStatusOptions,
    screenLoader,
    taxToggle,
    setTaxToggle,
    federalTaxId,
    isRequired,
    setUpdatedContactInfo,
    setContactInfoErrorMessage,
    updatedContactInfo,
    contactInfoErrorMessage,
    govIdFocused,
    setGovIdFocused,
    faxSheetDetails,
    setFaxSheetDetails,
    currentRole,
    legalHoldedScreen,
    addressErrorMsg,
    suggestedAddress,
    defaultAddress,
    setDefaultAddress,
    matchCode,
    ValidateIsOpen,
    setValidateIsOpen,
    validatedAddresses,
    canSuggestAddress,
    setCanSuggestAddress,
    AddressDoc,
    BtnIsdisabled1,
    setBtnIsdisabled1,
    BtnIsdisabled2,
    setBtnIsdisabled2,
    cantSuggestAddress,
    setCantSuggestAddress,
    addressIndex
  } = useContext(UpdateCustomerContext);

  useEffect(() => {
    if (agreementInformation?.agreementTransferApplicable) {
      setShowTransferAgreemnt(true);
    }
  }, []);
  const address = {
    activeAddresses: activeAddress,
    inActiveAddresses: inActiveAddress,
  };

  const addressValidationPayload = {
    addressErrorMsg, suggestedAddress,
    defaultAddress, setDefaultAddress, matchCode,
    ValidateIsOpen, setValidateIsOpen, validatedAddresses,
    canSuggestAddress, setCanSuggestAddress,
    AddressDoc, BtnIsdisabled1, setBtnIsdisabled1,
    BtnIsdisabled2, setBtnIsdisabled2,
    cantSuggestAddress, setCantSuggestAddress, addressIndex,
    setIsCustomerInfoUpdate, customerBasicInformation,
    setCustomerBasicInformation, setScreenLoader
  }

  useEffect(() => {
    setUpdatedContactInfo(contactInformation);
    setContactInfoErrorMessage(Array(contactInformation.length).fill({ ...CONTACT_INFORMATION_TEMPLATE }));
    // console.log('Customer Basic Info', customerBasicInformation, updatedCustomerBasicInfo);
  }, [contactInformation]);
  const classes = globalStyles();

  return (
    <>
      <Grid container className={`${classes.pt05p}`}>
        {!legalHoldedScreen ? <Grid item md={12}>
          <CustomerAlerts />
        </Grid> : null}

        <Grid item md={12} style={{ marginTop: "15px" }}>
          <BasicInformation
            data={{
              dropdownLoader: screenLoader,
              coworkerRole: currentRole,
              showDecryptedValues: showDecryptedValues,
              customerId: EMPTY_STRING,
              dropdownsData: {
                govtIDType: govtIDTypeOptions,
                state: stateOptions,
                salutation: saluationOptions,
                suffix: suffixOptions,
              },
              agreementTransferInit: agreementInformation.transferInitiated,
              requiredFieldExists: isRequired,
              basicInformation: customerBasicInformation,
              showCustomerInfo: showCustomerInfo,
              updatedBasicInfo: updatedCustomerBasicInfo,
              isMaskedDataHasChanged: isMaskedDataHasChanged,
              govIdFocused: govIdFocused,
              legalHoldScreen: legalHoldedScreen
            }}
            function={{
              setBasicInformation: setCustomerBasicInformation,
              setScreenLoader: setScreenLoader,
              setShowCustomerInfo: setShowCustomerInfo,
              setShowDecryptedValues: setShowDecryptedValues,
              setUpdatedBasicInfo: setUpdatedCustomerBasicInfo,
              setIsCustomerInfoUpdate: setIsCustomerInfoUpdate,
              setisMaskedDataHasChanged: setisMaskedDataHasChanged,
              setGovIdFocused: setGovIdFocused,
            }}
          />
        </Grid>

        {!legalHoldedScreen ? <Grid item md={12} style={{ marginTop: "15px" }} id="addressDiv">
          <AddressInformation
            value={address}
            setterData={{
              editedAddress,
              setEditedAddress,
              setActiveAddress,
              primaryAddress,
            }}
            addressValidationPayload={addressValidationPayload}
          />
        </Grid> : null}

        {!legalHoldedScreen ? <Grid item md={12} style={{ marginTop: "15px" }} id="ParentContactInformation">
          <ContactInformation
            data={{
              activeContactInformation: contactInformation,
              inactiveContactInformation: inactivePhone,
              agreementTransferInit: agreementInformation.transferInitiated,
              requiredField: isRequired,
              dropdownLoader: screenLoader,
              route: communicationInformation.route,
              basicInformation: customerBasicInformation,
              updatedContactInfo,
              contactInfoErrorMessage,
            }}
            dropdown={{
              phoneTypeOptions: phoneTypeOptions,
              bestTimeToCallOptions: bestTimeToCallOptions,
            }}
            function={{
              setActiveContactInformation: setContactInformation,
              setInactiveContactInformation: setInactivePhone,
              setUpdatedContactInfo: setUpdatedContactInfo,
              setContactInfoErrorMessage: setContactInfoErrorMessage,
            }}
          />
        </Grid> : null}

        <Grid item md={12} style={{ marginTop: "15px" }}>
          <CommunicationPreference
            data={{
              agreementTransferInitiated:
                agreementInformation.transferInitiated,
              dropdownLoader: screenLoader,
              communicationInformation: communicationInformation,
              updatedCommunicationInformation: updatedCommunicationInformation,
              currentRole: currentRole
            }}
            dropdowns={{
              routeOptions: routeOptions,
              methodOfContactOptions: methodOfContactOptions,
            }}
            function={{
              setCommunicationInformation: setCommunicationInformation,
              setUpdatedCommunicationInformation:
                setUpdatedCommunicationInformation,
              setScreenLoader: setScreenLoader,
            }}
          />
        </Grid>

        <Grid item md={12} style={{ marginTop: "15px" }} id="LegalDiv">
          <Legal
            legalHoldStatus={legalInformation.legalHoldStatus || false}
            skip={skipStolenHardStatus.skip || false}
            hard={skipStolenHardStatus.hard || false}
            legalHoldReason={legalInformation.refCode || EMPTY_STRING}
            legalHoldDropdown={legalHoldOptions}
            dropdownLoader={screenLoader}
            currentRole={currentRole}
            setSkipStolenHardStatus={(status: Partial<SkipHardStatus>) => setSkipStolenHardStatus({ ...skipStolenHardStatus, ...status })}
            setScreenLoader={setScreenLoader}
          />
        </Grid>

        <Grid item md={12} style={{ marginTop: "15px" }}>
          <TaxExempt
            data={{
              customerInformation: customerBasicInformation,
              taxExemptInformation: taxExemptInformation,
              federalTaxId: federalTaxId,
              requiredField: isRequired,
              dropdownLoader: screenLoader,
              agreementTransferInit: agreementInformation.transferInitiated,
              toggle: taxToggle,
              faxSheetLoading: loadFaxSheet,
              faxSheetDetails: faxSheetDetails,
              dropdownOption: taxStatusOptions,
            }}
            function={{
              setToggle: setTaxToggle,
              setTaxExemptInformation: setTaxexemptInformation,
              setFaxSheetLoading: setLoadFaxSheet,
              setFaxSheetDetails: setFaxSheetDetails
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}