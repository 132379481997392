/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */

import React from "react";
import {
  Grid,
  RACButton,
  RACCheckBox,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { EmployersInfoProps } from "../../../interface/globalInterface";
import { formatText, formatZipCode, removeObjectFromArray, updateEmploymentDetails } from "../Utils";
import { dropdownOptions } from "../../../constants/constants";

export default function EmployerAddressInfo({value, setterData} : EmployersInfoProps) {
  const classes = globalStyles();
  const Agrtransferinit = false;
  return (
    <Grid container spacing={2} className={`${classes.mt10px}`}>
      <Grid item md={12}>
        <Typography
          className={`${classes.fontOpensansBold} ${classes.fontSize16px}`}
        >
          Address Information
        </Typography>
      </Grid>
      <Grid item md={3} lg={2} className={classes.pt0}>
        <RACTextbox
          isCurrency={false}
          required={false}
          type={
            setterData.employerDetails[setterData.index].addressInfo.doNotVisit
              ? "password"
              : "text"
          }
          inputlabel={"Address Line 1"}
          placeHolder={"Address Line 1"}
          OnChange={(e: any) =>
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                addressInfo: {
                  ...setterData.employerDetails[setterData.index].addressInfo,
                  addressLine1: formatText(e.target.value),
                },
              }
            )
          }
          maxlength={30}
          value={value.addressInfo.addressLine1}
          disabled={
            Agrtransferinit
              ? true
              : setterData.employerDetails[setterData.index].addressInfo
                  .doNotVisit ||
                !setterData.employerDetails[setterData.index].active
              ? true
              : false
          }
        />
      </Grid>
      <Grid item md={3} lg={2} className={classes.pt0}>
        <RACTextbox
          type={
            setterData.employerDetails[setterData.index].addressInfo.doNotVisit
              ? "password"
              : "text"
          }
          inputlabel="Address Line 2"
          infoIcon={true}
          infoTitle={"Address 2, APT #/Unit #, P.O.Box"}
          placeHolder={"Address 2, APT #/Unit #, P.O.Box"}
          OnChange={(e: any) =>
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                addressInfo: {
                  ...setterData.employerDetails[setterData.index].addressInfo,
                  addressLine2: formatText(e.target.value),
                },
              }
            )
          }
          maxlength={30}
          value={value.addressInfo.addressLine2}
          disabled={
            Agrtransferinit
              ? true
              : setterData.employerDetails[setterData.index].addressInfo
                  .doNotVisit ||
                !setterData.employerDetails[setterData.index].active
              ? true
              : false
          }
        />
      </Grid>
      <Grid item md={3} lg={2} className={classes.pt0}>
        <RACTextbox
          isCurrency={false}
          required={false}
          type={
            setterData.employerDetails[setterData.index].addressInfo.doNotVisit
              ? "password"
              : "text"
          }
          inputlabel="Zip"
          placeHolder="Zip Code"
          OnChange={(e: any) =>
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                addressInfo: {
                  ...setterData.employerDetails[setterData.index].addressInfo,
                  zip: e.target.value,
                },
              }
            )
          }
          maxlength={10}
          value={formatZipCode(value.addressInfo.zip)}
          disabled={
            Agrtransferinit
              ? true
              : setterData.employerDetails[setterData.index].addressInfo
                  .doNotVisit ||
                !setterData.employerDetails[setterData.index].active
              ? true
              : false
          }
          // errorMessage={
          //   data?.postalCode != undefined &&
          //   data?.postalCode != null &&
          //   data?.postalCode != "" &&
          //   data?.active == "Y" &&
          //   isrequired === true &&
          //   data?.postalCode.length > 0 &&
          //   data?.postalCode.length != 5 &&
          //   data?.postalCode.length != 10
          //     ? INVALID_ZIP
          //     : ""
          // }
        />
      </Grid>
      <Grid item md={3} lg={2} className={classes.pt0}>
        <RACTextbox
          isCurrency={false}
          required={false}
          type={
            setterData.employerDetails[setterData.index].addressInfo.doNotVisit
              ? "password"
              : "text"
          }
          inputlabel="City"
          placeHolder="City"
          OnChange={(e: any) =>
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                addressInfo: {
                  ...setterData.employerDetails[setterData.index].addressInfo,
                  city: formatText(e.target.value),
                },
              }
            )
          }
          maxlength={30}
          value={value.addressInfo.city}
          disabled={
            Agrtransferinit
              ? true
              : setterData.employerDetails[setterData.index].addressInfo
                  .doNotVisit ||
                !setterData.employerDetails[setterData.index].active
              ? true
              : false
          }
        />
      </Grid>
      <Grid item md={4} lg={1} className={classes.pt0}>
        <RACSelect
          inputLabel="State"
          onChange={(e: any) =>
            updateEmploymentDetails(
              setterData.index,
              setterData.employerDetails,
              setterData.setEmployerDetails,
              {
                addressInfo: {
                  ...setterData.employerDetails[setterData.index].addressInfo,
                  state: e.target.value,
                },
              }
            )
          }
          options={dropdownOptions}
          isDisabled={Agrtransferinit ? true : !value.active}
          defaultValue={value.addressInfo.state ? value.addressInfo.state : "0"}
          loading={dropdownOptions.length == 0}
        />
      </Grid>
      <Grid item md={3} lg={2} className={`${classes.pt0} ${classes.mt0px}`}>
        <Grid
          item
          className={`${classes.alignCheckBox} ${classes.mt19pxRem} ${classes.floatLeft} ${classes.pl0}`}
        >
          <RACCheckBox
            label="Do Not Visit"
            size="small"
            onChange={(e: any) =>
              updateEmploymentDetails(
                setterData.index,
                setterData.employerDetails,
                setterData.setEmployerDetails,
                {
                  addressInfo: {
                    ...setterData.employerDetails[setterData.index].addressInfo,
                    doNotVisit: e.target.checked,
                  },
                }
              )
            }
            disabled={Agrtransferinit}
            checked={
              setterData.employerDetails[setterData.index].addressInfo
                .doNotVisit
                ? true
                : false
            }
          />
        </Grid>
      </Grid>
      {!setterData.employerDetails[setterData.index].employerDetailsId  ? (
        <Grid
          item
          md={2}
          lg={1}
          className={`${classes.pt0} ${classes.mt16pxRem}`}
        >
          <RACButton
            variant="outlined"
            color="primary"
            className={classes.focusButton}
            onClick={() => 
              removeObjectFromArray(setterData.employerDetails, setterData.index, setterData.setEmployerDetails)
            }
          >
            Cancel
          </RACButton>
        </Grid>
      ) : null}
    </Grid>
  );
}
