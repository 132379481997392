/* eslint-disable */

import React, { useContext } from "react";
import {
  Grid,
  RACCheckBox,
  RACDatePicker,
  RACTextbox,
} from "@rentacenter/racstrap";
import { globalStyles } from "../../../jsStyles/globalStyles";
import { ResidenceInfoProps } from "../../../interface/globalInterface";
import { formatText, updateResidenceInfo } from "../Utils";
import { UpdateCustomerContext } from "../../../context/UpdateCustomerContext";

export default function OwnResidenceInfo({
  value,
  setterData,
}: ResidenceInfoProps) {
  console.log("OwnResidenceInfoOwnResidenceInfo", value)
  console.log("setterDatasetterData", setterData)
  const classes = globalStyles();
  const Agrtransferinit = false;
  const { residenceInfo } = setterData;
  const { residenceEditClicked, residenceInfoCopy } = useContext(UpdateCustomerContext)

  return (
    <Grid container>
      <Grid container className={`${classes.width100p} ${classes.mt10px}`}>
        <Grid container md={12}>
          <Grid container md={12} spacing={2}>
            <Grid item md={2}>
              <RACCheckBox
                id="mortgage"
                disabled={!residenceEditClicked}
                checked={residenceInfo.mortgage}
                onChange={(e: any) => {
                  updateResidenceInfo(
                    setterData.residenceInfo,
                    setterData.setResidenceInfo,
                    {
                      mortgage: e.target.checked,
                      verifiedDate: '',
                      ownResidenceDetails: {
                        ...setterData.residenceInfo.ownResidenceDetails
                      }
                    }
                  );
                }}
                label="Mortgage"
              />
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid item md={2} className={` ${classes.mt16pxRem}`}>
              <RACDatePicker
                value={residenceEditClicked ? value?.ownResidenceDetails.moveInDate : residenceInfoCopy?.ownResidenceDetails.moveInDate}
                label="Move in Date"
                disabled={!residenceEditClicked}
                onChange={(e: any) =>
                  updateResidenceInfo(
                    setterData.residenceInfo,
                    setterData.setResidenceInfo,
                    {
                      verified: false,
                      verifiedDate: '',
                      ownResidenceDetails: {
                        ...setterData.residenceInfo.ownResidenceDetails,
                        moveInDate: e,
                      },
                    }
                  )
                }
              />
            </Grid>
            {value.mortgage ? (
              <Grid container md={10} spacing={2}>
                <Grid item md={2} className={`${classes.ml10px} ${classes.mt16pxRem}`}>
                  <RACTextbox
                    isCurrency={false}
                    required={true}
                    type={"text"}
                    disabled={!residenceEditClicked}
                    inputlabel="Mortgage Company Name"
                    name={`llnumberCompany`}
                    OnChange={(e: any) =>
                      updateResidenceInfo(
                        setterData.residenceInfo,
                        setterData.setResidenceInfo,
                        {
                          verified: setterData.residenceInfo.rentalType == 'RENT' ? false : setterData.residenceInfo.verified,
                          verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                          ownResidenceDetails: {
                            ...setterData.residenceInfo.ownResidenceDetails,
                            mortgageCompanyName: formatText(e.target.value),
                          },
                        }
                      )
                    }
                    maxlength={30}
                    value={residenceEditClicked ? value.ownResidenceDetails?.mortgageCompanyName : residenceInfoCopy?.ownResidenceDetails?.mortgageCompanyName}
                    errorMessage={
                      value?.residenceInfoError?.mortgageCompanyNameError ? "Required" : ''
                    }
                  />
                </Grid>
                <Grid item md={2} className={`${classes.mt16pxRem}`}>
                  <RACTextbox
                    isCurrency={true}
                    type={"number"}
                    digitFormat={"currency"}
                    disabled={!residenceEditClicked}
                    inputlabel="Payment Amount"
                    OnChange={(e: any) =>
                      updateResidenceInfo(
                        setterData.residenceInfo,
                        setterData.setResidenceInfo,
                        {
                          verified: setterData.residenceInfo.rentalType == 'RENT' ? false : setterData.residenceInfo.verified,
                          verifiedDate: setterData.residenceInfo.rentalType == 'OWN' ? '' : setterData.residenceInfo.verifiedDate,
                          ownResidenceDetails: {
                            ...setterData.residenceInfo.ownResidenceDetails,
                            paymentAmount: e.target.value,
                          },
                        }
                      )
                    }
                    maxlength={10}
                    value={residenceEditClicked ? value.ownResidenceDetails?.paymentAmount ? value.ownResidenceDetails?.paymentAmount : '' : residenceInfoCopy?.ownResidenceDetails.paymentAmount ? residenceInfoCopy?.ownResidenceDetails?.paymentAmount : ''}
                    className={classes.currentTextBoxStyle}
                    dollarTextClassName={classes.customerDollerInputFiled}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
