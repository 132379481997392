/* eslint-disable */
import { Card, CardContent, CircularProgress, Grid, RACCheckBox, RACTable, RACTableCell, RACTableRow, RACTooltip, Typography } from "@rentacenter/racstrap";
import { manageCustomerStyles } from "../../../../jsStyles/manageCustomerStyles";
import React, { useContext, useEffect, useState } from "react";
import { UpdateCustomerContext } from "../../../../context/UpdateCustomerContext";
import { getAgreementHistory, getFeatureFlagInfo } from "../../../../apiHandler/updateCustomerApiHandler";
import { useParams } from "react-router-dom";
import { agreementHistoryHeader } from "../../../../constants/constants";
import { ReactComponent as AccordianOpenIcon } from '../../../../../assets/images/Accordion-down.svg'
import { ReactComponent as AccordianCloseIcon } from '../../../../../assets/images/Accordion-close.svg';
import { FeatureFlagContext } from "../../../../../context/FeatureFlagContext";
import { ReactComponent as ExchangeIcon } from '../../../../../assets/images/Exchange.svg';
import { ReactComponent as Alerticon } from '../../../../../assets/images/No-records.svg';

import moment from "moment";
import { ClubInfoResponse, ConfigurationsRequest, GetAgreement } from "../../../../interface/globalInterface";
import { CheckboxClick, filterAgreement, formatValue, getClass, getTableStyles, processAgreementData, processFeatureFlags, processSplitAgrInfo, validateForExchange } from "./AgreementHistoryUtils";
import SecondFactor from "../../../../../racstrapComponent/manageCustomer/secondAuthen";
import RACExchange from "../../../../../racstrapComponent/customerComponent/RACExchange";

export function AgreementHistory() {
    const classes = manageCustomerStyles();
    const { isExchangeEnabled, setExchangeEnabled, exchangePeriod, setExchangePeriod,
        returnPeriod, setReturnPeriod, racExchangeFuncFlag, setRacExchangeFuncFlag,
        agreementInfo, setagreementInfo, setSplitCoCustomerAvailble,
        setsplitEligibleAgrs, setGetAgrbyIdresponse, configurations,
        Agrtransferinit, activeExpand, setactiveExpand, inActiveExpand, setinActiveExpand,
        pending, setpending, reInstate, setreInstate, agrCheckBoxId, setagrCheckBoxId,
        setcheckAction, currentRole, setRentPaid, setAgreementId, setExchangeAgreementNumber, 
        setSuspenseAmount, agrHistoryPageDisplay, setAgrHistoryPageDisplay, setShowSplit,
        rentPaid, exchangeAgreementId, exchangeNavigationPopup, setExchangeNavigationPopup,
        exchangeAgreementNumber, suspenseAmount
    } = useContext(UpdateCustomerContext);
    const { featureFlagDetails } = useContext(FeatureFlagContext);
    const { customerId } = useParams<{ customerId: string }>();
    const [showAuth, setShowAuth] = useState<string>('')

    useEffect(() => {
        // If Agreement History data is already available in the context variable, then API call will be skipped.
        if (agreementInfo && agreementInfo.length == 0 && agrHistoryPageDisplay !== 200) {
            getAgreement()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // For fetching the Active and Inactive agreements of the customer 
    async function getAgreement(): Promise<void> {
        try {
            const storeNumber = window.sessionStorage.getItem("storeNumber");
            // Loader starts
            setAgrHistoryPageDisplay(1);

            const payload = {
                storeNumbers: [String(storeNumber)],
                paramKeyNames: [
                    'AgreementExchange',
                    'AgreementExchangePeriod',
                    'AgreementExchangeReturnPeriod',
                    'RACExchange'
                ],
            };

            // Fetching feature flags and agreement history
            const [featureFlagInfo, agreementHistory, activeAgreementHistory] = await fetchAgreements(storeNumber, payload);

            // Loader ends
            setAgrHistoryPageDisplay(200);

            // Process feature flags
            processFeatureFlags(featureFlagInfo, setExchangeEnabled,
                setExchangePeriod, setReturnPeriod, setRacExchangeFuncFlag);

            // Process agreement data
            const agreements = processAgreementData(agreementHistory, activeAgreementHistory,
                setGetAgrbyIdresponse, setagreementInfo, configurations.reinstatementAllowed);

            // Filter active and pending agreements
            const activeAgreements = agreements.filter(agreement =>
                agreement.agreementStatus === 'Active' || agreement.agreementStatus === 'Pending'
            );

            // Set details for split agreements
            processSplitAgrInfo(activeAgreementHistory, activeAgreements, setSplitCoCustomerAvailble, setsplitEligibleAgrs, setShowSplit);
        } catch (error) {
            console.error('Error fetching agreements:', error);
            setAgrHistoryPageDisplay(500);
            setagreementInfo([]);
            setsplitEligibleAgrs([]);
        }
    }

    // Helper function to fetch agreements and feature flags
    async function fetchAgreements(storeNumber: string | null, payload: ConfigurationsRequest) {
        const featureFlagPromise = (storeNumber && storeNumber !== 'null' && storeNumber !== 'undefined')
            ? getFeatureFlagInfo(payload)
            : Promise.resolve([]);

        const agreementHistoryPromise = getAgreementHistory(customerId, 'Y');
        const activeAgreementHistoryPromise = getAgreementHistory(customerId, 'N');

        return Promise.all([
            featureFlagPromise,
            agreementHistoryPromise,
            activeAgreementHistoryPromise,
        ]);
    }

    // For opening and closing the accordion button
    const GridShow = (agreementStatus: string) => {
        if (agreementStatus === 'Active') {
            setactiveExpand(!activeExpand);
        } else if (agreementStatus === 'InActive') {
            setinActiveExpand(!inActiveExpand);
            setreInstate(!inActiveExpand);
        } else if (agreementStatus === 'Pending') {
            setpending(!pending);
        } else if (agreementStatus === 'Reinstatable') {
            setreInstate(!reInstate);
        }
    };

    // For rendering the header content for Agreement History tab
    const renderAgreementHistoryHeader = () => (
        <>
            {agreementHistoryHeader.map((col: string, index: number) => {
                return (
                    <RACTableCell
                        className={`${classes.lineBreak} ${classes.customerTablecellbgcolor} 
                    ${col === '# Payments' || col === 'Total Cost' ? classes.textCenter : ''} 
                    ${col === 'Next Due Date' ? classes.txtStart : ''}`}
                        key={index}
                    >
                        {col}
                    </RACTableCell>
                );
            })}
        </>
    );

    const renderAgreementHistoryContent: any = () => {
        return agreementInfo.length > 0
            ? renderAgreementHistoryContentForActiveInactive()
            : renderContentNoRecordFound();
    };

    // For rendering the body content for Agreement History tab
    const renderAgreementHistoryContentForActiveInactive = () => {
        interface payload {
            objKey: string;
            objVal: boolean;
        }
        // Filtering the inactive agreements
        const inActiveagreements = agreementInfo.filter((value: (GetAgreement | ClubInfoResponse)) =>
            value.agreementStatus != 'Active' &&
            value.agreementStatus != 'Pending' &&
            value.isReinstate === false
        );
        // Filter the reinstate agreements
        const reInagreements = agreementInfo.filter((value: (GetAgreement | ClubInfoResponse)) => value.isReinstate === true);
        // Below is the agreement status and their accordion open/close variable
        const payloadObj: payload[] = [
            { objKey: 'Active', objVal: activeExpand },
            { objKey: 'Pending', objVal: pending },
            { objKey: 'InActive', objVal: inActiveExpand },
            { objKey: 'Reinstatable', objVal: reInstate },
        ];
        if (isExchangeEnabled && !agreementHistoryHeader.includes('Action')) {
            agreementHistoryHeader.push('Action');
        }
        // Looping the payloadObj and returning the html
        return payloadObj.map((el: payload) => {
            // For adding the Inactive accordion icon, if there is no inactive agreements but has reinstate agreements
            if (el.objKey == 'InActive' && inActiveagreements.length == 0 && reInagreements.length > 0) {
                return AccordionGrid(el.objKey, el.objVal, null, `${classes.agreementCategory}`);
            }
            // Returning the html and mapping the respective agreement details based on the agreement status
            return AgreementGrid(el.objKey, el.objVal);
        });
    };

    // For rendering no records found body content
    const renderContentNoRecordFound = () => {
        return (
            <RACTableRow hover backgroundColor="white">
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell>No Records Found</RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
                <RACTableCell></RACTableCell>
            </RACTableRow>
        );
    };

    // For rendering the tablecells
    const tableCell = (title: string | null, value: any, column: string) => {
        return (
            <RACTableCell title={title || ''} className={getTableStyles(value, column, classes)}>
                {formatValue(column, value, classes, racExchangeFuncFlag)}
            </RACTableCell>
        );
    };

    // For rendering the first cell of the agreementHistory Body
    const renderAgreementNumber = (value: (GetAgreement | ClubInfoResponse), agreementStatusProp: string, passDue: boolean) => {
        const exchangeAgreementsList: { [agreementId: string]: string | undefined } = {};
        const agreementStatus: { [agreementId: string]: boolean | undefined } = {};

        agreementInfo.forEach((element: (GetAgreement | ClubInfoResponse)) => {
            exchangeAgreementsList[element.agreementId] = element.parentAgreementId;
            agreementStatus[element.agreementId] = element.isExchangeAgreement;
        });

        return (
            <RACTableCell>
                {/* Checkbox */}
                <RACCheckBox
                    className={value.isReinstate ? classes.pl28 : classes.mR8}
                    checked={value.agreementId === agrCheckBoxId.id && agrCheckBoxId.checkBox}
                    onClick={(e: any) => {
                        CheckboxClick(value.agreementId, value.isReinstate, e, setagrCheckBoxId, setcheckAction)
                    }
                    }
                />

                {/* Agreement Number */}
                {(() => {
                    const isClub = value.agreementType === 'club';
                    const isPrelim = featureFlagDetails?.CustomerOrder === '0' &&
                        ['PRELIM', 'PRELIMPAID', 'PRELIMRFND', 'PRELIMINV'].includes(value.agreementRefCode);
                    const isExchange = exchangeAgreementsList[value.agreementId] &&
                        racExchangeFuncFlag && agreementStatus[value.parentAgreementId];

                    const agreementNumberClassName = `${getClass(
                        passDue === false ? 'PaymentIssue' : isClub ? 'club' : isPrelim ? agreementStatusProp : agreementStatusProp, classes
                    )} ${classes.focforLinkPaymentIssue}`;

                    const agreementNumberLink = isExchange
                        ? `/agreement/info/details/${customerId}/${value.agreementId}?isExchange=1&exchangeagreementid=${exchangeAgreementsList[value.agreementId]}`
                        : `/agreement/info/details/${customerId}/${value.agreementId}`;

                    return (
                        <a className={agreementNumberClassName} style={{ padding: '3px !important' }} href={agreementNumberLink}>
                            {value.agreementNumber}
                        </a>
                    );
                })()}

                {/* Auto Pay Badge */}
                {value.autoPay && <span className={classes.badgeContainer}>AP</span>}
            </RACTableCell>
        )
    }

    // For rendering the Action column in agreementHistory Body
    const renderExchangeIcon = (value: (GetAgreement | ClubInfoResponse)) => {
        return (
            <RACTableCell
                style={{ textAlign: 'center' }}
                className={value?.isExchangeAgreement && racExchangeFuncFlag ? `${classes.pointerEvents} ${classes.positionRelative}` : ''}
            >
                {validateForExchange(value.agreementStatus, value.closeDate, value.currentDueDate, value.agreementOpenDate, value.isEppAgreement, value.agreementType, isExchangeEnabled, returnPeriod, exchangePeriod, moment) &&
                    (value.storeNumber == window.sessionStorage.getItem("storeNumber")) ? (
                    <RACTooltip
                        placement={value?.isDeliveryConfirmed == '0' ? 'top' : 'top-start'}
                        className={classes.tooltipcss}
                        classes={{
                            tooltip: classes.toolTipStyle,
                            arrow: classes.arrowStyle
                        }}
                        title={<span style={{ fontSize: '10px' }}>{value?.isDeliveryConfirmed == '0' ? `Please confirm delivery to initiate an exchange` : `Exchange`}</span>
                        }
                    >
                        <ExchangeIcon className={(value?.isDeliveryConfirmed == '0' || value?.isExchangeAgreement) ? `${classes.iconWidth1} ${classes.pointer} ${classes.blur}` : `${classes.iconWidth1} ${classes.pointer}`}
                            onClick={() => {
                                if (racExchangeFuncFlag && value?.isDeliveryConfirmed == '1') {
                                    setShowAuth('RACExchange');
                                    setRentPaid(value?.rentPaid);
                                    setAgreementId(value?.agreementId);
                                    setSuspenseAmount(value?.suspenseAmount == '0.00' ? 0 : value?.suspenseAmount)
                                    setExchangeAgreementNumber(value?.agreementNumber)
                                }
                            }}
                        />
                    </RACTooltip>
                ) : <span style={{ textAlign: 'center', height: '100%', }}>-</span>}
            </RACTableCell>
        )
    }

    // For rendering the html for Agreement History tab body content 
    const AgreementGrid = (agreementStatusProp: string, accordianOpen: boolean) => {

        // Filtering the agreements based on the agreement status
        const agreements: (GetAgreement | ClubInfoResponse)[] = filterAgreement(agreementStatusProp, agreementInfo);

        if (agreements && agreements.length > 0) {
            return agreements.map((value: (GetAgreement | ClubInfoResponse), index: number) => {
                const passDue = value.currentDayslate && value.currentDayslate !== '0' && value.currentDayslate !== 0
                    ? false : true;
                const style = getTableStyles(value, 'accordion', classes);
                return (
                    <React.Fragment key={'agreement' + index}>
                        {index === 0 ? (AccordionGrid(agreementStatusProp, accordianOpen, index, style)) : null}
                        {accordianOpen === true ? (
                            <RACTableRow className={passDue === false ? `${classes.paymentIssue} ${classes.agreementRow}`
                                : `${classes.agreementRow} ${classes.customerTablecellbgcolor}`}
                            >
                                {renderAgreementNumber(value, agreementStatusProp, passDue)}
                                {tableCell(value.agreementDescription, value.agreementDescription, 'Description')}
                                {tableCell(value.agreementType, value.agreementType, 'agreementType')}
                                {tableCell(null, value.storeNumber, 'storeNumber')}
                                {tableCell(null, value.numberOfPayments, 'numberOfPayments')}
                                {tableCell(null, value.totalCost, 'totalCost')}
                                {tableCell(null, value.paymentSchedule, 'paymentSchedule')}
                                {tableCell(null, value, 'nextDueDate')}
                                {tableCell(null, value.amountDue, 'amountDue')}
                                {tableCell(null, value.agreementSubstatus, 'agreementSubstatus')}
                                {isExchangeEnabled ? renderExchangeIcon(value) : null}
                            </RACTableRow>
                        ) : null}
                    </React.Fragment>
                );
            });
        }
    };

    // For returning the According Image and its functionality
    const AccordionGrid = (agreementStatusProp: string, accordianOpen: boolean, index: number | null, classname: string) => {
        return (
            <React.Fragment>
                <RACTableRow key={'agreement' + (index || '')} className={classes.customerTablecellbgcolor}>
                    <RACTableCell colSpan={10} className={classname}>
                        {accordianOpen === false ? (
                            <AccordianCloseIcon
                                onClick={() => GridShow(agreementStatusProp)}
                                className={`${classes.accordionIconGrid} ${classes.mR8} ${classes.accordionGridClose}`}
                            />
                        ) : (
                            <AccordianOpenIcon
                                onClick={() => GridShow(agreementStatusProp)}
                                className={`${classes.accordionIconGrid} ${classes.mR8}`}
                            />
                        )}
                        {agreementStatusProp}
                    </RACTableCell>
                </RACTableRow>
            </React.Fragment>
        );
    };

    const onAuthComplete = (e: any) => {
        if (e == true) {
            setExchangeNavigationPopup(true);
            setShowAuth('')
        }
    }
    
    return (
        <>
            {showAuth !== '' ? (
                <SecondFactor
                    setTwoFactorCancelClick={() => setShowAuth('')}
                    setTwoFactorCompleted={(e: any) => onAuthComplete(e)}
                    moduleName={showAuth}
                    currentRole={currentRole}
                ></SecondFactor>
            ) : null}

            {
                exchangeNavigationPopup ? (
                    <RACExchange value={{
                        rentPaid: rentPaid,
                        agreementId: exchangeAgreementId,
                        customerId: customerId,
                        setExchangeNavigationPopup: setExchangeNavigationPopup,
                        exchangeNavigationPopup: exchangeNavigationPopup,
                        racExchangeFuncFlag: racExchangeFuncFlag,
                        exchangeAgreementNumber: exchangeAgreementNumber,
                        suspenseAmount: suspenseAmount
                    }} />
                ) : null
            }

            <Grid container id="agreementhistoryinfo" className={Agrtransferinit ?
                `${classes.pointerEvent}${classes.mb16}${classes.px2}` : `${classes.mb16} ${classes.px2}`}
            >
                <Grid item md={12}>
                    <Typography className={classes.title}>
                        Agreement History
                    </Typography>
                </Grid>
                <Grid item md={12} className={classes.mb120}>
                    <Card className={`${classes.card} ${classes.w100}`}>
                        <CardContent>
                            <Grid md={12}>
                                {agrHistoryPageDisplay == 1 ? (
                                    <Grid md={12} className={classes.textCenter}>
                                        <CircularProgress />
                                    </Grid>
                                ) : agreementInfo ? (
                                    <>
                                        <RACTable
                                            renderTableHead={renderAgreementHistoryHeader}
                                            renderTableContent={renderAgreementHistoryContent}
                                            className={classes.agrementGridMC}
                                        />
                                    </>
                                ) : (
                                    <Grid md={12} className={classes.textCenter}>
                                        <Alerticon></Alerticon>
                                        <Typography className={`${classes.mt16} ${classes.textCenter}`}>
                                            {agreementInfo?.length == 0 ? 'No rental agreements found'
                                                : 'Something went wrong'}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}