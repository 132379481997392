/* eslint-disable */
/* eslint-disable sonarjs/no-duplicate-string */
import { makeStyles } from '@rentacenter/racstrap';
export const SecondFactorStyles = () => {
  const UseClasses = makeStyles((theme) => ({
    racRemoteLoginAlert: {
      color: `red`,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
      marginTop: '15px',
    },
    racRemoteLogin: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      textAlign: 'center',
      margin: '25px 15px',
      fontSize: theme.typography.pxToRem(14),
    },
    buttonMargin: {
      marginRight: '50px',
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    logoResponsive: {},
    spacerMargin: {
      margin: theme.typography.pxToRem(8),
    },
    title: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      marginBottom: '10px',
    },
    spacerMT5: {
      marginTop: theme.typography.pxToRem(48),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(24),
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    RACPOPMsg: {
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      letterSpacing: 0,
    },
    w100: {
      width: '100%',
    },
    floatLeft: {
      float: 'left',
    },
    floatRight: {
      float: 'right',
    },
    /* Margin and Padding spacing */

    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },

    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },

    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },

    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },

    me2: {
      marginRight: theme.typography.pxToRem(8),
    },

    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },

    textCenter: {
      textAlign: 'center',
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    racLoginAlert: {
      color: 'red',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
    },

    racBtnDisabled: {
      pointerEvents: 'none',
      opacity: 0.65,
    },
    racErrorIcon: {
      width: ' 50px',
    },
    racErrorFunction: {
      fontFamily: 'OpenSans-semibold',
      marginBottom: '20px',
      marginTop: '10px',
    },
    racPinError: {
      color: 'red',
      marginTop: '5px',
      marginLeft: '0.5rem',
      fontFamily: 'OpenSans-semibold',
    },
    racPinContainer: {
      margin: '0 auto',
      width: '94%',
    },
    racPinLabel: {
      fontFamily: 'OpenSans-semibold',
      color: '#000000',
      marginLeft: '0.5rem',
      marginBottom: '3px',
    },
    racPinField: {
      height: '45px',
      width: '45px',
      marginRight: '20px',
      marginLeft: '0.5rem',
      textAlign: 'center',
      borderRadius: '0.6rem',
      border: '1px solid #adb5bd',
      fontSize: 'x-large',
    },
    RACLoaderPage: {
      textAlign: 'center',
      marginTop: '250px',
      fontSize: '16px',
      marginLeft: '30px',
      marginRight: '30px',
    },
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const classes = UseClasses();
  return classes;
};
