/* eslint-disable */
import { buildDate } from "../../../../utils/utils";
import { buildAddressPayload, buildEmpObj, buildPhones, buildReferenceObj, buildVechicle, landlordReferences, validateAddresses, validatePhone, validateTabFour, validateTabOne, validateTabThree, validateTabTwo } from "./SaveValidations";
import { tabList } from "../../../../interface/globalInterface";
import { UpdateAddressInfo, UpdateCustomerInfo } from "../../../../api/user";

export async function agreementRedirect(props: any, btn: any) {
    // Destructuring the props
    const { setCreateAgreementClick, customerInformation, featureFlagDetails, activeAddress,
        setcomingSoonPopup, setValidateResult, isCustomerInfoUpdate, setIsRequired, residenceInfo, isMaskedDataHasChanged,
        activetab, salesLeadIdQuery, setErrorPopup, setisAuth, isAuth, setScreenLoader, federalTaxId, taxToggle,
        salesLeadId, setrestatementPopUp, setcreateCoCustomerPopUp, setIsCustomerInfoUpdate,
        setShowUpdatePopUp, setisSuccess, contactInformation, taxExemptInformation, skipStolenHardStatus,
        bestTimeToCallOptions, phoneTypeOptions, employerDetails, customerId, pageType,
        vehicleInformation, communicationInformation, setSecondLevelAuthenOn, history, configurations,
        referenceInformation, payDayFrequencyOptions, relationshipOptions, setIsPrimaryNotSet
    } = props;

    const loginStore = sessionStorage.getItem('storeNumber');

    // Payload for Tab One Validation function
    const validateTabOnePayload = {
        customerInformation, federalTaxId, taxToggle, taxExemptInformation,
        setIsPrimaryNotSet, activeAddress, contactInformation
    };

    // Payload for build phone function
    const buildPhonePayload = { contactInformation, bestTimeToCallOptions, phoneTypeOptions };

    // Agreement Redirect function flow starts here
    let updateCustomerAddress: any;

    // Coming soon popup should be shown for rac cowoker
    if (customerInformation.racCoworker && customerInformation.racCoworker == 'Y' &&
        featureFlagDetails?.DiscountedAgreement == '0' && btn == 'AGREEMENT') {
        setcomingSoonPopup(true);
    }
    // Validation, updation and navigation flow starts here if above condition fails.
    else {
        const validateResultObj: any = [];
        const normalFlow = (!configurations?.customerVerificationOptimization ||
            configurations?.customerVerificationOptimization != '1');
        let isValid = true;
        // Validating 1st tab values (Basic Information, Address, Phone ,)
        let firstTabValid = validateTabOne(validateTabOnePayload, true) && validateAddresses(activeAddress);

        // SSN validation
        const SSN: any = customerInformation.ssn || null;
        if (SSN !== null && SSN.length < 9) {
            firstTabValid = false;
        }

        if (firstTabValid === false) {
            validateResultObj.push('Customer Information');
            isValid = false;
        }
        setValidateResult(validateResultObj);

        let result: any = '';
        // For tab validation and field value updation
        if (isValid) {
            try {
                // isCustomerInfoUpdate variable indicates if any changes happens in any of the fields
                if (isCustomerInfoUpdate && normalFlow) {
                    setIsRequired(false);
                    const updateCustomerRequest: any = {
                        customerId: customerId,
                        globalCustomerId: customerInformation.globalCustomerId,
                        firstName: customerInformation.firstName,
                        lastName: customerInformation.lastName,
                        middleInitial: customerInformation.middleInitial,
                        coworker: customerInformation.racCoworker,
                        suffix: customerInformation.suffix !== '0' &&
                            customerInformation.suffix
                            ? customerInformation.suffix
                            : '',
                        salutation: customerInformation.salutation,
                        governmentId: customerInformation.governmentId,
                        governmentIdType:
                            customerInformation.governmentIdType == '0'
                                ? ''
                                : customerInformation.governmentIdType,
                        governmentIdIssuedState:
                            customerInformation.governmentIdIssuedState,
                        emailAddress: customerInformation.emailAddress,
                        preferredContactMethod:
                            communicationInformation.contactMethod !== '0' &&
                                communicationInformation.contactMethod !== 'EM'
                                ? communicationInformation.contactMethod
                                : '',
                        preferredLanguage: communicationInformation.language,
                        printReceiptMethod: communicationInformation.paymentReceipt,
                        phoneSolicitationAllowed:
                            communicationInformation.phoneSolicitation,
                        emailSolicitationAllowed:
                            communicationInformation.emailSolicitation,
                        mailSolicitationAllowed:
                            communicationInformation.mailSolicitation,
                        textSolicitationAllowed: communicationInformation.textMessageRemainder,
                        paymentReminderCallsAllowed:
                            communicationInformation.paymentRemainderCall,
                        verified: customerInformation?.verifiedDate ? 'Y'
                            : 'N',
                        // verifiedDate: '', // residenceInfo.ownVerifieddate ? buildDate(residenceInfo.ownVerifieddate) : '',
                        skip: skipStolenHardStatus.skip ? 'Y' : 'N',
                        stolen: skipStolenHardStatus.stolen ? 'Y' : 'N',
                        hard: skipStolenHardStatus.hard ? 'Y' : 'N',
                        skipDate: buildDate(''),
                    };
                    if (isMaskedDataHasChanged.ssn) {
                        updateCustomerRequest.taxId = SSN,
                            updateCustomerRequest.taxIdType = 'SSN'
                    }
                    if (isMaskedDataHasChanged.dob) {
                        updateCustomerRequest.dateOfBirth = buildDate(customerInformation.dateOfBirth)
                    }
                    if (isMaskedDataHasChanged.govtId) {
                        updateCustomerRequest.governmentId = customerInformation.governmentId
                    }
                    updateCustomerAddress = buildAddressPayload(activeAddress, customerId);
                    const updatedPhonePayload: any = buildPhones(buildPhonePayload);

                    if (contactInformation && contactInformation.length > 0 &&
                        validatePhone(contactInformation, false).isValidate) {
                        updateCustomerRequest.phones = updatedPhonePayload;
                    }

                    const ObjTabTwo: any = validateTabTwo(employerDetails, true);
                    const ObjTabThree: any = validateTabThree(residenceInfo, true);
                    const ObjTabFour: any = validateTabFour(referenceInformation, true);

                    if (ObjTabTwo?.isValidate === true &&
                        ObjTabTwo?.UpdatedInfo === true) {
                        const EmpPropsArray: any = buildEmpObj(employerDetails, payDayFrequencyOptions);
                        updateCustomerRequest.employerReferences = EmpPropsArray;
                    } else {
                        if ((ObjTabTwo.isValidate === false || ObjTabTwo.UpdatedInfo === false) &&
                            (activetab == tabList.EMPLOYMENTINFO)) {
                            setIsRequired(true);
                            return;
                        }
                    }
                    if (ObjTabThree.isValidate === true &&
                        ObjTabThree.UpdatedInfo === true) {
                        if (residenceInfo.residenceType === 'RENT') {
                            updateCustomerRequest.landlordReferences = landlordReferences(residenceInfo);
                            updateCustomerRequest.residenceType = residenceInfo.rentalType;
                            updateCustomerRequest.ownVerifiedDate = '';
                        } else {
                            const isOwned = residenceInfo.rentalType === 'OWN';
                            const hasMortgage = isOwned && residenceInfo.mortgage;

                            updateCustomerRequest.mortgageCompanyName = hasMortgage
                                ? residenceInfo.ownResidenceDetails.mortgageCompanyName || ''
                                : '';

                            updateCustomerRequest.residenceSince = isOwned
                                ? residenceInfo.ownResidenceDetails.moveInDate
                                : null;

                            updateCustomerRequest.monthlyMortgagePayment = hasMortgage
                                ? (residenceInfo.ownResidenceDetails.paymentAmount === 'NaN' ? ''
                                    : residenceInfo.ownResidenceDetails.paymentAmount) : '';
                        }
                    } else {
                        if ((ObjTabThree.isValidate === false ||
                            ObjTabThree.UpdatedInfo === false) &&
                            (activetab == tabList.RESIDENCE)) {
                            setIsRequired(true);
                            return;
                        }
                    }
                    if (salesLeadIdQuery) {
                        updateCustomerRequest.salesLeadId = salesLeadIdQuery;
                    }

                    if (ObjTabFour.isValidate === true &&
                        ObjTabFour.UpdatedInfo === true) {
                        const ReferencePropsArray: any = buildReferenceObj(referenceInformation, relationshipOptions, bestTimeToCallOptions);

                        updateCustomerRequest.personalReferences = ReferencePropsArray;
                    } else {
                        if ((ObjTabFour.isValidate === false ||
                            ObjTabFour.UpdatedInfo === false) &&
                            (activetab == tabList.REFERENCE)) {
                            setIsRequired(true);
                            return;
                        }
                    }
                    if (validateResultObj.length > 0) {
                        setErrorPopup(true);
                        setIsRequired(true);
                        return;
                    }
                    if (pageType == 'customer') {
                        const vechicleObj: any = buildVechicle(vehicleInformation);
                        updateCustomerRequest.vehicles = vechicleObj;
                    }
                    if (
                        customerInformation.racCoworker &&
                        customerInformation.racCoworker == 'Y' &&
                        btn == 'AGREEMENT' && isAuth === false
                    ) {
                        setisAuth(true);
                        setSecondLevelAuthenOn('RAC Coworker');
                    }
                    else {
                        updateCustomerRequest.assignRoute = {
                            storeNumber: loginStore,
                            customerId: parseInt(customerId || ''),
                            routeRefCode: communicationInformation.route,
                        };
                        setCreateAgreementClick(true);
                        setScreenLoader(true);

                        console.log(updateCustomerRequest, 'final updateCustomerRequest')

                        result = await UpdateCustomerInfo(
                            updateCustomerRequest,
                            customerId
                        );
                        const updateAddPayload: any = updateCustomerAddress;
                        await UpdateAddressInfo(updateAddPayload);
                        setCreateAgreementClick(false);
                        setScreenLoader(false);
                    }
                }
            } catch (e: any) {
                setCreateAgreementClick(false);
                setErrorPopup(true);
            }
        } else {
            setErrorPopup(true);
            setIsRequired(true);
        }

        // For redirecting to respective page
        try {
            if (!isCustomerInfoUpdate || (result && result?.UpdateCustomer?.value?.customerId == customerId) ||
                configurations?.customerVerificationOptimization == '1') {
                if (customerId !== undefined) {
                    if (customerInformation.coCustomerId && btn == 'AGREEMENT') {
                        history.push(
                            (salesLeadIdQuery) || (salesLeadId)
                                ? `/agreement/rental/itemsearch/${customerId}?salesLeadId=${salesLeadIdQuery || salesLeadId}`
                                : `/agreement/rental/itemsearch/${customerId}`
                        );
                    } else if (btn == 'PAYMENT') {
                        history.push(
                            `/payment1/paymentinformation/${customerId}/0`
                        );
                    } else if (btn == 'ReInstatement') {
                        setrestatementPopUp(true);
                    } else if (customerInformation.racCoworker && customerInformation.racCoworker == 'Y'
                        && btn == 'AGREEMENT') {
                        history.push(
                            (salesLeadIdQuery) || (salesLeadId)
                                ? `/agreement/rental/itemsearch/${customerId}?salesLeadId=${salesLeadIdQuery || salesLeadId}`
                                : `/agreement/rental/itemsearch/${customerId}?coCustomerId=N`
                        );
                    } else {
                        setcreateCoCustomerPopUp(true);
                    }
                }
            } else {
                setisSuccess(false);
                setShowUpdatePopUp(true);
            }
        } catch (e: any) {
            setCreateAgreementClick(false);
            setErrorPopup(true);
        }

        setCreateAgreementClick(false);
        setIsCustomerInfoUpdate(false);
    }
};