/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-duplicated-branches */
import React, { useState, useEffect, useContext } from 'react';
import {
  RACTextbox,
  RACDatePicker,
  RACButton,
  RACTableCell,
  RACTable,
  RACTableRow,
  RACRadio,
  CircularProgress,
  RACModalCard,
  Grid,
  Typography,
  Box,
} from '@rentacenter/racstrap';
import { ReactComponent as Alerticon } from '../../assets/images/No-records.svg';
import { GetSearchResult, GetCustomerBasicInfo } from '../../api/user';
import { customerValidationContext } from '../../context/customerContext/customerContext';
import moment from 'moment';
import clsx from 'clsx';
export interface coCustomerProps {
  customerId: string;
  handleClosePopUp: (action: boolean) => void;
  showCustomerInfo: (customerId: string) => void;
  history: any;
  isValidatePage?: boolean;
  salesLeadIdQuery?: any;
  modelNumberQueryParams?: any;
  exceptionSt?: any;
  styles: any;
  reinstatementConsition?: boolean;
  agreementId?: any;
}
import { CoCustomerHeader } from '../../constants/constants';
const Initialsearch = {
  FirstName: '',
  LastName: '',
  dob: '',
};
const InitialSSN = {
  ThreeDigitSSN: '',
  TwoDigitSSN: '',
  FourDigitSSN: '',
}; //Ini

export function AddCoCustomer(props: coCustomerProps) {
  const classes = props.styles();
  const [SSN, setSSN] = useState(InitialSSN);
  const [search, Setsearch] = useState(Initialsearch);
  const [searchData, SetsearchData] = useState([]);
  const { searchVal, customerQsrc, reInstateCheck } = useContext(
    customerValidationContext
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValues, setsearchVal] = searchVal;
  const [hidesearch, SetHideSearch] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validateMsg, setValidateMsg] = useState<'R' | 'C' | ''>('');
  const [isclsAlertMsg, setisclsAlertMsg] = useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [btnstate, setbtnstate] = useState(true);
  const [blurLoaderActive, setblurLoaderActive] = useState<any>(false);
  const [phonenumber, Setphonenumber]: any = useState();
  const [searchedUser, setSearchedUser]: any = useState();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [datepicker, setdatepicker] = useState('');
  const [enableloader, setenableloader] = useState<any>(false);
  const [clearData, setclearData] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableSubmitButton, setenableSubmitButton] = useState(true);
  const [exceptionStatus, setexceptionStatus] = useState<any>(false);
  const [radioIndex, setRadioIndex] = useState<any>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerSrc, setcustomerSrc] = customerQsrc;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFromCustPage, setisFromCustPage] = reInstateCheck;

  const formatTextChange = (value: any) => {
    return value !== undefined && value !== null && value.trim().length == 0
      ? value.trim()
      : value;
  };
  const OnsearchChange = (e: any) => {
    // let regexItem;

    if (e.target.name !== 'dob') {
      // regexItem = new RegExp(/[^A-Z'.,-’ a-z]/);

      // if (e.target.value.replace(/[^A-Z'.,-’ a-z]/, '')) {

      //setSSN({...SSN,[e.target.name]:e.target.value})
      if (
        e.target.value &&
        e.target.value.length > 0 &&
        e.target.value.charAt(e.target.value.length - 1) !== ' '
      ) {
        const char: any = capitalize(e.target.value);
        Setsearch({
          ...search,
          [e.target.name]: formatTextChange(
            char.replace(/[^A-Z-'.,’ a-z]/gi, '')
          ),
        });
      } else {
        Setsearch({
          ...search,
          [e.target.name]: formatTextChange(
            formatTextChange(e.target.value.replace(/[^A-Z-'.,’ a-z]/gi, ''))
          ),
        });
      }
      // }
    } else {
      Setsearch({ ...search, [e.target.name]: e.target.value });
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    // eslint-disable-next-line no-debugger
    debugger;
    const phoneNUmber =
      phonenumber !== undefined && phonenumber !== null
        ? phonenumber.replace(/\D/g, '')
        : '';
    let nameValid = true;
    //const dateValid = true;
    let ssnValid = true;
    let phoneNumberValid = true;
    let filterApplied = true;
    if (
      search.FirstName.length + search.LastName.length == 0 &&
      search.dob == '' &&
      SSN.ThreeDigitSSN.length +
        SSN.TwoDigitSSN.length +
        SSN.FourDigitSSN.length ==
        0 &&
      phoneNUmber.length == 0
    ) {
      filterApplied = false;
    }
    if (filterApplied !== false) {
      if (
        search.FirstName.length + search.LastName.length !== 0 &&
        search.FirstName.length + search.LastName.length < 3
      ) {
        nameValid = false;
      }
      // if (search.dob == undefined || search.dob == null || search.dob == '') {
      //   dateValid = false
      // }
      if (
        SSN.ThreeDigitSSN.length +
          SSN.TwoDigitSSN.length +
          SSN.FourDigitSSN.length !==
          0 &&
        SSN.ThreeDigitSSN.length +
          SSN.TwoDigitSSN.length +
          SSN.FourDigitSSN.length <
          9
      ) {
        ssnValid = false;
      }
      if (phoneNUmber.length !== 0 && phoneNUmber.length < 10) {
        phoneNumberValid = false;
      }
    }
    const disableSearch: boolean =
      filterApplied === true
        ? nameValid && ssnValid && phoneNumberValid
          ? false
          : true
        : true;
    setbtnstate(disableSearch);
    // if (
    //   search.FirstName.length >= 3 ||
    //   search.LastName.length >= 3 ||
    //   search.FirstName.length + search.LastName.length >= 3 ||
    //   search.dob !== '' ||
    //   (SSN.ThreeDigitSSN.length == 3 &&
    //     SSN.TwoDigitSSN.length === 2 &&
    //     SSN.FourDigitSSN.length == 4)
    // ) {
    //   setbtnstate(false);
    // } else {
    //   setbtnstate(true);
    // }
    // if (phonenumber != undefined) {
    //   const modifiedPhone = ('' + phonenumber).replace(/\D/g, '');

    //   if (modifiedPhone.length !== 0 && modifiedPhone.length < 10) {
    //     setbtnstate(false);
    //   }
    //   if (
    //     (modifiedPhone.length < 10 && search.LastName.length >= 3) ||
    //     (modifiedPhone.length < 10 && search.FirstName.length >= 3) ||
    //     (SSN.ThreeDigitSSN.length == 3 &&
    //       SSN.TwoDigitSSN.length === 2 &&
    //       SSN.FourDigitSSN.length == 4 &&
    //       modifiedPhone.length < 10)
    //   ) {
    //     setbtnstate(true);
    //   } else {
    //     setbtnstate(false);
    //   }
    // }
    // eslint-disable-next-line sonarjs/no-duplicate-string
    setdatepicker(moment().subtract(18, 'years').format('YYYY-MM-DD'));
  }, [search, SSN, phonenumber]);
  const Search_Onclick = async () => {
    setenableloader(true);
    SetsearchData([]);

    if (
      search.FirstName !== '' ||
      search.LastName !== '' ||
      search.dob !== '' ||
      phonenumber != ''
    ) {
      //declaring the three variables initailly and convert them to tostring and concatenate all the variables to a variable Ssnnum
      let twodigit: any = SSN.ThreeDigitSSN;
      twodigit = twodigit.toString();
      let threedigit: any = SSN.TwoDigitSSN;
      threedigit = threedigit.toString();
      let fourdigit: any = SSN.FourDigitSSN;
      fourdigit = fourdigit.toString();
      let Ssnnum: any = twodigit + threedigit + fourdigit;
      Ssnnum = parseInt(Ssnnum);
      // eslint-disable-next-line no-console
      console.log(Ssnnum);
      setSearchedUser(search.LastName + ' ' + search.FirstName);
      const phones: any = [
        {
          phoneNumber: '',
        },
      ];
      const payloadReq: any = {};
      if (search.FirstName != '') {
        payloadReq.firstName = search.FirstName;
      }
      if (search.LastName != '') {
        payloadReq.lastName = search.LastName;
      }
      if (search.dob != '') {
        payloadReq.dateOfBirth = search.dob;
      }
      if (
        SSN.ThreeDigitSSN !== '' &&
        SSN.TwoDigitSSN !== '' &&
        SSN.FourDigitSSN !== ''
      ) {
        payloadReq.taxId =
          SSN.ThreeDigitSSN + SSN.TwoDigitSSN + SSN.FourDigitSSN;
      }
      if (
        phonenumber !== undefined &&
        phonenumber !== '' &&
        phonenumber.length >= 12
      ) {
        const PhoneNum = phonenumber.replace(/\D/g, '');
        phones[0].phoneNumber = PhoneNum;
        payloadReq.phones = phones;
        // eslint-disable-next-line no-console
        console.log(payloadReq);
      }
      getsearchvalues(payloadReq);
    }
  };
  const getsearchvalues = async (payload: any) => {
    //getting the response form the client to the tsx component
    const SearchCoCustomerresponse = await GetSearchResult(payload);
    setenableloader(false);
    setclearData(true);

    if (
      SearchCoCustomerresponse !== undefined &&
      SearchCoCustomerresponse.GetCustomers !== undefined &&
      SearchCoCustomerresponse.GetCustomers.customers !== undefined
    ) {
      SetsearchData(SearchCoCustomerresponse.GetCustomers.customers);
    }
    SetsearchData(SearchCoCustomerresponse.GetCustomers.customers);
    // eslint-disable-next-line no-console
    console.log(
      'searchciustomerresponse',
      SearchCoCustomerresponse.GetCustomers.customers
    );
    SetHideSearch(true);
  };
  const enterKeyPressed = (event: any) => {
    // Number 13 is the "Enter" key on the keyboard
    /* eslint-disable no-console */

    console.log('key pressed');
    /* eslint-enable no-console */

    if (event.charCode === 13 && !btnstate) {
      // Cancel the default action, if needed
      event.preventDefault();
      /* eslint-disable no-console */
      console.log('enter pressed');
      /* eslint-enable no-console */
      Search_Onclick();
      // Trigger the button element with a click
    }
  };
  const handlessn1 = (e: any) => {
    debugger;
    const ssn3El: any = document.getElementById('ssn4');
    const ssn1El: any = document.getElementById('ssn3');
    const ssn2El: any = document.getElementById('ssn2');
    let regexItem;
    if (
      e.target.name == 'ThreeDigitSSN' ||
      e.target.name == 'TwoDigitSSN' ||
      e.target.name == 'FourDigitSSN'
    ) {
      regexItem = new RegExp('^[0-9]*$');
    }
    if (e.target.value.match(regexItem)) {
      setSSN({ ...SSN, [e.target.name]: e.target.value });

      if (e.target.name === 'ThreeDigitSSN' && e.target.value.length === 3) {
        if (ssn1El == document.activeElement) {
          ssn2El.focus();
        }
      } else if (
        e.target.name === 'TwoDigitSSN' &&
        e.target.value.length === 2
      ) {
        if (ssn2El == document.activeElement) {
          ssn3El.focus();
        }
      } else if (
        e.target.name === 'TwoDigitSSN' &&
        e.target.value.length === 0 &&
        SSN.ThreeDigitSSN.length > 0
      ) {
        if (ssn2El == document.activeElement) {
          ssn1El.focus();
        }
      } else if (
        e.target.name === 'FourDigitSSN' &&
        e.target.value.length === 0
      ) {
        if (ssn3El == document.activeElement) {
          ssn2El.focus();
        }
      }
    }
  };
  const capitalize = (input: any) => {
    const words: any = input.split(' ');
    const CapitalizedWords: any = [];
    words.forEach((element: any) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(' ');
  };
  const HandleChange = (e: any) => {
    // debugger;
    //alert(e.target.value);
    const cleaned = ('' + e.target.value).replace(/\D/g, '');
    //This is raw data to pass on props
    //setPhonenumberforrcustValidatoion(cleaned);
    if (cleaned.length <= 10) {
      const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      const temp = cleaned.replace(PhoneRegex, '($1) $2-$3');

      //Isvalid = temp.length == 14 ? true:false
      //alert(temp);
      Setphonenumber(temp);
    }
  };
  const [checkBoxActive, setcheckBoxActive] = useState<any>(undefined);

  const checkBoxActiveOnclick = async (index: number, gcd: any) => {
    // setcheckBoxActive(undefined);
    const currentCUstomerChecked: number | undefined =
      checkBoxActive == index ? undefined : index;
    if (index !== undefined) {
      const res = await getcustomerbyid('C', gcd);
      /* eslint-disable no-console */
      console.log('custID.customerId', res);
      if (res == undefined) {
        setcheckBoxActive(undefined);
      } else {
        res.customerId !== undefined
          ? setenableSubmitButton(false)
          : setenableSubmitButton(true);
        setRadioIndex(index);
        setcheckBoxActive(index);
      }
    } else {
      setRadioIndex(undefined);
      setenableSubmitButton(false);
      setcheckBoxActive(currentCUstomerChecked);
    }
  };
  const AgeementCounts = (value: any) => {
    return value.agreementCounts !== undefined &&
      value.agreementCounts.findIndex(
        (type: { lob: string }) => type.lob === 'RTO'
      ) !== undefined &&
      value.agreementCounts.findIndex(
        (type: { lob: string }) => type.lob === 'RTO'
      ) !== -1
      ? value.agreementCounts[
          value.agreementCounts.findIndex(
            (type: { lob: string }) => type.lob === 'RTO'
          )
        ].active
      : 0;
  };
  const normalizeinput = (phonenum: any) => {
    phonenum =
      phonenum !== undefined &&
      phonenum.length > 0 &&
      phonenum[0].phoneNumber !== undefined
        ? phonenum[0].phoneNumber
        : '-';
    if (phonenum == '-') return phonenum;
    if (!phonenum) return phonenum;
    const currentValue = phonenum.replace(/[^\d]/g, '');

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  };
  const AddressLine2Validation = (value: any) => {
    return value.addresses !== undefined &&
      value.addresses[0]?.addressLine2 !== undefined &&
      value.addresses[0]?.addressLine2 !== null
      ? value.addresses[0]?.addressLine2 + ','
      : '';
  };
  //   const Add_Cocustomer = (customerid: any) => {
  //     setenableSubmitButton(false);
  //     // eslint-disable-next-line no-console
  //     console.log('GlobalCutomerId', customerid);
  //     setCustomerId(customerid);
  //     const addobj: any = { customerid };
  //     Setactivecustomer(addobj);
  //   };
  //   const [isFetchingcustIdByGCD, setisFetchingcustIdByGCD] =
  // useState<any>(false);
  const [custID, setcustID] = useState<any>(undefined);
  const getcustomerbyid = async (type: any, id: any) => {
    try {
      setblurLoaderActive(true);
      const res: any = await GetCustomerBasicInfo(id);
      setblurLoaderActive(false);
      if (res !== undefined && res.customerId !== undefined) {
        // eslint-disable-next-line no-console
        //setisFetchingcustIdByGCD(false);
        console.log(res);
        if (type == 'C') {
          setcustID(res.customerId);
          return res;
        } else if (type == 'R') {
          props.showCustomerInfo(res.customerId);
        }
      } else {
        setValidateMsg(type);
        setisclsAlertMsg(true);
        setisclsAlertMsg(true);
      }
    } catch (err: any) {
      setValidateMsg(type);
      setblurLoaderActive(false);
      setisclsAlertMsg(true);
      if (err) {
        //setisFetchingcustIdByGCD(false);
        //setisclsAlertMsg(true);
        // eslint-disable-next-line no-console
        console.log('error while fetching customerid', err);
      }
    }
  };
  function CustNotFoundPopUp() {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <Alerticon></Alerticon>
          <Typography className={classes.RACPOPMsg}>
            {validateMsg == 'C'
              ? 'Customer record is incomplete, please create new customer.'
              : 'Customer record is incomplete, we will not be able to navigate.'}
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => setisclsAlertMsg(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );

    // Reason popup for Not running DE PC-CC-29
  }
  function GetCustIDAlert() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={isclsAlertMsg}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setisclsAlertMsg(false)}
        // eslint-disable-next-line react/no-children-prop
        children={CustNotFoundPopUp()}
        title=""
      />
    );
  }
  const createCoCustomer = () => {
    // eslint-disable-next-line
    debugger
    const searchVal = {
      firstName: search.FirstName,
      lastName: search.LastName,
      dob: search.dob,
      ssn1: SSN.ThreeDigitSSN,
      ssn2: SSN.TwoDigitSSN,
      ssn3: SSN.FourDigitSSN,
      phoneNumber:
        phonenumber !== undefined && phonenumber !== null && phonenumber !== ''
          ? phonenumber.replace(/\D/g, '')
          : '',
    };
    setsearchVal(searchVal);
    if (props.reinstatementConsition === true) {
      setisFromCustPage('RI');
      props.history.push(
        `/customer/createcustomer?customerId=${props.customerId}&agrId=${props.agreementId}&src=RI`
      );
      // return;
    } else {
      props.history.push(
        props.salesLeadIdQuery !== undefined &&
          props.salesLeadIdQuery !== null &&
          props.salesLeadIdQuery !== ''
          ? `/customer/createcustomer?customerId=${props.customerId}&&salesLeadId=${props.salesLeadIdQuery}&&modelNumber=${props.modelNumberQueryParams}`
          : `/customer/createcustomer?customerId=${props.customerId}`
      );
      if (props.isValidatePage === true) {
        window.location.reload();
      }
    }
  };
  function truncString(value: any) {
    if (
      value.dateOfBirth !== undefined &&
      value.dateOfBirth !== null &&
      value.dateOfBirth !== ''
    ) {
      const change = value.dateOfBirth;
      const statelookup = change.replace(/-/g, '/');
      const myArray = statelookup.split('/');
      const formatedDate = myArray[1] + '/' + myArray[2] + '/' + '****';

      return formatedDate !== '' &&
        formatedDate !== undefined &&
        formatedDate !== null
        ? formatedDate.toString()
        : '-';
    }
    return '-';
  }
  const renderSearchTableHead = () => (
    <>
      {CoCustomerHeader.map((col: string, index: number) => {
        return (
          <RACTableCell
            className={
              col == '#Active Agreements'
                ? `${classes.textCenter}`
                : `${classes.racstrapTablecellBgColor}`
            }
            key={index}
          >
            {col}
          </RACTableCell>
        );
      })}
    </>
  );
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderSearchTableContentFilled = () => {
    return searchData.map((el: any, i: any) => {
      const address: any =
        el.addresses !== undefined &&
        el.addresses !== null &&
        el.addresses.length > 0
          ? el.addresses.filter(
              (e: any) => e.addressType == 'PRIM' || e.addressType == 'P'
            )
          : [];
      const phones: any =
        el.phones !== undefined && el.phones !== null && el.phones.length > 0
          ? el.phones.filter((e: any) => e.isPrimary == 'Y')
          : [];
      return (
        <React.Fragment key={i}>
          <RACTableRow key={i}>
            <RACTableCell className={classes.racstrapTablecellBgColor}>
              {(el.agreementCounts == undefined &&
                el.globalCustomerId !== props.exceptionSt.globalCustomerId) ||
              (el.agreementCounts !== undefined &&
                el.agreementCounts.findIndex(
                  (type: { lob: string }) => type.lob === 'RTO'
                ) !== -1 &&
                el.agreementCounts[
                  el.agreementCounts.findIndex(
                    (type: { lob: string }) => type.lob === 'RTO'
                  )
                ].active == 0 &&
                el.globalCustomerId !== props.exceptionSt.globalCustomerId) ||
              props.reinstatementConsition === true ? (
                <RACRadio
                  name={`flexRadioDefault_${i}`}
                  onClick={() => checkBoxActiveOnclick(i, el.globalCustomerId)}
                  checked={radioIndex == i ? true : false}
                  value={undefined}
                />
              ) : null}
            </RACTableCell>
            <RACTableCell className={classes.racstrapTablecellBgColor}>
              <a
                className={classes.racpadLink}
                data-testid="searchhide"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => getcustomerbyid('R', el.globalCustomerId)}
              >
                {el.lastName}
              </a>
            </RACTableCell>
            <RACTableCell className={classes.racstrapTablecellBgColor}>
              <a
                className={classes.racpadLink}
                data-testid="searchhide"
                style={{ cursor: 'pointer', textDecoration: 'none' }}
                onClick={() => getcustomerbyid('R', el.globalCustomerId)}
              >
                {el.firstName}
              </a>
            </RACTableCell>
            <RACTableCell
              className={
                truncString(el) == '-'
                  ? `${classes.racstrapTablecellBgColor} ${classes.textCenter}`
                  : `${classes.racstrapTablecellBgColor}`
              }
            >
              {truncString(el)}
            </RACTableCell>
            <RACTableCell className={classes.racstrapTablecellBgColor}>
              {address !== undefined && address !== null && address.length > 0
                ? address[0]?.addressLine1
                : ''}
              {address !== undefined && address !== null && address.length > 0
                ? ','
                : ''}
              {AddressLine2Validation(el)}
              {address !== undefined &&
              address !== null &&
              address.length > 0 &&
              address[0]?.city !== undefined &&
              address[0]?.postalCode !== undefined
                ? address[0]?.city + ',' + address[0]?.postalCode
                : ''}
            </RACTableCell>
            <RACTableCell
              className={
                normalizeinput(phones) == '-'
                  ? `${classes.racstrapTablecellBgColor} ${classes.textCenter}`
                  : `${classes.racstrapTablecellBgColor}`
              }
            >
              {normalizeinput(phones)}
            </RACTableCell>
            <RACTableCell
              className={`${classes.racstrapTablecellBgColor} ${classes.textCenter}`}
            >
              {AgeementCounts(el)}
            </RACTableCell>
          </RACTableRow>
        </React.Fragment>
      );
    });
  };
  const renderContentNoRecordFound = () => {
    return (
      <RACTableRow hover backgroundColor="white">
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell>No Records Found</RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
        <RACTableCell></RACTableCell>
      </RACTableRow>
    );
  };
  const renderSearchTableContent: any = () => {
    //if (loading) return <></>;

    return searchData !== undefined &&
      searchData !== null &&
      searchData.length > 0
      ? renderSearchTableContentFilled()
      : renderContentNoRecordFound();
  };
  const exceptionCust = (customerInformation: any) => {
    // eslint-disable-next-line no-console
    console.log('customerInformation.skip', customerInformation.skip);
    // eslint-disable-next-line no-console
    console.log('customerInformation', customerInformation);
    if (
      (customerInformation.skip !== undefined &&
        customerInformation.skip !== null &&
        customerInformation.skip !== '' &&
        customerInformation.skip == 'Y') ||
      (customerInformation.hard !== undefined &&
        customerInformation.hard !== null &&
        customerInformation.hard !== '' &&
        customerInformation.hard == 'Y') ||
      (customerInformation.stolen !== undefined &&
        customerInformation.stolen !== null &&
        customerInformation.stolen !== '' &&
        customerInformation.stolen == 'Y')
    ) {
      return true;
    } else {
      return false;
    }
  };
  const exceptionCustStPopup = () => {
    return (
      <Grid item md={12} className={classes.spacerPX2}>
        <Grid
          container
          classes={{
            root: clsx(classes.customerJustifycontentcenter, classes.spacerMB2),
          }}
        >
          <Typography
            classes={{
              root: clsx(
                classes.spacerMS2,
                classes.spacerMT1,
                classes.RACPOPMsg
              ),
            }}
            variant="h6"
          >
            Primary has been marked with one or more of the following
            classifications:Skip/Stolen/Hard. This will effect the co-customer
            as well. Are you sure?
          </Typography>
        </Grid>
        <Grid
          container
          className={`${classes.spacerMT4} ${classes.justifyContentCenter}`}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            variant="outlined"
            color="primary"
            id="createCustomerNoBtn"
            isRounded={false}
            onClick={() => setexceptionStatus(false)}
          >
            No
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            isRounded={false}
            onClick={() => reDirect()}
          >
            Yes
          </RACButton>
        </Grid>
      </Grid>
    );
  };
  const reDirect = () => {
    if (
      props.reinstatementConsition === false ||
      props.reinstatementConsition == undefined ||
      props.reinstatementConsition == null
    ) {
      props.history.push(
        props.salesLeadIdQuery !== undefined &&
          props.salesLeadIdQuery !== null &&
          props.salesLeadIdQuery !== ''
          ? `/agreement/rental/itemsearch/${props.customerId}?coCustomerId=${custID}&&salesLeadId=${props.salesLeadIdQuery}&&modelNumber=${props.modelNumberQueryParams}`
          : `/agreement/rental/itemsearch/${props.customerId}?coCustomerId=${custID}`
      );
    } else if (customerSrc !== null) {
      customerSrc == 'SO'
        ? props.history.push(
            `/agreement/info/details/${custID}/${props.agreementId}?transferrightsapplied=Y`
          )
        : customerSrc == 'RI'
        ? `/agreement/reinstate/itemsearch/${custID}/${props.agreementId}?transferrightsapplied=Y`
        : '';
    } else {
      props.history.push(
        `/agreement/reinstate/itemsearch/${custID}/${props.agreementId}?transferrightsapplied=Y`
      );
    }
  };
  // popup for want to create New customer PC-CC-25
  function ExceptionCustSt() {
    return (
      <RACModalCard
        borderRadius="20px"
        isOpen={exceptionStatus}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setexceptionStatus(false)}
        // eslint-disable-next-line react/no-children-prop
        children={exceptionCustStPopup()}
      />
    );
  }
  const submitBtn = () => {
    if (exceptionCust(props.exceptionSt)) {
      setexceptionStatus(true);
    } else {
      reDirect();
    }
  };
  const ssnFoc: any = (e: any) => {
    if (e !== undefined) {
      const stDate = moment(e, 'YYYY-MM-DD');
      const lt18 = moment(moment().subtract(18, 'years'), 'YYYY-MM-DD');
      const lt110 = moment(moment().subtract(110, 'years'), 'YYYY-MM-DD');
      const lt18Res: any = lt18.diff(stDate, 'days');
      const lt110Res: any = lt110.diff(stDate, 'days');
      const res: any = lt18Res >= 0 && lt110Res < 0 ? true : false;
      if (res) {
        const el: any = document.getElementById('ssn3');
        el.focus();
      }
    }
  };
  return (
    <>
      <GetCustIDAlert />
      <ExceptionCustSt />
      <Grid container className={classes.ps2}>
        {blurLoaderActive ? (
          <Grid className={classes.masterLoader}>
            <Grid className={classes.Loader}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.spacerP2}>
          <Grid item md={12} className={classes.spacerMB3}>
            <Typography className={classes.widgetTitle}>
              {props.reinstatementConsition === true
                ? 'Search Customer'
                : 'Search Co-Customer'}
            </Typography>
          </Grid>

          <Grid item md={2}>
            <RACTextbox
              type="text"
              inputlabel="Last Name"
              name="LastName"
              value={search.LastName}
              OnChange={(e: any) => OnsearchChange(e)}
              onKeyPress={(e: any) => enterKeyPressed(e)}
              isCurrency={false}
            />
          </Grid>
          <Grid item md={2}>
            <RACTextbox
              type="text"
              name="FirstName"
              inputlabel="First Name"
              value={search.FirstName}
              maxlength={30}
              OnChange={(e: any) => OnsearchChange(e)}
              onKeyPress={(e: any) => enterKeyPressed(e)}
              isCurrency={false}
            />
          </Grid>
          <Grid item md={3} lg={2} className={classes.datePicker}>
            <Box
              component="div"
              className={`${classes.formLabel} ${classes.datePickerAddCo}`}
            >
              Date of Birth
              {/* <Box
                component="span"
                className={`${classes.mandatoryfield} ${classes.spacerMS1}`}
              >
                *
              </Box> */}
            </Box>
            <RACDatePicker
              id="a11y_dob"
              label=""
              value={search.dob}
              required={false}
              name={'dob'}
              onChange={(e: any) => {
                Setsearch({ ...search, dob: e });
                ssnFoc(e);
              }}
              inputProps={{
                min: moment().subtract(110, 'years').format('YYYY-MM-DD'),
                max: moment().subtract(18, 'years').format('YYYY-MM-DD'),
              }}
            />
            {/* <RACDatePicker
                        id="a11y_dob"
                        value={Dob}
                        required={true}
                        label="Date of Birth"
                        onChange={(e: any) => HandleDateChange(e)}
                        inputProps={{
                          min: moment()
                            .subtract(110, 'years')
                            .format('YYYY-MM-DD'),
                          max: moment()
                            .subtract(18, 'years')
                            .format('YYYY-MM-DD'),
                        }}
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                      /> */}
          </Grid>
          <Grid item md={2} className={classes.ssnPaddingCocust}>
            <Typography variant="caption" className={classes.formLabelAddCo}>
              Social Security# / ITIN#
            </Typography>
            <Grid container spacing={1}>
              <Grid item md={4}>
                <RACTextbox
                  type="password"
                  name="ThreeDigitSSN"
                  id="ssn3"
                  placeHolder={'xxx'}
                  maxlength={3}
                  value={SSN.ThreeDigitSSN}
                  OnChange={(e: any) => handlessn1(e)}
                  isCurrency={false}
                />
              </Grid>
              <Grid item md={3}>
                <RACTextbox
                  type="password"
                  name="TwoDigitSSN"
                  value={SSN.TwoDigitSSN}
                  placeHolder={'xx'}
                  formControlClassName={classes.ssnTextField}
                  maxlength={2}
                  id="ssn2"
                  OnChange={(e: any) => handlessn1(e)}
                  isCurrency={false}
                />
              </Grid>
              <Grid item md={5}>
                <RACTextbox
                  type="text"
                  name="FourDigitSSN"
                  value={SSN.FourDigitSSN}
                  placeHolder={'xxxx'}
                  id="ssn4"
                  maxlength={4}
                  OnChange={(e: any) => handlessn1(e)}
                  onKeyPress={(e: any) => enterKeyPressed(e)}
                  isCurrency={false}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <RACTextbox
              isCurrency={false}
              type="phoneno"
              maxlength={14}
              inputlabel="Phone Number"
              value={phonenumber}
              name="phone_number"
              OnChange={(e: any) => HandleChange(e)}
              id="Phone"
              onKeyPress={(e: any) => enterKeyPressed(e)}
            />
          </Grid>
          <Grid item md={2} className={classes.spacerMT0}>
            <RACButton
              disabled={btnstate}
              className={classes.spacerMT2}
              variant="contained"
              color="primary"
              isRounded={false}
              onClick={() => Search_Onclick()}
            >
              Search
            </RACButton>
          </Grid>
        </Grid>

        {hidesearch ? (
          <Grid container className={classes.spacerP2}>
            <Grid container>
              {searchData.length > 0 ? (
                <Grid item md={6}>
                  <Typography className={classes.widgetTitle}>
                    Search Results
                  </Typography>
                </Grid>
              ) : null}
              <Grid
                item
                md={6}
                id="hidecreatecocustomer"
                className={classes.textRight}
              >
                {searchData.length > 0 ? (
                  <RACButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => createCoCustomer()}
                  >
                    {props.reinstatementConsition === true
                      ? 'Create Customer'
                      : 'Create Co-Customer'}
                  </RACButton>
                ) : null}
              </Grid>
              {/* ) : null} */}
            </Grid>
            {enableloader === true ? (
              <Grid
                item
                md={12}
                className={`${classes.RACLoaderPage} ${classes.mt5} ${classes.textCenter}`}
              >
                <CircularProgress />
              </Grid>
            ) : null}

            {clearData === true &&
            enableloader === false &&
            searchData !== undefined &&
            searchData !== null &&
            searchData.length <= 0 ? (
              <Grid item md={12} className={classes.px2}>
                <Grid
                  container
                  className={`${classes.justifyContentCenter} ${classes.mb2}`}
                >
                  <Alerticon style={{ width: '30px', height: '30px' }} />
                  <Box className={`${classes.ms2} ${classes.mt1}`}>
                    No Records Found
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Typography
                    className={`${classes.RACPOPMsg} ${classes.textCenter}`}
                  >
                    <Box>
                      Do you want to create{' '}
                      <Typography
                        variant="caption"
                        className={`${classes.bold} ${classes.font16}`}
                      >
                        {searchedUser}{' '}
                      </Typography>
                      as a new customer?
                    </Box>
                  </Typography>
                </Grid>
                <Grid
                  container
                  className={`${classes.justifyContentCenter} ${classes.mt4}`}
                >
                  <RACButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => createCoCustomer()}
                  >
                    {props.reinstatementConsition === true
                      ? 'Create Customer'
                      : 'Create Co-Customer'}
                  </RACButton>
                </Grid>
              </Grid>
            ) : enableloader === false ? (
              <Grid item md={12} className={classes.spacerMT2}>
                <RACTable
                  renderTableHead={renderSearchTableHead}
                  renderTableContent={renderSearchTableContent}
                />
              </Grid>
            ) : null}
            <Grid
              item
              md={12}
              className={`${classes.w100} ${classes.spacerMT3} ${classes.textRight} ${classes.fixedBottomAddCo}`}
            >
              {searchData.length > 0 ? (
                <RACButton
                  className={`${classes.floatLeft} ${classes.foc}`}
                  variant="outlined"
                  color="primary"
                  onClick={() => props.handleClosePopUp(false)}
                >
                  Cancel
                </RACButton>
              ) : null}
              {searchData.length > 0 ? (
                <RACButton
                  className={classes.floatRight}
                  disabled={enableSubmitButton}
                  variant="contained"
                  color="primary"
                  onClick={() => submitBtn()}
                >
                  Submit
                </RACButton>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <>
            {enableloader === true ? (
              <Grid item md={12} className={classes.customerNorecords}>
                <CircularProgress />
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    </>
  );
}
