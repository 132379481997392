/* eslint-disable  */

import {
  RACButton,
  Grid,
  RACModalCard,
  Typography
} from '@rentacenter/racstrap';


const emptyPhone =
  [
    {
      phoneId: '',
      phoneNumber: '',
      phoneType: null,
      extension: '',
      primary: 'N',
      callTimeType: '',
      note: '',
      active: 'Y'
    },
  ];

export const ndncTip = 'Phone numbers that are on the National Do Not Call Registry are not allowed to receive Marketing solicitations. Customers can contact the National Do Not Call Registry at 1-888-382-1222.';

export const checkTCPAFlags = (phones: any) => {
  if (!phones) {
    return false;
  }

  let result = false;

  phones.forEach(function (item: any, index: any) {
    console.info(item, index);
    if (!item.phoneId) {
      result = true;
    }
    if (item.phoneNumber !== item.phoneNumberOri) {  
      item.isTrnsMsgAllowed = 'N';
      item.isMktgMsgAllowed = 'N';
      result = true;
    }
    else if (item.textMessageReminderOri && item.isTrnsMsgAllowed === 'N') {
      result = true;
    }
    else if (item.textMarketingOffersOri && item.isMktgMsgAllowed === 'N') {
      result = true;
    }
  });
  return result;

}


export const mapPhones = (customerResult: any) => {
  if (!customerResult || !customerResult.GetCustomer || !customerResult.GetCustomer.value || !customerResult.GetCustomer.value.phones) {
    return emptyPhone;
  }

  const phones = customerResult.GetCustomer.value.phones;

  const mapped = phones.map((item: any) => {
    if (!item.isTrnsMsgAllowed || item.isTrnsMsgAllowed === 'N') {
      item.textMessageReminderOri = false;
    }
    else {
      item.textMessageReminderOri = true;  
    }
    if (!item.isMktgMsgAllowed || item.isMktgMsgAllowed === 'N') {
      item.textMarketingOffersOri = false;
    }
    else {
      item.textMarketingOffersOri = true;  
    }
    item.phoneNumberOri = item.phoneNumber;
    if(!item.isNDNCOpted){
      item.isNDNCOpted = 'N';
    } 
    return item;
  });

  return mapped;

};

export const renderTcpaContent = (cbfunc: any) => {
  return (
    <>
      <Grid item md={12}>
        <Grid item md={12} >
          <Typography
            style={{
              marginTop: "-6px", marginBottom: "16px",
            }}
            variant={'body2'}
          >
            Please inform the customer that to Opt into text message Payment Reminders and text message Marketing Offers they will need to log into their <b>My Account </b>on the Rent-A- Center website and sign-up.
          </Typography>

        </Grid>
        <Grid
          item style={{ textAlign: "center" }}
        >
          <RACButton
            variant="outlined"
            color="primary"  
          
            onClick={cbfunc}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    </>
  );
};


